import { DeclarationForm } from '../schemas/declarationFormSchema'
import { CL112_CTC_COUNTRIES } from '../../../common/utils/ncts-constants'
import { removePrefix } from '../../../common/utils/classifier-util'

const R0905Validator = {
  hasNonSmOrAdDestinationForCtcCountry: (
    formData: DeclarationForm,
  ): boolean => {
    const destinationCountry = removePrefix(formData.destinationCustomsOffice, 'DESTINATION_OFFICE_', 2) ?? ''
    if (destinationCountry !== 'AD' && destinationCountry !== 'SM') {
      return true
    }
    const departureCountry = removePrefix(formData.departureCustomsOffice, 'DEPARTURE_OFFICE_', 2) ?? ''

    return !CL112_CTC_COUNTRIES.includes(departureCountry)
  },
} as const

export default R0905Validator
