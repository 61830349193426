import { useContext, useMemo } from 'react'
import { UserRole } from 'hooks/useAuth'
import { UserContext } from 'context/UserContext'
import { ISelectOption } from 'types/IClassifier'
import { useLocation } from 'react-router-dom'
import ROUTES from 'config/routes'
import useNctsProfileApi from './api'

function useNctsProfile() {
  const { user } = useContext(UserContext)
  const location = useLocation()
  const isAdmin = useMemo(() => user?.role === UserRole.ADMIN, [user?.role])

  const {
    getNctsProfilesByCustomerId,
    getNctsProfilesForCurrentCustomer,
  } = useNctsProfileApi()

  const profileNames = useMemo(() => {
    if (!isAdmin) {
      return getNctsProfilesForCurrentCustomer.data?.data ?? []
    }

    if (location.pathname === ROUTES.newTransit || location.pathname === ROUTES.newTransitP5) {
      return getNctsProfilesForCurrentCustomer.data?.data ?? []
    }

    return getNctsProfilesByCustomerId.data?.data ?? []
  }, [getNctsProfilesByCustomerId.data, getNctsProfilesForCurrentCustomer.data])

  const profilesAsOptions = useMemo(
    () => profileNames.map<ISelectOption>(({ name, isDefault, isShared }) => ({
      isDefault,
      isShared,
      label: name,
      value: name,
    })),
    [profileNames],
  )

  return {
    profileNames,
    profilesAsOptions,
  }
}

export default useNctsProfile
