import { RuleResult } from 'types/DeclarationP5'
import { SecurityEnumT } from '../schemas/declarationFormSchema'
import { IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'
import B1889Validator from './transitional/B1889'

const C0599Validator = {
  validateModeOfTransportAtBorderRequirement: (security: SecurityEnumT, departureCustomsOffice: string): RuleResult => {
    if (IS_TRANSITIONAL_PERIOD) {
      return B1889Validator.validateModeOfTransportAtBorderRequirement(security, departureCustomsOffice)
    }

    if (security === '1' || security === '2' || security === '3') { // AND additionalDeclarationType.A hardcoded
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0599Validator
