import { useEffect } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import { UseDeclarationFormReturn } from '../../form'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useDocumentApi from './api'
import {
  DocumentRequestWithFiles,
  parsePreviousDocumentResponse,
  parseSupportingDocumentResponse,
  parseTransportDocumentResponse,
  toFileRequest,
  toPreviousDocumentRequest,
  toSupportingDocumentRequest,
  toTransportDocumentRequest,
} from './mapper'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import {
  DocumentRequest, DocumentRequestTypeEnum, DocumentResponse, FileResponse,
} from '../../../common/models'
import { PreviousDocument, SupportingDocument, TransportDocument } from '../../form/schemas/documentSchemas'
import TransitApiConfig from '../apiConfig'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import { excludeDeleted, hasText } from '../../../common/utils/common-util'
import useFileApi, { FileFolder, SpecificFileRequest } from '../useFile/api'

const {
  queryKeys: { rootPath },
} = TransitApiConfig.paths.consignmentItem

function useDocument(form: UseDeclarationFormReturn) {
  const {
    setValue,
    getValues,
    watch,
    formState: {
      isValid,
      isSubmitting,
    },
    reset,
    trigger,
  } = form

  const isFetching = useIsFetching({ queryKey: [rootPath] })
  const { t } = useTranslation()
  const transitOperationId: number | null = watch('id')
  const { postFile, deleteFile } = useFileApi()

  const {
    getDocuments: getTransportDocuments,
    postDocuments: postTransportDocuments,
    putDocuments: putTransportDocuments,
    deleteDocument: deleteTransportDocument,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.TRANSPORT, isSubmitting)
  const {
    getDocuments: getPreviousDocuments,
    postDocuments: postPreviousDocuments,
    putDocuments: putPreviousDocuments,
    deleteDocument: deletePreviousDocument,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.PREVIOUS, isSubmitting)
  const {
    getDocuments: getSupportingDocuments,
    postDocuments: postSupportingDocuments,
    putDocuments: putSupportingDocuments,
    deleteDocument: deleteSupportingDocument,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.SUPPORTING, isSubmitting)

  // TODO convert to Array.prototype.groupToMap when supported by browsers?
  const groupBy = <T, K, E = T>(list: T[], getKey: (item: T) => K, apply?: (item: T, index: number) => E): Map<K, E[]> => {
    const map = new Map<K, E[]>()
    list.forEach((item, index) => {
      const key = getKey(item)
      if (!map.has(key)) {
        map.set(key, [])
      }
      map.get(key)?.push(apply ? apply(item, index) : item as unknown as E)
    })
    return map
  }

  const populateFormConsignmentItems = () => {
    if (getTransportDocuments.isLoading || getPreviousDocuments.isLoading || getSupportingDocuments.isLoading || isSubmitting) {
      return
    }

    let consignmentItemTransportDocuments: Map<number, TransportDocument[]> = new Map<number, TransportDocument[]>()
    let consignmentItemPreviousDocuments: Map<number, PreviousDocument[]> = new Map<number, PreviousDocument[]>()
    let consignmentItemSupportingDocuments: Map<number, SupportingDocument[]> = new Map<number, SupportingDocument[]>()

    if (getTransportDocuments.data) {
      consignmentItemTransportDocuments = groupBy(
        getTransportDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parseTransportDocumentResponse,
      )

      // TODO after transition filter out house and root level docs
    }
    if (getPreviousDocuments.data) {
      consignmentItemPreviousDocuments = groupBy(
        getPreviousDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parsePreviousDocumentResponse,
      )
      // TODO after transition filter out house and root level docs
    }
    if (getSupportingDocuments.data) {
      consignmentItemSupportingDocuments = groupBy(
        getSupportingDocuments.data.filter((document) => document.consignmentItemId !== null),
        (item) => item.consignmentItemId!,
        parseSupportingDocumentResponse,
      )
      // TODO after transition filter out house and root level docs
    }

    const formClone: DeclarationForm = structuredClone(getValues())

    reset({
      ...formClone,
      houseConsignment: formClone.houseConsignment.map((formHouseConsignment) => ({
        ...formHouseConsignment,
        consignmentItem: formHouseConsignment.consignmentItem
          .map((consignmentItem) => {
            const consignmentItemId = consignmentItem.id
            if (consignmentItemId === null) return consignmentItem

            const itemTransportDocuments = consignmentItemTransportDocuments.get(consignmentItemId)
            const itemPreviousDocuments = consignmentItemPreviousDocuments.get(consignmentItemId)
            const itemSupportingDocuments = consignmentItemSupportingDocuments.get(consignmentItemId)

            return ({
              ...consignmentItem,
              transportDocument: itemTransportDocuments?.sort(sortBySequenceNumber) ?? [],
              previousDocument: itemPreviousDocuments?.sort(sortBySequenceNumber) ?? [],
              supportingDocument: itemSupportingDocuments?.sort(sortBySequenceNumber) ?? [],
            })
          }),
      })),
    })
  }

  useEffect(() => {
    populateFormConsignmentItems()
  }, [getTransportDocuments.data, getPreviousDocuments.data, getSupportingDocuments.data, isFetching])

  function refreshSavedIds(houseConsignments: HouseConsignmentType[], response: DocumentResponse[]) {
    houseConsignments.forEach((houseConsignment, houseConsignmentIndex) => {
      houseConsignment.consignmentItem.forEach((consignmentItem, consignmentItemIndex) => {
        consignmentItem.transportDocument.forEach((transportDocument, transportDocumentIndex) => {
          const savedItem = response
            .find((responseItem) => (transportDocument.id === null && responseItem.sequenceNumber === transportDocument.sequenceNumber)
              || transportDocument.id === responseItem.id)
          if (savedItem) {
            setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${
              consignmentItemIndex}.transportDocument.${transportDocumentIndex}.id`, savedItem.id)
          }
        })
        consignmentItem.previousDocument.forEach((previousDocument, previousDocumentIndex) => {
          const savedItem = response
            .find((responseItem) => (previousDocument.id === null && responseItem.sequenceNumber === previousDocument.sequenceNumber)
              || previousDocument.id === responseItem.id)
          if (savedItem) {
            setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${
              consignmentItemIndex}.previousDocument.${previousDocumentIndex}.id`, savedItem.id)
          }
        })
        consignmentItem.supportingDocument.forEach((supportingDocument, supportingDocumentIndex) => {
          const savedItem = response
            .find((responseItem) => (supportingDocument.id === null && responseItem.sequenceNumber === supportingDocument.sequenceNumber)
              || supportingDocument.id === responseItem.id)
          if (savedItem) {
            setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${
              consignmentItemIndex}.supportingDocument.${supportingDocumentIndex}.id`, savedItem.id)
          }
        })
      })
    })
  }

  function findDocumentId(request: DocumentRequest) {
    const matchingDocument = getValues('houseConsignment')
      .flatMap((houseConsignment) => houseConsignment.consignmentItem)
      .filter((consignmentItem) => consignmentItem.id === request.consignmentItemId)
      .flatMap((consignmentItem) => {
        switch (request.type) {
          case 'TRANSPORT':
            return consignmentItem.transportDocument
          case 'SUPPORTING':
            return consignmentItem.supportingDocument
          default:
            return []
        }
      })
      .find((document) => !document.deleted && (document.sequenceNumber === request.sequenceNumber))

    if (matchingDocument === undefined || matchingDocument.id === null) {
      return -1
    }

    return matchingDocument.id
  }

  const createOrUpdateDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return
    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')

    const createRequestsForConsignmentItem: DocumentRequestWithFiles[] = []
    const updateRequestsForConsignmentItem: DocumentRequestWithFiles[] = []
    const houseConsignments = getValues('houseConsignment')
    houseConsignments
      .filter(excludeDeleted)
      .flatMap((house) => house.consignmentItem)
      .filter(excludeDeleted)
      .forEach((consignmentItem) => {
        const transportDocuments = groupBy(
          consignmentItem.transportDocument
            .filter(excludeDeleted),
          (item) => item.id,
          (item) => toTransportDocumentRequest(item, currentTransitOperationId, consignmentItem.id),
        )
        const previousDocuments = groupBy(
          consignmentItem.previousDocument
            .filter(excludeDeleted),
          (item) => item.id,
          (item) => toPreviousDocumentRequest(item, currentTransitOperationId, consignmentItem.id),
        )
        const supportingDocuments = groupBy(
          consignmentItem.supportingDocument
            .filter(excludeDeleted),
          (item) => item.id,
          (item) => toSupportingDocumentRequest(item, currentTransitOperationId, consignmentItem.id),
        )

        transportDocuments.forEach((value, key) => {
          if (key === null) {
            createRequestsForConsignmentItem.push(...value)
          } else {
            updateRequestsForConsignmentItem.push(...value)
          }
        })
        previousDocuments.forEach(async (value, key) => {
          if (key === null) {
            createRequestsForConsignmentItem.push(...value)
          } else {
            updateRequestsForConsignmentItem.push(...value)
          }
        })
        supportingDocuments.forEach(async (value, key) => {
          if (key === null) {
            createRequestsForConsignmentItem.push(...value)
          } else {
            updateRequestsForConsignmentItem.push(...value)
          }
        })
      })

    const asyncRequests: Array<Promise<DocumentResponse[]>> = []

    const newTransportDocuments = createRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT)
    const newPreviousDocuments = createRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS)
    const newSupportingDocuments = createRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING)

    if (newTransportDocuments.length > 0) {
      asyncRequests.push(postTransportDocuments.mutateAsync(newTransportDocuments.map(({ request }) => request)))
    }
    if (newSupportingDocuments.length > 0) {
      asyncRequests.push(postSupportingDocuments.mutateAsync(newSupportingDocuments.map(({ request }) => request)))
    }
    if (newPreviousDocuments.length > 0) {
      asyncRequests.push(postPreviousDocuments.mutateAsync(newPreviousDocuments.map(({ request }) => request)))
    }

    const updatedTransportDocuments = updateRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.TRANSPORT)
    const updatedPreviousDocuments = updateRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.PREVIOUS)
    const updatedSupportingDocuments = updateRequestsForConsignmentItem
      .filter(({ request }) => request.type === DocumentRequestTypeEnum.SUPPORTING)
    if (updatedTransportDocuments.length > 0) {
      asyncRequests.push(putTransportDocuments.mutateAsync(updatedTransportDocuments.map(({ request }) => request)))
    }
    if (updatedSupportingDocuments.length > 0) {
      asyncRequests.push(putSupportingDocuments.mutateAsync(updatedSupportingDocuments.map(({ request }) => request)))
    }
    if (updatedPreviousDocuments.length > 0) {
      asyncRequests.push(putPreviousDocuments.mutateAsync(updatedPreviousDocuments.map(({ request }) => request)))
    }

    const responses = await Promise.allSettled(asyncRequests)
    responses.forEach((response) => {
      if (response.status === 'fulfilled') {
        refreshSavedIds(houseConsignments, response.value)
      }
    })

    const asyncFileRequests: Array<Promise<FileResponse | void>> = []

    asyncFileRequests.push(...[...updatedTransportDocuments, ...updatedSupportingDocuments, ...updatedPreviousDocuments]
      .flatMap((transportDocument) => transportDocument.files)
      .filter((docFile) => !(docFile.deleted && docFile.id === null))
      .map((supportingDocumentFile) => {
        const { uuid, deleted } = supportingDocumentFile
        if (deleted && hasText(uuid)) {
          const splitUuid = uuid!.split('/')
          const request: SpecificFileRequest = {
            folder: splitUuid[0] as FileFolder,
            fileUuid: splitUuid[1],
          }
          return deleteFile.mutateAsync(request)
        }
        if (supportingDocumentFile.documentId !== null && supportingDocumentFile.id === null) {
          return postFile.mutateAsync(toFileRequest(supportingDocumentFile, supportingDocumentFile.documentId))
        }

        return Promise.resolve()
      }))

    asyncFileRequests.push(...[...newTransportDocuments, ...newSupportingDocuments, ...newPreviousDocuments]
      .map((transportDocumentWithFiles) => transportDocumentWithFiles.files
        .filter((docFile) => !(docFile.deleted && docFile.id === null))
        .map((transportFile) => {
          const {
            uuid,
            deleted,
          } = transportFile
          if (deleted && hasText(uuid)) {
            const splitUuid = uuid!.split('/')
            const request: SpecificFileRequest = {
              folder: splitUuid[0] as FileFolder,
              fileUuid: splitUuid[1],
            }
            return deleteFile.mutateAsync(request)
          }
          if (transportFile.documentId !== null) {
            return postFile.mutateAsync(toFileRequest(transportFile, transportFile.documentId))
          }

          const newDocumentId = findDocumentId(transportDocumentWithFiles.request)
          if (newDocumentId === -1) {
            toast.warn(t('declaration.p5.upload', { context: 'error' }))
            return Promise.reject(Error('Unable to find document id for file'))
          }

          return postFile.mutateAsync(toFileRequest(transportFile, newDocumentId))
        })).flatMap((requests) => requests))


    const filePromises = await Promise.allSettled(asyncFileRequests)
    if (filePromises.some((result) => result.status === 'rejected')) {
      toast.warn(t('declaration.p5.upload', { context: 'error' }))
    }
  }

  const archiveDocuments = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const currentTransitOperationId = getValues('id')
    if (currentTransitOperationId === null) throw Error('Missing required transit operation id for documents')

    const archivedTransportDocument: number[] = []
    const archivedPreviousDocument : number[] = []
    const archivedSupportingDocument : number[] = []

    const houseConsignments = getValues('houseConsignment')
    houseConsignments
      .filter(excludeDeleted)
      .flatMap((house) => house.consignmentItem)
      .filter(excludeDeleted)
      .forEach((consignmentItem) => {
        archivedTransportDocument.push(...consignmentItem.transportDocument
          .filter((item) => item.deleted === true && item.id !== null)
          .map((doc) => doc.id!))
        archivedPreviousDocument.push(...consignmentItem.previousDocument
          .filter((item) => item.deleted === true && item.id !== null)
          .map((doc) => doc.id!))
        archivedSupportingDocument.push(...consignmentItem.supportingDocument
          .filter((item) => item.deleted === true && item.id !== null)
          .map((doc) => doc.id!))
      })

    const asyncRequests: Array<Promise<void>> = []

    if (archivedTransportDocument.length > 0) {
      archivedTransportDocument.forEach((id) => asyncRequests.push(deleteTransportDocument.mutateAsync(id)))
    }
    if (archivedPreviousDocument.length > 0) {
      archivedPreviousDocument.forEach((id) => asyncRequests.push(deletePreviousDocument.mutateAsync(id)))
    }
    if (archivedSupportingDocument.length > 0) {
      archivedSupportingDocument.forEach((id) => asyncRequests.push(deleteSupportingDocument.mutateAsync(id)))
    }

    await Promise.allSettled(asyncRequests)
  }

  return {
    createOrUpdateDocuments,
    archiveDocuments,
  }
}

export default useDocument
