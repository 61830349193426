import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CreateTransitOperationInfoRequest,
  NotesTransitOperationInfoRequest,
  CompanyTransitOperationInfoResponse,
  TransitOperationInfoResponse,
  UpdateReviewRequest,
} from '../../../common/models'
import { MutateRequest } from '../request-type'
import TransitApiConfig from '../apiConfig'
import { UserFeedbackRequest } from '../../../common/models/user-feedback-request'

const {
  baseUrl,
  queryKeys: {
    getTransitOperationInfoByTransitOperationId,
  },
} = TransitApiConfig.paths.transitOperationInfo
const {
  queryKeys: {
    rootPath: transitOperationRootPath,
  },
} = TransitApiConfig.paths.transitOperation

function useTransitOperationInfoApi(transitOperationId: number | null) {
  const queryClient = useQueryClient()

  const postTransitOperationInfo = useMutation({
    mutationFn: async (data: CreateTransitOperationInfoRequest) => (await axios
      .post<TransitOperationInfoResponse>(baseUrl, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getTransitOperationInfoByTransitOperationId(transitOperationId) }),
  })

  const patchCompanyTransitOperationInfoNotesOrFeedback = useMutation({
    mutationFn: async ({
      id,
      data,
    }: MutateRequest<NotesTransitOperationInfoRequest>) => (await axios
      .patch<CompanyTransitOperationInfoResponse>(`${baseUrl}/company/${id}`, data)).data,
  })

  const patchTransitOperationInfoNotesOrFeedback = useMutation({
    mutationFn: async ({
      id,
      data,
    }: MutateRequest<NotesTransitOperationInfoRequest>) => (await axios
      .patch<TransitOperationInfoResponse>(`${baseUrl}/${id}`, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getTransitOperationInfoByTransitOperationId(transitOperationId) }),
  })

  const patchFeedback = useMutation({
    mutationFn: async ({
      id,
      data,
    }: MutateRequest<UserFeedbackRequest>) => (await axios
      .patch<void>(`${baseUrl}/feedback/${id}`, data)),
  })

  const patchTransitOperationInfoReviewStatus = useMutation({
    mutationFn: async ({
      id,
      data,
    }: MutateRequest<UpdateReviewRequest>) => (await axios
      .patch<TransitOperationInfoResponse>(`${baseUrl}/review-status/${id}`, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [transitOperationRootPath] }),
  })

  const deleteTransitOperationInfo = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
    onSuccess: (_, variables) => queryClient
      .invalidateQueries({ queryKey: getTransitOperationInfoByTransitOperationId(variables) }),
  })

  const getTransitOperationInfo = useQuery({
    enabled: !!transitOperationId,
    queryFn: async ({ signal }) => (await axios
      .get<TransitOperationInfoResponse>(`${baseUrl}/${transitOperationId}`, { signal })).data,
    queryKey: getTransitOperationInfoByTransitOperationId(transitOperationId),
    staleTime: Infinity, // never stale
  })

  return {
    getTransitOperationInfo,
    postTransitOperationInfo,
    patchTransitOperationInfoNotesOrFeedback,
    patchTransitOperationInfoReviewStatus,
    deleteTransitOperationInfo,
    patchFeedback,
    patchCompanyTransitOperationInfoNotesOrFeedback,
  }
}

export default useTransitOperationInfoApi
