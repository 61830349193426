import React from 'react'
import { Row } from 'react-table'
import { DeclarationAudit } from '../../../types/DeclarationAudit'
import TableRow from './TableRow'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface RestrictionsTableBodyProps {
  isLoading: boolean;
  page: Array<Row<object>>;
  prepareRow: any;
  getTableBodyProps: any;
  refreshPage: () => void;
  getDeclaration: (id: number, row: any) => void;
  openDecisionModal: (declaration: DeclarationAudit) => void;
}

function TableBody(props: RestrictionsTableBodyProps) {
  const {
    getTableBodyProps,
    page,
    prepareRow,
    refreshPage,
    getDeclaration,
    openDecisionModal,
  } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <tbody {...getTableBodyProps()} className="position-relative">
      {
        page.map((row, i: number) => {
          prepareRow(row)
          return (
            <TableRow
              key={`${row.id}_row_wrapper`}
              row={row}
              index={i}
              refreshPage={refreshPage}
              getDeclaration={getDeclaration}
              openDecisionModal={openDecisionModal}
            />
          )
        })
      }
    </tbody>
  )
}

export default TableBody

