import React from 'react'
import Form from 'types/Form'

function TextInput(props: Form.TextInput) {
  const {
    name,
    value,
    onChange,
    ...rest
  } = props

  return (
    <input
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      id={`${name}_input`}
      key={`${name}_input`}
      name={name}
      className={`form-control ${rest.className ?? ''}`}
      type="text"
      value={value ?? ''}
      onChange={onChange}
    />
  )
}

export default TextInput
