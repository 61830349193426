/* eslint-disable no-param-reassign */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { IConsignmentDetail } from 'types/IConsignmentDetail'
import { sortBySortOrder } from 'helpers'
import { FieldUsage, getFieldUsageAccordingToC60, isBulkPackage } from 'helpers/condition.helper'
import { NctsProfileNameOption } from 'types/NctsProfileNameResponse'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import { AccessLevel, DeclarationContext } from '../../../context/DeclarationContext'
import i18n from '../../../i18n'
import { Document, DocumentType } from '../../../types/Document'
import { ISelectOption } from '../../../types/IClassifier'
import { IDeclaration } from '../../../types/IDeclaration'
import { IGoodsItem } from '../../../types/IGoodsItem'
import { InvalidField } from '../../Transits/TransitsAside/detailView'
import DocumentColumn from './DocumentColumn'
import GoodsInfoColumn from './GoodsInfoColumn'
import GoodsItemRow from './GoodsItemRow'
import FileService from '../services/file.service'
// eslint-disable-next-line import/no-cycle
import useImportGoods from './hooks/useImportGoods'
import store from '../../../redux/store'
import { IDeclarationInfoReducerState } from '../../../redux/reducers/declarationInfoReducer'

export interface MappedColumns {
  header: string,
  field: string,
}

interface IGoodsProps {
  quantityUnitsOptions: ISelectOption[],
  countriesOptions: ISelectOption[]
  documentTypeOptions: ISelectOption[]
  previousDocumentTypeOptions: ISelectOption[]
  procedureTypeOptions: ISelectOption[],
  isLoading: boolean,
  restrictedGoods: Array<IGoodsItem>,
  restrictionKeys: Array<string>,
  nctsProfileNames: Array<NctsProfileNameOption>;

  goodsItemsState: {
    readonly goodsItems: Array<IGoodsItem>,
    setGoodsItems: Dispatch<SetStateAction<Array<IGoodsItem>>>,
  },
  deletedGoodsItemsState: {
    readonly deletedGoodsItems: Array<IGoodsItem>,
    setDeletedGoodsItems: Dispatch<SetStateAction<Array<IGoodsItem>>>
  },
  shouldUpdateFormState: {
    readonly shouldUpdateGoodsForm: boolean,
    setShouldUpdateGoodsForm: Dispatch<SetStateAction<boolean>>
  },
  readonly declaration: IDeclaration,
  invalidFields: Array<InvalidField>,
  setInvalidFields: Dispatch<SetStateAction<Array<InvalidField>>>;
  traderRestrictions: Array<string>;
  countryStandardVatRate: number
}

export const blankDocument: Document = {
  id: null,
  type: DocumentType.GOODS,
  number: '',
  documentType: null,
  procedureType: null,
  sortOrder: 0,
  declarationId: null,
  goodsItemId: null,
  deleted: false,
  orderNumber: null,
  transportDocumentId: null,
  files: [],
}
export const blankTrader: IConsignmentDetail = {
  id: null,
  target: 'CONSIGNOR',
  name: '',
  street: '',
  city: '',
  country: '',
  zip: '',
  eori: '',
}
export const blankPreviousDocument = { ...blankDocument, type: DocumentType.PREVIOUS }
export const blankGoodsItem: IGoodsItem = {
  id: null,
  quantity: null,
  pieces: null,
  grossWeight: null,
  netWeight: null,
  quantityUnit: null,
  marks: null,
  description: null,
  hsCode: null,
  dispatchCountryCode: null,
  destinationCountryCode: null,
  declarationId: null,
  consignorId: null,
  consigneeId: null,
  sortOrder: 0,
  visualCode: null,
  consignor: {
    ...blankTrader,
    target: 'CONSIGNOR',
  },
  consignee: {
    ...blankTrader,
    target: 'CONSIGNEE',
  },
  documents: [{ ...blankDocument }, { ...blankPreviousDocument }],
  price: undefined,
  dutyRate: undefined,
  vatRate: undefined,
}

export function Goods(props: IGoodsProps) {
  const {
    quantityUnitsOptions, countriesOptions, documentTypeOptions,
    previousDocumentTypeOptions, procedureTypeOptions, countryStandardVatRate, goodsItemsState,
    shouldUpdateFormState, deletedGoodsItemsState, isLoading, restrictedGoods,
    nctsProfileNames, restrictionKeys, traderRestrictions, setInvalidFields,
  } = props
  const { metaData: { accessLevel } } = useContext(DeclarationContext)
  const { goodsItems, setGoodsItems } = goodsItemsState
  const { deletedGoodsItems, setDeletedGoodsItems } = deletedGoodsItemsState
  const { shouldUpdateGoodsForm, setShouldUpdateGoodsForm } = shouldUpdateFormState
  const { declaration, invalidFields } = props
  const { t } = useTranslation()

  const [currentGoodsItemIndex, setCurrentGoodsItemIndex] = useState(0)
  const [currentGoodsItem, setCurrentGoodsItem] = useState(goodsItems.length > 0 ? goodsItems[0]
    : { ...blankGoodsItem, vatRate: countryStandardVatRate })

  const [showItems, toggleItems] = useState(goodsItems.length > 0)
  const [showAdditionalActions, setShowAdditionalActions] = useState(goodsItems.length > 0)

  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [columnHeaders, setColumnHeaders] = useState<Array<string>>([])

  const [selectFields, setSelectFields] = useState<Array<ISelectOption>>([])
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false)
  const [columnData, setColumnData] = useState<Array<MappedColumns>>([])
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const [csvRows, setCsvRows] = useState<Array<any>>([])
  const [documentKeys, setDocumentKeys] = useState<object>([])
  const { mapFileRowsToGoodsItems } = useImportGoods()

  const blacklistIndexes: Array<string> = ['id', 'consigneeId', 'consignorId', 'consignor', 'consignee', 'sortOrder', 'declarationId',
    'sortOrder', 'date_created', 'date_modified', 'date_deleted', 'created_by', 'modified_by', 'deleted_by',
    'consignor_id', 'consignee_id', 'declaration_id', 'sort_order', 'consignee_target', 'consignor_target', 'consignee_id', 'consignor_id',
    'documents_0', 'documents_1', 'documents', 'visualCode']
  const saveCurrentGoodsItem = () => {
    let newGoods: Array<IGoodsItem> = goodsItems.slice(0, currentGoodsItemIndex)
    newGoods.push(currentGoodsItem)
    newGoods = newGoods.concat(goodsItems.slice(currentGoodsItemIndex + 1))
    setGoodsItems(newGoods)
    if (!showItems) {
      toggleItems(!showItems)
    }
    if (!showAdditionalActions) {
      setShowAdditionalActions(!showAdditionalActions)
    }
  }

  const addNewGoodsItem = () => {
    if (accessLevel === AccessLevel.VIEW) {
      return
    }
    setShowAdditionalActions(true)
    const goodsItem = { ...blankGoodsItem, vatRate: countryStandardVatRate }
    const state = store.getState()
    const { declarationInfo } = state.declarationInfoReducer
    goodsItem.destinationCountryCode = !declaration.isGoodsTradersEnabled
    && declarationInfo && declarationInfo.destinationCountryCode !== null
      ? declarationInfo?.destinationCountryCode : null
    goodsItem.dispatchCountryCode = !declaration.isGoodsTradersEnabled
    && declarationInfo && declarationInfo.dispatchCountryCode !== null
      ? declarationInfo?.dispatchCountryCode : null
    goodsItem.sortOrder = goodsItems.length
    setCurrentGoodsItem(goodsItem)
    setCurrentGoodsItemIndex(goodsItems.length)
    saveCurrentGoodsItem()
  }

  const handleClick = () => {
    hiddenFileInput.current!.click()
  }

  const parseFileToGoods = () => {
    const importedGoods = mapFileRowsToGoodsItems(csvRows, columnData)
    setGoodsItems(() => {
      setCurrentGoodsItem(importedGoods[0])
      setCurrentGoodsItemIndex(importedGoods[0].sortOrder)
      return importedGoods
    })

    setIsExcelModalOpen(false)
  }

  function createHeaderToFieldMapping(
    prefixKey: string,
    headerName: string,
    fieldName: string,
    targetObjectWithFieldName: object,
  ): MappedColumns | null {
    const targetObjectKeys = Object.keys(targetObjectWithFieldName)

    if (!headerName.startsWith(prefixKey)) {
      return null
    }
    // mapped fieldName must exist in target object
    if (targetObjectKeys.find((key) => key === fieldName) === undefined) {
      return null
    }

    return {
      header: headerName,
      field: fieldName,
    }
  }

  const processCSV = (str: string) => {
    let delimiter = ','
    if (str.slice(0, str.indexOf('\n')).indexOf(delimiter) === -1) {
      delimiter = ';'
    }

    const headers: Array<string> = str.slice(0, str.indexOf('\n')).trim().split(delimiter)

    // eslint-disable-next-line no-restricted-syntax
    for (const header of headers) {
      const headerName = header.replace(/['"]+/g, '').trim()

      // eslint-disable-next-line no-continue
      if (blacklistIndexes.includes(headerName)) { continue } // skips forbidden headers

      setColumnHeaders((prevData) => [...prevData, headerName])

      const goodsItemMapping = createHeaderToFieldMapping(headerName, headerName, headerName, blankGoodsItem)

      if (goodsItemMapping != null) {
        setColumnData((previousData) => [...previousData, goodsItemMapping])
        // eslint-disable-next-line no-continue
        continue
      }

      const documentTypeMapping = createHeaderToFieldMapping('documentType_', headerName, 'documentType', documentKeys)

      // eslint-disable-next-line max-len
      const previousDocumentTypeMapping = createHeaderToFieldMapping('previous_documentType', headerName, 'previous_documentType', documentKeys)

      if (previousDocumentTypeMapping != null) {
        setColumnData((previousData) => [...previousData, previousDocumentTypeMapping])
      }
      if (documentTypeMapping != null) {
        setColumnData((previousData) => [...previousData, documentTypeMapping])
      }
      // eslint-disable-next-line max-len
      const previousDocumentNumberMapping = createHeaderToFieldMapping('previous_documentNumber', headerName, 'previous_documentNumber', documentKeys)

      if (previousDocumentNumberMapping !== null) {
        setColumnData((previousData) => [...previousData, previousDocumentNumberMapping])
      }

      const documentNumberMapping = createHeaderToFieldMapping('documentNumber_', headerName, 'number', documentKeys)
      if (documentNumberMapping != null) {
        setColumnData((previousData) => [...previousData, documentNumberMapping])
      }

      const documentOrderNumberMapping = createHeaderToFieldMapping('orderNumber_', headerName, 'orderNumber', documentKeys)
      if (documentOrderNumberMapping != null) {
        setColumnData((previousData) => [...previousData, documentOrderNumberMapping])
      }

      if (headerName.includes('consignor_') || headerName.includes('consignee_')) {
        const traderParts = headerName.split('_')
        const traderMapping = createHeaderToFieldMapping(traderParts[0], headerName, traderParts[1], blankTrader)
        if (traderMapping != null) {
          traderMapping.field = headerName
          setColumnData((previousData) => [...previousData, traderMapping])
        }
      }
    }

    const csvRowsAsText: Array<string> = str.slice(str.indexOf('\n') + 1).split('\n').filter((rowText) => rowText.length > 0)
    const csvRowsMappedToFields = csvRowsAsText.map((row) => {
      if (row === '') {
        return headers
      }

      if (row.startsWith('"') && row.includes(';')) {
        const rowValue = row.replace(/;/, '')
        row = rowValue
      }

      const values = row.split(delimiter)

      /* eslint-disable  @typescript-eslint/no-explicit-any */
      return headers.reduce((obj: any, header: string, i) => {
        // eslint-disable-next-line no-param-reassign
        header = header.replace(/['"]+/g, '')

        // eslint-disable-next-line no-param-reassign
        obj[header] = values[i]

        return obj
      }, {})
    })

    setCsvRows(csvRowsMappedToFields)
    setIsExcelModalOpen(true)
  }

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const file: FileList | null = event.target.files

    if (file && file[0] !== null) {
      setCsvRows([])
      setColumnHeaders([])
      if (!file[0].name.endsWith('.csv')) {
        toast.error(t('excel.mustBeCsv'))
        return
      }

      file[0].text()
        .then((fileText) => {
          if (fileText !== null) {
            processCSV(fileText)
          }
        })
        .catch(() => {
          toast.error(t('excel.processingError'))
        })
    }
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''
    }
  }

  const duplicateGoodsItem = (index: number) => {
    const goodsItem: IGoodsItem | undefined = goodsItems.find((item) => item.sortOrder === index)

    if (goodsItem !== undefined) {
      const duplicate = { ...goodsItem }
      duplicate.id = null
      duplicate.sortOrder = goodsItems.length
      duplicate.documents = duplicate.documents.map((document) => {
        const doc = { ...document }
        doc.id = null
        doc.goodsItemId = null
        doc.files = []

        return doc
      })
      setGoodsItems([...goodsItems, duplicate])
    }
  }

  const selectGoodsItem = (index: number, remainingGoodsItems?: Array<IGoodsItem>) => {
    const items = remainingGoodsItems === undefined ? goodsItems : remainingGoodsItems
    const goodsItem: IGoodsItem | undefined = items.find((item) => item.sortOrder === index)

    if (goodsItem !== undefined) {
      const state = store.getState()
      const { declarationInfo } = state.declarationInfoReducer
      if (!declaration.isGoodsTradersEnabled) {
        goodsItem.dispatchCountryCode = declarationInfo?.dispatchCountryCode !== null && declarationInfo
          ? declarationInfo.dispatchCountryCode : null
        goodsItem.destinationCountryCode = declarationInfo?.destinationCountryCode !== null && declarationInfo
          ? declarationInfo.destinationCountryCode : null
      }
      setCurrentGoodsItem(goodsItem)
      setCurrentGoodsItemIndex(index)
      if (!showAdditionalActions) {
        setShowAdditionalActions(!showAdditionalActions)
      }
    }
  }

  const deleteGoodsItem = (index: number) => {
    setDeletedGoodsItems([...deletedGoodsItems, goodsItems[index]])
    setGoodsItems([...goodsItems.filter((item, itemIndex) => (itemIndex !== index)).map((item, sortIndex) => {
      // eslint-disable-next-line no-param-reassign
      item.sortOrder = sortIndex
      return item
    })])
    selectGoodsItem(0, [...goodsItems.filter((item, itemIndex) => (itemIndex !== index))])
  }

  const populateItemsList = goodsItems.map((item, index) => (
    <GoodsItemRow
      key={`row-order-${item.sortOrder}-index-${index + 1}`}
      hsCode={item.visualCode}
      description={item.description}
      quantity={
        // eslint-disable-next-line no-nested-ternary
        isBulkPackage(item.quantityUnit ?? '') ? 1
          : getFieldUsageAccordingToC60(item.quantityUnit, 'QUANTITY') === FieldUsage.REQUIRED ? item.quantity : item.pieces
      }
      grossWeight={item.grossWeight}
      isActive={currentGoodsItemIndex === index}
      onDelete={() => {
        deleteGoodsItem(index)
      }}
      onRowClick={() => {
        selectGoodsItem(index)
      }}
      onDuplicate={() => {
        duplicateGoodsItem(index)
      }}
      isLoading={isLoading}
      hasRestrictedCode={restrictedGoods.find((goodsItem) => goodsItem.hsCode === item.hsCode) !== undefined}
    />
  ))

  useEffect(() => {
    if (showAdditionalActions && goodsItems[currentGoodsItemIndex] !== null) {
      let newGoods: Array<IGoodsItem> = goodsItems.slice(0, currentGoodsItemIndex)
      newGoods.push(currentGoodsItem)
      newGoods = newGoods.concat(goodsItems.slice(currentGoodsItemIndex + 1))
      setGoodsItems(newGoods)
    }
  }, [currentGoodsItem])

  useEffect(() => {
    if (goodsItems.length === 0) {
      if (accessLevel === AccessLevel.VIEW) {
        return
      }
      const goodsItem = { ...blankGoodsItem }
      setShowAdditionalActions(true)
      setCurrentGoodsItem(goodsItem)
      setGoodsItems([goodsItem])
      setCurrentGoodsItemIndex(0)
    }
  }, [goodsItems])

  useEffect(() => {
    const goodsKeys = Object.keys(blankGoodsItem)
    goodsKeys.forEach((goodsKey) => {
      // @ts-ignore
      if (blankGoodsItem[goodsKey] && (typeof blankGoodsItem[goodsKey] === 'object' && declaration.isGoodsTradersEnabled)) {
        // @ts-ignore
        const nestedKeys = Object.keys(blankGoodsItem[goodsKey])
        goodsKeys.push(...nestedKeys.map((nestedKey) => `${goodsKey}_${nestedKey}`))
      }
    })

    const documentKeyValues = Object.keys(blankDocument)
    documentKeyValues.push('previous_documentType')
    documentKeyValues.push('previous_documentNumber')
    const allDocuments = blankDocument
    Object.assign(allDocuments, { previous_documentType: '' })
    Object.assign(allDocuments, { previous_documentNumber: 0 })
    setDocumentKeys(allDocuments)
    documentKeyValues.forEach((item) => {
      if (item.toString() === 'documentType'
          || item.toString() === 'number'
          || item.toString() === 'previous_documentType'
          || item.toString() === 'previous_documentNumber'
          || item.toString() === 'orderNumber') {
        setSelectFields((prevState) => [...prevState, {
          value: item.toString(),
          label: `${t(`translations${i18n.language.toUpperCase()}:${item}`)}`,
        }])
      }
    })

    const arrayOfGoods = goodsKeys.filter(((firstItem) => blacklistIndexes.indexOf(firstItem) === -1)).map((item) => ({
      value: item,
      label: `${t(`translations${i18n.language.toUpperCase()}:${item}`)}`,
    }))

    arrayOfGoods.forEach((key) => {
      setSelectFields((prevState) => [...prevState, {
        value: key.value,
        label: key.label,
      }])
    })
  }, [])

  useEffect(() => {
    if (shouldUpdateGoodsForm) {
      setShouldUpdateGoodsForm(false)
      const goodsItem: IGoodsItem | undefined = goodsItems.find((item) => item.sortOrder === currentGoodsItemIndex)
      if (goodsItem !== undefined && goodsItem.documents !== undefined) {
        const state = store.getState()
        const { declarationInfo }: IDeclarationInfoReducerState = state.declarationInfoReducer
        setCurrentGoodsItem(() => ({
          ...goodsItem,
          dispatchCountryCode: !declaration.isGoodsTradersEnabled ? declarationInfo!.dispatchCountryCode
            : currentGoodsItem.dispatchCountryCode,
          destinationCountryCode: !declaration.isGoodsTradersEnabled ? declarationInfo!.destinationCountryCode
            : currentGoodsItem.destinationCountryCode,
          documents: [...goodsItem.documents
            .map((document) => ({ ...document, files: [...document.files] }))
            .sort(sortBySortOrder),
          ],
        }))
      }
    }
  }, [shouldUpdateGoodsForm])

  const calculateGrossMass = () => {
    if (goodsItems !== null) {
      const grossMass = goodsItems.length > 0 && goodsItems
        .map((item) => item.grossWeight)
        .reduce((previousValue, currentValue) => (previousValue !== null && previousValue !== undefined
          ? Number(previousValue) + Number((currentValue !== null && currentValue !== undefined) ? currentValue : 0) : 0))

      if (grossMass !== null) {
        return Number(grossMass).toFixed(3)
      }
    }

    return 0
  }

  const goodsItemsList = () => (
    <>
      {goodsItems.length > 0 && !isLoading && (
        <>

          <div className="items-list__header">
            <span className="me-3">
              {`${t('declaration.headers.goodsItems')}:`}
              {' '}
              {goodsItems.length}
            </span>
            <span>
              {`${t('declaration.totalGrossMass')}:`}
              {' '}
              {
                calculateGrossMass()
              }
            </span>
          </div>
          <table className="goods-table table table-borderless w-100">
            <tbody>
              { !isLoading && populateItemsList}
            </tbody>
          </table>
        </>
      )}
      {
        goodsItems.length === 0 && !isLoading && (
          <div className="d-flex justify-content-center mt-5 mb-5 font-size-1 text-black-50 text-uppercase">
            {t('messages.noGoodsItemsAdded')}
          </div>
        )
      }
      {!isLoading && (
        <div className="d-flex justify-content-center mb-5">
          <button
            disabled={accessLevel === AccessLevel.VIEW}
            type="button"
            className="btn btn-outline-dark d-flex align-items-center"
            onClick={() => addNewGoodsItem()}
          >
            <i className="far fa-plus me-1" />
            <span>
              {t('buttons.addGoods')}
            </span>
          </button>
        </div>
      )}
      <div className="d-flex justify-content-center mb-2">
        <button
          disabled={accessLevel === AccessLevel.VIEW}
          type="button"
          className="btn btn-primary d-flex align-items-center"
          onClick={handleClick}
        >
          <input
            style={{ display: 'none' }}
            ref={hiddenFileInput}
            onChange={(event) => uploadFile(event)}
            type="file"
          />
          <i className="fal fa-md fa-file-csv me-1" />
          <span>
            {t('excel.importGoods')}
          </span>
        </button>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <a
          className="btn btn-outline-dark d-flex align-items-center"
          href={`${process.env.PUBLIC_URL}/example.csv`}
          download="example.csv"
        >
          {t('excel.example')}
        </a>
      </div>
    </>
  )

  const isFilenameUnique = (filename: string) => {
    const anyMatches = goodsItems
      .flatMap((goodsItem) => goodsItem.documents)
      .flatMap((goodsItemDocument) => goodsItemDocument.files)
      .filter((addedFile) => (addedFile.deleted !== true && (addedFile.filename.trim() === filename)))

    return anyMatches.length === 0
  }

  const generateNameForUploadedFile = (originalFilename: string) => {
    let filename = originalFilename.trim()
    let isUnique = isFilenameUnique(filename)
    if (isUnique) {
      return filename
    }

    let depth = 1
    while (!isUnique) {
      filename = FileService.addDuplicationNumberToName(originalFilename, depth)
      isUnique = isFilenameUnique(filename)
      if (!isUnique) {
        // eslint-disable-next-line no-plusplus
        depth++
      }
    }

    return filename
  }

  return (
    <>
      {isLoading && <LoadingBackdrop loading={isLoading} />}
      <div className="col-12 col-xl-8">
        {!isLoading && (
          <div className="d-flex flex-wrap mb-3 border-bottom no-mobile-border">
            <GoodsInfoColumn
              key="goodsInfoColumn"
              quantityUnitsOptions={quantityUnitsOptions}
              countriesOptions={countriesOptions}
              currentGoodsItem={currentGoodsItem}
              setCurrentGoodsItem={setCurrentGoodsItem}
              declaration={declaration}
              invalidFields={invalidFields}
              restrictedKeys={restrictionKeys}
              setInvalidFields={setInvalidFields}
              traderRestrictions={traderRestrictions}
              nctsProfileNames={nctsProfileNames}
              countryStandardVatRate={countryStandardVatRate}
            />
            <DocumentColumn
              key="documentColumn"
              declaration={declaration}
              previousDocumentTypeOptions={previousDocumentTypeOptions}
              procedureTypeOptions={procedureTypeOptions}
              documentTypeOptions={documentTypeOptions}
              setDocuments={(updatedDocs: Array<Document>) => {
                setCurrentGoodsItem((prevState) => ({
                  ...prevState,
                  documents: updatedDocs,
                }))
              }}
              documents={currentGoodsItem.documents !== undefined
                ? currentGoodsItem.documents : [{ ...blankDocument }, { ...blankPreviousDocument }]}
              generateNameForUploadedFile={generateNameForUploadedFile}
              invalidFields={invalidFields}
            />
          </div>
        )}
        {!isLoading && (
          <div className="d-flex flex-column flex-md-row mb-3 px-4 justify-content-between">
            {/* eslint-disable-next-line max-len */}
            <div className={`${showAdditionalActions ? 'd-flex' : 'd-none'} justify-content-between flex-grow-1 mb-3 flex-md-row flex-column`}>
              <button
                type="button"
                className="btn btn-lg btn-light mb-2 mb-md-0 border text-danger"
                onClick={() => deleteGoodsItem(currentGoodsItemIndex)}
              >
                <span>{t('buttons.delete')}</span>
              </button>
              <button
                type="button"
                className="btn btn-lg btn-outline-dark mx-0 mx-md-1"
                disabled={accessLevel === AccessLevel.VIEW}
                onClick={() => duplicateGoodsItem(currentGoodsItemIndex)}
              >
                <i className="fal fa-clone fa-flip-horizontal me-2" />
                <span>{t('buttons.duplicate')}</span>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="items-list col-12 col-xl-4 position-sticky">
        {goodsItemsList()}
      </div>
      <Modal
        show={isExcelModalOpen}
        dialogClassName="import-modal modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setIsExcelModalOpen(false)}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-self-center">
            <h2>{t('excel.import')}</h2>
          </Modal.Title>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setIsExcelModalOpen(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="ms-2 mb-5 mt-2">
            <p>{t('excel.explanation')}</p>
          </div>
          <div className="d-flex flex-row w-100 pb-5" style={{ overflowY: 'hidden', overflowX: 'auto' }}>
            {columnHeaders.map(((header, index) => (
              <div
                key={`${header}`}
                className={`${index !== 0 ? 'ms-2 d-flex flex-column w-100 align-items-center'
                  : 'd-flex flex-column w-100 align-items-center'}`}
              >
                <div className={`${index !== 0 ? 'ms-2' : ''}`}>{header}</div>
                <Select
                  isDisabled={accessLevel === AccessLevel.VIEW}
                  options={selectFields}
                  menuPosition="fixed"
                  className="select mt-3 form-control p-0 pe-1 import-csv-select"
                  classNamePrefix="select"
                  menuPlacement="auto"
                  placeholder={t('common.chooseField')}
                  onChange={(option: ISelectOption | null) => {
                    if (option !== null) {
                      setColumnData((previousData) => [...previousData, {
                        header,
                        field: option.value,
                      }])
                    }
                  }}
                  value={selectFields.find((data) => data.value === columnData.find((columns) => columns.header === header)?.field)
                      ?? []}
                />
              </div>
            )))}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark btn-lg m-0 text-primary"
            disabled={columnData.length === 0}
            onClick={() => parseFileToGoods()}
          >
            <i className="fal fa-check me-2" />
            <span>{t('excel.save')}</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
