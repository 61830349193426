import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import FormRow from '../../../components/FormRow'
import { IStoreState } from '../../../redux/store'
import { useSeals } from './hooks/useSeals'
import { ISeal } from './types/Seal'
import SealService from './seal.service'
import { addSeal } from '../../../redux/actions'
import { UserContext } from '../../../context/UserContext'

export interface SealProps {
  transportId: number | null,
}

function Seal(props: SealProps) {
  const { transportId } = props
  const { t } = useTranslation()
  const store = useStore()
  const getSeals = () => (store.getState() as IStoreState).sealReducer.seal
  const [seals, setSeals] = useState<Array<ISeal>>([])
  const { user } = useContext(UserContext)

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (transportId !== null) {
        setSeals(getSeals())
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (transportId !== null) {
      const sealArray: Array<ISeal> = []
      if (user?.role === 'ADMIN') {
        SealService.getSeals(transportId)
          .then((result) => {
            result.forEach((seal, index) => {
              // eslint-disable-next-line no-param-reassign
              seal.sortOrder = index
              sealArray.push(seal)
            })
            store.dispatch(addSeal([...sealArray]))
            setSeals(sealArray)
          })
      }
    }
  }, [transportId])

  const updateSeal = (index: number, identity: string) => {
    if (user?.role === 'ADMIN') {
      const seal = seals.find((oldSeal) => oldSeal.sortOrder === index)

      if (seal) {
        seal.identity = identity
        store.dispatch(addSeal([...seals]))
        setSeals([...seals])
      }
    }
  }

  const deleteSeal = (index: number) => {
    if (user?.role === 'ADMIN') {
      const seal = seals.find((oldSeal) => oldSeal.sortOrder === index)

      if (seal && seal.id) {
        store.dispatch(addSeal([...seals]))
        seal.deleted = true
        setSeals([...seals])
      } else if (!seal?.id) {
        const sealArray = seals.filter((e) => e.sortOrder !== index)
        setSeals([...sealArray])
      }
    }
  }

  const { addItem } = useSeals({ seals, transportId, setSeals })
  return (
    <FormRow
      key="seal"
      title={t('declaration.seal')}
    >
      {
        seals.length !== 0 && seals.map((row, index) => (!row.deleted && (
          <div
            className="row align-items-baseline"
            key={`seal_${row.sortOrder}`}
          >
            <input
              className="form-control w-50"
              type="text"
              value={row.identity}
              onChange={(event) => {
                updateSeal(index, event.target.value)
              }}
            />
            <button
              key={`seal_delete_${row.sortOrder}`}
              type="button"
              className="ml-2 btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm px-0"
              onClick={() => {
                deleteSeal(index)
              }}
            >
              <i className="far fa-trash" />
            </button>
          </div>
        )))
      }
      <button
        type="button"
        className="btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm mt-2"
        onClick={() => { addItem() }}
      >
        <i className="far fa-plus" />
      </button>

    </FormRow>
  )
}

export default Seal
