import React, { useContext } from 'react'
import { UserContext } from 'context/UserContext'
import { User } from 'routes/Customer/services/user.service'
import DeclarationTab from '../layout/DeclarationTab'
import { Tab } from '../navigation/NavigationBarTabs'
import DeclarationGeneralTab from './general'
import DeclarationTransitPartiesTab from './transitParties'
import DeclarationConsignmentTab from './consignment'
import DeclarationGoodsTab from './goods'
import DeclarationSummaryTab from './summary'
import DirectoTab from './directo'
import { IS_TRANSITIONAL_PERIOD } from '../../common/utils/ncts-constants'
import EventLog from './event-logs/EventLog'

export interface DeclarationEditorProps {
  currentTab: Tab
}

function DeclarationTabs({ currentTab }: DeclarationEditorProps) {
  const { user }: { user: User | undefined } = useContext(UserContext)

  return (
    <>
      <DeclarationTab tab="general" currentTab={currentTab}>
        <DeclarationGeneralTab />
      </DeclarationTab>
      <DeclarationTab tab="transit-parties" currentTab={currentTab}>
        <DeclarationTransitPartiesTab />
      </DeclarationTab>
      {!IS_TRANSITIONAL_PERIOD ? (
        <DeclarationTab tab="consignment" currentTab={currentTab}>
          <DeclarationConsignmentTab />
        </DeclarationTab>
      ) : null}
      <DeclarationTab tab="goods" currentTab={currentTab}>
        <DeclarationGoodsTab />
      </DeclarationTab>
      <DeclarationTab tab="summary" currentTab={currentTab}>
        <DeclarationSummaryTab />
      </DeclarationTab>
      {user?.role === 'ADMIN' ? (
        <DeclarationTab tab="directo" currentTab={currentTab}>
          <DirectoTab />
        </DeclarationTab>
      ) : null}
      <DeclarationTab tab="logs" currentTab={currentTab}>
        <EventLog />
      </DeclarationTab>
    </>
  )
}

export default DeclarationTabs
