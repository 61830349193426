/* eslint-disable react/destructuring-assignment */
import React, {
  Dispatch, SetStateAction, useContext, useMemo,
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from 'types/IClassifier'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { FormScope } from 'types/DeclarationP5'
import AsyncSelect from 'react-select/async'
import { filterCustomsOfficeOptions, getSelectValue } from 'helpers'
import { TransitOperationContext } from '../hooks/useTransitOperationContext'

export type SelectProps<T extends FormScope> = {
  field: T
  labelKey: string
  isClearable?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  customFilterOption?: (option: FilterOptionOption<ISelectOption>, rawInput: string) => boolean
} & ({
  type: 'async'
  loadOptions: (inputValue: string) => Promise<ISelectOption[]>
  valueBuffer: ISelectOption | null
  setValueBuffer: Dispatch<SetStateAction<ISelectOption | null>>
} | {
  type: 'sync'
  options: ISelectOption[]
})

function appendFilter(customFilterOption: ((option: FilterOptionOption<ISelectOption>, rawInput: string) => boolean) | undefined) {
  if (customFilterOption === null || customFilterOption === undefined) return {}
  return { filterOption: customFilterOption }
}

function FormSelect<T extends FormScope>(selectProps: SelectProps<T>) {
  const {
    field,
    labelKey,
    isClearable,
    isDisabled,
    customFilterOption,
  } = selectProps

  const { control } = useFormContext()
  const { t } = useTranslation()
  const { fieldRules } = useContext(TransitOperationContext)
  const isCurrentPathAnItemInArray = useMemo(() => {
    const parentPath = field.slice(0, field.lastIndexOf('.'))
    return !Number.isNaN(Number(parentPath.at(parentPath.length - 1)))
  }, [field])

  const rule = useMemo(
    () => {
      const separatorBeforeParentPath = field.lastIndexOf('.')
      if (separatorBeforeParentPath > 0) {
        let parentPath = field.slice(0, separatorBeforeParentPath)
        if (isCurrentPathAnItemInArray) {
          parentPath = parentPath.slice(0, parentPath.lastIndexOf('.'))
        }
        const parentRule = fieldRules.find((item) => item.path === parentPath)?.ruleResult
        if (parentRule === 'NOT_ALLOWED') {
          return 'NOT_ALLOWED'
        }
      }

      return fieldRules.find((item) => item.path === field)?.ruleResult ?? 'OPTIONAL'
    },
    [fieldRules],
  )
  const fallbackForBigOptionsArray = (
    inputValue: string,
    loadingCallback: (func: any) => void,
  ) => {
    if (selectProps.type === 'sync') {
      loadingCallback(filterCustomsOfficeOptions(inputValue, selectProps.options, ' - '))
    }
  }

  return (
    <Controller
      control={control}
      name={field as string}
      render={({ field: { onChange, value, ref }, fieldState }) => {
        if (selectProps.type === 'async') {
          return (
            <AsyncSelect
              ref={ref}
              key={`${field}_${value}${selectProps.valueBuffer?.label}`}
              isDisabled={isDisabled || rule === 'NOT_ALLOWED'}
              isClearable={isClearable}
              className={`select menu-list--auto ${fieldState.invalid ? 'form-control p-0 pe-3 is-invalid' : ''} ${
                rule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''
              }`}
              defaultOptions={selectProps.valueBuffer ? [selectProps.valueBuffer] : []}
              value={selectProps.valueBuffer}
              menuPlacement="auto"
              classNamePrefix="select"
              loadOptions={selectProps.loadOptions}
              placeholder={t(labelKey)}
              onChange={(option: ISelectOption | null) => {
                onChange(option?.value ?? '')
                selectProps.setValueBuffer(option)
              }}
              {...(appendFilter(customFilterOption))}
            />
          )
        }

        // eslint-disable-next-line react/destructuring-assignment
        const selectOptions = selectProps.options
        return (
          <AsyncSelect
            ref={ref}
            key={field + value}
            isDisabled={isDisabled || rule === 'NOT_ALLOWED'}
            isClearable={isClearable}
            className={`bg-red select menu-list--auto ${fieldState.invalid ? 'form-control p-0 pe-3 is-invalid' : ''} ${
              rule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''
            }`}
            menuPlacement="auto"
            classNamePrefix="select"
            defaultOptions={[...selectOptions.slice(0, 200)]}
            loadOptions={fallbackForBigOptionsArray}
            placeholder={t(labelKey)}
            value={getSelectValue(value, selectOptions)}
            onChange={(option: ISelectOption | null) => {
              (onChange(option?.value ?? ''))
            }}
            {...(appendFilter(customFilterOption))}
          />
        )
      }}
    />
  )
}
FormSelect.defaultProps = {
  isClearable: true,
  isDisabled: false,
  isInvalid: false,
  customFilterOption: null,
}

export default FormSelect

