import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { TransitOperationReassignmentRequest } from '../../../common/models'
import TransitApiConfig from '../../../TransitOperationEditor/hooks/apiConfig'

const {
  baseUrl,
  queryKeys: { rootPath },
} = TransitApiConfig.paths.transitOperation

function useReassignTransitOperationApi() {
  const queryClient = useQueryClient()

  const reassignTransitOperation = useMutation({
    mutationFn: async (data: TransitOperationReassignmentRequest) => (await axios
      .post<void>(`${baseUrl}/reassign`, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [rootPath] }),
  })

  return {
    reassignTransitOperation,
  }
}

export default useReassignTransitOperationApi
