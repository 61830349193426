import { format } from 'date-fns'
import { DeclarationForm, SecurityEnum } from '../../form/schemas/declarationFormSchema'
import { CustomsOfficeOfTransitDeclared } from '../../form/schemas/commonConsignmentSchemas'
import {
  CreateOrUpdateCustomsOfficeOfTransitRequest,
  CreateOrUpdateTransitOperationRequest,
  CreateOrUpdateTransitOperationRequestAdditionalDeclarationTypeEnum,
  CreateOrUpdateTransitOperationRequestDeclarationTypeEnum,
  CustomsOfficeOfTransitResponse,
  TransitOperationResponse,
} from '../../../common/models'

export function toCreateOrUpdateCustomsOfficeOfTransitRequest(
  item: CustomsOfficeOfTransitDeclared,
  index: number,
): CreateOrUpdateCustomsOfficeOfTransitRequest {
  return {
    id: item.id ?? undefined,
    sequenceNumber: index,
    referenceNumber: item.referenceNumber,
    arrivalDateAndTime: new Date(item.arrivalDateAndTime ?? ''), // TODO is date pattern valid?
  }
}

export function toCreateOrUpdateTransitOperationRequest(declarationForm: DeclarationForm): CreateOrUpdateTransitOperationRequest {
  return {
    customsOfficeOfDeparture: declarationForm.departureCustomsOffice,
    customsOfficeOfDestination: declarationForm.destinationCustomsOffice,
    declarationType: CreateOrUpdateTransitOperationRequestDeclarationTypeEnum[declarationForm.declarationType],
    security: declarationForm.security,
    additionalDeclarationType: CreateOrUpdateTransitOperationRequestAdditionalDeclarationTypeEnum.A,
    reducedDatasetIndicator: false,
    bindingItinerary: declarationForm.bindingItinerary === 'true',
    customsOfficeOfTransits: declarationForm.transitCustomsOffices
      .filter((item) => !item.deleted)
      .map(toCreateOrUpdateCustomsOfficeOfTransitRequest),
    limitDate: declarationForm.limitDate ?? '',
  }
}

export function parseCustomsOfficeOfTransitResponse(office: CustomsOfficeOfTransitResponse, index: number): CustomsOfficeOfTransitDeclared {
  return {
    id: office.id,
    deleted: false,
    sequenceNumber: office.sequenceNumber ?? index,
    arrivalDateAndTime: office.arrivalDateAndTime ?? '',
    referenceNumber: office.referenceNumber ?? '',
  }
}

type TransitOperationPartiallyPrefilledFields = 'id' | 'security' | 'bindingItinerary' | 'transitCustomsOffices' | 'status' | 'lrn' | 'mrn'
| 'departureCustomsOffice' | 'destinationCustomsOffice' | 'customerId' | 'additionalDeclarationType' | 'limitDate' | 'declarationType' | 'directoId'

export function parseTransitOperationResponse(
  response: TransitOperationResponse | undefined,
): Pick<DeclarationForm, TransitOperationPartiallyPrefilledFields> {
  const declarationType: string = response !== undefined && response?.declarationType !== undefined ? response.declarationType.toString() : 'T1'

  return {
    id: response?.id ?? null,
    declarationType: response !== undefined && response?.declarationType !== undefined
      ? CreateOrUpdateTransitOperationRequestDeclarationTypeEnum[
        declarationType as keyof typeof CreateOrUpdateTransitOperationRequestDeclarationTypeEnum]
      : CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T1,
    security: SecurityEnum.parse(response?.security?.charAt(1) ?? '0'),
    transitCustomsOffices: response?.customsOfficeOfTransits?.map(parseCustomsOfficeOfTransitResponse) ?? [],
    departureCustomsOffice: response?.customsOfficeOfDeparture ?? '',
    destinationCustomsOffice: response?.customsOfficeOfDestination ?? '',
    bindingItinerary: response?.bindingItinerary === true ? 'true' : 'false',
    customerId: response?.customerId ?? null,
    limitDate: response?.limitDate ? format(new Date(response.limitDate), 'yyyy-MM-dd') : '',
    lrn: response?.lrn ?? '',
    mrn: response?.mrn ?? '',
    status: response?.status ?? 'DRAFT',
    additionalDeclarationType: 'A',
    directoId: response?.directoId ?? null,
  }
}
