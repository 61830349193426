import { Dispatch, SetStateAction, useState } from 'react'
import { IGoodsItem } from 'types/IGoodsItem'
import { sortBySortOrder } from 'helpers'

export interface GuaranteeCalculationGoodsItemRow {
  sortOrder: number;
  description: string;
  hsCode: string;

  grossWeight: number | null;
  price?: number;
  dutyRate?: number;
  vatRate?: number;
  taxableItemPrice?: number;
  taxableDutyAmount?: number;
  taxableVatAmount?: number;
}

function useGuaranteeCalculator(
  countryStandardVatRate: number,
  goodsItems: Array<IGoodsItem>,
  isCalculationModalVisible: boolean,
  setCalculationModalVisible: Dispatch<SetStateAction<boolean>>,
) {
  const [goodsItemsRows, setGoodsItemsRows] = useState<GuaranteeCalculationGoodsItemRow[]>([])

  const loadGoodsInfo = () => {
    const rows: Array<GuaranteeCalculationGoodsItemRow> = goodsItems.map((item) => {
      const vatRate = item.vatRate ?? 0
      let taxableDutyAmount: number | undefined
      let taxableVatAmount: number | undefined
      let taxableItemPrice: number | undefined

      if (item.price !== undefined && item.dutyRate !== undefined) {
        const price = Number(item.price)
        taxableDutyAmount = parseFloat(((price * item.dutyRate) / 100).toFixed(3))
        taxableVatAmount = parseFloat(((price + taxableDutyAmount) * (vatRate / 100)).toFixed(3))
        taxableItemPrice = parseFloat((price + taxableVatAmount + taxableDutyAmount).toFixed(3))
      }

      return ({
        sortOrder: item.sortOrder,
        description: item.description ?? '',
        hsCode: item.visualCode ?? '',
        grossWeight: item.grossWeight,
        price: item.price,
        dutyRate: item.dutyRate,
        taxableItemPrice,
        taxableDutyAmount,
        taxableVatAmount,
        vatRate,
      })
    })
    setGoodsItemsRows(rows.sort(sortBySortOrder))
  }

  const openCalculation = () => {
    if (isCalculationModalVisible) {
      return
    }

    setCalculationModalVisible(true)
    loadGoodsInfo()
  }

  return {
    goodsItemsRows,
    openCalculation,
  } as const
}

export default useGuaranteeCalculator
