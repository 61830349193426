import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { AUTHORISATION_TYPE_CODE_PREFIX, removePrefix } from '../../../common/utils/classifier-util'

const C0839Validator = {
  validateLimitDateRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.authorisations.length === 0) {
      return 'NOT_ALLOWED'
    }

    if (formData.authorisations.some((auth) => removePrefix(auth.type, AUTHORISATION_TYPE_CODE_PREFIX) === 'C521')) {
      if (formData.additionalDeclarationType === 'D') {
        return 'OPTIONAL'
      }

      return 'REQUIRED'
    }
    return 'NOT_ALLOWED'
  },

} as const

export default C0839Validator
