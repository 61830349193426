import { RuleResult } from 'types/DeclarationP5'

const B1892Validator = {
  validateIdentificationRequirement: (containerIndicator: string | null): RuleResult => {
    if (containerIndicator !== null && containerIndicator === 'true') {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default B1892Validator
