import React from 'react'

function LoadingBackdropBody() {
  return (
    <div className="loading-backdrop-message">
      <i className="fa fab fa-spinner-third fa-spin fa-3x text-primary" />
    </div>
  )
}

function LoadingBackdrop({
  loading,
  isInTable = true,
// eslint-disable-next-line react/require-default-props
}: { loading: boolean, isInTable?: boolean }) {
  return (
    <>
      {
        isInTable && (
          <caption className="border-0 p-0">
            {loading && <LoadingBackdropBody />}
          </caption>
        )
      }
      {
        !isInTable && (
          <div className="border-0 p-0">
            {loading && <LoadingBackdropBody />}
          </div>
        )
      }
    </>
  )
}
export default LoadingBackdrop

