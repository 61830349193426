import React, { useState } from 'react'
import { TitleBar } from 'components/TitleBar'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { ISelectOption } from '../types/IClassifier'

interface SelectOptionProps {
  value: string;
  label: string;
}

function Docs() {
  const notify = () => toast.success('Copied!')

  const selectOptions: SelectOptionProps[] = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
  ]
  const [selectValue, setSelectValue] = useState<ISelectOption>(selectOptions[0])

  const buttons: string[] = [
    '<button type="button" className="btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm"><i className=""></i></button>',
    '<button type="button" className="btn btn-primary btn-lg d-flex align-items-center shadow-sm"><i className="me-2"></i> <span></span></button>',
    '<button className="btn btn-lg btn-dark mx-1 px-4 text-primary"><span></span></button>',
  ]
  const inputs: string[] = [
    '<div className="form-check d-flex align-items-center"><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" defaultChecked /><label className="form-check-label" htmlFor="flexCheckDefault">NNN</label></div>',
    '<div className="form-check d-flex align-items-center"><input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked /><label className="form-check-label text-nowrap ps-1" htmlFor="flexRadioDefault1">NNN</label></div><div className="form-check d-flex align-items-center me-3"><input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" /><label className="form-check-label text-nowrap ps-1" htmlFor="flexRadioDefault2">NNN</label></div>',
    '<input className="form-control" type="text" placeholder="NNN" />',
    '<Select options={} className="select" classNamePrefix="select" defaultValue={} onChange={} />',
  ]
  const tables: string[] = [
    '<table className="table table-hover"><thead><tr><th className="w-1 hover" scope="col"></th><th className="hover" scope="col"></th></tr><tr className="table-search"><td><button className="btn"><i className="fal fa-search fa-md" /></button></td><td><input type="search" name="" id="" /></td></tr></thead><tbody><tr><td></td><td></td></tr></tbody></table>',
    '<table className="table table-sm table-hover table-borderless data-table"><thead><tr><th className="hover" scope="col"><div></div></th></tr></thead><tbody><tr><td></td></tr></tbody></table>',
    '<div className="col-4"><div className="heading"><h3>NNN</h3></div><table className="table table-sm table-borderless mb-5"><tbody><tr><th></th><td><input className="form-control" type="text" placeholder="NNN" /></td><td><input className="form-control" type="text" placeholder="1/3 Cell" /></td></tr><tr><th></th><td colSpan={2}><input className="form-control" type="text" placeholder="NNN" /></td></tr></tbody></table></div>',
  ]
  const headings: string[] = [
    '<div className="heading"><h3>NNN</h3></div>',
    '<div className="position-relative  heading"><h3>NNN</h3><div className="heading-button d-flex align-items-center"><button className="btn btn-outline-dark btn-sm d-flex align-items-center" onClick={notify}> <i className="far fa-plus me-1" /><span>Add New</span></button></div></div>',
    '<div className="heading"><h5>NNN</h5></div>',
  ]
  const icons: string[] = [
    'fal fa-search',
    'fal fa-cloud-upload fa-2x',
    'far fa-plus',
    'fal fa-times',
    'fal fa-file',
    'far fa-trash',
    'fal fa-clone fa-flip-horizontal',
    'fal fa-chevron-up',
    'fal fa-chevron-down',
    'fal fa-route fa-md',
    'fal fa-container-storage fa-md',
    'fas fa-ellipsis-h',
    'fal fa-print',
    'fal fa-align-right',
    'fal fa-pen',
    'fal fa-exclamation-triangle text-danger fa-md',
    'fal fa-envelope fa-md',
    'fal fa-lock-alt fa-md',
    'fal fa-ban',
    'fal fa-reply fa-flip-horizontal',
    'fal fa-calendar-alt',
  ]

  const populateIcons = icons.map((icon) => (
    <CopyToClipboard text={icon}>
      <button
        type="button"
        className="btn btn-link btn-lg"
        onClick={notify}
      >
        <i className={`${icon} me-2`} />
      </button>
    </CopyToClipboard>
  ))

  return (
    <>
      <TitleBar title="Page elements library" />
      <div className="mt-4 mx-4 pt-5">

        {/* BUTTONS */}
        <div className="heading">
          <h3>
            Buttons
          </h3>
        </div>
        <div className="d-flex mx-4 mb-5">
          <CopyToClipboard text={buttons[0]}>
            <>
              <button
                type="button"
                className="btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm me-3"
                onClick={notify}
              >
                <i className="fal fa-print" />
              </button>
              <div className="d-flex align-items-center me-3">
                For icon only buttons.
              </div>
            </>
          </CopyToClipboard>
          <CopyToClipboard text={buttons[1]}>
            <button
              type="button"
              className="btn btn-primary btn-lg d-flex align-items-center shadow-sm me-3"
              onClick={notify}
            >
              <i className="fal fa-reply fa-flip-horizontal me-2" />
              <span>
                Icon&text button
              </span>
            </button>
          </CopyToClipboard>
          <CopyToClipboard text={buttons[2]}>
            <button type="button" className="btn btn-lg btn-dark mx-1 px-4 text-primary" onClick={notify}>
              <span>
                Text only button
              </span>
            </button>
          </CopyToClipboard>
        </div>

        {/* INPUTS */}
        <div className="heading">
          <h3>
            Inputs
          </h3>
        </div>
        <div className="d-flex mx-4 mb-5">
          <CopyToClipboard text={inputs[0]}>
            <div role="presentation" className="form-check d-flex align-items-center me-3" onClick={notify}>
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  defaultChecked
                />
                Remember me
              </label>
            </div>
          </CopyToClipboard>
          <CopyToClipboard text={inputs[1]}>
            <>
              <div role="presentation" className="form-check d-flex align-items-center me-3" onClick={notify}>
                <label className="form-check-label text-nowrap ps-1" htmlFor="flexRadioDefault1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    defaultChecked
                  />
                  No (0)
                </label>
              </div>
              <div className="form-check d-flex align-items-center me-3">
                <label className="form-check-label text-nowrap ps-1" htmlFor="flexRadioDefault2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  Yes (1)
                </label>
              </div>
            </>
          </CopyToClipboard>
          <CopyToClipboard text={inputs[2]}>
            <input className="form-control w-25 me-3" type="text" placeholder="Please fill in the form" onClick={notify} />
          </CopyToClipboard>
          <CopyToClipboard text={inputs[3]}>
            <Select
              options={selectOptions}
              className="select w-25"
              classNamePrefix="select"
              defaultValue={selectValue}
              onChange={(value: ISelectOption | null) => setSelectValue(value!)}
              onInputChange={notify}
            />
          </CopyToClipboard>
        </div>

        {/* TABLES */}
        <div className="heading">
          <h3>
            Tables
          </h3>
        </div>
        <div className="mx-4 mb-5">
          <CopyToClipboard text={tables[0]}>
            <table role="presentation" className="table table-hover mb-5" onClick={notify}>
              <thead>
                <tr>
                  <th aria-label="col" className="w-1 hover" scope="col" />
                  <th className="hover" scope="col">
                    Main data table
                  </th>
                  <th className="hover" scope="col">
                    Column header
                  </th>
                  <th className="hover" scope="col">
                    Column header
                  </th>
                  <th className="hover" scope="col">
                    Column header
                  </th>
                </tr>
                <tr className="table-search">
                  <td>
                    <button className="btn" type="button">
                      <i className="fal fa-search fa-md" />
                    </button>
                  </td>
                  <td><input type="search" name="" id="" /></td>
                  <td><input type="search" name="" id="" /></td>
                  <td><input type="search" name="" id="" /></td>
                  <td><input type="search" name="" id="" /></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
                <tr>
                  <td />
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
                <tr>
                  <td />
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
              </tbody>
            </table>
          </CopyToClipboard>
          <CopyToClipboard text={tables[1]}>
            <table role="presentation" className="table table-sm table-hover table-borderless data-table mb-5" onClick={notify}>
              <thead>
                <tr>
                  <th className="hover" scope="col">
                    <div>
                      Data table for users, documents etc.
                    </div>
                  </th>
                  <th className="hover" scope="col">
                    <div>
                      Column header
                    </div>
                  </th>
                  <th className="hover" scope="col">
                    <div>
                      Column header
                    </div>
                  </th>
                  <th className="hover" scope="col">
                    <div>
                      Column header
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
                <tr>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
                <tr>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
              </tbody>
            </table>
          </CopyToClipboard>
          <CopyToClipboard text={tables[2]}>
            <div className="col-4">
              <div className="heading">
                <h3>
                  Table for formating forms
                </h3>
              </div>
              <table role="presentation" className="table table-sm table-borderless mb-5" onClick={notify}>
                <tbody>
                  <tr>
                    <th>Input title</th>
                    <td>
                      <input className="form-control" type="text" placeholder="1/3 Cell" />
                    </td>
                    <td>
                      <input className="form-control" type="text" placeholder="1/3 Cell" />
                    </td>
                  </tr>
                  <tr>
                    <th>Input title</th>
                    <td colSpan={2}>
                      <input className="form-control" type="text" placeholder="2/3 Cell" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CopyToClipboard>
        </div>

        {/* HEADINGS */}
        <div className="heading">
          <h3>
            Headers/headings
          </h3>
        </div>
        <div className="mx-4 mb-5">
          <CopyToClipboard text={headings[0]}>
            <div role="presentation" className="heading pointer" onClick={notify}><h3>Heading</h3></div>
          </CopyToClipboard>
          <CopyToClipboard text={headings[1]}>
            <div role="presentation" className="position-relative heading pointer" onClick={notify}>
              <h3>Heading with a button</h3>
              <div className="heading-button d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-sm d-flex align-items-center"
                >
                  <i className="far fa-plus me-1" />
                  <span>
                    Add New
                  </span>
                </button>
              </div>
            </div>
          </CopyToClipboard>
          <CopyToClipboard text={headings[3]}>
            <div role="presentation" className="heading pointer" onClick={notify}><h5>Heading in detail view panel</h5></div>
          </CopyToClipboard>
        </div>

        {/* ICONS */}
        <div className="heading">
          <h3>
            Icons
          </h3>
        </div>
        <div className="d-flex mx-4 mb-5">
          {populateIcons}
        </div>
      </div>
    </>
  )
}

export default Docs

