import React, { useState } from 'react'
import { Row, TablePropGetter, TableProps } from 'react-table'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import TableRowStatus from '../../../Transits/Table/TableRowStatus'
import { TransitOperationAuditPageResponse } from '../../common/models'

interface AuditTableBodyProps {
  asideAction: (input: boolean, targetId: number, response: TransitOperationAuditPageResponse) => void;
  page: Array<Row<object>>;
  prepareRow: (row: Row<object>) => void;
  getTableBodyProps: (propGetter?: TablePropGetter<object> | undefined) => TableProps;
  openDecisionModal: (form: TransitOperationAuditPageResponse) => void;
}

function TableBody(props: AuditTableBodyProps) {
  const {
    page,
    prepareRow,
    openDecisionModal,
    asideAction,
  } = props
  const [isOptionsOpen, toggleOptions] = useState(false)
  const [lastSeen, setLastSeen] = useState<number>(0)

  const { t } = useTranslation()

  return (
    <tbody>
      {
        page.map((row: Row<TransitOperationAuditPageResponse>, i: number) => {
          prepareRow(row)

          return (
            <React.Fragment key={`${i + row.cells.length}n`}>
              <tr
                style={{
                  color: (lastSeen !== 0 && lastSeen === row.original.id) ? '#1a1b1e' : undefined,
                  backgroundColor: (lastSeen !== 0 && lastSeen === row.original.id) ? 'rgba(0, 0, 0, 0.075)' : undefined,
                }}
                {...row.getRowProps()}
              >
                {/* eslint-disable  @typescript-eslint/no-explicit-any */}
                {row.cells.map((cell: any) => (
                  <td
                    key={cell.id}
                    role="presentation"
                    className={
                      `${cell.column.id === 'id' ? 'text-left' : ''} ${
                        (!isOptionsOpen && !(cell.column.id === 'id')) ? 'pointer' : ''} ${
                        cell.column.className ? cell.column.className : ''
                      }`
                    }
                    onClick={() => {
                      if (row.original.id && row.original.transitOperationId) {
                        setLastSeen(row.original.id)
                        asideAction(true, row.original.transitOperationId, row.original)
                      }
                    }}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...cell.getCellProps()}
                  >
                    {
                      cell.column.id === 'id'
                    && (
                      <div
                        className="d-flex align-items-center justify-content-between border-0 w-100 h-100 p-0 lh-1"
                      >
                        <span className="text-decoration-underline">
                          {row.cells[0].render('Cell')}
                        </span>
                      </div>
                    )
                    }
                    {
                      cell.column.id === 'status'
                    && (
                      <TableRowStatus value={row.original.status !== undefined ? row.original.status.toString() : '-'} />
                    )
                    }
                    {
                      (cell.column.id === 'isReviewed')
                    && (
                      <div
                        className={`d-flex justify-content-center align-items-center rounded-circle wh-24 ms-1 ${
                          row.original.isReviewed ? 'text-success' : 'text-danger'
                        }`}
                        style={{ border: '1px solid' }}
                      >
                        <i className={`${row.original.isReviewed ? 'fa-check' : 'fa-times'} far`} />
                      </div>
                    )
                    }
                    {
                      cell.column.id !== 'id'
                    && cell.column.id !== 'status'
                    && (
                      cell.render('Cell')
                    )
                    }
                  </td>
                ))}

                <td className="dropdown-cell w-1">
                  <Dropdown
                    onToggle={(isOpen) => toggleOptions(isOpen)}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="link"
                      className="p-0 hide-caret"
                    >
                      <i className="fas fa-ellipsis-h fa-md" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow">
                      <Dropdown.Item onClick={() => {
                        openDecisionModal(row.original)
                      }}
                      >
                        <i className="fal fa-edit me-2 fa-md" />
                        <span>{t('buttons.decideAudit')}</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            </React.Fragment>
          )
        })
      }
    </tbody>
  )
}

export default TableBody
