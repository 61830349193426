import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import DocumentService from '../routes/Declaration/services/document.service'
import { Document, DocumentType } from '../types/Document'
import { IGoodsItem } from '../types/IGoodsItem'
import ConsignmentDetailsService from '../routes/Declaration/services/consignment-detail.service'
import { IConsignmentDetail } from '../types/IConsignmentDetail'

function initBlankTrader(target: 'CONSIGNOR' | 'CONSIGNEE') {
  return {
    id: null,
    target,
    name: '',
    street: '',
    city: '',
    country: '',
    zip: '',
    eori: '',
  }
}

function getGoodsItemsConsignmentDetails(goodsItemsResponse: Array<IGoodsItem>): Promise<Array<IGoodsItem>> {
  return new Promise(
    (resolve, reject) => {
      const consignmentIds: Array<number> = []
      const addId = (id: number | null | undefined) => {
        if (id !== undefined && id !== null) {
          consignmentIds.push(id)
        }
      }

      goodsItemsResponse
        .map((goodsItem) => goodsItem.consigneeId)
        .forEach(addId)

      goodsItemsResponse
        .map((goodsItem) => goodsItem.consignorId)
        .forEach(addId)

      if (consignmentIds.length === 0) {
        resolve(goodsItemsResponse)
        return
      }

      ConsignmentDetailsService.getConsignmentDetailsByIds(consignmentIds).then((consignmentDetails: Array<IConsignmentDetail>) => {
        let goodsItems: Array<IGoodsItem> = []
        if (consignmentDetails.length > 0) {
          goodsItems = goodsItemsResponse.map((goodsItem) => {
            if (goodsItem.consigneeId != null) {
              const consignee = consignmentDetails.find((item) => item.id === goodsItem.consigneeId)
              // eslint-disable-next-line no-param-reassign
              goodsItem.consignee = consignee !== undefined ? {
                id: consignee.id,
                target: 'CONSIGNEE',
                name: consignee.name,
                street: consignee.street,
                city: consignee.city,
                country: consignee.country,
                zip: consignee.zip,
                eori: consignee.eori,
              } : initBlankTrader('CONSIGNEE')
            }
            if (goodsItem.consignorId != null) {
              const consignor = consignmentDetails.find((item) => item.id === goodsItem.consignorId)
              // eslint-disable-next-line no-param-reassign
              goodsItem.consignor = consignor !== undefined ? {
                id: consignor.id,
                target: 'CONSIGNOR',
                name: consignor.name,
                street: consignor.street,
                city: consignor.city,
                country: consignor.country,
                zip: consignor.zip,
                eori: consignor.eori,
              } : initBlankTrader('CONSIGNOR')
            }

            return goodsItem
          })
        }
        resolve(goodsItems)
      }, (error: AxiosError) => {
        reject(error)
      })
    },
  )
}

function getTransitGoodsItemsDetailed(declarationId: number, goodsItems: Array<IGoodsItem>): Promise<Array<IGoodsItem>> {
  return new Promise((resolve, reject) => {
    if (declarationId === undefined) {
      reject()
      return
    }
    const documentPromises = []

    documentPromises.push(
      DocumentService.getAllDocuments(declarationId, DocumentType.GOODS)
        .then((goodsDocs) => [...goodsDocs]),
    )
    documentPromises.push(
      DocumentService.getAllDocuments(declarationId, DocumentType.PREVIOUS)
        .then((prevDocs) => [...prevDocs]),
    )

    Promise.all(documentPromises).then((promiseDocuments: Array<Array<Document>>) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const document of promiseDocuments) {
        // eslint-disable-next-line no-param-reassign
        goodsItems = goodsItems.map((goodsItem) => {
          const goodsDocs = document.filter((goodsDoc) => goodsDoc.goodsItemId === goodsItem.id)
          if (goodsDocs !== null && goodsDocs.length > 0) {
            if (goodsItem.documents !== undefined) {
              // eslint-disable-next-line no-param-reassign
              goodsItem.documents = [...goodsItem.documents, ...goodsDocs]
            } else {
              // eslint-disable-next-line no-param-reassign
              goodsItem.documents = [...goodsDocs]
            }
          }
          // eslint-disable-next-line no-param-reassign
          goodsItem.consignor = initBlankTrader('CONSIGNOR')
          // eslint-disable-next-line no-param-reassign
          goodsItem.consignee = initBlankTrader('CONSIGNEE')

          return goodsItem
        })
      }

      getGoodsItemsConsignmentDetails(goodsItems).then((itemsWithDetails) => {
        resolve(itemsWithDetails)
      }, (error) => {
        resolve(goodsItems)
        toast.warn('Warning! There were errors loading goods items traders')
        // eslint-disable-next-line no-console
        console.warn(error)
      })
    })
  })
}

const TransitService = {
  getTransitGoodsItemsDetailed,
}

export default TransitService
