import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { blankPreviousDocument } from '../../../form'
import { PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { PreviousDocument } from '../../../form/schemas/documentSchemas'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted'

function isPreviousDocumentField(field: string): field is Exclude<ObjectKeys<PreviousDocument>, NotImportableFields> {
  return field in blankPreviousDocument
}

export default function mapPreviousDocument(
  newImportedItemObject: PreviousDocument[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isPreviousDocumentField(consignmentItemObjectSubfield)) {
    return
  }
  let previousDocument = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)
  if (previousDocument === undefined) {
    previousDocument = {
      ...blankPreviousDocument,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(previousDocument)
  }

  switch (consignmentItemObjectSubfield) {
    case 'documentType':
      previousDocument[consignmentItemObjectSubfield] = hasText(cellValue) ? PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX + cellValue : ''
      break
    case 'referenceNumber':
    case 'complementOfInformation':
    case 'transportDocumentReferenceNumber':
      previousDocument[consignmentItemObjectSubfield] = cellValue
      break
    default:
      throw Error(`Unknown previous document field ${consignmentItemObjectSubfield}`)
  }
}
