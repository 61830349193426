import { z } from 'zod'
import { FORBIDDEN_CHARACTERS } from 'helpers/string.helper'

// eslint-disable-next-line import/prefer-default-export
export const withoutForbiddenCharacters = (value: string | null, ctx: z.RefinementCtx) => {
  if (value !== null && FORBIDDEN_CHARACTERS.some((char) => value.includes(char))) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Text cannot forbidden unicode characters ',
    })
  }
}
