import { PureComponent } from 'react'

interface WrapperProps {
  togglePageHeader: () => void;
}

class RouteItemWrapper extends PureComponent<WrapperProps> {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.togglePageHeader()
  }

  render() {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    return this.props.children
  }
}

export default RouteItemWrapper
