import { useFormContext } from 'react-hook-form'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import { NestedFieldScope, ObjectScope, ScopeProps } from '../../../../../types/DeclarationP5'
import { apiService, handleResponseData } from '../../../../../services/api.service'
import { IConsignmentDetail } from '../../../../../types/IConsignmentDetail'
import { ExternalApiError, ValidationError } from '../../../../../types/ValidationError'
import Input from '../Input'

// eslint-disable-next-line max-len
const expectedScope = /(^consignee$)|(houseConsignment\.\d+\.consignee)|(houseConsignment\.\d+\.consignmentItem\.\d+\.consignee)|(^consignor$)|(houseConsignment\.\d+\.consignor)|(^carrier$)/g
const isTraderScope = (scope: ObjectScope): scope is 'consignee' | `houseConsignment.${number}.consignee`
| `houseConsignment.${number}.consignmentItem.${number}.consignee` | 'consignor' | `houseConsignment.${number}.consignor` | 'carrier' => (Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

type ConsignmentCarrier = NestedFieldScope<'carrier'>
type ConsignmentConsignor = NestedFieldScope<'consignor'>
type ConsignmentConsignee = NestedFieldScope<'consignee'>
type HouseConsignor = NestedFieldScope<`houseConsignment.${number}.consignor`>
type HouseConsignee = NestedFieldScope<`houseConsignment.${number}.consignee`>
type ConsignmentItemConsignee = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.consignee`>
type TraderScope =
  ConsignmentCarrier
  | ConsignmentConsignor
  | ConsignmentConsignee
  | HouseConsignor
  | HouseConsignee
  | ConsignmentItemConsignee

const nameMaxLength = 35

function mapToTraderForm(content: IConsignmentDetail, identificationNumber: string, isConsignee: boolean) {
  return {
    name: content.name ?? '',
    id: content.id ?? 0,
    identificationNumber: content.eori ?? identificationNumber,
    address: content.city ? {
      streetAndNumber: content.street ?? '',
      country: content.country ?? '',
      city: content.city ?? '',
      id: 0,
      postcode: content.zip ?? '',
    } : null,
    contactPerson: null,
    target: isConsignee ? 'CONSIGNEE' : 'CONSIGNOR',
  }
}

function mapToCarrierForm(content: IConsignmentDetail, identificationNumber: string) {
  return {
    carrierName: content.name ?? '',
    id: 0,
    identificationNumber: content.eori ?? identificationNumber,
    contactPerson: null,
  }
}

function EoriSearch({ scope }: ScopeProps<ObjectScope>) {
  if (!isTraderScope(scope)) throw Error('Unable to narrow, invalid scope')

  const commonCompanyPrefixes = ['Sabiedrība ar ierobežotu atbildību ', 'UŽDAROJI AKCINĖ BENDROVĖ ', 'Usługi Transportowe ']
  const [eoriLoading, setEoriLoading] = useState(false)
  const { t } = useTranslation()

  const {
    setValue,
    getValues,
  } = useFormContext()

  const searchUsingEori = (searchQuery: string) => {
    setEoriLoading(true)
    if (searchQuery === '') {
      setEoriLoading(false)
      return
    }
    if (searchQuery.startsWith('GB')) {
      toast.error(t('eori.error', { context: 'GB' }))
      setEoriLoading(false)
      return
    }

    axios.get(
      apiService.getFullApiUrl('/eori'),
      {
        params: {
          eoriCode: searchQuery.replace(' ', '').toUpperCase(),
        },
      },
    ).then(handleResponseData).then((eoriConsignmentDetails: IConsignmentDetail) => {
      let companyName = eoriConsignmentDetails.name

      const containsTypicalPrefix = commonCompanyPrefixes.find((prefix) => companyName.toLowerCase().startsWith(prefix.toLowerCase()))

      if (containsTypicalPrefix !== undefined) {
        companyName = companyName.substring(containsTypicalPrefix.length).replaceAll('"', '')
      }

      if (companyName.length > nameMaxLength) {
        companyName = companyName.substring(0, nameMaxLength)
        toast.warning('Company name was too long and has been shortened. Please check and verify the name.')
      }

      if (!scope.includes('carrier')) {
        setValue(scope, mapToTraderForm(eoriConsignmentDetails, searchQuery, scope.includes('consignee')))
      } else {
        setValue(scope, mapToCarrierForm(eoriConsignmentDetails, searchQuery))
      }
    }).catch((error: AxiosError<ValidationError | ExternalApiError>) => {
      let errorCode = '500'
      if (error.response && 'errorCode' in error.response.data) {
        errorCode = error.response.data.errorCode ?? '400'
      }
      if (error.response && 'errors' in error.response.data) {
        errorCode = '400'
        if (Array.isArray(error.response.data.errors) && error.response.data.errors.length) {
          errorCode = error.response.data.errors.some((item) => item.reason === 'Pattern') ? 'pattern' : '400'
        }
      }
      toast.error(t('eori.error', { context: errorCode }), { autoClose: 3000 })
    })
      .finally(() => {
        setEoriLoading(false)
      })
  }

  return (
    <div>
      <div className="input-group">
        <Input
          <TraderScope>
          field={`${scope}.identificationNumber`}
          labelKey="declaration.p5.consignorIdentificationNr"
          type="text"
          placeholder={t('declaration.eoriId')}
          onKeyDown={(onKeyDownEvent) => {
            if (onKeyDownEvent.key === 'Enter') {
              searchUsingEori(onKeyDownEvent.currentTarget.value)
            }
          }}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-light h-100 border"
            onClick={() => {
              if (!eoriLoading) {
                searchUsingEori(getValues(scope).identificationNumber)
              }
            }}
          >
            {
              eoriLoading
              && <i className="fa fa-spinner-third fa-spin" />
            }
            {
              !eoriLoading
              && <i className="fal fa-search" />
            }
          </button>
        </div>
      </div>

    </div>
  )
}

export default EoriSearch
