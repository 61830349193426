import { useEffect, useState } from 'react'
import { useObservableState } from 'observable-hooks'
import { Observable } from 'rxjs'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { isMobile } from 'react-device-detect'
import { PageFilterRequest, PageResponse, TableFilter } from '../../../../types/PageResponse'
import AuthenticationService from '../../../../services/auth.service'
import RestrictionService from '../../services/restriction.service'
import { Restriction } from '../../../../types/restriction'
import { extractPageResponseContent } from '../../../../helpers'

export interface RestrictionsPageProps {
  pageIndex: number,
  pageSize: number,
  gotoPage: (index: number) => void
}

export function useRestrictionsPage(props: RestrictionsPageProps) {
  const {
    gotoPage,
  } = props
  const [data, setData] = useState<PageResponse<Restriction> | Array<Restriction>>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(props.pageSize)
  const [currentPageIndex, setCurrentPageIndex] = useState(props.pageIndex)

  const typeFilterState = useState('')
  const filtersState = useState<Array<TableFilter>>([])
  const [filters] = filtersState
  const [typeFilter, setTypeFilter] = typeFilterState

  const fetchPage = (requestEvent: PageFilterRequest) => {
    AuthenticationService.eagerlyLogoutWhenTokenExpireDateImpending()
    return RestrictionService.getRestrictionPage(requestEvent)
  }

  const [pageResponse, invokeFetchPage] = useObservableState(
    (event$: Observable<PageFilterRequest>) => event$.pipe(
      debounceTime(200),
      tap(() => setLoading(true)),
      switchMap((requestEvent) => fetchPage(requestEvent)),
    ),
    () => ({} as PageResponse<Restriction>),
  )

  const invokeFetch = () => {
    invokeFetchPage({
      pageIndex: currentPageIndex,
      pageSize: currentPageSize,
      filters,
      typeFilter,
    })
  }

  useEffect(() => {
    setData([])
    if (currentPageIndex === 0) {
      invokeFetch()
    }
    gotoPage(0)
  }, [filters, typeFilter])

  useEffect(() => {
    setTotalPages(pageResponse.totalPages)
    setData(extractPageResponseContent(pageResponse, isMobile))
    setLoading(false)
  }, [pageResponse])

  useEffect(() => {
    invokeFetch()
  }, [currentPageSize, currentPageIndex])

  const refreshPage = () => {
    invokeFetch()
  }

  return {
    data,
    setData,
    totalPages,
    setTotalPages,
    filtersState,
    typeFilter,
    setTypeFilter,
    refreshPage,
    loading,
    setCurrentPageSize,
    setCurrentPageIndex,
  }
}
