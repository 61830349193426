import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { SupportingDocument } from '../../../form/schemas/documentSchemas'
import { blankSupportingDocument } from '../../../form'
import { SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted'
function isSupportingDocumentField(field: string): field is Exclude<ObjectKeys<SupportingDocument>, NotImportableFields> {
  return field in blankSupportingDocument
}

export default function parseSupportingDocument(
  newImportedItemObject: SupportingDocument[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isSupportingDocumentField(consignmentItemObjectSubfield)) {
    return
  }
  let supportingDocument = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)
  if (supportingDocument === undefined) {
    supportingDocument = {
      ...blankSupportingDocument,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(supportingDocument)
  }

  switch (consignmentItemObjectSubfield) {
    case 'documentType':
      supportingDocument[consignmentItemObjectSubfield] = hasText(cellValue) ? SUPPORTING_DOCUMENT_TYPE_CODE_PREFIX + cellValue : ''
      break
    case 'referenceNumber':
    case 'complementOfInformation':
      supportingDocument[consignmentItemObjectSubfield] = cellValue
      break
    case 'documentLineNumber':
      supportingDocument[consignmentItemObjectSubfield] = Number.isNaN(Number(cellValue)) ? null : Number(cellValue)
      break
    default:
      throw Error(`Unknown supporting document field ${consignmentItemObjectSubfield}`)
  }
}
