import { useEffect, useState } from 'react'
import { useObservableState } from 'observable-hooks'
import { Observable } from 'rxjs'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { isMobile } from 'react-device-detect'
import { PageFilterRequest, PageResponse, TableFilter } from '../../../types/PageResponse'
import AuthenticationService from '../../../services/auth.service'
import { extractPageResponseContent } from '../../../helpers'
import DeclarationAuditService from '../services/declaration-audit.service'
import { DeclarationAudit } from '../../../types/DeclarationAudit'

export interface DeclarationAuditPageProps {
  pageIndex: number,
  pageSize: number,
  gotoPage: (index: number) => void
}

export function useDeclarationAuditPage(props: DeclarationAuditPageProps) {
  const {
    gotoPage,
  } = props

  const [data, setData] = useState<DeclarationAudit[]>([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(props.pageSize)
  const [currentPageIndex, setCurrentPageIndex] = useState(props.pageIndex)

  const statusFilterState = useState('')
  const filtersState = useState<Array<TableFilter>>([])
  const [filters] = filtersState
  const [statusFilter, setStatusFilter] = statusFilterState

  const fetchPage = (requestEvent: PageFilterRequest) => {
    AuthenticationService.eagerlyLogoutWhenTokenExpireDateImpending()
    return DeclarationAuditService.getAuditPage(requestEvent)
  }

  const [pageResponse, invokeFetchPage] = useObservableState(
    (event$: Observable<PageFilterRequest>) => event$.pipe(
      debounceTime(200),
      tap(() => setLoading(true)),
      switchMap((requestEvent) => fetchPage(requestEvent)),
    ),
    () => ({} as PageResponse<DeclarationAudit>),
  )
  const invokeFetch = () => {
    invokeFetchPage({
      pageIndex: currentPageIndex,
      pageSize: currentPageSize,
      filters,
      statusFilter,
    })
  }

  useEffect(() => {
    setData([])
    if (currentPageIndex === 0) {
      invokeFetch()
    }
    gotoPage(0)
  }, [filters, statusFilter])

  useEffect(() => {
    setTotalPages(pageResponse.totalPages)
    setData(extractPageResponseContent(pageResponse, isMobile))
    setLoading(false)
  }, [pageResponse])

  useEffect(() => {
    invokeFetch()
  }, [currentPageSize, currentPageIndex])

  const refreshPage = () => {
    invokeFetch()
  }

  return {
    data,
    setData,
    totalPages,
    setTotalPages,
    filtersState,
    statusFilterState,
    setStatusFilter,
    statusFilter,
    refreshPage,
    loading,
    setCurrentPageSize,
    setCurrentPageIndex,
  }
}
