import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { EventLog } from '../../../types/EventLog'

function getAllEventLogs(declarationId: number): Promise<Array<EventLog>> {
  return new Promise((resolve) => {
    axios.post(apiService.getFullApiUrl('/logs'), { declarationId })
      .then(handleResponseData)
      .then((value) => resolve(value))
      .catch(handleApiException)
  })
}

const EventLogService = {
  getAllEventLogs,
}

export default EventLogService
