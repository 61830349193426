import React, {
  SetStateAction, useContext, useEffect, useState,
} from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { DateRangePicker, RangeKeyDict } from 'react-date-range'
import { enGB, ru } from 'date-fns/locale'
import getMonth from 'date-fns/getMonth'
import addHours from 'date-fns/addHours'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import FormRow from 'components/FormRow'
import Select, { MultiValue } from 'react-select'
import { toast } from 'react-toastify'
import CustomerTableService from 'routes/CustomerTable/services/customer-table.service'
import ExportService from './services/export.service'
import { ISelectOption } from '../../types/IClassifier'
import { DeclarationStatusEnum } from '../../types/IDeclaration'
import { UserContext } from '../../context/UserContext'
import CustomsProfileService from '../Customer/CustomsProfile/services/customs_profile.service'

interface ExportParams {
  modalVisibilityState: boolean
  setModalVisibility: React.Dispatch<SetStateAction<boolean>>
  isPhaseFive: boolean
}

function Export(props: ExportParams) {
  const {
    t,
    i18n,
  } = useTranslation()
  const { modalVisibilityState, setModalVisibility, isPhaseFive } = props

  const { user } = useContext(UserContext)
  const [startDate, setStartDate] = useState<Date>(new Date(Date.UTC(new Date().getFullYear(), getMonth(new Date()), 1, 6)))
  const [endDate, setEndDate] = useState<Date>(() => addHours(lastDayOfMonth(startDate), 6))
  const [loading, setLoading] = useState(false)
  const [customers, setCustomers] = useState<Array<ISelectOption>>([])
  const [customerId, setCustomerId] = useState<number | null>(null)
  const [status, setStatus] = useState<Array<string>>([])
  const [declarationStatuses, setDeclarationStatuses] = useState<Array<ISelectOption>>([])
  const [declarationProfiles, setDeclarationProfiles] = useState<Array<ISelectOption>>([])
  const [profiles, setProfiles] = useState<Array<string>>([])
  const [dateFilter, setDateFilter] = useState<Array<ISelectOption>>([{ value: '', label: '' }])
  const [useCreatedDate, setUseCreatedDate] = useState<boolean>(true)

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  }

  useEffect(() => {
    Object.keys(DeclarationStatusEnum).forEach((enumKey) => {
      setDeclarationStatuses((current) => [
        ...current, { value: enumKey, label: enumKey.replaceAll('_', ' ') },
      ])
    })

    setDateFilter([{
      value: 'modified',
      label: 'Modified',
    }, { value: 'created', label: 'Created' }])
  }, [])

  const loadAllCustomers = async () => {
    const loadedCustomers = await CustomerTableService.fetchAllCustomers()
    setCustomers(loadedCustomers?.map((item) => ({
      value: item,
      label: `${item.name}`,
    })))
  }

  const loadAllProfiles = async () => {
    const loadedProfiles = await CustomsProfileService.getAllProfiles()
    setDeclarationProfiles(loadedProfiles?.map((item) => ({
      value: item.name,
      label: `${item.name}`,
    })))
  }

  const loadAllProfilesForCustomer = async () => {
    const loadedProfiles = await CustomsProfileService.getAllCustomsProfileForCustomer()
    setDeclarationProfiles(loadedProfiles?.map((item) => ({
      value: item.name,
      label: `${item.name}`,
    })))
  }

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      loadAllCustomers()
      loadAllProfiles()
    } else {
      loadAllProfilesForCustomer()
    }
  }, [user?.role])

  const handleSelect = ({ selection }: RangeKeyDict) => {
    if (loading) {
      return
    }

    if (selection && selection.startDate) {
      setStartDate(selection.startDate)
    }

    if (selection && selection.endDate) {
      setEndDate(selection.endDate)
    }
  }

  const postExportRequest = () => {
    setLoading(true)
    startDate.setDate(startDate.getDate() + 1)
    endDate.setDate(endDate.getDate() + 1)
    if (!isPhaseFive) {
      ExportService.postExport(customerId, startDate, endDate, i18n.language === 'ru' ? 'RU' : 'EN', status, useCreatedDate, profiles)
        .then(() => {
          toast.success(t('export.queueSubmit', { context: 'success' }))
          setModalVisibility(false)
        })
        .catch(() => {
          toast.error(t('export.queueSubmit', { context: 'failed' }))
          setStatus([])
        })
        .finally(() => {
          setLoading(false)
          setUseCreatedDate(true)
          setStatus([])
        })
    } else {
      ExportService.createExport(customerId, startDate, endDate, i18n.language === 'ru' ? 'RU' : 'EN', status, useCreatedDate, profiles)
        .then(() => {
          toast.success(t('export.queueSubmit', { context: 'success' }))
          setModalVisibility(false)
        })
        .catch(() => {
          toast.error(t('export.queueSubmit', { context: 'failed' }))
          setStatus([])
        })
        .finally(() => {
          setLoading(false)
          setUseCreatedDate(true)
          setStatus([])
        })
    }
  }

  return (
    <Modal
      show={modalVisibilityState}
      size="xl"
      className="overflow-hidden"
      dialogClassName="export-modal modal align-modal-top align-modal-left"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setModalVisibility(!modalVisibilityState)}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title className="justify-content-between">
          <h2>{t('export.title')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setModalVisibility(!modalVisibilityState)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          {
            user?.role === 'ADMIN' && (
              <FormRow title={t('export.customerSelect')}>
                <Select
                  options={customers}
                  className="select"
                  classNamePrefix="select"
                  menuPlacement="auto"
                  isSearchable={false}
                  isClearable
                  isDisabled={loading}
                  placeholder={t('export.customerSelect')}
                  onChange={(option: ISelectOption | null) => setCustomerId(Number(option?.value?.id) ?? null)}
                />
              </FormRow>
            )
          }
          <FormRow title={t('export.statusSelect')}>
            <Select
              options={declarationStatuses}
              className="select"
              classNamePrefix="select"
              menuPlacement="auto"
              isSearchable={false}
              isClearable
              isMulti
              isDisabled={loading}
              placeholder={t('export.statusSelect')}
              onChange={(option: MultiValue<ISelectOption>) => {
                if (option !== null) {
                  const statusArray: Array<string> = []
                  option.forEach((itemOption: ISelectOption) => {
                    if (itemOption.value) {
                      statusArray.push(itemOption.value)
                    }
                  })
                  setStatus(statusArray)
                  if (option.length !== statusArray.length) {
                    option.forEach((itemOption: ISelectOption) => {
                      const filteredArray = statusArray.filter((item) => item !== itemOption.value)
                      setStatus(filteredArray)
                    })
                  }
                  setUseCreatedDate(true)
                }
              }}
            />
          </FormRow>
          {
            !status?.length && (
              <FormRow title={t('export.dateFilter')}>
                <Select
                  options={dateFilter}
                  defaultValue={dateFilter[1]}
                  className="select"
                  classNamePrefix="select"
                  menuPlacement="auto"
                  isSearchable={false}
                  isDisabled={loading}
                  placeholder={t('export.dateFilter')}
                  onChange={(option: ISelectOption | null) => {
                    if (option !== null) {
                      if (option.label === 'Modified') {
                        setUseCreatedDate(false)
                      } else {
                        setUseCreatedDate(true)
                      }
                    }
                  }}
                />
              </FormRow>
            )
          }
          <FormRow title={t('export.profileFilter')}>
            <Select
              options={declarationProfiles}
              defaultValue={declarationProfiles[0]}
              className="select"
              classNamePrefix="select"
              menuPlacement="auto"
              isSearchable={false}
              isDisabled={loading}
              isMulti
              placeholder={t('export.profileFilter')}
              onChange={(option: MultiValue<ISelectOption>) => {
                const profileArray: Array<string> = []
                option.forEach((itemOption: ISelectOption) => {
                  profileArray.push(itemOption.label)
                })
                setProfiles(profileArray)
                if (option.length !== profileArray.length) {
                  option.forEach((itemOption: ISelectOption) => {
                    const filteredArray = profileArray.filter((item) => item !== itemOption.value)
                    setProfiles(filteredArray)
                  })
                }
              }}
            />
          </FormRow>
          <FormRow title={t('export.calendarFilter')}>
            <DateRangePicker
              locale={i18n.language === 'ru' ? ru : enGB}
              weekStartsOn={1}
              ranges={[selectionRange]}
              onChange={handleSelect}
            />
          </FormRow>
          <div className="mt-2">
            <p>
              {t('export.tutorial', { 0: user?.email })}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={loading}
          className="btn btn-secondary btn-lg text-primary"
          onClick={() => postExportRequest()}
        >
          {
            loading && <i className="far fa-spinner-third fa-spin me-2" />
          }
          {
            !loading && <i className="far fa-plus me-2" />
          }
          <span>
            {t('export.queueExport')}
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default Export
