import React from 'react'
import DirectoEvent from './DirectoEvent'

function DirectoTab() {
  return (
    <DirectoEvent />
  )
}

export default DirectoTab
