import { Modal } from 'react-bootstrap'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface CustomsConfirmationModalProps {
  confirmationModalVisible: boolean;
  setConfirmationModalVisible: Dispatch<SetStateAction<boolean>>;
  confirmationModalText: () => string;

  customsActionConfirmed: () => void;
  invalidFormFields: Array<string>;
}

function CustomsConfirmationModal(props: CustomsConfirmationModalProps) {
  const {
    confirmationModalVisible, setConfirmationModalVisible, confirmationModalText, customsActionConfirmed, invalidFormFields,
  } = props
  const { t } = useTranslation()

  return (
    <Modal
      show={confirmationModalVisible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal customs-modal"
      onHide={() => setConfirmationModalVisible(false)}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-uppercase">{t('common.confirmAction')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setConfirmationModalVisible(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="m-4">
          {invalidFormFields.length > 0
            ? (<p className="text-dark fw-bold">{t('messages.invalidSubmissionText')}</p>)
            : (
              <p className={`fw-bold ${invalidFormFields.length === 0 ? 'd-inline' : ''}`}>
                {t('messages.confirmSubmissionText')}
              </p>
            )}

          {invalidFormFields.length > 0 && (
            <div className="mt-4">
              {invalidFormFields.map((invalidField) => (
                <p key={invalidField} className="text-danger">{invalidField}</p>
              ))}
            </div>
          )}
          <p className={`fw-bold ${invalidFormFields.length === 0 ? 'd-inline' : ''}`}>
            {invalidFormFields.length === 0 && confirmationModalText()}
          </p>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          disabled={invalidFormFields.length > 0}
          onClick={() => customsActionConfirmed()}
        >
          <i className="fal fa-reply fa-flip-horizontal me-2" />
          <span>{t('buttons.confirm')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomsConfirmationModal
