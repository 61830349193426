import { IDeclaration } from '../../types/IDeclaration'
import { IAction } from '../actionTypes'

interface IDeclarationReducerState {
  declaration: IDeclaration | null
}

const initialState: IDeclarationReducerState = {
  declaration: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function declarationReducer(state = initialState, action: IAction<IDeclaration>) {
  switch (action.type) {
    case 'ADD_DECLARATION':
      return {
        ...state,
        declaration: action.payload,
      }
    default:
      return state
  }
}
