import React from 'react'

function Terms() {
  return (
    <div className="terms-and-conditions" id="terms-and-conditions">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="terms-and-conditions__title text-center pt-5 mt-5">Terms and Conditions</h2>
            <p>
              These Website Standard Terms and Conditions written on this webpage shall manage your use of
              our website, Tardek T1, accessible at https://t1.tardek.com
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="text-center">
              By using our Website and Software, you accepted these terms and
              conditions in full. If you disagree
              with these terms and conditions or any part of these terms and conditions, you must not use
              our
              Website or Software.
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="text-center">Intellectual property rights</h3>
            <p>
              Unless otherwise stated, Tardek OÜ or its licensors own the intellectual property rights in
              the website
              and software and material on the website and within the software. Subject to the license
              below, all
              these intellectual property rights are reserved.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="text-center">License to use website and software</h3>
            <p>
              You may use the software and access the website for your own personal use, subject to the
              restrictions
              set out below and elsewhere in these terms and conditions.
            </p>
            <p>
              You must not:
            </p>
            <ul>
              <li>
                use the software for any illegal or unauthorized purpose.
              </li>
              <li>
                in the use of the
                software, violate any laws in your jurisdiction (including but not limited to copyright
                laws).
              </li>
              <li>
                republish material from this website (including republication on another website);
              </li>
              <li>
                sell, rent or sub-license the software or material on the website;
              </li>
              <li>
                show any material from the website or software in public;
              </li>
              <li>
                reproduce, duplicate, copy or otherwise exploit material on our website or within the
                software for a
                commercial purpose;
              </li>
              <li>
                edit or otherwise modify any material on the website or within the software; or
              </li>
              <li>
                redistribute material from this website or software except for content specifically and
                expressly made
                available for redistribution. Where content is specifically made available for
                redistribution, it may
                only be redistributed within your organization.
              </li>
              <li>
                use the software to transmit or send unsolicited commercial communications.
              </li>
              <li>
                use the software for any purposes related to marketing without the express written
                consent
                of Tardek OÜ.
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="text-center">Restricted access</h3>
            <p>
              Tardek OÜ reserves the right to restrict access to the software or areas of our website, or
              indeed our
              whole website and software, at our discretion and without notice.
            </p>
            <p>
              If Tardek OÜ provides you with a user ID and password to enable you to access restricted
              areas of the
              software or website, or other content or services, you must ensure that the user ID and
              password are
              kept confidential at all times.
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12">
            <h3 className="text-center">Your content</h3>
            <p>
              In these terms and conditions, &quot;your content&quot; means material (including without limitation
              text,
              images,
              audio material, video material and audio-visual material) that you submit to the website,
              for
              whatever
              purpose.
            </p>
            <p>
              You grant to Tardek OÜ a worldwide, irrevocable, non-exclusive, royalty-free license to use,
              reproduce,
              adapt, publish, translate and distribute your content in any existing or future media. You
              also grant to
              Tardek OÜ the right to sub-license these rights, and the right to bring an action for
              infringement of
              these rights.
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Terms
