/* eslint-disable max-len */
interface Error {
  code: string
  en: string
  ru: string
}

interface Messages {
  errors: Array<Error>
}

const messages: Messages = {
  errors: [
    {
      code: 'C001',
      en: 'IF ‘Country of Destination’ (box 17a) on Transit Operation level contains one of the Contracting Parties, or AD or SM\n'
        + 'THEN TRADER Consignee (box 8) = ‘R’\n'
        + 'ELSE TRADER Consignee (box 8) = ‘O’.\n'
        + 'Contracting parties are : the individual non EU countries of the Convention on Common\n'
        + 'Transit procedure and EU as a group.',
      ru: 'IF ‘Country of Destination’ (box 17a) on Transit Operation level contains one of the Contracting Parties, or AD or SM\n'
        + 'THEN TRADER Consignee (box 8) = ‘R’\n'
        + 'ELSE TRADER Consignee (box 8) = ‘O’.\n'
        + 'Contracting parties are : the individual non EU countries of the Convention on Common\n'
        + 'Transit procedure and EU as a group.',
    },
    {
      code: 'C002',
      en: 'IF ‘Country of Destination’ (ex box 17a) on GOODS ITEM level contains one of the Contracting Parties (see definition in C1), or AD or SM\n'
        + 'THEN TRADER Consignee (ex box 8) = ‘R’ ELSE TRADER Consignee (ex box 8) = ‘O’.',
      ru: 'IF ‘Country of Destination’ (ex box 17a) on GOODS ITEM level contains one of the Contracting Parties (see definition in C1), or AD or SM\n'
        + 'THEN TRADER Consignee (ex box 8) = ‘R’ ELSE TRADER Consignee (ex box 8) = ‘O’.',
    },
    {
      code: 'C004',
      en: 'IF the recovery request message is sent by country of departure AND \'Guarantee type\' is 1, 2, 4 or 9,\n'
        + 'THEN \'GUARANTEE INFORMATION\' = \'R\' ELSE \'GUARANTEE INFORMATION\' = \'O\'.',
      ru: 'IF the recovery request message is sent by country of departure AND \'Guarantee type\' is 1, 2, 4 or 9,\n'
        + 'THEN \'GUARANTEE INFORMATION\' = \'R\' ELSE \'GUARANTEE INFORMATION\' = \'O\'.',
    },
    {
      code: 'C005',
      en: 'IF first digit of ‘Inland mode of transport’ (box 26) = ‘5’ or ‘7’\n'
        + 'THEN Identity at Departure (box 18) can not be used.',
      ru: 'IF first digit of ‘Inland mode of transport’ (box 26) = ‘5’ or ‘7’\n'
        + 'THEN Identity at Departure (box 18) can not be used.',
    },
    {
      code: 'C006',
      en: 'IF first digit of ‘Inland mode of transport’ (box 26) = ‘2’, ‘5’ or ‘7’\n'
        + 'THEN ‘Nationality at Departure’ (box 18) can not be used.',
      ru: 'IF first digit of ‘Inland mode of transport’ (box 26) = ‘2’, ‘5’ or ‘7’\n'
        + 'THEN ‘Nationality at Departure’ (box 18) can not be used.',
    },
    {
      code: 'C007',
      en: 'IF \'Information (on paper) available\' is \'1\' (\'yes\') THEN \'Information text\' = \'R\'\n'
        + 'ELSE \'Information text\' = can not be used.',
      ru: 'IF \'Information (on paper) available\' is \'1\' (\'yes\') THEN \'Information text\' = \'R\'\n'
        + 'ELSE \'Information text\' = can not be used.',
    },
    {
      code: 'C008',
      en: 'IF the Competent Authority of the Country of Departure is equal to the Customs Office of Departure\n'
        + 'THEN \'CUSTOMS OFFICE of Departure\' may not be used ELSE \'CUSTOMS OFFICE of Departure\'= \'R\'.',
      ru: 'IF the Competent Authority of the Country of Departure is equal to the Customs Office of Departure\n'
        + 'THEN \'CUSTOMS OFFICE of Departure\' may not be used ELSE \'CUSTOMS OFFICE of Departure\'= \'R\'.',
    },
    {
      code: 'C009',
      en: 'IF \'TRADER Actual Consignee\' in IE141 is used\n'
        + 'THEN \'TRADER Actual Consignee\' = \'R\'\n'
        + 'ELSE \'TRADER Actual Consignee\' = can not be used.',
      ru: 'IF \'TRADER Actual Consignee\' in IE141 is used\n'
        + 'THEN \'TRADER Actual Consignee\' = \'R\'\n'
        + 'ELSE \'TRADER Actual Consignee\' = can not be used.',
    },
    {
      code: 'C010',
      en: 'IF first digit of ‘Transport mode at the border’ (box 25) = ‘2’, ‘5’ or ‘7’ THEN ‘Nationality crossing border’ (box 21) = ‘O’\n'
        + 'ELSE ‘Nationality crossing border’ (box 21) = ‘R’.',
      ru: 'IF first digit of ‘Transport mode at the border’ (box 25) = ‘2’, ‘5’ or ‘7’ THEN ‘Nationality crossing border’ (box 21) = ‘O’\n'
        + 'ELSE ‘Nationality crossing border’ (box 21) = ‘R’.',
    },
    {
      code: 'C011',
      en: 'IF \'Nationality crossing border\' is present or if \'Special circumstance indicator\' is = \'D\' THEN the attribute = \'R\'\n'
        + 'ELSE the attribute = \'O\'.',
      ru: 'IF \'Nationality crossing border\' is present or if \'Special circumstance indicator\' is = \'D\' THEN the attribute = \'R\'\n'
        + 'ELSE the attribute = \'O\'.',
    },
    {
      code: 'C012',
      en: 'IF \'Response Code\' = \'3\'\n'
        + 'THEN \'Return Copy returned on\' = \'R\'\n'
        + 'ELSE \'Return Copy returned on\' = can not be used.',
      ru: 'IF \'Response Code\' = \'3\'\n'
        + 'THEN \'Return Copy returned on\' = \'R\'\n'
        + 'ELSE \'Return Copy returned on\' = can not be used.',
    },
    {
      code: 'C013',
      en: 'IF \'Response Code\' = \'2\' or \'4\'\n'
        + 'THEN \' Response Information\' = \'R\'\n'
        + 'ELSE \'Response Information\' = can not be used.\n',
      ru: 'IF \'Response Code\' = \'2\' or \'4\'\n'
        + 'THEN \' Response Information\' = \'R\'\n'
        + 'ELSE \'Response Information\' = can not be used.\n',
    },
    {
      code: 'C014',
      en: 'IF sending country is country of guarantee and if \'Guarantee type\' is \'1\', \'2\', \'4\', or \'9\' THEN \'GUARANTOR\' = \'R\'\n'
        + 'ELSE \'GUARANTOR\' = \'O\'.',
      ru: 'IF sending country is country of guarantee and if \'Guarantee type\' is \'1\', \'2\', \'4\', or \'9\' THEN \'GUARANTOR\' = \'R\'\n'
        + 'ELSE \'GUARANTOR\' = \'O\'.',
    },
    {
      code: 'C015',
      en: 'IF SGI-CODES is used\n'
        + 'THEN ‘Commodity Code’ (box 33) = ‘R’\n'
        + 'ELSE ‘Commodity Code’ (box 33) = ‘O’.',
      ru: 'IF SGI-CODES is used\n'
        + 'THEN ‘Commodity Code’ (box 33) = ‘R’\n'
        + 'ELSE ‘Commodity Code’ (box 33) = ‘O’.',
    },
    {
      code: 'C025',
      en: 'IF the recovery acceptance notification is sent by the country of departure AND \'ACCEPTANCE\' flag is set to \'1\' (yes) AND Guarantee type is \'1\', \'2\', \'4\' or \'9\'\n'
        + 'THEN \'GUARANTEE INFORMATION\'  R ELSE \'GUARANTEE INFORMATION\'  O',
      ru: 'IF the recovery acceptance notification is sent by the country of departure AND \'ACCEPTANCE\' flag is set to \'1\' (yes) AND Guarantee type is \'1\', \'2\', \'4\' or \'9\'\n'
        + 'THEN \'GUARANTEE INFORMATION\'  R ELSE \'GUARANTEE INFORMATION\'  O',
    },
    {
      code: 'C026',
      en: 'IF the data group \'CTL_CONTROL\' is present\n'
        + 'THEN this data group/attribute can not be used\n'
        + 'ELSE\n'
        + 'IF this data group/attribute is conditional based on others conditions\n'
        + 'THEN these other conditions apply ELSE this data group/attribute = \'O\'',
      ru: 'IF the data group \'CTL_CONTROL\' is present\n'
        + 'THEN this data group/attribute can not be used\n'
        + 'ELSE\n'
        + 'IF this data group/attribute is conditional based on others conditions\n'
        + 'THEN these other conditions apply ELSE this data group/attribute = \'O\'',
    },
    {
      code: 'C027',
      en: 'IF the data group \'CTL_CONTROL\' is present\n'
        + 'THEN this data group/attribute can not be used\n'
        + 'ELSE this data group/attribute =\'R\'',
      ru: 'IF the data group \'CTL_CONTROL\' is present\n'
        + 'THEN this data group/attribute can not be used\n'
        + 'ELSE this data group/attribute =\'R\'',
    },
    {
      code: 'C030',
      en: 'IF there are different Contracting parties (see definition in C1) declared for Departure (identified by the Office of Departure, box C) and Destination (identified by the Office of Destination, box 53), or the country code of either Office of Departure or Destination is AD or SM\n'
        + 'THEN at least one ‘CUSTOMS OFFICE of Transit’ (box 51) = ‘R’ ELSE ‘CUSTOMS OFFICE of Transit’ (box 51) = ‘O’.',
      ru: 'IF there are different Contracting parties (see definition in C1) declared for Departure (identified by the Office of Departure, box C) and Destination (identified by the Office of Destination, box 53), or the country code of either Office of Departure or Destination is AD or SM\n'
        + 'THEN at least one ‘CUSTOMS OFFICE of Transit’ (box 51) = ‘R’ ELSE ‘CUSTOMS OFFICE of Transit’ (box 51) = ‘O’.',
    },
    {
      code: 'C035',
      en: 'IF ‘Declaration type’ (box 1) or ‘Declaration type’ (ex box 1) = ‘T2’,’T2F’ and if the Country of the Office of Departure, identified by the first 2 digits of the ‘CUSTOMS OFFICE of Departure Reference Number’ (Box C) = is a non EU Contracting party\n'
        + 'THEN ‘PREVIOUS ADMINISTRATIVE REFERENCES’ = ‘R’ ELSE ‘PREVIOUS ADMINISTRATIVE REFERENCES’ = ‘O’.',
      ru: 'IF ‘Declaration type’ (box 1) or ‘Declaration type’ (ex box 1) = ‘T2’,’T2F’ and if the Country of the Office of Departure, identified by the first 2 digits of the ‘CUSTOMS OFFICE of Departure Reference Number’ (Box C) = is a non EU Contracting party\n'
        + 'THEN ‘PREVIOUS ADMINISTRATIVE REFERENCES’ = ‘R’ ELSE ‘PREVIOUS ADMINISTRATIVE REFERENCES’ = ‘O’.',
    },
    {
      code: 'C037',
      en: 'IF request / response concerns NCTS authorised consignee\n'
        + 'THEN ‘TRADER Authorised Consignee.TIN =’R’,\n'
        + '‘TRADER Authorised Consignee.Holder ID TIR’ can not be used\n'
        + 'ELSE ‘TRADER Authorised Consignee.Holder ID TIR’=’R’ ‘TRADER Authorised Consignee.TIN can not be used',
      ru: 'IF request / response concerns NCTS authorised consignee\n'
        + 'THEN ‘TRADER Authorised Consignee.TIN =’R’,\n'
        + '‘TRADER Authorised Consignee.Holder ID TIR’ can not be used\n'
        + 'ELSE ‘TRADER Authorised Consignee.Holder ID TIR’=’R’ ‘TRADER Authorised Consignee.TIN can not be used',
    },
    {
      code: 'C045',
      en: 'IF ‘Declaration type’ (box 1) = ‘T-’\n'
        + 'THEN ‘Declaration type’ (ex box 1) = ‘R’ (T1, T2, T2F or T2SM)\n'
        + 'ELSE ‘Declaration type’ (ex box 1) can not be used.\n',
      ru: 'IF ‘Declaration type’ (box 1) = ‘T-’\n'
        + 'THEN ‘Declaration type’ (ex box 1) = ‘R’ (T1, T2, T2F or T2SM)\n'
        + 'ELSE ‘Declaration type’ (ex box 1) can not be used.\n',
    },
    {
      code: 'C050',
      en: 'IF ‘TIN’ (box 50) is used\n'
        + 'THEN all attributes of name and address (NAD) (box50) = ‘O’ if\n'
        + 'already known by NCTS\n'
        + 'ELSE all attributes of name and address (NAD) (box50) = ‘R’.',
      ru: 'IF ‘TIN’ (box 50) is used\n'
        + 'THEN all attributes of name and address (NAD) (box50) = ‘O’ if\n'
        + 'already known by NCTS\n'
        + 'ELSE all attributes of name and address (NAD) (box50) = ‘R’.',
    },
    {
      code: 'C055',
      en: 'IF ‘Container’ (box 19) = ‘1’\n'
        + 'THEN ‘CONTAINERS (box 31)’ must be present in at least one data\n'
        + 'group \'GOODS ITEM\'\n'
        + 'ELSE ‘CONTAINERS (box 31)’ can not be used.',
      ru: 'IF ‘Container’ (box 19) = ‘1’\n'
        + 'THEN ‘CONTAINERS (box 31)’ must be present in at least one data\n'
        + 'group \'GOODS ITEM\'\n'
        + 'ELSE ‘CONTAINERS (box 31)’ can not be used.',
    },
    {
      code: 'C060',
      en: 'IF ‘Kind of packages’ (Box 31) indicates ‘BULK’ (UNECE rec 21 : ‘VQ’, ‘VG’, ‘VL’, ‘VY’,’VR’,\'VS\' or ‘VO’)\n'
        + 'THEN ‘Marks & numbers of packages’ (Box 31) = ‘O’ ‘Number of packages’ (box 31) can not be used\n'
        + '‘Number of Pieces’ (box 31) can not be used\n'
        + 'ELSE\n'
        + 'IF ‘Kind of packages’ (Box 31) indicates ‘UNPACKED’ (UNECE rec 21 : = ‘NE’, \'NF\' or\n'
        + '   NG\')\n'
        + 'THEN ‘Marks & numbers of packages’ (Box 31) = ‘O’ ‘Number of packages’ can not be used\n'
        + '‘Number of Pieces’ (box 31) = ‘R’\n'
        + 'ELSE ‘Marks & numbers of packages’ (Box 31) = ‘R’\n'
        + '‘Number of packages’ (box 31) = ‘R’ ‘Number of Pieces’ (box 31) can not be used.',
      ru: 'IF ‘Kind of packages’ (Box 31) indicates ‘BULK’ (UNECE rec 21 : ‘VQ’, ‘VG’, ‘VL’, ‘VY’,’VR’,\'VS\' or ‘VO’)\n'
        + 'THEN ‘Marks & numbers of packages’ (Box 31) = ‘O’ ‘Number of packages’ (box 31) can not be used\n'
        + '‘Number of Pieces’ (box 31) can not be used\n'
        + 'ELSE\n'
        + 'IF ‘Kind of packages’ (Box 31) indicates ‘UNPACKED’ (UNECE rec 21 : = ‘NE’, \'NF\' or\n'
        + '   NG\')\n'
        + 'THEN ‘Marks & numbers of packages’ (Box 31) = ‘O’ ‘Number of packages’ can not be used\n'
        + '‘Number of Pieces’ (box 31) = ‘R’\n'
        + 'ELSE ‘Marks & numbers of packages’ (Box 31) = ‘R’\n'
        + '‘Number of packages’ (box 31) = ‘R’ ‘Number of Pieces’ (box 31) can not be used.',
    },
    {
      code: 'C075',
      en: 'IF ‘Additional Information id’ (Box 44) = ‘DG0’ or ‘DG1’\n'
        + 'THEN ‘Export from EC’ or ‘Export from country’ (Box 44) = ‘R’\n'
        + 'ELSE ‘Export from EC’ and ‘Export from country’ (Box 44) cannot be used.',
      ru: 'IF ‘Additional Information id’ (Box 44) = ‘DG0’ or ‘DG1’\n'
        + 'THEN ‘Export from EC’ or ‘Export from country’ (Box 44) = ‘R’\n'
        + 'ELSE ‘Export from EC’ and ‘Export from country’ (Box 44) cannot be used.',
    },
    {
      code: 'C085',
      en: 'IF ‘Guarantee type’ = ‘0’, ‘1’, ‘2’, ‘4’,‘9’ or \'B\'\n'
        + 'THEN ‘GUARANTEE REFERENCE’ = ‘R’\n'
        + 'ELSE ‘GUARANTEE REFERENCE’ = ‘O’.',
      ru: 'IF ‘Guarantee type’ = ‘0’, ‘1’, ‘2’, ‘4’,‘9’ or \'B\'\n'
        + 'THEN ‘GUARANTEE REFERENCE’ = ‘R’\n'
        + 'ELSE ‘GUARANTEE REFERENCE’ = ‘O’.',
    },
    {
      code: 'C086',
      en: 'IF ‘Guarantee type’ = ‘0’, ‘1’, ‘2’, ‘4’ or ‘9’, THEN ‘Access code’ = ‘R’\n'
        + 'ELSE ‘Access code’ = ‘O’.',
      ru: 'IF ‘Guarantee type’ = ‘0’, ‘1’, ‘2’, ‘4’ or ‘9’, THEN ‘Access code’ = ‘R’\n'
        + 'ELSE ‘Access code’ = ‘O’.',
    },
    {
      code: 'C090',
      en: 'IF first digit of ‘Control result code’ = ‘B’\n'
        + 'THEN ‘Waiting for discrepancies resolution’ = ‘R’\n'
        + 'ELSE ‘Waiting for discrepancies resolution’ = ‘O’.',
      ru: 'IF first digit of ‘Control result code’ = ‘B’\n'
        + 'THEN ‘Waiting for discrepancies resolution’ = ‘R’\n'
        + 'ELSE ‘Waiting for discrepancies resolution’ = ‘O’.',
    },
    {
      code: 'C100',
      en: 'IF ‘CONTROL RESULT’ (Box D) is used\n'
        + 'THEN ‘Authorised location of goods’ = ‘O’\n'
        + '‘Customs sub place’ can not be used ‘Agreed location code’ can not be used ‘Agreed location of goods’ can not be used\n'
        + 'ELSE ‘Authorised location of goods’ can not be used ‘Agreed location code’ = ‘O’\n'
        + '‘Agreed location of goods’ = ‘O’ ‘Customs sub place’ = ‘O’.',
      ru: 'IF ‘CONTROL RESULT’ (Box D) is used\n'
        + 'THEN ‘Authorised location of goods’ = ‘O’\n'
        + '‘Customs sub place’ can not be used ‘Agreed location code’ can not be used ‘Agreed location of goods’ can not be used\n'
        + 'ELSE ‘Authorised location of goods’ can not be used ‘Agreed location code’ = ‘O’\n'
        + '‘Agreed location of goods’ = ‘O’ ‘Customs sub place’ = ‘O’.',
    },
    {
      code: 'C111',
      en: 'IF ‘CONTROL RESULT’ is present (Simplified procedure) or\n'
        + 'IF \'Specific circumstance indicator \'E\' is present and the first two digits of CUSTOMS\n'
        + 'OFFICE.Office of Departure consist of an EU Member State country code THEN ‘TIN’ = ‘R’\n'
        + 'ELSE ‘TIN’ = ‘O’.',
      ru: 'IF ‘CONTROL RESULT’ is present (Simplified procedure) or\n'
        + 'IF \'Specific circumstance indicator \'E\' is present and the first two digits of CUSTOMS\n'
        + 'OFFICE.Office of Departure consist of an EU Member State country code THEN ‘TIN’ = ‘R’\n'
        + 'ELSE ‘TIN’ = ‘O’.',
    },
    {
      code: 'C112',
      en: 'IF ‘CONTROL RESULT’ is present (Simplified procedure) or\n'
        + 'IF the first two digits of \'CUSTOMS OFFICE of Destination.Reference number\' consist of an\n'
        + 'EU Member State country code THEN ‘TIN’ = ‘R’\n'
        + 'ELSE ‘TIN’ = ‘O’.',
      ru: 'IF ‘CONTROL RESULT’ is present (Simplified procedure) or\n'
        + 'IF the first two digits of \'CUSTOMS OFFICE of Destination.Reference number\' consist of an\n'
        + 'EU Member State country code THEN ‘TIN’ = ‘R’\n'
        + 'ELSE ‘TIN’ = ‘O’.',
    },
    {
      code: 'C113',
      en: 'IF the first two digits of \'CUSTOMS OFFICE of Destination.Reference number\' consist of an EU Member State country code\n'
        + 'THEN ‘TIN’ = ‘R’ ELSE ‘TIN’ = ‘O’.',
      ru: 'IF the first two digits of \'CUSTOMS OFFICE of Destination.Reference number\' consist of an EU Member State country code\n'
        + 'THEN ‘TIN’ = ‘R’ ELSE ‘TIN’ = ‘O’.',
    },
    {
      code: 'C125',
      en: 'IF ‘guarantee type’ = ‘0’, ‘1’, ‘2’, ‘4’ or ‘9’ THEN ‘GRN’ = ‘R’\n'
        + 'ELSE ‘GRN’ can not be used.\n',
      ru: 'IF ‘guarantee type’ = ‘0’, ‘1’, ‘2’, ‘4’ or ‘9’ THEN ‘GRN’ = ‘R’\n'
        + 'ELSE ‘GRN’ can not be used.\n',
    },
    {
      code: 'C130',
      en: 'IF ‘GRN’ is NOT used\n'
        + 'THEN ‘Other guarantee reference’ = ‘R’\n'
        + 'ELSE ‘Other guarantee reference’ can not be used.',
      ru: 'IF ‘GRN’ is NOT used\n'
        + 'THEN ‘Other guarantee reference’ = ‘R’\n'
        + 'ELSE ‘Other guarantee reference’ can not be used.',
    },
    {
      code: 'C135',
      en: 'IF only 1 Country of dispatch is declared\n'
        + 'THEN ‘Country of dispatch (box 15a)’ on TRANSIT OPERATION\n'
        + 'level = ‘R’, ‘Country of dispatch (ex box 15a)’ on\n'
        + 'GOODS ITEM level cannot be used\n'
        + 'ELSE ‘Country of dispatch (box 15a)’ on TRANSIT OPERATION\n'
        + 'level cannot be used, ‘Country of dispatch (ex box 15a)’ on GOODS ITEM level = ‘R’.',
      ru: 'IF only 1 Country of dispatch is declared\n'
        + 'THEN ‘Country of dispatch (box 15a)’ on TRANSIT OPERATION\n'
        + 'level = ‘R’, ‘Country of dispatch (ex box 15a)’ on\n'
        + 'GOODS ITEM level cannot be used\n'
        + 'ELSE ‘Country of dispatch (box 15a)’ on TRANSIT OPERATION\n'
        + 'level cannot be used, ‘Country of dispatch (ex box 15a)’ on GOODS ITEM level = ‘R’.',
    },
    {
      code: 'C140',
      en: 'IF only 1 Country of destination is declared\n'
        + 'THEN ‘Destination country (box 17a)’ on TRANSIT OPERATION\n'
        + 'level = ‘R’, ‘Destination country (ex box 17a)’ on\n'
        + 'GOODS ITEM level cannot be used\n'
        + 'ELSE ‘Destination country (box 17a)’ on TRANSIT OPERATION\n'
        + 'level cannot be used, ‘Destination country (ex box 17a) GOODS ITEM level = ‘R’.',
      ru: 'IF only 1 Country of destination is declared\n'
        + 'THEN ‘Destination country (box 17a)’ on TRANSIT OPERATION\n'
        + 'level = ‘R’, ‘Destination country (ex box 17a)’ on\n'
        + 'GOODS ITEM level cannot be used\n'
        + 'ELSE ‘Destination country (box 17a)’ on TRANSIT OPERATION\n'
        + 'level cannot be used, ‘Destination country (ex box 17a) GOODS ITEM level = ‘R’.',
    },
    {
      code: 'C155',
      en: 'IF ‘Simplified procedure flag’ = ‘YES’ THEN ‘Arrival agreed location of goods’ can not be used ‘Arrival agreed location code’ can not be used ‘Customs sub place’ can not be used\n'
        + '‘Arrival authorised location of goods’ = ‘O’ ELSE ‘Arrival authorised location of goods’ can not be used ‘Customs sub place’ = ‘O’\n'
        + '‘Arrival agreed location of goods’ = ‘O’ ‘Arrival agreed location code’ = ‘O’.',
      ru: 'IF ‘Simplified procedure flag’ = ‘YES’ THEN ‘Arrival agreed location of goods’ can not be used ‘Arrival agreed location code’ can not be used ‘Customs sub place’ can not be used\n'
        + '‘Arrival authorised location of goods’ = ‘O’ ELSE ‘Arrival authorised location of goods’ can not be used ‘Customs sub place’ = ‘O’\n'
        + '‘Arrival agreed location of goods’ = ‘O’ ‘Arrival agreed location code’ = ‘O’.',
    },
    {
      code: 'C160',
      en: 'IF TIN of Trader is used \n THEN all attributes marked with this Condition = ‘O’ if already known by NCTS \n ELSE all attributes marked with this condition = ‘R’.',
      ru: 'IF TIN of Trader is used \n THEN all attributes marked with this Condition = ‘O’ if already known by NCTS \n ELSE all attributes marked with this condition = ‘R’.',
    },
    {
      code: 'C170',
      en: 'IF ‘Cancellation initiated by Customs’ = ‘Yes’ \n THEN ‘Cancellation Decision’ can not be used \n ELSE ‘Cancellation Decision’ = ‘R’.',
      ru: 'IF ‘Cancellation initiated by Customs’ = ‘Yes’ \n THEN ‘Cancellation Decision’ can not be used \n ELSE ‘Cancellation Decision’ = ‘R’.',
    },
    {
      code: 'C180',
      en: 'IF ‘Cancellation initiated by Customs’ = ‘No’ AND ‘Cancellation Decision’ = ‘0’ (=N0)) OR (‘Cancellation initiated by Customs’ = ‘Yes’) \n THEN ‘Cancellation Decision’ can not be used \n ELSE ‘Cancellation Decision’ = ‘R’.',
      ru: 'IF ‘Cancellation initiated by Customs’ = ‘No’ AND ‘Cancellation Decision’ = ‘0’ (=N0)) OR (‘Cancellation initiated by Customs’ = ‘Yes’) \n THEN ‘Cancellation Decision’ can not be used \n ELSE ‘Cancellation Decision’ = ‘R’.',
    },
    {
      code: 'C185',
      en: 'IF first digit of ‘Control result code’ = ‘A’\n'
        + 'AND second digit of ‘Control result code’ = ‘1’ or ‘2’ (‘Satisfactory’ or ‘Considered\n'
        + 'satisfactory’)\n'
        + 'THEN All data groups and attributes marked with ‘Cond 185’ can not\n'
        + 'be used\n'
        + 'ELSE All data groups and attributes marked with ‘Cond 185’ = ‘R’\n'
        + 'when relevant.',
      ru: 'IF first digit of ‘Control result code’ = ‘A’\n'
        + 'AND second digit of ‘Control result code’ = ‘1’ or ‘2’ (‘Satisfactory’ or ‘Considered\n'
        + 'satisfactory’)\n'
        + 'THEN All data groups and attributes marked with ‘Cond 185’ can not\n'
        + 'be used\n'
        + 'ELSE All data groups and attributes marked with ‘Cond 185’ = ‘R’\n'
        + 'when relevant.',
    },
    {
      code: 'C186',
      en: 'IF the attribute \'TRANSIT OPERATION.Security\' is not present THEN the data group/attribute can not be used\n'
        + 'ELSE the data group/attribute is by default = \'O\', except where additional conditions or rules for the same data group/attribute imply something else.',
      ru: 'IF the attribute \'TRANSIT OPERATION.Security\' is not present THEN the data group/attribute can not be used\n'
        + 'ELSE the data group/attribute is by default = \'O\', except where additional conditions or rules for the same data group/attribute imply something else.',
    },
    {
      code: 'C187',
      en: 'IF the attribute \'TRANSIT OPERATION.Security\' is set to \'1\'\n'
        + 'THEN the data group \'TRADER Consignor – security\' on TRANSIT\n'
        + 'OPERATION level or TRADER Consignor – security\' on GOODS ITEM level must be present.',
      ru: 'IF the attribute \'TRANSIT OPERATION.Security\' is set to \'1\'\n'
        + 'THEN the data group \'TRADER Consignor – security\' on TRANSIT\n'
        + 'OPERATION level or TRADER Consignor – security\' on GOODS ITEM level must be present.',
    },
    {
      code: 'C188',
      en: 'IF the attribute \'TRANSIT OPERATION.Security\' is set to \'1\' and the attribute \'SPECIAL'
        + 'MENTION.Additional information_id\' = \'10600\' is used for at least one GOODS ITEM THEN the data group \'TRADER Consignee - security\' and\n'
        + 'the data group \'GOODS ITEM.TRADER Consignee - security\'\n'
        + 'can not be used\n'
        + 'ELSE the data group \'TRADER Consignee – security\' on TRANSIT\n'
        + 'OPERATION level or TRADER Consignee – security\' on GOODS ITEM level must be present.',
      ru: 'IF the attribute \'TRANSIT OPERATION.Security\' is set to \'1\' and the attribute \'SPECIAL'
        + 'MENTION.Additional information_id\' = \'10600\' is used for at least one GOODS ITEM THEN the data group \'TRADER Consignee - security\' and\n'
        + 'the data group \'GOODS ITEM.TRADER Consignee - security\'\n'
        + 'can not be used\n'
        + 'ELSE the data group \'TRADER Consignee – security\' on TRANSIT\n'
        + 'OPERATION level or TRADER Consignee – security\' on GOODS ITEM level must be present.',
    },
    {
      code: 'C191',
      en: 'IF the attribute \'TRANSIT OPERATION.Security\' is set to \'1\'\n'
        + 'THEN the data group/attribute must be present\n'
        + 'ELSE the data group/attribute = \'O\'.',
      ru: 'IF the attribute \'TRANSIT OPERATION.Security\' is set to \'1\'\n'
        + 'THEN the data group/attribute must be present\n'
        + 'ELSE the data group/attribute = \'O\'.',
    },
    {
      code: 'C200',
      en: 'IF ‘State of seals ok’ = ‘NO’\n'
        + 'THEN SEALS INFO = ‘R’\n'
        + 'ELSE SEALS INFO = ‘O’.',
      ru: 'IF ‘State of seals ok’ = ‘NO’\n'
        + 'THEN SEALS INFO = ‘R’\n'
        + 'ELSE SEALS INFO = ‘O’.',
    },
    {
      code: 'C210',
      en: 'IF UNLOADING RESULT, ‘Conform’ = ‘YES’ (NO remarks)\n'
        + 'THEN All data groups and attributes marked with ‘Cond 210’ cannot\n'
        + 'be used\n'
        + 'ELSE All data groups and attributes marked with ‘Cond 210’ = ‘R’\n'
        + 'when relevant.',
      ru: 'IF UNLOADING RESULT, ‘Conform’ = ‘YES’ (NO remarks)\n'
        + 'THEN All data groups and attributes marked with ‘Cond 210’ cannot\n'
        + 'be used\n'
        + 'ELSE All data groups and attributes marked with ‘Cond 210’ = ‘R’\n'
        + 'when relevant.',
    },
    {
      code: 'C215',
      en: 'IF ENQUIRY.Information (on paper) available = ‘1’ (= yes)\n'
        + 'THEN At least one of the data groups becomes ‘R’\n'
        + 'ELSE the 2 data groups can not be used.',
      ru: 'IF ENQUIRY.Information (on paper) available = ‘1’ (= yes)\n'
        + 'THEN At least one of the data groups becomes ‘R’\n'
        + 'ELSE the 2 data groups can not be used.',
    },
    {
      code: 'C220',
      en: 'IF ENQUIRY.TC11 delivered by Customs = ‘1’ (= yes)\n'
        + 'THEN ‘Delivery date information/TC11’ = ‘R’\n'
        + 'ELSE ‘Delivery date information/TC11’ can not be used.',
      ru: 'IF ENQUIRY.TC11 delivered by Customs = ‘1’ (= yes)\n'
        + 'THEN ‘Delivery date information/TC11’ = ‘R’\n'
        + 'ELSE ‘Delivery date information/TC11’ can not be used.',
    },
    {
      code: 'C226',
      en: 'IF \'Query identifier\' = \'1\' or \'3 and Guarantee type ‘0’, ‘1’ or ‘9’\n'
        + 'THEN \'Period from date\' and \'Period to date\' = \'R\'\n'
        + 'ELSE \'Period from date\' and \'Period to date\' can not be used.',
      ru: 'IF \'Query identifier\' = \'1\' or \'3 and Guarantee type ‘0’, ‘1’ or ‘9’\n'
        + 'THEN \'Period from date\' and \'Period to date\' = \'R\'\n'
        + 'ELSE \'Period from date\' and \'Period to date\' can not be used.',
    },
    {
      code: 'C227',
      en: 'IF ‘COUNTRY of guarantee’ is different from the country of the ‘requester’\n'
        + ' THEN ‘COUNTRY of guarantee’ = R \n ELSE ‘COUNTRY of guarantee’ = O.',
      ru: 'IF ‘COUNTRY of guarantee’ is different from the country of the ‘requester’\n'
        + ' THEN ‘COUNTRY of guarantee’ = R \n ELSE ‘COUNTRY of guarantee’ = O.',
    },
    {
      code: 'C230',
      en: 'IF ‘Guarantee type’ = ‘9’ (Individual guarantee with multiple usage) \n THEN the attribute = R \n ELSE the attribute = O',
      ru: 'IF ‘Guarantee type’ = ‘9’ (Individual guarantee with multiple usage) \n THEN the attribute = R \n ELSE the attribute = O',
    },
    {
      code: 'C231',
      en: 'IF ‘Guarantee type’ = ‘0’ or ‘1’ \n THEN the attribute = R \n ELSE attribute can not be used.',
      ru: '',
    },
    {
      code: 'C232',
      en: 'IF ‘TIR Carnet’ =’1’ \n THEN ‘Voucher amount’ and ‘Currency’ = R \n ELSE attribute can not be used.',
      ru: '',
    },
    {
      code: 'C233',
      en: 'IF in at least one occurrence of "GUARANTEE" ‘Guarantee type’ = ‘2’ THEN ‘CUSTOMS OFFICE of Destination’ = R\n'
        + 'ELSE can not be used.',
      ru: 'IF in at least one occurrence of "GUARANTEE" ‘Guarantee type’ = ‘2’ THEN ‘CUSTOMS OFFICE of Destination’ = R\n'
        + 'ELSE can not be used.',
    },
    {
      code: 'C234',
      en: 'IF ‘Guarantee type = 1, 2, 4 or 9\n'
        + 'THEN the data group is required\n'
        + 'ELSE can not be used.',
      ru: 'IF ‘Guarantee type = 1, 2, 4 or 9\n'
        + 'THEN the data group is required\n'
        + 'ELSE can not be used.',
    },
    {
      code: 'C235',
      en: 'IF ‘Guarantee type = 9\n'
        + 'THEN the attribute is required\n'
        + 'ELSE can not be used.',
      ru: 'IF ‘Guarantee type = 9\n'
        + 'THEN the attribute is required\n'
        + 'ELSE can not be used.',
    },
    {
      code: 'C236',
      en: 'IF attribute ‘GRN (Box 52)’ of data group ‘GUARANTEE REFERENCE’ is used '
        + '\n THEN the attribute = R \n ELSE the attribute = O.',
      ru: '',
    },
    {
      code: 'C270',
      en: 'IF ‘Query identifier’ = \'4\' (General information only) \n THEN data groups marked with this condition = \'R\' '
        + '\n ELSE data groups marked with this condition can not be used.',
      ru: '',
    },
    {
      code: 'C280',
      en: 'IF ‘Query identifier’ = \'2\' (Exposure only) or \'4\' (General information only) \n THEN  USAGE can not be used \n ELSE'
        + 'USAGE = \'R\'.',
      ru: '',
    },
    {
      code: 'C285',
      en: 'IF ‘Query identifier’ = \'1\' \'Guarantee type\' = ‘2’ or \'4\'\n'
        + '(Usage only) or \'4\' (General information only) or\n'
        + 'THEN EXPOSURE can not be used ELSE EXPOSURE = \'R\'.',
      ru: 'IF ‘Query identifier’ = \'1\' \'Guarantee type\' = ‘2’ or \'4\'\n'
        + '(Usage only) or \'4\' (General information only) or\n'
        + 'THEN EXPOSURE can not be used ELSE EXPOSURE = \'R\'.',
    },
    {
      code: 'C286',
      en: 'IF \'Guarantee Monitoring code\' = \'3\'\n'
        + 'THEN \'Balance\' = \'R\'\n'
        + 'ELSE \'Balance\' can not be used.',
      ru: 'IF \'Guarantee Monitoring code\' = \'3\'\n'
        + 'THEN \'Balance\' = \'R\'\n'
        + 'ELSE \'Balance\' can not be used.',
    },
    {
      code: 'C290',
      en: 'IF ‘Trader authorised’ = ‘1’ (authorised)\n'
        + 'THEN AUTHORISED GOODS LOCATION = \'R\'\n'
        + 'ELSE AUTHORISED GOODS LOCATION can not be used.',
      ru: 'IF ‘Trader authorised’ = ‘1’ (authorised)\n'
        + 'THEN AUTHORISED GOODS LOCATION = \'R\'\n'
        + 'ELSE AUTHORISED GOODS LOCATION can not be used.',
    },
    {
      code: 'C295',
      en: 'IF ‘Variable Holiday Date’ and/or ‘Variable Season Date’ = ‘1’ THEN ‘Year’ = ‘R’\n'
        + 'ELSE ‘Year’ can not be used.',
      ru: 'IF ‘Variable Holiday Date’ and/or ‘Variable Season Date’ = ‘1’ THEN ‘Year’ = ‘R’\n'
        + 'ELSE ‘Year’ can not be used.',
    },
    {
      code: 'C300',
      en: 'IF ‘Operation’ = ‘C’ OR ‘U’\n'
        + 'THEN All attributes marked with this condition = ‘R’\n'
        + 'ELSE All attributes marked with this condition = ‘O’.',
      ru: 'IF ‘Operation’ = ‘C’ OR ‘U’\n'
        + 'THEN All attributes marked with this condition = ‘R’\n'
        + 'ELSE All attributes marked with this condition = ‘O’.',
    },
    {
      code: 'C305',
      en: 'IF \'AAR-/AER-/Summary Declaration-/ Pre-Arrival item rejection reason code\' = \'4\' (other) THEN \'AAR/AER/Summary Declaration rejection reason\' = \'R\'\n'
        + 'ELSE \'AAR/AER/Summary Declaration rejection reason\' = \'O\'.',
      ru: 'IF \'AAR-/AER-/Summary Declaration-/ Pre-Arrival item rejection reason code\' = \'4\' (other) THEN \'AAR/AER/Summary Declaration rejection reason\' = \'R\'\n'
        + 'ELSE \'AAR/AER/Summary Declaration rejection reason\' = \'O\'.',
    },
    {
      code: 'C310',
      en: 'IF ‘Amendment rejection motivation code’ = ‘?’ (other) *\n'
        + 'THEN \'Amendment rejection motivation text\' = \'R\'\n'
        + 'ELSE \'Amendment rejection motivation text\' = ‘O’ *(To be defined at national level).',
      ru: 'IF ‘Amendment rejection motivation code’ = ‘?’ (other) *\n'
        + 'THEN \'Amendment rejection motivation text\' = \'R\'\n'
        + 'ELSE \'Amendment rejection motivation text\' = ‘O’ *(To be defined at national level).',
    },
    {
      code: 'C312',
      en: 'IF \'Diversion Rejection Code\'/\'Entry Rejection Motivation Code\' = \'4\' (Other) THEN \'Diversion Rejection Text\' = \'R\'\n'
        + 'ELSE ‘Diversion Rejection Text\' = \'O\'.',
      ru: 'IF \'Diversion Rejection Code\'/\'Entry Rejection Motivation Code\' = \'4\' (Other) THEN \'Diversion Rejection Text\' = \'R\'\n'
        + 'ELSE ‘Diversion Rejection Text\' = \'O\'.',
    },
    {
      code: 'C315',
      en: 'IF ‘TC11 delivered by Customs’ = ‘1’ (Yes)\n'
        + 'THEN ‘CUSTOMS OFFICE Presentation Office’ = ‘R’\n'
        + 'ELSE ‘CUSTOMS OFFICE Presentation Office’ = ‘O’.\n',
      ru: 'IF ‘TC11 delivered by Customs’ = ‘1’ (Yes)\n'
        + 'THEN ‘CUSTOMS OFFICE Presentation Office’ = ‘R’\n'
        + 'ELSE ‘CUSTOMS OFFICE Presentation Office’ = ‘O’.\n',
    },
    {
      code: 'C320',
      en: 'IF " Information Response Code" = "10", "40" or "50"\n'
        + 'THEN \'Additional Information Response Text\' = \'R\'\n'
        + 'ELSE \'Additional Information Response Text" cannot be used.',
      ru: 'IF " Information Response Code" = "10", "40" or "50"\n'
        + 'THEN \'Additional Information Response Text\' = \'R\'\n'
        + 'ELSE \'Additional Information Response Text" cannot be used.',
    },
    {
      code: 'C330',
      en: 'IF "Requested Information Code" = "5" or "6"\n'
        + 'THEN \'Requested Information Text\' = \'R\'\n'
        + 'ELSE \'Requested Information Text\' can not be used.',
      ru: 'IF "Requested Information Code" = "5" or "6"\n'
        + 'THEN \'Requested Information Text\' = \'R\'\n'
        + 'ELSE \'Requested Information Text\' can not be used.',
    },
    {
      code: 'C340',
      en: 'IF \'ATR rejection reason code\' = \'4\' (other)\n'
        + 'THEN \'ATR rejection reason\' = \'R\'\n'
        + 'ELSE \'ATR rejection reason\' = \'O\'.',
      ru: 'IF \'ATR rejection reason code\' = \'4\' (other)\n'
        + 'THEN \'ATR rejection reason\' = \'R\'\n'
        + 'ELSE \'ATR rejection reason\' = \'O\'.',
    },
    {
      code: 'C350',
      en: 'IF ‘Reference amount’ and or \'Guarantee amount\' is used THEN \'Currency\' = \'R\'\n'
        + 'ELSE \'Currency\' can not be used.',
      ru: '',
    },
    {
      code: 'C400',
      en: 'IF ‘Type’ = ‘S’ (Scheduled)\n'
        + 'THEN ‘Downtime TO’ = ‘R’\n'
        + 'ELSE ‘Downtime TO’ = ‘O’.\n',
      ru: 'IF ‘Type’ = ‘S’ (Scheduled)\n'
        + 'THEN ‘Downtime TO’ = ‘R’\n'
        + 'ELSE ‘Downtime TO’ = ‘O’.\n',
    },
    {
      code: 'C410',
      en: 'IF ‘Geo-Info Code’ contains “/” (i.e. the Customs office is situated at the frontier) THEN Attribute ‘Nearest Office’ = “R”\n'
        + 'ELSE Attribute ‘Nearest Office’ can not be used.\n',
      ru: 'IF ‘Geo-Info Code’ contains “/” (i.e. the Customs office is situated at the frontier) THEN Attribute ‘Nearest Office’ = “R”\n'
        + 'ELSE Attribute ‘Nearest Office’ can not be used.\n',
    },
    {
      code: 'C501',
      en: 'IF the attribute \'TIN\' is present in the data group\n'
        + 'THEN this attribute cannot be used\n'
        + 'ELSE this attribute = \'R\'.',
      ru: 'IF the attribute \'TIN\' is present in the data group\n'
        + 'THEN this attribute cannot be used\n'
        + 'ELSE this attribute = \'R\'.',
    },
    {
      code: 'C531',
      en: 'IF \'Security\' = \'1\' and Transport mode at border\' = \'4\'\n'
        + 'THEN the attribute is \'R\' and consists of the (IATA/ICAO) flight\n'
        + 'number and has format an..8:\n'
        + '-an..3: mandatory prefix identifying the airline/operator -n..4: mandatory number of the flight\n'
        + '-a1: optional suffix\n'
        + 'ELSE the attribute is \'O\' and the format of the attribute has no restrictions.',
      ru: 'IF \'Security\' = \'1\' and Transport mode at border\' = \'4\'\n'
        + 'THEN the attribute is \'R\' and consists of the (IATA/ICAO) flight\n'
        + 'number and has format an..8:\n'
        + '-an..3: mandatory prefix identifying the airline/operator -n..4: mandatory number of the flight\n'
        + '-a1: optional suffix\n'
        + 'ELSE the attribute is \'O\' and the format of the attribute has no restrictions.',
    },
    {
      code: 'C547',
      en: 'IF the attribute \'TRANSIT OPERATION.Security\' is not present or if the \'Specific circumstance indicator\' = \'A\' (\'TRANSIT OPERATION.Security\' is present)\n'
        + 'THEN the attributes \'Commercial Reference Number (box 7)\', \'Commercial Reference Number (ex box 7)\' and the data group \'PRODUCED DOCUMENTS/CERTIFICATES\' = \'O\'\n'
        + 'ELSE (\'Security\' is present and \'Specific circumstance indicator\' not present or other than \'A\')\n'
        + 'IF the attribute \'Commercial Reference Number (box 7)\' and \'Commercial Reference Number (ex box 7)\' is not used\n'
        + 'THEN at least one attribute \'PRODUCED DOCUMENTS/ CERITIFICATES.Document type\' for the first GOODS ITEM pointing to a transport document must be present\n'
        + 'ELSE the data group \'PRODUCED DOCUMENTS/ CERTIFICATES\' = \'O\'',
      ru: 'IF the attribute \'TRANSIT OPERATION.Security\' is not present or if the \'Specific circumstance indicator\' = \'A\' (\'TRANSIT OPERATION.Security\' is present)\n'
        + 'THEN the attributes \'Commercial Reference Number (box 7)\', \'Commercial Reference Number (ex box 7)\' and the data group \'PRODUCED DOCUMENTS/CERTIFICATES\' = \'O\'\n'
        + 'ELSE (\'Security\' is present and \'Specific circumstance indicator\' not present or other than \'A\')\n'
        + 'IF the attribute \'Commercial Reference Number (box 7)\' and \'Commercial Reference Number (ex box 7)\' is not used\n'
        + 'THEN at least one attribute \'PRODUCED DOCUMENTS/ CERITIFICATES.Document type\' for the first GOODS ITEM pointing to a transport document must be present\n'
        + 'ELSE the data group \'PRODUCED DOCUMENTS/ CERTIFICATES\' = \'O\'',
    },
    {
      code: 'C572',
      en: 'IF the attribute \'Specific circumstance indicator = \'E\'\n'
        + 'IF the first two digits of the CUSTOMS OFFICE.Office of Departure consist of an Member\n'
        + 'State country code\n'
        + 'THEN the attribute = \'R\'\n'
        + 'ELSE the attribute = \'O\' ELSE the attribute = \'O\'',
      ru: 'IF the attribute \'Specific circumstance indicator = \'E\'\n'
        + 'IF the first two digits of the CUSTOMS OFFICE.Office of Departure consist of an Member\n'
        + 'State country code\n'
        + 'THEN the attribute = \'R\'\n'
        + 'ELSE the attribute = \'O\' ELSE the attribute = \'O\'',
    },
    {
      code: 'C576',
      en: 'IF the attribute \'EXPORT OPERATION/IMPORT OPERATION/ TRANSIT OPERATION.Transport charges – Method of payment\' is used\n'
        + 'THEN this attribute can not be used ELSE this attribute = \'O\'.\n',
      ru: 'IF the attribute \'EXPORT OPERATION/IMPORT OPERATION/ TRANSIT OPERATION.Transport charges – Method of payment\' is used\n'
        + 'THEN this attribute can not be used ELSE this attribute = \'O\'.\n',
    },
    {
      code: 'C587',
      en: 'IF the attribute \'Security\' on HEADER level is present, THEN the datagroup/attribute = \'R\'\n'
        + 'ELSE the datagroup/attribute cannot be used.\n',
      ru: 'IF the attribute \'Security\' on HEADER level is present, THEN the datagroup/attribute = \'R\'\n'
        + 'ELSE the datagroup/attribute cannot be used.\n',
    },
    {
      code: 'C589',
      en: 'IF the attribute \'Specific circumstance indicator\' = \'B\' or \'E\'\n'
        + 'THEN the attribute \'Unloading place\' = \'O\' ELSE the attribute \'Unloading place\' = \'R\'.',
      ru: 'IF the attribute \'Specific circumstance indicator\' = \'B\' or \'E\'\n'
        + 'THEN the attribute \'Unloading place\' = \'O\' ELSE the attribute \'Unloading place\' = \'R\'.',
    },
    {
      code: 'C596',
      en: 'IF the attribute \'Specific circumstance indicator = \'E\'\n'
        + 'IF the first two digits of the \'CUSTOMS OFFICE of Departure.Reference number\' do not\n'
        + 'consist of an Member State country code THEN the attribute = \'R\' ELSE the attribute = \'O\'\n'
        + 'ELSE the attribute = \'O\'.',
      ru: 'IF the attribute \'Specific circumstance indicator = \'E\'\n'
        + 'IF the first two digits of the \'CUSTOMS OFFICE of Departure.Reference number\' do not\n'
        + 'consist of an Member State country code THEN the attribute = \'R\' ELSE the attribute = \'O\'\n'
        + 'ELSE the attribute = \'O\'.',
    },
    {
      code: 'C598',
      en: 'IF the attribute \'TRANSIT OPERATION.Security = \'1\' and\n'
        + 'the first two digits of the CUSTOMS OFFICE of Transit.Reference number consists of\n'
        + 'a Member State country code and\n'
        + 'the first two digits of the CUSTOMS OFFICE of Departure.Reference number does not consist of an Member State country code\n'
        + 'THEN the attribute = \'R\' ELSE the attribute = \'O\'.',
      ru: 'IF the attribute \'TRANSIT OPERATION.Security = \'1\' and\n'
        + 'the first two digits of the CUSTOMS OFFICE of Transit.Reference number consists of\n'
        + 'a Member State country code and\n'
        + 'the first two digits of the CUSTOMS OFFICE of Departure.Reference number does not consist of an Member State country code\n'
        + 'THEN the attribute = \'R\' ELSE the attribute = \'O\'.',
    },
    {
      code: 'C599',
      en: 'IF the attribute \'TRANSIT OPERATION.Security = \'1\' and\n'
        + 'the first two digits of the CUSTOMS OFFICE of Departure.Reference number does not consist of an Member State country code \n'
        + '\n THEN the attribute = \'R\' \n ELSE the attribute = \'O\'.',
      ru: '',
    },
    {
      code: 'C900',
      en: 'IF "Declaration Type (box 1)" = \'TIR\' \n THEN "Type of Guarantee" type \'B\' = \'R\' \n ELSE "Type of Guarantee" type \'B\' cannot be used.',
      ru: '',
    },
    {
      code: 'C901',
      en: 'IF "Declaration Type (box 1)" = \'TIR\' \n THEN one occurrence of the value \'952\' of "Document Type\n'
        + '(box 44)"- irrespective of other document types declared – must be entered within one operation and it must be present in the first goods item'
        + ' \n ELSE value \'952\' of "Document Type (box 44)" can not be used',
      ru: '',
    },
    {
      code: 'C902',
      en: 'IF "Document Type (box 44)" = \'952\' (an10, 6.2 TIR convention) , \n THEN "Document Reference (box 44)" = \'R\' \n'
        + ' ELSE "Document Reference (box 44)" = \'O',
      ru: '',
    },
    {
      code: 'C903',
      en: 'IF "Declaration Type" = \'TIR\' \n THEN the data group \'PRODUCED DOCUMENTS/CERTIFICATES\' for the first Goods Item = \'R\''
        + '\n ELSE the data group \'PRODUCED DOCUMENTS/ CERTIFICATES\' = \'O\'',
      ru: '',
    },
    {
      code: 'C904',
      en: 'IF "Declaration Type" = \'TIR\' \n THEN "Holder ID TIR" = \'R\', \n ELSE "Holder ID TIR" can not be used.',
      ru: '',
    },
    {
      code: 'R01',
      en: 'Each \'Enquiry Information Code\' can only be used once per message.',
      ru: 'Each \'Enquiry Information Code\' can only be used once per message.',
    },
    {
      code: 'R02',
      en: 'Each \'Requested Information Code\' can only be used once per message.',
      ru: 'Each \'Requested Information Code\' can only be used once per message.',
    },
    {
      code: 'R05',
      en: '‘Item no’ (box 32) is always used even if ‘Items’ (box 5) = ‘1’, ‘Item no’ (box 32) is also ‘1’.',
      ru: '‘Item no’ (box 32) is always used even if ‘Items’ (box 5) = ‘1’, ‘Item no’ (box 32) is also ‘1’.',
    },
    {
      code: 'R07',
      en: 'Each ‘Item no’ (box 32) is unique throughout the declaration The items shall be numbered in a sequential fashion, starting from \'1\' for the first item and incrementing the numbering by \'1\' for each following item".',
      ru: 'Each ‘Item no’ (box 32) is unique throughout the declaration The items shall be numbered in a sequential fashion, starting from \'1\' for the first item and incrementing the numbering by \'1\' for each following item".',
    },
    {
      code: 'R010',
      en: 'If the same Consignor/Exporter is declared for all goods items, the data group ‘TRADER Consignor (box 2)’/\'TRADER Exporter (box 2)\' on TRANSIT OPERATION/EXPORT OPERATION/IMPORT OPERATION level is used. The data group ‘TRADER Consignor (ex box 2)’/\'TRADER Exporter (box 2)\' on GOODS ITEM level can not be used.',
      ru: 'If the same Consignor/Exporter is declared for all goods items, the data group ‘TRADER Consignor (box 2)’/\'TRADER Exporter (box 2)\' on TRANSIT OPERATION/EXPORT OPERATION/IMPORT OPERATION level is used. The data group ‘TRADER Consignor (ex box 2)’/\'TRADER Exporter (box 2)\' on GOODS ITEM level can not be used.',
    },
    {
      code: 'R011',
      en: 'When the same Consignee is declared for all goods items, the data group ‘TRADER Consignee (box 8)’ on TRANSIT OPERATION level is used. The data group ‘TRADER Consignee (ex box 8)’ on GOODS ITEM level can not be used.',
      ru: 'When the same Consignee is declared for all goods items, the data group ‘TRADER Consignee (box 8)’ on TRANSIT OPERATION level is used. The data group ‘TRADER Consignee (ex box 8)’ on GOODS ITEM level can not be used.',
    },
    {
      code: 'R012',
      en: 'The attribute is sent if it was provided in the corresponding message that was received from the Trader.',
      ru: 'The attribute is sent if it was provided in the corresponding message that was received from the Trader.',
    },
    {
      code: 'R015',
      en: 'The data group ‘TRADER Authorised Consignee (box 53)’ can be used to indicate that simplified procedure will be used at destination.',
      ru: 'The data group ‘TRADER Authorised Consignee (box 53)’ can be used to indicate that simplified procedure will be used at destination.',
    },
    {
      code: 'R017',
      en: 'Only one of the data attributes \'CTL_CONTROL.Trader authorised NCTS\' and \'CTL_CONTROL.Trader authorised TIR\' can be set to \'1\' (yes)',
      ru: 'Only one of the data attributes \'CTL_CONTROL.Trader authorised NCTS\' and \'CTL_CONTROL.Trader authorised TIR\' can be set to \'1\' (yes)',
    },
    {
      code: 'R020',
      en: 'When the declaration type (box 1 or ex box 1) = ‘T2’ and the movement is issued in a non-EU country (identified by the Office of Departure), the Principal must declare at least one ‘Previous document type’ (box 40) = ‘T2’, ‘T2L’, ‘T2F’, ‘T2LF’, ‘T2CIM’, ‘T2TIR’ or ‘T2ATA’ followed by its reference in ‘Previous document reference’.\n',
      ru: 'When the declaration type (box 1 or ex box 1) = ‘T2’ and the movement is issued in a non-EU country (identified by the Office of Departure), the Principal must declare at least one ‘Previous document type’ (box 40) = ‘T2’, ‘T2L’, ‘T2F’, ‘T2LF’, ‘T2CIM’, ‘T2TIR’ or ‘T2ATA’ followed by its reference in ‘Previous document reference’.\n',
    },
    {
      code: 'R021',
      en: 'A zero \'0\' is to be considered as a valid number in this field.',
      ru: 'A zero \'0\' is to be considered as a valid number in this field.',
    },
    {
      code: 'R027',
      en: 'Either ‘Additional information id’ or ‘Text’ of the data group ‘SPECIAL MENTIONS’ (Box 44) is required in case ‘SPECIAL MENTIONS’ is used.',
      ru: 'Either ‘Additional information id’ or ‘Text’ of the data group ‘SPECIAL MENTIONS’ (Box 44) is required in case ‘SPECIAL MENTIONS’ is used.',
    },
    {
      code: 'R035',
      en: 'Conditions ‘C5’ and ‘C6’ can not be checked in case Box 26 is not used.\n',
      ru: 'Conditions ‘C5’ and ‘C6’ can not be checked in case Box 26 is not used.\n',
    },
    {
      code: 'R036',
      en: 'Condition ‘C10’ can not be checked in case Box 25 is not used.',
      ru: 'Condition ‘C10’ can not be checked in case Box 25 is not used.',
    },
    {
      code: 'R041',
      en: '‘Agreed Location Of Goods’/’Agreed Location Code’, ‘Authorised location of goods’ and ‘Customs sub place’ (box 30) can not be used at the same time.',
      ru: '‘Agreed Location Of Goods’/’Agreed Location Code’, ‘Authorised location of goods’ and ‘Customs sub place’ (box 30) can not be used at the same time.',
    },
    {
      code: 'R060',
      en: 'When the Trader uses Commodity codes then he must declare at least 4 and up to 8 digits of the commodity code.',
      ru: 'When the Trader uses Commodity codes then he must declare at least 4 and up to 8 digits of the commodity code.',
    },
    {
      code: 'R075',
      en: '‘Export from EC‘ and ‘Export from country’ can not be used at the same time. When ‘Export from EC’ is used, its value is set to ‘1’.',
      ru: '‘Export from EC‘ and ‘Export from country’ can not be used at the same time. When ‘Export from EC’ is used, its value is set to ‘1’.',
    },
    {
      code: 'R079',
      en: 'The datagroups PREVIOUS ADMINISTRATIVE REFERENCES and PRODUCED DOCUMENTS/CERTIFICATES will be sent to Destination/Exit by means of IE001/501 only in case the declared attributes ‘Previous Document Type (Box 40)’ and/or ‘Document Type (Box 44)’ contain codes which are considered as ‘common’.',
      ru: 'The datagroups PREVIOUS ADMINISTRATIVE REFERENCES and PRODUCED DOCUMENTS/CERTIFICATES will be sent to Destination/Exit by means of IE001/501 only in case the declared attributes ‘Previous Document Type (Box 40)’ and/or ‘Document Type (Box 44)’ contain codes which are considered as ‘common’.',
    },
    {
      code: 'R080',
      en: 'Only the SPECIAL MENTIONS that are marked as ‘Common’ in the reference data shall be included in common domain messages.',
      ru: 'Only the SPECIAL MENTIONS that are marked as ‘Common’ in the reference data shall be included in common domain messages.',
    },
    {
      code: 'R095',
      en: 'The use of paper loading lists having been suppressed; this attribute can no longer be used.',
      ru: 'The use of paper loading lists having been suppressed; this attribute can no longer be used.',
    },
    {
      code: 'R099',
      en: 'This data item must be entered if the message is encoded in EDIFACT and the corresponding free text field is not in the UNOC character set. It is otherwise optional.\n'
        + 'If the data item is filled in, it must contain the language code of the language used in the corresponding free text field.',
      ru: 'This data item must be entered if the message is encoded in EDIFACT and the corresponding free text field is not in the UNOC character set. It is otherwise optional.\n'
        + 'If the data item is filled in, it must contain the language code of the language used in the corresponding free text field.',
    },
    {
      code: 'R100',
      en: 'The attribute is used as the basic language to be used in any further communication between the Trader and the Customs system. '
        + 'If the Trader does not use this attribute then the Customs system will use the default language of the Office concerned.',
      ru: 'The attribute is used as the basic language to be used in any further communication between the Trader and the Customs system. '
        + 'If the Trader does not use this attribute then the Customs system will use the default language of the Office concerned.',
    },
    {
      code: 'R101',
      en: 'The attribute is used as the basic language to be used in any further communication between the Trader and the Customs system. If the Trader does not use this attribute then the Customs system will use the default language of the Office concerned.',
      ru: 'The attribute is used as the basic language to be used in any further communication between the Trader and the Customs system. If the Trader does not use this attribute then the Customs system will use the default language of the Office concerned.',
    },
    {
      code: 'R105',
      en: 'The total number of packages is equal to the sum of all ‘Number of packages’ + all ‘Number of pieces’ + a value of ‘1’ for each declared ‘bulk’.\n',
      ru: 'The total number of packages is equal to the sum of all ‘Number of packages’ + all ‘Number of pieces’ + a value of ‘1’ for each declared ‘bulk’.\n',
    },
    {
      code: 'R115',
      en: 'The IE structure used for the amendment is the same as E_DEC_DAT/E_EXP_DAT/E_EXS_DAT /E_ENS_DAT/E_SUD_DAT with some changes:\n'
        + '   replace LRN by MRN REQUIRED an18,\n replace ‘Declaration date’ by ‘Date of amendment’ REQUIRED n8 (NCTS/ECS), \n'
        + ' replace ‘Declaration date and time’ by ‘Date and time of amendment’ REQUIRED n12 (ICS),\n replace ‘Declaration place’ by ‘Amendment place’ REQUIRED an..35\n'
        + 'The IE transmits a complete amended declaration data, this means that all data groups and attributes are transmitted and not only those that are amended.',
      ru: 'The IE structure used for the amendment is the same as E_DEC_DAT/E_EXP_DAT/E_EXS_DAT /E_ENS_DAT/E_SUD_DAT with some changes:\n'
        + '   replace LRN by MRN REQUIRED an18,\n replace ‘Declaration date’ by ‘Date of amendment’ REQUIRED n8 (NCTS/ECS), \n'
        + ' replace ‘Declaration date and time’ by ‘Date and time of amendment’ REQUIRED n12 (ICS),\n replace ‘Declaration place’ by ‘Amendment place’ REQUIRED an..35\n'
        + 'The IE transmits a complete amended declaration data, this means that all data groups and attributes are transmitted and not only those that are amended.',
    },
    {
      code: 'R123',
      en: 'From the originally received IE, only the attributes in error are transmitted back to the Trader, indicating whether the attribute(s) in question is (are) missing or incorrect.',
      ru: 'From the originally received IE, only the attributes in error are transmitted back to the Trader, indicating whether the attribute(s) in question is (are) missing or incorrect.',
    },
    {
      code: 'R130',
      en: 'When two or more Offices of Transit belong to the same National Administration and the IE50 is transmitted via EDI, then only one IE50 (ATR) is sent to the National Administration in question.',
      ru: 'When two or more Offices of Transit belong to the same National Administration and the IE50 is transmitted via EDI, then only one IE50 (ATR) is sent to the National Administration in question.',
    },
    {
      code: 'R135',
      en: 'The IE retransmits back to the Trader the whole E_DEC_DAT received associating to each single attribute, when the case occurs, the data group ‘RESULTS OF CONTROL’.',
      ru: 'The IE retransmits back to the Trader the whole E_DEC_DAT received associating to each single attribute, when the case occurs, the data group ‘RESULTS OF CONTROL’.',
    },
    {
      code: 'R143',
      en: 'The data in the IE corresponds always to the current (latest) version of the Transit/Export/Summary declaration operation data. This means that it contains the amended declaration data (if any) and/or the revised declaration data after a control (if any) and completed with the departure/export control results and entry/exit control results.',
      ru: 'The data in the IE corresponds always to the current (latest) version of the Transit/Export/Summary declaration operation data. This means that it contains the amended declaration data (if any) and/or the revised declaration data after a control (if any) and completed with the departure/export control results and entry/exit control results.',
    },
    {
      code: 'R145',
      en: 'At least one occurrence of this data group must be present in the message.\n',
      ru: 'At least one occurrence of this data group must be present in the message.\n',
    },
    {
      code: 'R150',
      en: 'The attribute ‘Corrected value’ of the data group ‘RESULTS OF CONTROL’ is linked with each single attribute that can be subject of a control and therefore it must reflect the same characteristics of the original message attribute.',
      ru: 'The attribute ‘Corrected value’ of the data group ‘RESULTS OF CONTROL’ is linked with each single attribute that can be subject of a control and therefore it must reflect the same characteristics of the original message attribute.',
    },
    {
      code: 'R155',
      en: 'The data group SGI-CODES must be present in case the declaration contains Sensitive Goods.',
      ru: 'The data group SGI-CODES must be present in case the declaration contains Sensitive Goods.',
    },
    {
      code: 'R156',
      en: 'A ‘Sensitive Quantity’ is always required when the movement contains sensitive goods (see also Rule 155).\n'
        + 'The ‘Sensitive Goods Code’ is not always required when the movement contains sensitive goods. If the HS6 commodity code (Box 33) is enough to uniquely identify a sensitive good then the ‘Sensitive Goods Code’ is not required. If the HS6 commodity code (Box 33) is not enough to uniquely identify a sensitive good then the ‘Sensitive Goods Code’ becomes required.',
      ru: 'A ‘Sensitive Quantity’ is always required when the movement contains sensitive goods (see also Rule 155).\n'
        + 'The ‘Sensitive Goods Code’ is not always required when the movement contains sensitive goods. If the HS6 commodity code (Box 33) is enough to uniquely identify a sensitive good then the ‘Sensitive Goods Code’ is not required. If the HS6 commodity code (Box 33) is not enough to uniquely identify a sensitive good then the ‘Sensitive Goods Code’ becomes required.',
    },
    {
      code: 'R157',
      en: 'If the data group is present in the IE then at least one of the optional attributes must be filled in. When it concerns a data group containing a name/address then the attributes of the name/address are treated as one.',
      ru: 'If the data group is present in the IE then at least one of the optional attributes must be filled in. When it concerns a data group containing a name/address then the attributes of the name/address are treated as one.',
    },
    {
      code: 'R160',
      en: 'The data group CONTROL RESULTS must be present in case the declaration is submitted under simplified procedure.',
      ru: 'The data group CONTROL RESULTS must be present in case the declaration is submitted under simplified procedure.',
    },
    {
      code: 'R165',
      en: 'The data group SEALS INFO must be present in case the declaration is submitted under simplified procedure, the authorisation of which foresees the use of seals.',
      ru: 'The data group SEALS INFO must be present in case the declaration is submitted under simplified procedure, the authorisation of which foresees the use of seals.',
    },
    {
      code: 'R170',
      en: 'This IE is only sent to the Offices of transit which have not notified that the consignment has crossed the frontier.\n'
        + 'When the IE is transmitted via EDI and when two or more Offices of Transit belong to the same National Administration, or an (some) Office(s) of Transit and the Office of Destination belong to the same National Administration, then only one IE is sent to the National Administration in question.',
      ru: 'This IE is only sent to the Offices of transit which have not notified that the consignment has crossed the frontier.\n'
        + 'When the IE is transmitted via EDI and when two or more Offices of Transit belong to the same National Administration, or an (some) Office(s) of Transit and the Office of Destination belong to the same National Administration, then only one IE is sent to the National Administration in question.',
    },
    {
      code: 'R173',
      en: 'When the IE10 is transmitted via EDI and two or more Offices of Transit belong to the same National Administration, or an (some) Office(s) of Transit and the Office of Destination belong to the same National Administration, then only one IE10 is sent to the National Administration in question.',
      ru: 'When the IE10 is transmitted via EDI and two or more Offices of Transit belong to the same National Administration, or an (some) Office(s) of Transit and the Office of Destination belong to the same National Administration, then only one IE10 is sent to the National Administration in question.',
    },
    {
      code: 'R174',
      en: 'When simplified procedure is used then the TIN becomes ‘R’ else the TIN is ‘O’.',
      ru: 'When simplified procedure is used then the TIN becomes ‘R’ else the TIN is ‘O’.',
    },
    {
      code: 'R175',
      en: 'Two possible values : ‘0’ = ‘No’ : Request to cancel initiated by Trader\n'
        + '‘1’ = ‘Yes’ : Request to cancel initiated by Customs.',
      ru: 'Two possible values : ‘0’ = ‘No’ : Request to cancel initiated by Trader\n'
        + '‘1’ = ‘Yes’ : Request to cancel initiated by Customs.',
    },
    {
      code: 'R180',
      en: 'Two possible values : Cancellation refused by Customs : Decision = ‘0’ (=NO) Cancellation accepted by Customs : Decision = ‘1’ (=YES)',
      ru: 'Two possible values : Cancellation refused by Customs : Decision = ‘0’ (=NO) Cancellation accepted by Customs : Decision = ‘1’ (=YES)',
    },
    {
      code: 'R181',
      en: 'The data group is required if different from the \'TRADER Principal\' (otherwise it is optional).',
      ru: 'The data group is required if different from the \'TRADER Principal\' (otherwise it is optional).',
    },
    {
      code: 'R186',
      en: '‘Unloading completion’ is used as a flag, it can contain 2 possible values; ‘0’ = ‘NO’ This means that the unloading of the goods is not yet completed; ‘1’ = ‘YES’ This means that the goods are completely unloaded.\n',
      ru: '‘Unloading completion’ is used as a flag, it can contain 2 possible values; ‘0’ = ‘NO’ This means that the unloading of the goods is not yet completed; ‘1’ = ‘YES’ This means that the goods are completely unloaded.\n',
    },
    {
      code: 'R190',
      en: 'Either ‘New transport means identity’ and ‘New transport means nationality’ OR ‘New container number’ OR both must be used when ‘TRANSHIPMENT’ occurred.',
      ru: 'Either ‘New transport means identity’ and ‘New transport means nationality’ OR ‘New container number’ OR both must be used when ‘TRANSHIPMENT’ occurred.',
    },
    {
      code: 'R195',
      en: 'The purpose of this IE is to communicate the permission to start unload the goods (or to go on with the unloading) to the Authorised Consignee.',
      ru: 'The purpose of this IE is to communicate the permission to start unload the goods (or to go on with the unloading) to the Authorised Consignee.',
    },
    {
      code: 'R200',
      en: '‘State of seals ok’ is used as a flag and can contain 2 values: ‘State of seals ok’ = ‘0’ (NO)\n'
        + '‘State of seals ok’ = ‘1’ (YES).',
      ru: '‘State of seals ok’ is used as a flag and can contain 2 values: ‘State of seals ok’ = ‘0’ (NO)\n'
        + '‘State of seals ok’ = ‘1’ (YES).',
    },
    {
      code: 'R205',
      en: '‘Conform’ is used as a flag, it can contain 2 possible values; ‘0’ = ‘NO’ there are unloading remarks;\n'
        + '‘1’ = ‘YES’ no unloading remarks present.',
      ru: '‘Conform’ is used as a flag, it can contain 2 possible values; ‘0’ = ‘NO’ there are unloading remarks;\n'
        + '‘1’ = ‘YES’ no unloading remarks present.',
    },
    {
      code: 'R206',
      en: 'When no seals are found attached to the consignment and the information brought in by the AAR indicated the use of seals then SEALS ID can not be used, also, the attribute ‘Seals Number’ is set to zero and an explanation must be given in ‘Unloading Remark’ (=‘R’). In all other cases the data group SEALS ID is required.',
      ru: 'When no seals are found attached to the consignment and the information brought in by the AAR indicated the use of seals then SEALS ID can not be used, also, the attribute ‘Seals Number’ is set to zero and an explanation must be given in ‘Unloading Remark’ (=‘R’). In all other cases the data group SEALS ID is required.',
    },
    {
      code: 'R210',
      en: 'The IE transmits to the Office of Departure/Office of Export the relevant AAR/AER information received from the Office of Departure/Office of Export associating to each single attribute, when the case occurs, the data group ‘RESULTS OF CONTROL’.',
      ru: 'The IE transmits to the Office of Departure/Office of Export the relevant AAR/AER information received from the Office of Departure/Office of Export associating to each single attribute, when the case occurs, the data group ‘RESULTS OF CONTROL’.',
    },
    {
      code: 'R211',
      en: 'The IE retransmits back to the Customs Office of Destination the pertinent part of E_ULD_PER received associating to each single attribute, when the case occurs (this means when the attribute is incorrect), the data group ‘RESULTS OF CONTROL’.\n',
      ru: 'The IE retransmits back to the Customs Office of Destination the pertinent part of E_ULD_PER received associating to each single attribute, when the case occurs (this means when the attribute is incorrect), the data group ‘RESULTS OF CONTROL’.\n',
    },
    {
      code: 'R212',
      en: 'The IE transmits to the Office of Departure/Export the relevant controlled declaration data associating to each single attribute, when the case occurs, the data group ‘RESULTS OF CONTROL’.',
      ru: 'The IE transmits to the Office of Departure/Export the relevant controlled declaration data associating to each single attribute, when the case occurs, the data group ‘RESULTS OF CONTROL’.',
    },
    {
      code: 'R213',
      en: 'The IE is used to communicate the controlled items and minor or major discrepancies (if any).',
      ru: 'The IE is used to communicate the controlled items and minor or major discrepancies (if any).',
    },
    {
      code: 'R215',
      en: 'Transit (NCTS):\n'
        + '\'TRANSIT OPERATION\'/\'CUSTOMS OFFICE of Departure\' and \'C_AAR_SND\' cannot be used at the same time. When Customs can agree with the request for an AAR, the complete C_AAR_SND is sent to destination. When they disagree, only the MRN, CUSTOMS OFFICE of Departure (if rejection reason code is other than \'3\'), AAR rejection reason code and the AAR rejection reason (if necessary) are sent to destination.\n'
        + 'Export declaration:\n'
        + '‘EXPORT OPERATION REJECTION’, ’CUSTOMS OFFICE of Export\' and ‘C_AER_SND’ can not be used at the same time. When Customs can agree with the request for an AER,the complete \'C_AER_SND\' is sent to exit. When they disagree, only the \'MRN\', the \'CUSTOMS OFFICE of Export\',the \'AER/ rejection reason code\' and the \'AERrejection reason\' (if necessary) are sent to exit.\n'
        + 'Exit summary declaration:\n'
        + '‘EXPORT OPERA TION REJECTION’, \'CUSTOMS OFFICE of Lodgement\' and \'C_EXS_SND\' can not be used at the same time. When Customs can agree with the request for a Summary Declaration, the complete \'C_EXS_SND\' is sent to exit. When they disagree, only the MRN, the CUSTOMS OFFICE of Lodgement, the \'Summary Declaration rejection reason code\' and the \'Summary Declaration rejection reason\' (if necessary) are sent to exit.',
      ru: 'Transit (NCTS):\n'
        + '\'TRANSIT OPERATION\'/\'CUSTOMS OFFICE of Departure\' and \'C_AAR_SND\' cannot be used at the same time. When Customs can agree with the request for an AAR, the complete C_AAR_SND is sent to destination. When they disagree, only the MRN, CUSTOMS OFFICE of Departure (if rejection reason code is other than \'3\'), AAR rejection reason code and the AAR rejection reason (if necessary) are sent to destination.\n'
        + 'Export declaration:\n'
        + '‘EXPORT OPERATION REJECTION’, ’CUSTOMS OFFICE of Export\' and ‘C_AER_SND’ can not be used at the same time. When Customs can agree with the request for an AER,the complete \'C_AER_SND\' is sent to exit. When they disagree, only the \'MRN\', the \'CUSTOMS OFFICE of Export\',the \'AER/ rejection reason code\' and the \'AERrejection reason\' (if necessary) are sent to exit.\n'
        + 'Exit summary declaration:\n'
        + '‘EXPORT OPERA TION REJECTION’, \'CUSTOMS OFFICE of Lodgement\' and \'C_EXS_SND\' can not be used at the same time. When Customs can agree with the request for a Summary Declaration, the complete \'C_EXS_SND\' is sent to exit. When they disagree, only the MRN, the CUSTOMS OFFICE of Lodgement, the \'Summary Declaration rejection reason code\' and the \'Summary Declaration rejection reason\' (if necessary) are sent to exit.',
    },
    {
      code: 'R216',
      en: '\'TRANSIT OPERATION\'/\'CUSTOMS OFFICE of Departure\' and \'C_ATR_SND\' can not be used at the same time. When Customs can agree with the request for an ATR, the complete C_ATR_SND is sent to Office of Transit. When they disagree, only the MRN, CUSTOMS OFFICE of Departure (if rejection reason code is other than \'5\'), ATR rejection reason code and the ATR rejection reason (if necessary) are sent to the Office of Transit.\n',
      ru: '\'TRANSIT OPERATION\'/\'CUSTOMS OFFICE of Departure\' and \'C_ATR_SND\' can not be used at the same time. When Customs can agree with the request for an ATR, the complete C_ATR_SND is sent to Office of Transit. When they disagree, only the MRN, CUSTOMS OFFICE of Departure (if rejection reason code is other than \'5\'), ATR rejection reason code and the ATR rejection reason (if necessary) are sent to the Office of Transit.\n',
    },
    {
      code: 'R217',
      en: 'All ‘Incidents’ occurred are transmitted to the Office of Departure. Only those ‘Transhipments’ marked ‘not yet sent’ (i.e. flag ‘Already in NCTS’ in the message ‘Arrival notification or a new box created for this purpose on the NCTS ACCOMPANYING DOCUMENT) are transmitted to the Office of Departure.',
      ru: 'All ‘Incidents’ occurred are transmitted to the Office of Departure. Only those ‘Transhipments’ marked ‘not yet sent’ (i.e. flag ‘Already in NCTS’ in the message ‘Arrival notification or a new box created for this purpose on the NCTS ACCOMPANYING DOCUMENT) are transmitted to the Office of Departure.',
    },
    {
      code: 'R228',
      en: 'When the attribute \'Information available\' is \'0\', the attribute "TC11 delivered by Customs" must have the same value.',
      ru: 'When the attribute \'Information available\' is \'0\', the attribute "TC11 delivered by Customs" must have the same value.',
    },
    {
      code: 'R229',
      en: 'The attribute is used to distinguish between cases where the NCTS is used (Security = \'1\') and not used (Security = not present) for safety and security purposes.',
      ru: 'The attribute is used to distinguish between cases where the NCTS is used (Security = \'1\') and not used (Security = not present) for safety and security purposes.',
    },
    {
      code: 'R230',
      en: 'This attribute is used as a flag, its value can be either ‘0’ (‘no’) or ‘1’ (‘yes’).',
      ru: 'This attribute is used as a flag, its value can be either ‘0’ (‘no’) or ‘1’ (‘yes’).',
    },
    {
      code: 'R231',
      en: 'The value of this attribute must be a country with the country regime \'TOC\', with the exclusion of LI and SJ.\n',
      ru: 'The value of this attribute must be a country with the country regime \'TOC\', with the exclusion of LI and SJ.\n',
    },
    {
      code: 'R232',
      en: '“Variable Holiday Date” and/or “Variable Season Date” are used as a flag and can contain 2 possible values:\n'
        + '‘0’: the holiday and/or season date is fixed and thus the year doesn’t have to be specified (e.g. Christmas = 25 December)\n'
        + '‘1’: the holiday and/or season date is variable and therefore the year will need to be specified together with the date (e.g. Easter Monday).',
      ru: '“Variable Holiday Date” and/or “Variable Season Date” are used as a flag and can contain 2 possible values:\n'
        + '‘0’: the holiday and/or season date is fixed and thus the year doesn’t have to be specified (e.g. Christmas = 25 December)\n'
        + '‘1’: the holiday and/or season date is variable and therefore the year will need to be specified together with the date (e.g. Easter Monday).',
    },
    {
      code: 'R240',
      en: 'When the data group “EN ROUTE EVENT” is present then the data groups “INCIDENT” or “TRANSHIPMENT” or both must be present, complemented, where relevant, with the data group “NEW SEALS INFO”.',
      ru: 'When the data group “EN ROUTE EVENT” is present then the data groups “INCIDENT” or “TRANSHIPMENT” or both must be present, complemented, where relevant, with the data group “NEW SEALS INFO”.',
    },
    {
      code: 'R241',
      en: 'The datagroup “EN ROUTE EVENTS” is always required when information on “Incidents” and/or “Transhipments” are registered at OoDep.',
      ru: 'The datagroup “EN ROUTE EVENTS” is always required when information on “Incidents” and/or “Transhipments” are registered at OoDep.',
    },
    {
      code: 'R245',
      en: '“Customs sub place”, “Arrival authorised location of goods” and “Arrival Agreed Location Of Goods / Arrival Agreed Location Code” can not be used at the same time.\n',
      ru: '“Customs sub place”, “Arrival authorised location of goods” and “Arrival Agreed Location Of Goods / Arrival Agreed Location Code” can not be used at the same time.\n',
    },
    {
      code: 'R250',
      en: '“Movement unknown at Destination” and ‘Enquiry engaged at Destination” can not be used at the same time.',
      ru: '“Movement unknown at Destination” and ‘Enquiry engaged at Destination” can not be used at the same time.',
    },
    {
      code: 'R251',
      en: 'This attribute is not to be used anymore (suppression of return copy).',
      ru: 'This attribute is not to be used anymore (suppression of return copy).',
    },
    {
      code: 'R256',
      en: 'This IE is always paper-based, it is used as a working document to perform a control of the goods (it is a print-out containing the accepted declaration data). During the control, the Customs Officer marks the control results on the paper.',
      ru: 'This IE is always paper-based, it is used as a working document to perform a control of the goods (it is a print-out containing the accepted declaration data). During the control, the Customs Officer marks the control results on the paper.',
    },
    {
      code: 'R257',
      en: 'This IE is always paper-based, it is used as a working document to perform a control of the goods at Destination/Exit/Entry (it is a print-out containing the received AAR/AER/E_EXP_SUM/EXS_DAT/E_ENS_DAT data). During the control, the Customs Officer marks the control results on the paper.',
      ru: 'This IE is always paper-based, it is used as a working document to perform a control of the goods at Destination/Exit/Entry (it is a print-out containing the received AAR/AER/E_EXP_SUM/EXS_DAT/E_ENS_DAT data). During the control, the Customs Officer marks the control results on the paper.',
    },
    {
      code: 'R260',
      en: 'Either the guarantee is rendered invalid or it becomes again valid from a given date. ‘Invalidity date’ and ‘Validity date’ can not be used at the same time.\n',
      ru: 'Either the guarantee is rendered invalid or it becomes again valid from a given date. ‘Invalidity date’ and ‘Validity date’ can not be used at the same time.\n',
    },
    {
      code: 'R261',
      en: 'Query Identifier ‘2’ (exposure only) and ‘3’ (usage and exposure) can not be used for guarantee types ‘2’ (individual guarantee by Guarantor) and ‘4’ (individual guarantee in the form of voucher).',
      ru: 'Query Identifier ‘2’ (exposure only) and ‘3’ (usage and exposure) can not be used for guarantee types ‘2’ (individual guarantee by Guarantor) and ‘4’ (individual guarantee in the form of voucher).',
    },
    {
      code: 'R262',
      en: 'The data group can be used only when the query identifier code ‘4’ (general information only) is used.\n',
      ru: 'The data group can be used only when the query identifier code ‘4’ (general information only) is used.\n',
    },
    {
      code: 'R263',
      en: 'The attribute can be used only with guarantee types ‘0’ (guarantee waiver), ‘1’ (comprehensive guarantee) or ‘9’ (Individual guarantee with multiple usage), with either Query Identifier ‘1’ (usage only) or ‘3’ (usage and exposure).',
      ru: 'The attribute can be used only with guarantee types ‘0’ (guarantee waiver), ‘1’ (comprehensive guarantee) or ‘9’ (Individual guarantee with multiple usage), with either Query Identifier ‘1’ (usage only) or ‘3’ (usage and exposure).',
    },
    {
      code: 'R264',
      en: 'The attribute can be used only with guarantee types ‘0’ (guarantee waiver) and ‘1’ (comprehensive guarantee).\n',
      ru: 'The attribute can be used only with guarantee types ‘0’ (guarantee waiver) and ‘1’ (comprehensive guarantee).\n',
    },
    {
      code: 'R265',
      en: 'One of these data groups is the requester of the query. As a consequence one and only one data group must be present in the IE.',
      ru: 'One of these data groups is the requester of the query. As a consequence one and only one data group must be present in the IE.',
    },
    {
      code: 'R266',
      en: 'For international IEs, the data group can be used only when the query identifier code ‘4’ (general information only) is used.',
      ru: 'For international IEs, the data group can be used only when the query identifier code ‘4’ (general information only) is used.',
    },
    {
      code: 'R267',
      en: 'For international IEs, the value of the attribute is always in EUR.',
      ru: 'For international IEs, the value of the attribute is always in EUR.',
    },
    {
      code: 'R268',
      en: 'This IE can be used only with guarantee types ‘0’ (guarantee waiver) and ‘1’ (comprehensive guarantee).',
      ru: 'This IE can be used only with guarantee types ‘0’ (guarantee waiver) and ‘1’ (comprehensive guarantee).',
    },
    {
      code: 'R269',
      en: 'This attribute is optional for international IEs.',
      ru: 'This attribute is optional for international IEs.',
    },
    {
      code: 'R270',
      en: 'When a Principal is requesting the information then the Access code (of the Principal) becomes required.',
      ru: 'When a Principal is requesting the information then the Access code (of the Principal) becomes required.',
    },
    {
      code: 'R271',
      en: 'This attribute becomes optional for international IEs when guarantee type is ‘0’ or ‘1’, and is required in all other cases.',
      ru: 'This attribute becomes optional for international IEs when guarantee type is ‘0’ or ‘1’, and is required in all other cases.',
    },
    {
      code: 'R275',
      en: 'When the Principal requests information about the guarantee then data group ‘TRADER Principal (owner)’ and data group ‘TRADER Principal (requester)’ must have the same content, and the country of guarantee must be equal to the country of the ‘TRADER Principal (requester)’.',
      ru: 'When the Principal requests information about the guarantee then data group ‘TRADER Principal (owner)’ and data group ‘TRADER Principal (requester)’ must have the same content, and the country of guarantee must be equal to the country of the ‘TRADER Principal (requester)’.',
    },
    {
      code: 'R280',
      en: 'One and only one of the identified data groups must be present (required) in the IE',
      ru: 'One and only one of the identified data groups must be present (required) in the IE',
    },
    {
      code: 'R325',
      en: 'When there are already seals identified in the AAR/AER and/or Arrival Notification/Arrival at Exit (IE507) then ‘State of seals’ is required',
      ru: 'When there are already seals identified in the AAR/AER and/or Arrival Notification/Arrival at Exit (IE507) then ‘State of seals’ is required',
    },
    {
      code: 'R330',
      en: 'The use of paper loading lists having been suppressed and all offices being NCTS offices, this data-group/attribute can no longer be used',
      ru: 'The use of paper loading lists having been suppressed and all offices being NCTS offices, this data-group/attribute can no longer be used',
    },
    {
      code: 'R335',
      en: 'The data groups ‘CONTROL RESULT’ and where necessary ‘SEALS INFO’ become required only when a control by Customs took place at premises of the Authorised Consignor/Trader Exporter.',
      ru: 'The data groups ‘CONTROL RESULT’ and where necessary ‘SEALS INFO’ become required only when a control by Customs took place at premises of the Authorised Consignor/Trader Exporter.',
    },
    {
      code: 'R340',
      en: 'When the messages 43 E_ULD_PER and/or 7 E_ARR_NOT contain SEALS INFO then the ‘State of seals ok’ must be communicated.',
      ru: 'When the messages 43 E_ULD_PER and/or 7 E_ARR_NOT contain SEALS INFO then the ‘State of seals ok’ must be communicated.',
    },
    {
      code: 'R350',
      en: 'The ‘old’ access code can be the same as the ‘new’ access code in case the Principal does not want to change his access code.',
      ru: 'The ‘old’ access code can be the same as the ‘new’ access code in case the Principal does not want to change his access code.',
    },
    {
      code: 'R355',
      en: 'Only ‘GOODS ITEMS’ showing discrepancies are sent back to the Office of Departure/Export.',
      ru: 'Only ‘GOODS ITEMS’ showing discrepancies are sent back to the Office of Departure/Export.',
    },
    {
      code: 'R403',
      en: 'The seasonally opening days and hours periods are to be followed in succession, without any interruption in time. In other words: the ending day of the preceding period must be the day before the starting day of the following period. A time period of one year has to be covered.',
      ru: 'The seasonally opening days and hours periods are to be followed in succession, without any interruption in time. In other words: the ending day of the preceding period must be the day before the starting day of the following period. A time period of one year has to be covered.',
    },
    {
      code: 'R410',
      en: 'The IE consists of top level data groups. Each top level data group has its own data group ACTION which attributes apply to the top level data group and its subsequent sub data groups.\n'
        + 'The sender of the IE will always (in case of Create, Update and Delete) send all values of the occurrence.\n'
        + 'The ‘Operation’ attribute is used to indicate what action is applied to the occurrence in the IE. Its value can be:\n'
        + '‘C’ (create) ,‘U’ (update), ‘D’(delete).\n'
        + 'The ‘Validity date’ together with the attribute ‘Modification Subtype’ allow the following mechanism;\n'
        + 'An occurrence becomes invalid as of the specified ‘Validity date’ when the value of ‘Modification Subtype’ is set to ‘0’ (implies an implicit end date).\n'
        + 'An occurrence and all values of the attributes become valid as of the specified ‘Validity date’ when the value of ‘Modification Subtype’ is set to ‘1’.\n'
        + '‘C’ (create) :\n'
        + 'The create operation is used to create a new occurrence of an entity;\n'
        + '‘U’ (update).\n'
        + 'The update operation is used to change an existing occurrence. It can render an existing occurrence invalid or it can modify existing values of attribute(s) of an occurrence;\n'
        + '‘D’ (delete).\n'
        + 'The delete operation is used to physically delete an occurrence. This operation must only be used when data has been created erroneously.',
      ru: 'The IE consists of top level data groups. Each top level data group has its own data group ACTION which attributes apply to the top level data group and its subsequent sub data groups.\n'
        + 'The sender of the IE will always (in case of Create, Update and Delete) send all values of the occurrence.\n'
        + 'The ‘Operation’ attribute is used to indicate what action is applied to the occurrence in the IE. Its value can be:\n'
        + '‘C’ (create) ,‘U’ (update), ‘D’(delete).\n'
        + 'The ‘Validity date’ together with the attribute ‘Modification Subtype’ allow the following mechanism;\n'
        + 'An occurrence becomes invalid as of the specified ‘Validity date’ when the value of ‘Modification Subtype’ is set to ‘0’ (implies an implicit end date).\n'
        + 'An occurrence and all values of the attributes become valid as of the specified ‘Validity date’ when the value of ‘Modification Subtype’ is set to ‘1’.\n'
        + '‘C’ (create) :\n'
        + 'The create operation is used to create a new occurrence of an entity;\n'
        + '‘U’ (update).\n'
        + 'The update operation is used to change an existing occurrence. It can render an existing occurrence invalid or it can modify existing values of attribute(s) of an occurrence;\n'
        + '‘D’ (delete).\n'
        + 'The delete operation is used to physically delete an occurrence. This operation must only be used when data has been created erroneously.',
    },
    {
      code: 'R411',
      en: 'The ‘Operation’ attribute is used to indicate what action is applied: ‘C’ (create), ‘D’ (delete).\n'
        + 'In case of “updates”, the data group ‘SYSTEM UNAVAILABILITY’ to be updated will be first deleted and then (re)-created.',
      ru: 'The ‘Operation’ attribute is used to indicate what action is applied: ‘C’ (create), ‘D’ (delete).\n'
        + 'In case of “updates”, the data group ‘SYSTEM UNAVAILABILITY’ to be updated will be first deleted and then (re)-created.',
    },
    {
      code: 'R422',
      en: 'This attribute refers to the content of Column 2 in the UN/LOCODE. Column 2 shows the names of the locations (city names) which have been accepted for inclusion in UN/LOCODE, in accordance with the provisions of Recommendation n° 16 (see Rule 460).',
      ru: 'This attribute refers to the content of Column 2 in the UN/LOCODE. Column 2 shows the names of the locations (city names) which have been accepted for inclusion in UN/LOCODE, in accordance with the provisions of Recommendation n° 16 (see Rule 460).',
    },
    {
      code: 'R425',
      en: 'The attribute ‘Season Code’ is NOT used as a flag. It is possible to have up to 9 different ‘seasons’ in a year, depending on a defined time period, which is completely covering the year-period.',
      ru: 'The attribute ‘Season Code’ is NOT used as a flag. It is possible to have up to 9 different ‘seasons’ in a year, depending on a defined time period, which is completely covering the year-period.',
    },
    {
      code: 'R435',
      en: 'The ‘Day in the week (end)’ must be equal or greater then the ‘Day in the week (begin)’.',
      ru: 'The ‘Day in the week (end)’ must be equal or greater then the ‘Day in the week (begin)’.',
    },
    {
      code: 'R445',
      en: 'The SENSITIVE GOODS CODE consists of two parts: the first part represents the HS6 six digit commodity code of the TARIC system, the second part represents the ‘Sensitive Goods Code’ which is a two numeric digit code, defined by DG TAXUD.\n'
        + 'This two digit numeric ‘Sensitive Goods Code’ is only used when the 6 digit HS6 code is not specific enough to identify the concerned goods (example: the codes ‘0405xx’ and ‘2208xx’ in the TARIC system cover both sensitive and non sensitive goods, or more than one sensitive good, and must be extended to 0405xx.01 and 2208xx.01 in order to uniquely identify the sensitive goods).\n'
        + 'These codes are used in the ’’List of Goods which when transported give rise to an increase in the Flat-rate Guarantee” (see proper domain).',
      ru: 'The SENSITIVE GOODS CODE consists of two parts: the first part represents the HS6 six digit commodity code of the TARIC system, the second part represents the ‘Sensitive Goods Code’ which is a two numeric digit code, defined by DG TAXUD.\n'
        + 'This two digit numeric ‘Sensitive Goods Code’ is only used when the 6 digit HS6 code is not specific enough to identify the concerned goods (example: the codes ‘0405xx’ and ‘2208xx’ in the TARIC system cover both sensitive and non sensitive goods, or more than one sensitive good, and must be extended to 0405xx.01 and 2208xx.01 in order to uniquely identify the sensitive goods).\n'
        + 'These codes are used in the ’’List of Goods which when transported give rise to an increase in the Flat-rate Guarantee” (see proper domain).',
    },
    {
      code: 'R450',
      en: '‘Declaration Type’ (box 1 of SAD form) is split up into three subdivisions:\n'
        + '1st subdivision (declaration symbol): up to 3 alphabetic characters related to the type of movement concerned;\n'
        + '2nd subdivision (declaration type): one numeric code related to the type of procedure concerned;\n'
        + '3rd subdivision (declaration transit): up to 5 alphanumeric characters. Only this 3rd subdivision has to be considered in the context of this Transit application.',
      ru: '‘Declaration Type’ (box 1 of SAD form) is split up into three subdivisions:\n'
        + '1st subdivision (declaration symbol): up to 3 alphabetic characters related to the type of movement concerned;\n'
        + '2nd subdivision (declaration type): one numeric code related to the type of procedure concerned;\n'
        + '3rd subdivision (declaration transit): up to 5 alphanumeric characters. Only this 3rd subdivision has to be considered in the context of this Transit application.',
    },
    {
      code: 'R456',
      en: 'for ‘Package Code’ use the alphabetic two-digit UN-code16 going from ‘AE’ = ‘aerosol’ to ‘WB’ = ‘Wickerbottle’.',
      ru: 'for ‘Package Code’ use the alphabetic two-digit UN-code16 going from ‘AE’ = ‘aerosol’ to ‘WB’ = ‘Wickerbottle’.',
    },
    {
      code: 'R460',
      en: 'This UN/LOCODE refers to the 3 digit alphabetical code of the second part of Column 1 in the UN/LOCODE - Code for Ports and other Locations\n'
        + ' Column 1 shows in a first part the ISO 3166 alpha-2 Country Code which is followed by a space and a 3-alpha code for the city (location) name: XX XXX. This combination is invariably identifying the ‘City Name’. This UN/LOCODE city name is used as an attribute in the ‘CUSTOMS OFFICE LSD’ entity.',
      ru: 'This UN/LOCODE refers to the 3 digit alphabetical code of the second part of Column 1 in the UN/LOCODE - Code for Ports and other Locations\n'
        + ' Column 1 shows in a first part the ISO 3166 alpha-2 Country Code which is followed by a space and a 3-alpha code for the city (location) name: XX XXX. This combination is invariably identifying the ‘City Name’. This UN/LOCODE city name is used as an attribute in the ‘CUSTOMS OFFICE LSD’ entity.',
    },
    {
      code: 'R470',
      en: 'The IE15 allows the usage of commodity codes up to 8 digits (national), however only the first six digits are sent to destination with the AAR (international) and to the Office(s) of Transit with the ATR (international).',
      ru: 'The IE15 allows the usage of commodity codes up to 8 digits (national), however only the first six digits are sent to destination with the AAR (international) and to the Office(s) of Transit with the ATR (international).',
    },
    {
      code: 'R485',
      en: 'Not all offices have a time-table, e.g. the Customs Offices that are HIGHER AUTHORITY. However when the Customs Offices are used by Traders then at least a time table should be provided.',
      ru: 'Not all offices have a time-table, e.g. the Customs Offices that are HIGHER AUTHORITY. However when the Customs Offices are used by Traders then at least a time table should be provided.',
    },
    {
      code: 'R486',
      en: 'This attribute can be used to indicate potential specific characteristics of the Customs Office, namely regarding variation of opening hours (e.g. during public holidays, etc.), operational and/or procedural restrictions (e.g. competent only for simplified procedures, competent only for certain goods, the only office authorised to accept flat-rate guarantees, etc.). This attribute will be coded by DG TAXUD after clear definition of the need of the NAs.',
      ru: 'This attribute can be used to indicate potential specific characteristics of the Customs Office, namely regarding variation of opening hours (e.g. during public holidays, etc.), operational and/or procedural restrictions (e.g. competent only for simplified procedures, competent only for certain goods, the only office authorised to accept flat-rate guarantees, etc.). This attribute will be coded by DG TAXUD after clear definition of the need of the NAs.',
    },
    {
      code: 'R489',
      en: 'Reference Number Main Office is used to indicate the Customs Office (local) to which the Customs Office in question is subordinate. The use of this attribute is left up to the NAs.',
      ru: 'Reference Number Main Office is used to indicate the Customs Office (local) to which the Customs Office in question is subordinate. The use of this attribute is left up to the NAs.',
    },
    {
      code: 'R490',
      en: 'Reference Number Higher Authority (see Compendium, Part 1, Title III, Chapter 3, Section 1, B.6.) is used to indicate that the Customs Office in question has a higher authority. As a consequence this attribute becomes required when the Office in question has a higher authority.',
      ru: 'Reference Number Higher Authority (see Compendium, Part 1, Title III, Chapter 3, Section 1, B.6.) is used to indicate that the Customs Office in question has a higher authority. As a consequence this attribute becomes required when the Office in question has a higher authority.',
    },
    {
      code: 'R495',
      en: 'Reference Number Take-over is used to indicate another Customs Office that replaces (take- over) the Customs Office in question when the latter is no longer able to perform NCTS operations',
      ru: 'Reference Number Take-over is used to indicate another Customs Office that replaces (take- over) the Customs Office in question when the latter is no longer able to perform NCTS operations',
    },
    {
      code: 'R500',
      en: 'At least 1 of the 4 attributes must be present.',
      ru: 'At least 1 of the 4 attributes must be present.',
    },
    {
      code: 'R505',
      en: 'This data group is only filled in with the information received from the Principal at departure via IE103 (if any).',
      ru: 'This data group is only filled in with the information received from the Principal at departure via IE103 (if any).',
    },
    {
      code: 'R506',
      en: 'This data group is only used when the received IE106 contains the data group “REQUEST FOR ADDITIONAL INFORMATION”. In this case, IE104 (completed with the requested additional information) will be re-sent to ‘CUSTOMS OFFICE Requested’.',
      ru: 'This data group is only used when the received IE106 contains the data group “REQUEST FOR ADDITIONAL INFORMATION”. In this case, IE104 (completed with the requested additional information) will be re-sent to ‘CUSTOMS OFFICE Requested’.',
    },
    {
      code: 'R510',
      en: 'The attribute \'Continue unloading\' of the data group CTL-CONTROL indicates with its progressive numbering, starting from 1, that the IE043 is transmitted to give the first or further permission to continue the unloading.\n'
        + 'Where the IE brings the permission to start the unloading the data group CTL_CONTROL can not be used.\n'
        + 'Where the IE communicates the permission to continue the unloading then only the attribute MRN of the data group TRANSIT OPERATION and the data groups CTL_CONTROL, CUSTOMS OFFICE Presentation Office and TRADER At Destination are used, all the other data groups and attributes can not be used.',
      ru: 'The attribute \'Continue unloading\' of the data group CTL-CONTROL indicates with its progressive numbering, starting from 1, that the IE043 is transmitted to give the first or further permission to continue the unloading.\n'
        + 'Where the IE brings the permission to start the unloading the data group CTL_CONTROL can not be used.\n'
        + 'Where the IE communicates the permission to continue the unloading then only the attribute MRN of the data group TRANSIT OPERATION and the data groups CTL_CONTROL, CUSTOMS OFFICE Presentation Office and TRADER At Destination are used, all the other data groups and attributes can not be used.',
    },
    {
      code: 'R515',
      en: 'Only one of the attributes \'Date of usage cancellation\' and \'Date of release\' must be present.',
      ru: 'Only one of the attributes \'Date of usage cancellation\' and \'Date of release\' must be present.',
    },
    {
      code: 'R520',
      en: 'The IE is used for 2 different types of amendment:\n'
        + 'IF the ‘Amendment Type Flag’ is ‘0’, then it is allowed to amend all items of the original declaration data,\n'
        + 'IF the ‘Amendment Type Flag’ is ‘1’, then it is only allowed to amend the original declared guarantee, i.e. the data groups GUARANTEE, GUARANTEE REFERENCE, VALIDITY LIMITATION EC and VALIDITY LIMITATION NON EC.\n'
        + 'In both cases it is not allowed to amend the data groups TRADER Principal (Box 50) and CUSTOMS OFFICE of Departure (Box C).',
      ru: 'The IE is used for 2 different types of amendment:\n'
        + 'IF the ‘Amendment Type Flag’ is ‘0’, then it is allowed to amend all items of the original declaration data,\n'
        + 'IF the ‘Amendment Type Flag’ is ‘1’, then it is only allowed to amend the original declared guarantee, i.e. the data groups GUARANTEE, GUARANTEE REFERENCE, VALIDITY LIMITATION EC and VALIDITY LIMITATION NON EC.\n'
        + 'In both cases it is not allowed to amend the data groups TRADER Principal (Box 50) and CUSTOMS OFFICE of Departure (Box C).',
    },
    {
      code: 'R526',
      en: 'This data group becomes mandatory when the declaration contains sensitive goods/suspended goods.',
      ru: 'This data group becomes mandatory when the declaration contains sensitive goods/suspended goods.',
    },
    {
      code: 'R527',
      en: 'This data group becomes mandatory when the declaration contains commodity codes and when the guarantee type is ‘2’ (Individual guarantee by guarantor) or ‘9’ (Individual guarantee with multiple usage).',
      ru: 'This data group becomes mandatory when the declaration contains commodity codes and when the guarantee type is ‘2’ (Individual guarantee by guarantor) or ‘9’ (Individual guarantee with multiple usage).',
    },
    {
      code: 'R605',
      en: 'For an international IE038/538, the values of this attribute must be amongst those defined by the common domain (see IE032, data group ‘TRANSIT OPERATION STATE TYPE’/ EXPORT OPERATION STATE TYPE).',
      ru: 'For an international IE038/538, the values of this attribute must be amongst those defined by the common domain (see IE032, data group ‘TRANSIT OPERATION STATE TYPE’/ EXPORT OPERATION STATE TYPE).',
    },
    {
      code: 'R610',
      en: 'This attribute becomes mandatory when the IE is sent to the TRADER Principal, otherwise it can not be used.',
      ru: 'This attribute becomes mandatory when the IE is sent to the TRADER Principal, otherwise it can not be used.',
    },
    {
      code: 'R650',
      en: 'At least one of the optional data sub groups or attributes must be filled in.',
      ru: 'At least one of the optional data sub groups or attributes must be filled in.',
    },
    {
      code: 'R660',
      en: 'The time has to be given in the format YYYYMMDDHHMM where:',
      ru: 'The time has to be given in the format YYYYMMDDHHMM where:',
    },
    {
      code: 'R665',
      en: 'The timestamp has to be given in the format YYYYMMDDHHMMSS where:',
      ru: 'The timestamp has to be given in the format YYYYMMDDHHMMSS where:',
    },
    {
      code: 'R700',
      en: 'This information is not required when goods of different type covered by the same declaration are packed together in such a way that it is impossible to determine the gross mass of each type of goods.',
      ru: 'This information is not required when goods of different type covered by the same declaration are packed together in such a way that it is impossible to determine the gross mass of each type of goods.',
    },
    {
      code: 'R821',
      en: 'Only those occurrences of the data group shall be included in the message for which the last digit of the attribute \'Risk Analysis result code\' has one of the following values: D, E and Z.',
      ru: 'Only those occurrences of the data group shall be included in the message for which the last digit of the attribute \'Risk Analysis result code\' has one of the following values: D, E and Z.',
    },
    {
      code: 'R824',
      en: 'The \'Item Number (box 32) involved\' shall be indicated if the risk analysis result relates to an item of an Entry summary declaration (ICS), Exit summary declaration or Export declaration (ECS) or Transit declaration (NCTS). In any other case the attribute \'Item Number (box 32) involved\' shall not be used.',
      ru: 'The \'Item Number (box 32) involved\' shall be indicated if the risk analysis result relates to an item of an Entry summary declaration (ICS), Exit summary declaration or Export declaration (ECS) or Transit declaration (NCTS). In any other case the attribute \'Item Number (box 32) involved\' shall not be used.',
    },
    {
      code: 'R825',
      en: 'The \'Specific circumstance indicator\' with value \'E\' (AEO) can be used only under following condition:\n'
        + 'The Office of Departure is located in the Community: the \'Principal\' and all declared \'Consignors-security\' have a valid AEO certificate of type \'AEOF\' or \'AEOS\'.\n'
        + 'The Office of Departure is not located in the Community: the \'Principal\' and all declared \'Consignees-security\' have a valid AEO certificate of type \'AEOF\' or \'AEOS\'.',
      ru: 'The \'Specific circumstance indicator\' with value \'E\' (AEO) can be used only under following condition:\n'
        + 'The Office of Departure is located in the Community: the \'Principal\' and all declared \'Consignors-security\' have a valid AEO certificate of type \'AEOF\' or \'AEOS\'.\n'
        + 'The Office of Departure is not located in the Community: the \'Principal\' and all declared \'Consignees-security\' have a valid AEO certificate of type \'AEOF\' or \'AEOS\'.',
    },
    {
      code: 'R846',
      en: 'The content of the data group \'RISK ANALYSIS\' shall only be sent if the requesting Customs Office is in the Community:\n'
        + 'IE003: IE002.CUSTOMS OFFICE Presentation Office.Reference number\n'
        + 'IE038: IE027. CUSTOMS OFFICE requester.Reference number,\n'
        + 'IE115: IE114.CUSTOMS Office Actual Office of Transit.Reference number.',
      ru: 'The content of the data group \'RISK ANALYSIS\' shall only be sent if the requesting Customs Office is in the Community:\n'
        + 'IE003: IE002.CUSTOMS OFFICE Presentation Office.Reference number\n'
        + 'IE038: IE027. CUSTOMS OFFICE requester.Reference number,\n'
        + 'IE115: IE114.CUSTOMS Office Actual Office of Transit.Reference number.',
    },
    {
      code: 'R876',
      en: 'If the same \'Commercial reference number\' is declared for all goods items, the attribute \'Commercial reference number\' (box 7) on Export/Transit/Import Operation level is used. The attribute \'Commercial reference number\' (ex box 7) on goods item level can not be used.',
      ru: 'If the same \'Commercial reference number\' is declared for all goods items, the attribute \'Commercial reference number\' (box 7) on Export/Transit/Import Operation level is used. The attribute \'Commercial reference number\' (ex box 7) on goods item level can not be used.',
    },
    {
      code: 'R900',
      en: 'When "Declaration Type (box 1)" = \'TIR\', the TIR Carnet Number must be used here, that shall correspond to the Document Reference of Document Type \'952\' in PRODUCED DOCUMENTS/CERTIFICATES.',
      ru: 'When "Declaration Type (box 1)" = \'TIR\', the TIR Carnet Number must be used here, that shall correspond to the Document Reference of Document Type \'952\' in PRODUCED DOCUMENTS/CERTIFICATES.',
    },
    {
      code: 'R901',
      en: 'When "Declaration Type (box 1)" = \'TIR\', the country code (first two digits) in the code of OoDep/Des must have the country regime \'EEC\'.\n',
      ru: 'When "Declaration Type (box 1)" = \'TIR\', the country code (first two digits) in the code of OoDep/Des must have the country regime \'EEC\'.\n',
    },
    {
      code: 'R902',
      en: 'When "Declaration Type (box 1)" = \'TIR\', CUSTOMS OFFICE of Transit cannot be used.',
      ru: 'When "Declaration Type (box 1)" = \'TIR\', CUSTOMS OFFICE of Transit cannot be used.',
    },
    {
      code: 'R903',
      en: 'When "Declaration Type (box 1)" = \'TIR\', Control Result Code \'A3\' can not be used.\n',
      ru: 'When "Declaration Type (box 1)" = \'TIR\', Control Result Code \'A3\' can not be used.\n',
    },
    {
      code: 'R904',
      en: 'When Country of Dispatch is AD or SM, Destination Country can only be EEC.',
      ru: 'When Country of Dispatch is AD or SM, Destination Country can only be EEC.',
    },
    {
      code: 'R905',
      en: 'Destination Country can not be AD or SM when Country of Dispatch is a non EU Contracting party.',
      ru: 'Destination Country can not be AD or SM when Country of Dispatch is a non EU Contracting party.',
    },
    {
      code: 'R906',
      en: 'When Destination Country is AD, the country code of Office of Transit can only be AD.',
      ru: 'When Destination Country is AD, the country code of Office of Transit can only be AD.',
    },
    {
      code: 'R907',
      en: 'When Destination Country is SM, the country code of Office of Transit can only have the country regime EEC.',
      ru: 'When Destination Country is SM, the country code of Office of Transit can only have the country regime EEC.',
    },
    {
      code: 'R908',
      en: 'When Country of Dispatch is a non EU Contracting party, Office of Transit can not have the country code AD or SM.',
      ru: 'When Country of Dispatch is a non EU Contracting party, Office of Transit can not have the country code AD or SM.',
    },
    {
      code: 'R909',
      en: 'When the country code of Office of Destination (box 53) is \'SM\' and the country code of Office of Departure (box C) is \'IT\', declaration type (box 1) can only be \'T2SM\'. When the country code of Office of Destination (box 53) is \'SM\' and the country code of Office of Departure (box C) is not \'IT\', declaration type (box 1) can only be \'T2\' or T2F.',
      ru: 'When the country code of Office of Destination (box 53) is \'SM\' and the country code of Office of Departure (box C) is \'IT\', declaration type (box 1) can only be \'T2SM\'. When the country code of Office of Destination (box 53) is \'SM\' and the country code of Office of Departure (box C) is not \'IT\', declaration type (box 1) can only be \'T2\' or T2F.',
    },
    {
      code: 'R910',
      en: 'When Country of Dispatch is AD or SM, the country regime code of Office of Transit can only be EEC.',
      ru: 'When Country of Dispatch is AD or SM, the country regime code of Office of Transit can only be EEC.',
    },
    {
      code: 'R911',
      en: 'When the country code of the Office of Departure is \'SM\' and the country code of the Office of Destination is an EU country, \'Declaration type (box 1)\' can only be \'T2\' or \'T2F\'.',
      ru: 'When the country code of the Office of Departure is \'SM\' and the country code of the Office of Destination is an EU country, \'Declaration type (box 1)\' can only be \'T2\' or \'T2F\'.',
    },
    {
      code: 'TR0102',
      en: 'In NCTS only 6 characters can be used in this data item. In ECS all 22 characters must be used with trailing zeroes if required.',
      ru: 'In NCTS only 6 characters can be used in this data item. In ECS all 22 characters must be used with trailing zeroes if required.',
    },
    {
      code: 'TR9120',
      en: 'If used, this data item has to be filled in at GOODS ITEM level only if there is more than one value. If there is\n'
        + 'one value, it has to be filled in at HEADER level',
      ru: 'If used, this data item has to be filled in at GOODS ITEM level only if there is more than one value. If there is\n'
        + 'one value, it has to be filled in at HEADER level',
    },
    {
      code: 'TR0100',
      en: 'This field is to be validated against Codelist 14 (Previous document type) if used in a Common Domain\n'
        + 'message. This field is to be validated against the Previous document types valid in the NA if used in an\n'
        + 'External Domain message.',
      ru: 'This field is to be validated against Codelist 14 (Previous document type) if used in a Common Domain\n'
        + 'message. This field is to be validated against the Previous document types valid in the NA if used in an\n'
        + 'External Domain message.',
    },
    {
      code: 'TR0101',
      en: 'This field is to be validated against Codelist 39 (Additional information coded codes) if used in a Common\n'
        + 'Domain message. This field is to be validated against the Additional information coded valid in the NA if used\n'
        + 'in an External Domain message.',
      ru: 'This field is to be validated against Codelist 39 (Additional information coded codes) if used in a Common\n'
        + 'Domain message. This field is to be validated against the Additional information coded valid in the NA if used\n'
        + 'in an External Domain message.',
    },
    {
      code: 'TR0103',
      en: 'This field is to be validated against Codelist 13 (Document Type) if used in a Common Domain message.\n'
        + 'This field is to be validated against the Document Type valid in the NA if used in an External Domain',
      ru: 'This field is to be validated against Codelist 13 (Document Type) if used in a Common Domain message.\n'
        + 'This field is to be validated against the Document Type valid in the NA if used in an External Domain',
    },
    {
      code: 'TR0020',
      en: 'A zero (0) is to be considered as a valid number in this field.',
      ru: 'A zero (0) is to be considered as a valid number in this field.',
    },
    {
      code: 'TR9030',
      en: 'The "Incident flag" data item is required if the data item "Incident information" is not present; in order to\n'
        + 'indicate that an incident has occurred and no other information is available. In any other case , the "Incident\n'
        + 'flag" data item is optional.\n'
        + 'The only allowed value of the field is the value \'1\'.',
      ru: 'The "Incident flag" data item is required if the data item "Incident information" is not present; in order to\n'
        + 'indicate that an incident has occurred and no other information is available. In any other case , the "Incident\n'
        + 'flag" data item is optional.\n'
        + 'The only allowed value of the field is the value \'1\'.',
    },
    {
      code: 'TR0007',
      en: 'The GI data group is Required only when discrepancies have been found in one or more GI or when a new\n'
        + 'goods item has been found during the control.',
      ru: 'The GI data group is Required only when discrepancies have been found in one or more GI or when a new\n'
        + 'goods item has been found during the control.',
    },
    {
      code: 'TR0021',
      en: 'The value of this field must be larger than zero (0).',
      ru: 'The value of this field must be larger than zero (0).',
    },
    {
      code: 'TR9060',
      en: 'IF "Guarantee type" = "0" or "1" and the message is national THEN the attribute is required ELSE IF "Guarantee type" = "0" or "1" and the message is international THEN the attribute is optional\n'
        + 'ELSE the attribute cannot be used.',
      ru: '',
    },
    {
      code: '-111',
      en: 'Blacklisted',
      ru: '',
    },
  ],
}

export default messages
