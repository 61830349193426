import { RuleResult } from 'types/DeclarationP5'
import { SecurityEnumT } from '../../schemas/declarationFormSchema'

const B1848Validator = {
  validateCountryOfRoutingRequirement: (
    security: SecurityEnumT,
  ): RuleResult => (['1', '2', '3'].includes(security) ? 'REQUIRED' : 'NOT_ALLOWED'),

} as const

export default B1848Validator
