import { createContext } from 'react'
import { CurrentCustomer } from '../../types/Customer'
import { User } from '../../routes/Customer/services/user.service'

export interface UserContextProps {
  customer: CurrentCustomer
  user: User | undefined
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps)
