import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserService from '../Customer/services/user.service'

function ActivateAccount() {
  const history = useNavigate()
  const { t } = useTranslation()
  // @ts-ignore
  const { activationToken } = useParams()
  const [loading, setLoading] = useState<boolean>(false)

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  useEffect(() => {
    if (activationToken === undefined || activationToken.length !== 36) {
      toast.error(t('messages.activationUrlError'))
    }
  }, [activationToken])

  const activateAccount = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (activationToken === undefined) {
      toast.error(t('messages.invalidUrlError'))
      return
    }

    setLoading(true)
    UserService
      .activatePublicUser({
        activationToken,
      })
      .then(() => {
        toast.success(t('messages.savingSuccess'))
        setTimeout(() => {
          setLoading(false)
          history('/login')
        }, 500)
      })
      .catch(() => {
        toast.error(t('messages.accountActivationError'))
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }
  return (
    <div className="login__container">
      <div className="login__card">
        <h1
          className="d-flex justify-content-center w-100 rounded-top mb-1 p-3 text-uppercase"
        >
          Tardek T1
        </h1>

        <form onSubmit={activateAccount}>
          <button
            className="btn btn-primary btn-lg mb-3 p-3 w-100"
            type="submit"
            disabled={loading}
          >
            {
              loading && loadingSpinner()
            }
            {
              !loading && (<span>{t('buttons.activateUser')}</span>)
            }
          </button>
        </form>
      </div>
    </div>
  )
}

export default ActivateAccount
