import { toast } from 'react-toastify'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useTransitOperationDuplicationApi from './api'

export default function useTransitOperationDuplication() {
  const {
    duplicateTransitOperation,
  } = useTransitOperationDuplicationApi()

  const [isDuplicationModalVisible, setDuplicationModalVisible] = useState(false)
  const [isDuplicationLoading, setDuplicationLoading] = useState(false)
  const { t } = useTranslation()

  const transitOperationDuplicate = async (id: number, witFiles: boolean) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    if (witFiles === null || witFiles === undefined) throw new Error('Customer ID cannot be null or undefined!')

    return duplicateTransitOperation.mutateAsync({
      transitOperationId: id,
      withFiles: witFiles,
    })
  }

  const duplicateDeclaration = (transitOperationId: number | null | undefined, withFiles: boolean) => {
    if (isDuplicationLoading
      || transitOperationId === null || transitOperationId === undefined) {
      return
    }

    setDuplicationLoading(true)

    transitOperationDuplicate(transitOperationId, withFiles)
      .then((response) => {
        toast.success(t('messages.duplicate', {
          context: 'successful',
          replace: { id: response.id },
        }))
        setDuplicationLoading(false)
        setDuplicationModalVisible(false)
      })
      .catch(() => {
        setDuplicationLoading(false)
        setDuplicationModalVisible(false)
      })
  }

  return {
    transitOperationDuplicate,
    duplicateDeclaration,
    isDuplicationLoading,
    isDuplicationModalVisible,
    setDuplicationModalVisible,
  }
}
