import { RuleResult } from 'types/DeclarationP5'
import { SecurityEnumT } from '../schemas/declarationFormSchema'
import { IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'
import B1858Validator from './transitional/B1858'

const C0191Validator = {
  validatePlaceOfUnloadingRequirement: (security: SecurityEnumT): RuleResult => {
    if (IS_TRANSITIONAL_PERIOD) {
      return B1858Validator.validatePlaceOfUnloadingRequirement(security)
    }

    switch (security) {
      case '1': return 'REQUIRED'
      case '2': return 'OPTIONAL'
      case '3': return 'REQUIRED'
      default:
        return 'NOT_ALLOWED'
    }
  },
}

export default C0191Validator
