import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CarrierRequest, CarrierResponse } from '../../../common/models'
import TransitApiConfig from '../apiConfig'

const { baseUrl, queryKeys: { getCarrier } } = TransitApiConfig.paths.carrier

function useCarrierApi(transitOperationId: number | null) {
  const queryClient = useQueryClient()

  const postCarrier = useMutation({
    mutationFn: async (data: CarrierRequest) => (await axios.post<CarrierResponse>(baseUrl, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getCarrier(transitOperationId) }),
  })

  // TODO GET CARRIERS

  return {
    postCarrier,
  }
}

export default useCarrierApi
