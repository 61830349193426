import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DocumentRequest, DocumentRequestTypeEnum, DocumentResponse } from '../../../common/models'
import DeclarationApi from '../apiConfig'

const {
  baseUrl,
  queryKeys: { getDocumentByOperationIdAndType },
} = DeclarationApi.paths.document

function useDocumentApi(transitOperationId: number | null, documentType: DocumentRequestTypeEnum, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const postDocuments = useMutation({
    mutationFn: async (data: DocumentRequest[]) => (await axios.post<DocumentResponse[]>(baseUrl, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(transitOperationId, documentType) }),
  })

  const putDocuments = useMutation({
    mutationFn: async (data : DocumentRequest[]) => (await axios.put<DocumentResponse[]>(`${baseUrl}`, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(transitOperationId, documentType) }),
  })

  const deleteDocument = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(transitOperationId, documentType) }),
  })

  const getDocuments = useQuery({
    queryFn: async ({ signal }) => (await axios
      .get<DocumentResponse[]>(
      `${baseUrl}/${transitOperationId}`,
      {
        signal,
        params: { documentType },
      },
    )).data,
    queryKey: getDocumentByOperationIdAndType(transitOperationId, documentType),
    staleTime: Infinity, // never stale
    enabled: !!transitOperationId && !postDocuments.isLoading && !putDocuments.isLoading && !deleteDocument.isLoading && !isSubmitting,
  })

  return {
    getDocuments,
    postDocuments,
    putDocuments,
    deleteDocument,
  }
}

export default useDocumentApi
