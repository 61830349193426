import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useMemo } from 'react'
import { useExpanded, usePagination, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { isMobile } from 'react-device-detect'
import { useRestrictionsPage } from '../useRestrictionsPage'
import { getRestrictionTypeOptions, getTableHeaders } from '../config'
import TableHeader from '../../../Transits/Table/TableHeader'
import LoadingBackdrop from '../../../../components/LoadingBackdrop'
import { TablePagination } from '../../../Transits/Table/TablePagination'
import TableBody from './TableBody'
import { ISelectOption } from '../../../../types/IClassifier'
import { NotificationContext } from '../../../../context/NotificationContext'

function Table() {
  const {
    t,
    i18n,
  } = useTranslation()

  const columns = useMemo(() => getTableHeaders(t), [i18n.language])
  const {
    data,
    loading,
    filtersState,
    typeFilter,
    setTypeFilter,
    totalPages,
    setCurrentPageSize,
    setCurrentPageIndex,
    refreshPage,
  } = useRestrictionsPage({
    pageIndex: 0,
    pageSize: 50,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    gotoPage: openPage,
  })

  function openPage(index: number) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    gotoPage(index)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // PAGINATION
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
    },
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } = useTable<any>(
    {
      columns,
      // @ts-ignore
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    useExpanded,
    useSticky,
    usePagination,
  )

  useEffect(() => {
    setCurrentPageIndex(pageIndex)
  }, [pageIndex])
  useEffect(() => {
    setCurrentPageSize(pageSize)
  }, [pageSize])

  const [filters, setFilters] = filtersState

  const typeOptions: ISelectOption[] = getRestrictionTypeOptions(t)

  const { contextHeaderNotificationMessage } = useContext(NotificationContext)
  return (
    <>
      <div
        className={`table__container table-responsive declaration-table__container
        ${isMobile ? 'not-in-mobile' : ''} ${contextHeaderNotificationMessage !== '' ? 'notification--open' : ''}`}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <table {...getTableProps()} className="table sticky table-hover fixed-columns mb-0">
          <TableHeader
            selectFilterOptions={typeOptions}
            headerGroups={headerGroups}
            filterState={{
              filters,
              setFilters,
            }}
            selectFilterState={{
              selectFilter: typeFilter,
              setSelectFilter: setTypeFilter,
            }}
          />
          <TableBody
            isLoading={loading}
            page={page}
            prepareRow={prepareRow}
            getTableBodyProps={getTableBodyProps}
            refreshPage={refreshPage}
          />
          <LoadingBackdrop loading={loading} />
        </table>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </>
  )
}

export default Table
