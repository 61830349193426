import { useTranslation } from 'react-i18next'
import React from 'react'
// eslint-disable-next-line import/no-cycle
import { TabType } from '.'

interface SettingsNavigationBarProps {
  currentTab: TabType;
  changeTab: (newTab: TabType) => void;
  accessibleTabTypes: TabType[];
  loading: boolean;
}

interface NavigationButtonProps {
  tabType: TabType;
  currentTab: TabType;
  changeTab: (newTab: TabType) => void;
  loading: boolean;
}
function NavigationButton(props: NavigationButtonProps) {
  const {
    tabType,
    currentTab,
    changeTab,
    loading,
  } = props
  const { t } = useTranslation()

  return (
    <button
      type="button"
      disabled={loading}
      value={tabType}
      className={`${currentTab === tabType ? 'text-primary ' : ''} btn btn-link px-3 py-0 text-decoration-none navigation-route`}
      onClick={() => changeTab(tabType)}
    >
      <h2>
        {t('pages.page', { context: tabType })}
      </h2>
    </button>
  )
}

function SettingsNavigationBar(props: SettingsNavigationBarProps) {
  const {
    currentTab,
    changeTab,
    accessibleTabTypes,
    loading,
  } = props
  return (
    <div className="navigation-bar">
      {
        accessibleTabTypes.map((tabType) => (
          <NavigationButton
            key={`${tabType}-tab-btn`}
            loading={loading}
            tabType={tabType}
            currentTab={currentTab}
            changeTab={changeTab}
          />
        ))
      }
    </div>
  )
}

export default SettingsNavigationBar
