import axios, { AxiosError } from 'axios'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import CustomsProfileService from 'routes/Customer/CustomsProfile/services/customs_profile.service'
import FormRow from '../../../../components/FormRow'
import { getSelectValue, isNullOrBlank } from '../../../../helpers'
import { apiService, handleResponseData } from '../../../../services/api.service'
import { ISelectOption } from '../../../../types/IClassifier'
import { IConsignmentDetail } from '../../../../types/IConsignmentDetail'
import { ExternalApiError, ValidationError } from '../../../../types/ValidationError'

export interface CustomsProfileType {
  id: number,
  name: string,
  grn: string,
  accessCode: string,
  eori: string,
  isDefault: boolean,
  companyName: string,
  companyPostalCode: string,
  companyStreet: string,
  companyCity: string,
  guaranteeType: string;
  authorityOffice: string;
  authorityCountry: string;
}

export const blankCustomsProfile: CustomsProfileType = {
  id: 0,
  name: '',
  grn: '',
  accessCode: '',
  eori: '',
  isDefault: false,
  companyName: '',
  companyPostalCode: '',
  companyStreet: '',
  companyCity: '',
  guaranteeType: 'GUARANTEE_TYPE_0',
  authorityCountry: '',
  authorityOffice: '',
}

interface ProfileModalParams {
  isProfileModalVisible: boolean;
  toggleProfileModal: Dispatch<SetStateAction<boolean>>;
  guaranteeTypes: Array<ISelectOption>;
  authorityOffice: Array<ISelectOption>;
  authorityCountry: Array<ISelectOption>;
  customsProfile: CustomsProfileType;
  setCustomsProfile: Dispatch<SetStateAction<CustomsProfileType>>;
  isSharing: boolean | undefined;
  customerId: number | undefined;
}

export function NctsProfileModal({
  isProfileModalVisible,
  toggleProfileModal,
  customsProfile,
  setCustomsProfile,
  isSharing,
  customerId,
  guaranteeTypes,
  authorityOffice,
  authorityCountry,
}: ProfileModalParams) {
  const commonCompanyPrefixes = ['Sabiedrība ar ierobežotu atbildību ', 'UŽDAROJI AKCINĖ BENDROVĖ ', 'Usługi Transportowe ']
  const [loading, setLoading] = useState<boolean>(false)
  const [eoriError, setEoriError] = useState<boolean>(false)
  const { t } = useTranslation()

  const fetchEori = () => {
    setLoading(true)
    if (customsProfile.eori.length === 0) {
      toast.error(t('eori.error'))
      setTimeout(() => {
        setLoading(false)
      }, 200)
      return
    }
    axios.get(
      apiService.getFullApiUrl('/eori'),
      {
        params: {
          eoriCode: customsProfile.eori.replace(' ', ''),
        },
      },
    ).then(handleResponseData).then((eoriConsignmentDetails: IConsignmentDetail) => {
      let companyName = eoriConsignmentDetails.name

      const containsTypicalPrefix = commonCompanyPrefixes.find((prefix) => companyName.toLowerCase().startsWith(prefix.toLowerCase()))

      if (containsTypicalPrefix !== undefined) {
        companyName = companyName.substring(containsTypicalPrefix.length).replaceAll('"', '')
      }

      if (companyName.length > 35) {
        companyName = companyName.substr(0, 35)
        toast.warning('Company name was too long and has been shortened. Please check and verify the name.')
      }

      if (customsProfile.id === 0 && !isSharing) {
        CustomsProfileService.postCustomsProfile({
          ...customsProfile,
          companyCity: eoriConsignmentDetails.city,
          companyStreet: eoriConsignmentDetails.street,
          companyName,
          companyPostalCode: eoriConsignmentDetails.zip,
        })
          .then(() => {
            toast.success(t('messages.savingSuccess'))
            setCustomsProfile(blankCustomsProfile)
            toggleProfileModal(false)
            setLoading(false)
          })
          .catch(() => {
            toast.error(t('messages.savingFailed'))
            setLoading(false)
          })
      } else if (!isSharing && customsProfile.id !== 0) {
        CustomsProfileService.updateCustomsProfile({
          ...customsProfile,
          companyCity: eoriConsignmentDetails.city,
          companyStreet: eoriConsignmentDetails.street,
          companyName,
          companyPostalCode: eoriConsignmentDetails.zip,
        }, customsProfile.id)
          .then(() => {
            toast.success(t('messages.savingSuccess'))
            setCustomsProfile(blankCustomsProfile)
            setLoading(false)
            toggleProfileModal(false)
            setEoriError(false)
          })
          .catch(() => {
            toast.error(t('messages.savingFailed'))
          })
        setLoading(false)
      }
    }).catch((error: AxiosError<ValidationError | ExternalApiError>) => {
      let errorCode = ''
      if (error.response && 'errorCode' in error.response.data) {
        errorCode = error.response.data.errorCode ?? '400'
      }
      setEoriError(true)
      setLoading(false)
      toast.error(t('eori.error', { context: errorCode }), { autoClose: 3000 })
    })
  }

  const validateProfileFields = () => {
    if (!isSharing && (isNullOrBlank(customsProfile.name)
        || isNullOrBlank(customsProfile.grn)
        || isNullOrBlank(customsProfile.accessCode)
        || isNullOrBlank(customsProfile.eori)
        || isNullOrBlank(customsProfile.companyPostalCode)
        || isNullOrBlank(customsProfile.companyName)
        || isNullOrBlank(customsProfile.companyStreet)
        || isNullOrBlank(customsProfile.companyCity))) {
      toast.warn(t('messages.missingRequiredFields'))
    }
  }

  const saveProfile = () => {
    validateProfileFields()
    setLoading(true)
    if (customsProfile.id === 0 && !isSharing) {
      CustomsProfileService.postCustomsProfile(customsProfile)
        .then(() => {
          toast.success(t('messages.savingSuccess'))
          setCustomsProfile(blankCustomsProfile)
          toggleProfileModal(false)
          setLoading(false)
          setEoriError(false)
        })
        .catch(() => {
          toast.error(t('messages.savingFailed'))
          setLoading(false)
        })
      setLoading(false)
    } else if ((!isSharing && (customsProfile.id !== 0)) || (isSharing && customsProfile.id !== 0)) {
      CustomsProfileService.updateCustomsProfile(customsProfile, customsProfile.id)
        .then(() => {
          toast.success(t('messages.savingSuccess'))
          setCustomsProfile(blankCustomsProfile)
          setLoading(false)
          toggleProfileModal(false)
          setEoriError(false)
        })
        .catch((reason) => {
          toast.error(t('messages.savingFailed'))
          // eslint-disable-next-line no-console
          console.error(reason)
          let errorCode = ''
          if (reason.response && 'errorCode' in reason.response.data) {
            errorCode = reason.response.data.errorCode ?? '400'
          }

          toast.error(t('eori.error', { context: errorCode }), { autoClose: 3000 })
          setEoriError(true)
        })
      setLoading(false)
    }

    if (isSharing && customerId !== undefined && customsProfile.id === 0) {
      CustomsProfileService.approveAccess(customerId, customsProfile.accessCode).then(() => {
        toast.success(t('messages.savingSuccess'))
        toggleProfileModal(false)
      }).catch((error) => {
        toast.error(t('messages.savingFailed'))
        // eslint-disable-next-line no-console
        console.error(error)
        setLoading(false)
      })
    }
  }
  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  return (
    <Modal
      show={isProfileModalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setEoriError(false)
        setCustomsProfile(blankCustomsProfile)
        toggleProfileModal(false)
      }}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {!isSharing ? (
            <h2
              className="text-capitalize"
            >
              {t('customsProfile.profile', { context: customsProfile && customsProfile.id !== 0 ? 'edit' : 'new' })}
            </h2>
          ) : (
            <h2
              className="text-capitalize"
            >
              {t('customsProfile.shareProfile')}
            </h2>
          )}

          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              setEoriError(false)
              setCustomsProfile(blankCustomsProfile)
              toggleProfileModal(false)
            }}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isSharing && (
          <>
            <FormRow title={t('common.customsProfileName', { context: 'capitalized' })}>
              <input
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={customsProfile.name}
                onChange={(event) => {
                  setCustomsProfile({
                    ...customsProfile,
                    name: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow title={t('common.grnCode', { context: 'capitalized' })}>
              <input
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={customsProfile.grn}
                onChange={(event) => {
                  setCustomsProfile({
                    ...customsProfile,
                    grn: event.target.value,
                  })
                }}
              />
            </FormRow>
          </>
        )}
        <FormRow title={t('customers.accessCode', { context: 'capitalized' })}>
          <input
            className="form-control"
            type="text"
            placeholder={t('common.fieldPlaceholder')}
            value={customsProfile.accessCode}
            onChange={(event) => {
              setCustomsProfile({
                ...customsProfile,
                accessCode: event.target.value,
              })
            }}
          />
        </FormRow>
        <FormRow title={t('common.guaranteeType', { context: 'capitalized' })}>
          <Select
            options={guaranteeTypes}
            className="select option mb-1"
            classNamePrefix="select"
            isClearable={false}
            menuPlacement="auto"
            placeholder={t('common.fieldPlaceholder')}
            value={getSelectValue(customsProfile.guaranteeType, guaranteeTypes)}
            onChange={(option: ISelectOption | null) => {
              if (option !== null) {
                setCustomsProfile({
                  ...customsProfile,
                  guaranteeType: option.value,
                })
              }
            }}
          />
        </FormRow>
        {!isSharing && (
          <>
            <FormRow title={t('common.customsProfileAuthority', { context: 'capitalized' })}>
              <Select
                options={authorityOffice}
                className="select option mb-1"
                classNamePrefix="select"
                isClearable={false}
                menuPlacement="auto"
                placeholder={t('common.fieldPlaceholder')}
                value={getSelectValue(customsProfile.authorityOffice, authorityOffice)}
                onChange={(option: ISelectOption | null) => {
                  if (option !== null) {
                    setCustomsProfile({
                      ...customsProfile,
                      authorityOffice: option.value,
                    })
                  }
                }}
              />
            </FormRow>
            <FormRow title={t('common.customsProfileCountry', { context: 'capitalized' })}>
              <Select
                options={authorityCountry}
                className="select option mb-1"
                classNamePrefix="select"
                isClearable={false}
                menuPlacement="auto"
                placeholder={t('common.fieldPlaceholder')}
                value={getSelectValue(customsProfile.authorityCountry, authorityCountry)}
                onChange={(option: ISelectOption | null) => {
                  if (option !== null) {
                    setCustomsProfile({
                      ...customsProfile,
                      authorityCountry: option.value,
                    })
                  }
                }}
              />
            </FormRow>
          </>
        )}
        {!isSharing && (
          <FormRow title={t('declaration.eoriId', { context: 'capitalized' })}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={customsProfile.eori}
              onChange={(event) => {
                setCustomsProfile({
                  ...customsProfile,
                  eori: event.target.value,
                })
              }}
            />
          </FormRow>
        )}
        {eoriError && (
          <div className="mt-5">
            <div className="position-relative heading">
              <h3>{t('customsProfile.info')}</h3>
            </div>
            <FormRow
              title={t('customers.companyName', { context: 'capitalized' })}
              hasInfoButton
              tooltipContent={(
                <>
                  {t('customers.companyNameExample')}
                </>
              )}
            >
              <input
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={customsProfile.companyName}
                onChange={(event) => {
                  setCustomsProfile({
                    ...customsProfile,
                    companyName: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow
              title={t('customers.companyZipCode', { context: 'capitalized' })}
              hasInfoButton
              tooltipContent={(
                <>
                  {t('customers.companyZipCodeExample')}
                </>
              )}
            >
              <input
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={customsProfile.companyPostalCode}
                onChange={(event) => {
                  setCustomsProfile({
                    ...customsProfile,
                    companyPostalCode: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow
              title={t('customers.companyStreet', { context: 'capitalized' })}
              hasInfoButton
              tooltipContent={(
                <>
                  {t('customers.streetExample')}
                </>
              )}
            >
              <input
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={customsProfile.companyStreet}
                onChange={(event) => {
                  setCustomsProfile({
                    ...customsProfile,
                    companyStreet: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow
              title={t('customers.companyCity', { context: 'capitalized' })}
              hasInfoButton
              tooltipContent={(
                <>
                  {t('customers.companyCityExample')}
                </>
              )}
            >
              <input
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={customsProfile.companyCity}
                onChange={(event) => {
                  setCustomsProfile({
                    ...customsProfile,
                    companyCity: event.target.value,
                  })
                }}
              />
            </FormRow>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          onClick={() => (!eoriError && !isSharing ? fetchEori() : saveProfile())}
        >
          {
            loading && loadingSpinner()
          }
          {
            !loading && (
              <span>{t('buttons.save')}</span>
            )
          }
        </button>
      </Modal.Footer>
    </Modal>
  )
}

