import { Button, Modal } from 'react-bootstrap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomerService from '../services/customer.service'
import LoadingSpinner from '../../../components/LoadingSpinner'

interface ClientApiTokenModalProps {
  isVisible: boolean
  toggleVisibility: () => void
}

function ClientApiTokenModal(
  { isVisible, toggleVisibility }: ClientApiTokenModalProps,
) {
  const { t } = useTranslation()

  const [token, setToken] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const closeModal = () => {
    setToken(undefined)
    toggleVisibility()
  }

  const generateToken = () => {
    if (loading) {
      return
    }
    setLoading(true)
    CustomerService.createClientApiTokenForCurrentCustomer()
      .then((newToken) => {
        setToken(newToken)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      show={isVisible}
      dialogClassName="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{t('customers.apiTokenActions')}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => closeModal()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('customers.generateApiToken', { context: 'description' })}
        </p>
        <code className="text-center">
          <p>{token}</p>
        </code>

        <div className="d-flex justify-content-center mt-2">
          <a
            className="btn btn-outline-dark d-flex align-items-center me-2"
            href={`${process.env.PUBLIC_URL}/client-api-1.0.0.yaml`}
            download="client-api-1.0.0.yaml"
          >
            {t('customers.apiDocs')}
          </a>
          <Button
            type="button"
            variant="secondary"
            className="text-primary"
            onClick={() => {
              generateToken()
            }}
          >
            {
              loading && <LoadingSpinner />
            }
            {
              !loading && <span>{t('customers.generateApiToken')}</span>
            }
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ClientApiTokenModal
