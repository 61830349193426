import React from 'react'
import { Button } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { ArrayScope } from 'types/DeclarationP5'
import { DeclarationForm } from '../form/schemas/declarationFormSchema'
import { FileType } from '../form/schemas/fileSchemas'
import { downloadFileFromCdn } from './FilePreviewModal'
import useFileApi, { FileFolder } from '../hooks/useFile/api'

const expectedScope = /\d+\.transportDocument\.\d+\.files|\d+\.supportingDocument\.\d+\.files|/g

export const isTransportOrSupportingDocumentFilesScope = (scope: ArrayScope):
  scope is `houseConsignment.${number}.consignmentItem.${number}.transportDocument.${number}.files`
    | `houseConsignment.${number}.consignmentItem.${number}.supportingDocument.${number}.files` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

interface DocumentFileNamesProps {
  documentFilesScope: ArrayScope
}

function TransitDocumentFileNames(props: DocumentFileNamesProps) {
  const {
    documentFilesScope,
  } = props
  const { setValue, getValues } = useFormContext<DeclarationForm>()
  if (!isTransportOrSupportingDocumentFilesScope(documentFilesScope)) {
    throw Error('Unable to narrow, invalid scope')
  }
  const { getSignedUrlByFolderAndUUID } = useFileApi()

  const deleteFile = (deletedFileIndex: number) => {
    const removedItemSequenceNumber = getValues(`${documentFilesScope}.${deletedFileIndex}.sequenceNumber`)
    const files = getValues(documentFilesScope)

    files.forEach((item, fileIndex) => {
      if (!item.deleted && (item.sequenceNumber >= removedItemSequenceNumber)) {
        if (item.sequenceNumber === removedItemSequenceNumber) {
          setValue(`${documentFilesScope}.${deletedFileIndex}.deleted`, true)
        } else {
          const sequenceNumber = getValues(`${documentFilesScope}.${fileIndex}.sequenceNumber`)
          setValue(`${documentFilesScope}.${fileIndex}.sequenceNumber`, sequenceNumber - 1)
        }
      }
    })
  }

  const saveBlob = (blob: Blob, fileName: string | null) => {
    const link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.href = URL.createObjectURL(blob)
    link.download = fileName ?? 'file'
    link.click()
    document.body.removeChild(link)
  }

  const saveFile = (documentFile: FileType) => {
    if (documentFile.fileBlob === null && documentFile.uuid !== null) {
      const fileParts = documentFile.uuid.split('/')
      getSignedUrlByFolderAndUUID.mutateAsync({ folder: fileParts[0] as FileFolder, fileUuid: fileParts[1] })
        .then((url) => {
          downloadFileFromCdn(url).then((fileBytes) => saveBlob(fileBytes, documentFile.fileName))
        })
    }
    if (documentFile.fileBlob !== null) {
      saveBlob(documentFile.fileBlob, documentFile.fileName)
    }
  }

  return (
    <div className="row mb-1" key="document-files">
      <div className="col-12 d-flex flex-column">
        {
          (getValues(documentFilesScope) ?? [])
            .map((file, index: number) => (
              <div
                className="d-flex justify-content-end align-items-center"
                key={`document-file-${file.sequenceNumber}-${file.fileName}`}
                hidden={file.deleted}
              >
                <Button
                  type="button"
                  variant="link"
                  className="d-flex overflow-auto align-items-baseline no-text-transform"
                  onClick={() => {
                    saveFile(file)
                  }}
                >
                  <i className="fal d-inline-block fa-file me-2" />
                  <span className="overflow-ellipsis">
                    {file.fileName}
                  </span>
                </Button>
                <Button
                  type="button"
                  variant="danger"
                  size="sm"
                  className="m-1"
                  onClick={() => {
                    deleteFile(index)
                  }}
                >
                  <i className="far fa-trash" />
                </Button>
              </div>
            ))
        }
      </div>
    </div>
  )
}

export default TransitDocumentFileNames
