import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { UseDeclarationFormReturn } from '../../form'
import useTransitOperationApi from './api'
import { parseTransitOperationResponse, toCreateOrUpdateTransitOperationRequest } from './mapper'
import useCustomsOfficeOfTransitApi from '../useCustomsOfficeOfTransit/api'
import { CustomsOfficeOfTransitDeclared } from '../../form/schemas/commonConsignmentSchemas'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'

export default function useTransitOperation(form: UseDeclarationFormReturn) {
  const {
    getValues,
    formState: { isValid, isSubmitting },
    trigger, reset, setValue,
    watch,
  } = form
  const transitOperationId: number | null = watch('id')

  const { deleteCustomsOfficeOfTransit } = useCustomsOfficeOfTransitApi()
  const {
    fetchTransitOperation,
    postTransitOperation,
    putTransitOperation,
  } = useTransitOperationApi(transitOperationId, isSubmitting)

  const populateFormTransitOperation = () => {
    if (fetchTransitOperation.isLoading || isSubmitting) {
      return
    }
    const response = parseTransitOperationResponse(fetchTransitOperation.data)
    response.transitCustomsOffices.sort(sortBySequenceNumber)

    reset({
      ...structuredClone(getValues()),
      ...response,
    })
  }

  useEffect(() => {
    populateFormTransitOperation()
  }, [fetchTransitOperation.data])

  const createTransitOperation = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) throw Error('Non-draft form is invalid')
    const transitOperation = toCreateOrUpdateTransitOperationRequest(getValues())

    const result = await postTransitOperation.mutateAsync(transitOperation)
    setValue('id', result.id!)
  }

  const updateTransitOperation = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return
    if (transitOperationId === null) return

    const transitForm = getValues()

    const deletionRequests = transitForm.transitCustomsOffices
      .filter((item: CustomsOfficeOfTransitDeclared) => item.id !== null && !!item.deleted)
      .map((item: CustomsOfficeOfTransitDeclared) => deleteCustomsOfficeOfTransit.mutateAsync(item.id!))
    try {
      await Promise.all(deletionRequests)
    } catch (error) {
      toast.error('Error deleting offices of transits')
      // eslint-disable-next-line no-console
      console.error('An error has occurred: ', error) // T1-1272
    }
    const transitOperation = toCreateOrUpdateTransitOperationRequest(transitForm)

    await putTransitOperation.mutateAsync({
      id: transitOperationId,
      data: transitOperation,
    })
  }

  const archiveTransitOperation = () => null

  return {
    createTransitOperation,
    updateTransitOperation,
    archiveTransitOperation,
  }
}

