/* eslint-disable react/require-default-props */
import React, { ChangeEvent } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'
import Tooltip from 'rc-tooltip'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../types/IClassifier'
import { InvalidField } from '../routes/Transits/TransitsAside/detailView'
import { getInvalidIconData } from '../routes/Transits/TransitsAside/invalid-icon.helper'
import TransitField from '../routes/Transits/TransitsAside/transitFields'
import { getSelectCountryFilter } from './react-select/CountryFunctions'

export function getSelectOption(value: string | null, selectOptions: Array<ISelectOption> | undefined) {
  return value != null && selectOptions ? selectOptions.find((option) => option.value === value) : null
}

interface FormRowProps {
  title: string,
  hasInfoButton?: boolean,
  children?: React.ReactNode,
  inputType?: 'short' | 'address' | 'textArea',
  withSecurity?: boolean,
  isLast?: true
  addressForm?: IAddressForm;
  setAddressForm?: (values: IAddressForm) => void;
  countriesOptions?: ISelectOption[]
  type?: 'CONSIGNOR' | 'CONSIGNEE' | 'CARRIER';
  invalidFields?: Array<InvalidField>
  tooltipContent?: React.ReactNode
  isDisabled?: boolean
  required?: boolean
}

export interface IAddressForm {
  street: string,
  city: string,
  country: string,
  zip: string,
}

export default function FormRow({
  addressForm,
  children,
  countriesOptions,
  hasInfoButton,
  inputType,
  invalidFields,
  isDisabled,
  isLast,
  required,
  setAddressForm,
  title,
  tooltipContent,
  type,
  withSecurity,
}: FormRowProps) {
  const notify = () => toast.info('Sorry! Not yet implemented.')
  const { t } = useTranslation()

  if (inputType === 'address' && addressForm !== undefined && setAddressForm !== undefined) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-12 col-md-4 input-label">
            {title}
            {hasInfoButton !== undefined && hasInfoButton ? (
              <button type="button" className="btn btn-link btn-gray-700 p-0" onClick={notify}>
                <i className="fal fa-info-circle ms-1" />
              </button>
            ) : null}
          </div>
          <div className="col-12 col-md-8">
            <input
              disabled={isDisabled}
              className={`form-control ${
                getInvalidIconData(
                  // eslint-disable-next-line no-nested-ternary
                  type === 'CONSIGNOR' ? [
                    TransitField.CONSIGNOR_TRADER_STREET_AND_NUMBER,
                    TransitField.CONSIGNOR_SECURITY_TRADER_STREET_AND_NUMBER]
                    : (
                      (type === 'CONSIGNEE') ? [
                        TransitField.CONSIGNEE_TRADER_STREET_AND_NUMBER,
                        TransitField.CONSIGNEE_SECURITY_TRADER_STREET_AND_NUMBER,
                      ] : [
                        TransitField.CARRIER_TRADER_STREET_AND_NUMBER,
                      ]
                    ),
                  invalidFields,
                ).isInvalid || (addressForm.street === '') ? 'is-invalid' : ''
              }`}
              type="text"
              maxLength={35}
              placeholder={t('common.street')}
              value={addressForm.street != null ? addressForm.street : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (setAddressForm !== undefined) {
                  setAddressForm({
                    ...(addressForm),
                    street: event.target.value,
                  } as IAddressForm)
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="d-none d-md-block col-12 col-md-4 input-label" />
          <div className="col-12 col-md-8">
            <input
              disabled={isDisabled}
              className={`form-control ${
                getInvalidIconData(
                  // eslint-disable-next-line no-nested-ternary
                  type === 'CONSIGNOR' ? [
                    TransitField.CONSIGNOR_TRADER_CITY]
                    : (
                      (type === 'CONSIGNEE') ? [
                        TransitField.CONSIGNEE_TRADER_CITY,
                      ] : [
                        TransitField.CARRIER_TRADER_CITY,
                      ]
                    ),
                  invalidFields,
                ).isInvalid || (addressForm.city === '') ? 'is-invalid' : ''
              }`}
              type="text"
              maxLength={35}
              placeholder={t('common.city')}
              value={addressForm.city != null ? addressForm.city : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (setAddressForm !== undefined) {
                  setAddressForm({
                    ...(addressForm),
                    city: event.target.value,
                  } as IAddressForm)
                }
              }}
            />
          </div>
        </div>
        <div className={`row ${isLast ? 'mb-5' : 'mb-2'}`}>
          <div className="d-none d-md-block col-12 col-md-4 input-label" />
          <div className="col-12 col-md-4 mb-2 mb-md-0 pe-md-1">
            <Select
              isDisabled={isDisabled}
              menuPlacement="auto"
              options={countriesOptions}
              filterOption={getSelectCountryFilter}
              className={`select ${
                getInvalidIconData(
                  // eslint-disable-next-line no-nested-ternary
                  type === 'CONSIGNOR' ? [
                    TransitField.CONSIGNOR_TRADER_COUNTRY_CODE,
                    TransitField.CONSIGNOR_SECURITY_TRADER_COUNTRY_CODE,
                  ]
                    : (
                      (type === 'CONSIGNEE') ? [
                        TransitField.CONSIGNEE_TRADER_COUNTRY_CODE,
                        TransitField.CONSIGNEE_SECURITY_TRADER_COUNTRY_CODE,
                      ] : [
                        TransitField.CARRIER_TRADER_COUNTRY_CODE,
                      ]
                    ),
                  invalidFields,
                ).isInvalid || (addressForm.country === '') ? 'form-control p-0 pe-3 is-invalid' : ''
              }`}
              classNamePrefix="select"
              placeholder={t('common.chooseCountry')}
              value={getSelectOption(addressForm.country, countriesOptions)}
              onChange={(option: ISelectOption | null) => {
                if (setAddressForm !== undefined) {
                  setAddressForm({
                    ...(addressForm),
                    country: option?.value,
                  } as IAddressForm)
                }
              }}
            />
          </div>
          <div className="col-12 col-md-4 ps-md-1">
            <input
              disabled={isDisabled}
              className={`form-control ${
                getInvalidIconData(
                  // eslint-disable-next-line no-nested-ternary
                  type === 'CONSIGNOR' ? [
                    TransitField.CONSIGNOR_TRADER_POSTAL_CODE,
                    TransitField.CONSIGNOR_SECURITY_TRADER_POSTAL_CODE,
                  ]
                    : (
                      (type === 'CONSIGNEE') ? [
                        TransitField.CONSIGNEE_TRADER_POSTAL_CODE,
                        TransitField.CONSIGNEE_SECURITY_TRADER_POSTAL_CODE,
                      ] : [
                        TransitField.CARRIER_TRADER_POSTAL_CODE,
                      ]
                    ),
                  invalidFields,
                ).isInvalid || (addressForm.zip === '') ? 'is-invalid' : ''
              }`}
              type="text"
              maxLength={9}
              placeholder={t('common.zip')}
              value={addressForm.zip != null ? addressForm.zip : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (setAddressForm !== undefined) {
                  setAddressForm({
                    ...(addressForm),
                    zip: event.target.value,
                  } as IAddressForm)
                }
              }}
            />
          </div>
        </div>
      </>
    )
  }
  return (
    <div className={`row ${isLast ? 'mb-5' : 'mb-2'}`}>
      <div
        className={`col-12 col-md-4 input-label ${
          withSecurity ? 'has-security' : ''}`}
      >
        <span>
          {title}
          {
            required
            && <sup className="text-danger">*</sup>
          }
        </span>
        {hasInfoButton !== undefined && hasInfoButton ? (
          <Tooltip overlayClassName="tooltip-lg" overlay={<small>{tooltipContent}</small>}>
            <button className="btn btn-link btn-gray-700 p-0 h-25" type="button">
              <i className="fal fa-info-circle ms-1" />
            </button>
          </Tooltip>
        ) : null}
        {withSecurity ? (
          <div className="security">
            {t('declaration.security')}
          </div>
        ) : null}
      </div>
      <div className={`col-12 ${
        inputType === 'short' ? 'col-md-4 pe-md-0' : 'col-md-8'}`}
      >
        {children}
      </div>
    </div>
  )
}
