export const IS_TRANSITIONAL_PERIOD = true
export const ANDORRA = 'AD' as const
export const BULGARIA = 'BG' as const
export const SAN_MARINO = 'SM' as const
export const MARITIME_TRANSPORT_MODE = '1' as const
export const RAIL_TRANSPORT_MODE = '2' as const
export const ROAD_TRANSPORT_MODE = '3' as const
export const AIR_TRANSPORT_MODE = '4' as const
export const POSTAL_CONSIGNMENT_TRANSPORT_MODE = '5' as const
// eslint-disable-next-line no-control-regex
export const cyrillicRegex = /^[\x00-\xFF]{1,1000}$/
export const CL112_CTC_COUNTRIES: readonly string[] = [
  'CH', // Switzerland
  'GB', // United Kingdom
  'GG', // Guernsey
  'IM', // Isle of Man
  'IS', // Iceland
  'JE', // Jersey
  'MK', // North Macedonia
  'NO', // Norway
  'RS', // Serbia
  'TR', // Turkey
  'UA', // Ukraine
]

export const CL178_PREVIOUS_DOCUMENT_UNION_GOODS: readonly string[] = [
  'C612',
  'C620',
  'N822',
  'N825',
]

export const CL234_PREVIOUS_DOCUMENT_EXCISE: readonly string[] = [
  'C651',
  'C658',
]

export const CL182_KING_OF_PACKAGES_UNPACKED: readonly string[] = [
  'PACKAGE_TYPE_NE',
  'PACKAGE_TYPE_NF',
  'PACKAGE_TYPE_NG',
]

export const CL147_SECURITY_AGREEMENT_AREA_COUNTRIES: readonly string[] = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'CH', // Switzerland
  'CY', // Cyprus
  'CZ', // Czechia
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MT', // Malta
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
  'XI', // Northern Ireland
]
export const CL505_WITHOUT_ZIP_COUNTRIES: readonly string[] = [
  'AE',
  'AG',
  'AI',
  'AO',
  'AW',
  'BF',
  'BI',
  'BJ',
  'BM',
  'BO',
  'BQ',
  'BS',
  'BW',
  'BZ',
  'CD',
  'CF',
  'CG',
  'CI',
  'CK',
  'CM',
  'CO',
  'CW',
  'DJ',
  'DM',
  'ER',
  'FJ',
  'GD',
  'GH',
  'GM',
  'GN',
  'GQ',
  'GY',
  'HK',
  'IE',
  'JM',
  'KI',
  'KM',
  'KN',
  'KP',
  'LC',
  'LY',
  'ML',
  'MO',
  'MR',
  'MW',
  'NR',
  'NU',
  'PA',
  'QA',
  'SB',
  'SC',
  'SL',
  'SR',
  'SS',
  'ST',
  'SX',
  'SY',
  'TD',
  'TF',
  'TG',
  'TK',
  'TL',
  'TO',
  'TV',
  'TZ',
  'UG',
  'VU',
  'YE',
  'ZW',
]

export const CL286_GUARANTEES_WITH_GRN: readonly string[] = ['0', '1', '2', '4', '9']
export const CL076_GUARANTEES_WITH_REFERENCE: readonly string[] = ['0', '1', '2', '3', '4', '5', '9']
export const CL190_COUNTRY_ADDRESS_POSTCODE_BASED: readonly string[] = [
  'IE', // Ireland
  'LU', // Luxembourg
  'NL', // Holland
]
export const CL198_COUNTRY_ADDRESS_POSTCODE_ONLY: readonly string[] = [
  'IE', // Ireland
  'LU', // Luxembourg
]

export const CL181_BULK_KIND_OF_PACKAGES: readonly string[] = [
  'VG', // Bulk, gas (at 1031 mbar and 15°C)
  'VL', // Bulk, liquid
  'VO', // Bulk, solid, large particles ("nodules")
  'VQ', // Bulk, liquefied gas (at abnormal temperature/pressure)
  'VR', // Bulk, solid, granular particles ("grains")
  'VS', // Bulk, scrap metal
  'VY', // Bulk, solid, fine particles ("powders")
]
export const CL182_UNPACKED_KIND_OF_PACKAGES: readonly string[] = [
  'NE', //  Unpacked or unpackaged
  'NF', //  Unpacked or unpackaged, single unit
  'NG', //  Unpacked or unpackaged, multiple units
]

export const CL009_COUNTRY_CODES_COMMON_TRANSIT: readonly string[] = [
  'AD', // Andorra
  'AT', // Austria
  'AX', // Åland islands
  'BE', // Belgium
  'BG', // Bulgaria
  'CH', // Switzerland
  'CY', // Cyprus
  'CZ', // Czechia
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GB', // United Kingdom
  'GF', // French Guyana
  'GG', // Guernsey
  'GP', // Guadeloupe
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IM', // Isle of Man
  'IS', // Iceland
  'IT', // Italy
  'JE', // Jersey
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MC', // Monaco
  'MF', // Saint Martin (French part)
  'MK', // North Macedonia
  'MQ', // Martinique
  'MT', // Malta
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RE', // Réunion
  'RO', // Romania
  'RS', // Serbia
  'SE', // Sweden
  'SI', // Slovenia
  'SJ', // Svalbard and Jan Mayen Islands
  'SK', // Slovakia
  'SM', // San Marino
  'TR', // Turkey
  'XI', // Northern Ireland
  'XS', // Serbia
  'YT', // Mayotte
]

export const CL010_CL553_EU_COUNTRY_CODES: readonly string[] = [
  'AT', // Austria
  'AX', // ÅLAND ISLANDS
  'BE', // Belgium
  'BG', // Bulgaria,
  'CY', // Cyprus
  'CZ', // Czechia
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GF', // French Guyana
  'GP', // Guadeloupe
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MC', // Monaco
  'MF', // Saint Martin (French part)
  'MQ', // Martinique
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RE', // Réunion
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
  'XI', // Northern Ireland
  'YT', // Mayotte
]
