import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope, ObjectScope } from 'types/DeclarationP5'
import { ISelectOption } from 'types/IClassifier'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { ActiveBorderTransportMeansType } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { blankActiveBorderTransportMeans } from '../../form'
import R0789Validator from '../../form/validators/R0789'
import { excludeDeleted } from '../../../common/utils/common-util'
import E1103Validator from '../../form/validators/E1103'
import FormFieldArray from '../../components/FormFieldArray'

const scope = 'activeBorderTransportMeans' as const
const expectedScope = /activeBorderTransportMeans\.\d+/g
type ActiveBorderTransportMeansItemScope = NestedFieldScope<`activeBorderTransportMeans.${number}`>
const isActiveBorderTransportMeansItemScope = (unknownScope: ObjectScope): unknownScope is `activeBorderTransportMeans.${number}` => (
  Array.from(unknownScope.matchAll(expectedScope))?.length ?? 0) > 0

function BorderTransportMeans() {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<ActiveBorderTransportMeansType>({
    blankItem: blankActiveBorderTransportMeans,
    name: scope,
  })

  const {
    getValues,
    setValue,
  } = useFormContext<DeclarationForm>()

  const borderModeOfTransport = getValues('borderModeOfTransport')
  const transitOffices = getValues('transitCustomsOffices')
  const activeBorderTransportMeansMultiplicity = R0789Validator.getActiveBorderTransportMeansMultiplicity(transitOffices)

  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, transportIdentificationsOptions] = useCodelist('TRANSPORT_IDENTIFICATIONS', borderModeOfTransport)
  const [, customsOfficeAtBorderOptions] = useCodelist('CUSTOMS_OFFICES')

  useEffect(() => {
    getValues('activeBorderTransportMeans')?.forEach((item, index) => {
      if (!borderModeOfTransport || (!transportIdentificationsOptions.find((
        option,
      ) => option.value === item.typeOfIdentification))) {
        setValue(`activeBorderTransportMeans.${index}.typeOfIdentification`, '', {
          shouldDirty: true,
          shouldTouch: true,
        })
      }
    })
  }, [borderModeOfTransport])
  const isAddButtonDisabled = arrayRule === 'NOT_ALLOWED'
    || getFields().filter(excludeDeleted).length >= activeBorderTransportMeansMultiplicity

  function excludeNotAllowedForActiveTransportMeans(option: ISelectOption) {
    return !option.value.endsWith('_20') && !option.value.endsWith('_31') // Excludes codes not allowed by CL219
  }

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-deprture-transport"
        headingLabel={t('declaration.p5.borderTransportMeans')}
      >
        {getFields().map((borderTransportMeans, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isActiveBorderTransportMeansItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`borderTransportMeans_${borderTransportMeans.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, borderTransportMeans)}
              sequenceNumber={borderTransportMeans.sequenceNumber + 1}
              hidden={borderTransportMeans.deleted}
            >
              <FormField labelKey="declaration.p5.customsOfficeAtTheBorder">
                <FormSelect
                  <ActiveBorderTransportMeansItemScope>
                  field={`${innerItemScope}.customsOfficeAtBorderReferenceNumber`}
                  labelKey="declaration.p5.customsOfficeAtTheBorder"
                  options={customsOfficeAtBorderOptions}
                  type="sync"
                />
              </FormField>

              <FormField labelKey="declaration.p5.typeOfIdentification">
                <FormSelect
                  <ActiveBorderTransportMeansItemScope>
                  field={`${innerItemScope}.typeOfIdentification`}
                  labelKey="declaration.p5.typeOfIdentification"
                  options={transportIdentificationsOptions.filter(excludeNotAllowedForActiveTransportMeans)}
                  isDisabled={!borderModeOfTransport}
                  type="sync"
                />
              </FormField>

              <FormField labelKey="declaration.p5.identificationNumber">
                <Input
                  <ActiveBorderTransportMeansItemScope>
                  field={`${innerItemScope}.identificationNumber`}
                  labelKey="declaration.p5.identificationNumber"
                  type="text"
                  maxLength={E1103Validator.getIdentificationNumberMaxLength()}
                  placeholder={t('declaration.p5.identificationNumber')}
                />
              </FormField>

              <FormField labelKey="declaration.p5.nationality">
                <FormSelect
                  <ActiveBorderTransportMeansItemScope>
                  field={`${innerItemScope}.nationality`}
                  labelKey="declaration.p5.nationality"
                  options={countriesOptions}
                  type="sync"
                />
              </FormField>

              <FormField labelKey="declaration.p5.conveyanceReferenceNumber">
                <Input
                  <ActiveBorderTransportMeansItemScope>
                  field={`${innerItemScope}.conveyanceReferenceNumber`}
                  labelKey="declaration.p5.conveyanceReferenceNumber"
                  type="text"
                  placeholder={t('declaration.p5.conveyanceReferenceNumber')}
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${isAddButtonDisabled ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={isAddButtonDisabled}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewTransportMeans')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default BorderTransportMeans
