import React from 'react'
import { useTranslation } from 'react-i18next'
import { EventLogRow } from '../../types/EventLog'
import { LogTable } from '../Transits/TransitsAside/logView'

function TempLogView(props: { rows: Array<EventLogRow> }) {
  const { t } = useTranslation()

  const columns = React.useMemo(() => [
    {
      Header: t('common.date'),
      accessor: 'date',
    },
    {
      Header: t('common.type'),
      accessor: 'type',
    },
    {
      Header: t('common.message'),
      accessor: 'message',
    },
    {
      Header: t('common.user'),
      accessor: 'user',
    },
  ], [])

  const { rows } = props
  return (
    <div className="my-4 mx-2 mx-sm-3 mx-md-4 flex-grow-1">
      <LogTable columns={columns} data={rows} />
    </div>
  )
}

export default TempLogView
