import React from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope, ObjectScope } from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import { Authorisation } from '../../form/schemas/commonConsignmentSchemas'
import { blankAuthorisations } from '../../form'
import FormSelect from '../../components/FormSelect'
import FormField from '../../components/FormField'
import Input from '../../components/Input'
import { excludeDeleted } from '../../../common/utils/common-util'
import useCodelist from '../../../common/hooks/useCodelist'
import FormFieldArray from '../../components/FormFieldArray'

const scope = 'authorisations' as const
const expectedScope = /authorisations\.\d+/g
type AuthorisationsScope = NestedFieldScope<`authorisations.${number}`>

const isAuthorisationsScope = (unknownScope: ObjectScope): unknownScope is `authorisations.${number}` => (
  Array.from(unknownScope.matchAll(expectedScope))?.length ?? 0) > 0

function Authorisations() {
  const { t } = useTranslation()

  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<Authorisation>({
    blankItem: blankAuthorisations,
    name: scope,
  })

  const [, authorisationsOptions] = useCodelist('AUTHORISATIONS')

  const isAddButtonDisabled = arrayRule === 'NOT_ALLOWED'
    || getFields().filter(excludeDeleted).length >= 9

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-authorisations"
        headingLabel={t('declaration.p5.authorisations')}
      >
        {getFields().map((authorisation, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isAuthorisationsScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`authorisations${authorisation.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, authorisation)}
              sequenceNumber={authorisation.sequenceNumber + 1}
              hidden={authorisation.deleted}
            >
              <FormField labelKey="declaration.p5.authorisationType">
                <FormSelect
                  <AuthorisationsScope>
                  field={`${innerItemScope}.type`}
                  labelKey="declaration.p5.authorisationType"
                  options={authorisationsOptions}
                  type="sync"
                />
              </FormField>

              <FormField labelKey="declaration.p5.authorisationReferenceNumber">
                <Input
                  <AuthorisationsScope>
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.authorisationReferenceNumber"
                  type="text"
                  placeholder={t('declaration.p5.authorisationReferenceNumber')}
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${isAddButtonDisabled ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={isAddButtonDisabled}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewAuthorisation')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default Authorisations
