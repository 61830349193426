import { Modal } from 'react-bootstrap'
import { Document, Page } from 'react-pdf'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageMagnifier from 'components/ImageMagnifier'
import DocViewer from '@cyntler/react-doc-viewer'
import { FileType } from '../../form/schemas/fileSchemas'

export interface FilePreviewModalProps {
  isVisible: boolean
  toggleVisibility: () => void
  file: FileType
  type: 'IMAGE' | 'PDF' | 'EXCEL' | 'WORD'
}

export function downloadFileFromCdn(signedUrl: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    fetch(signedUrl, {
      cache: 'no-cache',
      method: 'GET',
    }).then((responseObject) => {
      responseObject.arrayBuffer().then((bufferedBytes) => {
        resolve(new Blob([bufferedBytes]))
      })
    }).catch(() => {
      reject(Error('Failed to download file'))
    })
  })
}

export function saveBlob(file: FileType | null, source: object | string, type: 'blob' | 'url', pdfName?: string) {
  if (type === 'url' && typeof source === 'string') {
    downloadFileFromCdn(source).then((fileBytes) => saveBlob(file, fileBytes, 'blob', pdfName))
    return
  }
  if (type === 'blob') {
    const link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)
    // @ts-ignore
    link.href = URL.createObjectURL(source)
    link.download = file?.fileName ?? pdfName ?? 'pdf.pdf'
    link.click()
    document.body.removeChild(link)
  }
}

export function FilePreviewModal({
  isVisible,
  toggleVisibility,
  file,
  type,
}: FilePreviewModalProps) {
  const { t } = useTranslation()
  const [pdfPageCount, setPdfPageCount] = useState<number>(0)
  const [rotationDegrees, setRotationDegrees] = useState<number>(360)

  useEffect(() => {
    if (rotationDegrees === 0) {
      setRotationDegrees(360)
    }
  }, [rotationDegrees])

  useEffect(() => {
    if (!isVisible) {
      setRotationDegrees(360)
      setPdfPageCount(0)
    }
  }, [isVisible])

  const changeRotation = (degrees: number) => {
    setRotationDegrees(Math.abs((rotationDegrees + degrees)) % 360)
  }
  const renderButtons = (withRotationButtons = true) => (
    <div className="d-flex mb-2">
      {withRotationButtons && (
        <button
          type="button"
          className="button btn btn-outline-dark flex-grow-1 flex-basis-0"
          onClick={() => changeRotation(-90)}
        >
          <i className="fal fa-undo-alt me-2" />
          <span>{t('buttons.rotate', { context: 'left' })}</span>
        </button>
      )}
      {
        ['PDF', 'IMAGE', 'EXCEL'].includes(type) && (
          <button
            type="button"
            className="button btn btn-dark flex-grow-1 flex-basis-0 mx-1 text-primary"
            onClick={() => saveBlob(file, file.url ?? '', 'url')}
          >
            <i className="fal fa-save me-2" />
            <span>{t('buttons.save')}</span>
          </button>
        )
      }
      {withRotationButtons && (
        <button
          type="button"
          className="button btn btn-outline-dark flex-grow-1 flex-basis-0"
          onClick={() => changeRotation(+90)}
        >
          <i className="fal fa-redo-alt me-2" />
          <span>{t('buttons.rotate', { context: 'right' })}</span>
        </button>
      )}
    </div>
  )

  const getImageModalBody = () => (
    <div className="preview-container h-100 pb-4">
      <ImageMagnifier
        rotationDegree={rotationDegrees}
        imageAlt={file.fileName!}
        imageSrc={file.url ?? ''}
        elementClass={`preview-image content-visibility-auto rotate-${rotationDegrees}`}
      />
    </div>
  )

  const getPdfModalBody = () => (
    <div className="d-flex flex-column h-100 overflow-auto pb-4">
      <Document
        file={file.url}
        onLoadSuccess={({ numPages }) => {
          setPdfPageCount(numPages)
        }}
        noData="No PDF loaded"
        loading={<p>{t('common.loading')}</p>}
        rotate={rotationDegrees === 360 ? 0 : rotationDegrees}
      >
        {Array.from(
          new Array(pdfPageCount),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={1100}
            />
          ),
        )}
      </Document>
    </div>
  )

  const getDocViewerBody = () => (
    <div>
      <div className="d-flex flex-column m-0 p-0 overflow-y-hidden align-items-center align-content-center excel-preview-container">
        {file.url
          ? (
            <DocViewer
              documents={[{
                uri: file.url,
                fileType: file.mimeType,
                fileName: file.fileName ? file.fileName : '',
              }]}
            />
          )
          : 'Unable to load file'}
      </div>
    </div>
  )

  const getModalBody = () => {
    switch (type) {
      case 'IMAGE':
        return [renderButtons(), getImageModalBody()]
      case 'PDF':
        return [renderButtons(), getPdfModalBody()]
      case 'EXCEL':
      case 'WORD':
        return [renderButtons(false), getDocViewerBody()]
      default:
        return <span>Unsupported type</span>
    }
  }

  return (
    <Modal
      show={isVisible}
      size="xl"
      className="overflow-hidden"
      dialogClassName="preview-modal modal align-modal-top align-modal-left"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleVisibility()}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title className="justify-content-end">
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => toggleVisibility()}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        {getModalBody()}
      </Modal.Body>
    </Modal>
  )
}
