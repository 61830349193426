import React, { useEffect, useState } from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'components/LoadingSpinner'
import {
  isMimeTypeExcel, isMimeTypeImage, isMimeTypePdf, isMimeTypeWord,
} from 'helpers'
import { DocumentFile } from 'types/Document'
import { FileResponse } from '../../../models'
import { hasText } from '../../../utils/common-util'
import useFileApi, { FileFolder } from '../../../../TransitOperationEditor/hooks/useFile/api'
import DocumentService from '../../../../../Declaration/services/document.service'
import { FilePreviewModal } from '../../../../../Transits/TransitsAside/FilePreviewModal'

export interface TransitSidebarConsignmentItemDocumentFilesSectionProps {
  files: FileResponse[]
}

function TransitSidebarConsignmentItemDocumentFilesSection({ files }: TransitSidebarConsignmentItemDocumentFilesSectionProps) {
  const { t } = useTranslation()
  const [filesWithUrls, setFilesWithUrls] = useState<Array<FileResponse & { signedUrl: string }> | null>(null)
  const [isPreviewModalVisible, setPreviewModalVisibility] = useState(false)
  const [previewModalFile, setPreviewModalFile] = useState<DocumentFile>()

  const { getSignedUrlByFolderAndUUID } = useFileApi()

  useEffect(() => {
    Promise.all(files.map(async (file: FileResponse) => {
      const fileParts = file.uuid.split('/')
      const signedUrl = await getSignedUrlByFolderAndUUID.mutateAsync({
        folder: fileParts[0] as FileFolder,
        fileUuid: fileParts[1],
      })

      return {
        ...file,
        signedUrl,
      }
    })).then((result) => setFilesWithUrls(result))
  }, [files])

  const getFilePreviewTypeByMimeType = (mimeType: string) => {
    if (isMimeTypeImage(mimeType)) {
      return 'IMAGE'
    }
    if (isMimeTypeExcel(mimeType)) {
      return 'EXCEL'
    }
    if (isMimeTypeWord(mimeType)) {
      return 'WORD'
    }

    return isMimeTypePdf(mimeType) ? 'PDF' : null
  }

  const downloadOrOpenFile = (file: DocumentFile) => {
    if (file.url) {
      const filePreviewType = getFilePreviewTypeByMimeType(file.mimeType)
      switch (filePreviewType) {
        case 'IMAGE':
        case 'PDF':
        case 'EXCEL':
        case 'WORD':
          setPreviewModalFile(file)
          setPreviewModalVisibility(!isPreviewModalVisible)
          break
        default:
          DocumentService.saveBlob(file, file.url, 'url')
      }
    }
  }

  const renderFilePreviewModal = () => {
    if (!previewModalFile) {
      return null
    }

    const filePreviewType = getFilePreviewTypeByMimeType(previewModalFile.mimeType)
    if (!filePreviewType) {
      return null
    }

    return (
      <FilePreviewModal
        type={filePreviewType}
        key="filePreviewModal"
        isVisible={isPreviewModalVisible}
        toggleVisibility={() => setPreviewModalVisibility(!isPreviewModalVisible)}
        file={previewModalFile}
      />
    )
  }

  const renderFileButton = ({
    id: fileId,
    filename,
    mimeType,
    uuid,
    sortOrder,
    signedUrl,
  }: FileResponse & { signedUrl: string }) => {
    const filePreviewType = getFilePreviewTypeByMimeType(mimeType)
    if (filePreviewType === 'IMAGE') {
      return (
        <div className="overflow-x-auto">
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={500}
            overlay={(
              <Tooltip id="tooltip-thumbnail">
                <Image
                  alt={filename}
                  src={signedUrl}
                  style={{
                    maxWidth: '10rem',
                  }}
                />
              </Tooltip>
            )}
          >
            <Button
              className="pb-1 p-0 overflow-ellipsis text-left"
              variant="link"
              onClick={() => downloadOrOpenFile({
                id: fileId,
                file: null,
                uuid,
                filename,
                mimeType,
                sortOrder,
                url: signedUrl,
              })}
            >
              <i
                className="fal fa-image fa-md me-1"
              />
              <span>{filename}</span>
            </Button>
          </OverlayTrigger>
        </div>
      )
    }

    if (filePreviewType === 'PDF') {
      return (
        <div className="overflow-x-auto">
          <Button
            className="pb-1 p-0 overflow-ellipsis text-left"
            variant="link"
            onClick={() => {
              if (hasText(signedUrl)) {
                downloadOrOpenFile({
                  id: fileId,
                  file: null,
                  uuid,
                  filename,
                  mimeType,
                  sortOrder,
                  url: signedUrl,
                })
              }
            }}
          >
            <i
              className="fal fa-expand-arrows-alt fa-file-pdf fa-md me-1"
            />
            <span>{filename}</span>
          </Button>
        </div>
      )
    }

    if (filePreviewType === 'EXCEL') {
      return (
        <div className="overflow-x-auto">
          <Button
            className="pb-1 p-0 overflow-ellipsis text-left"
            variant="link"
            onClick={() => {
              downloadOrOpenFile({
                id: fileId,
                file: null,
                uuid,
                filename,
                mimeType,
                sortOrder,
                url: signedUrl,
              })
            }}
          >
            <i
              className="fal fa-expand-arrows-alt fa-file-spreadsheet fa-md me-1"
            />
            <span>{filename}</span>
          </Button>
        </div>
      )
    }

    if (filePreviewType === 'WORD') {
      return (
        <div className="overflow-x-auto">
          <Button
            className="pb-1 p-0 overflow-ellipsis text-left"
            variant="link"
            onClick={() => {
              downloadOrOpenFile({
                id: fileId,
                file: null,
                uuid,
                filename,
                mimeType,
                sortOrder,
                url: signedUrl,
              })
            }}
          >
            <i
              className="fal fa-expand-arrows-alt fa-file-word fa-md me-1"
            />
            <span>{filename}</span>
          </Button>
        </div>
      )
    }

    return (
      <div className="overflow-x-auto">
        <Button
          className="pb-1 p-0 overflow-ellipsis text-left"
          variant="link"
          onClick={() => {
            downloadOrOpenFile({
              id: fileId,
              file: null,
              uuid,
              filename,
              mimeType,
              sortOrder,
              url: signedUrl,
            })
          }}
        >
          <i
            className="fal fa-expand-arrows-alt fa-file fa-md me-1"
          />
          <span>{filename}</span>
        </Button>
      </div>
    )
  }

  return (
    <div className="col-12">
      <div className="row mb-2">
        <div className="col-5 d-flex text-muted input-label pt-0">
          {t('declaration.p5.files')}
        </div>
        {
          filesWithUrls == null && (
            <div className="col-7 ">
              <LoadingSpinner />
            </div>
          )
        }
        {
          filesWithUrls != null && (
            <div className="col-7 ">
              {
                filesWithUrls.map((fileWithUrl) => (
                  <div className="d-flex" key={`${fileWithUrl.uuid}`}>
                    {renderFileButton(fileWithUrl)}
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      {
        previewModalFile && isPreviewModalVisible && renderFilePreviewModal()
      }
    </div>
  )
}

export default TransitSidebarConsignmentItemDocumentFilesSection
