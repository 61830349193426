import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ActionMeta } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CustomerTableService from 'routes/CustomerTable/services/customer-table.service'
import { PageFilterRequest } from 'types/PageResponse'
import { AxiosError } from 'axios'
import { ISelectOption } from '../../../types/IClassifier'
import { TableFilter } from '../../../types/PageResponse'

interface CustomerSearchHeaderProps {
  filterState: {
    filters: Array<TableFilter>
    setFilters: Dispatch<SetStateAction<Array<TableFilter>>>
  };
}

function CustomerSearchHeader(props: CustomerSearchHeaderProps) {
  const {
    filterState: {
      filters,
      setFilters,
    },
  } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectValueBuffer, setSelectValueBuffer] = useState<ISelectOption | null>(null)

  useEffect(() => {
    const existingFilter = filters.find((item) => item.key === 'customerName')
    if (existingFilter && selectValueBuffer == null) {
      setSelectValueBuffer({
        value: existingFilter.value,
        label: existingFilter.value,
      } as ISelectOption)
    } else if (existingFilter === undefined && selectValueBuffer != null) {
      setTimeout(() => {
        const localStorageSearch = JSON.parse(localStorage.getItem('search')!)
        const isStatusFilterMissingFromCache = localStorageSearch.find((filter: TableFilter) => filter.key === 'customerName') === undefined
        if (isStatusFilterMissingFromCache) {
          setSelectValueBuffer(null)
        }
      }, 50)
    }
  }, [filters])

  useEffect(() => {
    const updatedFilters = filters.filter((item) => item.key !== 'customerName')
    if (selectValueBuffer !== null) {
      updatedFilters.push({
        key: 'customerName',
        value: selectValueBuffer.value,
      })
    }
    setFilters(updatedFilters)
  }, [selectValueBuffer])

  const searchForCustomers = (inputValue: string) => new Promise<ISelectOption[]>((resolve, reject) => {
    setIsLoading(true)
    const requestEvent: PageFilterRequest = {
      pageIndex: 0,
      pageSize: 40,
      filters: [
        {
          key: 'name',
          value: inputValue,
        },
        {
          key: 'external',
          value: 'false',
        },
      ],
    }
    CustomerTableService
      .getCustomerPage(requestEvent)
      .then((customers) => {
        const content = [...customers.content]
        resolve(content.map((item) => ({
          value: item.name,
          label: item.name,
        })))
      })
      .catch((error: AxiosError) => {
        setIsLoading(false)
        reject(error)
      })
  })

  const loadOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolveQuery) => {
    if (inputValue === '' || inputValue === null || inputValue.length < 2) {
      resolveQuery([])
      return
    }

    searchForCustomers(inputValue)
      .then((value) => {
        setIsLoading(false)
        resolveQuery(value)
      })
  })

  return (
    <AsyncCreatableSelect
      key={`${selectValueBuffer?.value}`}
      isClearable
      allowCreateWhileLoading={false}
      isSearchable
      cacheOptions
      noOptionsMessage={() => t('customers.searchUsingName')}
      menuPlacement="auto"
      isLoading={isLoading}
      loadingMessage={() => t('common.loading')}
      loadOptions={loadOptions}
      className="select no-transition menu-list--lg border-0"
      classNamePrefix="select"
      formatCreateLabel={(inputValue) => t('customers.searchForInput', { input: inputValue })}
      placeholder={t('customers.searchUsingName')}
      value={selectValueBuffer}
      /* eslint-disable-next-line consistent-return */
      onKeyDown={(event) => {
        if (isLoading) {
          event.preventDefault()
          event.stopPropagation()
        } else {
          return event
        }
      }}
      onChange={(option: ISelectOption | null, action: ActionMeta<ISelectOption>) => {
        if (option && (action.action === 'select-option' || action.action === 'create-option')) {
          setSelectValueBuffer(((option as ISelectOption)))
        } else {
          setSelectValueBuffer(null)
        }
      }}
    />
  )
}

export default CustomerSearchHeader
