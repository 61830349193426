import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import TransitApiConfig from '../../apiConfig'
import { CustomsRequest } from '../../../../common/models'

const {
  baseUrl,
} = TransitApiConfig.paths.transitOperation

function useSubmitActionApi() {
  const submit = useMutation({
    mutationFn: async (request: CustomsRequest) => (await axios.post<void>(`${baseUrl}/customs`, request)).data,
  })

  return {
    submit,
  }
}

export default useSubmitActionApi
