import React, { useState } from 'react'
import { useExpanded, useTable } from 'react-table'
// eslint-disable-next-line import/extensions
import '../../../types/react-table-config.d.ts'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { EventLog, EventLogRow, EventLogSubRow } from '../../../types/EventLog'
import { dateFormatWithTimeOptions } from '../../../config/dateFormatterConfig'
import messages from '../../../messages/error-messages'
import DocumentService from '../../Declaration/services/document.service'

interface Column {
  Header: string,
  accessor: string;
}

interface LogTableParams {
  columns: Array<Column>,
  data: EventLogRow[],
  // eslint-disable-next-line react/require-default-props
  openFirst?: true | false
}

export function LogTable({ columns, data, openFirst }: LogTableParams) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } = useTable<any>({ columns, data }, useExpanded)

  const [preExpandFirstRow, setPreExpandFirstRow] = useState(openFirst)

  const { t } = useTranslation()

  return (
    <table
      className="table table-sm table-borderless data-table me-2"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id} ${headerGroup.columns?.length}`}>
            <th className="log-status" aria-label="status" />
            {headerGroup.headers.map((column) => (
              <th
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...column.getHeaderProps()}
                key={column.id}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      { /* eslint-disable-next-line react/jsx-props-no-spreading */}
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          if (preExpandFirstRow && rows[0].canExpand) {
            if (rows[0]) {
              rows[0].toggleRowExpanded(true)
            }
            setPreExpandFirstRow(false)
          }

          return (
            <React.Fragment key={row.id + row.cells.length}>
              { row.depth === 0
                  && (
              /* eslint-disable-next-line react/jsx-props-no-spreading */
                    <tr className={` ${row.id}`} {...row.getRowProps()}>
                      <td className="log-status">
                        {
                          // eslint-disable-next-line no-nested-ternary
                          (row.original.status === 'ACCEPTED')
                            ? <i className="fal fa-check-circle text-success fa-md me-1" />
                            : (row.original.status === 'REFUSED')
                              ? <i className="fal fa-exclamation-triangle text-danger fa-md me-1" />
                              : ''
                        }
                      </td>
                      {row.cells.map((cell) => (
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                        <td {...cell.getCellProps()}>
                          {cell.column.Header === 'Message'
                            ? (
                              <button
                                className="btn btn-link border-0 p-0 text-nowrap"
                                type="button"
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...row.getToggleRowExpandedProps()
                                }
                              >
                                {cell.render('Cell')}
                              </button>
                            )
                            : cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )}
              {row.isExpanded && (
                row.subRows.map((subRow, j) => (
                  <tr className="log-row" key={subRow.id}>
                    {isMobile ? null : <td className="log-status" />}
                    <td
                      colSpan={row.cells.length}
                      className={`log-row__messages ${
                        // eslint-disable-next-line no-nested-ternary
                        (row.subRows.length <= 1) ? 'first last'
                          // eslint-disable-next-line no-nested-ternary
                          : (j === 0) ? 'first'
                            : (j === row.subRows.length - 1) ? 'last'
                              : ''}`}
                    >
                      <div className="log-row__messages-container">
                        <div className="log-row__messages-column">
                          <h5>Type</h5>
                          <span>
                            {subRow.original.subType}
                          </span>
                        </div>
                        <div className="log-row__messages-column">
                          <h5>Reason</h5>
                          <span>
                            {(subRow.original.subType === 'FITREL' || subRow.original.subType === 'FILE') ? (
                              <button
                                type="button"
                                className="download-link"
                                style={{ cursor: 'pointer' }}
                                onClick={() => DocumentService.saveBlob(null, subRow.original.reason, 'url', subRow.original.value)}
                              >
                                {t('buttons.downloadPdf')}
                              </button>
                            ) : subRow.original.reason}
                          </span>
                        </div>
                        <div className="log-row__messages-column">
                          <h5>Pointer</h5>
                          <span>
                            {subRow.original.pointer}
                          </span>
                        </div>
                        <div className="log-row__messages-column">
                          <h5>Value</h5>
                          <span>
                            { subRow.original.subType === 'FITREL' ? '' : subRow.original.value }
                          </span>
                        </div>
                        <div className="log-row__messages-column">
                          <h5>Description</h5>
                          <span>
                            {messages.errors.find((itm) => itm.code === subRow.original.reason)?.en}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )))}
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export function mapToRows(eventLogs: Array<EventLog>): Array<EventLogRow> {
  return eventLogs
    .map(({
      functionalErrors, messageContext, created,
    }: EventLog) => {
      let status: 'ACCEPTED' | 'REFUSED' | ''
      if (messageContext === 'IE028'
          || messageContext === 'IE004'
          || (messageContext === 'IE014' && functionalErrors.length === 0)
          || messageContext === 'FITACC'
          || messageContext === 'FITWRO'
          || messageContext === 'FITCTR') {
        status = 'ACCEPTED'
      } else if (messageContext === 'IE906') {
        status = 'REFUSED'
      } else {
        status = functionalErrors.length > 0 ? 'REFUSED' : ''
      }
      let date = ''
      if (created !== undefined && created.length > 0) {
        date = new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(created))
      }

      const subRows: Array<EventLogSubRow> = []
      subRows.push(...functionalErrors
        .map((functionalError) => ({
          subType: functionalError.type,
          reason: functionalError.reason,
          pointer: functionalError.pointer,
          value: functionalError.originalAttributeValue,
          description: '',
        } as EventLogSubRow)))

      return (
        {
          status,
          date,
          type: 'form',
          message: messageContext,
          user: '',
          subRows,
        })
    })
}

export function LogView(props: { rows: Array<EventLogRow>, isFirstRowExpanded: boolean }) {
  const { rows, isFirstRowExpanded } = props
  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Message',
      accessor: 'message',
    },
    {
      Header: 'User',
      accessor: 'user',
    },
  ], [])

  return (
    <div className="aside-main py-4 px-2 px-sm-3 px-md-4 table-responsive">
      <LogTable columns={columns} data={rows} openFirst={isFirstRowExpanded} />
    </div>
  )
}
