import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { hasText } from 'routes/phase5/common/utils/common-util'
import { PageResponse, TableFilter } from 'types/PageResponse'
import { TransitOperationPageResponse, TransitOperationPageAdminResponse } from '../../../common/models'
import TransitApiConfig from '../../../TransitOperationEditor/hooks/apiConfig'

const {
  baseUrl,
  queryKeys: { rootPath },
} = TransitApiConfig.paths.transitOperation

interface TransitOperationPageParameters {
  filters: TableFilter[]
  isAdmin: boolean
  status: string
  pageIndex: number
  pageSize: number
  startDate: string
  endDate: string
  sortBy: string
  orderBy: string
}

function isDatePattern(filterValue: string) {
  return filterValue !== null && filterValue !== undefined
    && filterValue.match('(\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d)$') !== null
}

export default function useTransitOperationPage(parameters: TransitOperationPageParameters) {
  const currentUrlParams = new URLSearchParams()

  const {
    filters, isAdmin,
    status, pageSize,
    pageIndex, sortBy,
    orderBy, startDate, endDate,
  } = parameters

  if (pageIndex) {
    currentUrlParams.append('page', pageIndex.toString())
  }

  if (pageSize) {
    currentUrlParams.append('size', pageSize.toString())
  }

  if (startDate) {
    if (isDatePattern(startDate)) {
      currentUrlParams.append('dateRangeStart', startDate)
    }
  }

  if (endDate) {
    if (isDatePattern(endDate)) {
      currentUrlParams.append('dateRangeEnd', endDate)
    }
  }

  // Custom sort fields here
  if (sortBy) {
    if (sortBy === 'dateReviewed') {
      currentUrlParams.append('sortBy', 'transitOperationInfo.dateReviewed')
    }
    if (sortBy === 'transitDateLimit') {
      currentUrlParams.append('sortBy', 'limitDate')
    } else {
      currentUrlParams.append('sortBy', sortBy)
    }
  }
  if (orderBy) {
    currentUrlParams.append('orderBy', orderBy)
  }

  filters.forEach((filter) => {
    if (hasText(filter.value)) {
      switch (filter.key) {
        case 'dateRangeStart':
        case 'dateRangeEnd':
          break
        case 'mrn':
          currentUrlParams.append('identifier', filter.value)
          break
        case 'notes':
          if (filter.value.length >= 2) {
            currentUrlParams.append(filter.key, filter.value)
          }
          break
        case 'dateModified':
        case 'dateCreated':
        case 'dateReviewed':
          if (isDatePattern(filter.value)) {
            currentUrlParams.append(filter.key, filter.value)
          }
          break
        case 'transitDateLimit':
          if (isDatePattern(filter.value)) {
            currentUrlParams.append('limitDate', filter.value)
          }
          break
        default:
          currentUrlParams.append(filter.key, filter.value)
      }
    }
  })
  if (status !== null && status !== undefined && status !== '') {
    currentUrlParams.append('status', status)
  }

  const getAllTransitOperation = useQuery({
    queryFn: async ({ signal }) => axios
      .get<PageResponse<TransitOperationPageResponse>>(baseUrl, { signal, params: currentUrlParams }),
    queryKey: [rootPath, filters, !isAdmin, status, currentUrlParams, pageIndex, pageSize, sortBy, orderBy],
    staleTime: Infinity,
    enabled: !isAdmin,
  })

  const getAllTransitOperationAdmin = useQuery({
    queryFn: async ({ signal }) => axios
      .get<PageResponse<TransitOperationPageAdminResponse>>(`${baseUrl}/admin`, { signal, params: currentUrlParams }),
    queryKey: [rootPath, filters, isAdmin, status, currentUrlParams, pageIndex, pageSize, sortBy, orderBy],
    staleTime: Infinity,
    enabled: isAdmin,
  })

  return {
    getAllTransitOperation,
    getAllTransitOperationAdmin,
  }
}
