import { IClassifier, ISelectOption } from 'types/IClassifier'
import { getNameForLocale } from 'helpers'

function mapOfficesToSelectOptions(offices: Array<IClassifier>, locale: string): Array<ISelectOption> {
  if (offices === undefined || offices === null) {
    return []
  }
  return offices.map<ISelectOption>((officeClassifier) => ({
    value: officeClassifier.code,
    label: `${officeClassifier.codeLabel}: ${getNameForLocale(officeClassifier, locale)}`,
  }))
}

export default mapOfficesToSelectOptions

