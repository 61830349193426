import { z } from 'zod'

const PlaceOfSchema = z.object({
  id: z.number().nullable(),
  unLocode: z.string(),
  country: z.string(),
  location: z.string(),
  type: z.enum(['LOADING', 'UNLOADING']),
  deleted: z.boolean().optional(),
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PlaceOfLoadingSchema = PlaceOfSchema.superRefine((arg, ctx) => {})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PlaceOfUnloadingSchema = PlaceOfSchema.superRefine((arg, ctx) => {})

export type PlaceOfLoading = z.infer<typeof PlaceOfLoadingSchema>
export type PlaceOfUnloading = z.infer<typeof PlaceOfUnloadingSchema>
