import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { removePrefix } from '../../../common/utils/classifier-util'
import { CL198_COUNTRY_ADDRESS_POSTCODE_ONLY } from '../../../common/utils/ncts-constants'

const C0382Validator = {
  validateHouseNumberRequirement: (formData: DeclarationForm): RuleResult => {
    const country = removePrefix(formData.locationOfGoods?.postcodeAddress?.country ?? null, 'COUNTRY_')
    if (country !== null && CL198_COUNTRY_ADDRESS_POSTCODE_ONLY.includes(country)) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default C0382Validator
