import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { UserPreferenceResponse } from '../../../common/models/user-preference-response'
import { UserPreferenceUpdateRequest } from '../../../common/models/user-preference-update-request'
import TransitApiConfig from '../../../TransitOperationEditor/hooks/apiConfig'

const {
  baseUrl,
  queryKeys: {
    getUserPreferences,
  },
} = TransitApiConfig.paths.userPreferences

function useUserPreferenceApi() {
  const queryClient = useQueryClient()

  const fetchUserPreferences = useQuery({
    queryFn: async ({ signal }) => (await axios
      .get<UserPreferenceResponse[] | undefined>(`${baseUrl}`, { signal })).data,
    queryKey: getUserPreferences(),
    staleTime: Infinity, // never stale
    retry: false,
  })

  const updateUserPreferences = useMutation({
    mutationFn: async (data: UserPreferenceUpdateRequest[]) => (await axios
      .patch<UserPreferenceResponse[]>(`${baseUrl}`, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getUserPreferences() }),
  })

  return {
    fetchUserPreferences,
    updateUserPreferences,
  }
}

export default useUserPreferenceApi
