import {
  IDeclaration, IDeclarationInfo, IGuarantee, ITransport,
} from '../types/IDeclaration'
import { IAction } from './actionTypes'
import { IConsignmentDetailPayload } from '../types/IConsignmentDetail'
import { ISeal } from '../routes/Declaration/Seal/types/Seal'

export const addDeclarationInfo: (declarationInfo: IDeclarationInfo)
=> IAction<IDeclarationInfo> = (declarationInfo: IDeclarationInfo) => ({
  type: 'ADD_DECLARATION_INFO',
  payload: declarationInfo,
})

export const addDeclaration: (declaration: IDeclaration) => IAction<IDeclaration> = (declaration: IDeclaration) => ({
  type: 'ADD_DECLARATION',
  payload: declaration,
})

export const addGuarantee: (guarantee: IGuarantee) => IAction<IGuarantee> = (guarantee: IGuarantee) => ({
  type: 'ADD_GUARANTEE',
  payload: guarantee,
})

export const addTransport: (transport: ITransport) => IAction<ITransport> = (transport: ITransport) => ({
  type: 'ADD_TRANSPORT',
  payload: transport,
})

export const addConsignmentDetails: (consignmentDetail: IConsignmentDetailPayload)
=> IAction<IConsignmentDetailPayload> = (consignmentDetailPayload: IConsignmentDetailPayload) => ({
  type: 'ADD_CONSIGNMENT_DETAILS',
  payload: consignmentDetailPayload,
})

export const addSeal: (seal: Array<ISeal>) => IAction<Array<ISeal>> = (seal: Array<ISeal>) => ({
  type: 'ADD_SEAL',
  payload: seal,
})
