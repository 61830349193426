import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { TransportDocument } from '../../form/schemas/documentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import TransitDocumentFileNames from '../../components/TransitDocumentFileNames'
import useCodelist from '../../../common/hooks/useCodelist'

import { blankTransportDocument } from '../../form'
import DocumentDropdownArea from '../../components/DocumentDropdownArea'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

const expectedScope = /houseConsignment\.\d+\.consignmentItem\.\d+\.transportDocument\.\d+/g
type TransportDocumentItemScope = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.transportDocument.${number}`>
const isTransportDocumentItemScope = (scope: ObjectScope):
  scope is `houseConsignment.${number}.consignmentItem.${number}.transportDocument.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function TransportDocuments({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<TransportDocument>({
    blankItem: blankTransportDocument,
    name: scope,
  })
  const [, transportDocumentTypesOptions] = useCodelist('TRANSPORT_DOCUMENTS')

  const { getValues } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-transport-documents"
        headingLabel={t('declaration.p5.transportDocuments')}
      >
        {getFields().map((transportDocument, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isTransportDocumentItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`transportDocument_${transportDocument.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, transportDocument)}
              sequenceNumber={transportDocument.sequenceNumber + 1}
              hidden={transportDocument.deleted}
            >
              <FormField labelKey="declaration.p5.transportDocumentType">
                <FormSelect
                  <TransportDocumentItemScope>
                  field={`${innerItemScope}.documentType`}
                  labelKey="declaration.p5.transportDocumentType"
                  options={transportDocumentTypesOptions}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.transportDocumentReferenceNumber">
                <Input
                  <TransportDocumentItemScope>
                  type="text"
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.transportDocumentReferenceNumber"
                />
              </FormField>
              <TransitDocumentFileNames
                key={`documentFileNames${transportDocument.id + transportDocument.sequenceNumber}`}
                documentFilesScope={`${innerItemScope}.files`}
              />
              <DocumentDropdownArea
                key={`documents${transportDocument.sequenceNumber + transportDocument.id}`}
                documentScope={`${innerItemScope}`}
                documentFilesScope={`${innerItemScope}.files`}
              />
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' || country === 'FI' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED' || country === 'FI'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewDocument')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default TransportDocuments
