import { useEffect, useState } from 'react'
import { DeclarationCountry } from 'context/DeclarationContext'
import { IClassifier } from 'types/IClassifier'

function useStandardVatRate(
  country: DeclarationCountry | null,
  countryClassifiers: Array<IClassifier>,
) {
  const [countryStandardVatRate, setCountryStandardVatRate] = useState<number>(0)

  useEffect(() => {
    const selectedCountry = countryClassifiers.find((item) => item.codeLabel === country)
    if (!selectedCountry) {
      return
    }
    setCountryStandardVatRate(Number(selectedCountry.attributes.find((attribute) => attribute.key === 'STANDARD_VAT')?.value ?? 0))
  }, [country, countryClassifiers.length])

  return {
    countryStandardVatRate,
  }
}

export default useStandardVatRate
