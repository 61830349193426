import { useQuery } from '@tanstack/react-query'
import { useContext, useMemo } from 'react'
import axios from 'axios'
import ROUTES from 'config/routes'
import { useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { UserContext } from 'context/UserContext'
import { UserRole } from 'hooks/useAuth'
import { minutesToMillis } from 'helpers'
import DeclarationApi from '../apiConfig'
import { NctsProfileNameResponse } from '../../../common/models'
import { DataResponse } from '../request-type'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

const {
  baseUrl,
  queryKeys: {
    rootPath,
    nctsProfilesByCustomerId,
  },
} = DeclarationApi.paths.nctsProfile

function useNctsProfileApi() {
  const { user } = useContext(UserContext)
  const isAdmin = useMemo(() => user?.role === UserRole.ADMIN, [user?.role])
  const location = useLocation()
  const { getValues } = useFormContext<DeclarationForm>()
  const customerId = getValues('customerId')

  const getNctsProfilesForCurrentCustomer = useQuery({
    queryFn: async ({ signal }) => (await axios
      .get<DataResponse<NctsProfileNameResponse[]>>(`${baseUrl}/declaration-profile`, { signal })).data,
    queryKey: [rootPath],
    staleTime: minutesToMillis(15),
    enabled: !isAdmin || location.pathname === ROUTES.newTransit || location.pathname === ROUTES.newTransitP5
        || location.pathname.includes(ROUTES.transitP5),
  })

  const getNctsProfilesByCustomerId = useQuery({
    queryFn: async ({ signal }) => (await axios
      .get<DataResponse<NctsProfileNameResponse[]>>(`${baseUrl}/declaration-profile/${customerId}`, { signal })).data,
    queryKey: nctsProfilesByCustomerId(customerId),
    staleTime: minutesToMillis(15),
    enabled: isAdmin && customerId !== null,
  })

  return {
    getNctsProfilesForCurrentCustomer,
    getNctsProfilesByCustomerId,
  }
}

export default useNctsProfileApi
