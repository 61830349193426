import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import Select from 'react-select'
import FormRow, { getSelectOption } from '../../../components/FormRow'
import { AccessLevel, DeclarationContext } from '../../../context/DeclarationContext'
import { getSelectValue, getValue as getValueTrimmed, sortBySortOrder } from '../../../helpers'
import { purgeForbiddenCharacters } from '../../../helpers/string.helper'
import { IStoreState } from '../../../redux/store'
import { Document, DocumentType } from '../../../types/Document'
import { ISelectOption } from '../../../types/IClassifier'
import { IDeclaration } from '../../../types/IDeclaration'
import { InvalidField } from '../../Transits/TransitsAside/detailView'
import { getInvalidIconData } from '../../Transits/TransitsAside/invalid-icon.helper'
import TransitField from '../../Transits/TransitsAside/transitFields'
import { useDocumentTypeOptions } from './hooks/useDocumentTypeOptions'
import DocumentFileNames from './DocumentFileNames'
import DocumentDropdownArea from './DocumentDropdownArea'

interface DocumentColumnProps {
  declaration: IDeclaration
  previousDocumentTypeOptions: ISelectOption[]
  procedureTypeOptions: ISelectOption[]
  documentTypeOptions: ISelectOption[]
  documents: Array<Document>
  setDocuments: (updatedDocs: Array<Document>) => void
  invalidFields: Array<InvalidField>
  generateNameForUploadedFile: (originalFilename: string) => string
}

function DocumentColumn(props: DocumentColumnProps) {
  const store = useStore()
  const { t } = useTranslation()
  const { metaData: { country, accessLevel } } = useContext(DeclarationContext)
  const {
    previousDocumentTypeOptions: allPreviousDocumentTypeOptions, generateNameForUploadedFile, documentTypeOptions,
    invalidFields, setDocuments, documents, declaration, procedureTypeOptions,
  } = props
  const { options: previousDocumentTypeOptions, setTypeOptions: setPreviousDocumentTypeOptions } = useDocumentTypeOptions(
    allPreviousDocumentTypeOptions,
    country,
    true,
  )

  useEffect(() => {
    setPreviousDocumentTypeOptions(previousDocumentTypeOptions)
    // eslint-disable-next-line react/destructuring-assignment
  }, [country, previousDocumentTypeOptions.length])

  const blankDocument: Document = {
    id: null,
    type: DocumentType.GOODS,
    number: '',
    documentType: null,
    procedureType: null,
    sortOrder: null,
    declarationId: null,
    goodsItemId: null,
    deleted: false,
    orderNumber: null,
    transportDocumentId: null,
    files: [],
  }

  function isDocumentTypeDisabled(selectedValue: string | null) {
    if (selectedValue === '' || selectedValue === null) {
      return false
    }

    switch (selectedValue) {
      case 'PROCEDURE_TYPE_PIIR':
        return true
      case 'PROCEDURE_TYPE_TERM':
        return true
      case 'PROCEDURE_TYPE_TRANS':
        return true
      case 'PROCEDURE_TYPE_VABA':
        return true
      case 'PROCEDURE_TYPE_LADU':
        return true
      case 'PROCEDURE_TYPE_ASENDUS':
        return true
      case 'PROCEDURE_TYPE_LAEV':
        return true
      default:
        return false
    }
  }

  const setFieldValue = (
    field: 'number' | 'documentType' | 'procedureType' | 'sortOrder' | 'files' | 'orderNumber' | 'transportDocumentId',
    newValue: string | ISelectOption | null,
    row: Document,
    selectOption?: boolean,
  ) => {
    const newList = documents.map((item) => {
      if (
        (
          (item.type === row.type)
          && (
            (row.sortOrder !== null && (item.sortOrder === row.sortOrder))
            || (
              (item.sortOrder === null || row.sortOrder === null) && row.id !== null && (item.id === row.id)
            )
          )
        )
      ) {
        const updatedItem: Document = { ...item, files: [...item.files] }
        if (selectOption === true) {
          // @ts-ignore
          updatedItem[field] = newValue && newValue.value !== null ? newValue.value : null
        } else {
          // @ts-ignore
          updatedItem[field] = newValue
        }
        return updatedItem
      }

      return item
    })

    setDocuments(newList)
  }

  const getValue = (value: string | null) => (value != null ? value : '')

  const mapIndexToSortOrder = (doc: Document, index: number) => {
    // eslint-disable-next-line no-param-reassign
    doc.sortOrder = index
    return doc
  }

  const addDocument = (documentType: DocumentType) => {
    const newDoc = { ...blankDocument }
    const reorderedGoodsDocuments = documents
      .filter((doc) => doc.type === documentType && !doc.deleted)
      .sort(sortBySortOrder)
      .map(mapIndexToSortOrder)
    const remainingDeletedDocuments = documents.filter((doc) => doc.type === documentType && doc.deleted)

    const newItemSortOrder = reorderedGoodsDocuments.filter((doc) => (doc.type === documentType) && !doc.deleted).length
    newDoc.sortOrder = reorderedGoodsDocuments ? newItemSortOrder : 0
    newDoc.type = documentType
    // makes sure that previously 1-indexed documents are reordered
    const previousDocuments = documents.filter((doc) => doc.type !== documentType)
    setDocuments([...previousDocuments, ...reorderedGoodsDocuments, newDoc, ...remainingDeletedDocuments])
  }

  const removeDocument = (document: Document, documentType: DocumentType) => {
    const previousDocuments = documents.filter((doc) => doc.type !== documentType)
    if (document.id === null) {
      const allOtherRemainingGoodsItemDocuments = documents
        .filter((doc) => doc.type === documentType && doc.sortOrder !== document.sortOrder && !doc.deleted)
        .map(mapIndexToSortOrder)
      const recombinedDocuments = [...previousDocuments, ...allOtherRemainingGoodsItemDocuments]
      recombinedDocuments.sort(sortBySortOrder)
      setDocuments(recombinedDocuments)
    } else {
      // eslint-disable-next-line max-len
      const allOtherRemainingGoodsItemDocuments = documents.filter((doc) => doc.sortOrder !== document.sortOrder && doc.type === document.type)
      const deletedDoc = { ...document }
      deletedDoc.deleted = true
      setDocuments([...previousDocuments, ...allOtherRemainingGoodsItemDocuments, deletedDoc])
    }
  }

  const getDocumentHeaderRow = (document: Document) => (
    <div className="row mb-2" key={`documentsHeaderRow${document.sortOrder}`}>
      <div className="col-12 col-md-4 input-label">
        {t('goodsItem.documentType')}
      </div>
      <div className="col-12 col-md-4 pe-md-0" key={`documentType${document.sortOrder}`}>
        <Select
          key={`documentTypeSelect${document.sortOrder}`}
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={documentTypeOptions}
          className={`select ${
            getInvalidIconData(
              [
                TransitField.GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_COMPLEMENT_OF_INFORMATION,
                TransitField.GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_DOCUMENT_TYPE,
              ],
              invalidFields,
            ).isInvalid ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          placeholder={t('common.select')}
          value={getSelectValue(document.documentType, documentTypeOptions)}
          onChange={(value) => setFieldValue('documentType', value, document, true)}
        />
      </div>
      <div className="col-12 col-md-4" key={`documentRemoval${document.sortOrder}`}>
        <button
          disabled={accessLevel === AccessLevel.VIEW}
          type="button"
          className="btn btn-link d-flex align-items-center h-100 btn-gray-700 text-decoration-none"
          onClick={() => removeDocument(document, DocumentType.GOODS)}
        >
          <i className="fal fa-times me-2 ms-1 ms-md-0 lh-sm" />
          <span>{t('buttons.remove')}</span>
        </button>
      </div>
    </div>
  )

  const isFileUploadAreaVisible = (document: Document) => {
    // @ts-ignore
    const state: IStoreState = store.getState()
    const transportForm = { ...state.transportReducer.transport }
    if (transportForm.departureCustomsOffice !== null && transportForm.departureCustomsOffice.startsWith('CUSTOMS_OFFICE_LV')) {
      return true
    }
    const documentNumber = getValueTrimmed(document.documentType, 'DOCUMENT_TYPE_')

    return documentNumber === '325' || documentNumber === '380' || documentNumber === '730' || documentNumber === 'ZZZ'
  }

  const getDocumentBodyRow = (document: Document) => {
    const elements = [
      <FormRow title={t('goodsItem.documentNumber')} key={`documentBodyNumber${document.sortOrder}`}>
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          type="text"
          className={`form-control ${
            getInvalidIconData(
              [TransitField.GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_DOCUMENT_REFERENCE],
              invalidFields,
            ).isInvalid ? 'is-invalid' : ''
          }`}
          maxLength={35}
          placeholder={t('common.fieldPlaceholder')}
          value={getValue(document.number)}
          onChange={(event) => setFieldValue('number', purgeForbiddenCharacters(event.target.value), document)}
        />
      </FormRow>,
    ]

    if (isFileUploadAreaVisible(document)) {
      elements.push(
        ...[
          <DocumentFileNames
            document={document}
            documents={documents}
            key={`documentFileNames${document.sortOrder}`}
            accessLevel={accessLevel}
            setDocuments={setDocuments}
          />,
          <DocumentDropdownArea
            document={document}
            key={`documentDropdownArea${document.sortOrder}`}
            accessLevel={accessLevel}
            setFieldValue={setFieldValue}
            generateNameForUploadedFile={generateNameForUploadedFile}
          />,
        ],
      )
    } else {
      elements.push(<div className="pb-3" key={`documentBottomPadding${document.sortOrder}`} />)
    }

    return elements
  }

  const isLockedDocument = (document: Document) => document.type === DocumentType.PREVIOUS
      && document.documentType === 'PREVIOUS_DOCUMENT_TYPE_355'
      && document.number === declaration.mrn

  // @ts-ignore
  return (
    <div className="pb-5 pb-md-3 pt-4 px-4 col-12 col-xl-6" key="documentColumn">
      <div className="position-relative heading" key="documentTitle">
        <h3>{t('goodsItem.documents')}</h3>
        <div className="heading-button d-flex align-items-center">
          <button
            disabled={accessLevel === AccessLevel.VIEW}
            className="btn btn-outline-dark btn-sm d-flex align-items-center"
            type="button"
            onClick={() => addDocument(DocumentType.GOODS)}
          >
            <i className="far fa-plus me-1" />
            <span>{t('buttons.addNew')}</span>
          </button>
        </div>
      </div>
      {
        documents.length > 0
        && documents
          .filter((document) => (document.type === DocumentType.GOODS) && !document.deleted)
          .map((document) => [getDocumentHeaderRow(document), getDocumentBodyRow(document)])
      }

      <div className="border-bottom mb-3 pb-1 position-relative heading" key="previousDocumentTitle">
        <h3>
          {t('goodsItem.previousDocument')}
        </h3>
        <div className="heading-button d-flex align-items-center">
          <button
            disabled={accessLevel === AccessLevel.VIEW}
            className="btn btn-outline-dark btn-sm d-flex align-items-center"
            type="button"
            onClick={() => addDocument(DocumentType.PREVIOUS)}
          >
            <i className="far fa-plus me-1" />
            <span>{t('buttons.addNew')}</span>
          </button>
        </div>
      </div>

      {
        documents
        && documents.filter((document) => document.type === DocumentType.PREVIOUS && !document.deleted).length > 0
        && documents.filter((document) => document.type === DocumentType.PREVIOUS && !document.deleted).map((document) => (
          <React.Fragment key={`previousDocumentFields${document.sortOrder}${document.id}`}>
            {/* {
              country === DeclarationCountry.ESTONIA && (
                <FormRow
                  title={t('goodsItem.previousDocumentProcedure')}
                  key={`previousDocumentProcedureTypes${document.sortOrder}`}
                >
                  <Select
                    key={`previousDocumentProcedureType${document.sortOrder}`}
                    options={procedureTypeOptions}
                    isDisabled={(document.documentType === 'PREVIOUS_DOCUMENT_TYPE_EX') || isLockedDocument(document) || accessLevel === AccessLevel.VIEW}
                    className={`select ${
                      getInvalidIconData(
                        [
                          TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_PROCEDURE_TYPE,
                        ],
                        invalidFields,
                      ).isInvalid ? 'form-control p-0 pe-3 is-invalid' : ''
                    }
                `}
                    classNamePrefix="select"
                    isClearable
                    menuPlacement="auto"
                    placeholder={t('common.select')}
                    value={getSelectOption(document.procedureType, procedureTypeOptions)}
                    onChange={(option: ISelectOption | null) => setFieldValue('procedureType', option, document, true)}
                  />
                </FormRow>
              )
            } */}
            <div className="row mb-2" key={`documentsHeaderRow${document.sortOrder}`}>
              <div className="col-12 col-md-4 input-label">
                {t('goodsItem.previousDocumentType')}
              </div>
              <div className="col-12 col-md-4 pe-md-0" key={`documentType${document.sortOrder}`}>
                <Select
                  key={`previousDocumentDocumentType${document.sortOrder}`}
                  options={previousDocumentTypeOptions}
                  isDisabled={isDocumentTypeDisabled(document.procedureType)
                      || isLockedDocument(document) || accessLevel === AccessLevel.VIEW}
                  className={`select ${
                    getInvalidIconData(
                      [
                        TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_DOCUMENT_TYPE,
                      ],
                      invalidFields,
                    ).isInvalid ? 'form-control p-0 pe-3 is-invalid' : ''
                  }`}
                  placeholder={t('common.select')}
                  classNamePrefix="select"
                  isClearable
                  menuPlacement="auto"
                  value={getSelectOption(document.documentType, previousDocumentTypeOptions)}
                  onChange={(option: ISelectOption | null) => setFieldValue('documentType', option, document, true)}
                />
              </div>
              {(document.sortOrder !== null
                  && documents
                    .filter((documentIndex) => documentIndex.type === DocumentType.PREVIOUS && !documentIndex.deleted)
                    .findIndex((indexDoc) => indexDoc.sortOrder === document.sortOrder) > 0)
                && (
                  <div className="col-12 col-md-4" key={`documentRemoval${document.sortOrder}`}>
                    <button
                      disabled={accessLevel === AccessLevel.VIEW}
                      type="button"
                      className="btn btn-link d-flex align-items-center h-100 btn-gray-700 text-decoration-none"
                      onClick={() => removeDocument(document, DocumentType.PREVIOUS)}
                    >
                      <i className="fal fa-times me-2 ms-1 ms-md-0 lh-sm" />
                      <span>{t('buttons.remove')}</span>
                    </button>
                  </div>
                )}
            </div>
            <FormRow
              title={t('goodsItem.previousDocumentNumber')}
              key={`previousDocumentNumber${document.sortOrder}`}
            >
              <input
                key={`previousDocumentNumberInput${document.sortOrder}`}
                className={`form-control ${
                  getInvalidIconData(
                    [
                      TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_DOCUMENT_REFERENCE,
                      TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES,
                      TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_ITEM,
                      TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_COMPLEMENT_OF_INFORMATION,
                    ],
                    // eslint-disable-next-line react/destructuring-assignment
                    props.invalidFields,
                  ).isInvalid ? 'is-invalid' : ''
                }`}
                disabled={(document.procedureType === 'PROCEDURE_TYPE_PIIR')
                    || isLockedDocument(document) || accessLevel === AccessLevel.VIEW}
                type="text"
                maxLength={35}
                placeholder={t('common.fieldPlaceholder')}
                value={getValue(document.number)}
                onChange={(event) => setFieldValue('number', purgeForbiddenCharacters(event.target.value), document)}
              />
            </FormRow>
            {country === 'FI' && (
              <>
                <FormRow
                  hasInfoButton
                  tooltipContent={(
                    <>
                      {t('declaration.finnish.arexNumber')}
                    </>
                  )}
                  title={t('goodsItem.previousDocumentOrderNumber')}
                >
                  <input
                    className="form-control"
                    type="text"
                    disabled={isLockedDocument(document) || accessLevel === AccessLevel.VIEW}
                    placeholder={t('common.fieldPlaceholder')}
                    onChange={(event) => setFieldValue('orderNumber', purgeForbiddenCharacters(event.target.value), document)}
                    value={getValue(document.orderNumber)}
                  />
                </FormRow>
                <FormRow
                  hasInfoButton
                  tooltipContent={(
                    <>
                      {t('declaration.finnish.transportDocumentId')}
                    </>
                  )}
                  title={t('goodsItem.previousDocumentOrderNumber')}
                >
                  <input
                    className="form-control"
                    type="text"
                    disabled={isLockedDocument(document) || accessLevel === AccessLevel.VIEW}
                    placeholder={t('common.fieldPlaceholder')}
                    onChange={(event) => {
                      setFieldValue('transportDocumentId', purgeForbiddenCharacters(event.target.value), document)
                    }}
                    value={getValue(document.transportDocumentId)}
                  />
                </FormRow>
              </>
            )}
            <div className="mb-3 pt-1" key={`previousDocumentPadding${document.sortOrder}`} />
          </React.Fragment>
        ))
      }
    </div>
  )
}
export default DocumentColumn
