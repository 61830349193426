import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { BadRequestPayload } from 'types/Errors'
import { mapReasonToTranslationString } from 'helpers/errors.helper'
import i18n from 'i18n'
import { isAxiosError } from 'routes/phase5/common/utils/api-util'
import { EditTransitOperationAuditRequest } from '../../../common/models/edit-transit-operation-audit-request'
import useTransitOperationAuditApi from './api'
import { NctsError } from '../useTransitOperationContext'

export default function useTransitOperationAudit() {
  const { t } = useTranslation()

  const {
    putTransitOperationAudit,
  } = useTransitOperationAuditApi()

  const updateTransitOperationAudit = async (auditId: number, { status, reason }: EditTransitOperationAuditRequest) => {
    if (auditId === null) return

    try {
      await putTransitOperationAudit.mutateAsync({
        id: auditId,
        data: {
          status,
          reason,
        },
      })
      toast.success(t('audit.updated', { context: 'successful' }))
    } catch (error) {
      if (isAxiosError(error)) {
        const data = error.response?.data
        if (error.response?.request.status === 400 && data?.message === 'CONSTRAINT_VIOLATION') {
          data?.errors.forEach((errorItem: BadRequestPayload) => {
            const translatedString = mapReasonToTranslationString(errorItem)
            if (translatedString !== '' && translatedString !== null && translatedString !== undefined) {
              toast.error(`${t(`translations${i18n.language.toUpperCase()}:${errorItem.field}`)} ${translatedString}`)
            }
          })
        }
        if (data.message.startsWith('Prepared request contains')) {
          toast.error(t('messages.nctsValidationError', { count: data?.errors.length }))
          data?.errors.forEach((errorItem: NctsError, index: number) => {
            if (index < 10) {
              toast.error(`${errorItem.field} ${errorItem.description}`, { autoClose: (index + 1) * 2000 })
            }
          })
        }
        if (error.response?.request.status === 503) {
          toast.error(
            t('common.submit', { context: 'service_unavailable' }),
            {
              autoClose: false,
              closeOnClick: false,
              draggable: false,
            },
          )
        }
      }
    }
  }

  return {
    updateTransitOperationAudit,
  }
}
