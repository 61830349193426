import { DeclarationForm } from '../schemas/declarationFormSchema'
import {
  excludeDeleted, hasText, nonNullConsignee, nonNullConsignor, tradersEqual,
} from '../../../common/utils/common-util'
import { Consignee, Consignor } from '../schemas/tradersSchema'
import { DepartureTransportMeansType } from '../schemas/commonConsignmentSchemas'

function nonNullDepartureMeans(means: DepartureTransportMeansType) {
  return hasText(means.typeOfIdentification)
    && hasText(means.identificationNumber)
    && hasText(means.nationality)
}
const R0506Validator = {
  hasMultipleUniqueConsignorForHouseConsignment: (formData: DeclarationForm): boolean => {
    const houses = formData.houseConsignment.filter(excludeDeleted)
    if (houses.length !== houses
      .filter((houseConsignment) => houseConsignment.consignor !== null && !nonNullConsignor(houseConsignment.consignor)).length) {
      return true
    }

    let previousConsignor: Consignor | null = null

    return formData.houseConsignment.filter(excludeDeleted).some((houseConsignment, index) => {
      if (index === 0) {
        previousConsignor = houseConsignment.consignor
        return false
      }
      if (!tradersEqual(previousConsignor, houseConsignment.consignor)) {
        return true
      }

      previousConsignor = houseConsignment.consignor
      return false
    })
  },
  hasMultipleUniqueConsigneeForHouseConsignment: (formData: DeclarationForm): boolean => {
    const houses = formData.houseConsignment.filter(excludeDeleted)
    if (houses.length !== houses
      .filter((houseConsignment) => houseConsignment.consignee !== null && !nonNullConsignee(houseConsignment.consignee)).length) {
      return true
    }

    let previousConsignee: Consignee | null = null

    return formData.houseConsignment.filter(excludeDeleted).some((houseConsignment, index) => {
      if (index === 0) {
        previousConsignee = houseConsignment.consignee
        return false
      }

      if (!tradersEqual(previousConsignee, houseConsignment.consignee)) {
        return true
      }
      previousConsignee = houseConsignment.consignee
      return false
    })
  },
  hasMultipleUniqueDepartureTransportMeansForHouseConsignment: (formData: DeclarationForm): boolean => {
    if (formData.houseConsignment
      .filter(excludeDeleted)
      .flatMap((houseConsignment) => houseConsignment.departureTransportMeans)
      .filter(excludeDeleted)
      .every((departureTransportMeans) => !nonNullDepartureMeans(departureTransportMeans))) {
      return true
    }

    let previousMeans: DepartureTransportMeansType[] = []
    return formData.houseConsignment.filter(excludeDeleted).some((houseConsignment, index) => {
      if (index === 0) {
        previousMeans = houseConsignment.departureTransportMeans.filter(excludeDeleted)
        return false
      }

      const hasDistinctMeans = houseConsignment.departureTransportMeans
        .filter(excludeDeleted)
        .some((means) => {
          const previousHouseContainsCurrentMeans = previousMeans
            .some((prevMeans) => prevMeans.typeOfIdentification === means.typeOfIdentification
          && prevMeans.identificationNumber === means.identificationNumber
          && prevMeans.nationality === means.nationality)

          return !previousHouseContainsCurrentMeans
        })

      previousMeans = houseConsignment.departureTransportMeans.filter(excludeDeleted)
      return hasDistinctMeans
    })
  },
  hasMultipleUniqueReferenceNumberUcrForHouseConsignment: (formData: DeclarationForm): boolean => {
    const houses = formData.houseConsignment.filter(excludeDeleted)
    if (houses.length !== houses.filter((houseConsignment) => !hasText(houseConsignment.referenceNumberUCR)).length) {
      return true
    }

    let previousReferenceNumber = ''
    return formData.houseConsignment
      .filter(excludeDeleted).some((houseConsignment, index) => {
        if (index === 0) {
          previousReferenceNumber = houseConsignment.referenceNumberUCR
          return false
        }

        if (previousReferenceNumber !== houseConsignment.referenceNumberUCR) {
          return true
        }

        previousReferenceNumber = houseConsignment.referenceNumberUCR
        return false
      })
  },
} as const

export default R0506Validator
