import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'components/LoadingSpinner'
import { ISelectOption } from '../../../../types/IClassifier'

interface NotificationActionHeaderProps {
  selectOptions: ISelectOption[];
  loading: boolean;
  view: ISelectOption;
  setView: Dispatch<SetStateAction<ISelectOption>>;
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  updateHeaderNotificationMessage: () => void;
}

function NotificationActionHeader({
  selectOptions,
  loading,
  view,
  setView,
  setIsConfirmationModalOpen,
  updateHeaderNotificationMessage,
}: NotificationActionHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="title-bar">
      <div className="d-flex justify-content-end align-items-center ps-3 w-100">
        <Select
          options={selectOptions}
          className="w-25 d-none d-lg-block"
          isDisabled={loading}
          defaultValue={selectOptions[0]}
          value={view}
          onChange={(option: ISelectOption | null) => {
            if (option !== null) {
              setView(option)
            }
          }}
        />
        <div className="ps-4 d-none d-lg-block">
          {view.value === 'SEND' && (
            <button
              type="button"
              disabled={loading}
              className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm d-none d-lg-block"
              onClick={() => {
                setIsConfirmationModalOpen(true)
              }}
            >
              {loading ? <LoadingSpinner /> : t('notification.send')}
            </button>
          )}
          {view.value === 'EDIT' && (
            <button
              type="button"
              disabled={loading}
              className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm d-none d-lg-block"
              onClick={() => {
                updateHeaderNotificationMessage()
              }}
            >
              {loading ? <LoadingSpinner /> : t('notification.update')}
            </button>
          )}
        </div>
      </div>
      <div className="d-md-block d-xl-none d-lg-none mt-3 me-3">
        <Dropdown>
          <Dropdown.Toggle variant="link" className="p-0 hide-caret" id="dropdown-basic">
            <i className="fas fa-ellipsis-h fa-md" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-sm">
            <Dropdown.Item>
              <Select
                options={selectOptions}
                className="w-100"
                defaultValue={selectOptions[1]}
                value={view}
                onChange={(option: ISelectOption | null) => {
                  if (option !== null) {
                    setView(option)
                  }
                }}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              {view.value === 'SEND' && (
                <button
                  type="button"
                  className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
                  onClick={() => {
                    if (!loading) {
                      setIsConfirmationModalOpen(true)
                    }
                  }}
                >
                  {t('notification.send')}
                </button>
              )}
              {view.value === 'EDIT' && (
                <button
                  type="button"
                  className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
                  onClick={() => {
                    if (!loading) {
                      updateHeaderNotificationMessage()
                    }
                  }}
                >
                  {t('notification.update')}
                </button>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default NotificationActionHeader
