import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { DeclarationDetailed, IDeclaration, IDeclarationReviewResponse } from '../../../types/IDeclaration'
import { de } from 'date-fns/locale'

function postNewDeclaration(declaration: IDeclaration): Promise<IDeclaration> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl('/declaration'),
      {
        declarationType: declaration.declarationType.toString(),
        isGoodsTradersEnabled: !!declaration.isGoodsTradersEnabled,
        isSecurityEnabled: !!declaration.isSecurityEnabled,
        profileName: declaration.profile,
        specialCondition: declaration.specialCondition,
        securityReferenceNumber: declaration.securityReferenceNumber,
        shipmentNumber: declaration.shipmentNumber,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while creating declaration')
      reject(error)
    })
  })
}

function getDeclaration(id: string | number): Promise<DeclarationDetailed> {
  return new Promise((resolve) => {
    axios.get(apiService.getFullApiUrl(`/declaration/${id}`))
      .then(handleResponseData)
      .then((value) => resolve(value))
      .catch(handleApiException)
  })
}

function putDeclaration(declarationId: number, declaration: IDeclaration): Promise<IDeclaration> {
  return new Promise((resolve, reject) => {
    axios.put(
      apiService.getFullApiUrl(`/declaration/${declarationId}`),
      {
        declarationType: declaration.declarationType.toString(),
        isGoodsTradersEnabled: !!declaration.isGoodsTradersEnabled,
        isSecurityEnabled: !!declaration.isSecurityEnabled,
        profileName: declaration.profile,
        specialCondition: declaration.specialCondition,
        securityReferenceNumber: declaration.securityReferenceNumber,
        shipmentNumber: declaration.shipmentNumber,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while saving declaration')
      reject(error)
    })
  })
}

function submitDeclarationToCustoms(declarationId: number, name: string | null, currencyExchangeRate?: number) {
  const apiUrl = apiService.getFullApiUrl('/customs/submit-declaration')

  return axios
    .post(
      apiUrl,
      { ...{ declarationId, name, currencyExchangeRate } },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function submitAmendmentToCustoms(declarationId: number, name: string | null) {
  const apiUrl = apiService.getFullApiUrl('/customs/declaration-amendment')

  return axios
    .post(
      apiUrl,
      { ...{ declarationId, name } },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function submitCancellationToCustoms(declarationId: number) {
  return axios
    .post(
      apiService.getFullApiUrl('/customs/cancel-declaration'),
      { ...{ declarationId } },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function deleteDeclaration(declarationId: number) {
  return axios
    .delete(apiService.getFullApiUrl(`/declaration/${declarationId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function duplicateDeclaration(declarationId: number, withFiles: boolean) {
  return axios
    .post(apiService.getFullApiUrl(`/declaration/duplicate/${declarationId}`), {}, {
      params: {
        withFiles,
      },
    })
    .then<IDeclaration>(handleResponseData)
    .catch(handleApiException)
}
function reassignDeclarationOwner(declarationId: number, newCustomerId: number) {
  return axios
    .post(apiService.getFullApiUrl('/declaration/reassign'), { declarationId, newCustomerId })
    .then<IDeclaration>(handleResponseData)
    .catch(handleApiException)
}

function changeDeclarationReviewStatus(declarationId: number, reviewed: boolean) {
  return axios
    .post(
      apiService.getFullApiUrl('/declaration/review-status'),
      { declarationId, isReviewed: reviewed },
    )
    .then<IDeclarationReviewResponse>(handleResponseData)
    .catch(handleApiException)
}

function changeDeclarationStatus(declarationId: number) {
  return axios
    .patch(
      apiService.getFullApiUrl(`/declaration/status/${declarationId}`),
    )
    .then<IDeclaration>(handleResponseData)
    .catch(handleApiException)
}

function giveFeedback(
  declarationId: number,
  feedback: string,
  isDeclarationDeclined: boolean,
  isPdfIncluded: boolean,
  emails: Array<string>,
  blindCopyEmail: string,
) {
  return axios
    .patch(
      apiService.getFullApiUrl(`/declaration/feedback/${declarationId}`),
      {
        feedback, isDeclarationDeclined, isPdfIncluded, emails, blindCopyEmail,
      },
    )
    .then<IDeclaration>(handleResponseData)
    .catch(handleApiException)
}

function addNotes(declarationId: number, notes: string) {
  return axios
    .patch(
      apiService.getFullApiUrl(`/declaration/notes/${declarationId}`),
      { note: notes },
    )
    .then<IDeclaration>(handleResponseData)
    .catch(handleApiException)
}

const DeclarationService = {
  postNewDeclaration,
  getDeclaration,
  putDeclaration,
  submitDeclarationToCustoms,
  submitAmendmentToCustoms,
  submitCancellationToCustoms,
  deleteDeclaration,
  duplicateDeclaration,
  reassignDeclarationOwner,
  changeDeclarationReviewStatus,
  giveFeedback,
  changeDeclarationStatus,
  addNotes,
}

export default DeclarationService
