const addDuplicationNumberToName = (filename: string, duplicationDepth: number): string => {
  if (filename === undefined || filename.length === 0) {
    return filename
  }
  const lastIndexOf = filename.lastIndexOf('.')
  const onlyFilename = filename.substring(0, lastIndexOf)
  const fileExtension = filename.substring(lastIndexOf + 1, filename.length)

  return `${onlyFilename} (${duplicationDepth}).${fileExtension}`
}

const FileService = {
  addDuplicationNumberToName,
}

export default FileService
