import { useStore } from 'react-redux'
import { IDeclaration } from '../../../../types/IDeclaration'
import { IStoreState } from '../../../../redux/store'
import GuaranteeService from '../../services/guarantee.service'
import { addGuarantee } from '../../../../redux/actions'

function useGuarantee() {
  const store = useStore()
  const createOrEditGuarantee = (savedDeclaration: IDeclaration, isDraft: boolean) => {
    // @ts-ignore
    const state: IStoreState = store.getState()
    if (savedDeclaration.id === null) {
      return Promise.reject(Error('Missing declaration id'))
    }
    if (state.guaranteeReducer.guarantee.id === null) {
      return GuaranteeService
        .postNewGuarantee(savedDeclaration.id, state.guaranteeReducer.guarantee, isDraft)
        .then(
          (response) => {
            store.dispatch(addGuarantee(response))
          },
        )
    }
    return GuaranteeService
      .putGuarantee(savedDeclaration.id, state.guaranteeReducer.guarantee, isDraft)
      .then((response) => {
        store.dispatch(addGuarantee(response))
      })
  }

  return { createOrEditGuarantee }
}

export default useGuarantee
