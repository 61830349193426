import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import TransitApiConfig from '../apiConfig'
import { AuthorisationResponse, CreateOrUpdateAuthorisationRequest } from '../../../common/models'

const {
  baseUrl,
  queryKeys: { getAuthorisationByTransitOperationId },
} = TransitApiConfig.paths.authorisation

function useAuthorisationApi(transitOperationId: number | null, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const postAuthorisation = useMutation({
    mutationFn: async (data: Array<CreateOrUpdateAuthorisationRequest>) => (await axios
      .post<Array<AuthorisationResponse>>(baseUrl, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getAuthorisationByTransitOperationId(transitOperationId) }),
  })

  const putAuthorisation = useMutation({
    mutationFn: async (data: Array<CreateOrUpdateAuthorisationRequest>) => (await axios
      .put<Array<AuthorisationResponse>>(baseUrl, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getAuthorisationByTransitOperationId(transitOperationId) }),
  })

  const fetchAuthorisations = useQuery({
    queryKey: getAuthorisationByTransitOperationId(transitOperationId),
    queryFn: async ({ signal }) => (await axios
      .get<Array<AuthorisationResponse>>(`${baseUrl}/${transitOperationId}`, { signal })).data,
    enabled: !!transitOperationId && !putAuthorisation.isLoading && !postAuthorisation.isLoading && !isSubmitting,
    staleTime: Infinity, // never stale
  })

  const deleteAuthorisation = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
  })

  return {
    postAuthorisation,
    putAuthorisation,
    fetchAuthorisations,
    deleteAuthorisation,
  }
}

export default useAuthorisationApi
