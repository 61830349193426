import { ComponentType } from 'react'

// Page components:
import Login from './Login'
import Transits from './Transits'
import Declaration from './Declaration'
import Docs from './Docs'
import { Customer } from './Customer'
import { CustomerTable } from './CustomerTable'
import AccountActivation from './AccountActivation'
import UserProfile from './UserProfile'
import RestrictionTable from './Restrictions/RestrictionTable'
import { Restriction } from './Restrictions/Restriction'
import DeclarationAudit from './DeclarationAudit'
import Download from './Download'
import Register from './Register/Register'
import ActivateAccount from './AccountActivation/ActivateAccount'
import CustomerAdmin from './Customer/CustomerAdmin'
import Settings from './Settings'
import ROUTES from '../config/routes'
import TransitOperationEditor from './phase5/TransitOperationEditor'
import TransitOperationTable from './phase5/TransitOperationTable'
import TransitOperationAudit from './phase5/TransitOperationAudit'
import Landing from './Landing'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface Page {
  url: string
  component: ComponentType<any>
  showInNav?: boolean
  isHeaderHidden?: boolean
  roles: Array<'USER' | 'CUSTOMER_ADMIN' | 'ADMIN'> | null
}

export const Pages: Page[] = [
  {
    url: '/',
    component: Landing,
    isHeaderHidden: true,
    roles: null,
  },
  {
    url: 'login',
    component: Login,
    isHeaderHidden: false,
    roles: null,
  },
  {
    url: 'activate/:activationToken',
    component: AccountActivation,
    isHeaderHidden: true,
    roles: null,
  },
  {
    url: 'activate/public/:activationToken',
    component: ActivateAccount,
    isHeaderHidden: true,
    roles: null,
  },
  {
    url: 'reset-password/:activationToken',
    component: AccountActivation,
    isHeaderHidden: true,
    roles: null,
  },
  {
    url: 'transits',
    component: Transits,
    showInNav: true,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: ROUTES.transit_operation,
    component: TransitOperationTable,
    showInNav: true,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: ROUTES.transit_audit,
    component: TransitOperationAudit,
    showInNav: true,
    roles: ['ADMIN'],
  },
  {
    url: 'customers',
    component: CustomerTable,
    showInNav: true,
    roles: ['ADMIN'],
  },
  {
    url: 'company',
    component: CustomerAdmin,
    showInNav: true,
    roles: ['CUSTOMER_ADMIN'],
  },
  {
    url: 'new-customer',
    component: Customer,
    roles: ['ADMIN'],
  },
  {
    url: 'customer/:customerId',
    component: Customer,
    roles: ['ADMIN'],
  },
  {
    url: 'external-customer',
    component: Customer,
    roles: ['ADMIN'],
  },
  {
    url: 'external-customer/:customerId',
    component: Customer,
    roles: ['ADMIN'],
  },
  {
    url: 'restrictions',
    component: RestrictionTable,
    showInNav: true,
    roles: ['ADMIN'],
  },
  {
    url: 'new-restriction',
    component: Restriction,
    roles: ['ADMIN'],
  },
  {
    url: 'restriction/:restrictionId',
    component: Restriction,
    roles: ['ADMIN'],
  },
  {
    url: 'new-transit',
    component: Declaration,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: ROUTES.newTransitP5,
    component: TransitOperationEditor,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: ROUTES.transitP5Url,
    component: TransitOperationEditor,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: 'transit/:declarationId',
    component: Declaration,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: 'profile',
    component: UserProfile,
    roles: ['ADMIN', 'CUSTOMER_ADMIN', 'USER'],
  },
  {
    url: 'docs',
    component: Docs,
    roles: null,
  },
  {
    url: 'download',
    component: Download,
    isHeaderHidden: true,
    roles: null,
  },
  {
    url: 'audit',
    component: DeclarationAudit,
    showInNav: true,
    roles: ['ADMIN'],
  },
  {
    url: 'register',
    component: Register,
    isHeaderHidden: false,
    showInNav: false,
    roles: null,
  },
  {
    url: 'settings',
    component: Settings,
    showInNav: true,
    roles: ['ADMIN'],
  },
]
