import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { toast } from 'react-toastify'
import TransitApiConfig from '../../../TransitOperationEditor/hooks/apiConfig'

const {
  baseUrl,
  queryKeys: {
    rootPath,
  },
} = TransitApiConfig.paths.transitOperation

function useTransitOperationDraftDeletion() {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const deleteDraft = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [rootPath] }),
    onError: () => { toast.error(t('messages.delete', { context: 'failed' })) },
  })

  const deleteTransitOperationDraft = async (id: number | undefined) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')
    await deleteDraft.mutateAsync(id)
  }

  return {
    deleteTransitOperationDraft,
  } as const
}

export default useTransitOperationDraftDeletion
