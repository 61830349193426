import React from 'react'
import Lottie from 'lottie-react'
import { useTranslation } from 'react-i18next'
import customsMapImg from '../../../assets/gfx/customs-map.svg'
import lottieAnimation from '../../../assets/js/lottieAnimation.json'

function IntegrationMap() {
  const { t } = useTranslation()

  return (
    <div className="integration-map" id="integrations">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="integration-map__title text-center text-uppercase
            my-lg-5 pt-lg-5 pt-3 mt-3 pb-5"
            >
              {t('landing.customsIntegrations')}
            </h2>
            <div className="text-center my-5">
              <div className="position-relative">
                <img className="integration-map__img img-fluid" src={customsMapImg} alt={t('landing.customsMapAltText')} />
                <div className="lottie finland" id="finland">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.finland')}</span>
                </div>
                <div className="lottie sweden" id="sweden">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.sweden')}</span>
                </div>
                <div className="lottie estonia" id="estonia">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.estonia')}</span>
                </div>
                <div className="lottie latvia" id="latvia">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.latvia')}</span>
                </div>
                <div className="lottie lithuania" id="lithuania">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.lithuania')}</span>
                </div>
                <div className="lottie poland" id="poland">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.poland')}</span>
                </div>
                <div className="lottie romania" id="romania">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.romania')}</span>
                </div>
                <div className="lottie great-britain" id="great-britain">
                  <Lottie animationData={lottieAnimation} loop />
                  <span className="pb-1">{t('landing.greatBritain', { context: 'split' })}</span>
                </div>
                <div className="lottie turkey" id="turkey">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.turkey')}</span>
                </div>
                <div className="lottie bulgaria" id="bulgaria">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.bulgaria')}</span>
                </div>
                <div className="lottie hungary" id="hungary">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.hungary')}</span>
                </div>
                <div className="lottie netherlands" id="netherlands">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.netherlands')}</span>
                </div>
                <div className="lottie germany" id="germany">
                  <Lottie animationData={lottieAnimation} loop />
                  <span>{t('landing.germany', { context: 'split' })}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegrationMap
