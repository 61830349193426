import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FileRequest, FileResponse } from '../../../common/models'
import DeclarationApi from '../apiConfig'

const {
  baseUrl,
  queryKeys: { getFileByFolderAndUUID },
} = DeclarationApi.paths.file
const {
  queryKeys: { getDocumentByOperationIdAndType },
} = DeclarationApi.paths.document
export type FileFolder = 'TRANSIT-DOCUMENT' | 'DECLARATION-PDF' | 'EXPORT' | 'NOTE'
export interface SpecificFileRequest {
  folder: FileFolder
  fileUuid: string
}

function useFileApi() {
  const queryClient = useQueryClient()

  const postFile = useMutation({
    mutationFn: async (data: FileRequest) => {
      if (data.multipartFile === undefined || data.multipartFile === null) {
        return Promise.reject(Error('Unable to post undefined multipartFile'))
      }
      const formData = new FormData()
      formData.append('filename', data.filename)
      formData.append('mimeType', data.mimeType)
      formData.append('sortOrder', `${data.sortOrder}`)
      formData.append('documentId', `${data.documentId}`)
      formData.append('multipartFile', data.multipartFile, data.filename)

      return (await axios.post<FileResponse>(baseUrl, formData)).data
    },
    onSuccess: (response, request) => {
      if (response.uuid) {
        const folderAndUuid = response.uuid.split('/')
        if (folderAndUuid.length === 2) {
          queryClient.invalidateQueries({ queryKey: getFileByFolderAndUUID(folderAndUuid[0] as FileFolder, folderAndUuid[1]) })
          queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(request.documentId, 'NOTE') })
          queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(request.documentId, 'TRANSPORT') })
          queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(request.documentId, 'SUPPORTING') })
          queryClient.invalidateQueries({ queryKey: getDocumentByOperationIdAndType(request.documentId, 'PREVIOUS') })
        }
      }
      return Promise.resolve()
    },
  })

  const deleteFile = useMutation({
    mutationFn: async ({
      folder,
      fileUuid,
    }: SpecificFileRequest) => (await axios.delete<void>(`${baseUrl}/${folder}/${fileUuid}`)).data,
    onSuccess: (response, {
      folder,
      fileUuid,
    }) => queryClient.invalidateQueries({ queryKey: getFileByFolderAndUUID(folder, fileUuid) }),
  })

  // TODO convert to useQueries(files.map(…) after filesSchema creation if at all needed for form - see getConsignmentItems
  const getSignedUrlByFolderAndUUID = useMutation({
    mutationFn: async ({
      folder,
      fileUuid,
    }: SpecificFileRequest) => (await axios.get<string>(`${baseUrl}/${folder}/${fileUuid}`)).data,
  })

  return {
    postFile,
    deleteFile,
    getSignedUrlByFolderAndUUID,
  }
}

export default useFileApi
