import { createContext, Dispatch, SetStateAction } from 'react'
import { ISelectOption } from '../../types/IClassifier'
import { CustomsProfileType } from './components/NctsProfileModal'

export interface CustomerContextProps {
  guaranteeTypes: Array<ISelectOption>;
  isNctsProfileModalVisible: boolean;
  setNctsProfileModalVisibility: Dispatch<SetStateAction<boolean>>;
  nctsProfile: CustomsProfileType;
  setNctsProfile: Dispatch<SetStateAction<CustomsProfileType>>;
  customerId?: number;
  isSharing?: boolean;
}

export const CustomerContext = createContext<CustomerContextProps>({} as CustomerContextProps)
