import React from 'react'
import { useTranslation } from 'react-i18next'
import TransitSidebarSection from './TransitSidebarSection'
import TransitSidebarField from './TransitSidebarField'

interface TransitSidebarFeedbackSectionProps {
  feedback: string
}

function TransitSidebarFeedbackSection(props: Readonly<TransitSidebarFeedbackSectionProps>) {
  const { t } = useTranslation()
  const { feedback } = props

  return (
    <TransitSidebarSection
      columnId="feedback"
      headingLabel={t('declaration.navbar.additional')}
    >
      <div className="col-6">
        <TransitSidebarField
          label={t('feedback.info')}
          value={feedback}
        />
      </div>
    </TransitSidebarSection>
  )
}

export default TransitSidebarFeedbackSection
