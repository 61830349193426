/* eslint-disable max-len */

enum TransitField {
  HEADER_NCTS_ACCOMPANYING_DOCUMENT_LANGUAGE_CODE = 'header.nctsAccompanyingDocumentLanguageCode',
  HEADER_AGREED_LOCATION_OF_GOODS = 'header.agreedLocationOfGoods',
  HEADER_AGREED_LOCATION_OF_GOODS_CODE = 'header.agreedLocationOfGoodsCode',
  AUTHORISED_CONSIGNEE_TRADER_TIN = 'authorisedConsigneeTrader.tin',
  HEADER_AUTHORISED_LOCATION_OF_GOODS_CODE = 'header.authorisedLocationOfGoodsCode',
  HEADER_IDENTITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER = 'header.identityOfMeansOfTransportCrossingBorder',
  HEADER_NATIONALITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER = 'header.nationalityOfMeansOfTransportCrossingBorder',
  HEADER_TRANSPORT_MODE_AT_BORDER = 'header.transportModeAtBorder',
  CARRIER_TRADER_CITY = 'carrierTrader.city',
  CARRIER_TRADER_COUNTRY_CODE = 'carrierTrader.countryCode',
  CARRIER_TRADER_NAME = 'carrierTrader.name',
  CARRIER_TRADER_POSTAL_CODE = 'carrierTrader.postalCode',
  CARRIER_TRADER_STREET_AND_NUMBER = 'carrierTrader.streetAndNumber',
  CARRIER_TRADER_TIN = 'carrierTrader.tin',
  HEADER_COMMERCIAL_REFERENCE_NUMBER = 'header.commercialReferenceNumber',
  CONSIGNEE_TRADER_CITY = 'consigneeTrader.city',
  CONSIGNEE_TRADER_COUNTRY_CODE = 'consigneeTrader.countryCode',
  CONSIGNEE_TRADER_NAME = 'consigneeTrader.name',
  CONSIGNEE_TRADER_POSTAL_CODE = 'consigneeTrader.postalCode',
  CONSIGNEE_TRADER_STREET_AND_NUMBER = 'consigneeTrader.streetAndNumber',
  CONSIGNEE_TRADER_TIN = 'consigneeTrader.tin',
  CONSIGNEE_SECURITY_TRADER_CITY = 'consigneeSecurityTrader.city',
  CONSIGNEE_SECURITY_TRADER_COUNTRY_CODE = 'consigneeSecurityTrader.countryCode',
  CONSIGNEE_SECURITY_TRADER_NAME = 'consigneeSecurityTrader.name',
  CONSIGNEE_SECURITY_TRADER_POSTAL_CODE = 'consigneeSecurityTrader.postalCode',
  CONSIGNEE_SECURITY_TRADER_STREET_AND_NUMBER = 'consigneeSecurityTrader.streetAndNumber',
  CONSIGNEE_SECURITY_TRADER_TIN = 'consigneeSecurityTrader.tin',
  CONSIGNOR_TRADER_CITY = 'consignorTrader.city',
  CONSIGNOR_TRADER_COUNTRY_CODE = 'consignorTrader.countryCode',
  CONSIGNOR_TRADER_NAME = 'consignorTrader.name',
  CONSIGNOR_TRADER_POSTAL_CODE = 'consignorTrader.postalCode',
  CONSIGNOR_TRADER_STREET_AND_NUMBER = 'consignorTrader.streetAndNumber',
  CONSIGNOR_TRADER_TIN = 'consignorTrader.tin',
  CONSIGNOR_SECURITY_TRADER_CITY = 'consignorSecurityTrader.city',
  CONSIGNOR_SECURITY_TRADER_COUNTRY_CODE = 'consignorSecurityTrader.countryCode',
  CONSIGNOR_SECURITY_TRADER_NAME = 'consignorSecurityTrader.name',
  CONSIGNOR_SECURITY_TRADER_POSTAL_CODE = 'consignorSecurityTrader.postalCode',
  CONSIGNOR_SECURITY_TRADER_STREET_AND_NUMBER = 'consignorSecurityTrader.streetAndNumber',
  CONSIGNOR_SECURITY_TRADER_TIN = 'consignorSecurityTrader.tin',
  HEADER_CONTAINERISED_INDICATOR = 'header.containerisedIndicator',
  HEADER_CONVEYANCE_REFERENCE_NUMBER = 'header.conveyanceReferenceNumber',
  ITINERARY_COUNTRY_OF_ROUTING_CODE = 'itinerary.countryOfRoutingCode',
  HEADER_CUSTOMS_SUB_PLACE = 'header.customsSubPlace',
  HEADER_DECLARATION_DATE = 'header.declarationDate',
  HEADER_DECLARATION_PLACE = 'header.declarationPlace',
  CONTROL_RESULT_CONTROL_RESULT_CODE = 'controlResult.controlResultCode',
  CONTROL_RESULT_DATE_LIMIT = 'controlResult.dateLimit',
  DEPARTURE_CUSTOMS_OFFICE_REFERENCE_NUMBER = 'departureCustomsOffice.referenceNumber',
  HEADER_IDENTITY_OF_MEANS_OF_TRANSPORT_AT_DEPARTURE_EXP_TRANS = 'header.identityOfMeansOfTransportAtDepartureExpTrans',
  HEADER_NATIONALITY_OF_MEANS_OF_TRANSPORT_AT_DEPARTURE = 'header.nationalityOfMeansOfTransportAtDeparture',
  HEADER_COUNTRY_OF_DESTINATION_CODE = 'header.countryOfDestinationCode',
  DESTINATION_CUSTOMS_OFFICE_REFERENCE_NUMBER = 'destinationCustomsOffice.referenceNumber',
  HEADER_COUNTRY_OF_DISPATCH_EXPORT_CODE = 'header.countryOfDispatchExportCode',
  GOODS_ITEM_COMMODITY_CODE = 'goodsItem.commodityCode',
  GOODS_ITEM_COMMERCIAL_REFERENCE_NUMBER = 'goodsItem.commercialReferenceNumber',
  GOODS_ITEM_CONTAINERS_CONTAINER_NUMBER = 'goodsItem.containers.containerNumber',
  GOODS_ITEM_CONTAINERS = 'goodsItem.containers',
  GOODS_ITEM_COUNTRY_OF_DISPATCH_EXPORT_CODE = 'goodsItem.countryOfDispatchExportCode',
  GOODS_ITEM_COUNTRY_OF_DESTINATION_CODE = 'goodsItem.countryOfDestinationCode',
  GOODS_ITEM_GOODS_DESCRIPTION = 'goodsItem.goodsDescription',
  GOODS_ITEM_GROSS_MASS = 'goodsItem.grossMass',
  GOODS_ITEM_NET_MASS = 'goodsItem.netMass',
  GOODS_ITEM_ITEM_NUMBER = 'goodsItem.itemNumber',
  GOODS_ITEM_TRANSPORT_CHARGES_METHOD_OF_PAYMENT = 'goodsItem.transportChargesMethodOfPayment',
  GOODS_ITEM_PACKAGES_KIND_OF_PACKAGES = 'goodsItem.packages.kindOfPackages',
  GOODS_ITEM_PACKAGES_MARKS_NUMBERS_OF_PACKAGES = 'goodsItem.packages.marksNumbersOfPackages',
  GOODS_ITEM_PACKAGES_NUMBER_OF_PACKAGES = 'goodsItem.packages.numberOfPackages',
  GOODS_ITEM_PACKAGES_NUMBER_OF_PIECES = 'goodsItem.packages.numberOfPieces',
  GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES = 'goodsItem.previousAdministrativeReferences',
  GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_COMPLEMENT_OF_INFORMATION = 'goodsItem.previousAdministrativeReferences.complementOfInformation',
  GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_ITEM = 'goodsItem.previousAdministrativeReferences.item',
  GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_DOCUMENT_REFERENCE = 'goodsItem.previousAdministrativeReferences.previousDocumentReference',
  GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_DOCUMENT_TYPE = 'goodsItem.previousAdministrativeReferences.previousDocumentType',
  GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_PROCEDURE_TYPE = 'goodsItem.previousAdministrativeReferences.previousProcedureType',
  GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_COMPLEMENT_OF_INFORMATION = 'goodsItem.producedDocumentsCertificates.complementOfInformation',
  GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_DOCUMENT_REFERENCE = 'goodsItem.producedDocumentsCertificates.documentReference',
  GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_DOCUMENT_TYPE = 'goodsItem.producedDocumentsCertificates.documentType',
  GOODS_ITEM_SGI_CODES_SENSITIVE_GOODS_CODE = 'goodsItem.sgiCodes.sensitiveGoodsCode',
  GOODS_ITEM_SGI_CODES_SENSITIVE_QUANTITY = 'goodsItem.sgiCodes.sensitiveQuantity',
  GOODS_ITEM_SPECIAL_MENTIONS_ADDITIONAL_INFORMATION = 'goodsItem.specialMentions.additionalInformation',
  GOODS_ITEM_SPECIAL_MENTIONS_ADDITIONAL_INFORMATION_CODED = 'goodsItem.specialMentions.additionalInformationCoded',
  GOODS_ITEM_SPECIAL_MENTIONS_EXPORT_FROM_COUNTRY = 'goodsItem.specialMentions.exportFromCountry',
  GOODS_ITEM_SPECIAL_MENTIONS_EXPORT_FROM_EC = 'goodsItem.specialMentions.exportFromEc',
  GOODS_ITEM_TYPE_OF_DECLARATION = 'goodsItem.typeOfDeclaration',
  GOODS_ITEM_UN_DANGEROUS_GOODS_CODE = 'goodsItem.unDangerousGoodsCode',
  GUARANTEE_GUARANTEE_REFERENCE_ACCESS_CODE = 'guarantee.guaranteeReference.accessCode',
  GUARANTEE_GUARANTEE_REFERENCE_GUARANTEE_REFERENCE_NUMBER_GRN = 'guarantee.guaranteeReference.guaranteeReferenceNumberGrn',
  GUARANTEE_GUARANTEE_TYPE = 'guarantee.guaranteeType',
  GUARANTEE_GUARANTEE_REFERENCE_VALIDITY_LIMITATION_EC_NOT_VALID_FOR_EC = 'guarantee.guaranteeReference.validityLimitationEc.notValidForEc',
  GUARANTEE_GUARANTEE_REFERENCE_VALIDITY_LIMITATION_NON_EC_NOT_VALID_FOR_OTHER_CONTRACTING_PARTIES = 'guarantee.guaranteeReference.validityLimitationNonEc.notValidForOtherContractingParties',
  GUARANTEE_GUARANTEE_REFERENCE_OTHER_GUARANTEE_REFERENCE = 'guarantee.guaranteeReference.otherGuaranteeReference',
  GUARANTEE_GUARANTEE_REFERENCE_AMOUNT_CONCERNED = 'guarantee.guaranteeReference.amountConcerned',
  HEADER_INLAND_TRANSPORT_MODE = 'header.inlandTransportMode',
  HEADER_DIALOG_LANGUAGE_INDICATOR_AT_DEPARTURE = 'header.dialogLanguageIndicatorAtDeparture',
  HEADER_TRANSPORT_CHARGES_METHOD_OF_PAYMENT = 'header.transportChargesMethodOfPayment',
  HEADER_PLACE_OF_LOADING_CODE = 'header.placeOfLoadingCode',
  HEADER_PLACE_OF_UNLOADING_CODE = 'header.placeOfUnloadingCode',
  PRINCIPAL_TRADER_CITY = 'principalTrader.city',
  PRINCIPAL_TRADER_COUNTRY_CODE = 'principalTrader.countryCode',
  PRINCIPAL_TRADER_NAME = 'principalTrader.name',
  PRINCIPAL_TRADER_POSTAL_CODE = 'principalTrader.postalCode',
  PRINCIPAL_TRADER_STREET_AND_NUMBER = 'principalTrader.streetAndNumber',
  PRINCIPAL_TRADER_TIN = 'principalTrader.tin',
  PRINCIPAL_TRADER_HOLDER_ID_TIR = 'principalTrader.holderIdTir',
  HEADER_REFERENCE_NUMBER = 'header.referenceNumber',
  REPRESENTATIVE_REPRESENTATIVE_CAPACITY = 'representative.representativeCapacity',
  REPRESENTATIVE_NAME = 'representative.name',
  SEALS_INFO_SEALS_NUMBER = 'sealsInfo.sealsNumber',
  SEALS_INFO_SEALS_ID_SEALS_IDENTITY = 'sealsInfo.sealsId.sealsIdentity',
  HEADER_SECURITY = 'header.security',
  HEADER_SPECIFIC_CIRCUMSTANCE_INDICATOR = 'header.specificCircumstanceIndicator',
  HEADER_TOTAL_GROSS_MASS = 'header.totalGrossMass',
  HEADER_TOTAL_NUMBER_OF_ITEMS = 'header.totalNumberOfItems',
  HEADER_TOTAL_NUMBER_OF_PACKAGES = 'header.totalNumberOfPackages',
  TRANSIT_CUSTOMS_OFFICE_ARRIVAL_TIME = 'transitCustomsOffice.arrivalTime',
  TRANSIT_CUSTOMS_OFFICE_REFERENCE_NUMBER = 'transitCustomsOffice.referenceNumber',
  HEADER_TYPE_OF_DECLARATION = 'header.typeOfDeclaration',
  HEADER_TYPE_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER = 'header.typeOfMeansOfTransportCrossingBorder',
  GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_NAME = 'goodsItem.consignorSecurityTrader.name',
  GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_STREET_AND_NUMBER = 'goodsItem.consignorSecurityTrader.streetAndNumber',
  GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_POSTAL_CODE = 'goodsItem.consignorSecurityTrader.postalCode',
  GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_CITY = 'goodsItem.consignorSecurityTrader.city',
  GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_COUNTRY_CODE = 'goodsItem.consignorSecurityTrader.countryCode',
  GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_TIN = 'goodsItem.consignorSecurityTrader.tin',
  GOODS_ITEM_CONSIGNOR_TRADER_NAME = 'goodsItem.consignorTrader.name',
  GOODS_ITEM_CONSIGNOR_TRADER_STREET_AND_NUMBER = 'goodsItem.consignorTrader.streetAndNumber',
  GOODS_ITEM_CONSIGNOR_TRADER_POSTAL_CODE = 'goodsItem.consignorTrader.postalCode',
  GOODS_ITEM_CONSIGNOR_TRADER_CITY = 'goodsItem.consignorTrader.city',
  GOODS_ITEM_CONSIGNOR_TRADER_COUNTRY_CODE = 'goodsItem.consignorTrader.countryCode',
  GOODS_ITEM_CONSIGNOR_TRADER_TIN = 'goodsItem.consignorTrader.tin',
  GOODS_ITEM_CONSIGNEE_TRADER_NAME = 'goodsItem.consigneeTrader.name',
  GOODS_ITEM_CONSIGNEE_TRADER_STREET_AND_NUMBER = 'goodsItem.consigneeTrader.streetAndNumber',
  GOODS_ITEM_CONSIGNEE_TRADER_POSTAL_CODE = 'goodsItem.consigneeTrader.postalCode',
  GOODS_ITEM_CONSIGNEE_TRADER_CITY = 'goodsItem.consigneeTrader.city',
  GOODS_ITEM_CONSIGNEE_TRADER_COUNTRY_CODE = 'goodsItem.consigneeTrader.countryCode',
  GOODS_ITEM_CONSIGNEE_TRADER_TIN = 'goodsItem.consigneeTrader.tin',
  HEADER_LARGE_GOODS = 'header.largeGoods',
}

export default TransitField
