import React from 'react'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import { useTranslation } from 'react-i18next'
import CollapsibleColumn from '../../../components/CollapsibleColumn'
import FormFieldArray from '../../../components/FormFieldArray'
import FormField from '../../../components/FormField'
import Input from '../../../components/Input'
import useFieldArrayActionHelper from '../../../services/useFieldArrayActionHelper'
import { SealType } from '../../../form/schemas/transportEquipmentSchema'

import { blankSealType } from '../../../form'

const expectedScope = /transportEquipment\.\d+\.seals\.\d+/g
type SealItemScope = NestedFieldScope<`transportEquipment.${number}.seals.${number}`>
const isSealItemScope = (scope: ObjectScope):
  scope is `transportEquipment.${number}.seals.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function Seals({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()

  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<SealType>({
    blankItem: blankSealType,
    name: scope,
  })

  return (
    <>
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-transport-equipment-goods-references"
        headingLabel={t('declaration.p5.seals')}
      >
        {
          getFields().map((sealItem, index) => {
            const innerItemScope: ObjectScope = `${scope}.${index}` as const
            if (!isSealItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

            return (
              <FormFieldArray
                key={`sealItem_${sealItem.id}`}
                titleKey="declaration.p5.sequenceNumber"
                removeItem={() => removeAction(index, sealItem)}
                hidden={sealItem.deleted}
                sequenceNumber={sealItem.sequenceNumber + 1}
              >
                <FormField labelKey="declaration.p5.sealIdentifier">
                  <Input
                    <SealItemScope>
                    field={`${innerItemScope}.identifier`}
                    labelKey="declaration.p5.sealIdentifier"
                    type="text"
                    placeholder={t('common.fieldPlaceholder')}
                  />
                </FormField>
              </FormFieldArray>
            )
          })
        }
      </CollapsibleColumn>
      <div className="row">
        <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
          <button
            type="button"
            className="btn btn-sm btn-primary mt-2"
            onClick={() => addAction()}
            disabled={arrayRule === 'NOT_ALLOWED'}
          >
            <i className="fa fa-plus me-2" />
            {t('declaration.p5.addNewSeal')}
          </button>
        </div>
      </div>

    </>
  )
}

export default Seals
