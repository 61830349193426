import React from 'react'
import DirectoForm from './DirectoForm'

function DirectoEvent() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <section className="py-3">
            <DirectoForm />
          </section>
        </div>
      </div>
    </div>
  )
}

export default DirectoEvent
