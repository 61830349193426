import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CreateOrUpdateTransitOperationRequest, TransitOperationResponse } from '../../../common/models'
import { MutateRequest } from '../request-type'
import DeclarationApi from '../apiConfig'

const {
  baseUrl,
  queryKeys: {
    getTransitOperationById,
  },
} = DeclarationApi.paths.transitOperation

function useTransitOperationApi(transitOperationId: number | null, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const postTransitOperation = useMutation({
    mutationFn: async (data: CreateOrUpdateTransitOperationRequest) => (await axios
      .post<TransitOperationResponse>(baseUrl, data)).data,
  })

  const putTransitOperation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: MutateRequest<CreateOrUpdateTransitOperationRequest>) => (await axios
      .put<TransitOperationResponse>(`${baseUrl}/${id}`, data)).data,
    onSuccess: (response) => queryClient
      .invalidateQueries({ queryKey: getTransitOperationById(response.id!) }),
  })

  const deleteTransitOperation = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
    onSuccess: (_, variables) => queryClient
      .invalidateQueries({ queryKey: getTransitOperationById(variables) }),
  })

  const fetchTransitOperation = useQuery({
    enabled: !!transitOperationId && !postTransitOperation.isLoading && !putTransitOperation.isLoading && !isSubmitting,
    queryFn: async ({ signal }) => (await axios
      .get<TransitOperationResponse>(`${baseUrl}/${transitOperationId}`, { signal })).data,
    queryKey: getTransitOperationById(transitOperationId),
    staleTime: Infinity, // never stale
  })

  return {
    fetchTransitOperation,
    postTransitOperation,
    putTransitOperation,
    deleteTransitOperation,
  }
}

export default useTransitOperationApi
