import { useTranslation } from 'react-i18next'
import React from 'react'

function AboutUs() {
  const { t } = useTranslation()

  return (
    <div className="aboutUs pt-3 mt-3 pt-md-5 mt-md-5" id="aboutUs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="about-us__title text-center mt-5">{t('landing.aboutUs')}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 pb-4 mb-4">
            <p className="text-center">
              {t('landing.integratedModule')}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 pb-4 mb-4">
            <p className="text-center">
              {t('landing.support')}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="eas" src={`${process.env.PUBLIC_URL}/eas.png`} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
