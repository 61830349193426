import { RuleResult } from 'types/DeclarationP5'

const C0343Validator = {
  validateCountryOfDestinationRequirement: (countryOfDestination: string): RuleResult => {
    if (countryOfDestination.trim().length > 0) {
      return 'NOT_ALLOWED'
    }

    return 'REQUIRED'
  },
} as const

export default C0343Validator
