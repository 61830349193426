import { TransportEquipmentType } from '../../form/schemas/transportEquipmentSchema'
import useTransportEquipmentApi from './api'

function useTransportEquipment() {
  const { deleteTransportEquipment } = useTransportEquipmentApi()

  const deleteTransportEquipments = async (equipments: TransportEquipmentType[]) => {
    const promises = equipments
      .filter((item) => item.id !== null && !!item.deleted)
      .map((item) => deleteTransportEquipment.mutateAsync(item.id!))

    await Promise.all(promises)
  }

  return {
    deleteTransportEquipments,
  }
}

export default useTransportEquipment
