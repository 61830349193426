import { z } from 'zod'
import { AddressSchema, ContactPersonSchema } from './commonConsignmentSchemas'

const GNSSSchema = z.object({
  latitude: z.string(),
  longitude: z.string(),
})
const PostcodeAddress = z.object({
  houseNumber: z.string(),
  postcode: z.string(),
  country: z.string(),
})

const LocationOfGoodsSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  typeOfLocation: z.string(),
  qualifierOfIdentification: z.string(),
  authorisationNumber: z.string(),
  additionalIdentifier: z.string(),
  unLocode: z.string(),
  referenceNumber: z.string(), // location customs office reference
  gnss: GNSSSchema.nullable(),
  economicOperatorIdentificationNumber: z.string(),
  address: AddressSchema.nullable(),
  postcodeAddress: PostcodeAddress.nullable(),
  contactPerson: ContactPersonSchema.nullable(),
})
export type LocationOfGoodsType = z.infer<typeof LocationOfGoodsSchema>
export type PostcodeAddressType = z.infer<typeof PostcodeAddress>
export type GNSS = z.infer<typeof GNSSSchema>

export default LocationOfGoodsSchema
