import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConsignmentDetailRequest, ConsignmentDetailResponse } from '../../../common/models'
import TransitApiConfig from '../apiConfig'

const {
  baseUrl,
  queryKeys: { getConsignmentDetailByTransitOperationId },
} = TransitApiConfig.paths.consignmentDetail

function useConsignmentDetailApi(transitOperationId: number | null) {
  const queryClient = useQueryClient()

  const doPostConsignmentDetail = useMutation({
    mutationFn: async (data: ConsignmentDetailRequest) => (await axios.post<ConsignmentDetailResponse>(baseUrl, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getConsignmentDetailByTransitOperationId(transitOperationId) }),
  })

  const doPostConsignmentDetailDraft = useMutation({
    mutationFn: async (data: ConsignmentDetailRequest) => (await axios.post<ConsignmentDetailResponse>(`${baseUrl}/draft`, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getConsignmentDetailByTransitOperationId(transitOperationId) }),
  })

  const postConsignmentDetail = (isDraft: boolean) => {
    if (isDraft) {
      return doPostConsignmentDetailDraft
    }
    return doPostConsignmentDetail
  }

  // FIXME GET API AND SEARCHING QUERY
  // const getConsignmentDetail = useQuery({
  //   enabled: !!transitOperationId && !postConsignmentDetail.isLoading,
  //   queryFn: async ({ signal }) => (await axios
  //     .get<ConsignmentDetailResponse>(`${baseUrl}/${transitOperationId}`, { signal })).data,
  //   queryKey: getConsignmentDetailByTransitOperationId(transitOperationId),
  //   staleTime: Infinity, // never stale
  // })

  return {
    // getConsignmentDetail,
    postConsignmentDetail,
  }
}

export default useConsignmentDetailApi
