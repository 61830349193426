import axios, { AxiosRequestHeaders } from 'axios'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { Page, Pages } from 'routes/Pages'
import CookieConsent from 'react-cookie-consent'
import { getI18n, useTranslation } from 'react-i18next'

import ReactDOM from 'react-dom'
import {
  MutationCache, QueryCache, QueryClient, QueryClientProvider,
} from '@tanstack/react-query'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Header from './components/Header'
import LoadingBackdrop from './components/LoadingBackdrop'
import { UserContext } from './context/UserContext'
import { useAuth } from './hooks/useAuth'
import './i18n'
import store from './redux/store'
import { apiService, getAuthHeader, getBaseApiUrl } from './services/api.service'
import AuthenticationService from './services/auth.service'
import { NotificationContext } from './context/NotificationContext'
import NotificationService from './routes/Settings/service/notification.service'
import SeoService from './routes/Settings/service/seo.service'
import { SeoTag } from './routes/Settings/tabs/Seo/hooks/useSeoTags'
import RouteItemWrapper from './components/RouteItemWrapper'
import { minutesToMillis } from './helpers'
import { isAxiosError } from 'routes/phase5/common/utils/api-util'

const abortController = new AbortController()
const headRoot = document.head

function notifyServerError(traceId: string | null) {
  if (traceId !== null) {
    const content = getI18n().t('errors.serverError', { traceId })
    toast.error(content, {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
    })
  }
}

const queryClient = new QueryClient(
  {
    mutationCache: new MutationCache({
      onError: (error) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 500) {
            notifyServerError(error.response.headers['x-trace-id'] ?? null)
          }
        }
      },
    }),
    queryCache: new QueryCache({
      onError: (error) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 500) {
            notifyServerError(error.response.headers['x-trace-id'] ?? null)
          }
        }
      },
    }),
    defaultOptions: {
      queries: {
        staleTime: minutesToMillis(30),
        refetchOnWindowFocus: false,
      },
    },
  },
)

interface PortalProps {
  children: React.ReactNode[]
}

function HeadPortal(props: PortalProps) {
  return ReactDOM.createPortal(props.children, headRoot)
}
const logoutT1 = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('tardekSearch')
  localStorage.removeItem('search')
  store.dispatch({ type: 'RESET_DECLARATION' })
  window.location.replace('/login')
}
const appendToken = (parent: AxiosRequestHeaders | undefined, header: { Authorization: string; }) => ({
  ...parent,
  ...header,
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Status codes that falls outside the range of 2xx
    if (error && error.response && error.response.status === 401) {
      if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
        abortController.abort()
        AuthenticationService.logout()
        logoutT1()
      }
    }

    return Promise.reject(error)
  },
)

axios.interceptors.request.use((requestConfig) => {
  const accessToken = localStorage.getItem('accessToken')
  AuthenticationService.eagerlyLogoutWhenTokenExpireDateImpending()
  const baseUrl = process.env.REACT_APP_API_URL
  // eslint-disable-next-line no-param-reassign
  if (requestConfig.url?.includes(apiService.getApiUrl())
    || requestConfig.url?.includes(getBaseApiUrl())
    || requestConfig.url?.includes(`${baseUrl}/v2`)) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers = appendToken(requestConfig.headers, getAuthHeader(accessToken || ''))
  }

  if ((requestConfig.url?.startsWith(apiService.getApiUrl())
      || requestConfig.url?.startsWith(getBaseApiUrl()))
    && !requestConfig.url?.endsWith('/auth/logout')) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.signal = abortController.signal
  }

  return requestConfig
}, (error) => {
  // Status codes that falls outside the range of 2xx
  if (error && error.response && error.response.status === 401) {
    logoutT1()
  }
  return Promise.reject(error)
})

function App() {
  const [isHeaderHidden, toggleHeader] = useState<boolean | undefined>(false)
  const [isAsideOpen, toggleAside] = useState<boolean>(false)
  const { t } = useTranslation()

  function setHeader(input: boolean | undefined) {
    toggleHeader(input)
  }
  const setAside = (input: boolean) => toggleAside(input)

  const { user, customer, isLoading } = useAuth()

  const [seoTags, setSeoTags] = useState<Array<SeoTag>>([])
  const [maintenanceMessage, setMaintenanceMessage] = useState<string>('')

  useEffect(() => {
    NotificationService.getHeaderNotificationMessage().then((response) => {
      setMaintenanceMessage(response.message)
    })
    SeoService.getSeoTags(abortController)
      .then((tags) => {
        setSeoTags(tags)
      })
  }, [])

  if (isLoading) {
    return <table><LoadingBackdrop loading={isLoading} /></table>
  }

  const populateMain = Pages.map((item: Page) => (
    <Route
      key={item.url}
      path={`/${item.url}`}
      element={(
        // @ts-ignore
        <RouteItemWrapper togglePageHeader={() => setHeader(item.isHeaderHidden)}>
          <item.component checkAside={setAside} />
        </RouteItemWrapper>
      )}
    />
  ))

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <HeadPortal>
        {
          seoTags.map(
            (tag) => {
              if (tag.key === 'SEO_TITLE') {
                return (
                  <React.Fragment key="SEO_TITLE">
                    <meta key="title" property="title" content={`${tag.value}`} />
                    <meta key="og:title" property="og:title" content={`${tag.value}`} />
                  </React.Fragment>
                )
              }
              if (tag.key === 'SEO_DESCRIPTION') {
                return (
                  <React.Fragment key="SEO_DESCRIPTION">
                    <meta key="description" property="description" content={`${tag.value}`} />
                    <meta key="og:description" property="og:description" content={`${tag.value}`} />
                  </React.Fragment>
                )
              }
              if (tag.key === 'SEO_KEYWORDS') {
                return (
                  <React.Fragment key="SEO_KEYWORDS">
                    <meta key="keywords" property="keywords" content={`${tag.value}`} />
                    <meta key="og:keywords" property="og:keywords" content={`${tag.value}`} />
                  </React.Fragment>
                )
              }
              // eslint-disable-next-line react/jsx-no-useless-fragment
              return <></>
            },
          )
        }
      </HeadPortal>

      <Provider store={store}>
        <BrowserRouter>
          {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
          <NotificationContext.Provider value={{
            contextHeaderNotificationMessage: maintenanceMessage,
            setContextHeaderNotificationMessage: setMaintenanceMessage,
          }}
          >
            <CookieConsent
              location="bottom"
              cookieName="tardek-cookie"
              buttonText={t('cookie.agree')}
              expires={720}
              style={{
                background: 'rgb(248, 248, 248)',
                color: 'rgb(35, 27, 51)',
                right: '0',
                width: 'auto',
                margin: '32px',
                boxShadow: '0px 8px 16px -4px rgba(35, 27, 51, 0.24)',
                borderRadius: '4px',
                alignItems: 'center',
              }}
              buttonStyle={{
                fontSize: '14px',
                padding: '8px 17.5px',
              }}
            >
              {t('cookie.usage')}
            </CookieConsent>
            {user && customer && !isLoading && (
              // eslint-disable-next-line react/jsx-no-constructed-context-values
              <UserContext.Provider value={{ user, customer }}>
                <Header isHidden={isHeaderHidden} notification={maintenanceMessage} />
                <main
                  className={(isHeaderHidden ? 'no-header ' : '') + (isAsideOpen ? 'aside-open' : '')}
                  style={{
                    top: `${maintenanceMessage !== '' && maintenanceMessage !== null ? '120px' : '60px'}`,
                    minHeight: `${maintenanceMessage !== '' && maintenanceMessage !== null ? 'calc(100vh - 120px)' : 'calc(100vh - 60px)'}`,
                  }}
                >
                  <Routes>
                    <Route
                      path="/login"
                      element={user && customer && <Navigate to="/transits" />}
                    />
                    {/* <Navigate to="/transits" /> */}
                    <Route
                      path="/"
                      element={user && customer && <Navigate to="/transits" />}
                    />
                    {populateMain}
                  </Routes>
                </main>
              </UserContext.Provider>
            )}
            {(user === null && customer === null && !isLoading) && (
              <>
                <Header isHidden={isHeaderHidden} notification={maintenanceMessage} />
                <main className={(isHeaderHidden ? 'no-header ' : '') + (isAsideOpen ? 'aside-open' : '')}>
                  <Routes>
                    {populateMain}
                  </Routes>
                </main>
              </>
            )}
          </NotificationContext.Provider>
        </BrowserRouter>
        <ToastContainer
          autoClose={2500}
        />
      </Provider>
    </QueryClientProvider>
  )
}

export default App
