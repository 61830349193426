import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { CreateHouseConsignmentRequest, HouseConsignmentResponse, UpdateHouseConsignmentRequest } from '../../../common/models'
import {
  parseAdditionalSupplyChainActorResponse,
  toCreateOrUpdateAdditionalSupplyChainActorRequest,
} from '../useConsignment/mapper'
import { excludeDeleted } from '../../../common/utils/common-util'

export function toCreateHouseConsignmentRequest(
  houseConsignment: HouseConsignmentType,
  consignmentId: number | null,
  grossMass: number,
  index: number,
): CreateHouseConsignmentRequest {
  if (consignmentId === null) throw Error('Missing consignmentId')

  return {
    consignmentId,
    additionalSupplyChainActors: houseConsignment.additionalSupplyChainActor
      .filter(excludeDeleted)
      .map(toCreateOrUpdateAdditionalSupplyChainActorRequest),
    consigneeId: null,
    consignorId: null,
    departureTransportMeans: [],
    // ToDo: Reverse this after Transition period
    grossMass,
    referenceNumberUcr: houseConsignment.referenceNumberUCR,
    sequenceNumber: index,
  }
}
export function toUpdateHouseConsignmentRequest(
  houseConsignment: HouseConsignmentType,
  grossMass: number,
  index: number,
): UpdateHouseConsignmentRequest {
  if (houseConsignment.id === null) throw Error('Missing house consignment id')

  return {
    additionalSupplyChainActors: houseConsignment.additionalSupplyChainActor
      .filter(excludeDeleted)
      .map(toCreateOrUpdateAdditionalSupplyChainActorRequest),
    consigneeId: null,
    consignorId: null,
    departureTransportMeans: [],
    // ToDo: Reverse this after Transition period
    grossMass,
    id: houseConsignment.id,
    referenceNumberUcr: houseConsignment.referenceNumberUCR,
    sequenceNumber: index,
  }
}
export type TransitionalHouseConsignment = 'id' | 'additionalSupplyChainActor' | 'grossMass' | 'referenceNumberUCR' | 'sequenceNumber'
export function parseHouseConsignmentResponse(
  response: HouseConsignmentResponse,
  index: number,
): Pick<HouseConsignmentType, TransitionalHouseConsignment> {
  return {
    id: response.id,
    grossMass: response.grossMass ?? 0,
    sequenceNumber: response.sequenceNumber ?? index,
    referenceNumberUCR: response.referenceNumberUcr ?? '',
    additionalSupplyChainActor: response?.additionalSupplyChainActors.map(parseAdditionalSupplyChainActorResponse) ?? [],
  }
}
