import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import { addDeclaration } from '../../../redux/actions'
import { IStoreState } from '../../../redux/store'
import { IClassifier, ISelectOption } from '../../../types/IClassifier'
import { InvalidField } from '../../Transits/TransitsAside/detailView'
import ConsignmentDetailColumn from './ConsignmentDetail'
import DeclarationInfoColumn from './DeclarationInfoColumn'
import TransportColumn from './TransportColumn'
import { NctsProfileNameOption } from '../../../types/NctsProfileNameResponse'
import { IGoodsItem } from '../../../types/IGoodsItem'

interface IDeclarationInfoProps {
  countriesOptions: ISelectOption[]
  countryClassifiers: Array<IClassifier>
  navigate: Dispatch<SetStateAction<string>>
  invalidFields: Array<InvalidField>
  setInvalidFields: Dispatch<SetStateAction<Array<InvalidField>>>
  traderRestrictions: Array<string>
  truckRestrictions: Array<string>
  nctsProfileNames: Array<NctsProfileNameOption>
  openCalculation: () => void
  goodsItems: Array<IGoodsItem>
}
function DeclarationInfo({
  countriesOptions,
  countryClassifiers,
  nctsProfileNames,
  invalidFields,
  navigate,
  traderRestrictions,
  truckRestrictions,
  setInvalidFields,
  openCalculation,
  goodsItems,
}: IDeclarationInfoProps) {
  const store = useStore()
  const { t } = useTranslation()
  const getDeclaration = () => (store.getState() as IStoreState).declarationReducer.declaration
  const [declaration, setDeclaration] = useState(getDeclaration())

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setDeclaration(getDeclaration())
    })

    return () => unsubscribe()
  }, [])

  const toggleSecurity = () => {
    if (declaration.mrn && declaration.mrn.length > 0) {
      return
    }
    store.dispatch(addDeclaration({
      ...declaration,
      isSecurityEnabled: !declaration.isSecurityEnabled,
    }))
  }

  return (
    <>
      <div className="d-flex flex-wrap mb-3 w-100">
        <DeclarationInfoColumn
          countryClassifiers={countryClassifiers}
          toggleSecurity={toggleSecurity}
          countriesOptions={countriesOptions}
          invalidFields={invalidFields}
          nctsProfileNames={nctsProfileNames}
          openCalculation={openCalculation}
        />

        <ConsignmentDetailColumn
          countriesOptions={countriesOptions}
          invalidFields={invalidFields}
          traderRestrictions={traderRestrictions}
          setInvalidFields={setInvalidFields}
        />
        <TransportColumn
          countryClassifiers={countryClassifiers}
          countriesOptions={countriesOptions}
          invalidFields={invalidFields}
          truckRestrictions={truckRestrictions}
          goodsItems={goodsItems}
        />
      </div>

      <div className="d-flex justify-content-center align-items-start col-12 mb-3">
        <button
          type="button"
          className="btn btn-outline-dark btn-lg d-flex align-items-center mx-2"
          disabled={(store.getState() as IStoreState).declarationReducer.declaration.profile === null
              || (store.getState() as IStoreState).declarationReducer.declaration.profile === ''}
          onClick={() => navigate('goods-items')}
        >
          <i className="far fa-plus me-1" />
          <span className="text-uppercase">
            {t('buttons.addGoods')}
          </span>
        </button>
      </div>
    </>
  )
}

export default DeclarationInfo
