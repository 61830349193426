import React from 'react'
import { TitleBar } from 'components/TitleBar'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ROUTES from 'config/routes'

function CustomerTitleBar() {
  const { t } = useTranslation()
  const newCustomerLink = ROUTES.newCustomer ?? '/404'
  const newExternalCustomerLink = ROUTES.newExternalCustomer ?? '/404'

  return (
    <TitleBar title={t('pages.page', { context: 'customers' })} isMobileThin>
      <div className="d-flex justify-content-end align-items-center ps-3 w-100">
        <Link
          type="button"
          className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm me-3"
          to={newExternalCustomerLink}
        >
          <i className="far fa-plus me-2" />
          <span>
            {t('buttons.addNewExternal')}
          </span>
        </Link>
        <Link
          type="button"
          className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
          to={newCustomerLink}
        >
          <i className="far fa-plus me-2" />
          <span>
            {t('buttons.addNew')}
          </span>
        </Link>
      </div>
    </TitleBar>
  )
}

export default CustomerTitleBar
