import { z } from 'zod'

const GuaranteeSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    amount: z.number().nonnegative(),
    profileName: z.string().nullable(),
    deleted: z.boolean().optional(),
  },
)
export type Guarantee = z.infer<typeof GuaranteeSchema>

export default GuaranteeSchema
