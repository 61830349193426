import { IGuarantee } from '../../types/IDeclaration'
import { IAction } from '../actionTypes'

interface IGuaranteeReducerState {
  guarantee: IGuarantee | null
}

const initialState: IGuaranteeReducerState = {
  guarantee: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function guaranteeReducer(state = initialState, action: IAction<IGuarantee>) {
  switch (action.type) {
    case 'ADD_GUARANTEE':
      return {
        ...state,
        guarantee: action.payload,
      }
    default:
      return state
  }
}
