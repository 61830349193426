import React from 'react'
import { useTranslation } from 'react-i18next'
import { TitleBar } from '../../../../components/TitleBar'
import { Restriction } from '../../../../types/restriction'

interface TitleBarProps {
  restriction: Restriction;
  save: () => void;
  loading: boolean
}

function RestrictionsTitleBar(props: TitleBarProps) {
  const { t } = useTranslation()
  const { restriction, save, loading } = props

  const saveButton = () => (
    <button
      type="button"
      disabled={loading}
      className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
      onClick={save}
    >
      {
        !loading
          && <span className="text-uppercase">{t('buttons.save')}</span>
      }
      {
        loading
          && (
            <>
              <span className="text-uppercase">{t('common.saving')}</span>
              <i className="fa fa-spinner fa-spin ms-2" />
            </>
          )
      }
    </button>
  )

  return (
    <TitleBar title={t('pages.page', { context: 'restrictions' })}>
      <div className="d-flex justify-content-between align-items-center ps-3 w-100">
        <div className="pt-2 text-uppercase text-muted border-end pe-3">
          {
            restriction.id && (
              <div className="d-flex">{`${t('restriction.id')}: ${restriction.id ?? ''}`}</div>
            )
          }
        </div>
        <div className="d-flex">
          {saveButton()}
        </div>
      </div>
    </TitleBar>
  )
}

export default RestrictionsTitleBar
