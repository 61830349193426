import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { apiService } from './api.service'
import { Jwt } from '../hooks/useAuth'

function loginUser(loginForm: { username: string, password: string }) {
  return axios
    .post(
      apiService.getFullApiUrl('/auth/login'),
      {
        ...loginForm,
      },
    )
    .then((response) => response.data.data)
    // eslint-disable-next-line consistent-return
    .catch((reason) => {
      if (reason.response.status === 302) {
        return reason.response.status
      }
      toast.error('Login failed')
    })
}
function logout() {
  return axios
    .delete(apiService.getFullApiUrl('/auth/logout'))
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      window.location.replace('/login')
      localStorage.removeItem('accessToken')
    })
}

function eagerlyLogoutWhenTokenExpireDateImpending() {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const decodedToken = jwtDecode<Jwt>(accessToken)
    const dateTokenWillExpire = new Date(decodedToken.exp * 1000)
    // @ts-ignore
    const diff = dateTokenWillExpire - (new Date())
    if (diff < 0) {
      logout()
      localStorage.removeItem('accessToken')
    }
    const hoursUntilTokenExpired = parseInt(`${diff / (60 * 60 * 1000)}`, 10)
    if (hoursUntilTokenExpired < 2) {
      setTimeout(() => {
        logout()
        localStorage.removeItem('accessToken')
      }, 2000)
    }
  }
}

function submitOtpRequest(username: string, otp: string) {
  return axios
    .post(
      apiService.getFullApiUrl('/auth/otp'),
      {
        username, otp,
      },
    )
    .then((response) => response.data.data)
    .catch((reason) => {
      if (reason.response.status === 302) {
        return reason.response.status
      }
      toast.error('Login failed')
      return null
    })
}

const AuthenticationService = {
  loginUser,
  logout,
  eagerlyLogoutWhenTokenExpireDateImpending,
  submitOtpRequest,
}

export default AuthenticationService
