/* tslint:disable */
/* eslint-disable */
/**
 * Tardek API Endpoints
 * This Is a documentation for T1 Tardek API Endpoints. <br />
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@bitweb.ee
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface DirectoEventResponse
 */
export interface DirectoEventResponse {
    /**
     *
     * @type {number}
     * @memberof DirectoEventResponse
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof DirectoEventResponse
     */
    descriptionAddendum?: string;
    /**
     *
     * @type {string}
     * @memberof DirectoEventResponse
     */
    remoteStaffCode?: string;
    /**
     *
     * @type {number}
     * @memberof DirectoEventResponse
     */
    customerId?: number;
    /**
     *
     * @type {string}
     * @memberof DirectoEventResponse
     */
    status?: DirectoEventResponseStatusEnum;
}
/**
 * @export
 * @enum {string}
 */
export enum DirectoEventResponseStatusEnum {
  IDLE = 'IDLE',
  QUEUED = 'QUEUED',
  SUBMITTED = 'SUBMITTED',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
}
