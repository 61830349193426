import React, { Dispatch, SetStateAction } from 'react'
import { HeaderGroup } from 'react-table'
import { isMobile } from 'react-device-detect'
import Select from 'react-select'
import { DebounceInput } from 'react-debounce-input'
import { TableFilter } from 'types/PageResponse'
import { ISelectOption } from 'types/IClassifier'
import { getSelectValue } from 'helpers'
import { getDirectoStatusOptions, getPublicOptions } from '../../../Transits/Table/config'
import CustomerSearchHeader from '../../../../components/Table/CustomerSearchHeader'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface TableHeaderProps {
  headerGroups: Array<HeaderGroup>
  filterState: {
    filters: Array<TableFilter>
    setFilters: Dispatch<SetStateAction<Array<TableFilter>>>
  }
  // eslint-disable-next-line react/require-default-props
  selectFilterOptions?: ISelectOption[]
  // eslint-disable-next-line react/require-default-props
  selectFilterState?: {
    selectFilter: string
    setSelectFilter: Dispatch<SetStateAction<string>>
  }
  // eslint-disable-next-line react/require-default-props
  sortState?: {
    sortBy: string
    setSortBy: Dispatch<SetStateAction<string>>
  }
  // eslint-disable-next-line react/require-default-props
  orderState?: {
    orderBy: string
    setOrderBy: Dispatch<SetStateAction<string>>
  }
}

const directoStatusOptions: ISelectOption[] = getDirectoStatusOptions()
const publicOptions: ISelectOption[] = getPublicOptions()

function TableHeader(props: Readonly<TableHeaderProps>) {
  const {
    headerGroups,
    sortState,
    orderState,
    selectFilterOptions,
    selectFilterState,
    filterState,
  } = props

  const {
    filters,
    setFilters,
  } = filterState

  const getFilter = (header: string) => {
    const match: TableFilter | undefined = filters.find((filter) => filter.key === header)
    return match ?? null
  }

  const getFilterValue = (header: string) => {
    const match: TableFilter | null = getFilter(header)
    return match ? match.value : ''
  }

  function isDateColumn(column: HeaderGroup<{}>) {
    return column.id === 'dateModified' || column.id === 'dateCreated' || column.id === 'dateReviewed' || column.id === 'transitDateLimit'
  }

  function getSimpleColumnInputHeader(column: HeaderGroup<{}>) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {
          (column.id !== 'status' && column.id !== 'type' && column.id !== 'isReviewed' && column.id !== 'directoStatus'
            && column.id !== 'customerName' && column.id !== 'external' && column.id !== 'usesT1Grn') && (
            <DebounceInput
              disabled={
                column.disableGlobalFilter
              }
              className="form-control"
              type="search"
              name={`${column.id}Search`}
              id={`${column.id}Search`}
              value={getFilterValue(column.id)}
              debounceTimeout={300}
              placeholder={isDateColumn(column) ? 'dd.mm.yyyy' : undefined}
              pattern={isDateColumn(column) ? '\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d' : undefined}
              onChange={(event) => {
                if (isDateColumn(column) && event.target.value !== '') {
                  const isValid = event.target.checkValidity()
                  if (!isValid) {
                    event.target.classList.add('invalid')
                  } else {
                    event.target.classList.remove('invalid')
                  }
                } else if (column.id === 'notes' || column.id === 'companyNotes') {
                  if (event.target.value.length < 2) {
                    event.target.classList.add('invalid')
                  } else {
                    event.target.classList.remove('invalid')
                  }
                }
                if (column.id === 'id' && /^\d*$/.exec(event.target.value) == null) {
                  return
                }
                if (getFilter(column.id) === null) {
                  setFilters([...filters, {
                    key: column.id,
                    value: event.target.value,
                  }])
                } else {
                  setFilters(filters.map((filter) => {
                    if (filter.key === column.id) {
                      // eslint-disable-next-line no-param-reassign
                      filter.value = event.target.value
                      return filter
                    }
                    return filter
                  }))
                }
              }}
            />
          )
        }
      </>
    )
  }

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {isMobile ? <th className="w-1" aria-label="empty" /> : null}
          {headerGroup.headers.map((column) => (
            <th
              key={column.id}
              role={column.getHeaderProps().role}
              style={{
                ...column.getHeaderProps().style,
                cursor: `${!column.disableSortBy
              && sortState ? 'pointer'
                  : 'auto'}`,
              }}
              className={(column as any).className ? ((column as any).className) : ''}
            >
              <span
                role="presentation"
                onClick={() => {
                  if (sortState) {
                    if (column.disableSortBy) {
                      return
                    }
                    sortState.setSortBy(column.id)
                  }
                }}
              >
                {column.render('title')}
              </span>
              {sortState && (!column.disableSortBy) && (column.id !== sortState.sortBy)
              && <span className="fas fa-arrows-alt-v ms-2" />}
              {sortState && orderState
              && (column.id === sortState.sortBy) && (
                <i
                  className={`${orderState.orderBy === 'DESC' ? 'fas fa-sort-up ms-2 mt-2' : 'fas fa-sort-down ms-2 mt-2'}`}
                  role="presentation"
                  onClick={() => {
                    if (orderState) {
                      orderState.setOrderBy(orderState.orderBy === 'DESC' ? 'ASC' : 'DESC')
                    }
                  }}
                />
              )}
            </th>
          ))}
        </tr>
      ))}
      {headerGroups.map((headerGroup) => (
        <tr className="table-search" {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <td
              className={
                `${column.id === 'id' ? 'cell-id' : ''
                }${isMobile && column.id === 'mrn' ? 'd-none d-sm-table-cell' : ''
                }${column.id === 'status' ? 'filter-select' : ''
                }${column.id === 'modified' || column.id === 'created' || column.id === 'dateReviewed' ? 'cell-modified' : ''} hover`
              }
              {...column.getHeaderProps()}
            >
              {
                (column.id === 'status' || column.id === 'type') && (
                  <Select
                    key={selectFilterState?.selectFilter}
                    options={selectFilterOptions}
                    className="select border-0 w-100"
                    classNamePrefix="select"
                    menuPlacement="auto"
                    value={selectFilterOptions ? getSelectValue(selectFilterState?.selectFilter!, selectFilterOptions) : null}
                    onChange={(option: ISelectOption | null) => selectFilterState?.setSelectFilter(option ? option.value : null)}
                    isClearable
                    isSearchable={false}
                  />
                )
              }
              {
                (column.id === 'directoStatus') && (
                  <Select
                    options={directoStatusOptions}
                    className="select border-0 w-100"
                    classNamePrefix="select"
                    menuPlacement="auto"
                    value={
                      filterState?.filters.find((filter) => filter.key === 'directoStatus')?.value
                      // eslint-disable-next-line max-len
                        ? getSelectValue(filterState?.filters.find((filter) => filter.key === 'directoStatus')?.value ?? null, directoStatusOptions)
                        : null
                    }
                    onChange={(option: ISelectOption | null) => {
                      if (getFilter(column.id) === null) {
                        setFilters([...filters, {
                          key: column.id,
                          value: option ? option.value : null,
                        }])
                      } else {
                        setFilters(filters.map((filter) => {
                          if (filter.key === column.id) {
                          // eslint-disable-next-line no-param-reassign
                            filter.value = option ? option.value : null
                            return filter
                          }
                          return filter
                        }))
                      }
                    }}
                    isClearable
                    isSearchable={false}
                  />
                )
              }
              {(column.id === 'usesT1Grn') && (
                <Select
                  options={publicOptions}
                  className="select border-0 w-100"
                  classNamePrefix="select"
                  menuPlacement="auto"
                  value={
                    filterState?.filters.find((filter) => filter.key === 'usesT1Grn')?.value
                      ? getSelectValue(filterState?.filters.find((filter) => filter.key === 'usesT1Grn')?.value!, publicOptions)
                      : null
                  }
                  onChange={(option: ISelectOption | null) => {
                    if (getFilter(column.id) === null) {
                      setFilters([...filters, {
                        key: column.id,
                        value: option ? option.value : null,
                      }])
                    } else {
                      setFilters(filters.map((filter) => {
                        if (filter.key === column.id) {
                        // eslint-disable-next-line no-param-reassign
                          filter.value = option ? option.value : null
                          return filter
                        }
                        return filter
                      }))
                    }
                  }}
                  isClearable
                  isSearchable={false}
                />
              )}
              {
                column.id === 'customerName' && (
                  <CustomerSearchHeader filterState={filterState} />
                )
              }
              {getSimpleColumnInputHeader(column)}
            </td>
          ))}
          <td className="d-none d-sm-table-cell" />
        </tr>
      ))}
    </thead>
  )
}

export default TableHeader
