import { TFunction } from 'i18next'
import { Header } from '../../Transits/Table/config'
import { ISelectOption } from '../../../types/IClassifier'

export function getTableHeaders(t: TFunction): Header[] {
  return [
    {
      title: t('restriction.id'),
      accessor: 'id',
    },
    {
      title: t('restriction.key'),
      accessor: 'key',
    },
    {
      title: t('restriction.description'),
      accessor: 'description',
    },
    {
      title: t('restriction.type'),
      accessor: 'type',
    },
    {
      title: t('restriction.modified'),
      accessor: 'modified',
    },
    {
      title: t('restriction.created'),
      accessor: 'created',
    },
  ]
}

export function getRestrictionTypeOptions(t: TFunction) {
  return [
    { value: 'COMMODITY_CODE', label: t('restriction.commodityCodeType') },
    { value: 'COMMODITY_DESCRIPTION', label: t('restriction.commodityDescriptionType') },
    { value: 'COMMODITY_SANCTION', label: t('restriction.commoditySanctionType') },
    { value: 'TRADER', label: t('restriction.commodityTraderType') },
    { value: 'TRUCK', label: t('restriction.commodityTruckType') },
  ] as ISelectOption[]
}
