import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Restriction, RestrictionType } from '../../../../types/restriction'
import RestrictionService from '../../services/restriction.service'
import { isNullOrBlank } from '../../../../helpers'
import ROUTES from '../../../../config/routes'

// eslint-disable-next-line import/prefer-default-export
export function useRestriction() {
  const { t } = useTranslation()
  const history = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [restriction, setRestriction] = useState<Restriction>({
    id: null,
    key: '',
    description: '',
    type: RestrictionType.COMMODITY_CODE,
    modified: null,
    requiresAudit: false,
    created: '',
  })

  const save = () => {
    if (loading || !restriction) {
      return
    }

    if (isNullOrBlank(restriction.key)) {
      toast.error(t('messages.missingRequiredFields'))
      return
    }

    setLoading(true)
    let apiPromise: Promise<Restriction>
    if (restriction.id) {
      apiPromise = RestrictionService.putRestriction(restriction)
    } else {
      apiPromise = RestrictionService.postRestriction(restriction)
    }
    apiPromise
      .then(() => {
        toast.success(t('messages.savingSuccess'))
        history(ROUTES.restrictions)
      })
      .catch((error) => {
        if (error.message === 'Integrity violation') {
          toast.error(t('restriction.alreadyExists'))
        } else {
          toast.error(t('messages.savingFailed'))
        }
      })
      .finally(() => setLoading(false))
  }

  const load = (restrictionId: number) => {
    if (!restrictionId) {
      return
    }

    RestrictionService.getRestriction(restrictionId)
      .then((response) => {
        setRestriction(response)
      })
  }

  return {
    loading,
    restriction,
    setRestriction,
    save,
    load,
  }
}
