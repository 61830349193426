import React from 'react'

interface DeclarationTabLayoutProps {
  start: React.ReactNode
  centerLeft: React.ReactNode
  centerRight: React.ReactNode
  end: React.ReactNode
}

function DeclarationTabLayout({
  centerLeft,
  centerRight,
  end,
  start,
}: DeclarationTabLayoutProps) {
  return (
    <React.Fragment key="layout">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            {start}
          </div>
          <div className="col-lg-3 col-md-6">
            {centerLeft}
          </div>
          <div className="col-lg-3 col-md-6">
            {centerRight}
          </div>
          <div className="col-lg-3 col-md-6">
            {end}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DeclarationTabLayout
