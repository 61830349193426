import React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'components/LoadingSpinner'
import { DocumentRequestTypeEnum, DocumentResponse } from '../../../models'
import TransitSidebarField from '../TransitSidebarField'
import { PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX, removePrefix } from '../../../utils/classifier-util'
import { hasText } from '../../../utils/common-util'
import TransitSidebarConsignmentItemDocumentSection from './TransitSidebarConsignmentItemDocumentSection'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import useDocumentApi from '../../../../TransitOperationEditor/hooks/useDocument/api'

export interface TransitSidebarConsignmentItemPreviousDocumentsProps {
  transitOperationId: number
  consignmentItemId: number | null
  isItemOpen: boolean
}

function TransitSidebarConsignmentItemPreviousDocuments(props: TransitSidebarConsignmentItemPreviousDocumentsProps) {
  const {
    transitOperationId,
    isItemOpen,
    consignmentItemId,
  } = props
  const { t } = useTranslation()

  const {
    getDocuments: getPreviousDocuments,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.PREVIOUS, !isItemOpen)

  const previousDocuments = ((getPreviousDocuments.data ?? []) as DocumentResponse[])
    .filter((transportDocument) => transportDocument.consignmentItemId === consignmentItemId)
    .sort(sortBySequenceNumber)

  if (previousDocuments.length === 0 && !getPreviousDocuments.isLoading) {
    return null
  }

  return (
    <div className="col-6 p-2">
      <div className="card p-2">
        <h5 className="text-secondary mb-2">
          {
            t('declaration.p5.previousDocuments')
          }
        </h5>
        {
          getPreviousDocuments.isLoading && (
            <div className="mx-auto mb-2">
              <LoadingSpinner />
            </div>
          )
        }
        {
          previousDocuments.map((document) => (
            <TransitSidebarConsignmentItemDocumentSection
              key={document.id}
              document={document}
              documentTypePrefix={PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX}
            >
              <div className="col-12">
                <TransitSidebarField
                  label={t('declaration.p5.supportingDocumentType')}
                  value={removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) ?? ''}
                />
                <TransitSidebarField
                  label={t('declaration.p5.previousDocumentReferenceNumber')}
                  value={document.referenceNumber ?? ''}
                />
                <TransitSidebarField
                  label={t('declaration.p5.complementOfInformation')}
                  value={document.complementOfInformation ?? ''}
                />
                <TransitSidebarField
                  label={t('declaration.p5.itemNumber')}
                  value={document.documentLineItemNumber ?? ''}
                />
                {
                  (hasText(document.transportDocumentReferenceNumber)
                    && (removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N355'
                      || removePrefix(document.documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N337')
                  ) && (
                    <TransitSidebarField
                      label={t('declaration.p5.transportDocumentReferenceNumber')}
                      value={document.transportDocumentReferenceNumber ?? ''}
                    />
                  )
                }
              </div>
            </TransitSidebarConsignmentItemDocumentSection>
          ))
        }
      </div>
    </div>
  )
}

export default TransitSidebarConsignmentItemPreviousDocuments
