/* eslint-disable no-unused-vars */
import { TransportCustomsOffice } from '../routes/Declaration/TransportCustomsOffice/types/TransportCustomsOffice'
import { IConsignmentDetail } from './IConsignmentDetail'
import { ICustomsProfile } from './ICustomsProfile'

export interface IDeclaration {
  id: number | null
  mrn: string | null
  localReferenceNumber: string | null
  status: DeclarationStatusEnum | null
  declarationType: DeclarationTypeEnum
  isGoodsTradersEnabled: boolean | null
  isSecurityEnabled: boolean | null
  consignorId: number | null
  consigneeId: number | null
  carrierId: number | null
  requiresAudit: boolean | null
  profile: string | null
  customerId: number | null
  specialCondition: string | null
  securityReferenceNumber: string | null
  shipmentNumber: string | null
}
export interface IDeclarationReviewResponse {
  id: number | null
  isReviewed: boolean | null
  dateReviewed: string
  reviewedBy: string
}
export interface DeclarationDetailed {
  id: number
  mrn: string | null
  localReferenceNumber: string | null
  status: DeclarationStatusEnum | null
  declarationType: DeclarationTypeEnum
  isGoodsTradersEnabled: boolean
  isSecurityEnabled: boolean
  consignor: IConsignmentDetail | null
  consignee: IConsignmentDetail | null
  carrier: IConsignmentDetail | null
  declarationInfo: IDeclarationInfo
  transport: ITransport
  guarantee: IGuarantee
  created: string
  modified: string
  reason: string
  requiresAudit: boolean | null
  feedback: string | null
  profile: ICustomsProfile | null
  customerId: number | null
  specialCondition: string | null
  securityReferenceNumber: string | null
  shipmentNumber: string | null
}

export interface IDeclarationInfo {
  id: number | null
  place: string | null
  packagesCount: number | null
  grossMass: number | null
  dispatchCountryCode: string | null
  destinationCountryCode: string | null
  transitCountries: Array<ITransitCountry> | []
  goodsLocation: string | null
  goodsLocationQualifier: string | null
  transitDateLimit: string | null
  transitLoadingDate: string | null
}

export interface ITransitCountry {
  id: number | null
  sortOrder: number
  declarationInfoId: number | null
  code: string | null
  nameEn: string | null
  nameRu: string | null
}

export interface IGuarantee {
  id: number | null
  amount: number | null
  declarationId: number | null
}

export interface ITransport {
  id: number | null
  departureTruckNo: string | null
  departureNationality: string | null
  departureCustomsOffice: string | null
  borderCrosserTruckNo: string | null
  borderCrosserNationality: string | null
  borderTransportMode: string | null
  isIndicatorContainerised: boolean
  destinationCustomsOffice: string | null
  unloadingPlace: string | null
  loadingPlace: string | null
  inlandTransportMode: string | null
  transportContainers: Array<ITransportContainer> | []
  transportCustomsOffices: Array<TransportCustomsOffice>
  declarationId: number | null
}

export interface ITransportContainer {
  id: number | null
  containerNumber: string | null
  transportId: number | null
  goodsItemSortOrder: number | null
  sortOrder: number
}

export enum FinnishQualifiers {
  Z = 'CUSTOMS_OFFICE',
  L = 'WAREHOUSE_IDENTIFIER', // Simple
  P = 'ZIP_CODE',
  O = 'GEOGRAPHICAL_LOCATION',
}

export enum DeclarationStatusEnum {
  CANCELED = 'CANCELED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CONTROL_DECISION = 'CONTROL_DECISION',
  OPEN = 'OPEN',
  CUSTOMS_REQUEST = 'CUSTOMS_REQUEST',
  ACCEPTED = 'ACCEPTED',
  DRAFT = 'DRAFT',
  UNLOADING_PERMISSION = 'UNLOADING_PERMISSION',
  GOODS_RELEASE_NOTIFICATION = 'GOODS_RELEASE_NOTIFICATION',
  WRITEOFF_NOTIFICATION = 'WRITEOFF_NOTIFICATION',

  ASSESSED = 'ASSESSED',
  BOOKING = 'BOOKING',
  CANCELLATION_OK = 'CANCELLATION_OK',
  CANCELLED = 'CANCELLED',
  CAN_RELEASE = 'CAN_RELEASE',
  CHECK_NOT_OK = 'CHECK_NOT_OK',
  DELETED = 'DELETED',
  DISCREPANCIES = 'DISCREPANCIES',
  GOODS_RELEASED = 'GOODS_RELEASED',
  IE006_ARRIVED = 'IE006_ARRIVED',
  INVALID_CANCELLATION = 'INVALID_CANCELLATION',
  NOT_FOUND = 'NOT_FOUND',
  PROCEDURE_ENDED = 'PROCEDURE_ENDED',
  RECOVERY = 'RECOVERY',
  RECOVERY_COMPLETED = 'RECOVERY_COMPLETED',
  REDIRECTED = 'REDIRECTED',
  TRANSIT_FORBIDDEN = 'TRANSIT_FORBIDDEN',
  TRANSIT_PROCEDURE_COMPLETED = 'TRANSIT_PROCEDURE_COMPLETED',
  WAITING_CANCELLATION = 'WAITING_CANCELLATION',
  WAITING_GOODS = 'WAITING_GOODS',
  SUBMITTED = 'SUBMITTED',
  SUBMISSION_CONFIRMED = 'SUBMISSION CONFIRMED',
  REJECTED = 'REJECTED',
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  T1_TRANSIT_CLOSED = 'T1_TRANSIT_CLOSED',
  AMENDMENT_REQUEST = 'AMENDMENT_REQUEST',
  CHANGE_REVIEW = 'CHANGE_REVIEW',
}

export enum DeclarationTypeEnum {
  T1, T2, TIR,
}
