import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { blankAdditionalReference } from '../../../form'
import { ADDITIONAL_REFERENCE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { AdditionalReference } from '../../../form/schemas/commonConsignmentSchemas'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted'

function isAdditionalReferenceField(field: string): field is Exclude<ObjectKeys<AdditionalReference>, NotImportableFields> {
  return field in blankAdditionalReference
}

export default function mapAdditionalReference(
  newImportedItemObject: AdditionalReference[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isAdditionalReferenceField(consignmentItemObjectSubfield)) {
    return
  }
  let additionalReference = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)

  if (additionalReference === undefined) {
    additionalReference = {
      ...blankAdditionalReference,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(additionalReference)
  }

  switch (consignmentItemObjectSubfield) {
    case 'referenceNumber':
      additionalReference[consignmentItemObjectSubfield] = cellValue
      break
    case 'type':
      additionalReference[consignmentItemObjectSubfield] = hasText(cellValue) ? ADDITIONAL_REFERENCE_CODE_PREFIX + cellValue : ''
      break
    default:
      throw Error(`Unknown additional reference field ${consignmentItemObjectSubfield}`)
  }
}
