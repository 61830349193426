import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Customer } from 'types/Customer'
import ROUTES from '../../../../config/routes'
import CustomerService from '../../services/customer.service'

const blankCustomer: Customer = {
  accessCode: '',
  city: '',
  contactEmail: '',
  country: 'COUNTRY_EE',
  eoriNumber: '',
  id: 0,
  maxGuaranteeAmount: 1,
  auditRequiredAmount: 1,
  guaranteeFormula: '',
  name: '',
  notes: '',
  registryCode: '',
  status: undefined,
  street: '',
  zipCode: '',
  documentFilesRequired: true,
  requiresAudit: true,
  remoteClientCode: '',
  profileStatus: '',
  isApiAccessEnabled: false,
}

function useCustomer(customerId?: string) {
  const [fetchedCustomer, setFetchedCustomer] = useState<Customer>(blankCustomer)
  const location = useLocation()
  const history = useNavigate()
  const [isExternalCustomer, setExternalCustomer] = useState(location.pathname.startsWith('/external-customer'))

  useEffect(() => {
    if (customerId === undefined) {
      return
    }

    CustomerService.getCustomer(Number(customerId))
      .then((customerResponse) => {
        setFetchedCustomer(customerResponse)

        setExternalCustomer(customerResponse.external ?? false)
        if (customerResponse.external === true) {
          history(`${ROUTES.externalCustomer}/${customerResponse.id}`, { replace: true })
        } else {
          history(`${ROUTES.customer}/${customerResponse.id}`, { replace: true })
        }
      })
  }, [customerId])

  const updateRemoteClientCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFetchedCustomer({
      ...fetchedCustomer,
      remoteClientCode: event.target.value,
    })
  }

  return {
    fetchedCustomer,
    setFetchedCustomer,
    updateRemoteClientCode,
    isExternalCustomer,
  }
}

export default useCustomer
