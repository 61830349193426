import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormScope, RuleResult } from 'types/DeclarationP5'
import Tooltip from 'rc-tooltip'
import { TransitOperationContext } from '../hooks/useTransitOperationContext'
import Input from './Input'
import FormSelect from './FormSelect'

export interface FieldProps {
  labelKey: string
  hidden?: boolean
  className?: string | undefined
  scope?: FormScope | undefined
  ruleResult?: RuleResult | undefined
  // eslint-disable-next-line react/require-default-props
  tooltipContent?: React.ReactNode
}
function FormField({
  labelKey, hidden, className, children, scope, ruleResult, tooltipContent,
}: React.PropsWithChildren<FieldProps>) {
  const { t } = useTranslation()
  const { fieldRules } = useContext(TransitOperationContext)

  const rule = useMemo(() => {
    if (ruleResult !== undefined) return ruleResult
    let fieldPath: FormScope | undefined = scope

    if (fieldPath === undefined && React.isValidElement(children) && !Array.isArray(children)) {
      if (typeof children.type !== 'string' && children.props.field !== undefined
        && (children.type.name === Input.name || children.type.name === FormSelect.name)
      ) {
        fieldPath = children.props.field as FormScope
      }
      if ((children.props.className ?? '').includes('input-group')) {
        const childInput = (children.props.children ?? [])
          .find((child: any) => child.type !== undefined && (child.type.name === Input.name || child.type.name === FormSelect.name))
        if (childInput) {
          fieldPath = childInput.props.field as FormScope
        }
      }
    }
    if (fieldPath !== undefined) {
      const separatorBeforeParentPath = fieldPath.lastIndexOf('.')
      if (separatorBeforeParentPath > 0) {
        let parentPath = fieldPath.slice(0, separatorBeforeParentPath)
        const isCurrentPathAnItemInArray = !Number.isNaN(Number(parentPath.at(parentPath.length - 1)))
        if (isCurrentPathAnItemInArray) {
          parentPath = parentPath.slice(0, parentPath.lastIndexOf('.'))
        }
        const parentRule = fieldRules.find((item) => item.path === parentPath)?.ruleResult
        if (parentRule === 'NOT_ALLOWED') {
          return 'NOT_ALLOWED'
        }
      }
    }

    return fieldRules.find((item) => item.path === fieldPath)?.ruleResult ?? 'OPTIONAL'
  }, [fieldRules, ruleResult])

  return (
    <React.Fragment key={labelKey}>
      {
        !hidden
        && (
          <div className={`row mb-2 ${className}`}>
            <div className={
              `col-4 d-flex align-items-center justify-content-end text-end input-label pt-0 ${
                rule === 'REQUIRED' ? 'required' : ''
              } ${
                rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''
              }`
            }
            >
              {t(labelKey)}
              {tooltipContent !== undefined ? (
                <Tooltip overlayClassName="tooltip-lg" overlay={<small>{tooltipContent}</small>}>
                  <button className="btn btn-link btn-gray-700 p-0 h-100" type="button">
                    <i className="fal fa-info-circle ms-1" />
                  </button>
                </Tooltip>
              ) : null}

            </div>
            <div className="col-8">{children}</div>
          </div>
        )
      }
    </React.Fragment>
  )
}

FormField.defaultProps = {
  hidden: false,
  className: '',
  scope: undefined,
  ruleResult: undefined,
}

export default FormField
