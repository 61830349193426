import React, { useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Tooltip from 'rc-tooltip'
import UserService, { UserListResponse, UserStatus } from '../services/user.service'
import { dateFormatWithTimeOptions } from '../../../config/dateFormatterConfig'
import { UserContext } from '../../../context/UserContext'
import Status from './UserStatus'
import ChangeUserModal from './ChangeUserModal'
import { UserRole } from '../../../hooks/useAuth'

interface UsersTableRowProps {
  currentUser: UserListResponse;
  loadUsers: () => void;
  currentUserEmail: string;
  customerId: number;
}

function UsersTableRow(props: UsersTableRowProps) {
  const {
    currentUser,
    loadUsers,
    currentUserEmail,
    customerId,
  } = props

  const [isUserModalVisible, toggleUserModal] = useState<boolean>(false)
  const [saving, setSaving] = useState(false)
  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const toggleUserBlockStatus = () => {
    if (saving) {
      return
    }
    setSaving(true)
    const newStatus: UserStatus = currentUser.status === UserStatus.BLOCKED
    || currentUser.status === UserStatus.ARCHIVED ? UserStatus.ACTIVE : UserStatus.BLOCKED
    UserService.patchUserStatus({
      userId: currentUser.id,
      status: newStatus,
    })
      .then(() => {
        toast.success('User status updated')
        loadUsers()
        setSaving(false)
      })
      .catch(() => {
        toast.error('Failed to update user status')
        setSaving(false)
      })
  }

  const sendPasswordResetEmailToUser = () => {
    UserService.sendForgotPasswordEmail({
      email: currentUser.email,
    })
      .then(() => {
        toast.success(t('messages.recoverySent'))
      })
      .catch(() => {
        toast.error(t('messages.recoverySendingFailed'))
      })
  }

  const sendConfirmationEmailToUser = () => {
    UserService.sendConfirmationEmail({
      email: currentUser.email,
    })
      .then(() => {
        toast.success(t('messages.recoverySent'))
      })
      .catch(() => {
        toast.error(t('messages.recoverySendingFailed'))
      })
  }

  const toggleUserArchivedStatus = () => {
    if (saving) {
      return
    }

    setSaving(true)

    UserService.deleteUser(currentUser.id)
      .then(() => {
        toast.success(t('messages.userDeleted'))
        loadUsers()
        setSaving(false)
      })
      .catch(() => {
        toast.error('Failed to update user status')
        setSaving(false)
      })
  }

  const createdDateFormatted = useMemo(() => (currentUser != null
    ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(currentUser.created)) : ''), [currentUser])

  const deletedDateFormatted = useMemo(() => (currentUser != null && currentUser.deleted !== null
    ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(currentUser.deleted)) : ''), [currentUser])

  const lastLoginDateFormatted = useMemo(() => (currentUser != null && currentUser.lastLogin !== null
    ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(currentUser.lastLogin)) : ''), [currentUser])

  function openChangeModal() {
    if (user?.role !== 'ADMIN') return

    toggleUserModal(true)
  }

  return (
    <tr key={`${currentUser.email}_${currentUser.firstname}_${currentUser.id}`}>
      <td key={currentUser.firstname} className="w-1 text-nowrap">
        {`${currentUser.firstname} ${currentUser.lastname}`}
      </td>
      <td
        key={currentUser.email}
        className="w-1 text-left"
      >
        {currentUser.email}
      </td>
      {
        user?.role === 'ADMIN' && (
          <td key={currentUser.remoteStaffCode} className="w-1 text-nowrap">
            {currentUser.remoteStaffCode}
          </td>
        )
      }
      <td key={`${currentUser.phone}_${currentUser.id}`} className="text-left">
        {currentUser.phone}
      </td>
      <td key={currentUser.status} className="w-1 text-center">
        <Status status={currentUser.status} />
      </td>
      <td key={currentUser.created} className="text-left text-nowrap">
        {createdDateFormatted}
      </td>
      <td key={`deleted_${currentUser.deleted}`} className="text-left text-nowrap">
        {deletedDateFormatted}
      </td>
      <td key={`login_${currentUser.lastLogin}`} className="text-left text-nowrap">
        {lastLoginDateFormatted}
      </td>
      <td key={`${currentUser.email}_buttons_${currentUser.id}`} className="w-1 text-right text-nowrap p-0">
        {
          currentUser.email !== currentUserEmail && currentUser.status === UserStatus.ACTIVE
          && user?.role === UserRole.ADMIN && (
            <button
              disabled={saving}
              type="button"
              className="btn btn-link p-1"
              onClick={() => openChangeModal()}
            >
              <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.editUser')}</small>}>
                <i className="fal fa-edit fa-md" />
              </Tooltip>
            </button>
          )
        }
        {
          currentUser.email !== currentUserEmail && currentUser.status !== UserStatus.INACTIVE && (
            <button
              disabled={saving}
              type="button"
              className="btn btn-link p-1"
              onClick={() => toggleUserBlockStatus()}
            >
              {
                currentUser.status !== UserStatus.BLOCKED && currentUser.status !== UserStatus.ARCHIVED
                && (
                  <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.blockUser')}</small>}>
                    <i className="fal fa-ban fa-md" />
                  </Tooltip>
                )
              }
              {
                currentUser.status === UserStatus.BLOCKED
                && (
                  <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.enableAccount')}</small>}>
                    <i className="fal fa-check fa-md" />
                  </Tooltip>
                )
              }
            </button>
          )
        }
        {
          currentUser.email !== currentUserEmail && currentUser.status !== UserStatus.INACTIVE && (
            <button
              disabled={saving}
              type="button"
              className="btn btn-link p-1"
              onClick={() => (currentUser.status === UserStatus.ACTIVE ? toggleUserArchivedStatus() : toggleUserBlockStatus())}
            >
              {
                currentUser.status !== UserStatus.ARCHIVED
                && (
                  <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.deleteUser')}</small>}>
                    <i className="fal fa-trash fa-md" />
                  </Tooltip>
                )
              }
              {
                currentUser.status === UserStatus.ARCHIVED
                && (
                  <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.enableAccount')}</small>}>
                    <i className="fal fa-check fa-md" />
                  </Tooltip>
                )
              }
            </button>
          )
        }
        {
          currentUser.email !== currentUserEmail && currentUser.status === UserStatus.ACTIVE && (
            <button
              disabled={saving}
              type="button"
              className="btn btn-link p-1"
              onClick={() => sendPasswordResetEmailToUser()}
            >
              <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.passwordReset')}</small>}>
                <i className="fal fa-key fa-md" />
              </Tooltip>
            </button>
          )
        }
        {
          currentUser.email !== currentUserEmail && currentUser.status === UserStatus.INACTIVE && (
            <button
              disabled={saving}
              type="button"
              className="btn btn-link p-1"
              onClick={() => sendConfirmationEmailToUser()}
            >
              <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('tooltips.confirmationEmail')}</small>}>
                <i className="fal fa-envelope fa-md" />
              </Tooltip>
            </button>
          )
        }
      </td>
      <ChangeUserModal
        isUserModalVisible={isUserModalVisible}
        toggleUserModal={toggleUserModal}
        existingUser={currentUser}
        customerId={Number(customerId)}
      />
    </tr>
  )
}

export default UsersTableRow
