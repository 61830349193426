import TextArea from 'components/Form/TextArea'
import FormRow from 'components/FormRow'
import { AccessLevel, DeclarationContext } from 'context/DeclarationContext'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { UserContext } from '../../../../context/UserContext'
import { purgeForbiddenCharacters } from '../../../../helpers/string.helper'
import { UserRole } from '../../../../hooks/useAuth'
import { ISelectOption } from '../../../../types/IClassifier'
import useDirectoCustomerSearch from './hooks/useDirectoCustomerSearch'

function DirectoEvent() {
  const { t } = useTranslation()
  const {
    directoEvent,
    metaData,
  } = useContext(DeclarationContext)

  const { user } = useContext(UserContext)
  const {
    descriptionAddendum,
    setDescriptionAddendum,
    setCustomerId,
    initialCustomerId,
  } = directoEvent

  const {
    loadOptions,
    searchQueryBuffer,
    setSearchQueryBuffer,
    valueBuffer,
    setValueBuffer,
    directoClients,
  } = useDirectoCustomerSearch({ initialCustomerId })

  useEffect(() => {
    setCustomerId(valueBuffer?.value)
  }, [valueBuffer])

  return (
    <React.Fragment key="directoEvent">
      {
        user?.role === UserRole.ADMIN
        && (
          <>
            <div className="heading">
              <h3>
                {t('declaration.headers.directoEvent')}
              </h3>
            </div>
            <FormRow title={t('directo.customer')}>
              <AsyncSelect
                isClearable
                isSearchable
                cacheOptions
                defaultOptions={valueBuffer ? [valueBuffer] : []}
                noOptionsMessage={() => t('messages.noMatches')}
                isDisabled={metaData.accessLevel === AccessLevel.VIEW}
                menuPlacement="auto"
                loadOptions={loadOptions}
                className="select menu-list--lg mb-1"
                classNamePrefix="select"
                value={valueBuffer}
                inputValue={searchQueryBuffer}
                placeholder={t('directo.customerSearch')}
                onInputChange={(newValue, actionMeta) => {
                  if (actionMeta.action === 'input-change') {
                    setSearchQueryBuffer(newValue)
                  } else {
                    setSearchQueryBuffer(undefined)
                  }
                }}
                onChange={(option: ISelectOption | null) => setValueBuffer(option)}
              />

            </FormRow>
            <FormRow title={t('directo.descriptionAddendum')}>
              <TextArea
                name="descriptionAddendum"
                placeholder={t('common.fieldPlaceholder')}
                disabled={metaData.accessLevel === AccessLevel.VIEW}
                value={descriptionAddendum}
                onChange={(event) => setDescriptionAddendum(purgeForbiddenCharacters(event.target.value))}
              />
            </FormRow>

            <FormRow title={t('customers.notes')}>
              <textarea
                className="form-control"
                disabled
                rows={5}
                placeholder={t('common.fieldPlaceholder')}
                value={directoClients.find((client) => client.id === valueBuffer?.value)?.note ?? ''}
              />
            </FormRow>
          </>
        )
      }
    </React.Fragment>
  )
}

export default DirectoEvent
