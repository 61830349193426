import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import LoadingSpinner from 'components/LoadingSpinner'

interface RequestReleaseModalProps {
  isVisible: boolean
  toggleVisibility: () => void
  transitOperationId: number | null
  releaseTransitOperation: (transitOperationId: number, isAcceptedRelease: boolean) => Promise<void>
}

function RequestReleaseModal(props: Readonly<RequestReleaseModalProps>) {
  const [isAcceptedRelease, setIsAcceptedRelease] = useState('false')
  const [loading, setLoading] = useState(false)

  const {
    isVisible,
    toggleVisibility,
    transitOperationId,
    releaseTransitOperation,
  } = props

  const { t } = useTranslation()

  const submitForm = () => {
    if (transitOperationId) {
      setLoading(true)
      releaseTransitOperation(transitOperationId, isAcceptedRelease === 'true')
        .finally(() => {
          setLoading(false)
          toggleVisibility()
        })
    }
  }

  return (
    <Modal
      show={isVisible}
      size="sm"
      className="overflow-hidden"
      dialogClassName="modal-g2rid modal"
      onHide={() => toggleVisibility()}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{t('declaration.p5.requestRelease', { context: 'title' })}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-self-center w-100">
          <p>{t('declaration.p5.requestRelease')}</p>
          <div className="form-check d-flex align-items-center me-3">
            <input
              className="form-check-input"
              type="radio"
              name="isAcceptedRelease"
              id="notAcceptedRelease"
              value="false"
              checked={isAcceptedRelease !== 'true'}
              onChange={() => {
                setIsAcceptedRelease('false')
              }}
            />
            <label className="form-check-label text-nowrap ps-1" htmlFor="notAcceptedRelease">
              {`${t('common.no')} (0)`}
            </label>
          </div>
          <div className="form-check d-flex align-items-center me-3">
            <input
              className="form-check-input"
              type="radio"
              name="isAcceptedRelease"
              id="yesAcceptedRelease"
              value="true"
              checked={isAcceptedRelease === 'true'}
              onChange={() => {
                setIsAcceptedRelease('true')
              }}
            />
            <label className="form-check-label text-nowrap ps-1" htmlFor="yesAcceptedRelease">
              {`${t('common.yes')} (1)`}
            </label>
          </div>
        </div>
        <div className="d-flex align-content-end justify-content-end mt-5">
          <button
            type="button"
            className="btn btn-lg btn-primary mx-1 text-black shadow-sm"
            onClick={submitForm}
          >
            {
              loading && <LoadingSpinner />
            }
            {
              !loading && (<span>{t('buttons.submit')}</span>)
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RequestReleaseModal
