import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../../apiConfig'
import { CustomsRequest } from '../../../../common/models'

const {
  baseUrl,
} = DeclarationApi.paths.transitOperation

function useChangeActionApi() {
  const change = useMutation({
    mutationFn: async (request: CustomsRequest) => (await axios.post<void>(`${baseUrl}/customs/amend`, request)).data,
  })

  return {
    change,
  }
}

export default useChangeActionApi
