import React, { SetStateAction, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

interface FileUploadDropzoneProps {
  setAcceptedFiles: React.Dispatch<SetStateAction<File[]>>
}

function FileUploadDropzone({ setAcceptedFiles }: FileUploadDropzoneProps) {
  const { t } = useTranslation()
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone()

  useEffect(() => {
    setAcceptedFiles(acceptedFiles)
  }, [acceptedFiles])

  return (
    <section className="dropzone-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{t('declaration.p5.dropzoneDescription')}</p>
      </div>
      {
        acceptedFiles.length > 0 && (
          <div className="row">
            <div className="col-12 mt-3">
              <h4>
                {t('declaration.p5.files')}
              </h4>
              <ol className="list-group ist-group-numbered">
                {acceptedFiles.map((file: File) => (
                  <li className="list-group-item" key={file.name}>
                    {file.name}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        )
      }
    </section>
  )
}

export default FileUploadDropzone
