import { CreateOrUpdateTransitOperationRequestDeclarationTypeEnum } from './models'
import { ISelectOption } from '../../../types/IClassifier'

const DECLARATION_TYPE_OPTIONS: ISelectOption[] = [{
  value: CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T1,
  label: 'T1 - Goods not having the customs status of Union goods, which are placed under the common transit procedure.',
},
{
  value: CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T2,
  label: 'T2 - Goods having the customs status of Union goods, which are placed under the common transit procedure',
},
{
  value: CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T2F,
  label: 'T2F - Goods required to move under the internal Union transit procedure, in accordance with Article 188 of Delegated Regulation (EU) 2015/2446',
},
]

export default DECLARATION_TYPE_OPTIONS
