import { Modal } from 'react-bootstrap'
import Select, { SingleValue } from 'react-select'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import { AccessLevel } from '../../../../context/DeclarationContext'
import { addDeclaration } from '../../../../redux/actions'
import { NctsProfileNameOption } from '../../../../types/NctsProfileNameResponse'
import { IDeclaration } from '../../../../types/IDeclaration'

interface ProfileModalProps {
  profileModalVisible: boolean;
  setProfileModalVisible: Dispatch<SetStateAction<boolean>>
  nctsProfileNames: Array<NctsProfileNameOption>;
  currentNctsProfile: NctsProfileNameOption | null;
  declaration: IDeclaration;
  invalidFormFields: Array<string>;
  confirmCustoms: () => void;
  accessLevel: AccessLevel;

}

function ProfileModal(props: ProfileModalProps) {
  const {
    profileModalVisible, setProfileModalVisible,
    nctsProfileNames, currentNctsProfile, declaration, invalidFormFields, confirmCustoms, accessLevel,
  } = props

  const store = useStore()
  const { t } = useTranslation()

  return (
    <Modal
      show={profileModalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setProfileModalVisible(false)}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-uppercase">{t('declaration.nctsProfile')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setProfileModalVisible(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="m-4">
          <Select
            options={nctsProfileNames}
            menuPlacement="auto"
            isDisabled={accessLevel === AccessLevel.VIEW}
            className="select option"
            classNamePrefix="select"
            value={currentNctsProfile}
            placeholder={t('common.chooseProfile')}
            onChange={(option: SingleValue<NctsProfileNameOption>) => {
              store.dispatch(addDeclaration({
                ...declaration,
                profile: option?.value ?? '',
              }))
            }}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          disabled={invalidFormFields.length > 0 || declaration.profile === '' || declaration.profile === null
                        || declaration.profile === '-'}
          onClick={() => confirmCustoms()}
        >
          <i className="fal fa-reply fa-flip-horizontal me-2" />
          <span>{t('buttons.send')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProfileModal
