import React, { FormEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import UserService from '../Customer/services/user.service'

interface AccountActivationForm {
  activationToken: string | undefined,
  password: string,
  passwordRepeated: string,
}

const blankForm: AccountActivationForm = {
  activationToken: undefined,
  password: '',
  passwordRepeated: '',
}

function AccountActivation() {
  const location = useLocation()
  const history = useNavigate()
  const { t } = useTranslation()
  // @ts-ignore
  const { activationToken } = useParams()
  const [accountActivationForm, setAccountActivationForm] = useState<AccountActivationForm>(blankForm)
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false)

  useEffect(() => {
    if (activationToken === undefined || activationToken.length !== 36) {
      setErrors([t('messages.activationUrlError')])
    }
    if (location.pathname.startsWith('/reset-password')) {
      setIsPasswordRecovery(true)
    }
  }, [activationToken])

  const isPasswordValid = () => {
    let error: string | undefined
    if (accountActivationForm.password !== accountActivationForm.passwordRepeated) {
      error = t('messages.passwordsMatchError')
    } else if (accountActivationForm.password.length < 7) {
      error = t('messages.passwordsLengthError')
    } else if (accountActivationForm.password.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$') === null) {
      error = t('messages.passwordsLetterError')
    }
    if (error === undefined) {
      setErrors([])
      return true
    }
    setErrors([error])
    return false
  }

  const activateAccount = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (!isPasswordValid()) {
      return
    }
    if (activationToken === undefined) {
      setErrors([t('messages.invalidUrlError')])
      return
    }

    setLoading(true)
    UserService
      .activateUser({ activationToken, password: accountActivationForm.password })
      .then(() => {
        toast.success(t('messages.savingSuccess'))
        setLoading(false)
        history('/login')
      })
      .catch(() => {
        toast.error(t('messages.accountActivationError'))
        setLoading(false)
      })
  }

  return (
    <div className="login__container">
      <div className="login__card">
        <h1
          className="d-flex justify-content-center w-100 rounded-top mb-1 p-3 text-uppercase"
        >
          Tardek T1
        </h1>
        <h4
          className="d-flex justify-content-center w-100 rounded-top mb-3"
        >
          {
            isPasswordRecovery
            && (
              <span>
                {t('user.accountCreationSetPassword')}
              </span>
            )
          }
          {
            !isPasswordRecovery
            && (
              <span>
                {t('user.accountCreationCreatePassword')}
              </span>
            )
          }
        </h4>

        <form onSubmit={activateAccount}>
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-lock-alt fa-md" />
            </span>
            <input
              required
              id="password"
              type="password"
              className="form-control px-1 py-3"
              placeholder={t('common.password')}
              autoComplete="new-password"
              value={accountActivationForm.password}
              onChange={(event) => {
                setAccountActivationForm({
                  ...accountActivationForm,
                  password: event.target.value,
                })
              }}
            />
          </div>
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon2">
              <i className="fal fa-lock-alt fa-md" />
            </span>
            <input
              required
              id="repeat-password"
              type="password"
              className="form-control px-1 py-3"
              autoComplete="new-password"
              placeholder={t('user.password', { context: 'repeat' })}
              value={accountActivationForm.passwordRepeated}
              onChange={(event) => {
                setAccountActivationForm({
                  ...accountActivationForm,
                  passwordRepeated: event.target.value,
                })
              }}
            />
          </div>
          {
            errors.length > 0
            && (
              <div className="mb-2">
                {
                  errors.map((message) => (
                    <small key={message} className="text-danger">
                      {message}
                    </small>
                  ))
                }
              </div>
            )
          }

          <button
            className="btn btn-primary btn-lg mb-3 p-3 w-100"
            type="submit"
            disabled={loading}
          >
            {
              isPasswordRecovery
              && (
                <span>
                  {`${t('buttons.change')} ${t('common.password')}`}
                </span>
              )
            }
            {
              !isPasswordRecovery
              && (
                <span>
                  {t('buttons.submit')}
                </span>
              )
            }
          </button>
        </form>
      </div>
    </div>
  )
}

export default AccountActivation
