/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import LoadingBackdrop from 'components/LoadingBackdrop'
import { ConsignmentItemResponse } from '../../models'
import TransitSidebarGoodsItemRow from './TransitSidebarGoodsItemRow'
import useConsignmentItemApi from '../../../TransitOperationEditor/hooks/useConsignmentItem/api'

export interface TransitSidebarGoodsItemsProps {
  houseConsignmentIds: number[]
  transitOperationId: number
  isLoading: boolean
}

function TransitSidebarGoodsItems(props: TransitSidebarGoodsItemsProps) {
  const {
    isLoading,
    houseConsignmentIds,
    transitOperationId,
  } = props

  const { fetchConsignmentItems } = useConsignmentItemApi(houseConsignmentIds, isLoading)

  const consignmentItems = useMemo(
    () => {
      const consignmentItemResponses = fetchConsignmentItems
        .flatMap((query: UseQueryResult<ConsignmentItemResponse[]>) => query.data ?? [])

      consignmentItemResponses.sort((a, b) => (
        a.goodsItemNumber !== null && b.goodsItemNumber !== null
          ? (a.goodsItemNumber < b.goodsItemNumber) ? -1 : ((a.goodsItemNumber > b.goodsItemNumber) ? 1 : 0) : 0))
      return consignmentItemResponses
    },
    [fetchConsignmentItems.reduce((sum, query) => sum + query.dataUpdatedAt, 0)],
  )

  return (
    <div className="col-12">
      {
        (fetchConsignmentItems.some((query) => query.isFetching) || isLoading) && (
          <div className="position-relative mt-2">
            <LoadingBackdrop loading isInTable={false} />
          </div>
        )
      }
      {
        consignmentItems.length > 0
        && consignmentItems
          .map(
            (consignmentItem) => (
              <TransitSidebarGoodsItemRow
                key={consignmentItem.id}
                consignmentItem={consignmentItem}
                initialOpenState={consignmentItems.length <= 5}
                transitOperationId={transitOperationId}
              />
            ),
          )
      }
    </div>
  )
}

export default TransitSidebarGoodsItems
