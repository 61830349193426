import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { addGuarantee } from 'redux/actions'
import { useStore } from 'react-redux'
import { IStoreState } from 'redux/store'
import CalculationRow from './CalculationRow'
import { GuaranteeCalculationGoodsItemRow } from '../../hooks/useGuaranteeCalculator'

interface GuaranteeCalculatorProps {
  isCalculationModalVisible: boolean;
  setCalculationModalVisible: Dispatch<SetStateAction<boolean>>;
  rows: GuaranteeCalculationGoodsItemRow[];
  exchangeRate: number,
  exchangeRateLoading: boolean,
}

function GuaranteeCalculatorModal({
  rows,
  isCalculationModalVisible,
  setCalculationModalVisible,
  exchangeRate,
  exchangeRateLoading,
}: GuaranteeCalculatorProps) {
  const { t } = useTranslation()
  const store = useStore()
  const getGuarantee = () => (store.getState() as IStoreState).guaranteeReducer.guarantee

  const totalPriceSum = useMemo(() => rows.reduce((previousValue, currentValue) => previousValue
      + (currentValue.taxableItemPrice ?? 0), 0), [rows])
  const totalPriceInRon = (totalPriceSum * exchangeRate).toFixed(3)

  const setCalculationResultAsGuaranteeAmount = () => {
    const guarantee = getGuarantee()

    const taxesSum: number = rows.reduce((accumulatedSum, item) => accumulatedSum + (item.taxableDutyAmount ?? 0)
      + (item.taxableVatAmount ?? 0), 0)

    store.dispatch(addGuarantee({
      ...guarantee,
      amount: Number(taxesSum),
    }))
  }

  return (
    <Modal
      show={isCalculationModalVisible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setCalculationModalVisible(false)}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
      dialogClassName="modal-to-size"
      fullscreen="lg-down"
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-capitalize">
            {t('guaranteeCalculator.preview')}
          </h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setCalculationModalVisible(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="guarantee-calculation-container">
          <div />
          <div>{t('guaranteeCalculator.price')}</div>
          <div>{t('guaranteeCalculator.dutyRate')}</div>
          <div>{t('guaranteeCalculator.vatRate')}</div>
          <div>{t('guaranteeCalculator.taxableDutyAmount')}</div>
          <div>{t('guaranteeCalculator.taxableVatAmount')}</div>
          <div>{t('guaranteeCalculator.taxableItemPrice')}</div>
          {
            rows.map((rowItem) => <CalculationRow key={`calc_row_${rowItem.sortOrder}`} rowItem={rowItem} />)
          }
        </div>
        <div className="d-flex justify-content-end mt-4 px-3">
          <div>
            <div className="mr-2">{t('guaranteeCalculator.totalPrice')}</div>
            <div className="d-flex justify-content-end">
              1 EUR =
              {exchangeRate}
            </div>
            <div className="d-flex justify-content-end">
              {totalPriceSum.toFixed(3)}
              {' '}
              EUR
            </div>
            <div className="d-flex justify-content-end">
              {totalPriceInRon}
              {' '}
              RON
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={exchangeRateLoading}
          className="btn btn-dark btn-lg m-0 text-primary"
          onClick={() => {
            setCalculationResultAsGuaranteeAmount()
            setCalculationModalVisible(false)
          }}
        >
          {exchangeRateLoading ? <LoadingSpinner /> : <span>{t('guaranteeCalculator.useResultValue')}</span>}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default GuaranteeCalculatorModal
