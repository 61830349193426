import React from 'react'
import { Row } from 'react-table'
// eslint-disable-next-line import/no-cycle
import { CustomerCountsData } from '../index'
import TableRow from './TableRow'
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface CustomersTableBodyProps {
  isLoading: boolean;
  page: Array<Row<object>>;
  prepareRow: any;
  getTableBodyProps: any;
  refreshPage: () => void;
  customerCountsData: Array<CustomerCountsData>
}

function TableBody(props: CustomersTableBodyProps) {
  const {
    getTableBodyProps,
    page,
    prepareRow,
    refreshPage,
    customerCountsData,
  } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <tbody {...getTableBodyProps()} className="position-relative">
      {
        page.map((row: Row<any>, i: number) => {
          prepareRow(row)
          const customerCounts = customerCountsData.find((item) => item.customerId === row.original?.id)

          return (
            <TableRow
              key={`${row.id}_row_wrapper`}
              row={row}
              index={i}
              refreshPage={refreshPage}
              customerCounts={customerCounts}
            />
          )
        })
      }
    </tbody>
  )
}
export default TableBody
