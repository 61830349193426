import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { removePrefix } from '../../../common/utils/classifier-util'
import { CL112_CTC_COUNTRIES } from '../../../common/utils/ncts-constants'

const C0821Validator = {
  validateCombinedNomenclatureCodeRequirement: ({
    departureCustomsOffice,
  }: DeclarationForm): RuleResult => {
    if (CL112_CTC_COUNTRIES.includes(removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2) ?? '')) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0821Validator
