import { useContext, useEffect, useMemo } from 'react'
import { IGoodsItem } from 'types/IGoodsItem'
import { UserContext } from 'context/UserContext'
import useRestrictionKeys from '../../../hooks/useRestrictionKeys'

function useRestrictedCodes(goodsItems: Array<IGoodsItem>, isShared: boolean) {
  const { user } = useContext(UserContext)
  const {
    restrictionKeys,
    load,
  } = useRestrictionKeys()

  useEffect(() => {
    load()
  }, [])

  const restrictedGoods: IGoodsItem[] = useMemo(() => {
    if (isShared && user?.role !== 'ADMIN' && restrictionKeys.length !== 0) {
      return goodsItems.filter((item) => item.hsCode !== null
        && item.hsCode !== undefined
        && restrictionKeys.indexOf(item.hsCode) !== -1)
    }

    return []
  }, [isShared, user?.role, restrictionKeys.length])

  return {
    restrictedGoods,
    restrictionKeys,
  }
}

export default useRestrictedCodes
