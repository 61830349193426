import axios, { AxiosError } from 'axios'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { UserPreference } from '../../../types/UserPreference'

function getPreferences(): Promise<Array<UserPreference>> {
  return new Promise((resolve) => {
    axios.get(apiService.getFullApiUrl('/user-preference'))
      .then(handleResponseData)
      .then((value) => resolve(value))
      .catch(handleApiException)
  })
}

function updatePreferences(userPreferences: Array<UserPreference>): Promise<void> {
  return new Promise((resolve, reject) => {
    axios.patch(apiService.getFullApiUrl('/user-preference'), { userPreferences })
      .then(handleResponseData)
      .then((value) => resolve(value))
      .catch((error: AxiosError) => {
        reject(error)
      })
  })
}

const UserPreferenceService = {
  getPreferences,
  updatePreferences,
}

export default UserPreferenceService
