import { TFunction } from 'i18next'
import { ISelectOption } from '../../types/IClassifier'
import { Header } from '../Transits/Table/config'

export function getTableHeaders(t: TFunction): Header[] {
  return [
    {
      title: t('audit.id'),
      accessor: 'id',
    },
    {
      title: t('audit.declarationId'),
      accessor: 'declarationId',
    },
    {
      title: t('audit.status'),
      accessor: 'status',
    },
    {
      title: t('audit.customer'),
      accessor: 'customerName',
    },
    {
      title: '',
      accessor: 'isReviewed',
      className: 'fixed-width-md-sm',
    },
    {
      title: t('transit.reviewer'),
      accessor: 'reviewedBy',
      className: 'fixed-width-md-sm',
    },
    {
      title: t('transit.lastReview'),
      accessor: 'dateReviewed',
      className: 'fixed-width-md-sm',
    },
    {
      title: t('audit.created'),
      accessor: 'created',
    },
    {
      title: t('audit.modified'),
      accessor: 'modified',
    },
    {
      title: t('audit.reason'),
      accessor: 'reason',
    },
  ]
}

export function getAuditStatusOptions() {
  return [
    { value: 'IN_REVIEW', label: 'In Review' },
    { value: 'ACCEPTED', label: 'Accepted' },
    { value: 'DECLINED', label: 'Declined' },
    { value: 'PROHIBITED_DESCRIPTION', label: 'Prohibited Description' },
  ] as ISelectOption[]
}
