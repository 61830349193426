import { DropdownToggleProps } from 'react-bootstrap/DropdownToggle'
import React from 'react'
import Button from 'react-bootstrap/Button'

export type IconState = 'LOADING' | 'OPEN' | 'CLOSED' | 'ARCHIVED'
type CustomDropdownButtonProps = Omit<DropdownToggleProps, 'as'> & { icon: IconState }

// The forwardRef is important
// Dropdown needs access to the DOM node in order to position the Menu
const DropdownToggleButton = React.forwardRef<HTMLButtonElement, CustomDropdownButtonProps>(
  (props, ref) => (
    <Button
      {...props}
      ref={ref}
    >
      <div className="w-100">
        {props.icon === 'OPEN' && (<i className="fal fa-caret-up" />)}
        {props.icon === 'CLOSED' && (<i className="fal fa-caret-down" />)}
        {props.icon === 'LOADING' && (<i className=" fal fa-spinner fa-spin" />)}
        {props.icon === 'ARCHIVED' && (<i className=" fal fa-trash-alt" />)}
      </div>
    </Button>
  ),
)

export default React.memo(DropdownToggleButton)
