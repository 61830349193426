import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../apiConfig'
import { TransitOperationLogResponse } from '../../../common/models'

export default function useTransitOperationLog(transitOperationId: number | undefined) {
  const {
    baseUrl,
    queryKeys: {
      getTransitOperationLog,
    },
  } = DeclarationApi.paths.transitOperationLog

  const fetchTransitOperationLogs = useQuery({
    enabled: !!transitOperationId,
    queryFn: async ({ signal }) => (await axios
      .get<TransitOperationLogResponse[]>(`${baseUrl}/transit-operation/${transitOperationId}`, { signal })).data,
    queryKey: getTransitOperationLog(transitOperationId!),
    staleTime: Infinity, // never stale
  })

  return {
    fetchTransitOperationLogs,
  }
}
