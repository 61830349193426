import { FieldPathRule } from '../../../../../types/DeclarationP5'
import { UseDeclarationFormReturn } from '../index'
import { excludeDeleted } from '../../../common/utils/common-util'

const updateFormErrors = (results: FieldPathRule[], form: UseDeclarationFormReturn) => {
  // form.clearErrors() TODO peale andmete laadimist osad lahtrid pole enam blank ja required, seega need initial issued tuleks eemaldada
  results.forEach((rule) => {
    if (rule.ruleResult === 'REQUIRED') {
      const formValue = form.getValues(rule.path)
      if (formValue === null || formValue === undefined || formValue === '') {
        form.setError(rule.path, {
          message: 'Required field cannot be blank',
          type: 'string',
        })
        if (!rule.issues.some((someIssue) => someIssue.message.includes('Required'))) {
          rule.issues.push({
            code: 'custom', path: [rule.path], fatal: true, message: 'Required',
          })
        }
      } else if (Array.isArray(formValue) && (formValue as Array<any>).filter(excludeDeleted).length === 0) {
        form.setError(rule.path, {
          message: 'Array cannot be empty',
          type: 'array',
        })
        if (!rule.issues.some((someIssue) => someIssue.message.includes('Required'))) {
          rule.issues.push({
            code: 'custom', path: [rule.path], fatal: true, message: 'Required',
          })
        }
      }
    }

    rule.issues.forEach((issue) => {
      form.setError(rule.path, issue)
    })
  })
}

export default updateFormErrors
