import { IAction } from '../actionTypes'
import { ISeal } from '../../routes/Declaration/Seal/types/Seal'

interface ISealState {
  seal: ISeal | null
}

const initialState: ISealState = {
  seal: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function sealReducer(state = initialState, action: IAction<ISeal>) {
  switch (action.type) {
    case 'ADD_SEAL':
      return {
        ...state,
        seal: action.payload,
      }
    default:
      return state
  }
}
