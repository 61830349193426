import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DeclarationCountry } from 'context/DeclarationContext'

function useCurrencyExchangeRate(country: DeclarationCountry | null) {
  const ECB_URL = 'https://data-api.ecb.europa.eu/service/data/EXR/'
  const MONTHLY_SERIES_KEY = 'M.RON.EUR..A'

  const { t } = useTranslation()
  const [controller] = useState<AbortController>(new AbortController())
  const [exchangeRateLoading, setExchangeRateLoading] = useState<boolean>(false)
  const [exchangeRate, setExchangeRate] = useState<number>(0)

  const loadExchangeRate = () => {
    setExchangeRateLoading(true)
    axios.get(ECB_URL + MONTHLY_SERIES_KEY, {
      params: {
        lastNObservations: 1,
        format: 'csvdata',
        detail: 'dataonly',
      },
      headers: {
        'Content-Type': 'text/csv',
      },
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status !== 200) {
          return
        }
        const responseCSV: string = response.data
        const rows = responseCSV.split('\r\n')
        let delimiter = ','
        if (responseCSV.slice(0, rows[0].indexOf('\r\n'))
          .indexOf(delimiter) === -1) {
          delimiter = ';'
        }
        const headers: Array<string> = rows[0].split(delimiter)
        const row: Array<string> = rows[1].split(delimiter)
        const observationValueColumnIndex = headers.findIndex((headerName) => headerName === 'OBS_VALUE')
        if (row[observationValueColumnIndex] === undefined || Number.isNaN(Number(row[observationValueColumnIndex]))) {
          toast.error(t('common.error'))
        } else {
          setExchangeRate(Number(Number(row[observationValueColumnIndex])
            .toFixed(4)))
        }
      })
      .finally(() => {
        setExchangeRateLoading(false)
      })
  }

  useEffect(() => {
    if (country !== 'RO') {
      return
    }
    loadExchangeRate()

    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort()
    }
  }, [country])

  return {
    exchangeRateLoading,
    exchangeRate,
  }
}

export default useCurrencyExchangeRate
