import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TransitOperationDuplicationRequest, TransitOperationResponse } from '../../../common/models'
import TransitApiConfig from '../../../TransitOperationEditor/hooks/apiConfig'

const {
  baseUrl,
  queryKeys: { rootPath },
} = TransitApiConfig.paths.transitOperation

function useTransitOperationDuplicationApi() {
  const queryClient = useQueryClient()

  const { t } = useTranslation()
  const history = useNavigate()

  const duplicateTransitOperation = useMutation({
    mutationFn: async (data: TransitOperationDuplicationRequest) => (await axios
      .post<TransitOperationResponse>(`${baseUrl}/duplicate`, data)).data,
    onSuccess: (response) => {
      history(`/transit-operation/${response.id}`)
      return queryClient.invalidateQueries({ queryKey: [rootPath] })
    },
    onError: () => { toast.error(t('messages.duplicate', { context: 'failed' })) },
  })

  return {
    duplicateTransitOperation,
  }
}

export default useTransitOperationDuplicationApi
