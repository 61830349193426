import React, { Dispatch, SetStateAction } from 'react'
import Editor from '../Editor'
import HeaderNotificationMessageEditor from './HeaderNotificationMessageEditor'

interface NotificationEditorProps {
  currentView: string;
  headerNotificationMessage: string;
  setHeaderNotificationMessage: Dispatch<SetStateAction<string>>;
  editorContent: string;
  setEditorContent: Dispatch<SetStateAction<string>>;

}

function NotificationEditor({
  editorContent,
  headerNotificationMessage,
  setEditorContent,
  setHeaderNotificationMessage,
  currentView,
}: NotificationEditorProps) {
  return (
    <>
      {currentView === 'SEND' && <Editor editorContent={editorContent} setEditorContent={setEditorContent} />}
      {currentView === 'EDIT' && (
        <HeaderNotificationMessageEditor
          headerNotificationMessage={headerNotificationMessage}
          setHeaderNotificationMessage={setHeaderNotificationMessage}
        />
      )}
    </>
  )
}

export default NotificationEditor
