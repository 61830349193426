import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrayScope } from 'types/DeclarationP5'
import CollapsibleColumn from '../../../components/CollapsibleColumn'
import FormField from '../../../components/FormField'
import Input from '../../../components/Input'
import FormFieldArray from '../../../components/FormFieldArray'
import { TransportEquipmentType } from '../../../form/schemas/transportEquipmentSchema'
import useFieldArrayActionHelper from '../../../services/useFieldArrayActionHelper'
import Seals from './Seals'
import GoodsReferences from './GoodsReferences'
import { blankTransportEquipment } from '../../../form'
import { TransitOperationContext } from '../../../hooks/useTransitOperationContext'

const scope: ArrayScope = 'transportEquipment' as const

function TransportEquipment() {
  const { t } = useTranslation()

  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<TransportEquipmentType>({
    blankItem: blankTransportEquipment,
    name: scope,
  })
  const { fieldRules } = useContext(TransitOperationContext)

  const containerIndicatorRule = useMemo(
    () => fieldRules.find((fieldRule) => fieldRule.path === 'containerIndicator')?.ruleResult ?? 'OPTIONAL',
    [fieldRules],
  )

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-transport-equipment-section"
        headingLabel={t('declaration.p5.transportEquipment')}
      >
        <FormField
          labelKey="declaration.p5.containerisedIndicator"
          ruleResult={containerIndicatorRule}
        >
          <Input
            field="containerIndicator"
            name="containerIndicator"
            labelKey="common.no"
            type="radio"
            value="false"
          />
          <Input
            field="containerIndicator"
            name="containerIndicator"
            labelKey="common.yes"
            type="radio"
            value="true"
          />
        </FormField>
        {
          getFields()
            .map((equipmentItem, index) => (
              <FormFieldArray
                key={`equipmentItem_${equipmentItem.id}`}
                titleKey="declaration.p5.containers"
                removeItem={() => removeAction(index, equipmentItem)}
                hidden={equipmentItem.deleted}
              >
                <FormField labelKey="declaration.p5.sequenceNumber">
                  <span>{equipmentItem.sequenceNumber + 1}</span>
                </FormField>
                <FormField labelKey="declaration.p5.containerNumber" scope={`transportEquipment.${index}.containerIdentificationNumber`}>
                  <Input
                    field={`transportEquipment.${index}.containerIdentificationNumber`}
                    labelKey="declaration.p5.containerNumber"
                    type="text"
                    placeholder={t('common.fieldPlaceholder')}
                  />
                </FormField>
                <GoodsReferences scope={`transportEquipment.${index}.goodsReferences`} />
                <Seals scope={`transportEquipment.${index}.seals`} />
              </FormFieldArray>
            ))
        }
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewContainer')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default TransportEquipment
