import React from 'react'
import DeclarationTabLayout from '../../layout/DeclarationTabLayout'
import GeneralSection from './GeneralSection'
import TransportEquipment from './transportEquipment'
import TransportSection from './TransportSection'
import DepartureTransportMeans from './DepartureTransportMeans'
import BorderTransportMeans from './BorderTransportMeans'
import PlaceOfLoading from './PlaceOfLoading'
import PlaceOfUnloading from './PlaceOfUnloading'
import CountriesOfRouting from './CountriesOfRouting'
import CustomsOfficeOfTransit from './CustomsOfficeOfTransit'
import TransportDocuments from './TransportDocuments'
import { IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'
import PreviousDocuments from './PreviousDocuments'
import SupportingDocuments from './SupportingDocuments'
import AdditionalReferences from './AdditionalReferences'
import LocationOfGoods from './LocationOfGoods'
import Authorisations from './Authorisations'
import Guarantees from './Guarantees'

function DeclarationGeneralTab() {
  return (
    <DeclarationTabLayout
      start={(
        <>
          <GeneralSection />
          <Guarantees />
          <CountriesOfRouting />
        </>
      )}
      centerLeft={(
        <>
          <TransportSection />
          <DepartureTransportMeans scope="departureTransportMeans" />
          <BorderTransportMeans />
        </>
      )}
      centerRight={(
        <>
          <PlaceOfLoading />
          <PlaceOfUnloading />
          <Authorisations />
          { !IS_TRANSITIONAL_PERIOD ? <TransportDocuments scope="transportDocument" /> : null }
          { !IS_TRANSITIONAL_PERIOD ? <PreviousDocuments scope="previousDocument" /> : null }
          { !IS_TRANSITIONAL_PERIOD ? <SupportingDocuments scope="supportingDocument" /> : null }
        </>
      )}
      end={(
        <>
          <TransportEquipment />
          <CustomsOfficeOfTransit scope="transitCustomsOffices" />
          {/* ToDo: Map to Request */}
          { !IS_TRANSITIONAL_PERIOD ? <AdditionalReferences scope="additionalReference" /> : null }
          {/* { IS_TRANSITIONAL_PERIOD ? <AdditionalInformations scope="additionalInformation" /> : null } */}
          <LocationOfGoods />
        </>
      )}
    />
  )
}

export default DeclarationGeneralTab
