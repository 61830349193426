import React from 'react'
import { DeclarationStatusEnum } from '../../../types/IDeclaration'
import { DeclarationAuditStatus } from '../../../types/DeclarationAudit'

interface TableRowStatusProps {
  value: string
}

function TableRowStatus({ value }: TableRowStatusProps) {
  switch (value) {
    case DeclarationStatusEnum.OPEN.toString():
      return <span className="text-secondary">TRANSIT OPEN</span>
    case DeclarationStatusEnum.DRAFT.toString():
      return <span className="text-muted">DRAFT</span>
    case DeclarationStatusEnum.ACCEPTED.toString():
      return <span className="text-success">ACCEPTED</span>
    case DeclarationStatusEnum.CUSTOMS_REQUEST.toString():
      return <span className="text-danger">CUSTOMS REQUEST</span>
    case DeclarationStatusEnum.CANCELED.toString():
      return <span className="text-danger">CANCELED</span>
    case DeclarationStatusEnum.CANCEL_REQUESTED.toString():
      return <span className="text-warning">CANCEL REQUESTED</span>
    case DeclarationStatusEnum.AMENDMENT_REQUEST.toString():
      return <span className="text-warning">AMENDMENT REQUEST</span>
    case DeclarationStatusEnum.CONTROL_DECISION.toString():
      return <span className="text-muted">CONTROL DECISION</span>
    case DeclarationStatusEnum.UNLOADING_PERMISSION.toString():
      return <span className="text-muted">UNLOADING PERMISSION</span>
    case DeclarationStatusEnum.WRITEOFF_NOTIFICATION.toString():
      return <span className="text-muted">WRITEOFF NOTIFICATION</span>
    case DeclarationStatusEnum.GOODS_RELEASE_NOTIFICATION.toString():
      return <span className="text-muted">GOODS RELEASE NOTIFICATION</span>
    case DeclarationStatusEnum.ASSESSED.toString():
      return <span className="text-success">ASSESSED</span>
    case DeclarationStatusEnum.BOOKING.toString():
      return <span className="text-warning">BOOKING</span>
    case DeclarationStatusEnum.CANCELLATION_OK.toString():
      return <span className="text-muted">CANCELLATION OK</span>
    case DeclarationStatusEnum.CANCELLED.toString():
      return <span className="text-danger">CANCELLED</span>
    case DeclarationStatusEnum.CAN_RELEASE.toString():
      return <span className="text-muted">CAN RELEASE</span>
    case DeclarationStatusEnum.CHECK_NOT_OK.toString():
      return <span className="text-danger">CHECK NOT OK</span>
    case DeclarationStatusEnum.DELETED.toString():
      return <span className="text-muted">DELETED</span>
    case DeclarationStatusEnum.DISCREPANCIES.toString():
      return <span className="text-danger">DISCREPANCIES</span>
    case DeclarationStatusEnum.GOODS_RELEASED.toString():
      return <span className="text-success">GOODS RELEASED</span>
    case DeclarationStatusEnum.IE006_ARRIVED.toString():
      return <span className="text-success">GOODS ARRIVED</span>
    case DeclarationStatusEnum.INVALID_CANCELLATION.toString():
      return <span className="text-danger">INVALID CANCELLATION</span>
    case DeclarationStatusEnum.NOT_FOUND.toString():
      return <span className="text-danger">NOT FOUND</span>
    case DeclarationStatusEnum.PROCEDURE_ENDED.toString():
      return <span className="text-success">PROCEDURE ENDED</span>
    case DeclarationStatusEnum.RECOVERY.toString():
      return <span className="text-warning">RECOVERY</span>
    case DeclarationStatusEnum.RECOVERY_COMPLETED.toString():
      return <span className="text-success">RECOVERY COMPLETED</span>
    case DeclarationStatusEnum.REDIRECTED.toString():
      return <span className="text-warning">REDIRECTED</span>
    case DeclarationStatusEnum.TRANSIT_FORBIDDEN.toString():
      return <span className="text-warning">TRANSIT FORBIDDEN</span>
    case DeclarationStatusEnum.TRANSIT_PROCEDURE_COMPLETED.toString():
      return <span className="text-success">TRANSIT ENDED</span>
    case DeclarationStatusEnum.T1_TRANSIT_CLOSED.toString():
      return <span style={{ color: '#4682B4' }}>TRANSIT ENDED</span>
    case DeclarationStatusEnum.WAITING_CANCELLATION.toString():
      return <span className="text-muted">WAITING CANCELLATION</span>
    case DeclarationStatusEnum.WAITING_GOODS.toString():
      return <span className="text-muted">WAITING GOODS</span>
    case DeclarationStatusEnum.REJECTED.toString():
      return <span className="text-danger">REJECTED</span>
    case DeclarationStatusEnum.DECLINED.toString():
      return <span className="text-danger">DECLINED</span>
    case DeclarationStatusEnum.IN_REVIEW.toString():
      return <span className="text-warning">IN REVIEW</span>
    case DeclarationStatusEnum.CHANGE_REVIEW.toString():
      return <span className="text-warning">CHANGE REVIEW</span>
    case DeclarationAuditStatus.PROHIBITED_DESCRIPTION.toString():
      return <span className="text-danger">PROHIBITED DESCRIPTION</span>
    default:
      return <span className="text-success">{value ? value.replaceAll('_', ' ') : value}</span>
  }
}

export default TableRowStatus
