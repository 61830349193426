import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react'
import Tooltip from 'rc-tooltip'
import { useStore } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addConsignmentDetails, addDeclaration } from '../../../../redux/actions'
import { IConsignmentDetail, IConsignmentDetailPayload } from '../../../../types/IConsignmentDetail'
import { IStoreState } from '../../../../redux/store'
import { ISelectOption } from '../../../../types/IClassifier'
import { InvalidField } from '../../../Transits/TransitsAside/detailView'
import EoriSearchInput from '../../../../components/EoriSearchInput'
import FormRow, { IAddressForm } from '../../../../components/FormRow'
import ConsignmentSelect from '../../../../components/ConsignmentSelect'
import { AccessLevel, DeclarationContext } from '../../../../context/DeclarationContext'

interface IConsignmentDetailColumnProps {
  countriesOptions: ISelectOption[];
  invalidFields: Array<InvalidField>;
  traderRestrictions: Array<String>;
  setInvalidFields: Dispatch<SetStateAction<Array<InvalidField>>>;
}

function ConsignmentDetailColumn(props: IConsignmentDetailColumnProps) {
  const {
    countriesOptions, invalidFields, traderRestrictions, setInvalidFields,
  } = props
  const store = useStore()
  const { metaData: { accessLevel } } = useContext(DeclarationContext)
  const { t } = useTranslation()
  const getDeclaration = () => (store.getState() as IStoreState).declarationReducer.declaration
  const getConsignor = () => (store.getState() as IStoreState).consignmentDetailReducer.consignor
  const getConsignee = () => (store.getState() as IStoreState).consignmentDetailReducer.consignee
  const getCarrier = () => (store.getState() as IStoreState).consignmentDetailReducer.carrier

  const [declaration, setDeclaration] = useState(getDeclaration())
  const [consignorForm, setConsignorForm]: [IConsignmentDetail, Dispatch<IConsignmentDetail>] = useState(getConsignor())
  const [consigneeForm, setConsigneeForm]: [IConsignmentDetail, Dispatch<IConsignmentDetail>] = useState(getConsignee())
  const [carrierForm, setCarrierForm]: [IConsignmentDetail, Dispatch<IConsignmentDetail>] = useState(getCarrier())

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setDeclaration(getDeclaration())
      setConsignorForm(getConsignor())
      setConsigneeForm(getConsignee())
      setCarrierForm(getCarrier())
    })

    return () => unsubscribe()
  }, [])

  const toggleTradersUnderGoods = () => {
    store.dispatch(addDeclaration({
      ...declaration,
      isGoodsTradersEnabled: !declaration.isGoodsTradersEnabled,
    }))
  }
  const setUnderGoodsTooltipText = t('tooltips.goodsTraders')

  const securityEnabled = declaration.isSecurityEnabled === true

  const getConsignorInputs = () => (
    <>
      <FormRow title={t('declaration.eoriId').toUpperCase()}>
        <EoriSearchInput
          isDisabled={accessLevel === AccessLevel.VIEW}
          form={consignorForm}
          setForm={(form: IConsignmentDetail) => {
            store.dispatch(
              addConsignmentDetails({
                carrier: carrierForm,
                consignee: consigneeForm,
                consignor: form,
              } as IConsignmentDetailPayload),
            )
          }}
        />
      </FormRow>
      <FormRow
        title={t('declaration.consignor')}
        withSecurity={declaration.isSecurityEnabled === true}
        invalidFields={invalidFields}
      >
        <ConsignmentSelect
          isDisabled={accessLevel === AccessLevel.VIEW}
          type="CONSIGNOR"
          selectForm={consignorForm}
          traderRestrictions={traderRestrictions}
          setSelectForm={(form: IConsignmentDetail) => {
            store.dispatch(
              addConsignmentDetails({
                carrier: carrierForm,
                consignee: consigneeForm,
                consignor: form,
              } as IConsignmentDetailPayload),
            )
          }}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      </FormRow>
      <FormRow
        isDisabled={accessLevel === AccessLevel.VIEW}
        title={`${t('declaration.consignor')} ${t('declaration.address').toLowerCase()}`}
        inputType="address"
        addressForm={{
          street: consignorForm.street,
          city: consignorForm.city,
          country: consignorForm.country,
          zip: consignorForm.zip,
        }}
        setAddressForm={(form: IAddressForm) => {
          store.dispatch(
            addConsignmentDetails({
              carrier: carrierForm,
              consignee: consigneeForm,
              consignor: {
                ...consignorForm,
                street: form.street,
                city: form.city,
                country: form.country,
                zip: form.zip,
              },
            } as IConsignmentDetailPayload),
          )
        }}
        countriesOptions={countriesOptions}
        invalidFields={invalidFields}
        type="CONSIGNOR"
        isLast
      />
    </>
  )
  const getConsigneeInputs = () => (
    <>
      <FormRow title={t('declaration.eoriId').toUpperCase()}>
        <EoriSearchInput
          isDisabled={accessLevel === AccessLevel.VIEW}
          form={consigneeForm}
          setForm={(form: IConsignmentDetail) => {
            store.dispatch(
              addConsignmentDetails({
                carrier: carrierForm,
                consignee: form,
                consignor: consignorForm,
              } as IConsignmentDetailPayload),
            )
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.consignee')} withSecurity={declaration.isSecurityEnabled === true}>
        <ConsignmentSelect
          isDisabled={accessLevel === AccessLevel.VIEW}
          type="CONSIGNEE"
          selectForm={consigneeForm}
          traderRestrictions={traderRestrictions}
          setSelectForm={(form: IConsignmentDetail) => {
            store.dispatch(
              addConsignmentDetails({
                carrier: carrierForm,
                consignee: form,
                consignor: consignorForm,
              } as IConsignmentDetailPayload),
            )
          }}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      </FormRow>
      <FormRow
        isDisabled={accessLevel === AccessLevel.VIEW}
        title={`${t('declaration.consignee')} ${t('declaration.address').toLowerCase()}`}
        inputType="address"
        addressForm={{
          street: consigneeForm.street,
          city: consigneeForm.city,
          country: consigneeForm.country,
          zip: consigneeForm.zip,
        }}
        setAddressForm={(form: IAddressForm) => {
          store.dispatch(
            addConsignmentDetails({
              carrier: carrierForm,
              consignor: consignorForm,
              consignee: {
                ...consigneeForm,
                street: form.street,
                city: form.city,
                country: form.country,
                zip: form.zip,
              },
            } as IConsignmentDetailPayload),
          )
        }}
        countriesOptions={countriesOptions}
        invalidFields={invalidFields}
        type="CONSIGNEE"
        isLast
      />
    </>
  )

  const getCarrierInputs = () => (
    <>
      <div className="heading">
        <h3>
          {t('declaration.headers.carrier')}
        </h3>
      </div>
      <FormRow title={t('declaration.eoriId').toUpperCase()}>
        <EoriSearchInput
          isDisabled={accessLevel === AccessLevel.VIEW}
          form={carrierForm}
          setForm={(form: IConsignmentDetail) => {
            store.dispatch(
              addConsignmentDetails({
                carrier: form,
                consignee: consigneeForm,
                consignor: consignorForm,
              } as IConsignmentDetailPayload),
            )
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.carrier')}>
        <ConsignmentSelect
          isDisabled={accessLevel === AccessLevel.VIEW}
          type="CARRIER"
          selectForm={carrierForm}
          traderRestrictions={traderRestrictions}
          setSelectForm={(form: IConsignmentDetail) => {
            store.dispatch(
              addConsignmentDetails({
                carrier: form,
                consignee: consigneeForm,
                consignor: consignorForm,
              } as IConsignmentDetailPayload),
            )
          }}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      </FormRow>
      <FormRow
        isDisabled={accessLevel === AccessLevel.VIEW}
        title={`${t('declaration.carrier')} ${t('declaration.address').toLowerCase()}`}
        inputType="address"
        addressForm={{
          street: carrierForm.street,
          city: carrierForm.city,
          country: carrierForm.country,
          zip: carrierForm.zip,
        }}
        setAddressForm={(form: IAddressForm) => {
          store.dispatch(
            addConsignmentDetails({
              consignee: consigneeForm,
              consignor: consignorForm,
              carrier: {
                ...carrierForm,
                street: form.street,
                city: form.city,
                country: form.country,
                zip: form.zip,
              },
            } as IConsignmentDetailPayload),
          )
        }}
        countriesOptions={countriesOptions}
        invalidFields={invalidFields}
        type="CARRIER"
        isLast
      />
    </>
  )

  return (
    <div className="pt-4 px-4 col-12 col-xl-4 border-end border-bottom">
      <div className="heading">
        <h3>
          {t('declaration.headers.traders')}
        </h3>
        <div className="d-flex align-items-center text-black-50">
          <small>{t('declaration.goodsTradersEnabled')}</small>
          <Tooltip overlayClassName="tooltip-lg" overlay={<small>{setUnderGoodsTooltipText}</small>}>
            <button className="btn btn-link btn-gray-700 p-0" type="button">
              <i className="fal fa-info-circle ms-1" />
            </button>
          </Tooltip>
          <div className="form-check form-switch ms-3">
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              checked={declaration.isGoodsTradersEnabled === true}
              onChange={() => toggleTradersUnderGoods()}
            />
          </div>
        </div>
      </div>
      <small
        className={
          `${declaration.isGoodsTradersEnabled === true ? '' : 'd-none '} font-size-12 mb-3 font-italic`
        }
      >
        {t('declaration.goodsTradersEnabled')}
      </small>
      {
        declaration.isGoodsTradersEnabled === false
        && (
          <>
            {getConsignorInputs()}
            {getConsigneeInputs()}
          </>
        )
      }
      {
        securityEnabled
        && getCarrierInputs()
      }
    </div>
  )
}

export default ConsignmentDetailColumn
