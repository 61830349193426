import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { apiService, handleApiException, handleResponseData } from '../../services/api.service'

function Download() {
  const { search } = useLocation()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [signedUrl, setSignedUrl] = useState<string>('')

  const getSignedUrlForOldExport = (uuid: string) => axios
    .get(apiService.getFullApiUrl('/export/download'), { params: { uuid } })
    .then(handleResponseData)
    .catch(handleApiException)

  const baseUrl = process.env.REACT_APP_API_URL

  const getSignedUrlForNewExport = (uuid: string) => axios
    .get(`${baseUrl}/v2/export/download`, { params: { uuid } })
    .then(handleResponseData)
    .catch(handleApiException)

  const refreshDownloadLink = (uuidUrl: string, isNew: boolean) => {
    if (uuidUrl && !isNew) {
      getSignedUrlForOldExport(uuidUrl)
        .then((url) => setSignedUrl(url))
    } else if (uuidUrl && isNew) {
      getSignedUrlForNewExport(uuidUrl)
        .then((url) => setSignedUrl(url))
    }
  }

  useEffect(() => {
    if (!search || !search.includes('uuid=') || search.split('=').length !== 3 || !search.includes('isNew=')) {
      toast.error(t('common.error'))
      setLoading(false)
      return
    }

    const uuidUrl = search.split('=')[1]
    const newExport = search.split('&')[1]
    const uuid = uuidUrl.split('&')[0]

    if (uuid.length !== 36) {
      toast.error(t('common.error'))
      setLoading(false)
      return
    }
    if (newExport.split('=')[1] === 'true') {
      getSignedUrlForNewExport(uuid)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then((signedUrl) => {
          setSignedUrl(signedUrl)
          setTimeout(() => {
            window.location.replace(signedUrl)
          }, 50)
        })
        .finally(() => setLoading(false))
    } else {
      getSignedUrlForOldExport(uuid)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then((signedUrl) => {
          setSignedUrl(signedUrl)
          setTimeout(() => {
            window.location.replace(signedUrl)
          }, 50)
        })
        .finally(() => setLoading(false))
    }

    const intervalId = setInterval(() => {
      refreshDownloadLink(uuid, newExport.split('=')[1] === 'true')
    }, 580000) // ~10min

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div className="download__container">
      <div className="download__card">
        <h1 className="d-flex justify-content-center w-100 rounded-top mb-3 p-3 text-uppercase">Tardek T1</h1>
        <div className="d-flex flex-column mb-2">
          <p className="export-notification">
            {t('messages.download', { context: 'starting' })}
          </p>
          <div className="d-flex justify-content-center">
            {
              loading && (
                <i className="fa fa-spinner-third fa-spin me-2" />
              )
            }
            {
              !loading && signedUrl && (
                <a
                  className="download-link"
                  href={signedUrl}
                  target="_blank"
                  download="export.xlsx"
                  type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  rel="noreferrer"
                >
                  {t('messages.download', { context: 'manual' })}
                </a>
              )
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default Download
