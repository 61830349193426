import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserStatus } from '../services/user.service'

interface UserStatusProps {
  status: UserStatus
}

function Status(props: UserStatusProps) {
  const { status } = props
  const { t } = useTranslation()
  return (
    <span className={`${
      // eslint-disable-next-line no-nested-ternary
      (status === UserStatus.ACTIVE) ? 'text-success' : (
      // eslint-disable-next-line no-nested-ternary
        (status === UserStatus.BLOCKED) ? 'text-danger' : (
          (status === UserStatus.ARCHIVED || status === UserStatus.INACTIVE) ? 'text-muted' : ''
        )
      )
    }`}
    >
      {
        t('customers.status', { context: status })
      }
    </span>
  )
}

export default Status
