const IRREGULAR_WHITESPACES: Array<string> = [
  '\u000B', // Line Tabulation (\v) - <VT>
  '\u000C', // Form Feed (\f) - <FF>
  '\u00A0', // No-Break Space - <NBSP>
  '\u0085', // Next Line
  '\u1680', // Ogham Space Mark
  '\u180E', // Mongolian Vowel Separator - <MVS>
  '\ufeff', // Zero Width No-Break Space - <BOM>
  '\u2000', // En Quad
  '\u2001', // Em Quad
  '\u2002', // En Space - <ENSP>
  '\u2003', // Em Space - <EMSP>
  '\u2004', // Three-Per-Em
  '\u2005', // Four-Per-Em
  '\u2006', // Six-Per-Em
  '\u2007', // Figure Space
  '\u2008', // Punctuation Space - <PUNCSP>
  '\u2009', // Thin Space
  '\u200A', // Hair Space
  '\u200B', // Zero Width Space - <ZWSP>
  '\u2028', // Line Separator
  '\u2029', // Paragraph Separator
  '\u202F', // Narrow No-Break Space
  '\u205f', // Medium Mathematical Space
  '\u3000', // Ideographic Space
]

export const FORBIDDEN_CHARACTERS = [
  ...IRREGULAR_WHITESPACES,
]

export function purgeForbiddenCharacters(input: string) {
  return `${input}`.replaceAll(new RegExp(`[${FORBIDDEN_CHARACTERS.reduce((acc, current) => acc + current)}]`, 'g'), '')
}

export function removePrefixFromStartRecursively(value: string, replacePrefix: string = '='): string {
  if (value.startsWith(replacePrefix)) {
    return removePrefixFromStartRecursively(value.substring(replacePrefix.length))
  }
  return value
}

/**
 * Removes spaces, tabs, new lines and rare characters,
 * such as \v, \f and \r. Also, \UFEFF which is a unicode character 'ZERO WIDTH NO-BREAK SPACE'
 * @param value String to clean up
 * @return {value} Cleaned up string value
 */

export function purgeCharacters(value: string) {
  return value.replaceAll(/[\s\uFEFF\xA0]+$/g, '')
}
