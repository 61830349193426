import * as React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

export interface TablePaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageCount: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
}

export function TablePagination(props: TablePaginationProps) {
  const { t } = useTranslation()
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
  } = props

  const pageSizes = [50, 100, 150, 200, 250, 300]
  return (
    <div className="table-pagination d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center justify-content-end flex-grow-1 h-100 ">
        {
          !isMobile
          && (
            <div className="d-flex px-2" style={{ width: '10rem' }}>
              <Select
                className="w-100"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={false}
                menuPlacement="auto"
                defaultValue={{
                  value: pageSizes[0],
                  label: `${t('common.show')} ${pageSizes[0]}`,
                }}
                options={pageSizes.map((size) => ({
                  value: size,
                  label: `${t('common.show')} ${size}`,
                }))}
                value={{
                  value: pageSize,
                  label: `${t('common.show')} ${pageSize}`,
                }}
                onChange={(event) => {
                  setPageSize(event ? Number(event.value) : pageSizes[0])
                }}
              />
            </div>
          )
        }
        <button
          className="d-flex btn btn-outline-secondary my-2 h-75 px-3 align-items-center justify-content-center"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          type="button"
        >
          <i className="fa fa-md fa-arrow-alt-to-left" />
        </button>
        <button
          className="d-flex btn btn-outline-primary m-2 h-75 px-3 align-items-center justify-content-center"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          type="button"
        >
          <i className="fa fa-md fa-arrow-alt-left" />
        </button>
      </div>
      <div className="d-flex align-items-center justify-self-center h-100 ">
        <span>{`${t('common.page')}`}</span>
        <strong className="ms-1">
          {`${pageIndex + 1} ${t('common.pageSeparator')} ${pageOptions.length}`}
        </strong>
      </div>
      <div className="d-flex align-items-center flex-grow-1 h-100 ">
        <button
          className="d-flex btn btn-outline-primary m-2  h-75 px-3 align-items-center justify-content-center"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          type="button"
        >
          <i className="fa fa-md fa-arrow-alt-right" />
        </button>
        <button
          className="d-flex btn btn-outline-secondary my-2 h-75 px-3 align-items-center justify-content-center"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          type="button"
        >
          <i className="fa fa-md fa-arrow-alt-to-right" />
        </button>
        {
          !isMobile
          && (
            <span className="ms-2">
              <span className="me-2">{`${t('common.goToPage')}`}</span>
              <input
                disabled={!canNextPage && !canPreviousPage}
                type="number"
                max={pageOptions.length}
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '3.75rem' }}
              />
            </span>
          )
        }
      </div>
    </div>
  )
}
