import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useExpanded, usePagination, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import ClassifierService from '../../../services/classifier.service'
import { IClassifier, ISelectOption } from '../../../types/IClassifier'
import TableHeader from '../../Transits/Table/TableHeader'
import { TablePagination } from '../../Transits/Table/TablePagination'
import { TransitsAside } from '../../Transits/TransitsAside'
import { getAuditStatusOptions, getTableHeaders } from '../config'
import { useDeclarationAuditPage } from '../hooks'
import useDeclarationAudit from '../hooks/useDeclarationAudit'
import DecisionModal from './DecisionModal'
import TableBody from './TableBody'
import { NotificationContext } from '../../../context/NotificationContext'

function Table() {
  const {
    t,
    i18n,
  } = useTranslation()

  const {
    data,
    loading,
    filtersState,
    totalPages,
    statusFilter,
    setStatusFilter,
    setCurrentPageSize,
    setCurrentPageIndex,
    refreshPage,
  } = useDeclarationAuditPage({
    pageIndex: 0,
    pageSize: 50,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    gotoPage: openPage,
  })

  const {
    decide,
    isDecisionModalVisibile,
    toggleDecisionModalVisibility,
    isDecisionLoading,
    setReasonText,
    setAuditStatus,
    setDecisionItem,
    isAsideOpen,
    changeAside,
    editDeclaration,
    declarationId,
    getDeclaration,
    openDecisionModal,
    decisionItem,
  } = useDeclarationAudit(refreshPage)
  const columns = useMemo(() => getTableHeaders(t), [i18n.language])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // PAGINATION
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
    },
  } = useTable(
    {
      /* @ts-ignore */
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    useExpanded,
    useSticky,
    usePagination,
  )

  const [countries, setCountries] = useState<Array<IClassifier>>([])
  const [customsOffices, setCustomsOffices] = useState<Array<IClassifier>>([])
  const [packageTypes, setPackageTypes] = useState<Array<IClassifier>>([])
  const [documentTypes, setDocumentTypes] = useState<Array<IClassifier>>([])
  const [previousDocumentTypes, setPreviousDocumentTypes] = useState<Array<IClassifier>>([])
  const [procedureTypes, setProcedureTypes] = useState<Array<IClassifier>>([])

  const { contextHeaderNotificationMessage } = useContext(NotificationContext)

  useEffect(() => {
    ClassifierService.getClassifier('CUSTOMS_OFFICES')
      .then((classifiers: IClassifier[]) => {
        setCustomsOffices(classifiers)
      })
    ClassifierService.getClassifier('COUNTRIES')
      .then((classifiers: IClassifier[]) => {
        setCountries(classifiers)
      })
    ClassifierService.getClassifier('PACKAGE_TYPES')
      .then((classifiers: IClassifier[]) => {
        setPackageTypes(classifiers)
      })
    ClassifierService.getClassifier('DOCUMENT_TYPES')
      .then((classifiers: IClassifier[]) => {
        setDocumentTypes(classifiers)
      })
    ClassifierService.getClassifier('PREVIOUS_DOCUMENT_TYPES')
      .then((classifiers: IClassifier[]) => {
        setPreviousDocumentTypes(classifiers)
      })
    ClassifierService.getClassifier('PROCEDURE_TYPES')
      .then((classifiers: IClassifier[]) => {
        setProcedureTypes(classifiers)
      })
  }, [])

  function openPage(index: number) {
    gotoPage(index)
  }

  useEffect(() => {
    setCurrentPageIndex(pageIndex)
  }, [pageIndex])
  useEffect(() => {
    setCurrentPageSize(pageSize)
  }, [pageSize])

  const [filters, setFilters] = filtersState
  const statusOptions: ISelectOption[] = getAuditStatusOptions()

  return (
    <>
      <div
        className={`table__container table-responsive declaration-table__container ${isMobile ? 'not-in-mobile' : ''} ${contextHeaderNotificationMessage !== '' ? 'notification--open' : ''}`}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <table {...getTableProps()} className="table sticky table-hover fixed-columns mb-0">
          <TableHeader
            /* @ts-ignore */
            headerGroups={headerGroups}
            selectFilterOptions={statusOptions}
            filterState={{
              filters,
              setFilters,
            }}
            selectFilterState={{
              selectFilter: statusFilter,
              setSelectFilter: setStatusFilter,
            }}
          />
          <TableBody
            isLoading={loading}
            /* @ts-ignore */
            page={page}
            prepareRow={prepareRow}
            getTableBodyProps={getTableBodyProps}
            refreshPage={refreshPage}
            getDeclaration={getDeclaration}
            openDecisionModal={openDecisionModal}
          />
          <LoadingBackdrop loading={loading} />
        </table>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
      <DecisionModal
        title={t('buttons.decideAuditHeader')}
        isVisible={isDecisionModalVisibile}
        toggleVisibility={toggleDecisionModalVisibility}
        isLoading={isDecisionLoading}
        onChange={setReasonText}
        onSelect={setAuditStatus}
        onConfirmation={() => decide()}
        onRejection={() => {
          toggleDecisionModalVisibility()
          setDecisionItem(null)
        }}
      />
      <div
        role="presentation"
        className={`${isAsideOpen ? 'active' : ''} overlay`}
        onClick={() => {
          if (isAsideOpen) {
            changeAside(false)
          }
        }}
      />
      {
        declarationId && (
          <TransitsAside
            isOpen={isAsideOpen}
            isViewMode
            isLoading={isDecisionLoading}
            asideAction={changeAside}
            parentPage="AUDIT"
            editDeclaration={editDeclaration}
            submitAudit={() => decide()}
            declarationAuditReason={setReasonText}
            asideDeclarationId={declarationId}
            declarationAuditStatus={setAuditStatus}
            customsOffices={customsOffices}
            countries={countries}
            packageTypes={packageTypes}
            documentTypes={documentTypes}
            previousDocumentTypes={previousDocumentTypes}
            procedureTypes={procedureTypes}
            originalRow={decisionItem != null ? decisionItem : undefined}
            triggerParentPageRefresh={refreshPage}
          />
        )
      }
    </>
  )
}

export default Table
