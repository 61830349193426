import { NotesTransitOperationInfoRequest, UpdateReviewRequest } from '../../../common/models'
import { UserFeedbackRequest } from '../../../common/models/user-feedback-request'

export function toNotesOrFeedbackTransitOperationInfoRequest(
  notes: string,
): NotesTransitOperationInfoRequest {
  return {
    notes,
  }
}

export function toFeedbackRequest(
  feedback: string,
  isDeclarationDeclined: boolean,
  isPdfIncluded: boolean,
  emails: Array<string>,
  blindCopyEmail: string,
): UserFeedbackRequest {
  return {
    feedback,
    isDeclarationDeclined,
    isPdfIncluded,
    emails,
    blindCopyEmail,
  }
}

export function toUpdateReviewRequest(
  isReviewed: boolean,
): UpdateReviewRequest {
  return {
    isReviewed,
  }
}
