import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import PrivacyService, { PrivacyPolicy } from 'routes/Settings/service/privacy.service'
import { getSelectCountryFilter } from '../../components/react-select/CountryFunctions'
import { usePasswordValid } from '../../hooks/usePasswordValid'
import { apiService, handleResponseData } from '../../services/api.service'
import { IClassifier, ISelectOption } from '../../types/IClassifier'
import userService from '../Customer/services/user.service'
import PrivacyModal from './PrivacyModal'
import { NotificationContext } from '../../context/NotificationContext'

interface RegistrationForm {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  registryCode: string;
  companyName: string;
  companyEmail: string;
  eoriNumber: string;
  city: string;
  street: string;
  zipCode: string;
  country: string;
  hasAcceptedPrivacyPolicy: boolean;
}

function Register() {
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(1)
  const [loading, setLoading] = useState(false)
  const [isAcceptanceModalOpen, setIsAcceptanceModalOpen] = useState<boolean>(false)
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>({
    id: 0,
    content: '',
  })
  const [searchString, setSearchString] = useState<string>('')
  const [registrationCountries, setRegistrationCountries] = useState<Array<ISelectOption>>([])
  const [registrationData, setRegistrationData] = useState<RegistrationForm>({
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    firstname: '',
    lastname: '',
    registryCode: '',
    companyName: '',
    companyEmail: '',
    eoriNumber: '',
    city: '',
    street: '',
    zipCode: '',
    country: '',
    hasAcceptedPrivacyPolicy: false,
  })

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  useEffect(() => {
    PrivacyService.getPrivacyTerms(localStorage.getItem('t1_locale') || 'en').then((response) => {
      setPrivacyPolicy(response)
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }, [])

  const searchForEori = (inputValue: string) => new Promise((resolve) => {
    if (loading) {
      return
    }
    setLoading(true)
    axios.get(
      apiService.getFullApiUrl('/eori'),
      {
        params: {
          eoriCode: inputValue.replace(' ', ''),
        },
      },
    )
      .then((response) => {
        setRegistrationData({
          ...registrationData,
          city: response.data.data.city,
          companyName: response.data.data.name,
          country: response.data.data.country,
          street: response.data.data.street,
          zipCode: response.data.data.zip,
          eoriNumber: inputValue.replace(' ', ''),
        })
        resolve(null)
      })
      .catch(() => {
        setLoading(false)
        toast.error(t('messages.eoriSearchFailed'))
      })
      .finally(() => {
        setLoading(false)
      })
  })

  const [
    validLength,
    match,
    isValid,
  ] = usePasswordValid({
    password: registrationData.password,
    confirmPassword: registrationData.confirmPassword,
    requiredLength: 7,
  })
  useEffect(() => {
    axios.get(apiService.getFullApiUrl('/classifier/countries'), {
      params: {
        classifierGroup: 'COUNTRIES',
      },
    })
      .then(handleResponseData)
      .then((countriesResponse: IClassifier[]) => {
        setRegistrationCountries(countriesResponse.map((country) => ({
          value: country.code,
          label: `${country.nameEn} (${country.codeLabel})`,
        })))
      })
  }, [])
  const back = () => {
    if (step === 1) {
      return
    }
    setStep(step - 1)
  }
  const isStepTwoValid = () => {
    const {
      registryCode,
      companyName,
      companyEmail,
      zipCode,
      country,
      street,
      city,
      hasAcceptedPrivacyPolicy,
    } = registrationData
    // eslint-disable-next-line max-len
    return !(registryCode !== '' && companyName !== '' && zipCode !== '' && country !== '' && street !== '' && city !== '' && !(companyEmail !== '' && companyEmail.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null) && hasAcceptedPrivacyPolicy)
  }

  const isStepOneValid = () => {
    const {
      email,
      password,
      confirmPassword,
      firstname,
      lastname,
    } = registrationData
    // eslint-disable-next-line max-len
    return !(email === '' || password === '' || confirmPassword === '' || (password !== confirmPassword) || firstname === '' || lastname === '')
  }
  const validateEmail = () => (registrationData.email.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null)

  const next = () => {
    if (step === 2) {
      return
    }
    setStep(step + 1)
  }

  const registerUser = () => {
    setLoading(true)
    userService.registerAccount({ ...registrationData })
      .then(() => {
        toast.success(t('messages.inviteSent'))
        setTimeout(() => {
          setLoading(false)
          setStep(3)
        }, 500)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
          if (error.data === 'Customer with the same registry code already in use!') {
            toast.error(t('messages.customerSupport'))
            setStep(1)
          } else if (error.data === 'Passwords do not match') {
            toast.error(t('messages.passwordsMatchError'))
            setStep(1)
          } else if (error.data === 'Email already in use!') {
            toast.error(t('messages.emailInUse'))
            setStep(1)
          }
        }, 500)
      })
  }

  const firstStep = () => (
    <>
      <h3 className="d-flex justify-content-center w-100 rounded-top mb-3 text-uppercase">{t('common.userInformation')}</h3>
      <div className="row">
        <div className="input-group input-group-lg mb-2">
          <span className="input-group-text py-3" id="basic-addon1">
            <i className="fal fa-envelope fa-md" />
          </span>
          <input
            id="email"
            type="email"
            className="form-control px-1 py-3"
            placeholder={t('common.email')}
            autoComplete="email"
            pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
            required
            value={registrationData.email}
            onChange={(event) => {
              setRegistrationData({
                ...registrationData,
                email: event.target.value,
              })
            }}
          />
        </div>
        <div className="input-group input-group-lg mb-2">
          <span className="input-group-text py-3" id="basic-addon1">
            <i className="fal fa-phone-alt fa-md" />
          </span>
          <input
            id="phone"
            type="text"
            className="form-control px-1 py-3"
            autoComplete="phone"
            placeholder={t('common.phone')}
            required
            value={registrationData.phone}
            onChange={(event) => {
              setRegistrationData({
                ...registrationData,
                phone: event.target.value,
              })
            }}
          />
        </div>

        <div className="col-6">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-user fa-md" />
            </span>
            <input
              id="firstname"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('user.firstName')}
              required
              value={registrationData.firstname}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  firstname: event.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-user fa-md" />
            </span>
            <input
              id="lastname"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('user.lastName')}
              required
              value={registrationData.lastname}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  lastname: event.target.value,
                })
              }}
            />
          </div>
        </div>

        <div className="input-group input-group-lg mb-2">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-lock-alt fa-md" />
            </span>
            <input
              id="password"
              type="password"
              className="form-control px-1 py-3"
              autoComplete="new-password"
              placeholder={t('common.password')}
              required
              value={registrationData.password}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  password: event.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="input-group input-group-lg mb-2">
          <span className="input-group-text py-3" id="basic-addon1">
            <i className="fal fa-lock-alt fa-md" />
          </span>
          <input
            id="confirmPassword"
            type="password"
            className="form-control px-1 py-3"
            autoComplete="new-password"
            placeholder={t('common.confirmPassword')}
            required
            value={registrationData.confirmPassword}
            onChange={(event) => {
              setRegistrationData({
                ...registrationData,
                confirmPassword: event.target.value,
              })
            }}
          />
        </div>
      </div>
      <button
        onClick={() => {
          next()
        }}
        className="btn btn-primary mb-3 p-3 w-100"
        type="button"
        disabled={loading || !isStepOneValid() || !match || isValid || !validLength || validateEmail()}
      >
        {t('buttons.next')}
      </button>
      {!match && (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span className="text-danger">{t('messages.passwordsMatchError')}</span>
        </div>
      )}
      {isValid && registrationData.password !== '' && (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span className="text-danger">{t('messages.passwordsLetterError')}</span>
        </div>
      )}
      {!validLength && (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span className="text-danger">{t('messages.passwordsLengthError')}</span>
        </div>
      )}
      {registrationData.email !== '' && validateEmail() && (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span className="text-danger">{t('messages.emailInvalid')}</span>
        </div>
      )}
    </>
  )

  const thirdStep = () => (
    <>
      <h3 className="d-flex justify-content-center w-100 rounded-top mb-3 text-uppercase">{t('common.registerSuccess')}</h3>
      <div className="row">
        <h3 className="text-center mt-3">{t('messages.accountRegisteredFirstParagraph')}</h3>
        <h3 className="text-center mt-3">{t('messages.checkEmail')}</h3>
      </div>
    </>
  )
  const secondStep = () => (
    <>
      <h3 className="d-flex justify-content-center w-100 rounded-top mb-3 text-uppercase">{t('common.companyInformation')}</h3>
      <div className="row">
        <div className="input-group input-group-lg mb-2">
          <span className="input-group-text py-3" id="basic-addon1">
            <i className="fal fa-pencil fa-md" />
          </span>
          <input
            id="eoriNumber"
            type="text"
            className="form-control px-1 py-3"
            placeholder={t('declaration.eoriId')}
            required
            value={registrationData.eoriNumber}
            onChange={(event) => {
              if (event.target.value.length >= 10) {
                setSearchString(event.target.value)
              }
              setRegistrationData({
                ...registrationData,
                eoriNumber: event.target.value,
              })
            }}
          />
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-light h-100 border"
              onClick={() => {
                searchForEori(searchString)
              }}
            >
              <i className="fal fa-search px-2" />
            </button>
          </div>
          <div className="w-100">
            <small className="text-muted mb-4 mt-1 text-center w-100">{t('messages.eoriHelper')}</small>
          </div>
        </div>
        <div className="input-group input-group-lg mb-2">
          <span className="input-group-text py-3" id="basic-addon1">
            <i className="fal fa-envelope fa-md" />
          </span>
          <input
            id="companyEmail"
            type="text"
            className="form-control px-1 py-3"
            placeholder={t('customers.companyContactEmail')}
            pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
            required
            value={registrationData.companyEmail}
            onChange={(event) => {
              setRegistrationData({
                ...registrationData,
                companyEmail: event.target.value,
              })
            }}
          />
        </div>
        <div className="col-6">
          <div className="input-group input-group-lg mb-2 ">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-globe fa-md" />
            </span>
            <input
              id="companyName"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('customers.companyName')}
              required
              value={registrationData.companyName}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  companyName: event.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-building fa-md" />
            </span>
            <input
              id="registryCode"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('customers.registryCode')}
              required
              value={registrationData.registryCode}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  registryCode: event.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-city fa-md" />
            </span>
            <input
              id="street"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('common.street')}
              required
              value={registrationData.street}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  street: event.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-city fa-md" />
            </span>
            <input
              id="street"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('common.city')}
              required
              value={registrationData.city}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  city: event.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-6 registration__select">
          <Select
            classNamePrefix="select"
            options={registrationCountries}
            value={registrationData.country != null
              ? registrationCountries.find((option) => option.value === registrationData.country) : null}
            filterOption={getSelectCountryFilter}
            onChange={(option: ISelectOption | null) => {
              if (option !== null && option.value) {
                setRegistrationData({
                  ...registrationData,
                  country: option.value,
                })
              }
            }}
            placeholder={t('common.chooseCountry')}
          />
        </div>
        <div className="col-6">
          <div className="input-group input-group-lg mb-2">
            <span className="input-group-text py-3" id="basic-addon1">
              <i className="fal fa-city fa-md" />
            </span>
            <input
              id="zipCode"
              type="text"
              className="form-control px-1 py-3"
              placeholder={t('common.zip')}
              required
              value={registrationData.zipCode}
              onChange={(event) => {
                setRegistrationData({
                  ...registrationData,
                  zipCode: event.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="form-row-special-input">
          <div className="form-check d-flex align-items-center me-3">
            <input
              className="form-check-input"
              type="radio"
              name="hasAccepted"
              id="hasAccepted"
              value="true"
              checked={registrationData.hasAcceptedPrivacyPolicy}
              onClick={() => {
                setRegistrationData({ ...registrationData, hasAcceptedPrivacyPolicy: !registrationData.hasAcceptedPrivacyPolicy })
              }}
            />
            <span
              className="form-check-label text-nowrap ps-1"
            >
              {`${t('user.privacyPolicy')}`}
              <span
                role="presentation"
                className="text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsAcceptanceModalOpen(true)
                }}
              >
                {`${t('user.privacy')}`}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div className="input-group input-group mb-2">
            <button
              onClick={() => {
                back()
              }}
              className="btn btn-primary mb-3 p-3 w-100"
              type="button"
              disabled={loading}
            >
              {t('buttons.previous')}
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="input-group input-group mb-2">
            <button
              onClick={() => {
                registerUser()
              }}
              className="btn btn-primary mb-3 p-3 w-100"
              type="button"
              disabled={loading || (isStepTwoValid())}
            >
              {
                loading && loadingSpinner()
              }
              {
                !loading && (<span>{t('buttons.register')}</span>)
              }
            </button>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line max-len */}
      {registrationData.companyEmail !== '' && registrationData.companyEmail.match('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$') === null && (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span className="text-danger">{t('messages.emailInvalid')}</span>
        </div>
      )}
    </>
  )

  const registerForm = () => (
    <form>
      {step === 1 && (
        firstStep()
      )}
      {step === 2 && (
        secondStep()
      )}
      {step === 3 && (
        thirdStep()
      )}
    </form>
  )

  const { contextHeaderNotificationMessage } = useContext(NotificationContext)

  return (
    <>
      <div
        className="registration__container"
        style={{ height: contextHeaderNotificationMessage !== '' ? 'calc(100vh - 60px)' : '100vh' }}
      >
        <div className="registration__card">
          <h1 className="d-flex justify-content-center rounded-top w-100 p-3 text-uppercase">Tardek T1</h1>
          {registerForm()}
        </div>
      </div>
      <PrivacyModal isModalOpen={isAcceptanceModalOpen} toggleModal={setIsAcceptanceModalOpen} privacyTerms={privacyPolicy.content} />
    </>
  )
}

export default Register
