import React, { ChangeEvent, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import axios, { AxiosError } from 'axios'
import { getBaseApiUrl, handleResponseData } from 'services/api.service'
import { ISelectOption } from 'types/IClassifier'
import LoadingSpinner from 'components/LoadingSpinner'

interface FinlandCustomsRequestReplyProps {
  isVisible: boolean,
  toggleVisibility: () => void,
  transitOperationId: number | null
}

interface FinlandCustomsReplyFormProps {
  response: boolean | null,
  description: string
}

function FinlandCustomsRequestReplyModal(props: Readonly<FinlandCustomsRequestReplyProps>) {
  const { isVisible, toggleVisibility, transitOperationId } = props
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [form, setForm] = useState<FinlandCustomsReplyFormProps>({
    response: null,
    description: '',
  })

  const responseSelectOptions: ISelectOption[] = [
    {
      value: true,
      label: 'I Agree with Decision',
    },
    {
      value: false,
      label: 'I Disagree with Decision',
    },
  ]

  const submitForm = () => {
    if (form.response === null) return null
    setLoading((prevState) => !prevState)

    return new Promise((resolve, reject) => {
      axios.post(
        getBaseApiUrl('/v2/transit-operation/customs/response'),
        {
          id: transitOperationId,
          isAccepted: form.response,
          notificationDescription: form.description,
        },
      )
        .then(handleResponseData)
        .then((value) => {
          resolve(value)
          setLoading((prevState) => !prevState)
        })
        .catch((error: AxiosError) => {
          reject(error)
          setLoading((prevState) => !prevState)
        })
    })
  }

  return (
    <Modal
      show={isVisible}
      dialogClassName="finland-contact-modal modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="backdrop-with-modal"
      onHide={() => toggleVisibility()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{t('declaration.p5.finlandCustomsRequestResponse')}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-self-center w-100">
          <Select
            placeholder={t('declaration.p5.agreeWithCustoms')}
            options={responseSelectOptions}
            className="select w-100 mb-4"
            classNamePrefix="select"
            menuPlacement="auto"
            value={responseSelectOptions.find((option) => option.value === form.response) ?? null}
            onChange={(option: ISelectOption | null) => {
              setForm({
                ...form,
                response: option?.value,
              })
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-column align-self-center w-100 mb-1">
          <textarea
            placeholder={t('declaration.p5.notificationDescription')}
            className="w-100 form-control mb-2"
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setForm({
              ...form,
              description: e.target.value,
            })}
          />
        </div>
        <div className="d-flex align-content-end justify-content-end mt-5">
          <button
            type="button"
            className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
            disabled={!form.response}
            onClick={submitForm}
          >
            {
              loading && <LoadingSpinner />
            }
            {
              !loading && (<span>{t('buttons.submit')}</span>)
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FinlandCustomsRequestReplyModal
