import { useEffect } from 'react'
import { UseDeclarationFormReturn } from '../../form'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import useHouseConsignmentApi from './api'
import { parseHouseConsignmentResponse, toCreateHouseConsignmentRequest, toUpdateHouseConsignmentRequest } from './mapper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { CreateHouseConsignmentRequest, HouseConsignmentResponse, UpdateHouseConsignmentRequest } from '../../../common/models'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'

function useHouseConsignment(form: UseDeclarationFormReturn) {
  const {
    trigger,
    getValues,
    setValue,
    formState: {
      isValid,
      isSubmitting,
    },
    reset,
  } = form
  const consignmentId: number | null = getValues('consignmentId')

  const {
    fetchHouseConsignments,
    postHouseConsignment,
    putHouseConsignment,
    putHouseConsignmentDraft,
  } = useHouseConsignmentApi(consignmentId, isSubmitting)

  const populateFormHouseConsignments = () => {
    if (fetchHouseConsignments.isLoading || isSubmitting) {
      return
    }

    const response = fetchHouseConsignments.data?.map(parseHouseConsignmentResponse)?.sort(sortBySequenceNumber) ?? []
    const formClone: DeclarationForm = structuredClone(getValues())

    reset({
      ...formClone,
      houseConsignment: formClone.houseConsignment.map((formItem) => ({
        ...formItem, // This is needed so that transitional fields are not written as invalid undefined
        ...response.find((item) => (item.id === formItem.id) || (item.sequenceNumber === formItem.sequenceNumber)),
      })),
    })
  }
  useEffect(() => {
    populateFormHouseConsignments()
  }, [fetchHouseConsignments.data])

  const getHouseConsignment = async () => fetchHouseConsignments.data

  function refreshSavedIds(houseConsignments: HouseConsignmentType[], response: HouseConsignmentResponse[]) {
    houseConsignments.forEach((item, index) => {
      const savedItem = response.find((responseItem) => responseItem.sequenceNumber === item.sequenceNumber)
      if (savedItem) setValue(`houseConsignment.${index}.id`, savedItem.id)
    })
  }

  const createHouseConsignments = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return

    const savedConsignmentId = getValues('consignmentId')
    const grossMass = getValues('grossMass')
    const houseConsignments = getValues('houseConsignment')
    const houseConsignmentRequests = houseConsignments
      .map((item, index) => toCreateHouseConsignmentRequest(item, savedConsignmentId, grossMass, index))
    const response = await postHouseConsignment.mutateAsync(houseConsignmentRequests)

    refreshSavedIds(houseConsignments, response)
  }

  const updateHouseConsignments = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return
    const savedConsignmentId = getValues('consignmentId')
    if (savedConsignmentId === null) return

    const houseConsignments = getValues('houseConsignment')
    const updateRequests: UpdateHouseConsignmentRequest[] = []
    const createRequests: CreateHouseConsignmentRequest[] = []
    const grossMass = getValues('grossMass')
    houseConsignments.forEach((houseConsignment, index) => {
      if (houseConsignment.id === null) {
        createRequests.push(toCreateHouseConsignmentRequest(houseConsignment, savedConsignmentId, grossMass, index))
      } else {
        updateRequests.push(toUpdateHouseConsignmentRequest(houseConsignment, grossMass, index))
      }
    })
    const responses: HouseConsignmentResponse[] = []
    responses.push(...await postHouseConsignment.mutateAsync(createRequests))

    if (isDraft) {
      responses.push(...await putHouseConsignmentDraft.mutateAsync(updateRequests))
    } else {
      responses.push(...await putHouseConsignment.mutateAsync(updateRequests))
    }

    refreshSavedIds(houseConsignments, responses)
  }

  return {
    getHouseConsignment,
    createHouseConsignments,
    updateHouseConsignments,
  }
}

export default useHouseConsignment
