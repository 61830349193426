import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../apiConfig'
import { MutateRequest } from '../request-type'

const {
  baseUrl,
} = DeclarationApi.paths.guaranteeCalculation

function useGuaranteeCalculationApi() {
  const fetchTaxesCalculation = useMutation({
    mutationFn: async (request: MutateRequest<boolean>) => (await axios.get<Blob>(`${baseUrl}/${request.id}`, { params: { xlsx: request.data }, responseType: 'blob' })),
  })

  return {
    fetchTaxesCalculation,
  }
}

export default useGuaranteeCalculationApi
