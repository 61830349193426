import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import DeclarationApi from '../apiConfig'

const {
  baseUrl,
} = DeclarationApi.paths.transportEquipment

function useTransportEquipmentApi() {
  const deleteTransportEquipment = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
  })

  return {
    deleteTransportEquipment,
  }
}

export default useTransportEquipmentApi
