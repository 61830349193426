import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope } from 'types/DeclarationP5'
import { Link } from 'react-router-dom'
import { useWatch } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import useCodelist from '../../../common/hooks/useCodelist'

const scope = 'placeOfUnloading'
type PlaceOfUnloadingScope = NestedFieldScope<typeof scope>

function PlaceOfUnloading() {
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-unloading-place"
        headingLabel={t('declaration.p5.placeOfUnLoading')}
      >
        <FormField labelKey="declaration.p5.nationality">
          <FormSelect
            <PlaceOfUnloadingScope>
            field="placeOfUnloading.country"
            labelKey="declaration.p5.nationality"
            options={countriesOptions}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.placeOfUnloadingLocation">
          <Input
            <PlaceOfUnloadingScope>
            field="placeOfUnloading.location"
            labelKey="declaration.p5.placeOfUnloadingLocation"
            type="text"
            maxLength={35}
            placeholder={t('declaration.p5.placeOfUnloadingLocation')}
          />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default PlaceOfUnloading
