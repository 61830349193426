import axios from 'axios'
import { CurrentCustomer, Customer, CustomerRequirements } from 'types/Customer'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'

function postCustomer(customer: Customer, isExternalCustomer: boolean) {
  return axios
    .post(apiService.getFullApiUrl(`${isExternalCustomer ? '/customer/external-customer' : '/customer'}`), {
      ...customer,
      external: isExternalCustomer,
    })
    .then<Customer>(handleResponseData)
    .catch(handleApiException)
}

function putCustomer(customer: Customer, isExternalCustomer: boolean) {
  return axios
    .put(apiService.getFullApiUrl(`${isExternalCustomer ? '/customer/external-customer' : '/customer'}`), {
      ...customer,
      external: isExternalCustomer,
    })
    .then<Customer>(handleResponseData)
    .catch(handleApiException)
}

function putCurrentCustomer(customer: CurrentCustomer) {
  return axios
    .put(apiService.getFullApiUrl('/customer/current'), {
      ...customer,
    })
    .then<CurrentCustomer>(handleResponseData)
    .catch(handleApiException)
}

function getCustomer(customerId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/customer/${customerId}`))
    .then<Customer>(handleResponseData)
    .catch(handleApiException)
}

function getCurrentCustomerRequirements() {
  return axios
    .get(apiService.getFullApiUrl('/customer/requirements'))
    .then<CustomerRequirements>(handleResponseData)
    .catch(handleApiException)
}

function createClientApiTokenForCurrentCustomer() {
  return axios
    .post(apiService.getFullApiUrl('/customer/generate-token'))
    .then<string>(handleResponseData)
    .catch(handleApiException)
}

const CustomerService = {
  getCustomer,
  postCustomer,
  putCustomer,
  getCurrentCustomerRequirements,
  putCurrentCustomer,
  createClientApiTokenForCurrentCustomer,
}

export default CustomerService
