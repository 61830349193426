import { DeclarationForm } from '../schemas/declarationFormSchema'
import { ConsignmentItem } from '../schemas/consignmentItemSchema'
import { removePrefix } from '../../../common/utils/classifier-util'
import { CL010_CL553_EU_COUNTRY_CODES } from '../../../common/utils/ncts-constants'
import { hasText } from '../../../common/utils/common-util'

const R0909Validator = {
  areDeclarationTypeAndCustomsOfficeOfDepartureCountryCorrect: (formData: DeclarationForm, consignmentItem: ConsignmentItem): boolean => {
    const departureOfficeCountry = removePrefix(formData.departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)
    const destinationOfficeCountry = removePrefix(formData.destinationCustomsOffice, 'DESTINATION_OFFICE_', 2)

    if (departureOfficeCountry === 'SM') {
      if (destinationOfficeCountry === 'IT') {
        return false // formData.declarationType === 'T2SM'
      }
      if (CL010_CL553_EU_COUNTRY_CODES.includes(departureOfficeCountry)) {
        return formData.declarationType === 'T2' || (hasText(consignmentItem.declarationType) && consignmentItem.declarationType === 'T2')
      }
    }

    return true
  },
}

export default R0909Validator
