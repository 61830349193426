import { RuleResult } from 'types/DeclarationP5'

const B1805Validator = {
  validateNetMassRequirement: (reducedDatasetIndicator: 'true' | 'false' | null): RuleResult => {
    if (reducedDatasetIndicator && reducedDatasetIndicator === 'true') {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default B1805Validator
