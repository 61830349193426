import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../apiConfig'
import { TransitOperationDirectoRequest } from '../../../common/models/transit-operation-directo-request'
import { DirectoEventResponse } from '../../../common/models/directo-event-response'
import { MutateRequest } from '../request-type'

const {
  baseUrl,
  queryKeys: {
    getDirectoEvent,
  },
} = DeclarationApi.paths.directo

function useDirectoApi(transitOperationId: number | null, isAdmin: boolean, directoId: number | null) {
  const queryClient = useQueryClient()

  const postDirecto = useMutation({
    mutationFn: async (data: TransitOperationDirectoRequest) => (await axios
      .post<DirectoEventResponse>(baseUrl, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getDirectoEvent(transitOperationId) }),
  })

  const updateDirecto = useMutation({
    mutationFn: async (request: MutateRequest<TransitOperationDirectoRequest>) => (await axios
      .put<DirectoEventResponse>(`${baseUrl}/${request.id}`, request.data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: getDirectoEvent(transitOperationId) }),
  })

  const fetchDirectoEvent = useQuery({
    enabled: !!transitOperationId && isAdmin && !!directoId,
    queryFn: async ({ signal }) => (await axios
      .get<DirectoEventResponse>(`${baseUrl}/${transitOperationId}`, { signal })).data,
    queryKey: getDirectoEvent(transitOperationId),
    staleTime: Infinity, // never stale
    retry: false,
  })

  return {
    postDirecto,
    fetchDirectoEvent,
    updateDirecto,
  }
}

export default useDirectoApi
