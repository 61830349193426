/* tslint:disable */
/* eslint-disable */
/**
 * Tardek API Endpoints
 * This Is a documentation for T1 Tardek API Endpoints. <br />
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@bitweb.ee
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressRequest } from './address-request';
import { CreateOrUpdateContactPersonRequest } from './create-or-update-contact-person-request';
/**
 *
 * @export
 * @interface ConsignmentDetailRequest
 */
export interface ConsignmentDetailRequest {
    /**
     * Consignment Detail ID
     * @type {number}
     * @memberof ConsignmentDetailRequest
     */
    id?: number;
    /**
     * Consignment Detail Name
     * @type {string}
     * @memberof ConsignmentDetailRequest
     */
    name: string;
    /**
     * Consignment Detail Type
     * @type {string}
     * @memberof ConsignmentDetailRequest
     */
    target: ConsignmentDetailRequestTargetEnum;
    /**
     * Consignment Detail Identification number
     * @type {string}
     * @memberof ConsignmentDetailRequest
     */
    identificationNumber: string;
    /**
     * Transit Operation ID
     * @type {number}
     * @memberof ConsignmentDetailRequest
     */
    transitOperationId: number;
    /**
     *
     * @type {AddressRequest}
     * @memberof ConsignmentDetailRequest
     */
    address?: AddressRequest | null;
    /**
     *
     * @type {CreateOrUpdateContactPersonRequest}
     * @memberof ConsignmentDetailRequest
     */
    contactPerson?: CreateOrUpdateContactPersonRequest | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsignmentDetailRequestTargetEnum {
    CONSIGNEE = 'CONSIGNEE',
    CONSIGNOR = 'CONSIGNOR',
    CARRIER = 'CARRIER'
}

