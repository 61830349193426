import { IGoodsItem } from '../../../../types/IGoodsItem'
import ConsignmentDetailsService from '../../services/consignment-detail.service'
import { IConsignmentDetail } from '../../../../types/IConsignmentDetail'

function useGetGoodsConsignmentDetails() {
  const appendConsignorToGoodsItem = (goodsItem: IGoodsItem, consignmentDetails: Array<IConsignmentDetail>) => {
    if (goodsItem.consignorId != null) {
      const consignor = consignmentDetails.find((item) => item.id === goodsItem.consignorId)
      // eslint-disable-next-line no-param-reassign
      goodsItem.consignor = consignor !== undefined ? {
        id: consignor.id,
        target: 'CONSIGNOR',
        name: consignor.name ? consignor.name.substr(0, 35) : consignor.name,
        street: consignor.street,
        city: consignor.city,
        country: consignor.country,
        zip: consignor.zip,
        eori: consignor.eori,
      } : {
        id: null,
        target: 'CONSIGNOR',
        name: '',
        street: '',
        city: '',
        country: '',
        zip: '',
        eori: '',
      }
    }
  }

  const appendConsigneeToGoodsItem = (goodsItem: IGoodsItem, consignmentDetails: Array<IConsignmentDetail>) => {
    if (goodsItem.consigneeId != null) {
      const consignee = consignmentDetails.find((item) => item.id === goodsItem.consigneeId)
      // eslint-disable-next-line no-param-reassign
      goodsItem.consignee = consignee !== undefined ? {
        id: consignee.id,
        target: 'CONSIGNEE',
        name: consignee.name ? consignee.name.substr(0, 35) : consignee.name,
        street: consignee.street,
        city: consignee.city,
        country: consignee.country,
        zip: consignee.zip,
        eori: consignee.eori,
      } : {
        id: null,
        target: 'CONSIGNEE',
        name: '',
        street: '',
        city: '',
        country: '',
        zip: '',
        eori: '',
      }
    }
  }

  const getGoodsItemsConsignmentDetails = (goodsItemsResponse: Array<IGoodsItem>): Promise<Array<IGoodsItem>> => new Promise(
    (resolve, reject) => {
      const ids: Array<number> = []

      const addId = (id: number | undefined | null) => {
        if (id !== undefined && id !== null) {
          ids.push(id)
        }
      }

      goodsItemsResponse
        .map((goodsItem) => goodsItem.consigneeId)
        .forEach(addId)

      goodsItemsResponse
        .map((goodsItem) => goodsItem.consignorId)
        .forEach(addId)

      const uniqueConsignmentIds = Array.from(new Set(ids))
      if (uniqueConsignmentIds.length === 0) {
        resolve(goodsItemsResponse)
        return
      }

      ConsignmentDetailsService.getConsignmentDetailsByIds(uniqueConsignmentIds)
        .then((consignmentDetails: Array<IConsignmentDetail>) => {
          if (consignmentDetails.length > 0) {
            // eslint-disable-next-line no-param-reassign
            goodsItemsResponse = goodsItemsResponse.map((goodsItem) => {
              appendConsigneeToGoodsItem(goodsItem, consignmentDetails)
              appendConsignorToGoodsItem(goodsItem, consignmentDetails)

              return goodsItem
            })
          }
          resolve(goodsItemsResponse)
        }, (error) => {
          reject(error)
        })
    },
  )

  return {
    getGoodsItemsConsignmentDetails,
  }
}

export default useGetGoodsConsignmentDetails
