import React, { useContext, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import CustomerTableService from 'routes/CustomerTable/services/customer-table.service'
import ConfirmationModal from '../../../components/ConfirmationModal'
import FormRow from '../../../components/FormRow'
import { dateFormatWithTimeOptions } from '../../../config/dateFormatterConfig'
import { UserContext } from '../../../context/UserContext'
import { DeclarationPageResponse } from '../../../types/DeclarationPageResponse'
import { IClassifier, ISelectOption } from '../../../types/IClassifier'
import DeclarationService from '../../Declaration/services/declaration.service'
import { getHeader } from './config'
import TableRowDirectoStatus from './TableDirectoRowStatus'
import TableRowStatus from './TableRowStatus'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface TableBodyProps {
  isLoading: boolean;
  rows: any;
  prepareRow: any;
  asideAction: (input: boolean, targetId: number, response: DeclarationPageResponse) => void;
  modalAction: (input: boolean, targetId: number, response: DeclarationPageResponse) => void;
  getTableBodyProps: any;
  customsOffices: IClassifier[];
  refreshFilters: () => void;
}

function TableBody(props: TableBodyProps) {
  const history = useNavigate()
  const { t } = useTranslation()
  const [isDuplicationModalVisible, setDuplicationModalVisible] = useState(false)
  const [isDuplicationLoading, setDuplicationLoading] = useState(false)
  const [duplicationDeclarationId, setDuplicationDeclarationId] = useState<number>()
  const [isReassigningModalVisible, setReassigningModalVisible] = useState(false)
  const [currentOwnerOfDeclaration, setCurrentOwnerOfDeclaration] = useState('')
  const [customers, setCustomers] = useState<Array<ISelectOption>>([])
  const [form, setForm] = useState<{ declarationId: number | undefined, newCustomer: ISelectOption | undefined }>({
    declarationId: undefined,
    newCustomer: undefined,
  })

  const { user } = useContext(UserContext)
  const {
    prepareRow, rows, asideAction, getTableBodyProps, customsOffices, isLoading, modalAction, refreshFilters,
  } = props
  const [isOptionsOpen, toggleOptions] = useState(false)
  const [lastSeen, setLastSeen] = useState<number>(0)

  const findHeader = (input: string): string | undefined => getHeader(input, t)?.title
  const toggleDuplicationModalVisibility = () => {
    if (!isDuplicationLoading) {
      setDuplicationModalVisible(!isDuplicationModalVisible)
    }
  }

  const deleteDeclaration = (declarationId: number) => {
    DeclarationService.deleteDeclaration(declarationId)
      .then(() => {
        toast.success(t('messages.delete', { context: 'successful' }))
        return refreshFilters()
      })
      .catch(() => {
        toast.error(t('messages.delete', { context: 'failed' }))
        return refreshFilters()
      })
  }

  const openDuplicationModal = (declarationId: number) => {
    toggleDuplicationModalVisibility()
    setDuplicationDeclarationId(declarationId)
  }

  const duplicateDeclaration = (withFiles: boolean) => {
    if (isDuplicationLoading || duplicationDeclarationId === undefined) {
      return
    }

    setDuplicationLoading(true)
    DeclarationService.duplicateDeclaration(duplicationDeclarationId, withFiles)
      .then((response) => {
        setDuplicationLoading(false)
        toast.success(t('messages.duplicate', {
          context: 'successful',
          replace: { id: response.id },
        }))
        if (response.id !== null && response.id > 0) {
          history(`/transit/${response.id}`)
        }
        return refreshFilters()
      })
      .catch(() => {
        setDuplicationLoading(false)
        toast.error(t('messages.duplicate', { context: 'failed' }))
        return refreshFilters()
      })
  }
  const closeReassigningModal = () => {
    setReassigningModalVisible(false)
    setCurrentOwnerOfDeclaration('')
    setForm({ declarationId: undefined, newCustomer: undefined })
  }

  const openReassignmentModal = async (declarationId: number, customerName: string) => {
    const loadedCustomers = await CustomerTableService.fetchAllCustomers()
    setReassigningModalVisible(true)
    setCurrentOwnerOfDeclaration(customerName)
    setForm({ newCustomer: undefined, declarationId })
    setCustomers(loadedCustomers?.map((item) => ({
      value: item.id.toString(),
      label: `${item.name}`,
    })))
  }
  const confirmReassignment = () => {
    if (form && form.declarationId && form.newCustomer) {
      DeclarationService.reassignDeclarationOwner(form.declarationId, Number(form.newCustomer.value))
        .then(() => {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          const changedRow = rows.find((row: any) => row.original.id === form.declarationId)
          if (changedRow) {
            changedRow.values.customerName = form.newCustomer?.label
          }
          toast.success(t('messages.savingSuccess'))
          closeReassigningModal()
        })
        .catch(() => toast.success(t('messages.savingFailed')))
    }
  }
  return (
    <tbody {...getTableBodyProps()} className="position-relative">
      {
        rows.map((row: any, i: number) => {
          prepareRow(row)
          return (
            <React.Fragment key={`${i + row.cells.length}n`}>
              {(isMobile)
                ? (
                  <React.Fragment key={`${i + row.cells.length}_mobile`}>
                    <tr
                      {...row.getRowProps()}
                    >
                      <td className="w-1">
                        <button
                          type="button"
                          className="btn btn-link no-underline d-flex align-items-center justify-content-between
                         font-weight-light text-decoration-underline border-0 w-100 h-100 p-0 lh-1"
                          onClick={() => {
                            asideAction(true, row.original.id, row.original)
                          }}
                        >
                          <i className="fal fa-align-left fa-md" />
                          {row.original.hasProblem
                            ? (
                              <i className={row.cells[1].row.original.hasProblem === true
                                ? 'fal fa-exclamation-triangle text-danger fa-md ms-1' : ''}
                              />
                            )
                            : null}
                        </button>
                      </td>
                      {/* eslint-disable  @typescript-eslint/no-explicit-any */}
                      {row.cells.map((cell: any) => (
                        <td
                          key={cell.id}
                          /* eslint-disable-next-line no-nested-ternary */
                          className={isMobile && cell.column.id === 'mrn'
                            ? 'd-none d-sm-table-cell' : cell.column.id === 'id'
                              ? 'w-1 ' : ''}
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...cell.getCellProps()}
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...row.getToggleRowExpandedProps()}
                        >

                          {
                            cell.column.id === 'status'
                          && (
                            <TableRowStatus value={cell.value} />
                          )
                          }
                          {
                            cell.column.id === 'directoStatus'
                          && (
                            <TableRowDirectoStatus value={cell.value} />
                          )
                          }
                          {
                            (cell.column.id === 'departureCustomsOffice' || cell.column.id === 'destinationCustomsOffice')
                          && (
                            <span>{customsOffices.find((office) => office.code === cell.value)?.codeLabel}</span>
                          )
                          }
                          {
                            (
                              (cell.column.id === 'consignorName' || cell.column.id === 'consigneeName')
                            && (row.original.consignorName === t('declaration.multipleGoodsTraders')
                              || row.original.consigneeName === t('declaration.multipleGoodsTraders'))
                            )
                          && (
                            <i>{cell.render('Cell')}</i>
                          )
                          }
                          {
                            (cell.column.id === 'mrn')
                          && (
                            <div>
                              <div>{row.original.mrn}</div>
                              <div>{row.original.lrn}</div>
                            </div>
                          )
                          }
                          {
                            (
                              cell.column.id !== 'status'
                            && cell.column.id !== 'directoStatus'
                            && !(cell.column.id === 'departureCustomsOffice' || cell.column.id === 'destinationCustomsOffice')
                            && !(cell.column.id === 'Consignor' || cell.column.id === 'Consignee')
                            && !(cell.column.id === 'mrn')
                            )
                          && cell.render('Cell')
                          }
                        </td>
                      ))}
                      <td role="presentation" className="d-none d-sm-table-cell dropdown-cell w-1" onClick={() => toggleOptions(!isOptionsOpen)}>
                        <Dropdown>
                          <Dropdown.Toggle variant="link" className="p-0 hide-caret" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h fa-md" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="shadow">
                            <Dropdown.Item onClick={() => openDuplicationModal(row.original.id)}>
                              <i className="fal fa-clone fa-flip-horizontal me-2 fa-md" />
                              <span>
                                {t('buttons.duplicate')}
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => asideAction(true, row.original.id, row.original)}>
                              <i className="fal fa-align-right me-2 fa-md" />
                              <span>
                                {t('buttons.viewLogs')}
                              </span>
                            </Dropdown.Item>
                            {
                              row.original.status === 'DRAFT'
                            && (
                              <Dropdown.Item onClick={() => deleteDeclaration(row.original.id)}>
                                <i className="fal fa-trash me-2 fa-md" />
                                <span>{t('buttons.delete')}</span>
                              </Dropdown.Item>
                            )
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                    {row.isExpanded ? (
                      /* eslint-disable  @typescript-eslint/no-explicit-any */
                      row.subRows.map((subRow: any) => (
                        <tr key={subRow.key}>
                          <td
                            colSpan={row.cells.length + 2}
                          >
                            <div className="d-flex flex-row flex-wrap">
                              {Object.entries(subRow.original).filter((subRowEntries) => (
                                subRowEntries[0] !== 'declarationType'
                              && subRowEntries[0] !== 'status'
                              )).map((originalValue) => (
                                <div
                                  key={originalValue[0]}
                                  className={`col-12 col-sm-6 col-md-4 col-lg-3 mb-2 lh-sm
                                  ${originalValue[0] === 'mobileMRN' ? 'd-table-cell d-sm-none' : ''}`}
                                >
                                  <div className="col-12 col-sm-6">
                                    <small className="text-muted">
                                      {findHeader(originalValue[0])}
                                    </small>
                                  </div>
                                  <div className="mb-2 mb-md-0 col-12 col-sm-6">
                                    {/* @ts-ignore */}
                                    <small>
                                      {
                                        (originalValue[0] === 'departureCustomsOffice' || originalValue[0] === 'destinationCustomsOffice')
                                          && (
                                            <span>{customsOffices.find((office) => office.code === originalValue[1])?.codeLabel}</span>
                                          )
                                      }

                                      {
                                        (originalValue[0] !== 'departureCustomsOffice'
                                            && originalValue[0] !== 'destinationCustomsOffice'
                                        )
                                          && originalValue[1]
                                      }
                                    </small>
                                  </div>
                                </div>
                              ))}
                              <div className="d-flex d-sm-none justify-content-center w-100">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-link no-underline hover--yellow-left font-weight-light"
                                  onClick={() => openDuplicationModal(row.original.id)}
                                >
                                  <i className="fal fa-clone fa-flip-horizontal me-2 fa-md" />
                                  <span>
                                    {t('buttons.duplicate')}
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-link no-underline hover--yellow-left font-weight-light"
                                  onClick={() => asideAction(true, row.original.id, row.original)}
                                >
                                  <i className="fal fa-align-right me-2 fa-md" />
                                  <span>
                                    {t('buttons.viewLogs')}
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-link no-underline hover--yellow-left font-weight-light"
                                  onClick={() => openReassignmentModal(row.original.id, row.original.customerName)}
                                >
                                  <i className="fal fa-align-right me-2 fa-md" />
                                  <span>
                                    {t('buttons.reassignOwner')}
                                  </span>
                                </button>
                                {
                                  row.original.status === 'DRAFT'
                                && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-link no-underline hover--yellow-left font-weight-light"
                                    onClick={() => deleteDeclaration(row.original.id)}
                                  >
                                    <i className="fal fa-trash me-2 fa-md" />
                                    <span>Delete</span>
                                  </button>
                                )
                                }
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))) : null}
                  </React.Fragment>
                )
                : (
                  <tr
                    style={{
                      color: lastSeen !== 0 && lastSeen === row.original.id && '#1a1b1e',
                      backgroundColor: lastSeen !== 0 && lastSeen === row.original.id && 'rgba(0, 0, 0, 0.075)',
                    }}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...row.getRowProps()}
                  >
                    {/* eslint-disable  @typescript-eslint/no-explicit-any */}
                    {row.cells.map((cell: any) => (
                      <td
                        key={cell.id}
                        role="presentation"
                        className={
                          `${cell.column.id === 'id' ? 'text-left' : ''} ${
                            (!isOptionsOpen && !(cell.column.id === 'mrn' || cell.column.id === 'id')) ? 'pointer' : ''} ${
                            cell.column.className ? cell.column.className : ''
                          }`
                        }
                        onClick={() => {
                          if (isOptionsOpen) {
                            return toggleOptions(!isOptionsOpen)
                          }

                          if (cell.column.id === 'mrn' || cell.column.id === 'id' || cell.column.id === 'notes') {
                            if (cell.column.id === 'notes') {
                              return modalAction(true, row.original.id, row.original)
                            }
                            return null
                          }
                          setLastSeen(row.original.id)
                          return asideAction(true, row.original.id, row.original)
                        }}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...cell.getCellProps()}
                      >
                        {
                          cell.column.id === 'id'
                        && (
                          <div
                            className="d-flex align-items-center justify-content-between border-0 w-100 h-100 p-0 lh-1"
                          >
                            {row.cells[1].row.original.hasProblem === true
                              ? <i className="fal fa-exclamation-triangle me-2 text-danger fa-md" />
                              : ''}
                            <span className="text-decoration-underline">
                              {row.cells[0].render('Cell')}
                            </span>
                          </div>
                        )
                        }

                        {
                          cell.column.id === 'status'
                        && (
                          <TableRowStatus value={row.original.status} />
                        )
                        }
                        {
                          cell.column.id === 'directoStatus'
                        && (
                          <TableRowDirectoStatus value={cell.value} />
                        )
                        }
                        {cell.column.id === 'usesT1Grn' && (
                          <div>{cell.value === false ? t('common.no') : t('common.yes')}</div>
                        )}
                        {
                          (cell.column.id === 'departureCustomsOffice' || cell.column.id === 'destinationCustomsOffice')
                        && (
                          <span>{cell.value === null ? '-' : customsOffices.find((office) => office.code === cell.value)?.codeLabel }</span>
                        )
                        }
                        {cell.column.id === 'consigneeName' && row.original.consigneeName !== 'Multiple set under goods' && (
                          <span>{cell.value === null ? '-' : cell.value}</span>
                        )}
                        {cell.column.id === 'consignorName' && row.original.consignorName !== 'Multiple set under goods' && (
                          <span>{cell.value === null ? '-' : cell.value}</span>
                        )}
                        {cell.column.id === 'carrierName' && (
                          <span>{cell.value === null ? '-' : cell.value}</span>
                        )}
                        {cell.column.id === 'departureTruckNo' && (
                          <span>{cell.value === null ? '-' : cell.value}</span>
                        )}
                        {
                          (
                            (cell.column.id === 'consignorName' || cell.column.id === 'consigneeName')
                          && (row.original.consignorName === 'Multiple set under goods'
                            || row.original.consigneeName === 'Multiple set under goods')
                          )
                        && (
                          <i>{t('declaration.multipleGoodsTraders')}</i>
                        )
                        }
                        {
                          (cell.column.id === 'mrn')
                        && (
                          <div>
                            <div>{row.original.mrn}</div>
                            <div>{row.original.lrn}</div>
                          </div>
                        )
                        }
                        {cell.column.id === 'notes' && (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {row.original.notes}
                          </>
                        )}
                        {
                          (cell.column.id === 'isReviewed')
                        && (
                          <div
                            className={`d-flex justify-content-center align-items-center rounded-circle wh-24 ms-1 ${
                              row.original.isReviewed ? 'text-success' : 'text-danger'
                            }`}
                            style={{ border: '1px solid' }}
                          >
                            <i className={`${row.original.isReviewed ? 'fa-check' : 'fa-times'} far`} />
                          </div>
                        )
                        }
                        {
                          (cell.column.id === 'latestCustomsUpdate')
                        && (
                          <span>
                            {
                              row.original.latestCustomsUpdate !== null
                              // eslint-disable-next-line max-len
                                ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(row.original.latestCustomsUpdate)) : ''
                            }
                          </span>
                        )
                        }
                        {
                          cell.column.id !== 'id'
                        && cell.column.id !== 'mrn'
                        && cell.column.id !== 'status'
                        && cell.column.id !== 'directoStatus'
                        && cell.column.id !== 'carrierName'
                        && cell.column.id !== 'consignorName'
                        && cell.column.id !== 'consigneeName'
                        && cell.column.id !== 'departureCustomsOffice'
                        && cell.column.id !== 'destinationCustomsOffice'
                        && cell.column.id !== 'latestCustomsUpdate'
                          && cell.column.id !== 'notes'
                        && !(
                          (cell.column.id === 'consignorName' || cell.column.id === 'consigneeName')
                          && (row.original.consignorName === 'Multiple set under goods'
                            || row.original.consigneeName === 'Multiple set under goods')
                        )
                        && (
                          cell.render('Cell')
                        )
                        }
                      </td>
                    ))}

                    <td className="dropdown-cell w-1">
                      <Dropdown
                        onToggle={(isOpen) => toggleOptions(isOpen)}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          variant="link"
                          className="p-0 hide-caret"
                        >
                          <i className="fas fa-ellipsis-h fa-md" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="shadow">
                          <Dropdown.Item onClick={() => openDuplicationModal(row.original.id)}>
                            <i className="fal fa-clone fa-flip-horizontal me-2 fa-md" />
                            <span>
                              {t('buttons.duplicate')}
                            </span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => asideAction(true, row.original.id, row.original)}
                          >
                            <i className="fal fa-align-right me-2 fa-md" />
                            <span>
                              {t('buttons.viewLogs')}
                            </span>
                          </Dropdown.Item>
                          {
                            row.original.status === 'DRAFT'
                          && user?.role === 'ADMIN'
                          && (
                            <Dropdown.Item
                              onClick={() => openReassignmentModal(row.original.id, row.original.customerName)}
                            >
                              <i className="fal fa-people-arrows me-2 fa-md" />
                              <span>{t('buttons.reassignOwner')}</span>
                            </Dropdown.Item>
                          )
                          }
                          {
                            row.original.status === 'DRAFT'
                          && (
                            <Dropdown.Item onClick={() => deleteDeclaration(row.original.id)}>
                              <i className="fal fa-trash me-2 fa-md" />
                              <span>{t('buttons.delete')}</span>
                            </Dropdown.Item>
                          )
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                )}
            </React.Fragment>
          )
        })
      }
      <tr className={`loading-backdrop ${isLoading ? 'backdrop-visible' : 'backdrop-hidden'}`} />
      <ConfirmationModal
        title={t('buttons.duplicate')}
        messageBody={t('messages.duplicateDocumentsMessage')}
        isVisible={isDuplicationModalVisible}
        toggleVisibility={toggleDuplicationModalVisibility}
        isLoading={isDuplicationLoading}
        onConfirmation={() => duplicateDeclaration(true)}
        onRejection={() => duplicateDeclaration(false)}
      />
      <Modal
        show={isReassigningModalVisible}
        dialogClassName="modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          closeReassigningModal()
        }}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-self-center">
            <h2>{t('declaration.reassignOwner')}</h2>
          </Modal.Title>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              closeReassigningModal()
            }}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <FormRow title={t('declaration.currentOwner')}>
              <div className="input-label justify-content-start ">{currentOwnerOfDeclaration}</div>
            </FormRow>
            <FormRow title={t('declaration.selectNewOwner')}>
              <Select
                options={customers}
                className="select"
                classNamePrefix="select"
                isClearable={false}
                menuPlacement="auto"
                isSearchable={false}
                placeholder={t('declaration.selectNewOwner')}
                onChange={(option: ISelectOption | null) => {
                  if (option !== null) {
                    setForm({
                      newCustomer: customers.find((customer) => customer.value === option.value),
                      declarationId: form.declarationId,
                    })
                  }
                }}
              />
            </FormRow>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary text-primary btn-lg m-1 mt-2"
              onClick={() => {
                confirmReassignment()
              }}
            >
              <span>{t('buttons.change')}</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </tbody>
  )
}

export default TableBody
