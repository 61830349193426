import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { hasText } from '../../../common/utils/common-util'

const C0671Validator = {
  validateAdditionalIdentifierRequirement: (formData: DeclarationForm): RuleResult => {
    if (hasText(formData.locationOfGoods?.authorisationNumber)) {
      return 'OPTIONAL'
    }
    return 'NOT_ALLOWED'
  },
} as const

export default C0671Validator
