import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'

export interface PrivacyPolicy {
  id: number;
  content: string;
}

export interface PrivacyPolicyUpdate {
  contentRu: string;
  contentEn: string;
}

function getPrivacyTerms(language: string) {
  return axios
    .get(apiService.getFullApiUrl(`/privacy-policy?language=${language}`))
    .then<PrivacyPolicy>(handleResponseData)
    .catch(handleApiException)
}

function acceptTerms() {
  return axios
    .patch(apiService.getFullApiUrl('/privacy-policy'))
    .then<PrivacyPolicy>(handleResponseData)
    .catch(handleApiException)
}

function changeTerms(contentEn: string, contentRu: string) {
  return axios
    .put(
      apiService.getFullApiUrl('/privacy-policy'),
      {
        contentEn, contentRu,
      },
    )
    .then<PrivacyPolicyUpdate>(handleResponseData)
    .catch(handleApiException)
}

const PrivacyService = {
  getPrivacyTerms,
  acceptTerms,
  changeTerms,
}

export default PrivacyService
