import { toast } from 'react-toastify'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useCsvParser from '../useCsvParser'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import { CustomColumnMap, isKnownColumnKey, parseConsignmentItemRow } from './mapper'
import { ConsignmentItem } from '../../form/schemas/consignmentItemSchema'
import { excludeDeleted } from '../../../common/utils/common-util'

function useConsignmentItemImport(houseConsignmentIndex: number) {
  const { t } = useTranslation()
  const {
    getValues,
    setValue,
  } = useFormContext<DeclarationForm>()
  const { parseCsv } = useCsvParser()
  const [csvRows, setCsvRows] = useState<string[][]>([])
  const [rawHeaders, setRawHeaders] = useState<ReadonlyArray<string>>([])
  const [customHeaderMappingModalVisibility, setCustomHeaderMappingModalVisibility] = useState(false)
  const [customColumnMap, setCustomColumnMap] = useState<CustomColumnMap>(new Map())

  const toggleMappingModalVisibility = () => setCustomHeaderMappingModalVisibility(!customHeaderMappingModalVisibility)

  const startImport = () => {
    // @ts-ignore
    if ([...customColumnMap.values()].some((value) => value === null || value === undefined)) {
      const blankColumns: string[] = []
      customColumnMap.forEach((value, key) => {
        if (value === null || value === undefined) {
          blankColumns.push(key)
        }
      })

      toast.warning(t('consignmentItemImport.missingColumnMapping', { 0: blankColumns }), { autoClose: false })
    }
    const consignmentItemPromises: Array<Promise<ConsignmentItem>> = []
    csvRows.forEach((row, index) => {
      consignmentItemPromises.push(
        parseConsignmentItemRow(rawHeaders, row, index, customColumnMap),
      )
    })

    Promise.allSettled(consignmentItemPromises)
      .then((results) => {
        const items: Array<ConsignmentItem> = []

        results.forEach((consignmentItemPromise) => {
          if (consignmentItemPromise.status === 'fulfilled') {
            items.push(consignmentItemPromise.value)
          }
        })
        const existingItems: ConsignmentItem[] = getValues(`houseConsignment.${houseConsignmentIndex}.consignmentItem`) ?? []
        const nextNewSequenceNumber = existingItems.filter(excludeDeleted).length
        existingItems.push(...items.map((item, index) => ({ ...item, sequenceNumber: nextNewSequenceNumber + index })))
        setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem`, existingItems)
        toggleMappingModalVisibility()
      })
      .catch((reason) => {
        toast.warn(`${reason}`)
      })
  }

  const parseImportFile = async (files: FileList | null) => {
    if (files && files[0] !== null) {
      try {
        const parsedCsvRows = await parseCsv(files[0]) as string[][]
        const headers = parsedCsvRows.shift() as ReadonlyArray<string>
        if (headers) {
          setCustomColumnMap(new Map(headers.map((csvHeader) => {
            if (isKnownColumnKey(csvHeader)) {
              return [csvHeader, csvHeader]
            }

            const headerParts = csvHeader.split('_')
            if (headerParts.length === 3 && !Number.isNaN(headerParts[2])) {
              const columnKey = `${headerParts[0]}_${headerParts[1]}`
              if (isKnownColumnKey(columnKey)) {
                return [csvHeader, columnKey]
              }
              return [csvHeader, null]
            }

            return [csvHeader, null]
          })))
        }
        if (parsedCsvRows.at(parsedCsvRows.length - 1)?.length === 1) {
          parsedCsvRows.pop()
        }
        setRawHeaders(headers)
        setCsvRows(parsedCsvRows)
      } catch (e) {
        toast.error(t('excel.processingError'))
        setCsvRows([])
        setRawHeaders([])
      }
    }
  }

  return {
    customHeaderMappingModalVisibility,
    toggleMappingModalVisibility,
    customColumnMap,
    setCustomColumnMap,
    parseImportFile,
    startImport,
  }
}

export default useConsignmentItemImport
