import { DirectoFormType } from '../../form/schemas/directoFormSchema'
import { TransitOperationDirectoRequest } from '../../../common/models/transit-operation-directo-request'
import { DirectoEventResponse, DirectoEventResponseStatusEnum } from '../../../common/models/directo-event-response'

export function toTransitOperationDirectoRequest(
  descriptionAddendum: string | undefined,
  customerId: number,
  transitOperationId: number,
): TransitOperationDirectoRequest {
  return {
    descriptionAddendum: descriptionAddendum ?? '',
    customerId,
    transitOperationId,
  }
}

export function parseDirectoResponse(response: DirectoEventResponse | undefined): DirectoFormType {
  return {
    id: response?.id,
    customerId: response?.customerId ?? null,
    descriptionAddendum: response?.descriptionAddendum,
    status: DirectoEventResponseStatusEnum[response?.status ?? 'IDLE'],
  }
}
