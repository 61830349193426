import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { useExpanded, useTable } from 'react-table'
import Tooltip from 'rc-tooltip'
import { useTranslation } from 'react-i18next'
import useCertificatesInfoApi from './hooks/useCertificateInfo/api'
import { CertificateInfoResponse } from './models/certificate-info-response'
import { dateFormatWithTimeOptions } from '../../../../config/dateFormatterConfig'
import { TabType } from '../index'

enum CertificateStatusEnum {
  VALID = 'VALID',
  DUE_TO_EXPIRE = 'DUE_TO_EXPIRE',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

interface CertificateInfoRow {
  status: CertificateStatusEnum
  name?: string
  issuer?: string
  validFrom?: string
  validTo?: string
}

interface CertificatesTabProps {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line react/no-unused-prop-types
  readonly tabType: TabType
}

function CertificatesTab(props: CertificatesTabProps) {
  const [certificates, setCertificates] = useState<CertificateInfoRow[]>([])
  const { t } = useTranslation()

  const {
    loading,
    setLoading,
  } = props

  const {
    fetchCertificatesInfo,
  } = useCertificatesInfoApi()

  function getStatus(certificateInfo: CertificateInfoResponse): CertificateStatusEnum {
    if (!certificateInfo.notAfter || !certificateInfo.notBefore) {
      return CertificateStatusEnum.INVALID
    }

    const now = new Date()
    const notBefore = new Date(certificateInfo.notBefore)
    const notAfter = new Date(certificateInfo.notAfter)

    if (now < notBefore) {
      return CertificateStatusEnum.NOT_ACTIVE
    }

    const oneMonthInTheFuture = new Date()
    oneMonthInTheFuture.setMonth(oneMonthInTheFuture.getMonth() + 1)

    if (notBefore <= now && now < notAfter) {
      if (notAfter < oneMonthInTheFuture) {
        return CertificateStatusEnum.DUE_TO_EXPIRE
      }

      return CertificateStatusEnum.VALID
    }

    return CertificateStatusEnum.EXPIRED
  }

  function formatDate(date: Date | undefined): string {
    return date ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(date)) : ''
  }

  function mapToRows(certificatesInfo: CertificateInfoResponse[]): CertificateInfoRow[] {
    return certificatesInfo.map((certInfo) => ({
      status: getStatus(certInfo),
      name: certInfo.name,
      issuer: certInfo.issuer,
      validFrom: formatDate(certInfo.notBefore),
      validTo: formatDate(certInfo.notAfter),
      fingerprint: certInfo.fingerprint,
    }))
  }

  useEffect(() => {
    if (fetchCertificatesInfo.data) {
      setCertificates(mapToRows(fetchCertificatesInfo.data.data))
    }
  }, [fetchCertificatesInfo.data])

  const columns = React.useMemo(() => [
    {
      Header: t('certificates.expiry'),
      accessor: 'expiry',
    },
    {
      Header: t('certificates.validTo'),
      accessor: 'validTo',
    },
    {
      Header: t('certificates.name'),
      accessor: 'name',
    },
    {
      Header: t('certificates.issuer'),
      accessor: 'issuer',
    },
    {
      Header: t('certificates.fingerprint'),
      accessor: 'fingerprint',
    },
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } = useTable<any>({
    columns,
    data: certificates,
  }, useExpanded)

  function getStatusIcon(row: CertificateInfoRow) {
    switch (row.status) {
      case CertificateStatusEnum.VALID:
        return (
          <Tooltip overlayClassName="tooltip-lg" overlay={<small>{`${row.validFrom} - ${row.validTo} (${row.status})`}</small>}>
            <i className="fal fa-check-circle text-success" />
          </Tooltip>
        )
      case CertificateStatusEnum.EXPIRED:
        return (
          <Tooltip overlayClassName="tooltip-lg" overlay={<small>{`${row.validFrom} - ${row.validTo} (${row.status})`}</small>}>
            <i className="fal fa-times-circle text-danger" />
          </Tooltip>
        )
      default:
        return (
          <Tooltip overlayClassName="tooltip-lg" overlay={<small>{`${row.validFrom} - ${row.validTo} (${row.status})`}</small>}>
            <i className="fal fa-exclamation-triangle text-warning" />
          </Tooltip>
        )
    }
  }

  return (
    <div className="container-fluid py-3">
      <table
        className="table table-sm table-borderless data-table me-2"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
            <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id} ${headerGroup.columns?.length}`}>
              {headerGroup.headers.map((column) => (
                <th
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...column.getHeaderProps()}
                  key={column.id}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <React.Fragment key={row.id + row.cells.length}>
                <tr>
                  <td>{getStatusIcon(row.original)}</td>
                  <td>{row.original.validTo}</td>
                  <td>{row.original.name}</td>
                  <td>
                    <span className="text-wrap text-break">
                      {row.original.issuer}
                    </span>
                  </td>
                  <td>{row.original.fingerprint}</td>
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CertificatesTab
