import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { DeclarationForm } from '../form/schemas/declarationFormSchema'

function NavigationBarInfo() {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()
  const transitOperationId = getValues('id')
  const mrn = getValues('mrn')
  const lrn = getValues('lrn')

  return (
    <div className="d-flex align-items-center py-2">
      <h1 className="text-uppercase">
        {
          transitOperationId !== null ? t('common.edit') : t('common.new')
        }
      </h1>
      {
        transitOperationId !== null && (
          <div className="badge bg-dark ms-3">
            <small className="pe-1">{`${t('transit.id')}: `}</small>
            <small>{transitOperationId}</small>
          </div>
        )
      }
      {mrn !== '' && (
        <div className="badge bg-dark ms-3">
          <small className="pe-1">{`${t('common.mrn')}: `}</small>
          <small>{mrn}</small>
        </div>
      )}
      {mrn === '' && lrn !== '' && (
        <div className="badge bg-dark ms-3">
          <small className="pe-1">{`${t('common.lrn')}: `}</small>
          <small>{lrn}</small>
        </div>
      )}
    </div>
  )
}
export default NavigationBarInfo
