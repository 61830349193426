import { RuleResult } from 'types/DeclarationP5'
import { SecurityEnumT } from '../../schemas/declarationFormSchema'

const B1858Validator = {
  validatePlaceOfUnloadingRequirement: (
    security: SecurityEnumT,
  ): RuleResult => {
    if (security === '0') return 'NOT_ALLOWED'

    return 'REQUIRED'
  },
} as const

export default B1858Validator
