import { Modal } from 'react-bootstrap'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import store from '../../../../redux/store'
import { InvalidField } from '../../../Transits/TransitsAside/detailView'

interface BlacklistModalProps {
  isBlacklistWarningModalVisible: boolean;
  setIsBlacklistWarningModalVisible: Dispatch<SetStateAction<boolean>>;
  setProfileModalVisible: Dispatch<SetStateAction<boolean>>
  invalidFields: Array<InvalidField>;
  truckRestrictions: Array<string>;
}

function BlacklistModal(props: BlacklistModalProps) {
  const {
    isBlacklistWarningModalVisible, setIsBlacklistWarningModalVisible,
    invalidFields, setProfileModalVisible, truckRestrictions,
  } = props

  const { t } = useTranslation()
  const state = store.getState()

  return (
    <Modal
      show={isBlacklistWarningModalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setIsBlacklistWarningModalVisible((prevState) => !prevState)}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2>{t('declaration.blacklisted')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setIsBlacklistWarningModalVisible((prevState) => !prevState)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-4">
          <h5 className="mb-3 text-black">{t('declaration.blacklistWarning')}</h5>
          {invalidFields.filter((field) => field.pointer !== null && field.pointer.includes('consignor')).length > 0 && (
            <h4 className="fw-bold">
              {t(
                'messages.blacklistedWarning',
                { name: state.consignmentDetailReducer.consignor?.name, field: t('declaration.trader') },
              )}
            </h4>
          )}

          {invalidFields.filter((field) => field.pointer !== null && field.pointer.includes('consignee')).length > 0
                    && (
                      <h4 className="fw-bold">
                        {t(
                          'messages.blacklistedWarning',
                          { name: state.consignmentDetailReducer.consignee?.name, field: t('declaration.trader') },
                        )}
                      </h4>
                    )}
          {invalidFields.filter((field) => field.pointer !== null && field.pointer.includes('carrier')).length > 0
                    && (
                      <h4 className="fw-bold">
                        {t(
                          'messages.blacklistedWarning',
                          { name: store.getState().consignmentDetailReducer.carrier?.name, field: t('declaration.trader') },
                        )}
                      </h4>
                    )}
          {truckRestrictions.filter((restrictions) => (store.getState())
            .transportReducer.transport?.departureTruckNo?.toLowerCase()
            .includes(restrictions.toLowerCase())).length > 0 && (
            <h4 className="fw-bold">
              {t(
                'messages.blacklistedWarning',
                { name: state.transportReducer.transport?.departureTruckNo, field: t('declaration.truck') },
              )}
            </h4>
          )}
          {truckRestrictions.filter((restrictions) => (store.getState())
            .transportReducer.transport?.borderCrosserTruckNo?.toLowerCase()
            .includes(restrictions.toLowerCase())).length > 0 && (
            <h4 className="fw-bold">
              {t(
                'messages.blacklistedWarning',
                {
                  name: (store.getState()).transportReducer.transport?.borderCrosserTruckNo,
                  field: t('declaration.truck'),
                },
              )}
            </h4>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          onClick={() => {
            setIsBlacklistWarningModalVisible((prevState) => !prevState)
            setProfileModalVisible(((prevState) => !prevState))
          }}
        >
          <i className="fal fa-reply fa-flip-horizontal me-2" />
          <span>{t('buttons.send')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default BlacklistModal
