import { combineReducers } from 'redux'
import declarationInfoReducer from './declarationInfoReducer'
import guaranteeReducer from './guaranteeReducer'
import transportReducer from './transportReducer'
import declarationReducer from './declarationReducer'
import consignmentDetailReducer from './consignmentDetailReducer'
// eslint-disable-next-line import/no-cycle
import { initialState } from '../store'
import sealReducer from './sealReducer'

const appReducers = combineReducers({
  declarationInfoReducer,
  guaranteeReducer,
  declarationReducer,
  transportReducer,
  consignmentDetailReducer,
  sealReducer,
})

/* eslint-disable  @typescript-eslint/no-explicit-any */
export default function rootReducer(state: any, action: any) {
  if (action.type === 'RESET_DECLARATION') {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }

  return appReducers(state, action)
}
