import React from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope, ObjectScope, ScopeProps } from 'types/DeclarationP5'
import { getSelectCountryFilter } from 'components/react-select/CountryFunctions'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import useCodelist from '../../../common/hooks/useCodelist'
import E1104Validator from '../../form/validators/E1104'
import E1102Validator from '../../form/validators/E1102'
import EoriSearch from '../../components/EoriSearch'
import TraderSearch from '../../components/TraderSearch'

const expectedScope = /(^consignee$)|(houseConsignment\.\d+\.consignee)|(houseConsignment\.\d+\.consignmentItem\.\d+\.consignee)/g
const isConsigneeScope = (scope: ObjectScope): scope is 'consignee' | `houseConsignment.${number}.consignee`
| `houseConsignment.${number}.consignmentItem.${number}.consignee` => (Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

type ConsignmentConsignee = NestedFieldScope<'consignee'>
type HouseConsignee = NestedFieldScope<`houseConsignment.${number}.consignee`>
type ConsignmentItemConsignee = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.consignee`>
type ConsigneeScope = ConsignmentConsignee | HouseConsignee | ConsignmentItemConsignee

function Consignee({ scope }: ScopeProps<ObjectScope>) {
  if (!isConsigneeScope(scope)) throw Error('Unable to narrow, invalid scope')
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-consignee"
        headingLabel={t('declaration.p5.consignee')}
      >
        <FormField labelKey="declaration.p5.consigneeIdentificationNr">
          <EoriSearch scope={scope} />
        </FormField>
        <FormField scope={`${scope}.name`} labelKey="declaration.p5.consigneeName">
          <TraderSearch
            scope={scope}
            maxLength={E1104Validator.getNameOrStreetMaxLength()}
            isConsignee
          />
        </FormField>
        <FormField labelKey="declaration.p5.country">
          <FormSelect
            <ConsigneeScope>
            field={`${scope}.address.country`}
            labelKey="declaration.p5.country"
            options={countriesOptions}
            customFilterOption={getSelectCountryFilter}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.address">
          <Input
            <ConsigneeScope>
            field={`${scope}.address.streetAndNumber`}
            autoComplete={`${scope}.address.streetAndNumber`}
            labelKey="declaration.p5.address"
            maxLength={E1104Validator.getNameOrStreetMaxLength()}
            type="text"
            placeholder={t('declaration.p5.address')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.city">
          <Input
            <ConsigneeScope>
            field={`${scope}.address.city`}
            autoComplete={`${scope}.address.city`}
            labelKey="declaration.p5.city"
            maxLength={35}
            type="text"
            placeholder={t('declaration.p5.city')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.postcode">
          <Input
            <ConsigneeScope>
            field={`${scope}.address.postcode`}
            autoComplete={`${scope}.address.postcode`}
            labelKey="declaration.p5.postcode"
            maxLength={E1102Validator.getPostcodeMaxLength()}
            type="text"
            placeholder={t('declaration.p5.postcode')}
          />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default Consignee
