import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import TRANSLATIONS_EN from './locale/en'
import TRANSLATIONS_RU from './locale/ru'
import translationsEN from './locale/en/errors.json'
import translationsRU from './locale/ru/errors.json'

const resources = {
  en: {
    translation: TRANSLATIONS_EN, translationsEN,
  },
  ru: {
    translation: TRANSLATIONS_RU, translationsRU,
  },
}
const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 't1_locale',
  caches: ['localStorage'],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'translation',
    resources,
    detection: DETECTION_OPTIONS,
    lng: localStorage.getItem('t1_locale') ? `${localStorage.getItem('t1_locale')}` : 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    returnNull: true,
  })

export default i18n
