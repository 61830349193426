import { createContext } from 'react'
import { DeclarationData } from '../../routes/Declaration/hooks/useDeclaration'
import { DirectoEventState } from '../../routes/Declaration/hooks/useDirectoEvent'

export interface MetaData {
  country: DeclarationCountry | null;
  setDepartureCustomsOffice: (customsOffice: string) => void;
  accessLevel: AccessLevel;
}

export enum DeclarationCountry {
  // ESTONIA = 'EE',
  LATVIA = 'LV',
  LITHUANIA = 'LT',
  POLAND = 'PL',
  FINLAND = 'FI',
  ROMANIA = 'RO',
  GREAT_BRITAIN = 'GB',
  NORTHERN_IRELAND = 'XI',
  // BULGARIA = 'BG',
}
export enum AccessLevel {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export interface IDeclarationContext {
  metaData: MetaData
  clearPreviousDocumentProcedures: () => void;
  directoEvent: DirectoEventState;
  declaration: DeclarationData
}

export const DeclarationContext = createContext<IDeclarationContext>({} as IDeclarationContext)
