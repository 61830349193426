/* tslint:disable */
/* eslint-disable */
/**
 * Tardek API Endpoints
 * This Is a documentation for T1 Tardek API Endpoints. <br />
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@bitweb.ee
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateOrUpdateCustomsOfficeOfTransitRequest } from './create-or-update-customs-office-of-transit-request'

/**
 *
 * @export
 * @interface CreateOrUpdateTransitOperationRequest
 */
export interface CreateOrUpdateTransitOperationRequest {
    /**
     * Departure office of transit operation
     * @type {string}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    customsOfficeOfDeparture: string | null;
    /**
     * Destination office of transit operation
     * @type {string}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    customsOfficeOfDestination: string | null;
    /**
     * Declaration type
     * @type {string}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    declarationType: CreateOrUpdateTransitOperationRequestDeclarationTypeEnum;
    /**
     * Security type
     * @type {string}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    security: CreateOrUpdateTransitOperationRequestSecurityEnum;
    /**
     * Additional Declaration type
     * @type {string}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    additionalDeclarationType: CreateOrUpdateTransitOperationRequestAdditionalDeclarationTypeEnum;
    /**
     * Has reduced dataset
     * @type {boolean}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    reducedDatasetIndicator: boolean;
    /**
     * Binding itinerary
     * @type {boolean}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    bindingItinerary: boolean;
    /**
     * Customs Office Of Transits
     * @type {Array<CreateOrUpdateCustomsOfficeOfTransitRequest>}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    customsOfficeOfTransits?: Array<CreateOrUpdateCustomsOfficeOfTransitRequest>;
    /**
     * Expected date of arrival at destination
     * @type {Date}
     * @memberof CreateOrUpdateTransitOperationRequest
     */
    limitDate: string | null
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOrUpdateTransitOperationRequestDeclarationTypeEnum {
    T1 = 'T1',
    T2 = 'T2',
    TIR = 'TIR',
    T2F = 'T2F'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOrUpdateTransitOperationRequestSecurityEnum {
    S0 = '0', // MODIFIED TO APPLY WITH ESLINT _0 -> S0
    S1 = '1',
    S2 = '2',
    S3 = '3'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOrUpdateTransitOperationRequestAdditionalDeclarationTypeEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    Y = 'Y',
    Z = 'Z'
}

