import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import ROUTES from 'config/routes'
import { NestedFieldScope } from 'types/DeclarationP5'
import useCurrencyExchangeRate from 'routes/phase5/common/hooks/useCurrencyExchangeRate'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import useNctsProfile from '../../hooks/useNctsProfile'
import useGuaranteeCalculator from '../../hooks/useGuaranteeCalculator'
import GuaranteeCalculatorModal from '../../components/GuaranteeCalculator'

type GuaranteeItemScope = NestedFieldScope<`guarantees.${number}`>

function Guarantees() {
  const { t } = useTranslation()
  const { getValues, setValue } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const transitOperationId = getValues('id')
  const [isCalculationModalVisible, setCalculationModalVisible] = useState<boolean>(false)

  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])
  const { profilesAsOptions } = useNctsProfile()
  const { exchangeRate, exchangeRateLoading } = useCurrencyExchangeRate(country)

  const {
    openCalculation,
    calculatedItemsRows,
  } = useGuaranteeCalculator(getValues('houseConsignment'), isCalculationModalVisible, setCalculationModalVisible)

  let foreignCurrency: 'EUR' | 'RON' | 'BGN' = 'EUR'
  if (country === 'RO') {
    foreignCurrency = 'RON'
  } else if (country === 'BG') {
    foreignCurrency = 'BGN'
  }

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope="guarantees"
        columnId="declaration-guarantees"
        headingLabel={t('declaration.p5.guarantees')}
      >
        <FormField labelKey="declaration.nctsProfile">
          <FormSelect
            <GuaranteeItemScope>
            field="guarantees.0.profileName"
            labelKey="common.chooseProfile"
            options={profilesAsOptions}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.amountToBeCovered">
          <div className="input-group">
            <Input
              <GuaranteeItemScope>
              field="guarantees.0.amount"
              labelKey="declaration.p5.amountToBeCovered"
              min="0"
              step={0.001}
              type="number"
              placeholder={t('declaration.p5.amountToBeCovered')}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {t('goodsItem.goodsItemPrice', { context: 'unit' })}
              </span>
            </div>
          </div>
        </FormField>
        <FormField labelKey="">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-light btn-outline-gray ms-1 mb-1 text-black-50"
              onClick={() => openCalculation()}
            >
              <span>{t('guaranteeCalculator.pdf')}</span>
            </button>
          </div>
          <div className="d-flex justify-content-end">
            {
              country === 'EE' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.eeGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50  pointer mb-2"
                >
                  {t('declaration.eeGuarantee')}
                </small>
              )
            }
            {
              country === 'LV' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.lvGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50  pointer mb-2"
                >
                  {t('declaration.lvGuarantee')}
                </small>
              )
            }
            {
              country === 'LT' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.ltGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50  pointer mb-2"
                >
                  {t('declaration.ltGuarantee')}
                </small>
              )
            }
            {
              country === 'PL' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.plGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50 pointer"
                >
                  {t('declaration.plGuarantee')}
                </small>
              )
            }
          </div>
        </FormField>
      </CollapsibleColumn>
      <GuaranteeCalculatorModal
        transitOperationId={transitOperationId}
        rows={calculatedItemsRows}
        isCalculationModalVisible={isCalculationModalVisible}
        setCalculationModalVisible={setCalculationModalVisible}
        exchangeRate={exchangeRate}
        exchangeRateLoading={exchangeRateLoading}
        setValue={setValue}
        foreignCurrency={foreignCurrency}
      />
    </section>
  )
}

export default Guarantees
