import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { DirectoEvent } from '../../../types/Directo'

function postDirectoEvent(declarationId: number, directoEvent: DirectoEvent): Promise<DirectoEvent> {
  return axios.post(
    apiService.getFullApiUrl('/directo-event'),
    {
      ...directoEvent,
      declarationId,
    },
  )
    .then(handleResponseData)
    .catch(handleApiException)
}

function putDirectoEvent(declarationId: number, directoEvent: DirectoEvent): Promise<DirectoEvent> {
  return axios.put(
    apiService.getFullApiUrl(`/directo-event/${directoEvent.id}`),
    {
      ...directoEvent,
      declarationId,
    },
  )
    .then(handleResponseData)
    .catch(handleApiException)
}

function getDirectoEvent(declarationId: number): Promise<DirectoEvent> {
  return axios.get(apiService.getFullApiUrl(`/directo-event/${declarationId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

const DirectoService = {
  putDirectoEvent,
  postDirectoEvent,
  getDirectoEvent,
}

export default DirectoService
