import ROUTES from 'config/routes'
import useQueryStrings from 'hooks/useQueryStrings'
import { useObservableState } from 'observable-hooks'
import React, {
  Dispatch, SetStateAction,
  useContext, useEffect, useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import {
  useExpanded, usePagination, useTable,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import { toast } from 'react-toastify'
import { Observable } from 'rxjs'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import LoadingBackdrop from '../../../components/LoadingBackdrop'
import { dateFormatOptions } from '../../../config/dateFormatterConfig'
import { extractPageResponseContent } from '../../../helpers'
import AuthenticationService from '../../../services/auth.service'
import { DeclarationPageResponse } from '../../../types/DeclarationPageResponse'
import { IClassifier, ISelectOption } from '../../../types/IClassifier'
import { PageResponse, TableFilter } from '../../../types/PageResponse'
import DeclarationTableService from '../services/declaration-table.service'
import { getStatusOptions, getTableHeaders } from './config'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import { TablePagination } from './TablePagination'
import { UserContext } from '../../../context/UserContext'
import { NotificationContext } from '../../../context/NotificationContext'
import { UserPreference } from '../../../types/UserPreference'

interface TransitsTableParams {
  asideAction: (input: boolean, targetId: number) => void;
  modalAction: (input: boolean, targetId: number) => void;
  customsOffices: Array<IClassifier>;
  startDate: number | Date | undefined;
  endDate: number | Date | undefined;
  tablePreferences: Array<UserPreference>,
  commodityCodeFilter: string,
  statusFilter: string,
  setStatusFilter: Dispatch<SetStateAction<string>>
  filters: Array<TableFilter>
  setFilters: Dispatch<SetStateAction<Array<TableFilter>>>
}

function TransitsTable({
  asideAction,
  customsOffices,
  endDate,
  startDate,
  modalAction,
  tablePreferences,
  commodityCodeFilter,
  statusFilter,
  setStatusFilter,
  filters,
  setFilters,
}: TransitsTableParams) {
  const { t, i18n } = useTranslation()
  const { user } = useContext(UserContext)
  const { contextHeaderNotificationMessage } = useContext(NotificationContext)
  const toggleHeaderOrder = () => getTableHeaders(t, isMobile, user?.role === 'ADMIN', tablePreferences)
  const columns = React.useMemo(() => toggleHeaderOrder(), [isMobile, i18n.language, tablePreferences])
  const [data, setData] = useState<Array<DeclarationPageResponse>>([])
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(false)
  const [sortBy, setSortBy] = useState<string>('id')
  const [orderBy, setOrderBy] = useState<string>('DESC')
  const queryStrings = useQueryStrings()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // PAGINATION
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    useExpanded,
    useSticky,
    usePagination,
  )

  useEffect(() => {
    if (commodityCodeFilter === '') {
      setFilters((prevState) => (prevState.filter((item) => item.key !== 'commodityCode')))
    } else {
      setFilters((prevState) => ([
        ...prevState,
        { key: 'commodityCode', value: commodityCodeFilter },
      ]))
    }
  }, [commodityCodeFilter])

  const fetchPage = (requestEvent: { pageIndex: number, pageSize: number, currentFilters: Array<TableFilter>, currentStatusFilter: string,
    sortBy: string, orderBy: string }) => {
    AuthenticationService.eagerlyLogoutWhenTokenExpireDateImpending()
    // eslint-disable-next-line no-shadow
    const {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      pageIndex, currentFilters, currentStatusFilter, pageSize, sortBy, orderBy,
    } = requestEvent

    const table = document.getElementsByClassName('table__container')
    if (table[0] !== undefined && table[0] !== null && !loading) {
      table[0].scrollTop = 0
    }

    return DeclarationTableService.getDeclarationPage(
      pageIndex,
      pageSize,
      currentFilters,
      currentStatusFilter,
      user?.role === 'ADMIN',
      sortBy,
      orderBy,
    )
  }

  const [declarationPageResponse, loadPage] = useObservableState(
    (event$: Observable<{ pageIndex: number, pageSize: number, currentFilters: Array<TableFilter>, currentStatusFilter: string,
      sortBy: string, orderBy: string }>) => event$.pipe(
      debounceTime(200),
      tap(() => setLoading(true)),
      switchMap((requestEvent) => fetchPage(requestEvent)),
    ),
    () => ({} as PageResponse<DeclarationPageResponse>),
  )

  const statusOptions: ISelectOption[] = getStatusOptions(t)

  useEffect(() => {
    if (startDate && endDate) {
      const dateRangeStart = new Intl.DateTimeFormat('et', dateFormatOptions).format(startDate)
      const dateRangeEnd = new Intl.DateTimeFormat('et', dateFormatOptions).format(endDate)
      setFilters([
        ...filters.filter((filter) => filter.key !== 'dateRangeStart' && filter.key !== 'dateRangeEnd'),
        { key: 'dateRangeStart', value: dateRangeStart },
        { key: 'dateRangeEnd', value: dateRangeEnd },
      ])
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (localStorage.getItem('tardekSearch')) {
      setFilters(JSON.parse(localStorage.getItem('tardekSearch')!))
    }
  }, [])

  useEffect(() => {
    if ((localStorage.getItem('tardekSearch') !== null)) {
      const filterArray: Array<TableFilter> = []
      filters.forEach((filter) => {
        if (filter.key !== 'dateRangeStart' && filter.key !== 'dateRangeEnd') {
          filterArray.push(filter)
        }
      })
      localStorage.setItem('tardekSearch', JSON.stringify(filterArray))
    } else {
      localStorage.setItem('tardekSearch', JSON.stringify([]))
    }
  }, [filters])

  useEffect(() => {
    setData([])
    if (pageIndex === 0) {
      const parsedSearch = JSON.parse(localStorage.getItem('tardekSearch')!)
      const arrayOf = parsedSearch.find((cachedFilter: TableFilter) => cachedFilter.key === 'statusFilter')
      const isNotInCache = parsedSearch.find((filter: TableFilter) => filter.key === 'statusFilter') === undefined
      if (isNotInCache && statusFilter !== '') {
        parsedSearch.push({ key: 'statusFilter', value: statusFilter })
        localStorage.setItem('tardekSearch', JSON.stringify(parsedSearch))
      } else if (!isNotInCache) {
        if (statusFilter !== '' && statusFilter !== arrayOf.value) {
          setStatusFilter(statusFilter)
        } else {
          setStatusFilter(arrayOf.value)
        }
        const indexOfElement = parsedSearch.findIndex((filter: TableFilter) => filter.key === 'statusFilter')
        parsedSearch[indexOfElement].value = statusFilter
        localStorage.setItem('tardekSearch', JSON.stringify(parsedSearch))
      }
      parsedSearch.push({ key: 'dateRangeStart', value: new Intl.DateTimeFormat('et', dateFormatOptions).format(startDate) })
      parsedSearch.push({ key: 'dateRangeEnd', value: new Intl.DateTimeFormat('et', dateFormatOptions).format(endDate) })
      loadPage({
        pageIndex,
        pageSize,
        currentFilters: parsedSearch.length !== 0 ? parsedSearch : [],
        currentStatusFilter: arrayOf !== undefined ? arrayOf.value : statusFilter,
        sortBy,
        orderBy,
      })
    }

    gotoPage(0)
  }, [filters, statusFilter, sortBy, orderBy])

  useEffect(() => {
    setTotalPages(declarationPageResponse.totalPages)
    setData(extractPageResponseContent<DeclarationPageResponse>(declarationPageResponse, isMobile))
    setLoading(false)
  }, [declarationPageResponse])

  useEffect(() => {
    loadPage({
      pageIndex, pageSize, currentFilters: filters, currentStatusFilter: statusFilter, sortBy, orderBy,
    })
  }, [pageIndex, pageSize])

  useEffect(() => {
    if (queryStrings.has('MRN') || queryStrings.has('LRN')) {
      setFilters([{
        key: 'mrn',
        value: queryStrings.get('MRN') ?? queryStrings.get('LRN') ?? '',
      }])
    }

    if (queryStrings.has('openEuropa') && queryStrings.has('MRN')) {
      const route = `${ROUTES.external.europaTransitLookup}MRN=${queryStrings.get('MRN') ?? ''}#outer-container`
      const openedWindow: Window | null = window.open(route, '_blank')
      setTimeout(() => {
        if (openedWindow == null) {
          toast.warn(t('messages.popupsBlocked'))
        }
      }, 1000)
    }
  }, [queryStrings])

  return (
    <>
      <div
        className={`table__container table-responsive declaration-table__container ${isMobile ? 'not-in-mobile' : ''} ${contextHeaderNotificationMessage !== '' ? 'notification--open' : ''}`}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <table {...getTableProps()} className="table sticky table-hover fixed-columns mb-0">
          <TableHeader
            /* @ts-ignore */
            headerGroups={headerGroups}
            selectFilterOptions={statusOptions}
            selectFilterState={{
              selectFilter: statusFilter,
              setSelectFilter: setStatusFilter,
            }}
            sortState={{
              sortBy,
              setSortBy,
            }}
            filterState={{
              filters,
              setFilters,
            }}
            orderState={{
              orderBy,
              setOrderBy,
            }}
          />
          <TableBody
            modalAction={modalAction}
            isLoading={loading}
            rows={page}
            prepareRow={prepareRow}
            asideAction={asideAction}
            getTableBodyProps={getTableBodyProps}
            customsOffices={customsOffices}
            refreshFilters={() => setFilters([...filters])}
          />
          <LoadingBackdrop loading={loading} />
        </table>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </>
  )
}
export default TransitsTable

