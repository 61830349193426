import { useStore } from 'react-redux'
import { IDeclaration } from '../../../../types/IDeclaration'
import { IStoreState } from '../../../../redux/store'
import DeclarationInfoService from '../../services/declaration-info.service'
import { addDeclarationInfo } from '../../../../redux/actions'
import { DeclarationCountry } from '../../../../context/DeclarationContext'

function useDeclarationInfo(country: DeclarationCountry | null) {
  const store = useStore()

  const invalidateFinnishFields = () => {
    // @ts-ignore
    const state: IStoreState = store.getState()
    const { declarationInfo } = state.declarationInfoReducer
    if (country !== 'FI') {
      store.dispatch(addDeclarationInfo({
        ...declarationInfo,
        goodsLocation: null,
        goodsLocationQualifier: null,
        transitDateLimit: null,
        transitLoadingDate: null,
      }))
    }
    if (declarationInfo.goodsLocationQualifier !== 'L' && declarationInfo.transitDateLimit !== null) {
      store.dispatch(addDeclarationInfo({
        ...declarationInfo,
        transitDateLimit: null,
      }))
    }
  }

  const createOrEditDeclarationInfo = (savedDeclaration: IDeclaration, isDraft: boolean) => {
    // @ts-ignore
    const state: IStoreState = store.getState()
    if (savedDeclaration.id === null) {
      return Promise.reject(Error('Missing declaration id'))
    }
    invalidateFinnishFields()
    if (state.declarationInfoReducer.declarationInfo.id === null) {
      return DeclarationInfoService
        .postNewDeclarationInfo(savedDeclaration.id, state.declarationInfoReducer.declarationInfo, isDraft)
        .then(
          (response) => {
            store.dispatch(addDeclarationInfo(response))
          },
        )
    }
    return DeclarationInfoService
      .putDeclarationInfo(savedDeclaration.id, state.declarationInfoReducer.declarationInfo, isDraft)
      .then((response) => {
        store.dispatch(addDeclarationInfo(response))
      })
  }

  return { createOrEditDeclarationInfo }
}

export default useDeclarationInfo
