import { Modal } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../../../config/routes'
import { UseDeclarationFormReturn } from '../../form'

interface CreateNewModalProps {
  isVisible: boolean;
  toggleVisibility: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  form: UseDeclarationFormReturn
}

export default function CreateNewTransitModal(props: CreateNewModalProps) {
  const { isVisible, toggleVisibility } = props
  const history = useNavigate()
  const { t } = useTranslation()

  return (
    <Modal
      show={isVisible}
      dialogClassName="modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleVisibility()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{t('declaration.p5.createNew')}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>{t('declaration.p5.createNewWarning')}</p>
        <div className="d-flex justify-content-around mt-4">
          <button
            type="button"
            className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
            onClick={() => {
              toggleVisibility()
              history(ROUTES.newTransitP5)
              window.location.reload()
            }}
          >
            <span>{t('common.yes')}</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
