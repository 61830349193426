import axios, { AxiosError } from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { apiService, handleResponseData } from '../services/api.service'
import { IClassifier, ISelectOption } from '../types/IClassifier'
import { PageResponse } from '../types/PageResponse'
import { UserContext } from '../context/UserContext'

const maxLength = 8
const codeLength = 8
const maxNameLength = 75

export interface HsCodeSelectProps {
  code: string | null,
  setHsCode: (code: string | null, description: string | null) => void;
  // eslint-disable-next-line react/require-default-props
  setVisualHsCode?: (code: string | null) => void;
  isInvalid: boolean
  // eslint-disable-next-line react/require-default-props
  isDisabled?: boolean
  // eslint-disable-next-line react/require-default-props
  restrictionKeys?: Array<string>
  // eslint-disable-next-line react/require-default-props
  isSanction?: boolean,
  // eslint-disable-next-line react/require-default-props
  isUsingSharedNctsProfile?: boolean,
}

function HsCodeSelect(props: HsCodeSelectProps) {
  const {
    isDisabled, restrictionKeys, code, setHsCode, setVisualHsCode, isSanction, isInvalid, isUsingSharedNctsProfile,
  } = props
  const { i18n } = useTranslation()
  const [inputValueBuffer, setInputValueBuffer] = useState<string | undefined>(undefined)
  const [searchClassifiersCache, setSearchClassifiersCache] = useState<Array<IClassifier>>([])

  const { user } = useContext(UserContext)
  const searchByCode = (hsCode: string) => new Promise<Array<IClassifier>>((resolve, reject) => {
    axios.get(
      apiService.getFullApiUrl('/classifier/page'),
      {
        params: {
          code: hsCode !== '990500' ? hsCode.padEnd(8, '0') : hsCode,
          classifierGroup: 'COMBINED_NOMENCLATURE',
        },
      },
    ).then(handleResponseData).then((response: PageResponse<IClassifier>) => {
      resolve(response.content)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })

  const getName = (value: IClassifier) => {
    const name = i18n.language === 'ru' ? value.nameRu : value.nameEn
    return name || ''
  }

  const getCodeValue = () => new Promise<ISelectOption | null>((resolve, reject) => {
    if (code && code.length >= 4) {
      searchByCode(code).then((initialClassifiers) => {
        if (initialClassifiers.length < 1) {
          toast.error('Error loading HS-code')
          resolve(null)
        } else {
          const item = initialClassifiers[0]
          // eslint-disable-next-line max-len
          resolve({ value: item.code, label: `${item.isArchived === true ? 'Archived: ' : ' '}${item.codeLabel} - ${item.nameEn}` } as ISelectOption)
        }
      }).catch((error) => reject(error))
    } else if (code && code.length > 1) {
      toast.error(`Error loading HS-code ${code}`, { autoClose: false })
      resolve(null)
    }
  })

  const [valueBuffer, setValueBuffer] = useState<ISelectOption | null>(null)

  useEffect(() => {
    let description = ''
    if (valueBuffer?.value) {
      const selectedClassifier = searchClassifiersCache.find((classifier) => classifier.code === valueBuffer?.value)
      if (selectedClassifier) {
        description = getName(selectedClassifier).substr(0, 280)
      }
    }
    if (isSanction) {
      setHsCode(valueBuffer?.label.split('-')[0].trim().replace(/\s/g, '') ?? valueBuffer?.value, description)
    } else {
      if (setVisualHsCode) {
        setVisualHsCode(valueBuffer?.label.split('-')[0] ?? '-')
      }
      setHsCode(valueBuffer?.value, description)
    }
  }, [valueBuffer, isSanction])

  useEffect(() => {
    if (((valueBuffer && valueBuffer.value !== code) || !valueBuffer)) {
      if (code) {
        getCodeValue().then((value) => setValueBuffer(value))
      } else {
        setValueBuffer(null)
      }
    }
  }, [code])

  useEffect(() => {
    getCodeValue().then((value) => setValueBuffer(value))
  }, [])

  useEffect(() => {
    if (((valueBuffer && valueBuffer.value !== code) || !valueBuffer)) {
      if (code) {
        getCodeValue().then((value) => setValueBuffer(value))
      } else {
        setValueBuffer(null)
      }
    }
  }, [code])

  const searchForOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolve, reject) => {
    if (isDisabled) {
      return
    }
    axios.get(
      apiService.getFullApiUrl('/classifier/page'),
      {
        params: {
          codeLabel: inputValue,
          classifierGroup: 'COMBINED_NOMENCLATURE',
        },
      },
    ).then(handleResponseData).then((response) => {
      let content = [...response.content]
      if (user?.role !== 'ADMIN') {
        if (restrictionKeys !== undefined && isUsingSharedNctsProfile && restrictionKeys.length > 0) {
          content = content.filter((item) => restrictionKeys.indexOf(item.code) === -1)
        }

        content = content.filter((itemCode) => itemCode.codeLabel.replace(/\s/g, '').length !== 4)
      }

      setSearchClassifiersCache([...searchClassifiersCache.filter((item) => item.isArchived === true), ...content])
      resolve(content.map((item: IClassifier) => ({
        value: item.code,
        label: `${item.codeLabel} - ${getName(item)
          .slice(0, maxNameLength)}${getName(item).length > maxNameLength ? '…' : ''}`,
      })))
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })

  const promiseOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolveQuery) => {
    if (inputValue === '' || inputValue === null || inputValue.trim().length < 4 || inputValue.trim().length > maxLength) {
      resolveQuery([])
      return
    }
    searchForOptions(inputValue).then((value) => {
      resolveQuery(value)
    })
  })

  const isHsCodeTierOne = () => {
    if (user?.role !== 'ADMIN' && valueBuffer && valueBuffer.label.split('-') !== undefined) {
      return valueBuffer.label.split('-')[0].trim().length === 4
    }

    return false
  }

  return (
    <AsyncSelect
      isDisabled={isDisabled}
      isClearable
      isSearchable
      cacheOptions
      defaultOptions={valueBuffer ? [valueBuffer] : []}
      noOptionsMessage={() => 'Type at least 4 numbers to start search'}
      menuPlacement="auto"
      loadOptions={promiseOptions}
      className={`select menu-list--lg mb-1 ${isInvalid || isHsCodeTierOne() ? 'invalid' : ''}`}
      classNamePrefix="select"
      value={valueBuffer}
      inputValue={inputValueBuffer}
      placeholder="Search for HS-code"
      onInputChange={(newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
          setInputValueBuffer(newValue.substring(0, codeLength))
        } else {
          setInputValueBuffer(undefined)
        }
      }}
      onChange={(option: ISelectOption | null) => setValueBuffer(option)}
    />
  )
}

export default HsCodeSelect
