import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { ActionMeta } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../types/IClassifier'
import { IConsignmentDetail } from '../types/IConsignmentDetail'
import { apiService, handleResponseData } from '../services/api.service'
import { InvalidField } from '../routes/Transits/TransitsAside/detailView'
import { getInvalidIconData } from '../routes/Transits/TransitsAside/invalid-icon.helper'
import TransitField from '../routes/Transits/TransitsAside/transitFields'

interface IConsignmentSelect {
  type: 'CONSIGNOR' | 'CONSIGNEE' | 'CARRIER'
  selectForm: IConsignmentDetail
  setSelectForm: (form: IConsignmentDetail) => void
  invalidFields: Array<InvalidField>
  // eslint-disable-next-line react/require-default-props
  isDisabled?: boolean
  traderRestrictions: Array<String>
  setInvalidFields: Dispatch<SetStateAction<Array<InvalidField>>>
}

const nameMaxLength = 35

function ConsignmentSelect(props: IConsignmentSelect) {
  const { t } = useTranslation()
  const {
    isDisabled, type, selectForm, setSelectForm, setInvalidFields, invalidFields, traderRestrictions,
  } = props

  let label: string
  switch (type) {
    case 'CARRIER':
      label = t('declaration.carrier')
      break
    case 'CONSIGNOR':
      label = t('declaration.consignor')
      break
    case 'CONSIGNEE':
      label = t('declaration.consignee')
      break
    default:
      label = '-'
  }
  const [nameBuffer, setNameBuffer] = useState(selectForm.name)
  useEffect(() => {
    if (selectForm && selectForm.name.length > nameMaxLength) {
      toast.error(`${label} ${t('messages.maxLength')} ${nameMaxLength}`)
    }
  }, [nameBuffer])

  useEffect(() => {
    if (selectForm.name !== nameBuffer) {
      setNameBuffer(selectForm.name)
    }

    if (traderRestrictions.filter((restrictions) => selectForm.name.toLowerCase()
      .includes(restrictions.toLowerCase())).length > 0) {
      if (type === 'CONSIGNEE') {
        setInvalidFields((prevValues: InvalidField[]) => [...prevValues,
          { reason: '-111', pointer: TransitField.CONSIGNEE_TRADER_NAME }])
      } else if (type === 'CONSIGNOR') {
        setInvalidFields((prevValues: InvalidField[]) => [...prevValues,
          { reason: '-111', pointer: TransitField.CONSIGNOR_TRADER_NAME }])
      } else if (type === 'CARRIER') {
        setInvalidFields((prevValues: InvalidField[]) => [...prevValues,
          { reason: '-111', pointer: TransitField.CARRIER_TRADER_NAME }])
      }
    } else if (type === 'CONSIGNEE') {
      setInvalidFields(invalidFields.filter((fields) => fields.pointer !== TransitField.CONSIGNEE_TRADER_NAME))
    } else if (type === 'CONSIGNOR') {
      setInvalidFields(invalidFields.filter((fields) => fields.pointer !== TransitField.CONSIGNOR_TRADER_NAME))
    } else if (type === 'CARRIER') {
      setInvalidFields(invalidFields.filter((fields) => fields.pointer !== TransitField.CARRIER_TRADER_NAME))
    }
  }, [selectForm.name])

  const searchForOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolve, reject) => {
    axios.get(
      apiService.getFullApiUrl('/consignment-detail'),
      {
        params: {
          name: inputValue,
        },
      },
    ).then(handleResponseData).then((consignmentDetails) => {
      resolve(consignmentDetails.map((item: IConsignmentDetail) => ({
        value: item,
        label: `${item.name}`,
      })))
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })

  const promiseOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolveQuery) => {
    if (inputValue === '' || inputValue === null || inputValue.trim().length < 2 || inputValue.trim().length > nameMaxLength) {
      resolveQuery([])
      return
    }
    searchForOptions(inputValue).then((value) => {
      resolveQuery(value)
    })
  })

  return (
    <AsyncCreatableSelect
      isDisabled={isDisabled}
      isClearable
      placeholder={`${label} ${t('common.name')}`}
      allowCreateWhileLoading={false}
      className={`select ${
        getInvalidIconData(
          // eslint-disable-next-line no-nested-ternary
          type === 'CONSIGNOR' ? [
            TransitField.CONSIGNOR_TRADER_NAME,
            TransitField.CONSIGNOR_SECURITY_TRADER_NAME,
          ]
            : (
              (type === 'CONSIGNEE') ? [
                TransitField.CONSIGNEE_TRADER_NAME,
                TransitField.CONSIGNEE_SECURITY_TRADER_NAME,
              ] : [
                TransitField.CARRIER_TRADER_NAME,
              ]
            ),
          invalidFields,
        ).isInvalid ? 'form-control p-0 pe-3 is-invalid' : ''
      }`}
      classNamePrefix="select"
      loadingMessage={(obj) => {
        if (obj.inputValue.length < nameMaxLength) {
          return t('common.typeToSearch', { 0: label.toLowerCase() })
        }
        return t('common.loading')
      }}
      noOptionsMessage={
        (obj) => {
          if (obj.inputValue.length < 2) {
            return t('common.typeToSearch', { 0: label.toLowerCase() })
          }
          return null
        }
      }
      cacheOptions
      inputValue={selectForm.name.substring(0, nameMaxLength)}
      value={selectForm.name !== ''
        ? { value: selectForm.name, label: selectForm.name } as ISelectOption : null}
      loadOptions={promiseOptions}
      onInputChange={(newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
          setNameBuffer(newValue.substring(0, nameMaxLength))
          setSelectForm({
            ...selectForm,
            name: newValue,
          } as IConsignmentDetail)
        }
        if (actionMeta.action === 'input-blur') {
          setSelectForm({
            ...selectForm,
            name: nameBuffer,
          } as IConsignmentDetail)
        }
      }}
      onChange={(option: ISelectOption | null, action: ActionMeta<ISelectOption>) => {
        if (option) {
          const selectOption = (option)
          if (action.action === 'select-option') {
            const value = selectOption.value as IConsignmentDetail
            if (value) {
              setNameBuffer(value.name.substring(0, nameMaxLength))
              setSelectForm({
                ...selectForm,
                name: value.name.substring(0, nameMaxLength),
                street: value.street,
                city: value.city,
                country: value.country,
                zip: value.zip,
                eori: value.eori,
              } as IConsignmentDetail)
            }
          } else {
            setNameBuffer(option.value.substring(0, nameMaxLength))
            setSelectForm({
              ...selectForm,
              name: option.value,
            } as IConsignmentDetail)
          }
        } else {
          setNameBuffer('')
          setSelectForm({
            ...selectForm,
            name: '',
          } as IConsignmentDetail)
        }
      }}
    />
  )
}

export default ConsignmentSelect

