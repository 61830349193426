import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { CustomsOfficeOfTransitDeclared } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'

import { blankCustomsOfficeOfTransitDeclared } from '../../form'

const expectedScope = /transitCustomsOffices\.\d+/g
type CustomsOfficeOfTransitItemScope = NestedFieldScope<`transitCustomsOffices.${number}`>
const isCustomsOfficeOfTransitItemScope = (scope: ObjectScope):
  scope is `transitCustomsOffices.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function CustomsOfficeOfTransit({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<CustomsOfficeOfTransitDeclared>({
    blankItem: blankCustomsOfficeOfTransitDeclared,
    name: scope,
  })
  const [, transitCustomsOfficesOptions] = useCodelist('TRANSIT_CUSTOMS_OFFICES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-offices-of-transit"
        headingLabel={t('declaration.p5.customsOfficeOfTransit')}
      >
        {getFields().map((transitCustomsOffices, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isCustomsOfficeOfTransitItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`transitCustomsOffice_${transitCustomsOffices.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, transitCustomsOffices)}
              sequenceNumber={transitCustomsOffices.sequenceNumber + 1}
              hidden={transitCustomsOffices.deleted}
            >
              <FormField labelKey="declaration.p5.referenceNumber">
                <FormSelect
                  <CustomsOfficeOfTransitItemScope>
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.referenceNumber"
                  options={transitCustomsOfficesOptions}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.arrivalDateAndTime">
                <Input
                  <CustomsOfficeOfTransitItemScope>
                  type="datetime-local"
                  step={1}
                  field={`${innerItemScope}.arrivalDateAndTime`}
                  labelKey="declaration.p5.arrivalDateAndTime"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewCustomsOfficeOfTransit')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default CustomsOfficeOfTransit
