import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { CustomsOfficeOfTransitResponse } from '../../../common/models'
import TransitApiConfig from '../apiConfig'

const {
  baseUrl,
} = TransitApiConfig.paths.customsOfficeOfTransit

function useCustomsOfficeOfTransitApi() {
  const deleteCustomsOfficeOfTransit = useMutation({
    mutationFn: async (id: number) => (await axios.delete<CustomsOfficeOfTransitResponse>(`${baseUrl}/${id}`)).data,
  })

  return {
    deleteCustomsOfficeOfTransit,
  }
}

export default useCustomsOfficeOfTransitApi
