import React from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope } from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import EoriSearch from '../../components/EoriSearch'
import CarrierSearch from '../../components/CarrierSearch'

const scope = 'carrier'
type ConsignmentCarrier = NestedFieldScope<typeof scope>

function Carrier() {
  const { t } = useTranslation()

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-carrier"
        headingLabel={t('declaration.p5.carrier')}
      >
        <FormField labelKey="declaration.p5.carrierIdentificationNr">
          <EoriSearch scope={scope} />
        </FormField>
        <FormField scope={`${scope}.carrierName`} labelKey="declaration.p5.carrierName">
          <CarrierSearch />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default Carrier
