import React, {
  ReactElement, useCallback, useContext, useEffect, useState,
} from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select, { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import PdfPreviewModal from '../../../components/PdfPreviewModal'
import { DeclarationCountry } from '../../../context/DeclarationContext'
import { UserContext } from '../../../context/UserContext'
import { DeclarationAudit } from '../../../types/DeclarationAudit'
import { DeclarationPageResponse } from '../../../types/DeclarationPageResponse'
import { EventLog, EventLogRow } from '../../../types/EventLog'
import { IClassifier, ISelectOption } from '../../../types/IClassifier'
import { DeclarationStatusEnum } from '../../../types/IDeclaration'
import DeclarationService from '../../Declaration/services/declaration.service'
import EventLogService from '../../Declaration/services/event-log.service'
import { getAuditStatusOptions } from '../../DeclarationAudit/config'
import TableRowStatus from '../Table/TableRowStatus'
// eslint-disable-next-line import/no-cycle
import { DetailView } from './detailView'
import { LogView, mapToRows } from './logView'
import ConfirmationModal from '../../../components/ConfirmationModal'

interface TransitsDetailViewProps {
  isOpen: boolean
  asideAction(input: boolean, declarationId?: number): void
  editDeclaration: (isPhase5: boolean) => void
  asideDeclarationId: number
  countries: Array<IClassifier>
  customsOffices: Array<IClassifier>
  packageTypes: Array<IClassifier>
  documentTypes: Array<IClassifier>
  previousDocumentTypes: Array<IClassifier>
  procedureTypes: Array<IClassifier>
  // eslint-disable-next-line react/require-default-props
  originalRow?: DeclarationPageResponse | DeclarationAudit | undefined
  // eslint-disable-next-line react/require-default-props
  declarationAuditReason?: (value: string) => void
  // eslint-disable-next-line react/require-default-props
  declarationAuditStatus?: (value: ISelectOption) => void
  // eslint-disable-next-line react/require-default-props
  submitAudit?: () => void
  // eslint-disable-next-line react/require-default-props
  isLoading?: boolean
  isViewMode: boolean
  // eslint-disable-next-line react/require-default-props
  parentPage?: 'TRANSIT' | 'AUDIT'
  // eslint-disable-next-line react/require-default-props
  triggerParentPageRefresh?: () => void
}
export interface DeclarationMetaInfoProps {
  name: string
  mrn: string
  lrn: string
  status: DeclarationStatusEnum | null
  reason: string
  declarationCountry: DeclarationCountry | null
  isPhase5: boolean
}

export function TransitsAside(props: TransitsDetailViewProps): ReactElement {
  const { t } = useTranslation()

  const [isLogOpen, toggleLogView] = useState(false)
  const [isFirstRowExpanded, setFirstRowExpanded] = useState(false)
  const [eventLogs, setEventLogs] = useState<Array<EventLogRow>>([])
  const [invalidFieldsEvent, setInvalidFieldsEvent] = useState<EventLog>()
  const [isPdfPreviewModalVisible, setPdfPreviewModalVisible] = useState(false)
  const { user } = useContext(UserContext)
  const [declinationReason, setDeclinationReason] = useState<string>('')
  const [auditStatus, setAuditStatus] = useState<SingleValue<ISelectOption> | null>({
    value: '',
    label: '',
  })
  const [isDeclinationModalOpen, setIsDeclinationModalOpen] = useState<boolean>(false)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false)
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false)
  const [declarationMetaInfo, setDeclarationMetaInfo] = useState<DeclarationMetaInfoProps>({
    status: null,
    name: '',
    lrn: '',
    mrn: '',
    declarationCountry: null,
    reason: '',
    isPhase5: false,
  })

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  const {
    asideAction, editDeclaration, isOpen, asideDeclarationId,
    countries, customsOffices, packageTypes, documentTypes, previousDocumentTypes, procedureTypes, originalRow,
    isViewMode, declarationAuditReason, declarationAuditStatus, submitAudit, parentPage, isLoading, triggerParentPageRefresh,
  } = props
  const [isReviewed, setIsReviewed] = useState(originalRow?.isReviewed)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isStatusLoading, setIsStatusLoading] = useState(false)

  const toggleConfirmationVisibility = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen)
  }

  if (!isOpen && isLogOpen) {
    toggleLogView(false)
  }

  const changeDeclarationStatus = () => {
    if (asideDeclarationId && originalRow) {
      setIsConfirmationModalOpen(!isConfirmationModalOpen)
    }
  }

  const submitStatusChange = () => {
    if (asideDeclarationId && originalRow) {
      setIsStatusLoading(true)
      DeclarationService.changeDeclarationStatus(asideDeclarationId).then((response) => {
        setDeclarationMetaInfo({ ...declarationMetaInfo, status: response.status })
        if (triggerParentPageRefresh !== undefined) {
          triggerParentPageRefresh()
        }
        originalRow.status = response.status
        toggleConfirmationVisibility()
        toast.success(t('messages.savingSuccess'))
        setIsStatusLoading(false)
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        toast.error(t('messages.savingFailed'))
        setIsStatusLoading(false)
        toggleConfirmationVisibility()
      })
    }
  }

  const sortNewestToFront = (a: EventLog, b: EventLog) => {
    const createdTimeA = new Date(a.created).getTime()
    const createdTimeB = new Date(b.created).getTime()

    // eslint-disable-next-line no-nested-ternary
    return createdTimeA < createdTimeB ? 1 : (createdTimeA > createdTimeB ? -1 : 0)
  }
  const toggleDeclarationReview = () => {
    if (asideDeclarationId && originalRow) {
      DeclarationService.changeDeclarationReviewStatus(asideDeclarationId, !originalRow.isReviewed)
        .then((result) => {
          originalRow.isReviewed = result.isReviewed
          originalRow.dateReviewed = result.dateReviewed
          originalRow.reviewedBy = result.reviewedBy
          setIsReviewed(result.isReviewed)
          if (triggerParentPageRefresh !== undefined) {
            triggerParentPageRefresh()
          }
        })
    }
  }

  const alertUser = (e: Event) => {
    e.preventDefault()
  }

  useEffect(() => {
    if (parentPage === 'AUDIT' && isLoading) {
      window.addEventListener('beforeunload', alertUser)
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [parentPage, isLoading])

  const changeAudit = () => {
    if ((auditStatus?.value === 'DECLINED' || auditStatus?.value === 'PROHIBITED_DESCRIPTION') && declinationReason.length === 0) {
      setIsDeclinationModalOpen(true)
    } else if (submitAudit) {
      submitAudit()
      setIsDeclinationModalOpen(false)
    }
  }
  const handleAuditReason = (reason: string) => {
    setDeclinationReason(reason)
    if (declarationAuditReason) {
      declarationAuditReason(reason)
    }
  }

  const handleChange = (option: ISelectOption | null) => {
    setAuditStatus(option)
    if (declarationAuditStatus && option !== null) {
      declarationAuditStatus(option)
    }
  }

  function isEventWithFunctionalErrors(event: EventLog) {
    const invalidContexts = ['IE016', 'IE005']
    return invalidContexts.indexOf(event.messageContext) !== -1
  }

  const escapeCloseAsideWindow = useCallback((event: { keyCode: number }) => {
    if (event.keyCode === 27) {
      asideAction(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escapeCloseAsideWindow, false)
    return () => document.removeEventListener('keydown', escapeCloseAsideWindow, false)
  }, [])

  useEffect(() => {
    setIsReviewed(originalRow?.isReviewed)
    EventLogService.getAllEventLogs(asideDeclarationId).then((logs) => {
      logs.sort(sortNewestToFront)
      const eventLog = logs[0]
      if (eventLog && isEventWithFunctionalErrors(eventLog)) {
        setInvalidFieldsEvent(eventLog)
      } else {
        setInvalidFieldsEvent(undefined)
      }
      return setEventLogs(mapToRows(logs))
    })
  }, [asideDeclarationId])

  function currentView() {
    if (isLogOpen) {
      return <LogView rows={eventLogs} isFirstRowExpanded={isFirstRowExpanded} />
    }
    return (
      <DetailView
        declarationId={asideDeclarationId}
        updateDeclarationMetaInfo={setDeclarationMetaInfo}
        countries={countries}
        customsOffices={customsOffices}
        packageTypes={packageTypes}
        documentTypes={documentTypes}
        previousDocumentTypes={previousDocumentTypes}
        declarationReason={declarationMetaInfo.reason}
        procedureTypes={procedureTypes}
        invalidFieldsEvent={invalidFieldsEvent}
        openLogView={() => {
          setFirstRowExpanded(true)
          return toggleLogView(!isLogOpen)
        }}
        isFeedbackModalOpen={isFeedbackOpen}
        setIsFeedbackModalOpen={setIsFeedbackOpen}
        isNotesModalOpen={isNotesOpen}
        setIsNotesModalOpen={setIsNotesOpen}
        originalRow={originalRow}
      />
    )
  }

  const openPdfModal = () => {
    setPdfPreviewModalVisible(true)
  }

  return (
    <aside className={isOpen ? 'active' : ''}>
      <div className="aside__header">
        <div className="d-flex justify-content-center align-items-end">
          {isLogOpen ? (
            <>
              <h1 className="text-dark text-capitalize pe-2 border-dark border-end ms-2 lh-1">{t('common.log')}</h1>
              <h1 className="text-dark text-capitalize lh-1">{declarationMetaInfo.name}</h1>
            </>
          )
            : (
              <>
                <h1 className="text-dark me-3">{declarationMetaInfo.name}</h1>
                <TableRowStatus value={declarationMetaInfo.status != null ? declarationMetaInfo.status.toString() : '-'} />
                <div className="d-flex ms-2 ps-1 flex-column">
                  {
                    declarationMetaInfo.mrn && (
                      <small>{declarationMetaInfo.mrn}</small>
                    )
                  }
                  {
                    declarationMetaInfo.lrn && (
                      <small>{declarationMetaInfo.lrn}</small>
                    )
                  }
                </div>
              </>
            )}
        </div>

        <div className="d-none d-md-flex justify-content-center align-items-center">
          {isLogOpen ? '' : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!isViewMode && (
                <button
                  type="button"
                  className="btn btn-light btn-lg d-flex justify-content-center align-items-center me-3 rounded-circle wh-34"
                  onClick={openPdfModal}
                >
                  <i className="fal fa-print" />
                </button>
              )}
            </>

          )}

          {(declarationMetaInfo.status === DeclarationStatusEnum.IN_REVIEW && user?.role === 'ADMIN' && parentPage === 'AUDIT') && (
            <>
              <button
                type="button"
                className={`${isLogOpen ? 'me-4' : 'me-3'} btn btn-light btn-lg `}
                onClick={() => {
                  changeAudit()
                }}
                disabled={isLoading}
                style={{ color: !isReviewed ? 'green' : 'red', borderColor: !isReviewed ? 'green' : 'red' }}
              >
                {
                  isLoading && loadingSpinner()
                }
                {!isLoading && <span>{t('buttons.decideAudit')}</span>}
              </button>
              <div className="d-flex px-2" style={{ width: '10rem' }}>
                <Select
                  className="w-100"
                  classNamePrefix="select"
                  isDisabled={isLoading}
                  value={auditStatus?.value === '' ? { label: 'In Review', value: 'IN_REVIEW' } : auditStatus}
                  options={getAuditStatusOptions()}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </>
          )}

          <button
            type="button"
            className={`${isLogOpen ? 'me-4' : 'me-3'} btn btn-outline-dark btn-lg`}
            onClick={() => {
              setFirstRowExpanded(false)
              return toggleLogView(!isLogOpen)
            }}
          >
            <i className={`${isLogOpen ? 'fa-times' : 'fa-align-right'} fal me-2`} />
            <span>
              {isLogOpen ? t('buttons.close') : t('buttons.viewLogs')}
            </span>
          </button>

          {(!isLogOpen && !isViewMode) || parentPage === 'AUDIT' ? (
            <button
              type="button"
              className="btn btn-dark btn-lg me-4 text-primary"
              onClick={(() => editDeclaration(declarationMetaInfo.isPhase5))}
            >
              <i className={`fal me-2 ${declarationMetaInfo.status === DeclarationStatusEnum.T1_TRANSIT_CLOSED ? 'fa-eye' : 'fa-pen'}`} />
              <span>
                {
                  // eslint-disable-next-line no-nested-ternary
                  user?.role === 'ADMIN' && declarationMetaInfo.status !== 'T1_TRANSIT_CLOSED' ? t('common.edit')
                    : ((declarationMetaInfo.status === 'DRAFT'
                      || declarationMetaInfo.status === 'REJECTED')
                      ? t('common.edit') : t('common.view'))
                }
              </span>
            </button>
          ) : ''}
          {user?.role === 'ADMIN' && !isLogOpen && (

            <div className="me-3">
              <Dropdown>
                <Dropdown.Toggle variant="link" className="p-0 hide-caret" id="dropdown-basic">
                  <i className="fas fa-ellipsis-h fa-md" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm">
                  {
                    (user?.role === 'ADMIN' && ((!isViewMode && !isLogOpen) || parentPage === 'AUDIT')) && (
                      <Dropdown.Item
                        type="button"
                        className="btn-lg"
                        onClick={() => {
                          toggleDeclarationReview()
                        }}
                        style={{
                          color: !isReviewed ? 'green' : 'red',
                          borderColor: !isReviewed ? 'green' : 'red',
                        }}
                      >
                        <i className={`${!isReviewed ? 'fa-check' : 'fa-times'} fas me-1`} />
                        <span>{t('buttons.review', { context: !isReviewed ? 'approved' : 'decline' })}</span>
                      </Dropdown.Item>
                    )
                  }
                  {user?.role === 'ADMIN' && !isViewMode && !isLogOpen && (
                    <Dropdown.Item
                      className="btn-lg"
                      style={{ color: '#1a1b1e' }}
                      onClick={() => {
                        setIsNotesOpen(true)
                      }}
                    >
                      <i className="fal fa-envelope me-2" />
                      <span>{t('buttons.notes')}</span>
                    </Dropdown.Item>
                  )}
                  {user?.role === 'ADMIN'
                    && !isViewMode
                    && !isLogOpen
                    && originalRow?.status !== DeclarationStatusEnum.T1_TRANSIT_CLOSED && (
                    <Dropdown.Item
                      className="btn-lg"
                      onClick={() => changeDeclarationStatus()}
                    >
                      <i className="fal fa-check me-2" />
                      <span>{t('buttons.t1Closed')}</span>
                    </Dropdown.Item>
                  )}
                  {
                    (user?.role === 'ADMIN' && !isViewMode && !isLogOpen) && (
                      <Dropdown.Item
                        className="btn-lg"
                        onClick={() => setIsFeedbackOpen(true)}
                      >
                        <i className="fal fa-info me-2" />
                        <span>
                          {t('buttons.feedback')}
                        </span>
                      </Dropdown.Item>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <button
            type="button"
            className="btn btn-link btn-lg no-underline d-flex justify-content-center align-items-center px-0"
            disabled={parentPage === 'AUDIT' && isLoading}
            onClick={() => asideAction(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </div>

        <div className="d-flex d-md-none align-items-center">
          {isLogOpen
            ? (
              <button
                type="button"
                className="btn btn-sm btn-outline-dark text-uppercase me-1 me-sm-4"
                onClick={() => {
                  setFirstRowExpanded(false)
                  return toggleLogView(false)
                }}
              >
                {t('buttons.close')}
              </button>
            )
            : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  className="btn-lg no-underline d-flex justify-content-center align-items-center rounded-circle wh-34 hide-caret"
                  id="dropdown-basic"
                >
                  <i className="fal fa-ellipsis-v fa-2x" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm">
                  <Dropdown.Item
                    onClick={openPdfModal}
                  >
                    <i className="fal fa-print me-2" />
                    <span>
                      Print
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFirstRowExpanded(false)
                      return toggleLogView(true)
                    }}
                  >
                    <i className="fa-align-right fal me-2" />
                    <span>{t('buttons.viewLogs')}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(() => editDeclaration(declarationMetaInfo.isPhase5))}
                  >
                    <i className="fal fa-pen me-2" />
                    <span>{t('common.edit')}</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

          <button
            type="button"
            className="btn btn-link btn-lg no-underline d-flex justify-content-center align-items-center px-0"
            onClick={() => asideAction(false)}
          >
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </div>
      </div>
      {currentView()}
      <PdfPreviewModal
        isPdfPreviewModalVisible={isPdfPreviewModalVisible}
        setPdfPreviewModalVisible={setPdfPreviewModalVisible}
        declarationId={asideDeclarationId}
        isPhase5={false}
      />
      {isDeclinationModalOpen && (
        <Modal
          show={isDeclinationModalOpen}
          dialogClassName="modal"
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setIsDeclinationModalOpen(false)}
          animation={false}
          centered
        >
          <Modal.Header>
            <Modal.Title className="d-flex align-self-center">
              <h2>{t('audit.declinationReason')}</h2>
            </Modal.Title>
            <button
              type="button"
              className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
              onClick={() => setIsDeclinationModalOpen(false)}
            >
              <span>{t('buttons.close')}</span>
              <i className="fal fa-times fa-2x ms-2" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column align-self-center mt-4">
              <h2 className="mb-2">{t('audit.reasonForDecline')}</h2>
              <textarea
                className="w-100 form-control-lg mb-2"
                onChange={(e) => handleAuditReason(e.target.value)}
              />
              <button
                type="button"
                disabled={isLoading}
                className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
                onClick={() => changeAudit()}
              >
                {
                  isLoading && loadingSpinner()
                }
                {
                  !isLoading && (<span>{t('audit.submitAudit')}</span>)
                }
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <ConfirmationModal
        title={t('messages.declarationStatus')}
        messageBody={t('messages.declarationStatusChange')}
        isVisible={isConfirmationModalOpen}
        toggleVisibility={toggleConfirmationVisibility}
        isLoading={isStatusLoading}
        onConfirmation={() => submitStatusChange()}
        onRejection={() => {
          toggleConfirmationVisibility()
        }}
      />
    </aside>
  )
}
