import {
  ConsignmentDetailRequest,
  ConsignmentDetailRequestTargetEnum,
  ConsignmentDetailResponse,
  CreateOrUpdateContactPersonRequest,
} from '../../../common/models'
import { Consignee, Consignor, TraderTargetEnum } from '../../form/schemas/tradersSchema'
import { ContactPerson } from '../../form/schemas/commonConsignmentSchemas'
import { hasText, isConsignor } from '../../../common/utils/common-util'

export function toCreateOrUpdateContactPersonRequest(
  contact: ContactPerson | null,
  transitOperationId: number,
): CreateOrUpdateContactPersonRequest | null {
  if (contact === null) {
    return null
  }
  if (!hasText(contact.name) && !hasText(contact.email) && !hasText(contact.telephone)) {
    return null
  }

  return {
    id: contact.id,
    name: contact.name,
    email: contact.email,
    telephone: contact.telephone,
    transitOperationId,
  }
}

export function toConsignmentDetailRequest(
  trader: Consignor | Consignee,
  transitOperationId: number,
  id: number | null | undefined,
): ConsignmentDetailRequest {
  return {
    id: id ?? undefined,
    transitOperationId,
    name: trader.name,
    target: ConsignmentDetailRequestTargetEnum[trader.target],
    address: trader.address !== null ? {
      id: trader.address.id,
      city: trader.address.city,
      country: trader.address.country,
      postcode: trader.address.postcode,
      street: trader.address.streetAndNumber,
      transitOperationId,
    } : null,
    contactPerson: isConsignor(trader) ? toCreateOrUpdateContactPersonRequest(trader.contactPerson, transitOperationId) : undefined,
    identificationNumber: trader.identificationNumber,
  }
}

export function parseConsigneeConsignmentDetailResponse(response?: ConsignmentDetailResponse | null): Consignee {
  return {
    address: {
      city: response?.address?.city ?? '',
      country: response?.address?.country ?? '',
      id: response?.address?.id ?? null,
      postcode: response?.address?.postcode ?? '',
      streetAndNumber: response?.address?.street ?? '',
    },
    id: response?.id ?? null,
    identificationNumber: response?.identificationNumber ?? '',
    name: response?.name ?? '',
    target: response ? TraderTargetEnum.parse(response?.target) : 'CONSIGNEE',
    deleted: false,
  }
}

export function parseConsignorConsignmentDetailResponse(response?: ConsignmentDetailResponse | null): Consignor {
  return {
    address: {
      id: response?.address?.id ?? null,
      city: response?.address?.city ?? '',
      country: response?.address?.country ?? '',
      postcode: response?.address?.postcode ?? '',
      streetAndNumber: response?.address?.street ?? '',
    },
    contactPerson: {
      deleted: false,
      email: response?.contactPerson?.email ?? '',
      id: response?.contactPerson?.id ?? null,
      name: response?.contactPerson?.name ?? '',
      telephone: response?.contactPerson?.telephone ?? '',
    },
    id: response?.id ?? null,
    identificationNumber: response?.identificationNumber ?? '',
    name: response?.name ?? '',
    target: response ? TraderTargetEnum.parse(response?.target) : 'CONSIGNOR',
    deleted: false,
  }
}
