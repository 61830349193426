import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { AdditionalInformation } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'

import { blankAdditionalInformation } from '../../form'

const expectedScope = /additionalInformation\.\d+/g
type AdditionalInformationItemScope = NestedFieldScope<`additionalInformation.${number}`>

const isAdditionalInformationItemScope = (unknownScope: ObjectScope): unknownScope is `additionalInformation.${number}` => (
  Array.from(unknownScope.matchAll(expectedScope))?.length ?? 0) > 0

function AdditionalInformations({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<AdditionalInformation>({
    blankItem: blankAdditionalInformation,
    name: scope,
  })
  const [, additionalInformationOptions] = useCodelist('ADDITIONAL_INFORMATION')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-additional-informations"
        headingLabel={t('declaration.p5.additionalInformations')}
      >
        {getFields().map((additionalInformation, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isAdditionalInformationItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`additionalInformation_${additionalInformation.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, additionalInformation)}
              sequenceNumber={additionalInformation.sequenceNumber + 1}
              hidden={additionalInformation.deleted}
            >
              <FormField labelKey="declaration.p5.additionalInformationCode">
                <FormSelect
                  <AdditionalInformationItemScope>
                  field={`${innerItemScope}.code`}
                  labelKey="declaration.p5.additionalInformationCode"
                  options={additionalInformationOptions}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.additionalInformationText">
                <Input
                  <AdditionalInformationItemScope>
                  type="textarea"
                  field={`${innerItemScope}.text`}
                  labelKey="declaration.p5.additionalInformationText"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addRow')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default AdditionalInformations
