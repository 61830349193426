import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import UserService, { User } from '../../routes/Customer/services/user.service'
import { CurrentCustomer } from '../../types/Customer'

export interface AuthState {
  user: User | null
  customer: CurrentCustomer | null
  isLoading: boolean
}

export enum UserRole {
  ADMIN = 'ADMIN',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  USER = 'USER',
}

export interface Jwt {
  exp: number
  iat: number
  roles: string
  sub: string
}

export function useAuth(): AuthState {
  const [user, setUser] = useState<User | null>(null)
  const [customer, setCustomer] = useState<CurrentCustomer | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const token = localStorage.getItem('accessToken')
    if (!token) {
      setIsLoading(false)

      return
    }
    setIsLoading(true)
    if (token) {
      const jwtDecoded: Jwt = jwtDecode(token)
      UserService.getCurrentUserTopbarInfo().then((userResponse) => {
        if (userResponse !== null) {
          setUser({
            customerName: userResponse.customerName,
            id: userResponse.id,
            email: userResponse.email,
            firstname: userResponse.firstname,
            lastname: userResponse.lastname,
            role: jwtDecoded.roles,
            phone: userResponse.phone,
          })
          if (jwtDecoded.roles === UserRole.USER) {
            setCustomer({
              city: undefined,
              contactEmail: userResponse.customerEmail,
              country: undefined,
              profileStatus: undefined,
              registryCode: undefined,
              status: undefined,
              street: undefined,
              zipCode: undefined,
              id: userResponse.customerId,
              name: userResponse.customerName,
              clientApiAccess: undefined,
            })
          }
        }
      })
        .catch((reason) => {
          // eslint-disable-next-line no-console
          console.error(reason)
          setIsLoading(false)
          toast.error('Error loading profile')
        })
      if (jwtDecoded.roles !== UserRole.USER) {
        UserService.getCurrentCustomer()
          .then((info) => {
            setCustomer({
              id: info.id,
              city: info.city,
              country: info.country,
              contactEmail: info.contactEmail,
              name: info.name,
              registryCode: info.registryCode,
              street: info.street,
              zipCode: info.zipCode,
              status: undefined,
              profileStatus: info.profileStatus,
              clientApiAccess: info.clientApiAccess,
            })
          }).catch((reason) => {
          // eslint-disable-next-line no-console
            console.error(reason)
            setIsLoading(false)
            toast.error('Error loading profile')
          })
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  return {
    user,
    customer,
    isLoading,
  }
}
