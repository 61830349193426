import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

const ECB_URL = 'https://data-api.ecb.europa.eu/service/data/EXR/'
const RON_MONTHLY_SERIES_KEY = 'M.RON.EUR..A'
const BGN_DAILY_SERIES_KEY = 'D.BGN.EUR..A'
const GBP_DAILY_SERIES_KEY = 'D.GBP.EUR..A'
const USD_DAILY_SERIES_KEY = 'D.USD.EUR..A'

function useCurrencyExchangeRate(country: string | null) {
  const { t } = useTranslation()
  const [exchangeRate, setExchangeRate] = useState<number>(0)

  const fetchExchangeRate = useQuery({
    enabled: !!country,
    queryFn: async ({ signal }) => {
      let seriesKey: string = ''
      if (country === 'RO') {
        seriesKey = RON_MONTHLY_SERIES_KEY
      } else if (country === 'BG') {
        seriesKey = BGN_DAILY_SERIES_KEY
      } else if (country === 'GB') {
        seriesKey = GBP_DAILY_SERIES_KEY
      } else if (country === 'US') {
        seriesKey = USD_DAILY_SERIES_KEY
      } else {
        return Promise.resolve('')
      }
      return (await axios.get<string>(`${ECB_URL}${seriesKey}`, {
        params: {
          lastNObservations: 1,
          format: 'csvdata',
          detail: 'dataonly',
        },
        headers: {
          'Content-Type': 'text/csv',
        },
        signal,
      })).data
    },
    queryKey: [ECB_URL, country],
    staleTime: Infinity, // never stale
  })

  function extractExchangeRate(responseCSV: string): number {
    const rows = responseCSV.split('\r\n')
    let delimiter = ','
    if (responseCSV.slice(0, rows[0].indexOf('\r\n')).indexOf(delimiter) === -1) {
      delimiter = ';'
    }
    const headers: Array<string> = rows[0].split(delimiter)
    const row: Array<string> = rows[1].split(delimiter)
    const observationValueColumnIndex = headers.findIndex((headerName) => headerName === 'OBS_VALUE')
    if (row[observationValueColumnIndex] === undefined || Number.isNaN(Number(row[observationValueColumnIndex]))) {
      toast.error(t('common.error'))
      return 0
    }

    return (Number(Number(row[observationValueColumnIndex]).toFixed(4)))
  }

  useEffect(
    () => {
      if (fetchExchangeRate.isSuccess && fetchExchangeRate.data !== undefined && fetchExchangeRate.data !== '') {
        setExchangeRate(extractExchangeRate(fetchExchangeRate.data))
      }
    },
    [country, fetchExchangeRate.isFetching],
  )

  return {
    exchangeRateLoading: fetchExchangeRate.isLoading,
    exchangeRate,
  }
}

export default useCurrencyExchangeRate
