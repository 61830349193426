import i18n from 'i18next'
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { sortBySortOrder } from '../../helpers'
import ClassifierService from '../../services/classifier.service'
import { IClassifier } from '../../types/IClassifier'
import { UserPreference } from '../../types/UserPreference'
import UserPreferenceService from './services/user-preference.service'

export interface CustomClassifier {
  codeLabel: string,
  sortOrder: number,
  nameEn: string,
  nameRu: string,
  deleted: boolean,
}

interface TableFilterProps {
  userPreferences: Array<UserPreference>;
  isTableFilterModalOpen: boolean;
  setIsTableFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  setUserPreferences: Dispatch<SetStateAction<Array<UserPreference>>>;
}

function TableFilter({
  userPreferences, isTableFilterModalOpen, setIsTableFilterModalOpen, setUserPreferences,
}: TableFilterProps) {
  const [savingPreferences, setSavingPreferences] = useState<boolean>(false)
  const [userPreferencesClassifiers, setUserPreferencesClassifiers] = useState<Array<CustomClassifier>>([])

  const { t } = useTranslation()

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin me-1" />)

  const blankClassifier: CustomClassifier = {
    codeLabel: '',
    sortOrder: 0,
    nameEn: '',
    nameRu: '',
    deleted: false,
  }

  useEffect(() => {
    if (userPreferences.length === 0) {
      return
    }

    ClassifierService.getClassifier('TRANSIT_TABLE')
      .then((classifiers: IClassifier[]) => {
        const classifierArray: Array<CustomClassifier> = []
        const arrayOfNotMigratedPreferences: Array<CustomClassifier> = []
        classifiers.forEach((classifier) => {
          const index = userPreferences.find((preference) => preference.value === classifier.codeLabel)
          const preference = JSON.parse(JSON.stringify(blankClassifier))
          if (index) {
            if (classifier.nameRu != null) {
              preference.nameRu = classifier.nameRu
            }
            if (classifier.nameEn != null) {
              preference.nameEn = classifier.nameEn
            }

            preference.deleted = index.deleted
            preference.sortOrder = index.sortOrder
            preference.codeLabel = index.value
            classifierArray.push(preference)
          } else {
            if (classifier.nameRu != null) {
              preference.nameRu = classifier.nameRu
            }
            if (classifier.nameEn != null) {
              preference.nameEn = classifier.nameEn
            }

            preference.deleted = true
            preference.sortOrder = userPreferences.length + arrayOfNotMigratedPreferences.length
            preference.codeLabel = classifier.codeLabel
            arrayOfNotMigratedPreferences.push(preference)
          }
        })

        classifierArray.push(...arrayOfNotMigratedPreferences)
        setUserPreferencesClassifiers(classifierArray.sort(sortBySortOrder))
        if (arrayOfNotMigratedPreferences.length > 0) {
          setUserPreferences((prevValues) => [...prevValues,
            ...arrayOfNotMigratedPreferences
              .sort(sortBySortOrder)
              .map((newPreference) => ({
                value: newPreference.codeLabel,
                sortOrder: newPreference.sortOrder,
                deleted: newPreference.deleted,
              }))])
        }
      })
  }, [userPreferences.length !== 0])

  const submitPreferences = () => {
    setSavingPreferences(true)
    UserPreferenceService.updatePreferences(userPreferences).then(() => {
      toast.success(t('preferences.updated'))
      setTimeout(() => {
        setSavingPreferences(false)
        setIsTableFilterModalOpen(false)
      }, 500)
    }).catch((error) => {
      toast.error(t('preferences.failed'))
      // eslint-disable-next-line no-console
      console.error(error.message)
      setSavingPreferences(false)
    })
  }

  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return
    const updatedList = [...userPreferences.sort(sortBySortOrder)]
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    updatedList.forEach((item, index) => {
      if (item) {
        // eslint-disable-next-line no-param-reassign
        item.sortOrder = index
      }
    })
    setUserPreferences(updatedList.sort(sortBySortOrder))

    const updatedClassifierList = [...userPreferencesClassifiers]
    const [reorderedClassifierItem] = updatedClassifierList.splice(droppedItem.source.index, 1)
    updatedClassifierList.splice(droppedItem.destination.index, 0, reorderedClassifierItem)
    updatedClassifierList
      .forEach((item, index) => {
        // eslint-disable-next-line no-param-reassign
        item.sortOrder = index
      })
    setUserPreferencesClassifiers(updatedClassifierList
      .sort(sortBySortOrder))
  }

  const changePreference = (type: string, checked: boolean) => {
    const preferenceExists = userPreferences.find((preference) => preference.value === type)
    const classifierExists = userPreferencesClassifiers.find((preference) => preference.codeLabel === type)

    if (preferenceExists && checked && classifierExists) {
      preferenceExists.value = type
      preferenceExists.deleted = false
      classifierExists.deleted = false
      setUserPreferences((prevValues) => [...prevValues])
    } else if (preferenceExists && !checked && classifierExists) {
      classifierExists.deleted = true
      preferenceExists.deleted = true
      setUserPreferences((prevValues) => [...prevValues])
    }
  }
  return (
    <Modal
      show={isTableFilterModalOpen}
      size="lg"
      className="overflow-hidden"
      dialogClassName="d-flex modal preferences-modal align-modal-top align-modal-left"
      backdropClassName="backdrop-with-modal"
      animation={false}
      onHide={() => setIsTableFilterModalOpen(!isTableFilterModalOpen)}
      centered
    >
      <Modal.Header>
        <Modal.Title className="justify-content-between">
          <h2>
            {t('user.preferences')}
          </h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              if (!savingPreferences) {
                setIsTableFilterModalOpen(!isTableFilterModalOpen)
              }
            }}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '80vh' }}>
        <p>{t('preferences.order')}</p>
        <div className="pt-4 px-4 col-12 col-xl-12 mb-5 mb-md-0">
          <div className="container">
            <div className="row align-items-start">
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {userPreferencesClassifiers.map((classifier, index) => (
                        <Draggable key={classifier.codeLabel} draggableId={classifier.codeLabel} index={index}>
                          {(secondProvider) => (
                            <div
                              className="item-container"
                              ref={secondProvider.innerRef}
                              /* eslint-disable-next-line react/jsx-props-no-spreading */
                              {...secondProvider.dragHandleProps}
                              /* eslint-disable-next-line react/jsx-props-no-spreading */
                              {...secondProvider.draggableProps}
                            >
                              <div className="d-flex mb-2 col" key={classifier.codeLabel}>
                                <div className="form-check form-switch ms-3 me-2">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={!classifier.deleted}
                                    onChange={(e) => changePreference(classifier.codeLabel, e.target.checked)}
                                  />
                                </div>
                                <p>
                                  {i18n.language === 'ru' ? classifier.nameRu : classifier.nameEn}
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-dark btn-sm d-flex align-items-center"
          type="button"
          disabled={savingPreferences}
          onClick={() => {
            submitPreferences()
          }}
        >
          {
            savingPreferences && loadingSpinner()
          }
          <span>{t('buttons.save')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TableFilter
