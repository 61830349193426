import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../../../../../phase5/TransitOperationEditor/hooks/apiConfig'
import { CertificateInfoResponse } from '../../models/certificate-info-response'

const {
  baseUrl,
  queryKeys,
} = DeclarationApi.paths.certificatesInfo

function useCertificatesInfoApi() {
  const fetchCertificatesInfo = useQuery({
    queryKey: queryKeys.rootPath(),
    queryFn: async () => (await axios.get<CertificateInfoResponse[]>(`${baseUrl}`)),
  })

  return {
    fetchCertificatesInfo,
  }
}

export default useCertificatesInfoApi
