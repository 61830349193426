import React, { useState } from 'react'
// eslint-disable-next-line import/no-cycle
import SettingsNavigationBar from './SettingsNavigationBar'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface TabProps {
  children: Array<React.ElementRef<any>>
  loading: boolean
}

export type TabType = 'notification' | 'privacy' | 'seo' | 'customs' | 'codelist' | 'certificates'
const accessibleTabTypes: TabType[] = ['notification', 'privacy', 'seo', 'customs', 'codelist', 'certificates']

export function SettingsTabsPanel(props: TabProps) {
  const {
    children,
    loading,
  } = props
  const [currentTab, setCurrentTab] = useState<TabType>('notification')

  const changeTab = (newTab: TabType) => {
    setCurrentTab(newTab)
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const findCurrentTab = (child: any) => {
    if (child.props.tabType === currentTab) {
      return child
    }

    return null
  }

  return (
    <React.Fragment key="settings-panel">
      <SettingsNavigationBar
        key="settings-navigation-bar"
        loading={loading}
        accessibleTabTypes={accessibleTabTypes}
        currentTab={currentTab}
        changeTab={changeTab}
      />
      {
        React.Children.map(children, findCurrentTab)
      }
    </React.Fragment>
  )
}
