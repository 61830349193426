import { useEffect, useState } from 'react'

interface Password {
  password: string,
  confirmPassword: string | null;
  requiredLength: number;
}

// eslint-disable-next-line import/prefer-default-export
export function usePasswordValid(props: Password) {
  const {
    password,
    confirmPassword,
    requiredLength = 7,
  } = props

  const [validLength, setValidLength] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [match, setMatch] = useState(true)

  useEffect(() => {
    if (password !== '') {
      setIsValid(password.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$') === null)
    }
    if (confirmPassword !== '' && password !== '') {
      setValidLength(password.length >= requiredLength)
      setMatch(password === confirmPassword)
    }
  }, [password, confirmPassword, requiredLength])

  return [validLength, match, isValid]
}
