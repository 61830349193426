import { z } from 'zod'

export const ContactPersonSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  name: z.string(),
  email: z.string().email().or(z.literal('')),
  telephone: z.string(),
})
export type ContactPerson = z.infer<typeof ContactPersonSchema>

export const AddressSchema = z.object({
  id: z.number().nullable(),
  streetAndNumber: z.string(),
  postcode: z.string(),
  city: z.string(),
  country: z.string(),
})
export type Address = z.infer<typeof AddressSchema>

export const BooleanSchema = z.enum(['true', 'false']).nullable()
export type BooleanType = z.infer<typeof BooleanSchema>

export const AdditionalSupplyChainActorSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  sequenceNumber: z.number().nonnegative(),
  role: z.string(),
  identificationNumber: z.string(),
})
export type AdditionalSupplyChainActor = z.infer<typeof AdditionalSupplyChainActorSchema>

export const DepartureTransportMeansSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  sequenceNumber: z.number().nonnegative(),
  typeOfIdentification: z.string(),
  identificationNumber: z.string(),
  nationality: z.string(),
})
export type DepartureTransportMeansType = z.infer<typeof DepartureTransportMeansSchema>

export const CountryOfRoutingOfConsignmentSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    country: z.string().nullable(),
    deleted: z.boolean().optional(),
  },
)
export type CountryOfRoutingOfConsignment = z.infer<typeof CountryOfRoutingOfConsignmentSchema>

export const ActiveBorderTransportMeansSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    customsOfficeAtBorderReferenceNumber: z.string(),
    typeOfIdentification: z.string(),
    identificationNumber: z.string(),
    nationality: z.string(),
    conveyanceReferenceNumber: z.string().nullable(),
    deleted: z.boolean().optional(),
  },
)
export type ActiveBorderTransportMeansType = z.infer<typeof ActiveBorderTransportMeansSchema>

export const AdditionalReferenceSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    type: z.string(),
    referenceNumber: z.string(),
    deleted: z.boolean().optional(),
  },
)
export type AdditionalReference = z.infer<typeof AdditionalReferenceSchema>

export const AdditionalInformationSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    code: z.string(),
    text: z.string(),
    deleted: z.boolean().optional(),
  },
)
export type AdditionalInformation = z.infer<typeof AdditionalInformationSchema>

export const CustomsOfficeOfTransitDeclaredSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    referenceNumber: z.string(),
    arrivalDateAndTime: z.union([
      z.literal('').transform(() => null),
      z.string().datetime({ local: true }).optional(),
    ]).optional(), // https://github.com/colinhacks/zod/issues/1721#issuecomment-2065656902
    deleted: z.boolean().optional(),
  },
)
export type CustomsOfficeOfTransitDeclared = z.infer<typeof CustomsOfficeOfTransitDeclaredSchema>

export const AuthorisationSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    type: z.string(),
    referenceNumber: z.string(),
    deleted: z.boolean().optional(),
  },
)
export type Authorisation = z.infer<typeof AuthorisationSchema>
