import React from 'react'
import { GuaranteeCalculationItemRow } from '../../hooks/useGuaranteeCalculator'

interface CalculationRowProps {
  rowItem: GuaranteeCalculationItemRow
}

function CalculationRow({
  rowItem,
}: CalculationRowProps) {
  return (
    <>
      <div>
        <small className="mb-1">
          {rowItem.commodityCode}
        </small>
        <small className="overflow-ellipsis">
          {rowItem.description}
        </small>
      </div>
      <div>
        {
          rowItem.price ?? '-'
        }
      </div>
      <div>
        {
          rowItem.excise ?? '-'
        }
      </div>
      <div>
        {
          rowItem.dutyRate ?? '-'
        }
      </div>
      <div>
        {
          rowItem.taxableDutyAmount ?? '-'
        }
      </div>
      <div>
        {
          rowItem.vatRate ?? '-'
        }
      </div>
      <div>
        {
          rowItem.taxableVatAmount ?? '-'
        }
      </div>
      <div>
        {
          rowItem.taxableItemPrice ?? '-'
        }
      </div>
    </>
  )
}

export default CalculationRow
