import { HouseConsignmentType } from '../schemas/houseConsignmentSchema'
import { IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'

const R0994Validator = {
  getMinimumConsignmentGrossMass: (
    houseConsignments: HouseConsignmentType[],
  ): number => {
    if (IS_TRANSITIONAL_PERIOD) {
      return 0
    }
    return houseConsignments.reduce((sumAccumulator, house) => sumAccumulator + house.grossMass, 0)
  },
} as const

export default R0994Validator
