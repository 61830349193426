import axios from 'axios'
import { useQueries } from '@tanstack/react-query'
import TransitApiConfig from '../apiConfig'

const {
  baseUrl,
  queryKeys: { countByHouseConsignmentId },
} = TransitApiConfig.paths.consignmentItem

function useConsignmentItemCountApi(houseConsignmentIds: Array<number | null>, isSubmitting: boolean) {
  // useQueries does not accept an abort signal
  //  https://github.com/TanStack/query/issues/5342
  const fetchConsignmentItemsCount = useQueries(
    {
      queries: houseConsignmentIds.map((houseConsignmentId) => ({
        queryKey: countByHouseConsignmentId(houseConsignmentId),
        queryFn: async () => (await axios.get<number>(`${baseUrl}/count/${houseConsignmentId}`)).data,
        staleTime: Infinity, // never stale
        enabled: !!houseConsignmentId && !isSubmitting,
      })),
    },
  )

  return {
    fetchConsignmentItemsCount,
  }
}

export default useConsignmentItemCountApi
