import useReassignTransitOperationApi from './api'

export default function useReassignTransitOperation() {
  const {
    reassignTransitOperation,
  } = useReassignTransitOperationApi()

  const reassignTransitOperationToNewOwner = async (id: number, newCustomerId: number) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    if (newCustomerId === null || newCustomerId === undefined) throw new Error('Customer ID cannot be null or undefined!')

    await reassignTransitOperation.mutateAsync({
      newCustomerId,
      transitOperationId: id,
    })
  }

  return {
    reassignTransitOperationToNewOwner,
  }
}
