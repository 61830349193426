import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { DeclarationAudit, DeclarationAuditStatus } from '../../../../types/DeclarationAudit'
import DeclarationAuditService from '../../services/declaration-audit.service'
import { ISelectOption } from '../../../../types/IClassifier'

function useDeclarationAudit(refreshPage: () => void) {
  const { t } = useTranslation()
  const [isDecisionModalVisibile, setIsDecisionModalVisible] = useState<boolean>(false)
  const [decisionItem, setDecisionItem] = useState<DeclarationAudit | null>({
    id: null,
    status: null,
    customerName: null,
    modified: null,
    created: '',
    reason: '',
    isReviewed: null,
    reviewedBy: null,
    dateReviewed: null,
  })

  const history = useNavigate()
  const [status, setStatus] = useState<DeclarationAuditStatus | null>(null)
  const [isDecisionLoading, setIsDecisionLoading] = useState<boolean>(false)
  const [reasonInput, setReasonInput] = useState<string>('')
  const [declarationId, setDeclarationId] = useState<number | null>(null)
  const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false)

  const editDeclaration = () => {
    setIsAsideOpen((prevState: boolean) => !prevState)
    if (declarationId === null) { return }
    history(`/transit/${declarationId}`)
  }

  const toggleDecisionModalVisibility = () => {
    setIsDecisionModalVisible(!isDecisionModalVisibile)
  }

  const openDecisionModal = (declaration: DeclarationAudit) => {
    toggleDecisionModalVisibility()
    setDecisionItem(declaration)
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const getDeclaration = (id: number, row: any) => {
    setDeclarationId(id)
    setIsAsideOpen(true)
    setDecisionItem(row.original)
  }

  const changeAside = (input: boolean, declaration?: number) => {
    setIsAsideOpen(input)
    setDeclarationId(declaration ?? null)

    return isAsideOpen
  }

  const decide = () => {
    setIsDecisionLoading(true)

    if (decisionItem === null || decisionItem.id === null) {
      return
    }
    if (status === 'DECLINED' || status === 'PROHIBITED_DESCRIPTION') {
      if (reasonInput.length === 0) {
        toast.error(t('audit.inputError'))
        setIsDecisionLoading(false)
        return
      }
    }

    DeclarationAuditService.putAudit(decisionItem)
      .then((response) => {
        toast.success(t('audit.updated', { context: 'successful' }))
        setDecisionItem({ ...response })
        changeAside(false)
        refreshPage()
      })
      .catch(() => {
        toast.error(t('audit.error', { context: 'failed' }))
      })
      .finally(() => {
        setIsDecisionLoading(false)
        setIsDecisionModalVisible(false)
      })
  }

  const setAuditStatus = (selectedOption: ISelectOption | null) => {
    if (selectedOption === null || decisionItem === null) {
      return
    }

    setDecisionItem({
      ...decisionItem,
      status: selectedOption.value,
    })
    setStatus(selectedOption.value)
  }

  const setReasonText = (reason: string) => {
    if (reason === null || decisionItem === null) {
      return
    }

    setDecisionItem({
      ...decisionItem,
      reason,
    })
    setReasonInput(reason)
  }

  return {
    decide,
    isDecisionModalVisibile,
    toggleDecisionModalVisibility,
    isDecisionLoading,
    setReasonText,
    setAuditStatus,
    decisionItem,
    setDecisionItem,
    isAsideOpen,
    changeAside,
    editDeclaration,
    declarationId,
    getDeclaration,
    openDecisionModal,
  }
}

export default useDeclarationAudit
