import React from 'react'
import { useTranslation } from 'react-i18next'
import { TitleBar } from '../../../components/TitleBar'
import AuditTable from './audit_table/Table'

function TransitOperationAudit() {
  const { t } = useTranslation()

  return (
    <>
      <TitleBar title={t('pages.page', { context: 'audit' })} />
      <AuditTable />
    </>
  )
}

export default TransitOperationAudit
