import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next'
import LoadingBackdrop from 'components/LoadingBackdrop'
import { TransitOperationResponse } from '../../models'
import TransitSidebarHeader, { SidebarView } from './TransitSidebarHeader'
import TransitSidebarSection from './TransitSidebarSection'
import TransitSidebarDetails from './TransitSidebarDetails'
import SidebarEventLog from './EventLog'
import useTransitOperationApi from '../../../TransitOperationEditor/hooks/useTransitOperation/api'

export interface TransitSidebarProps {
  isVisible: boolean
  toggleVisibility: () => void
  transitOperationId: number
  originalRow: any
  openAuditModal?: () => void
}

function TransitSidebar(props: Readonly<TransitSidebarProps>) {
  const {
    isVisible,
    toggleVisibility,
    transitOperationId,
    originalRow,
    openAuditModal,
  } = props
  const { t } = useTranslation()

  const [sidebarView, setSidebarView] = useState<SidebarView>('data')
  const [transitOperationData, setTransitOperationData] = useState<TransitOperationResponse>()
  const { fetchTransitOperation } = useTransitOperationApi(transitOperationId, false)

  useEffect(() => {
    setTransitOperationData(fetchTransitOperation.data)
  }, [fetchTransitOperation.data])

  const changeView = (view: SidebarView) => {
    setSidebarView(view)
  }

  return (
    <Offcanvas
      className="wide transit-sidebar"
      show={isVisible}
      onHide={() => {
        toggleVisibility()
        setSidebarView('data')
      }}
      placement="end"
    >
      <TransitSidebarHeader
        transitOperationId={transitOperationId}
        transitStatus={transitOperationData?.status?.toString() ?? 'DRAFT'}
        transitOperation={transitOperationData}
        currentView={sidebarView}
        changeView={changeView}
        originalRow={originalRow}
        openAuditModal={openAuditModal}
      />
      {
        sidebarView === 'logs' && transitOperationData?.id && (
          <Offcanvas.Body key="logs-body">
            <TransitSidebarSection columnId="general-info" headingLabel={t('declaration.navbar.logs')}>
              <SidebarEventLog transitOperationId={transitOperationData.id} />
            </TransitSidebarSection>
          </Offcanvas.Body>
        )
      }
      {
        sidebarView === 'data' && (
          <>
            {
              fetchTransitOperation.isLoading && (
                <div>
                  <LoadingBackdrop loading={fetchTransitOperation.isLoading} isInTable={false} />
                </div>
              )
            }
            {
              !fetchTransitOperation.isLoading && (
                <TransitSidebarDetails
                  transitOperationId={transitOperationId}
                  transitOperationData={transitOperationData}
                  isLoading={fetchTransitOperation.isFetching}
                />
              )
            }
          </>
        )
      }
    </Offcanvas>
  )
}

export default TransitSidebar
