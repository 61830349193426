import React, {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { TabType } from '..'
import usePrivacyPolicy from './hooks/usePrivacyPolicy'
import ConfirmationModal from '../ConfirmationModal'
import Editor from '../Editor'
import PrivacyPolicyActionHeader from './PrivacyPolicyActionHeader'
import { ISelectOption } from '../../../../types/IClassifier'

interface PrivacyPolicyProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly tabType: TabType;
}

const selectOptions: ISelectOption[] = [
  {
    value: 'ru',
    label: 'RUS',
  },
  {
    value: 'en',
    label: 'ENG',
  },
]

function PrivacyPolicy({
  loading,
  setLoading,
}: PrivacyPolicyProps) {
  const { t } = useTranslation()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const {
    currentTerms,
    setCurrentTerms,
    submitUpdatedPrivacyPolicy,
    language,
    setLanguage,
  } = usePrivacyPolicy(setIsConfirmationModalOpen, selectOptions, loading, setLoading)

  const CachedEditor = useCallback((args: { editorContent: string; setEditorContent: (content: string) => void; }) => (<Editor editorContent={args.editorContent} setEditorContent={args.setEditorContent} />), [language.value, currentTerms === ''])

  const modalBody = (
    <>
      <p className="fw-bold">{t('privacyPolicy.confirm')}</p>
      <p className="fw-bold">{t('privacyPolicy.language')}</p>
    </>
  )

  return (
    <>
      <PrivacyPolicyActionHeader
        selectOptions={selectOptions}
        loading={loading}
        language={language}
        setLanguage={setLanguage}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      />
      {currentTerms !== null && currentTerms !== '' ? (
        <CachedEditor editorContent={currentTerms} setEditorContent={setCurrentTerms} />
      ) : null}

      <ConfirmationModal
        loading={loading}
        title={t('privacyPolicy.editPrivacy')}
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        submissionConfirmed={submitUpdatedPrivacyPolicy}
        modalBody={modalBody}
      />
    </>
  )
}

export default PrivacyPolicy
