import React from 'react'
import Table from './components/Table'
import RestrictionsTableTitleBar from './components/RestrictionsTableTitleBar'

function RestrictionTable() {
  return (
    <>
      <RestrictionsTableTitleBar />
      <Table />
    </>
  )
}

export default RestrictionTable

