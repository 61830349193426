/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react'
import { getInvalidIconData } from './invalid-icon.helper'
import InvalidIcon from './InvalidIcon'

interface DataRowParams {
  columnWidth?: 4 | 3;
  title?: string;
  value?: string;
  extraValue?: string;
  secondValue?: string;
  isLast?: true;
  fieldLabels?: Array<string>;
  invalidFields?: Array<{ pointer: string, reason: string }>;
  onInvalidIconClick?: () => void;
}

function DataRow(props: DataRowParams) {
  const {
    title, isLast, value, extraValue, columnWidth, secondValue,
    fieldLabels, invalidFields, onInvalidIconClick,
  } = props

  // eslint-disable-next-line no-nested-ternary
  const columnA = columnWidth === 4 ? 'col-md-4' : (columnWidth === 3 ? 'col-md-3' : 'col-md-6')
  // eslint-disable-next-line no-nested-ternary
  const columnB = columnWidth === 4 ? 'col-md-8' : (columnWidth === 3 ? 'col-md-9' : 'col-md-6')

  const iconData = useMemo(() => getInvalidIconData(fieldLabels, invalidFields), [invalidFields, fieldLabels])

  if (secondValue) {
    return (
      <>
        <div key={`${title} + ${value} + '_dataRow'`} className="row mb-0 mb-md-2 lh-sm flex-basis-auto">
          <div className={`${columnA} col-12`}>
            <small className={`${iconData.isInvalid ? 'text-danger' : 'text-muted'}`}>
              {title}
            </small>
          </div>
          <div className={`${columnB} col-12 ${iconData.isInvalid ? 'text-danger' : ''}`}>
            <small>
              {value}
            </small>
          </div>
        </div>
        <div key={`${title} + ${secondValue} + '_dataRowSecondValue'`} className={`row lh-sm flex-basis-auto ${isLast ? 'mb-4' : 'mb-2'}`}>
          <div className={`${columnA} d-none d-md-block`} />
          <div className={`${columnB} col-12 ${iconData.isInvalid ? 'd-flex text-danger' : ''}`}>
            <small>
              {secondValue}
            </small>
            <InvalidIcon iconData={iconData} onInvalidIconClick={onInvalidIconClick} />
          </div>
        </div>
      </>
    )
  }
  return (
    <div key={`${title} + ${value} + '_dataRow'`} className={`row lh-sm flex-basis-auto ${isLast ? 'mb-4' : 'mb-2'}`}>
      <div className={`${columnA} col-12`}>
        <small className="text-muted">
          {title}
        </small>
      </div>
      {value ? (
        <div className={`${columnB} mb-2 mb-md-0 col-12 ${iconData.isInvalid ? 'd-flex text-danger align-items-end' : ''}`}>
          <small className="d-flex flex-wrap">
            <span>{value}</span>
            {extraValue ? (
              <span className={`${iconData.isInvalid ? 'text-danger ms-2' : 'text-muted ms-2'}`}>
                (
                {extraValue}
                )
              </span>
            ) : null}
          </small>
          <InvalidIcon iconData={iconData} onInvalidIconClick={onInvalidIconClick} />
        </div>
      )
        : (
          <div className={`${columnB} d-none d-md-block mb-2 mb-md-0 col-12`} />
        )}
    </div>
  )
}

export default DataRow
