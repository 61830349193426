import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { AdditionalSupplyChainActor } from '../../../form/schemas/commonConsignmentSchemas'
import { blankAdditionalSupplyChainActor } from '../../../form'
import { ADDITIONAL_SUPPLY_CHAIN_ACTOR_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted' | 'address'

function isAdditionalSupplyChainActorField(field: string): field is Exclude<ObjectKeys<AdditionalSupplyChainActor>, NotImportableFields> {
  return field in blankAdditionalSupplyChainActor
}

export default function mapAdditionalSupplyChainActor(
  newImportedItemObject: AdditionalSupplyChainActor[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isAdditionalSupplyChainActorField(consignmentItemObjectSubfield)) {
    return
  }
  let additionalSupplyChainActor = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)

  if (additionalSupplyChainActor === undefined) {
    additionalSupplyChainActor = {
      ...blankAdditionalSupplyChainActor,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(additionalSupplyChainActor)
  }

  switch (consignmentItemObjectSubfield) {
    case 'role':
      additionalSupplyChainActor[consignmentItemObjectSubfield] = hasText(cellValue)
        ? ADDITIONAL_SUPPLY_CHAIN_ACTOR_CODE_PREFIX + cellValue : ''
      break
    case 'identificationNumber':
      additionalSupplyChainActor[consignmentItemObjectSubfield] = cellValue
      break
    default:
      throw Error(`Unknown additional supply chain actor field ${consignmentItemObjectSubfield}`)
  }
}
