import { RuleResult } from 'types/DeclarationP5'

const C0387Validator = {
  validateCountryAndLocationRequirement: (unLocode: string): RuleResult => {
    if (unLocode.trim().length > 0) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default C0387Validator
