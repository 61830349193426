import { RuleResult } from 'types/DeclarationP5'
import { CL010_CL553_EU_COUNTRY_CODES } from '../../../../common/utils/ncts-constants'
import { SecurityEnumT } from '../../schemas/declarationFormSchema'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CustomsOfficeOfTransitDeclared } from '../../schemas/commonConsignmentSchemas'

const B1831Validator = {
  validateTransitOfficeDateTimeRequirement: (
    departureCustomsOffice: string,
    transitCustomsOffice: CustomsOfficeOfTransitDeclared,
    security: SecurityEnumT,
  ): RuleResult => {
    if (['1', '2', '3'].includes(security)) {
      const transitCustomsOfficeReferenceNumber = removePrefix(transitCustomsOffice.referenceNumber, 'TRANSIT_OFFICE_', 2)
      const departureOfficeCountry = removePrefix(departureCustomsOffice, 'DEPARTURE_OFFICE_', 2)

      if (transitCustomsOfficeReferenceNumber !== null
          && CL010_CL553_EU_COUNTRY_CODES.includes(transitCustomsOfficeReferenceNumber)
         && departureOfficeCountry !== null && !CL010_CL553_EU_COUNTRY_CODES.includes(departureOfficeCountry)) {
        return 'REQUIRED'
      }
    }

    return 'OPTIONAL'
  },
} as const

export default B1831Validator
