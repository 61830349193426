import { useEffect, useRef } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { PageResponse } from 'types/PageResponse'
import { CodelistResponse } from '../../../common/models'
import TransitApiConfig from '../../hooks/apiConfig'

const {
  baseUrl,
  queryKeys: {
    rootPath,
    searchCodelistPageWithCodeLabel,
  },
} = TransitApiConfig.paths.codelist

export type CombinedNomenclatureOption = {
  value: string
  codeLabel: string
  name: string
  isArchived: boolean
}

function useCommodityCodeSearch(codeLabel: string, pageNumber: number, country: string | null) {
  const cancelToken = useRef(axios.CancelToken.source())
  const queryClient = useQueryClient()
  const isQueryEnabled = codeLabel.length >= 2

  const {
    data,
    isFetching,
    isLoading,
  } = useQuery(
    {
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: searchCodelistPageWithCodeLabel(codeLabel, pageNumber, country),
      queryFn: async ({ signal }) => {
        cancelToken.current.cancel()
        cancelToken.current = axios.CancelToken.source() // TODO upgrade axios to fix abort signals

        return (await axios.get<PageResponse<CodelistResponse>>(`${baseUrl}/search`, {
          params: {
            codeLabel, country, page: pageNumber, startsWith: true, codeGroup: 'COMMODITY_CODES',
          },
          signal,
          cancelToken: cancelToken.current.token,
        })).data
      },
      enabled: isQueryEnabled,
    },
  )
  useEffect(() => () => {
    cancelToken.current.cancel()
    queryClient.removeQueries({ queryKey: [`${rootPath}/search`] })
  }, [])

  return {
    isLoading: isQueryEnabled && (isFetching || isLoading),
    data,
  } as const
}

export default useCommodityCodeSearch
