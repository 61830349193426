import { useState } from 'react'
import { CustomerRequirements } from '../../types/Customer'
import CustomerService from '../../routes/Customer/services/customer.service'

function useCustomerRequirement() {
  const [loading, setLoading] = useState(false)
  const [customerRequirement, setCustomerRequirement] = useState<CustomerRequirements | null>(null)

  const loadRequirements = () => {
    if (loading) {
      return
    }
    setLoading(true)
    CustomerService.getCurrentCustomerRequirements().then((response) => {
      setCustomerRequirement(response)
    })
      .finally(() => {
        setLoading(false)
      })
  }

  return { loadRequirements, customerRequirement }
}

export default useCustomerRequirement
