import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { excludeDeleted } from '../../../common/utils/common-util'

const C0055Validator = {
  isContainerIdentificationNumberRequired: (formData: DeclarationForm, currentSequenceNumber: number): RuleResult => {
    const {
      containerIndicator,
      transportEquipment,
    } = formData

    if (containerIndicator !== 'true') return 'NOT_ALLOWED'

    const hasAtLeastOneContainerNumber = transportEquipment
      .filter(excludeDeleted)
      .some((
        containerItem,
      ) => containerItem.containerIdentificationNumber
          && containerItem.containerIdentificationNumber.trim().length > 0 && containerItem.sequenceNumber !== currentSequenceNumber)

    if (hasAtLeastOneContainerNumber) return 'OPTIONAL'

    return 'REQUIRED'
  },
} as const

export default C0055Validator
