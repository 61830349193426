import TextInput from 'components/Form/Input'
import FormRow from 'components/FormRow'
import { TitleBar } from 'components/TitleBar'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ClassifierService from 'services/classifier.service'
import { Customer as CustomerType } from 'types/Customer'
import useCountries from 'hooks/useCountries'
import { IAddressForm } from '../../components/FormRow'
import ROUTES from '../../config/routes'
import { UserContext } from '../../context/UserContext'
import {
  getNameForLocale, isNullOrBlank, sortByCodeLabel, getValue,
} from '../../helpers'
import { IClassifier, ISelectOption, OfficeRole } from '../../types/IClassifier'
import { NctsProfileModal } from './components/NctsProfileModal'
import { CustomerContext } from './CustomerContext'
import useCustomer from './hooks/useCustomer'
import CustomerService from './services/customer.service'
import UsersTable from './UsersTable'
import { removePrefixFromStartRecursively } from '../../helpers/string.helper'
import { NotificationContext } from '../../context/NotificationContext'
import mapOfficesToSelectOptions from '../../helpers/classifier.helper'

export interface CustomsProfileType {
  id: number
  name: string
  grn: string
  accessCode: string
  eori: string
  isDefault: boolean
  companyName: string
  companyPostalCode: string
  companyStreet: string
  companyCity: string
  guaranteeType: string
  authorityOffice: string
  authorityCountry: string
}

export const blankCustomsProfile: CustomsProfileType = {
  id: 0,
  name: '',
  grn: '',
  accessCode: '',
  eori: '',
  isDefault: false,
  companyName: '',
  companyPostalCode: '',
  companyStreet: '',
  companyCity: '',
  guaranteeType: 'GUARANTEE_TYPE_0',
  authorityOffice: '',
  authorityCountry: '',
}

export function Customer() {
  const { customerId }: { customerId?: string } = useParams()

  const history = useNavigate()
  const {
    t,
    i18n,
  } = useTranslation()

  const {
    user,
    customer,
  } = useContext(UserContext)
  const [isProfileModalVisible, setIsProfileModalVisible] = useState<boolean>(false)
  const [guaranteeTypes, setGuaranteeTypes] = useState<Array<ISelectOption>>([])
  const [guaranteeOffices, setGuaranteeOffices] = useState<Array<ISelectOption>>([])
  const [nctsProfile, setNctsProfile] = useState<CustomsProfileType>(blankCustomsProfile)
  const {
    fetchedCustomer,
    setFetchedCustomer,
    isExternalCustomer,
    updateRemoteClientCode,
  } = useCustomer(customerId)
  const { contextHeaderNotificationMessage } = useContext(NotificationContext)

  useEffect(() => {
    ClassifierService.getClassifier('GUARANTEE_TYPES')
      .then((guaranteeClassifiers: IClassifier[]) => {
        const guaranteeTypesResponse = guaranteeClassifiers
          .sort(sortByCodeLabel)
          .map((item) => ({
            value: item.code,
            label: `${item.codeLabel} - ${getNameForLocale(item, i18n.language)}`,
          }))

        setGuaranteeTypes(guaranteeTypesResponse)
      })

    ClassifierService.getCustomsOfficesByRoles([OfficeRole.OFFICE_ROLE_GUA]).then((classifiers: IClassifier[]) => {
      setGuaranteeOffices(mapOfficesToSelectOptions(classifiers, i18n.language))
    })
  }, [])

  const { countries } = useCountries(i18n.language)

  const redirectAfterSave = (savedCustomer: CustomerType) => {
    if (savedCustomer.external === true) {
      history(`${ROUTES.externalCustomer}/${savedCustomer.id}`)
    } else {
      history(`${ROUTES.customer}/${savedCustomer.id}`)
    }
  }

  const saveCustomer = () => {
    if (isExternalCustomer && (isNullOrBlank(customer.name)
      || isNullOrBlank(fetchedCustomer.registryCode) || isNullOrBlank(fetchedCustomer.remoteClientCode))) {
      toast.warn(t('messages.missingRequiredFields'))
      return
    }
    if (!isExternalCustomer && (
      isNullOrBlank(customer.registryCode)
      || isNullOrBlank(customer.name)
      || isNullOrBlank(customer.city)
      || isNullOrBlank(customer.street)
      || isNullOrBlank(customer.contactEmail)
      || isNullOrBlank(customer.zipCode))) {
      toast.warn(t('messages.missingRequiredFields'))
      return
    }

    // Non-blocking reminder when no GUA is found in the formula while saving
    if (fetchedCustomer.guaranteeFormula !== null && fetchedCustomer.guaranteeFormula !== undefined
      && fetchedCustomer.guaranteeFormula.length > 0
      && !fetchedCustomer.guaranteeFormula.includes('GUA')) {
      toast.info(t('messages.useGUAFormulaPlaceholder'))
    }

    if (customerId === undefined || customer.id === null) {
      CustomerService.postCustomer(fetchedCustomer, isExternalCustomer)
        .then((savedCustomer) => {
          toast.success(t('messages.savingSuccess'))
          setFetchedCustomer(savedCustomer)
          redirectAfterSave(savedCustomer)
        })
    } else if (user?.role === 'ADMIN') {
      CustomerService.putCustomer(fetchedCustomer, isExternalCustomer)
        .then((savedCustomer) => {
          toast.success(t('messages.savingSuccess'))
          setFetchedCustomer(savedCustomer)
          redirectAfterSave(savedCustomer)
        })
    }
  }

  return (
    <>
      <TitleBar title={t('customers.customer', { context: (customerId !== undefined || fetchedCustomer.id) ? 'edit' : 'new' })}>
        <div className="d-flex justify-content-end align-items-center ps-3 w-100">
          <button
            type="button"
            className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
            onClick={saveCustomer}
          >
            <span>{t('buttons.save')}</span>
          </button>
          {/* TODO: oauth loogika uk moodulis, suunamisega lahendada vms */}
          <a href={`${process.env.REACT_APP_API_URL}/oauth2/authorization/ncts_uk`}>Open auth</a>
        </div>
      </TitleBar>
      <div className={`new-customer__container overflow-auto ${contextHeaderNotificationMessage !== '' ? 'new-customer__container--notification-open' : ''}`}>
        <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
          <div className="heading">
            <h3>
              {t('customers.companyInfo')}
            </h3>
          </div>
          <FormRow title={t('common.name', { context: 'capitalized' })} required={isExternalCustomer}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={fetchedCustomer?.name}
              onChange={(event) => {
                setFetchedCustomer({
                  ...fetchedCustomer,
                  name: event.target.value,
                })
              }}
            />
          </FormRow>
          <FormRow title={t('customers.registryCode')} required={isExternalCustomer}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={fetchedCustomer.registryCode}
              disabled={user?.role !== 'ADMIN'}
              onChange={(event) => {
                setFetchedCustomer({
                  ...fetchedCustomer,
                  registryCode: event.target.value,
                })
              }}
            />
          </FormRow>
          <FormRow title={t('customers.directoClientCode')} required={isExternalCustomer}>
            <TextInput
              name="remoteClientCode"
              placeholder={t('common.fieldPlaceholder')}
              value={fetchedCustomer.remoteClientCode === undefined ? '' : fetchedCustomer.remoteClientCode}
              onChange={updateRemoteClientCode}
            />
          </FormRow>
          <FormRow title={t('common.email')}>
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder={t('common.fieldPlaceholder')}
              value={fetchedCustomer.contactEmail}
              onChange={(event) => {
                setFetchedCustomer({
                  ...fetchedCustomer,
                  contactEmail: event.target.value,
                })
              }}
            />
          </FormRow>
          <FormRow
            title={t('customers.address')}
            inputType="address"
            countriesOptions={countries}
            addressForm={{
              street: fetchedCustomer.street,
              city: fetchedCustomer.city,
              country: fetchedCustomer.country,
              zip: fetchedCustomer.zipCode,
            }}
            setAddressForm={(form: IAddressForm) => {
              setFetchedCustomer({
                ...fetchedCustomer,
                street: form.street,
                city: form.city,
                country: form.country,
                zipCode: form.zip,
              })
            }}
            isLast
          />
          {user?.role === 'ADMIN' && (
            <>
              <FormRow title={t('customers.giveAccess')}>
                <div className="form-row-special-input">
                  <div className="form-check form-switch ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={fetchedCustomer.profileStatus === 'APPROVED'}
                      onChange={(e) => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          profileStatus: fetchedCustomer.profileStatus === 'APPROVED' ? 'DISAPPROVED' : 'APPROVED',
                        })
                        setIsProfileModalVisible(e.target.checked)
                      }}
                    />
                  </div>

                </div>
              </FormRow>
              <FormRow title={t('customers.maxGuaranteeAmount')}>
                <input
                  className="form-control"
                  type="number"
                  placeholder={t('common.fieldPlaceholder')}
                  value={fetchedCustomer.maxGuaranteeAmount}
                  onChange={(event) => {
                    setFetchedCustomer({
                      ...fetchedCustomer,
                      maxGuaranteeAmount: Number(event.target.value),
                    })
                  }}
                />
              </FormRow>
              <FormRow title={t('customers.auditRequiredAmount')}>
                <input
                  className="form-control"
                  type="number"
                  placeholder={t('common.fieldPlaceholder')}
                  value={fetchedCustomer.auditRequiredAmount !== null ? fetchedCustomer.auditRequiredAmount : ''}
                  onChange={(event) => {
                    setFetchedCustomer({
                      ...fetchedCustomer,
                      auditRequiredAmount: Number(event.target.value),
                    })
                  }}
                />
              </FormRow>
              <FormRow title={t('customers.guaranteeFormula')}>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">=</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t('messages.useGUAFormulaPlaceholder')}
                    value={getValue(fetchedCustomer.guaranteeFormula)}
                    onChange={(event) => {
                      setFetchedCustomer({
                        ...fetchedCustomer,
                        guaranteeFormula: removePrefixFromStartRecursively(event.target.value.trim()),
                      })
                    }}
                  />
                </div>
              </FormRow>
              <FormRow title={t('customers.notes')} inputType="textArea">
                <textarea
                  className="form-control"
                  id="notes"
                  rows={3}
                  value={fetchedCustomer.notes !== null ? fetchedCustomer.notes : ''}
                  onChange={(event) => {
                    setFetchedCustomer({
                      ...fetchedCustomer,
                      notes: event.target.value,
                    })
                  }}
                />
              </FormRow>
              <FormRow title={t('customers.documentFilesRequired')}>
                <div className="form-row-special-input">
                  <div className="form-check d-flex align-items-center me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="areDocumentFilesRequired"
                      id="noDocumentFilesRequired"
                      value="false"
                      checked={!fetchedCustomer.documentFilesRequired}
                      onChange={() => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          documentFilesRequired: false,
                        })
                      }}
                    />
                    <label className="form-check-label text-nowrap ps-1" htmlFor="noDocumentFilesRequired">
                      {`${t('common.no')} (0)`}
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="areDocumentFilesRequired"
                      id="documentFilesRequired"
                      value="true"
                      checked={fetchedCustomer.documentFilesRequired}
                      onChange={() => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          documentFilesRequired: true,
                        })
                      }}
                    />
                    <label className="form-check-label text-nowrap ps-1" htmlFor="documentFilesRequired">
                      {`${t('common.yes')} (1)`}
                    </label>
                  </div>
                </div>
              </FormRow>
              <FormRow title={t('customers.requiresAudit')}>
                <div className="form-row-special-input">
                  <div className="form-check d-flex align-items-center me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IsAuditRequired"
                      id="noAuditRequired"
                      value="false"
                      checked={!fetchedCustomer.requiresAudit}
                      onChange={() => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          requiresAudit: false,
                        })
                      }}
                    />
                    <label className="form-check-label text-nowrap ps-1" htmlFor="noAuditRequired">
                      {`${t('common.no')} (0)`}
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isAuditRequired"
                      id="auditRequired"
                      value="true"
                      checked={fetchedCustomer.requiresAudit}
                      onChange={() => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          requiresAudit: true,
                        })
                      }}
                    />
                    <label className="form-check-label text-nowrap ps-1" htmlFor="auditRequired">
                      {`${t('common.yes')} (1)`}
                    </label>
                  </div>
                </div>
              </FormRow>
              <FormRow title={t('customers.apiTokenAccess')}>
                <div className="form-row-special-input">
                  <div className="form-check d-flex align-items-center me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="apiAccessEnabled"
                      id="noApiAccessEnabled"
                      value="false"
                      checked={!fetchedCustomer.isApiAccessEnabled}
                      onChange={() => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          isApiAccessEnabled: false,
                        })
                      }}
                    />
                    <label className="form-check-label text-nowrap ps-1" htmlFor="noApiAccessEnabled">
                      {`${t('common.no')} (0)`}
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="apiAccessEnabled"
                      id="yesApiAccessEnabled"
                      value="true"
                      checked={fetchedCustomer.isApiAccessEnabled}
                      onChange={() => {
                        setFetchedCustomer({
                          ...fetchedCustomer,
                          isApiAccessEnabled: true,
                        })
                      }}
                    />
                    <label className="form-check-label text-nowrap ps-1" htmlFor="yesApiAccessEnabled">
                      {`${t('common.yes')} (1)`}
                    </label>
                  </div>
                </div>
              </FormRow>
            </>
          )}
        </div>

        <CustomerContext.Provider value={
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          {
            guaranteeTypes,
            isNctsProfileModalVisible: isProfileModalVisible,
            setNctsProfileModalVisibility: setIsProfileModalVisible,
            nctsProfile,
            setNctsProfile,
            isSharing: Number(customerId) !== customer?.id,
            customerId: fetchedCustomer.id,
          }
        }
        >
          {
            !isExternalCustomer && customer !== undefined && (
              <UsersTable
                customerId={customerId}
                reFetch={!isProfileModalVisible && Number(customerId) !== customer.id}
              />
            )
          }
          <NctsProfileModal
            isProfileModalVisible={isProfileModalVisible}
            toggleProfileModal={setIsProfileModalVisible}
            customsProfile={nctsProfile}
            setCustomsProfile={setNctsProfile}
            isSharing={Number(customerId) !== customer?.id && fetchedCustomer.profileStatus === 'APPROVED'}
            customerId={fetchedCustomer.id}
            guaranteeTypes={guaranteeTypes}
            authorityOffice={guaranteeOffices}
            authorityCountry={countries}
          />

        </CustomerContext.Provider>
      </div>
    </>
  )
}
