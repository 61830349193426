import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from 'services/api.service'
// eslint-disable-next-line import/no-cycle
import { SeoTag } from '../tabs/Seo/hooks/useSeoTags'

function getSeoTags(controller?: AbortController): Promise<SeoTag[]> {
  return axios
    .get<{ data: SeoTag[] }>(
    apiService.getFullApiUrl('/configuration/seo-tags'),
    { signal: controller?.signal },
  )
    .then((handleResponseData))
    .catch(handleApiException)
}

function putSeoTag(value: string, seoTag: 'SEO_TITLE' | 'SEO_DESCRIPTION' | 'SEO_KEYWORDS') {
  return axios
    .put(apiService.getFullApiUrl('/configuration/seo-tag'), {
      value,
    }, {
      params: {
        seoTag,
      },
    })
    .then(handleResponseData)
    .catch(handleApiException)
}

const SeoService = {
  getSeoTags,
  putSeoTag,
}

export default SeoService
