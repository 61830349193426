import { z } from 'zod'
import { DirectoEventResponseStatusEnum } from '../../../common/models/directo-event-response'

const DirectoFormSchema = z.object({
  id: z.number().optional(),
  customerId: z.number().nullable(),
  descriptionAddendum: z.string().optional(),
  status: z.enum([
    DirectoEventResponseStatusEnum.IDLE,
    DirectoEventResponseStatusEnum.QUEUED,
    DirectoEventResponseStatusEnum.SUBMITTED,
    DirectoEventResponseStatusEnum.ERROR,
    DirectoEventResponseStatusEnum.FAILED,
  ]),
})

export type DirectoFormType = z.infer<typeof DirectoFormSchema>

export default DirectoFormSchema
