import React, {
  useDeferredValue, useEffect, useRef, useState,
} from 'react'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import LoadingSpinner from 'components/LoadingSpinner'
import { CodelistResponse } from 'routes/phase5/common/models'
import Tooltip from 'rc-tooltip'
import { ZodIssue } from 'zod'
import useCommodityCodeSearch, { CombinedNomenclatureOption } from './useCommodityCodeSearch'
import DropdownToggleButton, { IconState } from './DropdownToggleButton'
import { hasText } from '../../../common/utils/common-util'

export interface CommodityCodeSearchProps {
  scope: `houseConsignment.${number}.consignmentItem.${number}`
  country: string | null
}

function isClearEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  return event.currentTarget.value === '' && event.nativeEvent.type === 'input'
}

function mapToOptions(content: CodelistResponse[]) {
  return content.map<CombinedNomenclatureOption>(
    (codelistItem) => ({
      value: codelistItem.code ?? '',
      codeLabel: codelistItem.codeLabel ?? '',
      name: codelistItem.nameEn ?? '',
      isArchived: codelistItem.isArchived ?? false,
    }),
  )
}

const DIGIT_OR_WHITESPACE = /^[\d\s]{0,10}$/

function CommodityCodeSearch({ scope, country }: CommodityCodeSearchProps) {
  const { t } = useTranslation()
  const {
    setValue, getValues, setError,
  } = useFormContext()

  const [show, setShow] = useState(false)
  const dropdownItemsObserver = useRef(null)
  const [wasPreviousPageLast, setWasPreviousPageLast] = useState(false)
  const [currentSearch, setCurrentSearch] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(0)
  const [options, setOptions] = useState<CombinedNomenclatureOption[]>([])
  const [selectedOption, setSelectedOption] = useState<CombinedNomenclatureOption>()
  const deferredDropdownItems = useDeferredValue<CombinedNomenclatureOption[]>(options)
  const [isInvalidCode, setIsInvalidCode] = useState(false)

  const {
    isLoading,
    data,
  } = useCommodityCodeSearch(currentSearch, pageNumber, country)

  useEffect(() => {
    if (!hasText(getValues(`${scope}.commodityHarmonizedSystemSubHeadingCode`))
      && !hasText(getValues(`${scope}.commodityCombinedNomenclatureCode`))) {
      return
    }

    const hsCode = getValues(`${scope}.commodityHarmonizedSystemSubHeadingCode`)?.trim() ?? ''
    const cnCode = hasText(getValues(`${scope}.commodityCombinedNomenclatureCode`)) ? `${getValues(`${scope}.commodityCombinedNomenclatureCode`)?.trim()}` : ''
    const hsCodeLabel = hsCode.length > 4 ? `${hsCode.substring(0, 4)} ${hsCode.substring(4, 6)}` : hsCode
    const prefilledInput = `${hsCodeLabel} ${cnCode}`.trim()
    setCurrentSearch(prefilledInput)

    setSelectedOption({
      value: hsCode + cnCode,
      codeLabel: prefilledInput,
      name: '',
      isArchived: false,
    })
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting) {
          if (!isLoading && !wasPreviousPageLast) {
            setPageNumber((page) => page + 1)
          }
        }
      },
      { threshold: 0.2 },
    )

    if (dropdownItemsObserver.current) {
      observer.observe(dropdownItemsObserver.current)
    }

    return () => {
      if (dropdownItemsObserver.current && observer) {
        observer.unobserve(dropdownItemsObserver.current)
      }
    }
  }, [dropdownItemsObserver, dropdownItemsObserver.current, isLoading, pageNumber, wasPreviousPageLast])

  useEffect(() => {
    if (data) {
      if (pageNumber !== 0) {
        setOptions(options.concat(mapToOptions(data.content)))
      } else {
        const nomenclatureOptions = mapToOptions(data.content)
        setOptions(nomenclatureOptions)
        const optionsMatch = nomenclatureOptions.find((option) => option.value === selectedOption?.value)
        if (optionsMatch) {
          setSelectedOption({
            value: optionsMatch?.value ?? '',
            codeLabel: optionsMatch?.codeLabel ?? '',
            name: optionsMatch?.name ?? '',
            isArchived: optionsMatch?.isArchived ?? false,
          })
        }
      }
      setWasPreviousPageLast(data.last)
    }
  }, [data])

  useEffect(() => {
    setPageNumber(0)
    setWasPreviousPageLast(false)
  }, [currentSearch])

  useEffect(() => {
    const commodityCode = selectedOption?.value ?? ''
    if (commodityCode.length > 6) {
      setValue(`${scope}.commodityHarmonizedSystemSubHeadingCode`, commodityCode.substring(0, 6))
      setValue(`${scope}.commodityCombinedNomenclatureCode`, commodityCode.substring(6, commodityCode.length))
      setValue(`${scope}.commodityCodeArchived`, selectedOption?.isArchived)
    } else {
      setValue(`${scope}.commodityHarmonizedSystemSubHeadingCode`, commodityCode)
      setValue(`${scope}.commodityCombinedNomenclatureCode`, '')
      setValue(`${scope}.commodityCodeArchived`, selectedOption?.isArchived)
    }

    if (options.length === 0 && hasText(commodityCode)) {
      setIsInvalidCode(true)
      setError(`${scope}.commodityHarmonizedSystemSubHeadingCode`, {
        code: 'custom', path: `${scope}.commodityHarmonizedSystemSubHeadingCode`.split('.'), fatal: true, message: 'Invalid',
      } as ZodIssue)
    } else {
      setIsInvalidCode(false)
    }
  }, [selectedOption])

  let iconState: IconState
  if (selectedOption?.isArchived) {
    iconState = 'ARCHIVED'
  } else if (isLoading) {
    iconState = 'LOADING'
  } else {
    iconState = show ? 'OPEN' : 'CLOSED'
  }

  return (
    <Dropdown
      as={ButtonGroup}
      className="col-12"
      show={show}
      onSelect={(eventKey) => {
        const optionMatch = deferredDropdownItems.find((option) => option.value === eventKey)
        if (optionMatch) {
          setSelectedOption(optionMatch)
          setCurrentSearch(optionMatch.codeLabel)
          setShow(false)
        }
      }}
      onBlur={(event) => {
        if (!event.relatedTarget?.classList.contains('dropdown-item')) {
          if (selectedOption === undefined) {
            setCurrentSearch('')
          } else if (currentSearch !== selectedOption.codeLabel) {
            setCurrentSearch(selectedOption.codeLabel)
          }
          setShow(false)
        }
      }}

    >
      <Form.Control
        maxLength={11}
        autoComplete="off"
        disabled={selectedOption?.isArchived}
        value={currentSearch}
        className={isInvalidCode ? 'is-invalid' : ''}
        type="search"
        name="commodityCode"
        onChange={(event) => {
          if (isClearEvent(event)) {
            setCurrentSearch(event.currentTarget.value)
            setSelectedOption(undefined)
            setOptions([])

            return
          }
          if (DIGIT_OR_WHITESPACE.test(event.currentTarget.value)) {
            setCurrentSearch(event.currentTarget.value)
          }
        }}
        onFocus={() => {
          setShow(!show)
        }}
      />
      <Tooltip
        overlayClassName="tooltip-lg"
        visible={selectedOption?.isArchived ? undefined : false}
        overlay={<small>{t('messages.archivedCommodityCodes')}</small>}
      >
        <Dropdown.Toggle
          className="no-after-caret col-2 col-lg-2 col-md-2 col-sm-1 px-2"
          onClick={() => {
            if (selectedOption?.isArchived && iconState === 'ARCHIVED') {
              setCurrentSearch('')
              setSelectedOption(undefined)
              setOptions([])
            } else {
              setShow(!show)
            }
          }}
          icon={iconState}
          as={DropdownToggleButton}
        />
      </Tooltip>

      <Dropdown.Menu
        className="shadow w-100 overflow-y-auto"
        align="end"
      >
        {
          (deferredDropdownItems.length === 0 && !isLoading) && (
            <Dropdown.ItemText className="py-0">
              <span>{t('declaration.p5.commodityCodeSearch')}</span>
            </Dropdown.ItemText>
          )
        }
        {
          deferredDropdownItems
            .filter((option) => !option.isArchived)
            .map((option) => (
              <Dropdown.Item
                key={option.value}
                eventKey={option.value}
              >
                {`${option.codeLabel} - ${option.name}`}
              </Dropdown.Item>
            ))
        }
        <Dropdown.ItemText className="text-center py-1" ref={dropdownItemsObserver}>
          {
            iconState === 'LOADING' && (
              <LoadingSpinner />
            )
          }
        </Dropdown.ItemText>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CommodityCodeSearch
