import React from 'react'
import { CKEditor } from 'ckeditor4-react'

interface EditorProps {
  editorContent: string;
  setEditorContent: (content: string) => void;
}

const config = {
  height: 'inherit',
  resize_enabled: false,
}
export default function Editor({
  editorContent,
  setEditorContent,
}: EditorProps) {
  return (
    <div className="settings__container__editor">
      <CKEditor
        config={config}
        name="settings-editor"
        onGetData={() => editorContent}
        initData={editorContent}
        onChange={({ editor }) => {
          setEditorContent(editor.getData())
        }}
      />
    </div>
  )
}
