import { IGoodsItem } from 'types/IGoodsItem'
import { Document, DocumentType } from 'types/Document'
import { FieldUsage, getFieldUsageAccordingToC60 } from 'helpers/condition.helper'
// eslint-disable-next-line import/no-cycle
import {
  blankDocument, blankGoodsItem, blankPreviousDocument, blankTrader, MappedColumns,
} from '../..'
import { purgeCharacters } from '../../../../../helpers/string.helper'
import { toast } from 'react-toastify';

const DOCUMENT_NUMBER_MAX_LENGTH = 35

function useImportGoods() {
  const extractPrefixFromDelimiter = (column: MappedColumns, prefix: string) => {
    if (column.field.split('_')[1] !== undefined) {
      // eslint-disable-next-line prefer-destructuring,no-param-reassign
      prefix = column.field.split('_')[0]
    }

    return prefix
  }

  function mapDocumentTypes(column: MappedColumns, rowValueForColumn: string, goodsItem: IGoodsItem) {
    let sortOrder: number | null = Number(column.header.split('_')[1])
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(sortOrder)) {
      sortOrder = Number(column.header.split('_')[2])
    }
    if (sortOrder === null || sortOrder <= 0) {
      return
    }
    // eslint-disable-next-line no-plusplus
    sortOrder-- // convert to 0-indexed

    if (column.header.split('_')[0] !== 'previous') {
      if (!rowValueForColumn.includes('DOCUMENT_TYPE_')) {
        // eslint-disable-next-line no-param-reassign
        rowValueForColumn = `DOCUMENT_TYPE_${rowValueForColumn}`
      }

      let goodsDocument = goodsItem.documents.find((docs) => (docs.type === 'GOODS' && docs.sortOrder === sortOrder))
      if (goodsDocument === undefined) {
        goodsDocument = { ...blankDocument, sortOrder, documentType: rowValueForColumn }
        goodsItem.documents.push(goodsDocument)
      } else {
        goodsDocument.sortOrder = sortOrder
        goodsDocument.documentType = rowValueForColumn
      }
    }
    if (column.header.split('_')[0] === 'previous') {
      // eslint-disable-next-line no-restricted-globals
      let previousDocument = goodsItem.documents.find((docs) => (docs.type === 'PREVIOUS' && docs.sortOrder === sortOrder))
      if (previousDocument === undefined) {
        previousDocument = { ...blankPreviousDocument, sortOrder, documentType: `PREVIOUS_DOCUMENT_TYPE_${rowValueForColumn}` }
        goodsItem.documents.push(previousDocument)
      } else {
        previousDocument.sortOrder = sortOrder
        previousDocument.documentType = rowValueForColumn
      }
    }
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const mapCommodityCodeToGoodsItem = (column: MappedColumns, rowValueForColumn: string, goodsItemRow: any) => {
    if (column.field === 'hsCode') {
      const rawCode = rowValueForColumn.replace(/[ ]+/g, '')
      if (rawCode.length === 8) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = rawCode
        // eslint-disable-next-line no-param-reassign
        goodsItemRow.visualCode = `${rawCode.substring(0, 4)} ${rawCode.substring(4, 6)} ${rawCode.substring(6, 8)}`
      } else if (rawCode.length === 6 || rawCode.length === 4) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = rawCode.padEnd(8, '0')
        if (rawCode.length === 6) {
          // eslint-disable-next-line no-param-reassign
          goodsItemRow.visualCode = `${rawCode.substring(0, 4)} ${rawCode.substring(4, 6)}`
        } else {
          // eslint-disable-next-line no-param-reassign
          goodsItemRow.visualCode = rawCode
        }
      } else if (rawCode.length >= 10) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = rawCode.padEnd(8, '0').substring(0, 8)
        // eslint-disable-next-line no-param-reassign
        goodsItemRow.visualCode = `${rawCode.substring(0, 4)} ${rawCode.substring(4, 6)} ${rawCode.substring(6, 8)}`
      }
    }
  }

  const mapEnumFieldsToGoodsItem = (column: MappedColumns, rowValueForColumn: string, goodsItemRow: any) => {
    if (column.field === 'quantityUnit') {
      if (!rowValueForColumn.includes('PACKAGE_TYPE_')) {
        if (rowValueForColumn.length > 2) {
          toast.error(`Invalid quantity unit ${rowValueForColumn} , please remove it from CSV`, {autoClose: false})
          // eslint-disable-next-line no-param-reassign
          goodsItemRow[column.field] = ''
        } else {
          // eslint-disable-next-line no-param-reassign
          goodsItemRow[column.field] = `PACKAGE_TYPE_${rowValueForColumn}`
        }
      } else if (rowValueForColumn.includes('PACKAGE_TYPE_')) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = rowValueForColumn
      }
    }
    if (column.field === 'grossWeight' || column.field === 'quantity') {
      // eslint-disable-next-line no-param-reassign
      goodsItemRow[column.field] = rowValueForColumn.replaceAll(',', '.')
      if (!Number.isNaN(Number(goodsItemRow[column.field]))) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = Number(goodsItemRow[column.field])
      }
    }
    if (column.field === 'dispatchCountryCode') {
      if (!rowValueForColumn.includes('COUNTRY_')) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = `COUNTRY_${rowValueForColumn}`
      } else if (rowValueForColumn.includes('COUNTRY_')) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = rowValueForColumn
      }
    }
    if (column.field === 'destinationCountryCode') {
      if (!rowValueForColumn.includes('COUNTRY_')) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = `COUNTRY_${rowValueForColumn}`
      } else if (rowValueForColumn.includes('COUNTRY_')) {
        // eslint-disable-next-line no-param-reassign
        goodsItemRow[column.field] = rowValueForColumn
      }
    }

    return goodsItemRow
  }

  function mapDocumentNumber(column: MappedColumns, rowValueForColumn: string, goodsItem: IGoodsItem) {
    let documentNumber = rowValueForColumn
    let sortOrder: number | null = Number(column.header.split('_')[1])
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(sortOrder)) {
      sortOrder = Number(column.header.split('_')[2])
    }

    if (sortOrder === null || sortOrder <= 0) {
      return
    }
    // eslint-disable-next-line no-plusplus
    sortOrder-- // convert to 0-indexed

    if (documentNumber.length >= DOCUMENT_NUMBER_MAX_LENGTH) {
      documentNumber = documentNumber.substring(0, DOCUMENT_NUMBER_MAX_LENGTH)
    }

    if (column.header.split('_')[0] === 'documentNumber') {
      let goodsDocument = goodsItem.documents.find((docs) => (docs.type === 'GOODS' && docs.sortOrder === sortOrder))
      if (goodsDocument === undefined) {
        goodsDocument = { ...blankDocument, sortOrder, number: documentNumber }
        goodsItem.documents.push(goodsDocument)
      } else {
        goodsDocument.sortOrder = sortOrder
        goodsDocument.number = documentNumber
      }
    }
    if (column.header.split('_')[0] === 'previous') {
      let previousDocument = goodsItem.documents.find((docs) => (docs.type === 'PREVIOUS' && docs.sortOrder === sortOrder))
      if (previousDocument === undefined) {
        previousDocument = { ...blankPreviousDocument, sortOrder, number: documentNumber }
        goodsItem.documents.push(previousDocument)
      } else {
        previousDocument.sortOrder = sortOrder
        previousDocument.number = documentNumber
      }
    }
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const mapDocumentsToGoodsItem = (column: MappedColumns, rowValueForColumn: string, goodsItemRow: any) => {
    if (column.field === 'documentType' || column.field === 'previous_documentType') {
      if (rowValueForColumn) {
        mapDocumentTypes(column, rowValueForColumn, goodsItemRow)
      }
    }
    if (column.field === 'number' || column.field === 'previous_documentNumber') {
      if (rowValueForColumn) {
        mapDocumentNumber(column, rowValueForColumn, goodsItemRow)
      }
    }
    if (column.field === 'orderNumber') {
      let sortOrder: number | null = Number(column.header.split('_')[1])
      // eslint-disable-next-line no-restricted-globals
      if (sortOrder === null || sortOrder <= 0) {
        return
      }
      // eslint-disable-next-line no-plusplus
      sortOrder-- // convert to 0-indexed

      let previousDocument = goodsItemRow.documents.find((docs: Document) => (docs.type === 'PREVIOUS' && docs.sortOrder === sortOrder))
      if (previousDocument === undefined) {
        previousDocument = { ...blankPreviousDocument, sortOrder, orderNumber: rowValueForColumn }
        goodsItemRow.documents.push(previousDocument)
      } else {
        previousDocument.sortOrder = sortOrder
        previousDocument.orderNumber = rowValueForColumn
      }
    }
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const mapFileRowsToGoodsItems = (csvRows: Array<any>, columnData: MappedColumns[]) => {
    const arrayOfGoodsItems: Array<IGoodsItem> = []
    // eslint-disable-next-line no-param-reassign
    csvRows = csvRows.filter((csvRow) => !Array.isArray(csvRow))
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    csvRows.forEach((item: any, index: number) => {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const goodsItem: any = {
        ...blankGoodsItem,
        consignor: {
          ...blankTrader,
          target: 'CONSIGNOR',
        },
        consignee: {
          ...blankTrader,
          target: 'CONSIGNEE',
        },
      }
      goodsItem.sortOrder = index
      goodsItem.documents = []

      columnData.forEach((column: MappedColumns) => {
        let prefix: string = ''
        prefix = extractPrefixFromDelimiter(column, prefix)
        if (item[column.header]) {
          const rowValueForColumn: string = purgeCharacters(item[column.header].replace(/['"]+/g, ''))
          if (prefix !== '' && prefix !== 'previous') {
            const childObjectField = column.field.substring(prefix.length + 1)
            let rowCountryValueForColumn = `${rowValueForColumn}`
            if (childObjectField.includes('country')) {
              if (!rowCountryValueForColumn.includes('COUNTRY_')) {
                rowCountryValueForColumn = `COUNTRY_${rowCountryValueForColumn}`
              }
            }
            goodsItem[prefix][childObjectField] = rowCountryValueForColumn
          } else if ((typeof goodsItem[column.field]) !== undefined) {
            goodsItem[column.field] = rowValueForColumn
          }
          mapCommodityCodeToGoodsItem(column, rowValueForColumn, goodsItem)
          mapEnumFieldsToGoodsItem(column, rowValueForColumn, goodsItem)
          mapDocumentsToGoodsItem(column, rowValueForColumn, goodsItem)
        }
      })
      if (goodsItem.documents.filter((previousDoc: Document) => previousDoc.type === DocumentType.PREVIOUS).length === 0) {
        goodsItem.documents.push({ ...blankPreviousDocument })
      }
      if (goodsItem.documents.filter((goodsDoc: Document) => goodsDoc.type === DocumentType.GOODS).length === 0) {
        goodsItem.documents.push({ ...blankDocument })
      }
      arrayOfGoodsItems.push(goodsItem)
    })

    return arrayOfGoodsItems.map((goodsItem) => {
      if (getFieldUsageAccordingToC60(goodsItem.quantityUnit, 'QUANTITY') !== FieldUsage.REQUIRED) {
        // eslint-disable-next-line no-param-reassign
        goodsItem.quantity = null
      }
      if (getFieldUsageAccordingToC60(goodsItem.quantityUnit, 'MARKS') !== FieldUsage.REQUIRED) {
        // eslint-disable-next-line no-param-reassign
        goodsItem.marks = ''
      }
      if (getFieldUsageAccordingToC60(goodsItem.quantityUnit, 'PIECES') !== FieldUsage.REQUIRED) {
        // eslint-disable-next-line no-param-reassign
        goodsItem.pieces = null
      }

      return goodsItem
    })
  }

  return {
    mapFileRowsToGoodsItems,
  }
}

export default useImportGoods
