import { TFunction } from 'i18next'
import { ISelectOption } from 'types/IClassifier'
import { Header } from 'routes/Transits/Table/config'

export function getTableHeaders(t: TFunction): Header[] {
  return [
    {
      title: t('customers.id'),
      accessor: 'id',
    },
    {
      title: t('common.name', { context: 'capitalized' }),
      accessor: 'name',
      className: 'fixed-width-lg',
    },
    {
      title: t('customers.registryCode'),
      accessor: 'registryCode',
      className: 'fixed-width-md',
    },
    {
      title: t('customers.address'),
      accessor: 'address',
    },
    {
      title: t('customers.users'),
      accessor: 'usersCount',
      className: 'fixed-width-sm-l',
    },
    {
      title: t('pages.page', { context: 'transits' }),
      accessor: 'declarationsCount',
      className: 'fixed-width-sm-l',
    },
    {
      title: t('transit.status'),
      accessor: 'status',
      className: 'fixed-width-sm',
    },
    {
      title: t('customers.external'),
      accessor: 'external',
      className: 'fixed-width-sm-l',
    },
    {
      title: t('customers.modified'),
      accessor: 'modified',
      className: 'fixed-width-lg',
    },
  ]
}

export function getCustomerStatusOptions() {
  return [
    {
      value: 'ACCEPTED',
      label: 'Accepted',
    },
    {
      value: 'BLOCKED',
      label: 'Blocked',
    },
    {
      value: 'ARCHIVED',
      label: 'Archived',
    },
  ] as ISelectOption[]
}

export function getExternalCustomerOptions() {
  return [
    {
      value: 'false',
      label: 'Only internal',
    },
    {
      value: 'true',
      label: 'Only external',
    },
    {
      value: null,
      label: 'All',
    },
  ] as ISelectOption[]
}
