import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import UserService from '../../services/user.service'
import { CurrentCustomer } from '../../../../types/Customer'

const currentCustomer: CurrentCustomer = {
  city: '',
  contactEmail: '',
  country: 'COUNTRY_EE',
  name: '',
  registryCode: '',
  id: null,
  status: undefined,
  street: '',
  zipCode: '',
  profileStatus: '',
  clientApiAccess: false,
}
function useCurrentCustomer() {
  const [customer, setCustomer] = useState<CurrentCustomer>(currentCustomer)

  useEffect(() => {
    UserService.getCurrentCustomer()
      .then((info) => {
        setCustomer({
          id: info.id,
          city: info.city,
          country: info.country,
          contactEmail: info.contactEmail,
          name: info.name,
          registryCode: info.registryCode,
          street: info.street,
          zipCode: info.zipCode,
          status: undefined,
          profileStatus: info.profileStatus,
          clientApiAccess: info.clientApiAccess,
        })
      })
      .catch((reason) => {
        // eslint-disable-next-line no-console
        console.error(reason)
        toast.error('Error loading profile')
      })
  }, [])

  return {
    customer,
    setCustomer,
  }
}

export default useCurrentCustomer
