import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { PackagingType } from '../../../form/schemas/consignmentItemSchema'
import { blankPackaging } from '../../../form'
import { PACKAGE_TYPE_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted'

function isPackagingField(field: string): field is Exclude<ObjectKeys<PackagingType>, NotImportableFields> {
  return field in blankPackaging
}

export default function mapPackaging(
  newImportedItemObject: PackagingType[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isPackagingField(consignmentItemObjectSubfield)) {
    return
  }
  let packaging = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)
  if (packaging === undefined) {
    packaging = {
      ...blankPackaging,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(packaging)
  }
  switch (consignmentItemObjectSubfield) {
    case 'typeOfPackages':
      packaging[consignmentItemObjectSubfield] = hasText(cellValue) ? PACKAGE_TYPE_CODE_PREFIX + cellValue : ''
      break
    case 'shippingMarks':
      packaging[consignmentItemObjectSubfield] = cellValue
      break
    case 'numberOfPackages':
      packaging[consignmentItemObjectSubfield] = Number.isNaN(Number(cellValue)) ? null : Number(cellValue)
      break
    default:
      throw Error(`Unknown packaging field ${consignmentItemObjectSubfield}`)
  }
}
