import { TFunction } from 'i18next'
import { ISelectOption } from '../../../types/IClassifier'
import { UserPreference } from '../../../types/UserPreference'

export interface Header {
  title: string
  accessor: string
  columns?: []
  width?: number
  maxWidth?: number
  minWidth?: number
  sticky?: 'left' | 'right'
  className? : string
}

const addDefaultFieldsToTable = (columns: Array<Header>, t: TFunction) => {
  columns.push({
    title: t('transit.id'),
    accessor: 'id',
    sticky: 'left',
    maxWidth: 86,
    className: 'fixed-width-sm',
  }, {
    title: t('transit.mrnAndLrn'),
    accessor: 'mrn',
    sticky: 'left',
    width: 215,
    className: 'fixed-width-lg',
  })
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
const mapPreferencesToFields = (userPreferences: Array<UserPreference>, columns: Array<Header>, t: TFunction) => {
  // eslint-disable-next-line consistent-return
  userPreferences.forEach((item: UserPreference) => {
    if (!item.deleted) {
      if (item.value === 'TRANSIT_CONSIGNOR') {
        return columns.push({
          title: t('transit.consignor'),
          accessor: 'consignorName',
          className: 'fixed-width-lg',
        })
      }
      if (item.value === 'TRANSIT_CONSIGNEE') {
        return columns.push({
          title: t('transit.consignee'),
          accessor: 'consigneeName',
          className: 'fixed-width-lg',
        })
      }
      if (item.value === 'TRANSIT_CARRIER') {
        return columns.push({
          title: t('transit.carrier'),
          accessor: 'carrierName',
          className: 'fixed-width-lg',
        })
      }
      if (item.value === 'TRANSIT_TRANSPORT') {
        return columns.push({
          title: t('transit.transport'),
          accessor: 'departureTruckNo',
          className: 'fixed-width-md',
        })
      }
      if (item.value === 'TRANSIT_DEPARTURE_CUSTOMS') {
        return columns.push({
          title: t('transit.departureCustomsOffice'),
          accessor: 'departureCustomsOffice',
          className: 'fixed-width-sm-l',
        })
      }

      if (item.value === 'TRANSIT_DESTINATION_CUSTOMS') {
        return columns.push({
          title: t('transit.destinationCustomsOffice'),
          accessor: 'destinationCustomsOffice',
          className: 'fixed-width-sm-l',
        })
      }

      if (item.value === 'TRANSIT_CUSTOMER') {
        return columns.push({
          title: t('transit.customer'),
          accessor: 'customerName',
          className: 'fixed-width-md',
        })
      }
      if (item.value === 'TRANSIT_STATUS') {
        return columns.push({
          title: t('transit.status'),
          accessor: 'status',
          className: 'fixed-width-md',
        })
      }
      if (item.value === 'TRANSIT_NOTES') {
        return columns.push({
          title: t('buttons.notes'),
          accessor: 'notes',
          className: 'fixed-width-md',
        })
      }
      if (item.value === 'TRANSIT_DIRECTO_STATUS') {
        return columns.push({
          title: t('transit.directoStatus'),
          accessor: 'directoStatus',
          className: 'fixed-width-md',
        })
      }
      if (item.value === 'TRANSIT_DIRECTO') {
        return columns.push({
          title: t('customers.directoClientCode'),
          accessor: 'directoClientCode',
          className: 'fixed-width-md-sm',
        })
      }
      if (item.value === 'TRANSIT_T1_GRN') {
        return columns.push({
          title: t('customers.publicDeclaration'),
          accessor: 'usesT1Grn',
          className: 'fixed-width-md-sm',
        })
      }
      if (item.value === 'TRANSIT_PROFILE') {
        return columns.push({
          title: t('declaration.nctsProfile'),
          accessor: 'guaranteeProfile',
          className: 'fixed-width-md-sm',
        })
      }
      if (item.value === 'TRANSIT_REVIEWER') {
        return columns.push(
          {
            title: t('transit.reviewer'),
            accessor: 'reviewedBy',
            className: 'fixed-width-md-sm',
          },
          {
            title: t('transit.lastReview'),
            accessor: 'dateReviewed',
            className: 'fixed-width-md-sm',
          },
        )
      }
      if (item.value === 'TRANSIT_GUARANTEE') {
        return columns.push({
          title: t('transit.guaranteeAmount'),
          accessor: 'guaranteeAmount',
          className: 'fixed-width-md-sm',
        })
      }
      if (item.value === 'TRANSIT_CREATED_BY') {
        return columns.push({
          title: t('transit.createdByName'),
          accessor: 'createdByName',
          className: 'fixed-width-md-s',
        })
      }
      if (item.value === 'TRANSIT_MODIFIED') {
        return columns.push({
          title: t('transit.modified'),
          accessor: 'modified',
        })
      }
      if (item.value === 'TRANSIT_CREATED') {
        return columns.push({
          title: t('declaration.created'),
          accessor: 'created',
          className: 'fixed-width-md-sm',
        })
      }
      if (item.value === 'TRANSIT_LAST_CUSTOMS_UPDATE') {
        return columns.push({
          title: t('declaration.latestCustomsUpdate'),
          accessor: 'latestCustomsUpdate',
        })
      }
      if (item.value === 'TRANSIT_DATE_LIMIT') {
        return columns.push({
          title: t('declaration.transitDateLimit'),
          accessor: 'transitDateLimit',
        })
      }
    }
  })
}

export function getTableHeaders(
  t: TFunction,
  isMobile: boolean,
  isAdmin: boolean,
  tablePreferences?: Array<UserPreference>,
): Array<Header> {
  const columns: Array<Header> = []
  addDefaultFieldsToTable(columns, t)
  if (tablePreferences !== undefined && tablePreferences?.length !== 0) {
    mapPreferencesToFields(tablePreferences, columns, t)
  }

  return columns
}

export function getHeader(accessor: string, t: TFunction): Header | undefined {
  if (accessor === 'mobileMRN' || accessor === 'lrn') {
    // eslint-disable-next-line no-param-reassign
    accessor = 'mrn'
  }
  return getTableHeaders(t, false, false).find((column: Header) => column.accessor === accessor)
}

export function getStatusOptions(t: TFunction) {
  return [
    { value: 'CHECK_NOT_OK', label: t('declaration.status.checkOk') },
    { value: 'CONTROL_DECISION', label: t('declaration.status.control') },
    { value: 'DRAFT', label: t('declaration.status.draft') },
    { value: 'REJECTED', label: t('declaration.status.rejected') },
    { value: 'ACCEPTED', label: t('declaration.status.accepted') },
    { value: 'CANCEL_REQUESTED', label: t('declaration.status.cancelRequested') },
    { value: 'SUBMISSION_CONFIRMED', label: t('declaration.status.confirmed') },
    { value: 'OPEN', label: t('declaration.status.open') },
    { value: 'DECLINED', label: t('declaration.status.declined') },
    { value: 'DISCREPANCIES', label: t('declaration.status.discrepancies') },
    { value: 'CANCELED', label: t('declaration.status.canceled') },
    { value: 'TRANSIT_PROCEDURE_COMPLETED', label: t('declaration.status.ended') },
    { value: 'IE006_ARRIVED', label: t('declaration.status.arrived') },
    { value: 'GOODS_RELEASE_NOTIFICATION', label: t('declaration.status.goodsReleaseNotification') },
  ] as ISelectOption[]
}

export function getDirectoStatusOptions() {
  return [
    { value: 'SUBMITTED', label: 'Submitted' },
    { value: 'ERROR', label: 'Error' },
    { value: 'FAILED', label: 'Failed' },
    { value: 'QUEUED', label: 'Queued' },
    { value: 'IDLE', label: 'Idle' },
  ] as ISelectOption[]
}

export function getPublicOptions() {
  return [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ] as ISelectOption[]
}
