import { toast } from 'react-toastify'

import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import DeclarationApi from '../../apiConfig'
import useCancelActionApi from './api'
import { UseDeclarationFormReturn } from '../../../form'

const {
  queryKeys: {
    getTransitOperationById,
  },
} = DeclarationApi.paths.transitOperation

function useCancelAction(form: UseDeclarationFormReturn) {
  const {
    getValues,
  } = form

  const { cancel } = useCancelActionApi()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const cancelTransitOperation = async () => {
    const transitOperationId = getValues('id')
    if (transitOperationId === null) throw Error('Unable to submit without id')

    try {
      await cancel.mutateAsync({ id: transitOperationId })
      await queryClient.resetQueries({ queryKey: getTransitOperationById(transitOperationId) }).then(() => {
        toast.success(t('buttons.cancel', { context: 'success' }))
      })
    } catch (error) {
      toast.error(t('buttons.cancel', { context: 'error' }))
      // eslint-disable-next-line no-console
      console.error('An error has occurred: ', error) // T1-1272
    }
  }

  return {
    cancelTransitOperation,
  }
}

export default useCancelAction
