import { useStore } from 'react-redux'
import { IDeclaration } from '../../../../types/IDeclaration'
import { IStoreState } from '../../../../redux/store'
import DeclarationService from '../../services/declaration.service'
import { addDeclaration } from '../../../../redux/actions'

function useCreateOrEditDeclaration() {
  const store = useStore()

  const createOrEditDeclaration: () => Promise<IDeclaration> = () => (
    new Promise((resolve, reject) => {
      // @ts-ignore
      const { declarationReducer: { declaration } }: IStoreState = store.getState()
      if (declaration.id === null) {
        DeclarationService.postNewDeclaration(declaration)
          .then((createdDeclaration: IDeclaration) => {
            store.dispatch(addDeclaration(createdDeclaration))
            resolve(createdDeclaration)
          })
          .catch((error) => reject(error))
      } else {
        DeclarationService.putDeclaration(declaration.id, declaration)
          .then((createdDeclaration: IDeclaration) => {
            store.dispatch(addDeclaration(createdDeclaration))
            resolve(createdDeclaration)
          })
          .catch((error) => reject(error))
      }
    }))

  return {
    createOrEditDeclaration,
  }
}

export default useCreateOrEditDeclaration
