import { useState } from 'react'
import RestrictionService from '../../../Restrictions/services/restriction.service'
import { RestrictionType } from '../../../../types/restriction'

function useRestrictionKeys() {
  const [loading, setLoading] = useState(false)
  const [restrictionKeys, setRestrictionKeys] = useState<Array<string>>([])
  const [sanctionedKeys, setSanctionedKeys] = useState<Array<string>>([])
  const [traderRestrictions, setTraderRestrictions] = useState<Array<string>>([])
  const [truckRestrictions, setTruckRestrictions] = useState<Array<string>>([])

  const load = () => {
    if (loading) {
      return
    }

    RestrictionService.getAllRestrictionKeysForType()
      .then((keys) => {
        setRestrictionKeys(keys)
      })
      .finally(() => setLoading(false))
  }

  const loadSanctions = () => {
    if (loading) {
      return
    }
    RestrictionService.getAllRestrictionKeysForType(RestrictionType.COMMODITY_SANCTION)
      .then((keys) => {
        setSanctionedKeys(keys)
      })
      .finally(() => setLoading(false))
  }

  const loadCarrierRestrictions = () => {
    if (loading) {
      return
    }
    RestrictionService.getAllRestrictionKeysForType(RestrictionType.TRADER)
      .then((keys) => {
        setTraderRestrictions(keys)
      })
      .finally(() => setLoading(false))
  }

  const loadTruckRestrictions = () => {
    if (loading) {
      return
    }
    RestrictionService.getAllRestrictionKeysForType(RestrictionType.TRUCK)
      .then((keys) => {
        setTruckRestrictions(keys)
      })
      .finally(() => setLoading(false))
  }

  return {
    restrictionKeys,
    sanctionedKeys,
    load,
    loadSanctions,
    loadCarrierRestrictions,
    traderRestrictions,
    truckRestrictions,
    loadTruckRestrictions,
  }
}

export default useRestrictionKeys
