import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react'
import { UserContext } from '../../../../context/UserContext'
import { DirectoEvent } from '../../../../types/Directo'
import DirectoService from '../../services/directo.service'

export interface DirectoEventState {
  customerId: number | null;
  initialCustomerId: number | null;
  setDescriptionAddendum: Dispatch<SetStateAction<string>>;
  descriptionAddendum: string;
  setCustomerId: Dispatch<SetStateAction<number | null>>;
  saveDirectoEvent: (declarationId: number) => Promise<DirectoEvent>;
  getDirectoEvent: (declarationId: number) => void;
}

function useDirectoEvent(declarationId: number | null, shouldUpdateData: boolean): DirectoEventState {
  const [descriptionAddendum, setDescriptionAddendum] = useState('')
  const [id, setId] = useState<number | null>(null)
  const [customerId, setCustomerId] = useState<number | null>(null)
  const [initialCustomerId, setInitialCustomerId] = useState<number | null>(null)
  const { user } = useContext(UserContext)

  const getDirectoEvent = (declaration: number) => {
    if (declarationId === null || user?.role !== 'ADMIN') {
      return
    }

    setId(null)
    setCustomerId(null)
    setInitialCustomerId(null)
    setDescriptionAddendum('')

    DirectoService.getDirectoEvent(declaration)
      .then((response) => {
        if (response.id) {
          setId(response.id)
        }
        setInitialCustomerId(response.customerId)
        setDescriptionAddendum(response.descriptionAddendum)
      })
      .catch(() => {
        setId(null)
        setCustomerId(null)
        setInitialCustomerId(null)
        setDescriptionAddendum('')
        // eslint-disable-next-line no-console
        console.warn('No directo event for declaration')
      })
  }

  useEffect(() => {
    if (declarationId === null || !shouldUpdateData) {
      return
    }

    getDirectoEvent(declarationId)
  }, [declarationId, shouldUpdateData])

  const saveDirectoEvent = (declaration: number) => {
    const directoEvent = {
      id,
      descriptionAddendum,
      customerId,
    }
    if (id === null && descriptionAddendum.length === 0 && (customerId === null || customerId === undefined)) {
      return Promise.resolve(directoEvent)
    }

    if (id === null) {
      return DirectoService.postDirectoEvent(declaration, directoEvent)
        .then((response: DirectoEvent) => {
          if (response.customerId !== null) {
            setInitialCustomerId(response.customerId)
          }
          setId(response.id)
          return response
        })
    }

    return DirectoService.putDirectoEvent(declaration, directoEvent)
      .then((response: DirectoEvent) => {
        if (response.customerId !== null) {
          setInitialCustomerId(response.customerId)
        }
        return response
      })
  }

  return {
    descriptionAddendum,
    setDescriptionAddendum,
    customerId,
    setCustomerId,
    saveDirectoEvent,
    initialCustomerId,
    getDirectoEvent,
  }
}

export default useDirectoEvent
