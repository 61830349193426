/* eslint-disable no-case-declarations */
import { GroupBase } from 'react-select/dist/declarations/src/types'
import { ISelectOption } from 'types/IClassifier'
import { ConsignmentItem } from '../../../form/schemas/consignmentItemSchema'
import {
  blankAdditionalInformation,
  blankAdditionalReference,
  blankAdditionalSupplyChainActor,
  blankConsignee,
  blankConsignmentItem,
  blankPackaging,
  blankPreviousDocument,
  blankSupportingDocument,
  blankTransportDocument,
} from '../../../form'
import { hasText } from '../../../../common/utils/common-util'
import mapConsignee from './consignee'
import mapAdditionalSupplyChainActor from './additionalSupplyChainActor'
import mapPackaging from './packaging'
import mapPreviousDocument from './previousDocument'
import mapSupportingDocument from './supportingDocument'
import mapTransportDocument from './transportDocument'
import mapAdditionalInformation from './additionalInformation'
import mapAdditionalReference from './additionalReference'
import { sortBySequenceNumber } from '../../../services/useFieldArrayActionHelper'
import { COUNTRY_CODE_PREFIX } from '../../../../common/utils/classifier-util'

const ConsignmentItemObjectsFields = ['consignee', 'additionalSupplyChainActor',
  'packaging', 'previousDocument', 'supportingDocument', 'transportDocument', 'additionalReference', 'additionalInformation'] as const
type ConsignmentItemObjectField = typeof ConsignmentItemObjectsFields[number]
type ConsignmentItemObjects = Pick<ConsignmentItem, ConsignmentItemObjectField>

const ConsignmentItemPrimitiveFields = ['id', 'deleted', 'sequenceNumber', 'declarationType',
  'countryOfDestination', 'referenceNumberUCR', 'commodityDescriptionOfGoods', 'declarationGoodsItemNumber',
  'commodityHarmonizedSystemSubHeadingCode', 'commodityCombinedNomenclatureCode', 'goodsMeasureGrossMass', 'goodsMeasureNetMass',
  'price', 'vatRate', 'dutyRate', 'excise', 'dutyAmount',
] as const
type ConsignmentItemPrimitiveField = typeof ConsignmentItemPrimitiveFields[number]
type ConsignmentItemPrimitives = Pick<ConsignmentItem, ConsignmentItemPrimitiveField>

export type CustomColumnMap = Map<string, string | undefined | null>

function isConsignmentItemObjectField(header: string): header is ConsignmentItemObjectField {
  return ConsignmentItemObjectsFields.includes(header as ConsignmentItemObjectField)
}

function isConsignmentItemPrimitiveField(header: string): header is ConsignmentItemPrimitiveField {
  return ConsignmentItemPrimitiveFields.includes(header as ConsignmentItemPrimitiveField)
}

export const BLACKLIST_KEYS = ['id', 'sequenceNumber', 'target', 'deleted', 'declarationGoodsItemNumber', 'dangerousGoods',
  'additionalSupplyChainActor',
  'previousDocument',
  'supportingDocument',
  'transportDocument',
  'additionalReference',
  'additionalInformation',
  'packaging',
  'consignee', 'address',
]
export const ALL_KEYS = new Map([
  ['consignmentItem', Object.keys(blankConsignmentItem)],
  // E1301 ['dangerousGoods', Object.keys(blankDangerousGoods)],
  ['additionalSupplyChainActor', Object.keys(blankAdditionalSupplyChainActor)],
  ['previousDocument', Object.keys(blankPreviousDocument)],
  ['supportingDocument', Object.keys(blankSupportingDocument)],
  ['transportDocument', Object.keys(blankTransportDocument)],
  ['additionalReference', Object.keys(blankAdditionalReference)],
  ['additionalInformation', Object.keys(blankAdditionalInformation)],
  ['packaging', Object.keys(blankPackaging)],
  ['consignee', [...Object.keys(blankConsignee), ...Object.keys(blankConsignee.address ?? {})]],
])

export const COLUMN_KEY_OPTIONS: Array<GroupBase<ISelectOption>> = []
ALL_KEYS.forEach((fields, key) => {
  COLUMN_KEY_OPTIONS.push(
    {
      label: key,
      options: fields
        .filter((field) => !BLACKLIST_KEYS.includes(field))
        .map((field) => ({
          label: `${field}`,
          value: key === 'consignmentItem' ? field : `${key}_${field}`,
        })),
    },
  )
})

export function isKnownColumnKey(key: string) {
  return COLUMN_KEY_OPTIONS
    .flatMap((group) => group.options)
    .some((option) => option.value === key)
}

export function parseConsignmentItemRow(
  headers: readonly string[],
  row: string[],
  index: number,
  customColumnMap: CustomColumnMap,
): Promise<ConsignmentItem> {
  return new Promise((resolve, reject) => {
    if (headers.length !== row.length) {
      reject(Error('ROW_COLUMN_COUNT_MISMATCH', { cause: { 0: index + 1, 1: headers.length, 2: row.length } }))

      return
    }

    const newImportedItem: ConsignmentItem = structuredClone<ConsignmentItem>(blankConsignmentItem)
    newImportedItem.sequenceNumber = index // initial row index for errors, later overwritten to adjust the offset from existing items

    headers.forEach((header, headerIndex) => {
      const headerMappingToItem = customColumnMap.get(header)
      if (headerMappingToItem === null || headerMappingToItem === undefined) return
      const originalColumnHeader: string = headerMappingToItem
      const cellValue = row.at(headerIndex)?.trim() ?? ''
      const consignmentItemField = customColumnMap.get(header) ?? originalColumnHeader
      const consignmentItemFieldParts = consignmentItemField.split('_')
      const consignmentItemObjectField = consignmentItemFieldParts[0]
      const consignmentItemObjectSubfield = consignmentItemFieldParts[1]

      if (isConsignmentItemPrimitiveField(consignmentItemField)) {
        switch (consignmentItemField) {
          case 'deleted':
            newImportedItem[consignmentItemField] = false
            break
          case 'goodsMeasureGrossMass':
          case 'goodsMeasureNetMass':
          case 'price':
          case 'vatRate':
          case 'dutyRate':
          case 'dutyAmount':
          case 'excise':
            if (hasText(cellValue)) {
              newImportedItem[consignmentItemField] = Number.isNaN(cellValue) ? 0 : Number(cellValue.replaceAll(',', '.'))
            } else {
              newImportedItem[consignmentItemField] = 0
            }
            break
          case 'declarationType':
          case 'referenceNumberUCR':
          case 'commodityDescriptionOfGoods':
            newImportedItem[consignmentItemField] = cellValue
            break
          case 'commodityHarmonizedSystemSubHeadingCode':
            let hsCode = cellValue.replaceAll(' ', '')
            hsCode = hsCode.length > 6 ? hsCode.substring(0, 6) : hsCode
            newImportedItem[consignmentItemField] = hsCode
            break
          case 'commodityCombinedNomenclatureCode':
            let cnCode = cellValue.replaceAll(' ', '')
            cnCode = cnCode.length > 6 && cnCode.length <= 8 ? cnCode.substring(6, 8) : cnCode
            newImportedItem[consignmentItemField] = cnCode
            break
          case 'countryOfDestination':
            newImportedItem[consignmentItemField] = hasText(cellValue) ? COUNTRY_CODE_PREFIX + cellValue : ''
            break
          default:
            throw Error('Unknown field')
        }
      } else if (consignmentItemFieldParts.length > 0 && isConsignmentItemObjectField(consignmentItemObjectField)) {
        const reoccurrenceIndicator = Number(header.split('_')[2] ?? NaN) ?? 1
        const newSequenceNumber = reoccurrenceIndicator - 1
        switch (consignmentItemObjectField) {
          case 'additionalReference':
            mapAdditionalReference(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'additionalInformation':
            mapAdditionalInformation(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'consignee':
            mapConsignee(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
            )
            break
          case 'additionalSupplyChainActor':
            mapAdditionalSupplyChainActor(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'packaging':
            mapPackaging(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'previousDocument':
            mapPreviousDocument(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'supportingDocument':
            mapSupportingDocument(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          case 'transportDocument':
            mapTransportDocument(
              newImportedItem[consignmentItemObjectField],
              consignmentItemObjectSubfield,
              cellValue,
              newSequenceNumber,
            )
            break
          default:
            throw Error(`Unknown ${consignmentItemObjectField}`)
        }
      }
    })

    newImportedItem.additionalReference.sort(sortBySequenceNumber)
    newImportedItem.additionalInformation.sort(sortBySequenceNumber)
    newImportedItem.additionalSupplyChainActor.sort(sortBySequenceNumber)
    newImportedItem.packaging.sort(sortBySequenceNumber)
    newImportedItem.previousDocument.sort(sortBySequenceNumber)
    newImportedItem.supportingDocument.sort(sortBySequenceNumber)
    newImportedItem.transportDocument.sort(sortBySequenceNumber)

    resolve(newImportedItem)
  })
}

export default parseConsignmentItemRow
