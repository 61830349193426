import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { PageFilterRequest, PageResponse } from '../../../types/PageResponse'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { Restriction, RestrictionType } from '../../../types/restriction'

function getRestriction(restrictionId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/restriction/${restrictionId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function postRestriction(restriction: Restriction) {
  return axios
    .post(
      apiService.getFullApiUrl('/restriction'),
      { ...restriction },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function putRestriction(restriction: Restriction) {
  return axios
    .put(
      apiService.getFullApiUrl(`/restriction/${restriction.id}`),
      { ...restriction },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}
function importRestriction(file: File, type: RestrictionType) {
  const bodyData = new FormData()

  bodyData.append('file', file)
  bodyData.append('type', type)

  return axios
    .post(
      apiService.getFullApiUrl('/restriction/import'),
      bodyData,
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function deleteRestriction(restrictionId: number) {
  return axios
    .delete(apiService.getFullApiUrl(`/restriction/${restrictionId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function getRestrictionPage(
  {
    pageIndex,
    pageSize,
    filters,
    typeFilter,
  }: PageFilterRequest,
): Promise<PageResponse<Restriction>> {
  return new Promise((resolve, reject) => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filterParam: any = {}
    filterParam.page = pageIndex
    filterParam.size = pageSize

    if (typeFilter !== null && typeFilter !== '') {
      filterParam.type = typeFilter
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of filters) {
      if (filter.key === 'modified' || filter.key === 'created') {
        if (filter.value.match('(\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d)$') !== null) {
          filterParam[filter.key] = filter.value
        }
      } else if (filter.value !== null && filter.value !== '') {
        filterParam[filter.key] = filter.value
      }
    }
    axios.get(
      apiService.getFullApiUrl('/restriction'),
      {
        params: {
          ...filterParam,
        },
      },
    )
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      })
      .catch((error: AxiosError) => {
        toast.error('Getting declarations failed')
        reject(error)
      })
  })
}

function getAllRestrictionKeysForType(type: RestrictionType = RestrictionType.COMMODITY_CODE) {
  return axios
    .get(apiService.getFullApiUrl('/restriction/keys'), { params: { type } })
    .then(handleResponseData)
    .catch(handleApiException)
}

const RestrictionService = {
  postRestriction,
  putRestriction,
  deleteRestriction,
  getRestriction,
  getRestrictionPage,
  getAllRestrictionKeysForType,
  importRestriction,
}

export default RestrictionService
