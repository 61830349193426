import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from '../../../context/UserContext'
import { CustomerContext } from '../CustomerContext'
import CustomsProfile from '../CustomsProfile'
import UserService, { UserListResponse } from '../services/user.service'
import UserModal from './UserModal'
import UsersTableRow from './UsersTableRow'

interface UsersTableProps {
  customerId: string | undefined
  reFetch: boolean | undefined
}

function UsersTable(props: UsersTableProps) {
  const { customerId, reFetch } = props
  const { t } = useTranslation()
  const [isUserModalVisible, toggleUserModal] = useState<boolean>(false)
  const {
    setNctsProfileModalVisibility,
  } = useContext(CustomerContext)

  const [usersData, setUsersData] = useState<Array<UserListResponse>>([])

  const {
    user,
    customer,
  } = useContext(UserContext)

  const getAllUsers = () => {
    UserService.getAllUsersForCurrentCustomer()
      .then((users) => {
        // eslint-disable-next-line no-nested-ternary
        users.sort((a, b) => ((a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0)))
        setUsersData(users)
      })
      .catch(() => toast.error(t('messages.customerUsersError')))
  }

  const loadUsersForCustomer = () => {
    if (user?.role === 'ADMIN') {
      if (customerId === undefined) {
        return
      }
      UserService.getAllUsersForCustomer(Number(customerId))
        .then((users) => {
          // eslint-disable-next-line no-nested-ternary
          users.sort((a, b) => ((a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0)))
          setUsersData(users)
        })
        .catch(() => toast.error(t('messages.customerUsersError')))
    } else if (user?.role === 'CUSTOMER_ADMIN') {
      getAllUsers()
    }
  }

  useEffect(() => {
    loadUsersForCustomer()
  }, [customerId, isUserModalVisible])

  return (
    <div className="pt-4 px-4 col-12 col-xl-8 mb-5 mb-md-0">
      <div className="position-relative heading">
        <h3>
          {t('customers.users')}
        </h3>
        {
          customerId
          && (
            <div className="heading-button d-flex align-items-center">
              <button
                type="button"
                className="btn btn-outline-dark btn-sm d-flex align-items-center"
                onClick={() => toggleUserModal(true)}
              >
                <i className="far fa-plus me-1" />
                <span>{t('buttons.addUser')}</span>
              </button>
              {user?.role === 'ADMIN' && (Number(customerId) === customer?.id) && (
                <button
                  type="button"
                  className="btn ms-2 btn-outline-dark btn-sm d-flex align-items-center"
                  onClick={() => setNctsProfileModalVisibility(true)}
                >
                  <i className="far fa-plus me-1" />
                  <span>{t('buttons.addProfile')}</span>
                </button>
              )}
              {user?.role === 'CUSTOMER_ADMIN' && (
                <button
                  type="button"
                  className="btn ms-2 btn-outline-dark btn-sm d-flex align-items-center"
                  onClick={() => setNctsProfileModalVisibility(true)}
                >
                  <i className="far fa-plus me-1" />
                  <span>{t('buttons.addProfile')}</span>
                </button>
              )}
            </div>
          )
        }
      </div>

      <div className="table-responsive">
        <table className="table table-sm table-hover table-borderless data-table">
          <thead>
            <tr>
              <th key="Name">
                <div>
                  {t('common.name', { context: 'capitalized' })}
                </div>
              </th>
              <th key="E-mail">
                <div>
                  {t('common.email')}
                </div>
              </th>
              {
                user?.role === 'ADMIN' && (
                  <th key="remoteStaffCode">
                    <div>
                      {t('directo.remoteStaffCode')}
                    </div>
                  </th>
                )
              }
              <th key="Phone">
                <div>
                  {t('common.phone')}
                </div>
              </th>
              <th key="Status">
                <div>
                  {t('transit.status')}
                </div>
              </th>
              <th key="Created">
                <div>
                  {t('declaration.created')}
                </div>
              </th>
              <th key="Deleted">
                <div>
                  {t('customers.deleted')}
                </div>
              </th>
              <th key="lastLogin">
                <div>
                  {t('customers.lastLogin')}
                </div>
              </th>
              <th key="ActionsColumn">
                <div key="pl" />
              </th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((userResponse) => (
              <UsersTableRow
                key={`${userResponse.email}_row`}
                currentUser={userResponse}
                loadUsers={loadUsersForCustomer}
                currentUserEmail={user?.email ?? '-'}
                customerId={Number(customerId)}
              />
            ))}
          </tbody>
        </table>
        <div className="heading mt-5">
          <h3>
            {t('customers.customsProfile')}
          </h3>
        </div>
        <CustomsProfile
          customerId={customerId !== undefined ? customerId : '0'}
          reFetch={reFetch}
        />
      </div>
      <UserModal
        isUserModalVisible={isUserModalVisible}
        toggleUserModal={toggleUserModal}
        customerId={Number(customerId)}
      />
    </div>
  )
}

export default UsersTable
