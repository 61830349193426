import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { POSTAL_CONSIGNMENT_TRANSPORT_MODE, RAIL_TRANSPORT_MODE } from '../../../../common/utils/ncts-constants'

const B1806Validator = {
  validateBorderTransportMeansRequirement: (borderModeOfTransport: string): RuleResult => {
    const mode = removePrefix(borderModeOfTransport, 'TRANSPORT_MODE_', 1)

    if (mode === null || mode === RAIL_TRANSPORT_MODE || mode === POSTAL_CONSIGNMENT_TRANSPORT_MODE) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default B1806Validator
