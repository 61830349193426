import React from 'react'
import { CustomerStatus } from 'types/Customer'

/* eslint-disable  @typescript-eslint/no-explicit-any */
function TableCustomerStatusRow({ value }: { value: any }) {
  switch (value) {
    case CustomerStatus.ACCEPTED.toString():
      return <span className="text-success">ACCEPTED</span>
    case CustomerStatus.BLOCKED.toString():
      return <span className="text-danger">BLOCKED</span>
    case CustomerStatus.ARCHIVED.toString():
      return <span className="text-muted">ARCHIVED</span>
    default:
      return <span>{value}</span>
  }
}

export default TableCustomerStatusRow
