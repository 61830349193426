import { Dispatch, SetStateAction, useState } from 'react'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { parseNumber } from '../../../common/utils/common-util'

export interface GuaranteeCalculationItemRow {
  sequenceNumber: number
  description: string
  commodityCode: string

  grossWeight: number | null
  price: number | null
  excise: number | null
  dutyRate: number | null
  vatRate: number | null
  taxableItemPrice?: number
  taxableDutyAmount?: number
  taxableVatAmount?: number
}

function useGuaranteeCalculator(
  houseConsignments: Array<HouseConsignmentType>,
  isCalculationModalVisible: boolean,
  setCalculationModalVisible: Dispatch<SetStateAction<boolean>>,
) {
  const [calculatedItemsRows, setCalculatedItemsRows] = useState<GuaranteeCalculationItemRow[]>([])

  const loadGoodsInfo = () => {
    const rows: Array<GuaranteeCalculationItemRow> = houseConsignments
      .filter((item) => item.deleted !== true)
      .flatMap((item) => item.consignmentItem)
      .filter((item) => item.deleted !== true)
      .map((item) => {
        const vatRate = parseNumber(item.vatRate) ?? 0

        const price = parseNumber(item.price) ?? 0
        const dutyRate = item.dutyRateUnit === 'PERCENTAGE' ? parseNumber(item.dutyRate) ?? 0 : 0
        const excise = parseNumber(item.excise) ?? 0

        const taxableDutyAmount: number | undefined = item.dutyRateUnit === 'PERCENTAGE'
          ? (parseNumber(parseFloat(((price * dutyRate) / 100).toFixed(3))) ?? 0) : (item.dutyAmount ?? 0)
        const taxableVatAmount: number | undefined = parseNumber(
          parseFloat(((price + taxableDutyAmount + excise) * (vatRate / 100)).toFixed(3)),
        ) ?? 0
        const taxableItemPrice: number | undefined = parseNumber(
          parseFloat((taxableVatAmount + taxableDutyAmount + excise).toFixed(3)),
        ) ?? 0

        return ({
          sequenceNumber: item.sequenceNumber,
          description: item.commodityDescriptionOfGoods ?? '',
          commodityCode: `${item.commodityHarmonizedSystemSubHeadingCode} ${item.commodityCombinedNomenclatureCode}` ?? '',
          grossWeight: item.goodsMeasureGrossMass,
          price: parseNumber(item.price) ?? null,
          excise: parseNumber(item.excise) ?? null,
          dutyRate: item.dutyRateUnit === 'PERCENTAGE' ? parseNumber(item.dutyRate) ?? null : null,
          taxableItemPrice,
          taxableDutyAmount,
          taxableVatAmount,
          vatRate,
        })
      })
      .sort(sortBySequenceNumber)

    setCalculatedItemsRows(rows)
  }

  const openCalculation = () => {
    if (isCalculationModalVisible) {
      return
    }

    setCalculationModalVisible(true)
    loadGoodsInfo()
  }

  return {
    calculatedItemsRows,
    openCalculation,
  } as const
}

export default useGuaranteeCalculator
