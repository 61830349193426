import React, { Dispatch, SetStateAction } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../../../../types/IClassifier'
import LoadingSpinner from '../../../../components/LoadingSpinner'

interface PrivacyPolicyActionHeaderProps {
  selectOptions: ISelectOption[];
  loading: boolean;
  language: ISelectOption;
  setLanguage: Dispatch<SetStateAction<ISelectOption>>;
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
}

function PrivacyPolicyActionHeader({
  selectOptions,
  language,
  setLanguage,
  loading,
  setIsConfirmationModalOpen,
}: PrivacyPolicyActionHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="title-bar">
      <div className="d-flex justify-content-end align-items-center ps-3 w-100">
        <div className="d-flex justify-content-end align-items-center ps-3 w-100">
          <Select
            options={selectOptions}
            className="w-25"
            isDisabled={loading}
            defaultValue={selectOptions[0]}
            value={language}
            onChange={(option: ISelectOption | null) => {
              if (option !== null) {
                setLanguage(option)
              }
            }}
          />
          <div className="d-flex ps-4">
            <button
              type="button"
              disabled={loading}
              className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              {loading ? <LoadingSpinner /> : t('buttons.save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyActionHeader
