import { RuleResult } from 'types/DeclarationP5'
import { IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'
import B1893Validator from './transitional/B1893'
import { DeclarationForm } from '../schemas/declarationFormSchema'

const C0403Validator = {
  validatePlaceOfLoadingRequirement: (form :DeclarationForm): RuleResult => {
    if (IS_TRANSITIONAL_PERIOD) {
      return B1893Validator.validatePlaceOfLoadingRequirement(form.security)
    }

    return form.additionalDeclarationType === 'D' ? 'OPTIONAL' : 'REQUIRED'
  },
}

export default C0403Validator
