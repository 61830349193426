import axios from 'axios'
import { apiService, handleResponseData } from './api.service'
import { OfficeRole } from '../types/IClassifier'

async function getClassifier(classifierGroup: string) {
  if (classifierGroup === '' || classifierGroup == null) {
    return []
  }

  return axios.get(apiService.getFullApiUrl('/classifier'), {
    params: {
      classifierGroup: classifierGroup.toUpperCase(),
    },
  }).then(handleResponseData)
}

async function getCustomsOfficesByRoles(roles: Array<OfficeRole>) {
  if (roles.length === 0) {
    return []
  }

  return axios.get(apiService.getFullApiUrl('/classifier/customs-offices'), {
    params: {
      roles: roles.join(','),
    },
  }).then(handleResponseData)
}

const ClassifierService = {
  getClassifier,
  getCustomsOfficesByRoles,
}

export default ClassifierService
