import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { TransportCustomsOffice } from './types/TransportCustomsOffice'

function postTransportCustomsOffice(transportId: number, transportCustomsOffice: TransportCustomsOffice): Promise<TransportCustomsOffice> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl('/transport-customs-office'),
      {
        ...transportCustomsOffice,
        transportId,
      },
    )
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      })
      .catch((error: AxiosError) => {
        toast.warn('Error while saving transport transport')
        reject(error)
      })
  })
}

function putTransportCustomsOffice(transportId: number, transportCustomsOffice: TransportCustomsOffice): Promise<TransportCustomsOffice> {
  return new Promise((resolve, reject) => {
    axios.put(
      apiService.getFullApiUrl('/transport-customs-office'),
      {
        ...transportCustomsOffice,
        transportId,
      },
    )
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      })
      .catch((error: AxiosError) => {
        reject(error)
      })
  })
}

function deleteTransportCustomsOffice(transportId: number): Promise<void> {
  return axios
    .delete(apiService.getFullApiUrl(`/transport-customs-office/${transportId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

const TransportCustomsOfficeService = {
  putTransportCustomsOffice,
  postTransportCustomsOffice,
  deleteTransportCustomsOffice,
}

export default TransportCustomsOfficeService
