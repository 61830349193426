import { UseDeclarationFormReturn } from '../../form'
import useTransitOperation from '../useTransitOperation'
import useConsignment from '../useConsignment'
import useHouseConsignment from '../useHouseConsignment'
import useConsignmentItem from '../useConsignmentItem'
import useDocument from '../useDocument'
import useGuarantee from '../useGuarantee'
import useDirectoEvent from '../useDirectoEvent'
import useAuthorisation from '../useAuthorisations'

export function useFormDraft(form: UseDeclarationFormReturn) {
  const {
    getValues,
  } = form

  const { createTransitOperation, updateTransitOperation } = useTransitOperation(form)
  const { createConsignment, updateConsignment } = useConsignment(form)
  const { createHouseConsignments, updateHouseConsignments } = useHouseConsignment(form)
  const { updateOrCreateAuthorisations, deleteAuthorisations } = useAuthorisation(form)
  const { createOrUpdateConsignmentItems, archiveConsignmentItems } = useConsignmentItem(form)
  const { createOrUpdateDocuments, archiveDocuments } = useDocument(form)
  const { createOrUpdateGuarantee } = useGuarantee(form)
  const { createOrUpdateDirectoEvent } = useDirectoEvent(form)

  const saveTransit = async (isDraft: boolean) => {
    let transitOperationId = getValues('id')

    if (transitOperationId === null) {
      await createTransitOperation(isDraft)
      transitOperationId = getValues('id')
    } else {
      await updateTransitOperation(isDraft)
    }
    await createOrUpdateGuarantee(isDraft)

    if (transitOperationId !== null) {
      await createOrUpdateDirectoEvent()
    }

    let consignmentId = getValues('consignmentId')

    if (consignmentId === null) {
      await createConsignment(isDraft)
      await createHouseConsignments(isDraft)
      consignmentId = getValues('consignmentId')
    } else {
      await updateConsignment(isDraft)
      await updateHouseConsignments(isDraft)
    }

    await deleteAuthorisations()
    if (consignmentId !== null) {
      await archiveConsignmentItems(isDraft)
      await archiveDocuments(isDraft)
    }

    await updateOrCreateAuthorisations(isDraft)
    await createOrUpdateConsignmentItems(isDraft)
    await createOrUpdateDocuments(isDraft)
  }

  return {
    saveTransit,
  }
}
export default useFormDraft
