import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import { toast } from 'react-toastify'
import { isNullOrBlank } from '../../../../helpers'
import { addTransport } from '../../../../redux/actions'
import { IStoreState } from '../../../../redux/store'
import TransportCustomsOfficeService from '../../TransportCustomsOffice/transport-customs-office.service'
import { TransportCustomsOffice } from '../../TransportCustomsOffice/types/TransportCustomsOffice'

export interface DeclarationData {
  transportCustomsOffices: Array<TransportCustomsOffice>;
  setTransportCustomsOffices: (newTransportCustomsOffices: Array<TransportCustomsOffice>) => void;
}

export interface DeclarationState {
  declarationData: DeclarationData;
  readonly saveTransportCustomsOffices: (transportId: number | null) => Promise<Array<TransportCustomsOffice>>;
}
export const nonEUContractingParties = ['CH', 'GB', 'IS', 'LI', 'MK', 'NO', 'RS', 'TR', 'XS']

function useDeclaration(declarationId: string | undefined): DeclarationState {
  const { t } = useTranslation()

  // TODO temporary until the pesky redux is purged
  const store = useStore()
  const getTransport = () => (store.getState() as IStoreState).transportReducer.transport
  const [transportCustomsOffices, setTransportCustomsOfficesState] = useState<Array<TransportCustomsOffice>>([])
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const transport = getTransport()
      setTransportCustomsOfficesState(transport.transportCustomsOffices)
    })

    return () => unsubscribe()
  }, [])
  const setTransportCustomsOffices = (newTransportCustomsOffices: Array<TransportCustomsOffice>) => {
    store.dispatch(addTransport({
      ...getTransport(),
      transportCustomsOffices: [...newTransportCustomsOffices],
    }))
  }

  const [declarationData, setDeclarationData] = useState<DeclarationData>(
    {
      transportCustomsOffices,
      setTransportCustomsOffices,
    },
  )

  useEffect(() => {
    setDeclarationData({
      transportCustomsOffices,
      setTransportCustomsOffices,
    })
  }, [transportCustomsOffices, declarationId])

  // TODO split declaration data objects to separate hooks
  const saveTransportCustomsOffices = (transportId: number | null) => new Promise<Array<TransportCustomsOffice>>((resolve, reject) => {
    if (transportId === null) {
      toast.error(t('messages.savingFailed'))
      reject(Error('Error! Missing transport id to attach to customs offices'))
      return
    }
    const allEntriesHaveValues = transportCustomsOffices
      .filter((office) => !office.deleted)
      .every((entry) => !isNullOrBlank(entry.transitCustomsOffice))
    if (!allEntriesHaveValues) {
      toast.error(t('transportCustomsOffice.containsBlanks'))
      reject(Error('Contains blank elements'))
      return
    }

    const promises: Array<Promise<TransportCustomsOffice>> = []
    let newIndex = 0
    // eslint-disable-next-line no-restricted-syntax
    for (const transportCustomsOffice of transportCustomsOffices) {
      let action: Promise<TransportCustomsOffice>
      if (transportCustomsOffice.id != null) {
        if (transportCustomsOffice.deleted) {
          action = TransportCustomsOfficeService.deleteTransportCustomsOffice(transportCustomsOffice.id).then(() => transportCustomsOffice)
        } else {
          transportCustomsOffice.sortOrder = newIndex
          action = TransportCustomsOfficeService.putTransportCustomsOffice(transportId, transportCustomsOffice)
          // eslint-disable-next-line no-plusplus
          newIndex++
        }
      } else {
        transportCustomsOffice.sortOrder = newIndex
        action = TransportCustomsOfficeService.postTransportCustomsOffice(transportId, transportCustomsOffice)
        // eslint-disable-next-line no-plusplus
        newIndex++
      }
      promises.push(action)
    }
    Promise
      .all(promises)
      .then((savedTransportCustomsOffices) => {
        resolve(savedTransportCustomsOffices.filter((item) => !item.deleted))
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  })

  return {
    declarationData,
    saveTransportCustomsOffices,
  }
}

export default useDeclaration
