import { RuleResult } from 'types/DeclarationP5'
import { PreviousDocument } from '../../schemas/documentSchemas'
import { hasText } from '../../../../common/utils/common-util'

const NA60Validator = {
  validateConsignmentItemPreviousDocumentProcedureRequirement: (previousDocument: PreviousDocument): RuleResult => {
    if (hasText(previousDocument.documentType)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
  validateConsignmentItemPreviousDocumentDocumentTypeRequirement: (previousDocument: PreviousDocument): RuleResult => {
    if (hasText(previousDocument.procedureType)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default NA60Validator
