import { useTranslation } from 'react-i18next'
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useFormContext } from 'react-hook-form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { Dropdown } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useObservableState } from 'observable-hooks'
import { Observable } from 'rxjs'
import { debounceTime, switchMap } from 'rxjs/operators'
import { ConsignmentDetailResponse } from '../../../common/models'
import { ObjectScope } from '../../../../../types/DeclarationP5'
import { Address, ContactPerson } from '../../form/schemas/commonConsignmentSchemas'
import DropdownToggleButton from '../CommodityCodeSearch/DropdownToggleButton'
import { hasText } from '../../../common/utils/common-util'
import TransitApiConfig from '../../hooks/apiConfig'
import { TransitOperationContext } from '../../hooks/useTransitOperationContext'

// eslint-disable-next-line max-len
const expectedScope = /(^consignee$)|(houseConsignment\.\d+\.consignee)|(houseConsignment\.\d+\.consignmentItem\.\d+\.consignee)|(^consignor$)|(houseConsignment\.\d+\.consignor)/g
const isTraderScope = (scope: ObjectScope): scope is 'consignee' | `houseConsignment.${number}.consignee`
| `houseConsignment.${number}.consignmentItem.${number}.consignee` | 'consignor' | `houseConsignment.${number}.consignor` => (Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function isClearEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  return event.currentTarget.value === '' && event.nativeEvent.type === 'input'
}

export type ConsignmentOption = {
  id: number | null
  name: string
  identificationNumber: string
  address: Address | null
  contactPerson: ContactPerson | null
  target: 'CONSIGNEE' | 'CONSIGNOR'
}

function mapToOption(response: ConsignmentDetailResponse, isConsignee: boolean): ConsignmentOption {
  return {
    name: response.name ?? '',
    id: response.id ?? 0,
    identificationNumber: response.identificationNumber ?? '',
    address: response.address ? {
      streetAndNumber: response.address.street ?? '',
      country: response.address.country ?? '',
      city: response.address.city ?? '',
      id: response.address.id ?? 0,
      postcode: response.address.postcode ?? '',
    } : null,
    contactPerson: response.contactPerson ? {
      id: response.contactPerson.id,
      name: response.contactPerson.name ?? '',
      email: response.contactPerson.email ?? '',
      telephone: response.contactPerson.telephone ?? '',
    } : null,
    target: isConsignee ? 'CONSIGNEE' : 'CONSIGNOR',
  }
}

const {
  baseUrl,
} = TransitApiConfig.paths.consignmentDetail

function searchForOptions(inputValue: string) {
  return new Promise<ConsignmentDetailResponse[]>((resolve, reject) => {
    if (inputValue === '' || inputValue === null || inputValue.trim().length < 2 || inputValue.trim().length > 35) {
      resolve([])
      return
    }

    axios.get(
      baseUrl,
      {
        params: {
          name: inputValue,
        },
      },
    ).then((consignmentDetails: AxiosResponse) => {
      resolve(consignmentDetails.data)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })
}

export interface TraderSearchProps {
  scope: ObjectScope
  maxLength?: number | undefined
  isConsignee: boolean
}

function TraderSearch({
  scope,
  maxLength,
  isConsignee,
}: TraderSearchProps) {
  const { t } = useTranslation()

  const {
    setValue,
    getValues,
    getFieldState,
  } = useFormContext()
  const { fieldRules } = useContext(TransitOperationContext)

  if (!isTraderScope(scope)) throw Error(`Unable to narrow, invalid scope: ${scope}`)

  const [show, setShow] = useState(false)
  const [currentSearch, setCurrentSearch] = useState<string>('')

  useEffect(() => {
    const form = getValues(scope)
    if (!hasText(form.name)) return

    setCurrentSearch(form.name)
  }, [getValues(scope)])

  const [searchResults, invokeSearch] = useObservableState(
    (event$: Observable<string>) => event$.pipe(
      debounceTime(200),
      switchMap((requestEvent) => searchForOptions(requestEvent)),
    ),
    () => ([]),
  )

  const rule = useMemo(
    () => {
      const separatorBeforeParentPath = `${scope}.name`.lastIndexOf('.')
      if (separatorBeforeParentPath > 0) {
        const parentPath = `${scope}.name`.slice(0, separatorBeforeParentPath)
        const parentRule = fieldRules.find((item) => item.path === parentPath)?.ruleResult
        if (parentRule === 'NOT_ALLOWED') {
          return 'NOT_ALLOWED'
        }
      }

      return fieldRules.find((item) => item.path === `${scope}.name`)?.ruleResult ?? 'OPTIONAL'
    },
    [fieldRules],
  )

  useEffect(() => {
    invokeSearch(currentSearch)
  }, [currentSearch])

  const options = useMemo<ConsignmentOption[]>(() => searchResults?.map((item) => mapToOption(item, isConsignee)) ?? [], [searchResults])

  return (
    <Dropdown
      as={ButtonGroup}
      className="col-12"
      show={show}
      onSelect={(eventKey) => {
        const optionMatch = options.find((option) => option.name === eventKey)
        if (optionMatch) {
          setValue(scope, optionMatch)
          setCurrentSearch(optionMatch.name)
          setShow(false)
        }
      }}
      onBlur={(event: React.FocusEvent<HTMLElement, HTMLAnchorElement>) => {
        if (event.relatedTarget?.classList.contains('dropdown-item')) {
          event.relatedTarget?.click()
        } else {
          setValue(`${scope}.name`, currentSearch)
        }
        setShow(false)
      }}
    >
      <Form.Control
        value={currentSearch}
        type="search"
        name="name"
        autoComplete="off"
        disabled={rule === 'NOT_ALLOWED'}
        className={`form-control ${getFieldState(`${`${scope}.name`}`).invalid ? 'is-invalid' : ''
        } ${
          rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''
        }`}
        onChange={(event) => {
          if (isClearEvent(event)) {
            setCurrentSearch(event.currentTarget.value)

            return
          }
          setCurrentSearch(event.currentTarget.value)
        }}
        onFocus={() => {
          setShow(!show)
        }}
        maxLength={maxLength}
      />
      <Dropdown.Toggle
        className="no-after-caret col-2 col-lg-2 col-md-2 col-sm-1 px-2"
        onClick={() => {
          setShow(!show)
        }}
        disabled={rule === 'NOT_ALLOWED'}
        icon={show ? 'OPEN' : 'CLOSED'}
        as={DropdownToggleButton}
      />
      <Dropdown.Menu
        className="shadow w-100 overflow-y-auto"
        align="end"
      >
        {
          (options.length === 0) && (
            <Dropdown.ItemText className="py-0">
              <span>{t('declaration.p5.typeToSearch')}</span>
            </Dropdown.ItemText>
          )
        }
        {
          options
            .map((option) => (
              <Dropdown.Item
                key={option.name}
                eventKey={option.name}
              >
                {option.name}
              </Dropdown.Item>
            ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

TraderSearch.defaultProps = {
  maxLength: undefined,
}

export default TraderSearch
