import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { NestedFieldScope } from 'types/DeclarationP5'
import { ISelectOption } from 'types/IClassifier'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import Input from '../../components/Input'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import useCodelist from '../../../common/hooks/useCodelist'
import { getSelectCountryFilter } from '../../../../../components/react-select/CountryFunctions'
import E1104Validator from '../../form/validators/E1104'
import E1102Validator from '../../form/validators/E1102'
import { CL190_COUNTRY_ADDRESS_POSTCODE_BASED } from '../../../common/utils/ncts-constants'
import { removePrefix } from '../../../common/utils/classifier-util'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

const scope = 'locationOfGoods'
type LocationOfGoodsScope = NestedFieldScope<typeof scope>

function LocationOfGoods() {
  const { t } = useTranslation()
  const { getValues, watch, setValue } = useFormContext<DeclarationForm>()

  const qualifierOfIdentification = watch(`${scope}.qualifierOfIdentification`)
  const office = getValues('departureCustomsOffice')
  const typeOfLocation = getValues(`${scope}.typeOfLocation`)
  const authorisations = getValues('authorisations')

  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])
  const [, departureCustomsOffices] = useCodelist('DEPARTURE_CUSTOMS_OFFICES')
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, qualifierOfIdentificationOptions] = useCodelist('QUALIFIER_OF_IDENTIFICATION')

  const departureOffices = useMemo(() => departureCustomsOffices.filter((item) => item.value.includes(`DEPARTURE_OFFICE_${country || 'FI'}`)), [departureCustomsOffices, country])
  const qualifierOfIdentifications = useMemo(() => {
    // EE CRS17
    if (country === 'EE' && (typeOfLocation === 'B' || typeOfLocation === 'C')) {
      return qualifierOfIdentificationOptions.filter((item) => item.value.includes('QUALIFIER_OF_IDENTIFICATION_Y'))
    }
    return qualifierOfIdentificationOptions
  }, [qualifierOfIdentificationOptions, typeOfLocation])

  const postcodeCountriesOptions = useMemo(() => countriesOptions.filter((countryOption) => CL190_COUNTRY_ADDRESS_POSTCODE_BASED.includes(removePrefix(countryOption.value, 'COUNTRY_') ?? '')), [country, countriesOptions, qualifierOfIdentification])

  const typeOfLocationOptions: ISelectOption[] = [
    {
      value: 'A',
      label: 'A - Designated location',
    },
    {
      value: 'B',
      label: 'B - Authorised place',
    },
    {
      value: 'C',
      label: 'C - Approved place',
    },
    {
      value: 'D',
      label: 'D - Other',
    },
  ].filter((option) => {
    if (country === 'FI' && authorisations.find((auth) => auth.type === 'AUTHORISATION_TYPE_C521')) {
      return option.value === 'B' || option.value === 'C'
    }
    return option
  })

  useEffect(() => {
    if (qualifierOfIdentification !== 'QUALIFIER_OF_IDENTIFICATION_U') {
      setValue('locationOfGoods.unLocode', '', {
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }, [qualifierOfIdentification])

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-location-of-goods"
        headingLabel={t('declaration.p5.locationOfGoods')}
        initialIsSectionOpen={false}
      >
        <FormField
          labelKey="declaration.p5.typeOfLocation"
          tooltipContent={country === 'FI' ? (
            <small>
              {t('tooltips.typeOfLocation')}
            </small>
          ) : undefined}
        >
          <FormSelect
            <LocationOfGoodsScope>
            field="locationOfGoods.typeOfLocation"
            labelKey="declaration.p5.typeOfLocation"
            options={typeOfLocationOptions}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.qualifierOfIdentification">
          <FormSelect
            <LocationOfGoodsScope>
            field="locationOfGoods.qualifierOfIdentification"
            labelKey="declaration.p5.qualifierOfIdentification"
            options={qualifierOfIdentifications}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.unLocode">
          <Input
            <LocationOfGoodsScope>
            field="locationOfGoods.unLocode"
            autoComplete={t('declaration.p5.unLocode')}
            labelKey="declaration.p5.unLocode"
            maxLength={17}
            type="text"
            placeholder={t('declaration.p5.unLocode')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.additionalIdentifier">
          <Input
            <LocationOfGoodsScope>
            field="locationOfGoods.additionalIdentifier"
            placeholder={t('declaration.p5.additionalIdentifier')}
            labelKey="declaration.p5.additionalIdentifier"
          />
        </FormField>
        <FormField labelKey="declaration.p5.locationOfGoodsReferenceNumber">
          <FormSelect
            <LocationOfGoodsScope>
            field="locationOfGoods.referenceNumber"
            labelKey="declaration.p5.locationOfGoodsReferenceNumber"
            options={departureOffices}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.authorisationNumber">
          <Input
            <LocationOfGoodsScope>
            field="locationOfGoods.authorisationNumber"
            placeholder={t('declaration.p5.authorisationNumber')}
            labelKey="declaration.p5.authorisationNumber"
          />
        </FormField>
        <FormField
          labelKey="declaration.p5.economicOperatorIdentificationNumber"
          tooltipContent={country === 'FI' ? (
            <small>
              {t('tooltips.warehouseEori')}
            </small>
          ) : undefined}
        >
          <Input
            <LocationOfGoodsScope>
            field="locationOfGoods.economicOperatorIdentificationNumber"
            labelKey="declaration.p5.economicOperatorIdentificationNumber"
            maxLength={17}
            placeholder={t('declaration.p5.economicOperatorIdentificationNumber')}
          />

        </FormField>
        {
          qualifierOfIdentification !== 'QUALIFIER_OF_IDENTIFICATION_T'
            ? (
              <>
                <FormField labelKey="declaration.p5.country">
                  <FormSelect
                    <LocationOfGoodsScope>
                    field={`${scope}.address.country`}
                    labelKey="declaration.p5.country"
                    options={countriesOptions}
                    customFilterOption={getSelectCountryFilter}
                    type="sync"
                  />
                </FormField>
                <FormField labelKey="declaration.p5.address">
                  <Input
                    <LocationOfGoodsScope>
                    field={`${scope}.address.streetAndNumber`}
                    autoComplete={`${scope}.address.streetAndNumber`}
                    labelKey="declaration.p5.address"
                    maxLength={E1104Validator.getNameOrStreetMaxLength()}
                    type="text"
                    placeholder={t('declaration.p5.address')}
                  />
                </FormField>
                <FormField labelKey="declaration.p5.city">
                  <Input
                    <LocationOfGoodsScope>
                    field={`${scope}.address.city`}
                    autoComplete={`${scope}.address.city`}
                    labelKey="declaration.p5.city"
                    maxLength={35}
                    type="text"
                    placeholder={t('declaration.p5.city')}
                  />
                </FormField>
                <FormField labelKey="declaration.p5.postcode">
                  <Input
                    <LocationOfGoodsScope>
                    field={`${scope}.address.postcode`}
                    autoComplete={`${scope}.address.postcode`}
                    labelKey="declaration.p5.postcode"
                    maxLength={E1102Validator.getPostcodeMaxLength()}
                    type="text"
                    placeholder={t('declaration.p5.postcode')}
                  />
                </FormField>
              </>
            )
            : (
              <>
                <FormField labelKey="declaration.p5.country">
                  <FormSelect
                    <LocationOfGoodsScope>
                    field={`${scope}.postcodeAddress.country`}
                    labelKey="declaration.p5.country"
                    options={postcodeCountriesOptions}
                    customFilterOption={getSelectCountryFilter}
                    type="sync"
                  />
                </FormField>
                <FormField labelKey="declaration.p5.houseNumber">
                  <Input
                    <LocationOfGoodsScope>
                    field={`${scope}.postcodeAddress.houseNumber`}
                    autoComplete={`${scope}.postcodeAddress.houseNumber`}
                    labelKey="declaration.p5.houseNumber"
                    maxLength={17}
                    type="text"
                    placeholder={t('declaration.p5.houseNumber')}
                  />
                </FormField>
                <FormField labelKey="declaration.p5.postcode">
                  <Input
                    <LocationOfGoodsScope>
                    field={`${scope}.postcodeAddress.postcode`}
                    autoComplete={`${scope}.postcodeAddress.postcode`}
                    labelKey="declaration.p5.postcode"
                    maxLength={E1102Validator.getPostcodeMaxLength()}
                    type="text"
                    placeholder={t('declaration.p5.postcode')}
                  />
                </FormField>
              </>
            )
        }

      </CollapsibleColumn>
    </section>
  )
}

export default LocationOfGoods
