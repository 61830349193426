import React from 'react'
import { Row } from 'react-table'
import TableRow from './TableRow'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface RestrictionsTableBodyProps {
  isLoading: boolean;
  page: Array<Row<object>>;
  prepareRow: any;
  getTableBodyProps: any;
  refreshPage: () => void
}

function TableBody(props: RestrictionsTableBodyProps) {
  const {
    getTableBodyProps,
    page,
    prepareRow,
    refreshPage,
  } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <tbody {...getTableBodyProps()} className="position-relative">
      {
        page.map((row, i: number) => {
          prepareRow(row)
          return <TableRow key={`${row.id}_row_wrapper`} row={row} index={i} refreshPage={refreshPage} />
        })
      }
    </tbody>
  )
}

export default TableBody

