import Papa from 'papaparse'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

function useCsvParser() {
  const { t } = useTranslation()
  const parseCsv = async (file: File) => {
    if (!file.name.endsWith('.csv') || file.type !== 'text/csv') {
      toast.error(t('excel.mustBeCsv'))
      return Promise.reject(new Error('Invalid filetype'))
    }
    const fileAsText = await file.text()
    const parseResult = Papa.parse<string[]>(fileAsText, { delimiter: ';', delimitersToGuess: [';', ','] })

    return Promise.resolve(parseResult.data.map((row: string[]) => row))
  }

  return {
    parseCsv,
  }
}

export default useCsvParser
