import { RuleResult } from 'types/DeclarationP5'
import { hasText } from '../../../../common/utils/common-util'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { ConsignmentItem } from '../../schemas/consignmentItemSchema'

const B1896Validator = {
  validateConsignmentItemTransportDocumentRequirement: (formData: DeclarationForm, consignmentItem: ConsignmentItem): RuleResult => {
    if (!['1', '2', '3'].includes(formData.security)) {
      return 'OPTIONAL'
    }

    if (!hasText(formData.referenceNumberUCR) && !hasText(consignmentItem.referenceNumberUCR) && formData.declarationType !== 'TIR') {
      if (consignmentItem.sequenceNumber === 0) {
        return 'REQUIRED'
      }

      return 'OPTIONAL'
    }

    return 'OPTIONAL'
  },
} as const

export default B1896Validator
