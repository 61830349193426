import { Guarantee } from '../../form/schemas/guaranteeSchema'
import { CreateOrUpdateGuaranteeRequest, GuaranteeResponse } from '../../../common/models'

export function parseGuaranteeResponse(
  response: GuaranteeResponse,
  index: number,
): Guarantee {
  return {
    id: response.id,
    amount: response.amount ?? 0,
    sequenceNumber: index,
    profileName: response.profileName,
    deleted: false,
  }
}

export function toCreateOrUpdateGuaranteeRequest(
  guarantee: Guarantee,
  transitOperationId: number,
): CreateOrUpdateGuaranteeRequest {
  return {
    id: guarantee.id,
    amount: Number.isNaN(guarantee.amount) ? 0 : Number(guarantee.amount),
    profileName: guarantee.profileName,
    transitOperationId,
  }
}
