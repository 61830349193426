import { useEffect, useState } from 'react'
import axios from 'axios'
import { IClassifier, ISelectOption } from '../../types/IClassifier'
import { apiService, handleApiException, handleResponseData } from '../../services/api.service'
import { mapCountryToOption } from '../../components/react-select/CountryFunctions'

function useCountries(language: string) {
  const [countryClassifiers, setCountryClassifiers] = useState<Array<IClassifier>>([])
  const [countries, setCountries] = useState<Array<ISelectOption>>([])

  useEffect(() => {
    axios.get(apiService.getFullApiUrl('/classifier'), {
      params: {
        classifierGroup: 'COUNTRIES',
      },
    })
      .then(handleResponseData)
      .then((countriesResponse: IClassifier[]) => {
        setCountryClassifiers([...countriesResponse])
        setCountries(countriesResponse.map((country) => mapCountryToOption(country, language)))
      })
      .catch(handleApiException)
  }, [])

  return {
    countryClassifiers,
    setCountryClassifiers,
    countries,
    setCountries,
  }
}

export default useCountries
