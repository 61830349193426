import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'

const NC0035Validator = {
  validateEconomicOperatorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods?.typeOfLocation === 'B' || formData.locationOfGoods?.typeOfLocation === 'C') {
      return 'REQUIRED'
    }

    return 'NOT_ALLOWED'
  },

}

export default NC0035Validator
