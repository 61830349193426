import React from 'react'
import { components, GroupBase, NoticeProps } from 'react-select'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../../types/IClassifier'

const refNumHref = 'https://ec.europa.eu/taxation_customs/dds2/rd/rd_consultation_reference.jsp?RefNum='
const nameHref = 'https://ec.europa.eu/taxation_customs/dds2/rd/rd_consultation_name.jsp?UsuName='

export default function CustomsOfficesNoOptionsMessage(props: React.PropsWithChildren<NoticeProps<ISelectOption,
false, GroupBase<ISelectOption>>>) {
  const { selectProps } = props
  const { t } = useTranslation()

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.NoOptionsMessage {...props}>
        <small>{t('messages.noOfficeResults')}</small>
        <a
          href={`${refNumHref}${selectProps.inputValue}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('messages.noOfficeResults', { context: 'viaRefNum', input: selectProps.inputValue })}
        </a>
        <br />
        <a
          href={`${nameHref}${selectProps.inputValue}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('messages.noOfficeResults', { context: 'viaUsualName', input: selectProps.inputValue })}
        </a>
      </components.NoOptionsMessage>
    </div>
  )
}
