import { RuleResult } from 'types/DeclarationP5'
import { hasText } from '../../../../common/utils/common-util'

const B1895Validator = {
  validateConsignmentItemUcrRequirement: (referenceNumberUCR: string | null): RuleResult => {
    if (hasText(referenceNumberUCR)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },
} as const

export default B1895Validator
