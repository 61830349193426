import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RestrictionsTitleBar from './components/RestrictionsTitleBar'
import Form from './components/Form'
import { useRestriction } from './useRestriction'

// eslint-disable-next-line import/prefer-default-export
export function Restriction() {
  // @ts-ignore
  const { restrictionId } = useParams()
  const {
    loading,
    restriction,
    setRestriction,
    save,
    load,
  } = useRestriction()

  useEffect(() => {
    if (restrictionId) {
      load(Number(restrictionId))
    }
  }, [])

  return (
    <>
      <RestrictionsTitleBar
        restriction={restriction}
        save={save}
        loading={loading}
      />
      <Form
        restriction={restriction}
        setRestriction={setRestriction}
      />
    </>
  )
}
