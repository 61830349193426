import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { ITransport } from '../../../types/IDeclaration'
import { apiService, handleResponseData } from '../../../services/api.service'

function postNewTransport(declarationId: number, transport: ITransport, isDraft: boolean): Promise<ITransport> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl(!isDraft ? '/transport' : '/transport/draft'),
      {
        ...transport,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while saving declaration transport')
      reject(error)
    })
  })
}

function putTransport(declarationId: number, transport: ITransport, isDraft: boolean): Promise<ITransport> {
  return new Promise((resolve, reject) => {
    axios.put(
      apiService.getFullApiUrl(!isDraft ? `/transport/${transport.id}` : `/transport/${transport.id}/draft`),
      {
        ...transport,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })
}

const TransportService = {
  putTransport,
  postNewTransport,
}

export default TransportService
