import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { toast } from 'react-toastify'
import TransitApiConfig from '../../../TransitOperationEditor/hooks/apiConfig'

const {
  baseUrl,
  queryKeys: {
    rootPath,
  },
} = TransitApiConfig.paths.transitOperation

function useTransitOperationForceStatus() {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const forceStatusChange = useMutation({
    mutationFn: async (id: number) => (await axios.patch<void>(`${baseUrl}/status/${id}`)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [rootPath] }),
    onError: () => { toast.error(t('common.error')) },
  })

  const forceStatusForTransitOperation = async (transitOperationId: number | undefined) => {
    if (transitOperationId === null || transitOperationId === undefined) {
      throw new Error('Transit Operation ID cannot be null or undefined!')
    }

    await forceStatusChange.mutateAsync(transitOperationId)
  }

  return {
    forceStatusForTransitOperation,
  } as const
}

export default useTransitOperationForceStatus
