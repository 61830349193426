import React, { Dispatch, SetStateAction } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTranslation } from 'react-i18next'

interface SeoActionHeaderProps {
  loading: boolean;
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
}

function SeoActionHeader({
  loading,
  setIsConfirmationModalOpen,
}: SeoActionHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="title-bar">
      <div className="d-flex justify-content-end align-items-center ps-3 w-100">
        <button
          type="button"
          disabled={loading}
          className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm d-none d-lg-block"
          onClick={() => {
            setIsConfirmationModalOpen(true)
          }}
        >
          {loading ? <LoadingSpinner /> : t('buttons.save')}
        </button>

      </div>

    </div>
  )
}

export default SeoActionHeader
