import { toast } from 'react-toastify'
import { useStore } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import DeclarationService from '../../services/declaration.service'
import {
  addConsignmentDetails, addDeclaration, addDeclarationInfo, addGuarantee, addTransport,
} from '../../../../redux/actions'
import {
  DeclarationDetailed, IDeclaration, IDeclarationInfo, IGuarantee, ITransport,
} from '../../../../types/IDeclaration'
import { IConsignmentDetailPayload } from '../../../../types/IConsignmentDetail'
import GoodsItemService from '../../services/goods-item.service'
import { IGoodsItem } from '../../../../types/IGoodsItem'
import DocumentService from '../../services/document.service'
import { Document, DocumentType } from '../../../../types/Document'
import useGetGoodsConsignmentDetails from '../useGetGoodsConsignmentDetails'
import useUpdateGoodsItemsState from '../useUpdateGoodsItemsState'
import { sortBySortOrder } from '../../../../helpers'
import useNctsProfileNames from '../useNctsProfileName'
import { de } from 'date-fns/locale'

function usePrefillDeclaration(
  setIsGoodsItemsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setShouldUpdateGoodsForm:React.Dispatch<React.SetStateAction<boolean>>,
  setGoodsItems: React.Dispatch<React.SetStateAction<Array<IGoodsItem>>>,
) {
  const store = useStore()

  const { getGoodsItemsConsignmentDetails } = useGetGoodsConsignmentDetails()

  const { updateGoodsItemsState } = useUpdateGoodsItemsState(setShouldUpdateGoodsForm, setIsGoodsItemsLoading, setGoodsItems)
  const { nctsProfileNames, loadNctsProfileNames } = useNctsProfileNames()

  const { t } = useTranslation()

  const dispatchDeclarationInfoDetails = (details: DeclarationDetailed) => {
    if (details.declarationInfo) {
      store.dispatch(addDeclarationInfo({
        id: details.declarationInfo.id,
        place: details.declarationInfo.place,
        packagesCount: details.declarationInfo.packagesCount,
        grossMass: details.declarationInfo.grossMass,
        dispatchCountryCode: details.declarationInfo.dispatchCountryCode,
        destinationCountryCode: details.declarationInfo.destinationCountryCode,
        transitCountries: [...details.declarationInfo.transitCountries],
        goodsLocationQualifier: details.declarationInfo.goodsLocationQualifier,
        goodsLocation: details.declarationInfo.goodsLocation,
        transitLoadingDate: details.declarationInfo.transitLoadingDate,
        transitDateLimit: details.declarationInfo.transitDateLimit,
      } as IDeclarationInfo))
    }
  }

  const dispatchGuarantee = (details: DeclarationDetailed) => {
    if (details.guarantee) {
      store.dispatch(addGuarantee({
        id: details.guarantee.id,
        amount: details.guarantee.amount,
        declarationId: details.guarantee.declarationId,
      } as IGuarantee))
    }
  }

  const dispatchTransport = (details: DeclarationDetailed) => {
    if (details.transport) {
      store.dispatch(addTransport({
        id: details.transport.id,
        departureTruckNo: details.transport.departureTruckNo,
        departureNationality: details.transport.departureNationality,
        departureCustomsOffice: details.transport.departureCustomsOffice,
        borderCrosserTruckNo: details.transport.borderCrosserTruckNo,
        borderCrosserNationality: details.transport.borderCrosserNationality,
        borderTransportMode: details.transport.borderTransportMode,
        isIndicatorContainerised: details.transport.isIndicatorContainerised,
        destinationCustomsOffice: details.transport.destinationCustomsOffice,
        inlandTransportMode: details.transport.inlandTransportMode,
        unloadingPlace: details.transport.unloadingPlace,
        loadingPlace: details.transport.loadingPlace,
        declarationId: details.transport.declarationId,
        transportContainers: [...details.transport.transportContainers],
        transportCustomsOffices: [...details.transport.transportCustomsOffices],
      } as ITransport))
    }
  }

  function dispatchConsignmentDetails(details: DeclarationDetailed) {
    store.dispatch(addConsignmentDetails({
      consignor: details.consignor !== null ? {
        id: details.consignor.id,
        target: 'CONSIGNOR',
        name: details.consignor.name ? details.consignor.name.substr(0, 35) : details.consignor.name,
        street: details.consignor.street,
        city: details.consignor.city,
        country: details.consignor.country,
        zip: details.consignor.zip,
        eori: details.consignor.eori,
      } : null,
      consignee: details.consignee !== null ? {
        id: details.consignee.id,
        target: 'CONSIGNEE',
        name: details.consignee.name ? details.consignee.name.substr(0, 35) : details.consignee.name,
        street: details.consignee.street,
        city: details.consignee.city,
        country: details.consignee.country,
        zip: details.consignee.zip,
        eori: details.consignee.eori,
      } : null,
      carrier: details.carrier !== null ? {
        id: details.carrier.id,
        target: 'CARRIER',
        name: details.carrier.name,
        street: details.carrier.street,
        city: details.carrier.city,
        country: details.carrier.country,
        zip: details.carrier.zip,
        eori: details.carrier.eori,
      } : null,
    } as IConsignmentDetailPayload))
  }

  const dispatchDeclarationDetails = (details: DeclarationDetailed) => {
    loadNctsProfileNames(details.customerId)
      .then((profileOptions) => {
        if (details.profile) {
          return details.profile.name
        }

        return profileOptions.find((profileOption) => profileOption.isDefault)?.value ?? ''
      })
      .then((defaultProfileName: string) => {
        const loadedDeclaration: IDeclaration = {
          id: details.id,
          mrn: details.mrn,
          localReferenceNumber: details.localReferenceNumber,
          status: details.status,
          declarationType: details.declarationType,
          isGoodsTradersEnabled: details.isGoodsTradersEnabled,
          isSecurityEnabled: details.isSecurityEnabled,
          consignorId: details.consignor ? details.consignor.id : null,
          consigneeId: details.consignee ? details.consignee.id : null,
          carrierId: details.carrier ? details.carrier.id : null,
          requiresAudit: details.requiresAudit ? details.requiresAudit : null,
          profile: details.profile ? details.profile.name : defaultProfileName,
          customerId: details.customerId ? details.customerId : null,
          specialCondition: details.specialCondition ? details.specialCondition : null,
          securityReferenceNumber: details.securityReferenceNumber ? details.securityReferenceNumber : null,
          shipmentNumber: details.shipmentNumber ? details.shipmentNumber : null,
        }

        store.dispatch(addDeclaration(loadedDeclaration))
      })
  }

  const appendConsignorAndConsignee = (goodsItemResponse: IGoodsItem) => {
    // eslint-disable-next-line no-param-reassign
    goodsItemResponse.consignor = {
      id: null,
      target: 'CONSIGNOR',
      name: '',
      street: '',
      city: '',
      country: '',
      zip: '',
      eori: '',
    }
    // eslint-disable-next-line no-param-reassign
    goodsItemResponse.consignee = {
      id: null,
      target: 'CONSIGNEE',
      name: '',
      street: '',
      city: '',
      country: '',
      zip: '',
      eori: '',
    }
  }

  const getGoodsItemsDocuments = (documentPromises: Promise<Array<Document>>[], declarationId: number) => {
    documentPromises.push(
      DocumentService.getAllDocuments(declarationId, DocumentType.GOODS)
        .then((goodsDocs) => [...goodsDocs]),
    )
    documentPromises.push(
      DocumentService.getAllDocuments(declarationId, DocumentType.PREVIOUS)
        .then((prevDocs) => [...prevDocs]),
    )
  }

  const prefillDeclaration = (declarationId: number | undefined | null) => {
    if (declarationId === undefined || declarationId === null) return
    DeclarationService.getDeclaration(declarationId)
      .then((details) => {
        dispatchDeclarationDetails(details)

        dispatchDeclarationInfoDetails(details)

        dispatchGuarantee(details)

        dispatchTransport(details)

        dispatchConsignmentDetails(details)
      })
      // eslint-disable-next-line no-console
      .catch((reason) => console.warn('reason', reason))

    setIsGoodsItemsLoading(true)
    GoodsItemService.getAllGoods(declarationId)
      .then((goodsItemsResponse: Array<IGoodsItem>) => {
        const documentPromises: Promise<Array<Document>>[] = []

        getGoodsItemsDocuments(documentPromises, declarationId)
        goodsItemsResponse.sort(sortBySortOrder)

        Promise.all(documentPromises)
          .then((promiseDocuments: Array<Array<Document>>) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const document of promiseDocuments) {
              // eslint-disable-next-line no-param-reassign
              goodsItemsResponse = goodsItemsResponse.map((goodsItemResponse) => {
                const goodsDocs = document.filter((goodsDoc) => goodsDoc.goodsItemId === goodsItemResponse.id)
                if (goodsDocs !== null && goodsDocs.length > 0) {
                  if (goodsItemResponse.documents !== undefined) {
                    // eslint-disable-next-line no-param-reassign
                    goodsItemResponse.documents = [...goodsItemResponse.documents, ...goodsDocs]
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    goodsItemResponse.documents = [...goodsDocs]
                  }
                }
                if (goodsItemResponse.documents) {
                  goodsItemResponse.documents
                    .forEach((doc) => {
                      doc.files
                        .sort(sortBySortOrder)
                    })
                }
                appendConsignorAndConsignee(goodsItemResponse)

                return goodsItemResponse
              })
            }

            getGoodsItemsConsignmentDetails(goodsItemsResponse)
              .then((itemsWithDetails) => {
                updateGoodsItemsState(itemsWithDetails)
              }, (error) => {
                updateGoodsItemsState(goodsItemsResponse)
                toast.warn(t('messages.loadingGoodsItemTradersError'))
                // eslint-disable-next-line no-console
                console.warn(error)
              })
          })
      })
  }

  return { prefillDeclaration, nctsProfileNames, loadNctsProfileNames }
}

export default usePrefillDeclaration
