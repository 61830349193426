/* eslint-disable react/require-default-props */
import axios, { AxiosError } from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  getClassifierName, getValue, isMimeTypeExcel, isMimeTypeImage, isMimeTypePdf, isMimeTypeWord,
} from '../../../helpers'
import { apiService, handleResponseData } from '../../../services/api.service'
import { DocumentFile, DocumentType } from '../../../types/Document'
import { IClassifier } from '../../../types/IClassifier'
import { DeclarationDetailed } from '../../../types/IDeclaration'
import { IGoodsItem } from '../../../types/IGoodsItem'
import { PageResponse } from '../../../types/PageResponse'
import DocumentService from '../../Declaration/services/document.service'
import DataRow from './DataRow'
import { FilePreviewModal } from './FilePreviewModal'
import { getInvalidIconData } from './invalid-icon.helper'
import TransitField from './transitFields'
import InvalidIcon from './InvalidIcon'

interface GoodsRowProps {
  transit: DeclarationDetailed
  goodsItem: IGoodsItem
  countries: Array<IClassifier>
  packageTypes: Array<IClassifier>
  documentTypes: Array<IClassifier>
  previousDocumentTypes: Array<IClassifier>
  procedureTypes: Array<IClassifier>
  invalidFields?: Array<{ pointer: string; reason: string }>
  onInvalidIconClick?: () => void
  isLoading: boolean
}

// eslint-disable-next-line react/require-default-props
function DetailViewGoodsRow(props: GoodsRowProps) {
  const {
    goodsItem, transit, countries, packageTypes, documentTypes, previousDocumentTypes, procedureTypes,
    invalidFields, onInvalidIconClick, isLoading,
  } = props
  const { t, i18n } = useTranslation()
  const [isPreviewModalVisible, setPreviewModalVisibility] = useState(false)
  const [previewModalFile, setPreviewModalFile] = useState<DocumentFile>()

  const goodsItemDescriptionIconData = useMemo(() => getInvalidIconData([TransitField.GOODS_ITEM_GOODS_DESCRIPTION,
    TransitField.GOODS_ITEM_ITEM_NUMBER], invalidFields), [invalidFields])

  const loadingSpinner = () => (
    <div className="d-flex mt-5">
      <i className="fa fab fa-spinner-third fa-spin fa-3x text-primary" />
    </div>
  )

  const goodsItemGoodsDocumentIconData = useMemo(() => getInvalidIconData([
    TransitField.GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_COMPLEMENT_OF_INFORMATION,
    TransitField.GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_DOCUMENT_REFERENCE,
    TransitField.GOODS_ITEM_PRODUCED_DOCUMENTS_CERTIFICATES_DOCUMENT_TYPE], invalidFields), [invalidFields])
  const goodsItemPreviousDocumentProcedureTypeIconData = useMemo(() => getInvalidIconData([
    TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_PROCEDURE_TYPE], invalidFields), [invalidFields])
  const goodsItemPreviousDocumentDocumentTypeIconData = useMemo(() => getInvalidIconData([
    TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_DOCUMENT_TYPE], invalidFields), [invalidFields])
  const goodsItemPreviousDocumentReferenceIconData = useMemo(() => getInvalidIconData([
    TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_PREVIOUS_DOCUMENT_REFERENCE], invalidFields), [invalidFields])
  const goodsItemPreviousDocumentHeaderIconData = useMemo(() => getInvalidIconData([
    TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_COMPLEMENT_OF_INFORMATION,
    TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES_ITEM,
    TransitField.GOODS_ITEM_PREVIOUS_ADMINISTRATIVE_REFERENCES], invalidFields), [invalidFields])

  const searchByCode = (code: string) => new Promise<Array<IClassifier>>((resolve, reject) => {
    axios.get(
      apiService.getFullApiUrl('/classifier/page'),
      {
        params: {
          code: code.padEnd(8, '0'),
          classifierGroup: 'COMBINED_NOMENCLATURE',
        },
      },
    ).then(handleResponseData).then((response: PageResponse<IClassifier>) => {
      resolve(response.content)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })

  const getCodeValue = (code: string | null) => new Promise<IClassifier | null>((resolve, reject) => {
    if (code && code.length >= 4) {
      searchByCode(code).then((initialClassifiers) => {
        if (initialClassifiers.length < 1) {
          toast.error('Error loading HS-code')
          resolve(null)
        } else {
          resolve(initialClassifiers[0])
        }
      }).catch((error) => reject(error))
    } else if (code && code.length > 1) {
      toast.error(`Error loading HS-code ${code}`, { autoClose: false })
      resolve(null)
    } else {
      resolve(null)
    }
  })

  async function getHsCodeLabel(itemRow: IGoodsItem) {
    const name = (await getCodeValue(itemRow.hsCode))
    return (name ? name.codeLabel : (itemRow.hsCode || ''))
  }

  const [hsCodeLabel, setHsCodeLabel] = useState((goodsItem.hsCode || ''))
  useEffect(() => {
    getHsCodeLabel(goodsItem).then((label) => {
      setHsCodeLabel(label)
    })
  }, [goodsItem.hsCode])

  const previousDocuments = (
    goodsItem.documents !== undefined && goodsItem.documents.filter((doc) => doc.type === DocumentType.PREVIOUS).map((document) => {
      if (getValue(document.procedureType, 'PROCEDURE_TYPE_').length === 0
        && getValue(document.documentType, 'PREVIOUS_DOCUMENT_TYPE_').length === 0
        && getValue(document.number).length === 0) {
        return []
      }

      return (
        [
          <div className="heading mt-3 mb-0 border-bottom-0" key="goodsItemPreviousDocument">
            <h5 className={`${goodsItemPreviousDocumentHeaderIconData.isInvalid ? 'd-flex text-danger' : ''}`}>
              <span>{t('goodsItem.previousDocument')}</span>
              <InvalidIcon
                key="goodsItemPreviousDocumentHeaderIcon"
                iconData={goodsItemPreviousDocumentHeaderIconData}
                onInvalidIconClick={onInvalidIconClick}
              />
            </h5>
          </div>,
          <div className="row mb-2 lh-sm flex-basis-auto" key={`previousDocumentProcedureNumeric${document.sortOrder}`}>
            <div className="mb-2 mb-md-0 col-12 col-md-3 d-flex align-items-center">
              <small className="text-dark fw-bold">
                {t('goodsItem.previousDocumentProcedureNumeric')}
              </small>
            </div>
            <div
              className={`mb-2 mb-md-0 col-12 col-md-9 d-flex align-items-center ${
                goodsItemPreviousDocumentProcedureTypeIconData.isInvalid ? 'text-danger' : ''}`}
            >
              <small className={`${goodsItemPreviousDocumentProcedureTypeIconData.isInvalid ? 'd-flex text-danger' : ''}`}>
                {
                  `${getValue(document.procedureType, 'PROCEDURE_TYPE_')} ${
                    getClassifierName(document.procedureType, procedureTypes, i18n.language).length > 0
                      ? `(${getClassifierName(document.procedureType, procedureTypes, i18n.language)})` : ''}`
                }
                <InvalidIcon
                  key="goodsItemPreviousDocumentProcedureTypeIcon"
                  iconData={goodsItemPreviousDocumentProcedureTypeIconData}
                  onInvalidIconClick={onInvalidIconClick}
                />
              </small>
            </div>
          </div>,
          <div className="row mb-2 lh-sm flex-basis-auto" key={`previousDocumentTypeNumeric${document.sortOrder}`}>
            <div className="mb-2 mb-md-0 col-12 col-md-3 d-flex align-items-center">
              <small className="text-dark fw-bold">
                {t('goodsItem.previousDocumentTypeNumeric')}
              </small>
            </div>
            <div className="mb-2 mb-md-0 col-12 col-md-9 d-flex align-items-center">
              <small className={`${goodsItemPreviousDocumentDocumentTypeIconData.isInvalid ? 'd-flex text-danger' : ''}`}>
                {
                  document.documentType
                  && `${
                    getValue(document.documentType, 'PREVIOUS_DOCUMENT_TYPE_')
                  } ${
                    getClassifierName(document.documentType, previousDocumentTypes, i18n.language).length > 0
                      ? (`(${getClassifierName(document.documentType, previousDocumentTypes, i18n.language)})`) : ''}`
                }
                <InvalidIcon
                  key="goodsItemPreviousDocumentDocumentTypeIcon"
                  iconData={goodsItemPreviousDocumentDocumentTypeIconData}
                  onInvalidIconClick={onInvalidIconClick}
                />
              </small>
            </div>
          </div>,
          <div className="row mb-2 lh-sm flex-basis-auto" key={`previousDocumentNumberNumeric${document.sortOrder}`}>
            <div className="mb-2 mb-md-0 col-12 col-md-3 d-flex align-items-center">
              <small className="text-dark fw-bold">
                {t('goodsItem.previousDocumentNumberNumeric')}
              </small>
            </div>
            <div className="mb-2 mb-md-0 col-12 col-md-9 d-flex align-items-center">
              <small className={`${goodsItemPreviousDocumentReferenceIconData.isInvalid ? 'd-flex text-danger' : ''}`}>
                {
                  `${getValue(document.number)}`
                }
                <InvalidIcon
                  key="goodsItemPreviousDocumentNumberIcon"
                  iconData={goodsItemPreviousDocumentReferenceIconData}
                  onInvalidIconClick={onInvalidIconClick}
                />
              </small>
            </div>
          </div>,
        ]
      )
    })
  )

  const detailsColumn = (
    <div className="p-4 d-flex flex-column flex-basis-0 flex-grow-1" key="goodsItemDetails">
      <div className="heading mb-0 border-bottom-0" key={`goodsItem${goodsItem.id}DetailsHeading`}>
        <h5 className={`${goodsItemDescriptionIconData.isInvalid ? 'text-danger d-flex' : 'text-muted'} mb-1`}>
          {`${goodsItem.description}`}
          <InvalidIcon iconData={goodsItemDescriptionIconData} onInvalidIconClick={onInvalidIconClick} />
        </h5>
      </div>
      <div className="d-flex flex-column" key="goodsItemData">
        <DataRow
          key={`GoodsItemQuantity${goodsItem.id}`}
          title={t('goodsItem.quantity')}
          value={`${goodsItem.quantity !== null ? goodsItem.quantity : ''}`}
          extraValue={`${getClassifierName(goodsItem.quantityUnit, packageTypes, i18n.language)}`}
          fieldLabels={[TransitField.GOODS_ITEM_PACKAGES_NUMBER_OF_PACKAGES, TransitField.GOODS_ITEM_PACKAGES_KIND_OF_PACKAGES]}
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        <DataRow
          title={t('goodsItem.hsCode')}
          value={hsCodeLabel}
          key={`GoodsItemHsCode${goodsItem.id}`}
          fieldLabels={[TransitField.GOODS_ITEM_COMMODITY_CODE]}
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        <DataRow
          title={t('goodsItem.grossWeight')}
          value={`${getValue(goodsItem.grossWeight?.toString())}`}
          key={`GoodsItemGross${goodsItem.id}`}
          fieldLabels={[TransitField.GOODS_ITEM_GROSS_MASS]}
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        <DataRow
          title={t('goodsItem.netWeight')}
          value={`${getValue(goodsItem.netWeight?.toString())}`}
          key={`GoodsItemGross${goodsItem.id}`}
          fieldLabels={[TransitField.GOODS_ITEM_NET_MASS]}
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        {previousDocuments}
      </div>
    </div>
  )

  const tradersColumn = transit.isGoodsTradersEnabled ? (
    <div className="p-4 d-flex flex-column flex-basis-0 flex-grow-1" key="traders">
      <div className="d-flex flex-column" key="tradersBody">
        <DataRow
          key={`${goodsItem?.consignee?.id}consignorName`}
          columnWidth={4}
          title={t('declaration.consignor')}
          value={getValue(goodsItem.consignor?.name)}
          fieldLabels={[TransitField.GOODS_ITEM_CONSIGNOR_TRADER_NAME,
            TransitField.GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_NAME]}
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        <DataRow
          key={`${goodsItem?.consignee?.id}consignorAddress`}
          columnWidth={4}
          value={`${getValue(goodsItem.consignor?.street)}, ${getValue(goodsItem.consignor?.city)}`}
          secondValue={`${getClassifierName(goodsItem.consignor?.country, countries, i18n.language)},
          ${getValue(goodsItem.consignor?.zip)}`}
          fieldLabels={
            [TransitField.GOODS_ITEM_CONSIGNOR_TRADER_CITY,
              TransitField.GOODS_ITEM_CONSIGNOR_TRADER_STREET_AND_NUMBER,
              TransitField.GOODS_ITEM_CONSIGNOR_TRADER_COUNTRY_CODE,
              TransitField.GOODS_ITEM_CONSIGNOR_TRADER_POSTAL_CODE,
              TransitField.GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_NAME,
              TransitField.GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_STREET_AND_NUMBER,
              TransitField.GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_COUNTRY_CODE,
              TransitField.GOODS_ITEM_CONSIGNOR_SECURITY_TRADER_POSTAL_CODE]
          }
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        <DataRow
          key={`${goodsItem?.consignee?.id}consigneeName`}
          columnWidth={4}
          title={t('declaration.consignee')}
          value={getValue(goodsItem.consignee?.name)}
          fieldLabels={[TransitField.GOODS_ITEM_CONSIGNEE_TRADER_NAME]}
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
        <DataRow
          key={`${goodsItem?.consignee?.id}consigneeAddress`}
          columnWidth={4}
          value={`${getValue(goodsItem.consignee?.street)}, ${getValue(goodsItem.consignee?.city)}`}
          secondValue={`${getClassifierName(goodsItem.consignee?.country, countries, i18n.language)},
          ${getValue(goodsItem.consignee?.zip)}`}
          fieldLabels={
            [TransitField.GOODS_ITEM_CONSIGNEE_TRADER_CITY,
              TransitField.GOODS_ITEM_CONSIGNEE_TRADER_STREET_AND_NUMBER,
              TransitField.GOODS_ITEM_CONSIGNEE_TRADER_COUNTRY_CODE,
              TransitField.GOODS_ITEM_CONSIGNEE_TRADER_POSTAL_CODE]
          }
          invalidFields={invalidFields}
          onInvalidIconClick={onInvalidIconClick}
        />
      </div>
    </div>
  ) : ''

  const getFilePreviewTypeByMimeType = (mimeType: string) => {
    if (isMimeTypeImage(mimeType)) {
      return 'IMAGE'
    }
    if (isMimeTypeExcel(mimeType)) {
      return 'EXCEL'
    }
    if (isMimeTypeWord(mimeType)) {
      return 'WORD'
    }

    return isMimeTypePdf(mimeType) ? 'PDF' : null
  }

  const downloadOrOpenFile = (file: DocumentFile) => {
    if (file.url) {
      const filePreviewType = getFilePreviewTypeByMimeType(file.mimeType)
      switch (filePreviewType) {
        case 'IMAGE':
        case 'PDF':
        case 'EXCEL':
        case 'WORD':
          setPreviewModalFile(file)
          setPreviewModalVisibility(!isPreviewModalVisible)
          break
        default:
          DocumentService.saveBlob(file, file.url, 'url')
      }
    }
  }

  const goodsDocuments = (
    !isLoading
    && goodsItem.documents !== undefined && goodsItem.documents.filter((doc) => doc.type === DocumentType.GOODS).map((document) => (
      <div className="row mb-2 lh-sm flex-basis-auto mb-2" key={`GoodsItemDocumentNumber${document.sortOrder}`}>
        <div
          key={`${document.id ?? 'documentId'}DocumentType${document.type.toString()}`}
          className="col-5 d-flex align-items-start"
        >

          <small>
            {document.documentType !== null
              ? `${getValue(document.documentType, 'DOCUMENT_TYPE_')} (${
                getClassifierName(document.documentType, documentTypes, i18n.language)})`
              : ''}
          </small>
        </div>
        <div
          key={`${document.id ?? 'documentId'}DocumentNumber`}
          className={`col-3 d-flex align-items-start ${goodsItemGoodsDocumentIconData.isInvalid ? 'text-danger' : ''}`}
        >
          <small>
            {`${getValue(document.number)}`}
          </small>
          <InvalidIcon
            key={`goodsItemPreviousDocumentProcedureTypeIcon${document.number}`}
            iconData={goodsItemGoodsDocumentIconData}
            onInvalidIconClick={onInvalidIconClick}
          />
        </div>
        <div
          key={`${document.id ?? 'documentId'}DocumentFiles`}
          className="col-4"
        >
          {
            document.files && (
              document.files.map((file) => (
                <div
                  key={(file.id ?? 'fileId') + (file.uuid ?? 'uuid')}
                  className="d-flex flex-column justify-content-center align-items-start w-100 mt-1"
                >
                  {
                    file.url && isMimeTypeImage(file.mimeType) && (
                      <img
                        key={`${file.uuid}`}
                        role="presentation"
                        className="w-66 pointer content-visibility-auto"
                        loading="lazy"
                        decoding="async"
                        onClick={() => downloadOrOpenFile(file)}
                        alt={file.filename}
                        src={file.url}
                      />
                    )
                  }
                  {
                    file.url && !isMimeTypeImage(file.mimeType) && (
                      <button
                        className="btn btn-link p-1 px-0 overflow-ellipsis text-left"
                        type="button"
                        onClick={() => downloadOrOpenFile(file)}
                      >
                        <i className={`fal ${file.mimeType === 'application/pdf' ? 'fa-file-pdf' : 'fa-file'} fa-md me-2`} />
                        <span>
                          {file.filename.substr(0, 25)}
                        </span>
                      </button>

                    )
                  }
                </div>
              )))
          }
        </div>
      </div>
    ))
  )

  const documentsColumn = (
    <div className="p-4 d-flex flex-column flex-basis-0 flex-grow-1" key="documents">
      <div className="heading mb-0 border-bottom-0" key="documentsHeader">
        <h5>
          {t('goodsItem.documents')}
        </h5>
      </div>
      {isLoading && loadingSpinner() }
      <div className="d-flex flex-column" key="documentsBody">
        {goodsDocuments}
      </div>
    </div>
  )

  const renderFilePreviewModal = () => {
    if (!previewModalFile) {
      return null
    }

    const filePreviewType = getFilePreviewTypeByMimeType(previewModalFile.mimeType)
    if (!filePreviewType) {
      return null
    }

    return (
      <FilePreviewModal
        type={filePreviewType}
        key="filePreviewModal"
        isVisible={isPreviewModalVisible}
        toggleVisibility={() => setPreviewModalVisibility(!isPreviewModalVisible)}
        file={previewModalFile}
      />
    )
  }

  return (
    <>
      <div className="heading m-3 mb-0">
        <h5 className="text-dark">
          {`${goodsItem.sortOrder + 1}. ${t('declaration.headers.goodsItem').toLowerCase()}`}
        </h5>
      </div>
      <div className="d-flex flex-wrap" key={`${goodsItem.sortOrder}`}>
        {detailsColumn}
        {tradersColumn}
        {documentsColumn}
      </div>
      {
        previewModalFile && renderFilePreviewModal()
      }
    </>
  )
}

export default DetailViewGoodsRow

