import { IAction } from '../actionTypes'
import { IConsignmentDetail, IConsignmentDetailPayload } from '../../types/IConsignmentDetail'

interface IConsignmentDetailReducerState {
  consignor: IConsignmentDetail | null,
  consignee: IConsignmentDetail | null,
  carrier: IConsignmentDetail | null,
}

const initialState: IConsignmentDetailReducerState = {
  consignor: null,
  consignee: null,
  carrier: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function consignmentDetailReducer(state = initialState, action: IAction<IConsignmentDetailPayload>) {
  switch (action.type) {
    case 'ADD_CONSIGNMENT_DETAILS':
      return {
        ...state,
        consignor: action.payload.consignor !== null ? action.payload.consignor : state.consignor,
        consignee: action.payload.consignee !== null ? action.payload.consignee : state.consignee,
        carrier: action.payload.carrier !== null ? action.payload.carrier : state.carrier,
      }
    default:
      return state
  }
}
