import { excludeDeleted, hasText } from '../../../common/utils/common-util'
import { HouseConsignmentType } from '../schemas/houseConsignmentSchema'

const R0507Validator = {
  hasMultipleUniqueCountryOfDestinationForConsignmentItem: (houseConsignment: HouseConsignmentType): boolean => {
    const items = houseConsignment.consignmentItem.filter(excludeDeleted)
    if (items.length !== items.filter((consignmentItem) => hasText(consignmentItem.countryOfDestination)).length) {
      return true
    }

    let previous = ''
    return houseConsignment.consignmentItem.filter(excludeDeleted).some((consignmentItem, index) => {
      if (index === 0) {
        previous = consignmentItem.countryOfDestination
        return false
      }

      if (previous !== consignmentItem.countryOfDestination) {
        return true
      }

      previous = consignmentItem.countryOfDestination
      return false
    })
  },
  hasMultipleUniqueReferenceNumberUcrForConsignmentItem: (houseConsignment: HouseConsignmentType): boolean => {
    const items = houseConsignment.consignmentItem.filter(excludeDeleted)
    if (items.length !== items.filter((consignmentItem) => hasText(consignmentItem.referenceNumberUCR)).length) {
      return true
    }

    let previous = ''
    return houseConsignment.consignmentItem.filter(excludeDeleted).some((consignmentItem, index) => {
      if (index === 0) {
        previous = consignmentItem.referenceNumberUCR
        return false
      }

      if (previous !== consignmentItem.referenceNumberUCR) {
        return true
      }

      previous = consignmentItem.referenceNumberUCR
      return false
    })
  },
} as const

export default R0507Validator
