import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CreateHouseConsignmentRequest, HouseConsignmentResponse, UpdateHouseConsignmentRequest } from '../../../common/models'
import DeclarationApi from '../apiConfig'

const {
  baseUrl,
  queryKeys: { getHouseConsignmentByConsignmentId },
} = DeclarationApi.paths.houseConsignment

function useHouseConsignmentApi(consignmentId: number | null, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const postHouseConsignment = useMutation({
    mutationFn: async (data: CreateHouseConsignmentRequest[]) => (await axios.post<HouseConsignmentResponse[]>((baseUrl), data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getHouseConsignmentByConsignmentId(consignmentId) }),
  })

  const putHouseConsignment = useMutation({
    mutationFn: async (data: UpdateHouseConsignmentRequest[]) => (await axios.put<HouseConsignmentResponse[]>((baseUrl), data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getHouseConsignmentByConsignmentId(consignmentId) }),
  })

  const putHouseConsignmentDraft = useMutation({
    mutationFn: async (data: UpdateHouseConsignmentRequest[]) => (await axios
      .put<HouseConsignmentResponse[]>(`${baseUrl}/draft`, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getHouseConsignmentByConsignmentId(consignmentId) }),
  })

  const deleteHouseConsignment = useMutation({
    mutationFn: async (id: number) => (await axios.delete<void>(`${baseUrl}/${id}`)).data,
  })

  const fetchHouseConsignments = useQuery({
    queryKey: getHouseConsignmentByConsignmentId(consignmentId),
    queryFn: async ({ signal }) => (await axios
      .get<HouseConsignmentResponse[]>(`${baseUrl}/${consignmentId}`, { signal })).data,
    enabled: !!consignmentId && !postHouseConsignment.isLoading && !putHouseConsignment.isLoading
      && !deleteHouseConsignment.isLoading && !isSubmitting,
    staleTime: Infinity, // never stale
  })

  return {
    fetchHouseConsignments,
    postHouseConsignment,
    putHouseConsignment,
    putHouseConsignmentDraft,
    deleteHouseConsignment,
  }
}

export default useHouseConsignmentApi
