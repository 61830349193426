import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select, { SingleValue } from 'react-select'
import { getAuditStatusOptions } from '../config'
import { ISelectOption } from '../../../types/IClassifier'

interface DecisionModalProps {
  title: string;
  isVisible: boolean;
  isLoading: boolean;
  toggleVisibility: () => void;
  onConfirmation: () => void;
  onRejection: () => void;
  onSelect: (value: ISelectOption | null) => void;
  onChange: (value: string) => void;
}

function DecisionModal(props: DecisionModalProps) {
  const {
    title,
    isVisible,
    toggleVisibility,
    isLoading,
    onConfirmation,
    onRejection,
    onSelect,
    onChange,
  } = props

  const [selectValue, setSelectValue] = useState<SingleValue<ISelectOption>>({
    value: '',
    label: '',
  })

  const handleChange = (selectedOption: ISelectOption | null) => {
    onSelect(selectedOption)
    setSelectValue(selectedOption)
  }

  const handleInput = (input: string) => {
    onChange(input)
  }

  const handleSubmitCheck = () => {
    onConfirmation()
  }

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  const { t } = useTranslation()
  return (
    <Modal
      show={isVisible}
      dialogClassName="modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => onRejection()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{title}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          disabled={isLoading}
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-self-center w-100">
          <Select
            isDisabled={isLoading}
            value={selectValue?.value === '' ? { label: 'In Review', value: 'IN_REVIEW' } : selectValue}
            options={getAuditStatusOptions()}
            onChange={handleChange}
          />
        </div>
        {
          (selectValue?.value === 'DECLINED' || selectValue?.value === 'PROHIBITED_DESCRIPTION') && (
            <div className="d-flex flex-column align-self-center mt-4">
              <h2 className="mb-2">{t('audit.reasonForDecline')}</h2>
              <textarea
                className="w-100 form-control-lg mb-2"
                onChange={(e) => handleInput(e.target.value)}
              />
            </div>
          )
        }
        { selectValue?.value !== '' && (
          <div className="d-flex align-content-end justify-content-end mt-5">
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
              onClick={() => handleSubmitCheck()}
            >
              {
                isLoading && loadingSpinner()
              }
              {
                !isLoading && (<span>{t('audit.submitAudit')}</span>)
              }
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default DecisionModal
