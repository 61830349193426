import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'

// eslint-disable-next-line max-len
function postExport(
  customerId: number | null,
  dateRangeStart: Date,
  dateRangeEnd: Date,
  language: 'EN' | 'RU',
  statuses: Array<string>,
  useCreatedDate: boolean,
  profiles: Array<string>,
) {
  return axios
    .post(
      apiService.getFullApiUrl('/export'),
      {
        customerId,
        dateRangeStart: dateRangeStart.toISOString() ?? null,
        dateRangeEnd: dateRangeEnd.toISOString() ?? null,
        language,
        statuses,
        useCreatedDate,
        profiles,
      },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function createExport(
  customerId: number | null,
  dateRangeStart: Date,
  dateRangeEnd: Date,
  language: 'EN' | 'RU',
  statuses: Array<string>,
  useCreatedDate: boolean,
  profiles: Array<string>,
) {
  const baseUrl = process.env.REACT_APP_API_URL
  return axios.post(`${baseUrl}/v2/export`, {
    customerId,
    dateRangeStart: dateRangeStart.toISOString() ?? null,
    dateRangeEnd: dateRangeEnd.toISOString() ?? null,
    language,
    statuses,
    useCreatedDate,
    profiles,
  })
    .then(handleResponseData)
    .catch(handleApiException)
}

const ExportService = {
  postExport,
  createExport,
}

export default ExportService
