import { excludeDeleted } from '../../../common/utils/common-util'
import { CustomsOfficeOfTransitDeclared } from '../schemas/commonConsignmentSchemas'
import { IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'

const R0789Validator = {
  getActiveBorderTransportMeansMultiplicity: (transitCustomsOffices: CustomsOfficeOfTransitDeclared[]): number => {
    if (IS_TRANSITIONAL_PERIOD) {
      return 1 // E1406
    }
    if (transitCustomsOffices.filter(excludeDeleted).length > 0) {
      return 9
    }

    return 1
  },

} as const

export default R0789Validator
