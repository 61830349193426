import { useState } from 'react'
import { ISelectOption } from '../../../../../types/IClassifier'
import { DeclarationCountry } from '../../../../../context/DeclarationContext'

const acceptableLatvianPreviousDocumentTypeOptionCodes = [
  'PREVIOUS_DOCUMENT_TYPE_821',
  'PREVIOUS_DOCUMENT_TYPE_EX',
  'PREVIOUS_DOCUMENT_TYPE_IM',
  'PREVIOUS_DOCUMENT_TYPE_337',
  'PREVIOUS_DOCUMENT_TYPE_785',
  'PREVIOUS_DOCUMENT_TYPE_705',
  'PREVIOUS_DOCUMENT_TYPE_ZZZ',
]

const acceptableFinlandPreviousDocumentTypeOptionCodes = [
  'PREVIOUS_DOCUMENT_TYPE_00',
  'PREVIOUS_DOCUMENT_TYPE_T1',
  'PREVIOUS_DOCUMENT_TYPE_31',
  'PREVIOUS_DOCUMENT_TYPE_45',
  'PREVIOUS_DOCUMENT_TYPE_71',
  'PREVIOUS_DOCUMENT_TYPE_SUM',
  'PREVIOUS_DOCUMENT_TYPE_337',
]

// eslint-disable-next-line import/prefer-default-export
export function useDocumentTypeOptions(
  documentTypeOptions: ISelectOption[],
  declarationCountry: DeclarationCountry | null,
  isPreviousDocumentType?: boolean,
) {
  const [options, setOptions] = useState<ISelectOption[]>(documentTypeOptions)

  const extractLatvianPreviousDocumentTypeOptions = (allOptions: ISelectOption[]): Array<ISelectOption> => (
    allOptions.filter((option) => acceptableLatvianPreviousDocumentTypeOptionCodes.indexOf(option.value) !== -1)
  )

  const extractFinlandPreviousDocumentTypeOptions = (allOptions: ISelectOption[]): Array<ISelectOption> => (
    allOptions.filter((option) => acceptableFinlandPreviousDocumentTypeOptionCodes.indexOf(option.value) !== -1)
  )

  const setTypeOptions = (newOptions: ISelectOption[]) => {
    if (isPreviousDocumentType !== true) {
      setOptions([...newOptions])
      return
    }

    switch (declarationCountry) {
      case DeclarationCountry.LATVIA:
        setOptions([...(extractLatvianPreviousDocumentTypeOptions(newOptions))])
        break
      case DeclarationCountry.FINLAND:
        setOptions([...(extractFinlandPreviousDocumentTypeOptions(newOptions))])
        break
      default:
        if (declarationCountry === DeclarationCountry.LITHUANIA) {
          const lithuania = newOptions.filter((option) => option.value === 'PREVIOUS_DOCUMENT_TYPE_337')
          setOptions([...newOptions.filter((option) => acceptableFinlandPreviousDocumentTypeOptionCodes.indexOf(option.value) === -1),
            { value: lithuania[0].value, label: lithuania[0].label }])
        } else {
          setOptions([...newOptions.filter((option) => acceptableFinlandPreviousDocumentTypeOptionCodes.indexOf(option.value) === -1)])
        }
        break
    }
  }

  return { options, setTypeOptions }
}
