import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabType } from '..'
import { ISelectOption } from '../../../../types/IClassifier'
import useHeaderNotification from './hooks/useHeaderNotification'
import NotificationConfirmationModal from './modals/NotificationConfirmationModal'
import useEmailNotification from './hooks/useEmailNotification'
import NotificationEditor from './NotificationEditor'
import NotificationActionHeader from './NotificationActionHeader'

interface NotificationProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly tabType: TabType;
}

export default function NotificationTab({
  loading,
  setLoading,
}: NotificationProps) {
  const { t } = useTranslation()
  const selectOptions: ISelectOption[] = [{
    value: 'EDIT',
    label: t('notification.edit'),
  }, {
    value: 'SEND',
    label: t('notification.send'),
  }]
  const [view, setView] = useState<ISelectOption>(selectOptions[0])

  const {
    headerNotificationMessage,
    setHeaderNotificationMessage,
    updateHeaderNotificationMessage,
  } = useHeaderNotification(
    loading,
    setLoading,
  )

  const {
    editorContent,
    isConfirmationModalOpen,
    setEditorContent,
    setIsConfirmationModalOpen,
    submitEmailNotification,
  } = useEmailNotification(
    loading,
    setLoading,
  )

  return (
    <>
      <NotificationActionHeader
        selectOptions={selectOptions}
        loading={loading}
        view={view}
        setView={setView}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        updateHeaderNotificationMessage={updateHeaderNotificationMessage}
      />
      <NotificationEditor
        currentView={view.value as string}
        headerNotificationMessage={headerNotificationMessage}
        setHeaderNotificationMessage={setHeaderNotificationMessage}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
      />
      <NotificationConfirmationModal
        loading={loading}
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        submitNotification={submitEmailNotification}
      />
    </>
  )
}
