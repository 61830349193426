import FormRow from 'components/FormRow'
import React, {
  ChangeEvent, Dispatch, useContext, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import Select from 'react-select'
import { format } from 'date-fns'
import { getSelectCountryFilter } from '../../../components/react-select/CountryFunctions'
import ROUTES from '../../../config/routes'
import { AccessLevel, DeclarationContext, DeclarationCountry } from '../../../context/DeclarationContext'
import {
  getNameForLocale, getSelectValue, isValuePrecisionValid, sortBySortOrder,
} from '../../../helpers'
import { addDeclaration, addDeclarationInfo, addGuarantee } from '../../../redux/actions'
import { IStoreState } from '../../../redux/store'
import { IClassifier, ISelectOption } from '../../../types/IClassifier'
import {
  DeclarationStatusEnum, FinnishQualifiers, ITransitCountry, ITransport,
} from '../../../types/IDeclaration'
import { InvalidField } from '../../Transits/TransitsAside/detailView'
import { getInvalidIconData } from '../../Transits/TransitsAside/invalid-icon.helper'
import TransitField from '../../Transits/TransitsAside/transitFields'
import DirectoEvent from './DirectoEvent'
import { CUSTOMS_OFFICE_PREFIX } from '../../../hooks/useDeclarationMetaData'
import ClassifierService from '../../../services/classifier.service'
import { UserContext } from '../../../context/UserContext'
import { UserRole } from '../../../hooks/useAuth'
import { NctsProfileNameOption } from '../../../types/NctsProfileNameResponse'

interface IDeclarationInfoColumnProps {
  toggleSecurity: () => void
  countryClassifiers: Array<IClassifier>
  countriesOptions: ISelectOption[]
  invalidFields: Array<InvalidField>
  nctsProfileNames: Array<NctsProfileNameOption>
  openCalculation: () => void
}

function DeclarationInfoColumn({
  countriesOptions,
  countryClassifiers,
  nctsProfileNames,
  invalidFields,
  toggleSecurity,
  openCalculation,
}: IDeclarationInfoColumnProps) {
  const store = useStore()
  const { metaData: { accessLevel, country } } = useContext(DeclarationContext)
  const { t, i18n } = useTranslation()

  const getDeclarationInfo = () => (store.getState() as IStoreState).declarationInfoReducer.declarationInfo
  const getGuarantee = () => (store.getState() as IStoreState).guaranteeReducer.guarantee
  const getDeclaration = () => (store.getState() as IStoreState).declarationReducer.declaration
  const [declarationInfo, setDeclarationInfo] = useState(getDeclarationInfo())
  const [guarantee, setGuarantee] = useState(getGuarantee())
  const [declaration, setDeclaration] = useState(getDeclaration())
  const getTransport = () => (store.getState() as IStoreState).transportReducer.transport
  const [transport, setTransport]: [ITransport, Dispatch<ITransport>] = useState(getTransport())
  const [transitCountries, setTransitCountries]: [Array<ITransitCountry>,
    Dispatch<ITransitCountry[]>] = useState(declarationInfo.transitCountries)

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setTransport(getTransport())
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (country === 'FI' && declarationInfo.transitLoadingDate === null) {
      store.dispatch(addDeclarationInfo({
        ...declarationInfo,
        transitDateLimit: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
      }))
    }
  }, [])

  const declarationTypesTemp: ISelectOption[] = [
    {
      value: 'T1',
      label: 'T1',
    },
  ]

  const specialConditionsOptions: ISelectOption[] = [
    {
      value: 'A',
      label: 'A - Postal and express consignments',
    },
    {
      value: 'C',
      label: 'C - Road mode of transport',
    },
    {
      value: 'D',
      label: 'D - Rail mode of transport',
    },
    {
      value: 'E',
      label: 'E - Authorised economic operators',
    },
  ]

  const [customsOfficesOptions, setCustomsOfficesOptions] = useState<Array<ISelectOption>>([])
  const [goodsLocation, setGoodsLocation] = useState<ISelectOption | null>(null)
  const [goodsLocationQualifier, setGoodsLocationQualifier] = useState<ISelectOption | null>(null)
  const [goodsLocationQualifierOptions, setGoodsLocationQualifierOptions] = useState<Array<ISelectOption>>([])

  const getCountryName = (classifier: IClassifier) => `${classifier.nameEn}`

  const { user } = useContext(UserContext)
  const currentNctsProfile = useMemo(
    () => getSelectValue(declaration.profile, nctsProfileNames) as NctsProfileNameOption,
    [declaration.profile, nctsProfileNames.length],
  )

  useEffect(() => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const object: Array<any> = Object.entries(FinnishQualifiers).map(([value, word]) => ({ value, word }))
    object.forEach((item) => {
      if (user?.role !== 'ADMIN' && item.value !== 'L') {
        setGoodsLocationQualifierOptions((previousValues) => [...previousValues, {
          value: item.value,
          label: `${item.value}: ${t(`declaration.finnish.${item.word.toLowerCase()}`)}`,
        }])
      } else if (user?.role === 'ADMIN') {
        setGoodsLocationQualifierOptions((previousValues) => [...previousValues, {
          value: item.value,
          label: `${item.value}: ${t(`declaration.finnish.${item.word.toLowerCase()}`)}`,
        }])
      }
    })
  }, [])

  const transitCountriesRows = () => {
    const countrySelects = []
    if (transitCountries.length === 0) {
      transitCountries.push({
        id: null,
        code: null,
        declarationInfoId: declarationInfo.id,
        nameEn: null,
        nameRu: null,
        sortOrder: 0,
      })
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const transitCountry of transitCountries) {
      const currentCountryClassifier = countryClassifiers.find((countryClassifier) => countryClassifier.code === transitCountry.code)
      const currentValue = currentCountryClassifier
        ? {
          label: `${currentCountryClassifier.codeLabel} - ${getCountryName(currentCountryClassifier)}`,
          value: currentCountryClassifier.code,
        } : null

      countrySelects.push(
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          key={`country${transitCountry.sortOrder}`}
          menuPlacement="auto"
          options={countriesOptions}
          filterOption={getSelectCountryFilter}
          className={`select mb-1 ${
            getInvalidIconData([TransitField.ITINERARY_COUNTRY_OF_ROUTING_CODE], invalidFields).isInvalid ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          placeholder={t('common.chooseCountry')}
          value={currentValue}
          onChange={(option: ISelectOption | null) => {
            if (option && option.value) {
              const newCountry = { ...transitCountry }
              newCountry.code = option.value
              newCountry.nameEn = option.label
              const newList = [newCountry, ...transitCountries.filter((row) => row.sortOrder !== transitCountry.sortOrder)]
              newList.sort(sortBySortOrder)
              setTransitCountries(newList)
            }
          }}
        />,
      )
    }
    return countrySelects
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setDeclarationInfo(getDeclarationInfo())
      setTransitCountries(getDeclarationInfo().transitCountries)
      setGuarantee(getGuarantee())
      setDeclaration(getDeclaration())
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    store.dispatch(addDeclarationInfo({
      ...declarationInfo,
      transitCountries,
    }))

    if (country === 'FI') {
      ClassifierService.getClassifier('CUSTOMS_OFFICES')
        .then((classifiers: IClassifier[]) => {
          const classifiersCopy = [...classifiers]
          const customOffices: Array<ISelectOption> = []
          classifiersCopy.forEach((item) => {
            if (item.code.substring(CUSTOMS_OFFICE_PREFIX.length, 2) === 'FI') {
              customOffices.push({
                value: item.code,
                label: `${item.codeLabel} - ${getNameForLocale(item, i18n.language)}`,
              })
              if (item.code === declarationInfo.goodsLocation) {
                setGoodsLocation(getSelectValue(declarationInfo.goodsLocation, customOffices)!)
              }
            }
          })
          setCustomsOfficesOptions(customOffices)
        })
    }

    if (declarationInfo.goodsLocationQualifier !== null && declarationInfo.goodsLocationQualifier !== undefined) {
      const object: Array<any> = Object.entries(FinnishQualifiers).map(([value, word]) => ({ value, word }))
      object.forEach((item) => {
        if (item.value === declarationInfo.goodsLocationQualifier) {
          setGoodsLocationQualifier({
            value: item.value,
            label: `${item.value}: ${t(`declaration.finnish.${item.word.toLowerCase()}`)}`,
          })
        }
      })
    }
    if (transport.departureCustomsOffice !== null && transport.departureCustomsOffice.substr(CUSTOMS_OFFICE_PREFIX.length, 2) !== 'FI'
        && (declarationInfo.goodsLocation !== null || declarationInfo.goodsLocationQualifier !== null)) {
      setGoodsLocation(null)
      setGoodsLocationQualifier(null)
      store.dispatch(addDeclarationInfo({
        ...declarationInfo,
        goodsLocationQualifier: null,
        goodsLocation: null,
      }))
    }
  }, [transitCountries, country])

  return (
    <div className="pt-4 px-4 col-12 col-xl-4 border-end border-bottom">
      <DirectoEvent />
      <div className="border-bottom w-100">
        <div className="heading">
          <h3>
            {t('declaration.nctsProfile')}
          </h3>
        </div>
        <div className="input-standout flex-column">
          <FormRow title={t('declaration.nctsProfile')}>
            <Select
              options={nctsProfileNames}
              menuPlacement="auto"
              isDisabled={accessLevel === AccessLevel.VIEW}
              className="select"
              placeholder={t('common.chooseProfile')}
              classNamePrefix="select"
              value={currentNctsProfile}
              onChange={(option: ISelectOption | null) => {
                if (option && option.value) {
                  store.dispatch(addDeclaration({
                    ...declaration,
                    profile: option.value ?? '',
                  }))
                }
              }}
            />
          </FormRow>
        </div>
      </div>
      <div className="heading pt-5">
        <h3>
          {t('declaration.headers.declarationInfo')}
        </h3>
      </div>
      <FormRow title={t('declaration.type')}>
        <Select
          options={declarationTypesTemp}
          className="select"
          classNamePrefix="select"
          defaultValue={declarationTypesTemp[0]}
          isDisabled
        />
      </FormRow>
      <FormRow title={t('declaration.place')}>
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData([TransitField.HEADER_DECLARATION_PLACE], invalidFields).isInvalid ? 'is-invalid' : ''
          }`}
          type="text"
          maxLength={35}
          placeholder={t('common.fieldPlaceholder')}
          value={declarationInfo.place != null ? declarationInfo.place : ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            store.dispatch(addDeclarationInfo({
              ...declarationInfo,
              place: event.target.value,
            }))
          }}
        />
      </FormRow>
      <FormRow
        title={t('declaration.security')}
        hasInfoButton
        tooltipContent={(
          <>
            {t('tooltips.security')}
          </>
        )}
      >
        <div className="form-row-special-input form-check form-switch">
          <input
            className={`form-check-input ${
              getInvalidIconData([TransitField.HEADER_SECURITY,
                TransitField.HEADER_COMMERCIAL_REFERENCE_NUMBER], invalidFields).isInvalid ? 'is-invalid' : ''
            }`}
            checked={declaration.isSecurityEnabled === true}
            disabled={(declaration.mrn !== null && declaration.mrn !== undefined && declaration.mrn.length > 0)
              || (declaration.status !== null && declaration.status !== DeclarationStatusEnum.DRAFT) || accessLevel === AccessLevel.VIEW}
            type="checkbox"
            id="flexSwitchCheckDefault"
            onChange={() => toggleSecurity()}
          />
        </div>
      </FormRow>
      <FormRow title={t('declaration.packagesCount')}>
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData([TransitField.HEADER_TOTAL_NUMBER_OF_PACKAGES,
              TransitField.HEADER_TOTAL_NUMBER_OF_ITEMS], invalidFields).isInvalid || (declarationInfo.packagesCount === null || !declarationInfo.packagesCount) ? 'is-invalid' : ''
          }`}
          type="number"
          pattern={'[\\d]+'}
          placeholder={t('common.fieldPlaceholder')}
          value={declarationInfo.packagesCount != null ? declarationInfo.packagesCount : ''}
          step={1}
          min={1}
          max={9999999}
          onKeyPress={(event) => {
            if (event.key.match('[\\d]+') === null) {
              event.preventDefault()
              event.stopPropagation()
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if ((`${event.target.value}`).length > 7) {
              return
            }
            store.dispatch(addDeclarationInfo({
              ...declarationInfo,
              packagesCount: Number(event.target.value),
            }))
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.totalGrossMass')} isLast>
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData([TransitField.HEADER_TOTAL_GROSS_MASS], invalidFields).isInvalid || (declarationInfo.grossMass === null || !declarationInfo.grossMass) ? 'is-invalid' : ''
          }`}
          type="number"
          max={99999999999}
          placeholder={t('common.fieldPlaceholder')}
          value={declarationInfo.grossMass != null ? declarationInfo.grossMass : ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (isValuePrecisionValid(event.target.value)) {
              if ((`${event.target.value}`).length > 11) {
                return
              }
              store.dispatch(addDeclarationInfo({
                ...declarationInfo,
                grossMass: Number(event.target.value),
              }))
            }
          }}
        />
      </FormRow>

      {country === DeclarationCountry.POLAND && (
        <>
          <FormRow title={t('declaration.specialConditions')}>
            <Select
              options={specialConditionsOptions}
              className="select"
              classNamePrefix="select"
              defaultValue={declaration.specialCondition
                ? specialConditionsOptions.find((conditions) => conditions.value === declaration.specialCondition)
                : null}
              onChange={(event) => {
                store.dispatch(addDeclaration({
                  ...declaration,
                  specialCondition: event?.value,
                }))
              }}
              isDisabled={accessLevel === AccessLevel.VIEW}
            />
          </FormRow>
          {declaration.specialCondition !== 'A' ? (
            <FormRow title={t('declaration.securityReferenceNumber')} isLast>
              <input
                disabled={accessLevel === AccessLevel.VIEW}
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={declaration.securityReferenceNumber ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isValuePrecisionValid(event.target.value)) {
                    if ((`${event.target.value}`).length > 35) {
                      return
                    }
                    store.dispatch(addDeclaration({
                      ...declaration,
                      securityReferenceNumber: event.target.value,
                    }))
                  }
                }}
              />
            </FormRow>
          ) : (
            <FormRow title={t('declaration.securityReferenceNumber')}>
              <input
                disabled={accessLevel === AccessLevel.VIEW}
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={declaration.securityReferenceNumber ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isValuePrecisionValid(event.target.value)) {
                    if ((`${event.target.value}`).length > 35) {
                      return
                    }
                    store.dispatch(addDeclaration({
                      ...declaration,
                      securityReferenceNumber: event.target.value,
                    }))
                  }
                }}
              />
            </FormRow>
          ) }
          {declaration.specialCondition === 'A' && (
            <FormRow title={t('declaration.shipmentNumber')} isLast>
              <input
                disabled={accessLevel === AccessLevel.VIEW}
                className="form-control"
                type="text"
                placeholder={t('common.fieldPlaceholder')}
                value={declaration.shipmentNumber ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isValuePrecisionValid(event.target.value)) {
                    if ((`${event.target.value}`).length > 35) {
                      return
                    }
                    store.dispatch(addDeclaration({
                      ...declaration,
                      shipmentNumber: event.target.value,
                    }))
                  }
                }}
              />
            </FormRow>
          )}
        </>
      )}

      <FormRow title={t('declaration.dispatchCountry')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          menuPlacement="auto"
          options={countriesOptions}
          filterOption={getSelectCountryFilter}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_COUNTRY_OF_DISPATCH_EXPORT_CODE], invalidFields).isInvalid || (declarationInfo.dispatchCountryCode === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          placeholder={t('common.chooseCountry')}
          value={getSelectValue(declarationInfo.dispatchCountryCode, countriesOptions)}
          onChange={(option: ISelectOption | null) => {
            if (option && option.value) {
              store.dispatch(addDeclarationInfo({
                ...declarationInfo,
                dispatchCountryCode: option.value,
              }))
            }
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.destinationCountry')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          menuPlacement="auto"
          options={countriesOptions}
          filterOption={getSelectCountryFilter}
          className={`select ${
            getInvalidIconData([TransitField.HEADER_COUNTRY_OF_DESTINATION_CODE], invalidFields).isInvalid || (declarationInfo.destinationCountryCode === null) ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          placeholder={t('common.chooseCountry')}
          value={getSelectValue(declarationInfo.destinationCountryCode, countriesOptions)}
          onChange={(option: ISelectOption | null) => {
            if (option && option.value) {
              store.dispatch(addDeclarationInfo({
                ...declarationInfo,
                destinationCountryCode: option.value,
              }))
            }
          }}
        />
      </FormRow>
      <FormRow title={t('declaration.transitCountries')}>
        <div className="d-flex">
          <div className="w-50">
            {
              transitCountriesRows()
            }
          </div>
          <div className="d-flex align-item-center">
            {
              transitCountries.length > 1
              && (
                <button
                  disabled={accessLevel === AccessLevel.VIEW}
                  type="button"
                  className="btn btn-light btn-round ms-1 mb-1"
                  onClick={() => {
                    setTransitCountries([...transitCountries.filter((row) => row.sortOrder !== transitCountries.length - 1)])
                  }}
                >
                  <i className="far fa-minus" />
                </button>
              )
            }
            <div />
            <button
              disabled={accessLevel === AccessLevel.VIEW}
              type="button"
              className="btn btn-light btn-round ms-1 mb-1"
              onClick={() => {
                setTransitCountries([...transitCountries,
                  {
                    id: null,
                    code: null,
                    declarationInfoId: declarationInfo.id,
                    nameEn: null,
                    nameRu: null,
                    sortOrder: transitCountries.length,
                  }])
              }}
            >
              <i className="far fa-plus" />
            </button>
          </div>
        </div>
      </FormRow>
      {country === 'FI' && (
        <>
          <FormRow title={t('declaration.goodsLocationQualifier')}>
            <Select
              className="select option mb-1"
              classNamePrefix="select"
              value={goodsLocationQualifier}
              options={goodsLocationQualifierOptions}
              onChange={(options: ISelectOption | null) => {
                const option = goodsLocationQualifierOptions.find((item) => item.value === options?.value)
                if (option) {
                  setGoodsLocationQualifier(option)
                  if (options?.value === 'L' && declarationInfo.goodsLocationQualifier !== 'L') {
                    store.dispatch(addDeclarationInfo({
                      ...declarationInfo,
                      goodsLocation: null,
                    }))
                  }

                  store.dispatch(addDeclarationInfo({
                    ...declarationInfo,
                    goodsLocationQualifier: options?.value,
                    goodsLocation: declarationInfo.goodsLocationQualifier === 'L' && options?.value !== 'L'
                      ? null : declarationInfo.goodsLocation,
                  }))
                  if (declarationInfo.goodsLocationQualifier === 'L' && options?.value !== 'L') {
                    setGoodsLocation(null)
                  }
                }
              }}
            />
          </FormRow>
          {goodsLocationQualifier !== null && goodsLocation?.value !== null && goodsLocationQualifier.value !== 'L' && (
            <FormRow title={t('declaration.goodsLocation')} isLast>
              <Select
                className="select option mb-1"
                classNamePrefix="select"
                value={goodsLocation}
                options={customsOfficesOptions}
                onChange={(options: ISelectOption | null) => {
                  setGoodsLocation(getSelectValue(options?.value, customsOfficesOptions)!)
                  store.dispatch(addDeclarationInfo({
                    ...declarationInfo,
                    goodsLocation: options?.value,
                  }))
                }}
              />
            </FormRow>
          )}
          {goodsLocationQualifier !== null && goodsLocation?.value !== null && goodsLocationQualifier.value === 'L' && (
            <FormRow
              title={t('declaration.warehouseLocation')}
              hasInfoButton={goodsLocationQualifier.value === 'L'}
              tooltipContent={(
                <small>
                  {t('tooltips.warehouse')}
                </small>
              )}
            >
              <input
                className="form-control"
                type="text"
                onChange={(event) => {
                  store.dispatch(addDeclarationInfo({
                    ...declarationInfo,
                    goodsLocation: event.target.value,
                  }))
                  setGoodsLocation({ value: event.target.value, label: event.target.value })
                }}
                value={declarationInfo.goodsLocation ?? ''}
              />
            </FormRow>
          )}
        </>
      )}
      {goodsLocationQualifier !== null && goodsLocationQualifier.value === 'L' && user?.role === 'ADMIN' && (
        <FormRow
          title={t('declaration.transitDateLimit')}
          hasInfoButton
          tooltipContent={(
            <small>
              {t('tooltips.simplified')}
            </small>
          )}
        >
          <input
            type="date"
            name="dateLimit"
            className="form-control"
            disabled={user?.role !== UserRole.ADMIN || accessLevel !== AccessLevel.EDIT || country !== 'FI'}
            onChange={(event) => {
              store.dispatch(addDeclarationInfo({
                ...declarationInfo,
                transitDateLimit: format(new Date(event.target.value), 'yyyy-MM-dd\'T\'HH:mm:ss'),
              }))
            }}
            value={declarationInfo.transitDateLimit ? format(new Date(declarationInfo.transitDateLimit ?? new Date()), 'yyyy-MM-dd') : ''}
          />
        </FormRow>
      )}
      {country === 'FI' && (
        <FormRow
          title={t('declaration.loadingTime')}
          hasInfoButton
          tooltipContent={(
            <small>
              {t('tooltips.loading')}
            </small>
          )}
          isLast
        >
          <input
            type="datetime-local"
            name="loadingDate"
            className="form-control"
            disabled={accessLevel !== AccessLevel.EDIT || country !== 'FI'}
            onChange={(event) => {
              store.dispatch(addDeclarationInfo({
                ...declarationInfo,
                transitLoadingDate: format(new Date(event.target.value), 'yyyy-MM-dd\'T\'HH:mm:ss'),
              }))
            }}
            value={declarationInfo.transitLoadingDate ? format(new Date(declarationInfo.transitLoadingDate), 'yyyy-MM-dd\'T\'HH:mm') : ''}
          />
        </FormRow>
      )}

      <div className="input-standout flex-column">
        <FormRow title={t('declaration.guaranteeAmount')}>
          <div className="input-group">
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className={`form-control ${
                getInvalidIconData([TransitField.GUARANTEE_GUARANTEE_REFERENCE_GUARANTEE_REFERENCE_NUMBER_GRN,
                  TransitField.GUARANTEE_GUARANTEE_REFERENCE_ACCESS_CODE,
                  TransitField.GUARANTEE_GUARANTEE_REFERENCE_AMOUNT_CONCERNED], invalidFields).isInvalid || guarantee.amount === null || !guarantee.amount ? 'is-invalid' : ''
              }`}
              type="number"
              placeholder={t('common.fieldPlaceholder')}
              max={999999999999999}
              value={guarantee.amount !== null ? guarantee.amount : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (isValuePrecisionValid(event.target.value)) {
                  if ((`${event.target.value}`).length > 15) {
                    return
                  }
                  store.dispatch(addGuarantee({
                    ...guarantee,
                    amount: Number(event.target.value) ?? null,
                  }))
                }
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {t('goodsItem.goodsItemPrice', { context: 'unit' })}
              </span>
            </div>
          </div>

          <div className="mt-3">
            {/* {
              country === 'EE' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.eeGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50 w-100 pointer mb-2"
                >
                  {t('declaration.eeGuarantee')}
                </small>
              )
            } */}
            {
              country === 'LV' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.lvGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50 w-100 pointer mb-2"
                >
                  {t('declaration.lvGuarantee')}
                </small>
              )
            }
            {
              country === 'LT' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.ltGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50 w-100 pointer mb-2"
                >
                  {t('declaration.ltGuarantee')}
                </small>
              )
            }
            {
              country === 'PL' && (
                <small
                  role="presentation"
                  onClick={() => window.open(ROUTES.external.plGuarantee, '_blank', 'noopener,noreferrer')}
                  className="text-black-50 pointer"
                >
                  {t('declaration.plGuarantee')}
                </small>
              )
            }
            {
              country === 'RO' && (
                <button
                  type="button"
                  className="btn btn-light btn-outline-gray ms-1 mb-1 text-black-50"
                  onClick={() => openCalculation()}
                >
                  <span>{t('guaranteeCalculator.pdf')}</span>
                </button>
              )
            }
          </div>
        </FormRow>
      </div>
    </div>
  )
}

export default DeclarationInfoColumn

