import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'

const B1891Validator = {
  validateDepartureTransportMeansRequirement: (
    containerIndicator: string | null,
    inlandTransportMode: string,
  ): RuleResult => {
    if (removePrefix(inlandTransportMode, 'TRANSPORT_MODE_', 1) === '5') {
      return 'NOT_ALLOWED'
    }

    if (containerIndicator && containerIndicator === 'true') {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default B1891Validator
