import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { CodelistGroup } from 'routes/phase5/common/hooks/useCodelist/helper'
import { CodelistResponse } from 'routes/phase5/common/models/codelist-response'
import TransitApiConfig from 'routes/phase5/TransitOperationEditor/hooks/apiConfig'

const prefetchedGroups: CodelistGroup[] = [
  'COUNTRIES',
  'TRANSPORT_MODES',
  'TRANSPORT_IDENTIFICATIONS',
  'PREVIOUS_DOCUMENTS',
  'SUPPORTING_DOCUMENTS',
  'TRANSPORT_DOCUMENTS',
  'PREVIOUS_DOCUMENT_EXPORTS',
  'PACKAGES',
  'UNITS',
  'AUTHORISATIONS',
  'ADDITIONAL_INFORMATION',
  'ADDITIONAL_REFERENCES',
  'PAYMENT_METHODS',
  'CUSTOMS_OFFICES',
  'TRANSIT_CUSTOMS_OFFICES',
  'TRANSIT_EXIT_CUSTOMS_OFFICES',
  'DEPARTURE_CUSTOMS_OFFICES',
  'DESTINATION_CUSTOMS_OFFICES',
  'QUALIFIER_OF_IDENTIFICATION',
]

const {
  baseUrl,
  queryKeys: { getCodelistGroup },
} = TransitApiConfig.paths.codelist

function usePrefetchCodelist() {
  const queryClient = useQueryClient()

  const prefetchCodelists = async () => {
    const promises: Promise<void>[] = []

    for (const item of prefetchedGroups) {
      promises.push(
        queryClient.prefetchQuery(
          getCodelistGroup(item),
          async () => (await axios.get<CodelistResponse[]>(`${baseUrl}/group/${item}`)).data,
        ),
      )
    }

    await Promise.all(promises)
  }

  return {
    prefetchCodelists,
  }
}

export default usePrefetchCodelist
