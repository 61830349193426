import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import TransitApiConfig from '../apiConfig'
import { ConsignmentResponse, CreateOrUpdateConsignmentRequest } from '../../../common/models'
import { MutateRequest } from '../request-type'

const {
  baseUrl,
  queryKeys: { getConsignmentByTransitOperationId },
} = TransitApiConfig.paths.consignment

function useConsignmentApi(transitOperationId: number | null, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const postConsignment = useMutation({
    mutationFn: async (data: CreateOrUpdateConsignmentRequest) => (await axios.post<ConsignmentResponse>(baseUrl, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getConsignmentByTransitOperationId(transitOperationId) }),
  })

  const doPutConsignment = useMutation({
    mutationFn: async ({ id, data }: MutateRequest<CreateOrUpdateConsignmentRequest>) => (await axios
      .put<ConsignmentResponse>(`${baseUrl}/${id}`, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getConsignmentByTransitOperationId(transitOperationId) }),
  })

  const doPutConsignmentDraft = useMutation({
    mutationFn: async ({ id, data }: MutateRequest<CreateOrUpdateConsignmentRequest>) => (await axios
      .put<ConsignmentResponse>(`${baseUrl}/${id}/draft`, data)).data,
    onSuccess: () => queryClient
      .invalidateQueries({ queryKey: getConsignmentByTransitOperationId(transitOperationId) }),
  })

  const fetchConsignment = useQuery({
    queryKey: getConsignmentByTransitOperationId(transitOperationId),
    queryFn: async ({ signal }) => (await axios
      .get<ConsignmentResponse>(`${baseUrl}/${transitOperationId}`, { signal })).data,
    enabled: !!transitOperationId && !doPutConsignment.isLoading && !doPutConsignmentDraft.isLoading
      && !postConsignment.isLoading && !isSubmitting,
    staleTime: Infinity, // never stale
  })

  const putConsignment = (isDraft: boolean) => {
    if (isDraft) {
      return doPutConsignmentDraft
    }
    return doPutConsignment
  }

  return {
    fetchConsignment,
    postConsignment,
    putConsignment,
  }
}

export default useConsignmentApi
