import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabType } from '../index'
import SeoActionHeader from './SeoActionHeader'
import SeoTagsEditor from './SeoTagsEditor'
import useSeoTags from './hooks/useSeoTags'
import ConfirmationModal from '../ConfirmationModal'

interface SeoTabProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly tabType: TabType;
}

function SeoTab({
  loading,
  setLoading,
}: SeoTabProps) {
  const { t } = useTranslation()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const useSeoTagsState = useSeoTags(setIsConfirmationModalOpen, loading, setLoading)

  return (
    <>
      <SeoActionHeader loading={loading} setIsConfirmationModalOpen={setIsConfirmationModalOpen} />
      <SeoTagsEditor
        loading={loading}
        useSeoTagsState={useSeoTagsState}
      />
      <ConfirmationModal
        loading={loading}
        title={t('common.confirmAction')}
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        submissionConfirmed={useSeoTagsState.updateSeoTags}
        modalBody={(<p>{t('messages.confirmSaving')}</p>)}
      />
    </>
  )
}

export default SeoTab
