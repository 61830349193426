import useTransitOperationInfoApi from './api'
import {
  toFeedbackRequest,
  toNotesOrFeedbackTransitOperationInfoRequest,
  toUpdateReviewRequest,
} from './mapper'

export default function useTransitOperationInfo(transitOperationId: number | null) {
  const {
    postTransitOperationInfo,
    patchTransitOperationInfoNotesOrFeedback,
    patchTransitOperationInfoReviewStatus,
    getTransitOperationInfo,
    patchFeedback,
    patchCompanyTransitOperationInfoNotesOrFeedback,
  } = useTransitOperationInfoApi(transitOperationId)

  const createTransitOperationInfo = async (id: number) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    return postTransitOperationInfo.mutateAsync({
      transitOperationId: id,
    })
  }

  const createOrUpdateNotesOrFeedback = async (id: number, notes: string) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    return patchTransitOperationInfoNotesOrFeedback.mutateAsync({
      id,
      data: toNotesOrFeedbackTransitOperationInfoRequest(notes),
    })
  }

  const createOrUpdateCompanyNotes = async (id: number, notes: string) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    return patchCompanyTransitOperationInfoNotesOrFeedback.mutateAsync({
      id,
      data: toNotesOrFeedbackTransitOperationInfoRequest(notes),
    })
  }

  const sendUserFeedback = async (
    id: number,
    feedback: string,
    isDeclarationDeclined: boolean,
    isPdfIncluded: boolean,
    emails: Array<string>,
    blindCopyEmail: string,
  ) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    return patchFeedback.mutateAsync({
      id,
      data: toFeedbackRequest(feedback, isDeclarationDeclined, isPdfIncluded, emails, blindCopyEmail),
    })
  }

  const updateReviewStatus = async (id: number, isReviewed: boolean) => {
    if (id === null || id === undefined) throw new Error('Transit Operation ID cannot be null or undefined!')

    return patchTransitOperationInfoReviewStatus.mutateAsync({
      id,
      data: toUpdateReviewRequest(isReviewed),
    })
  }

  return {
    getTransitOperationInfo,
    createTransitOperationInfo,
    createOrUpdateNotesOrFeedback,
    updateReviewStatus,
    sendUserFeedback,
    createOrUpdateCompanyNotes,
  }
}
