import React from 'react'
import GeneralInformation from './GeneralInformation'
import Warnings from './Warnings'
import NctsErrors from './NctsErrors'

function DeclarationSummaryTab() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <GeneralInformation />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <NctsErrors />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Warnings />
        </div>
      </div>
    </div>
  )
}

export default DeclarationSummaryTab
