import React, { PropsWithChildren } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { hasText } from '../../utils/common-util'

export interface TransitSidebarArrayFieldProps {
  label: string
  items: {
    value: string | null | undefined
    parenthesesValue?: string | null | undefined
  }[]
  isLoading?: boolean
}

function TransitSidebarArrayField(props: PropsWithChildren<TransitSidebarArrayFieldProps>) {
  const {
    label,
    items,
    isLoading,
  } = props

  return (
    <div className="row mb-2">
      <div className="col-5 d-flex text-muted input-label pt-0">
        {label}
      </div>
      {
        isLoading && (
          <div className="col-7 ">
            <LoadingSpinner />
          </div>
        )
      }
      {
        !isLoading && (
          <div className="col-7 ">
            {
              items.map(({
                value,
                parenthesesValue,
              }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="d-flex" key={`${label}_${index}_${value}`}>
                  <small>{value}</small>
                  {hasText(parenthesesValue) ? (
                    <span className="text-muted ms-1">
                      (
                      {parenthesesValue}
                      )
                    </span>
                  ) : null}
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  )
}

TransitSidebarArrayField.defaultProps = {
  isLoading: false,
}

export default TransitSidebarArrayField
