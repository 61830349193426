import axios from 'axios'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { CurrentCustomer } from '../../../types/Customer'

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
}

export interface UserListResponse {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  status: UserStatus;
  created: string;
  deleted: string;
  lastLogin: string;
  remoteStaffCode?: string;
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  phone: string;
  customerName: string;
}

export interface TopBarUser {
  id: number;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  customerName: string;
  customerId: number;
  customerEmail: string;
}

export interface UserRequirements {
  requiresPrivacyConfirmation: boolean;
  hasProfile: boolean;
}

function postUser(user: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  customerId: number;
  remoteStaffCode?: string;
}) {
  const requestBody = {
    firstname: user.firstname ? user.firstname.trim() : null,
    lastname: user.lastname ? user.lastname.trim() : null,
    email: user.email ? user.email.trim() : null,
    phone: user.phone ? user.phone.trim() : null,
    customerId: user.customerId,
  }
  return axios
    .post(apiService.getFullApiUrl('/user'), {
      ...requestBody,
    })
    .then<User>(handleResponseData)
    .catch(handleApiException)
}

function updateUser(user: {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  customerId: number;
  remoteStaffCode?: string;
}) {
  const requestBody = {
    id: user.id,
    firstname: user.firstname ? user.firstname.trim() : null,
    lastname: user.lastname ? user.lastname.trim() : null,
    email: user.email ? user.email.trim() : null,
    phone: user.phone ? user.phone.trim() : null,
    remoteStaffCode: user.remoteStaffCode,
    customerId: user.customerId,
  }
  return axios
    .put(apiService.getFullApiUrl('/user'), {
      ...requestBody,
    })
    .then<User>(handleResponseData)
    .catch(handleApiException)
}

function postNewUserForCustomer(user: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}) {
  const requestBody = {
    firstname: user.firstname ? user.firstname.trim() : null,
    lastname: user.lastname ? user.lastname.trim() : null,
    email: user.email ? user.email.trim() : null,
    phone: user.phone ? user.phone.trim() : null,
  }
  return axios
    .post(apiService.getFullApiUrl('/user/company-user'), {
      ...requestBody,
    })
    .then<User>(handleResponseData)
    .catch(handleApiException)
}

function activateUser(data: {
  password: string;
  activationToken: string;
}) {
  return axios
    .post(apiService.getFullApiUrl('/user/activate'), {
      ...data,
    })
    .then<User>(handleResponseData)
    .catch(handleApiException)
}

function activatePublicUser(data: {
  activationToken: string;
}) {
  return axios
    .post(apiService.getFullApiUrl('/user/activate/public'), {
      ...data,
    })
    .then<User>(handleResponseData)
    .catch(handleApiException)
}

function sendForgotPasswordEmail({ email }: { email: string }) {
  return axios
    .post(
      apiService.getFullApiUrl('/user/forgot-password'),
      { email },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function sendConfirmationEmail({ email }: { email: string }) {
  return axios
    .post(
      apiService.getFullApiUrl('/user/confirmation-email'),
      { email },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function getAllUsersForCustomer(customerId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/customer-user/${customerId}`))
    .then<Array<UserListResponse>>(handleResponseData)
    .catch(handleApiException)
}

function getAllUsersForCurrentCustomer() {
  return axios
    .get(apiService.getFullApiUrl('/customer-user/current/users'))
    .then<Array<UserListResponse>>(handleResponseData)
    .catch(handleApiException)
}

function getCurrentUserTopbarInfo() {
  return axios
    .get(apiService.getFullApiUrl('/customer-user/current'))
    .then<TopBarUser>(handleResponseData)
    .catch(handleApiException)
}

function getCurrentCustomer() {
  return axios
    .get(apiService.getFullApiUrl('/customer-user/current/admin'))
    .then<CurrentCustomer>(handleResponseData)
    .catch(handleApiException)
}

export interface ChangeUserPasswordRequest {
  currentPassword: string,
  newPassword: string
}

export interface ChangeUserDetails {
  firstname: string,
  lastname: string,
  phone: string,
}

function changeUserPassword(request: ChangeUserPasswordRequest) {
  return axios
    .put(
      apiService.getFullApiUrl('/user/change-password'),
      { ...request },
    )
}

function changeUserDetails(request: ChangeUserDetails) {
  return axios
    .patch(
      apiService.getFullApiUrl('/user/current'),
      { ...request },
    )
    .catch(handleApiException)
}

function patchUserStatus(statusRequest: { userId: number, status: UserStatus }) {
  return axios
    .patch(
      apiService.getFullApiUrl('/user'),
      { ...statusRequest },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

function deleteUser(userId: number) {
  return axios
    .delete(apiService.getFullApiUrl(`/user/${userId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function registerAccount(
  user: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
    registryCode: string;
    companyName: string;
    companyEmail: string;
    zipCode: string;
    country: string;
    street: string;
    city: string;
    hasAcceptedPrivacyPolicy: boolean;
  },
) {
  const requestBody = {
    firstname: user.firstname ? user.firstname.trim() : null,
    lastname: user.lastname ? user.lastname.trim() : null,
    email: user.email ? user.email.trim() : null,
    phone: user.phone ? user.phone.trim() : null,
    password: user.password ? user.password.trim() : null,
    confirmPassword: user.confirmPassword ? user.confirmPassword.trim() : null,
    registryCode: user.registryCode ? user.registryCode.trim() : null,
    companyEmail: user.companyEmail ? user.companyEmail.trim() : null,
    companyName: user.companyName ? user.companyName.trim() : null,
    zipCode: user.zipCode ? user.zipCode.trim() : null,
    country: user.country ? user.country.trim() : null,
    street: user.street ? user.street.trim() : null,
    city: user.city ? user.city.trim() : null,
    hasAcceptedPrivacyPolicy: user.hasAcceptedPrivacyPolicy,
  }
  return axios
    .post(apiService.getFullApiUrl('/user/public'), {
      ...requestBody,
    })
    .then<User>(handleResponseData)
    .catch(handleApiException)
}

function getUserRequirements() {
  return axios
    .get(apiService.getFullApiUrl('/user/requirements'))
    .then<UserRequirements>(handleResponseData)
    .catch(handleApiException)
}

const UserService = {
  getAllUsersForCustomer,
  postUser,
  activateUser,
  sendForgotPasswordEmail,
  patchUserStatus,
  getCurrentUserTopbarInfo,
  changeUserPassword,
  registerAccount,
  activatePublicUser,
  postNewUserForCustomer,
  deleteUser,
  changeUserDetails,
  sendConfirmationEmail,
  getAllUsersForCurrentCustomer,
  getCurrentCustomer,
  getUserRequirements,
  updateUser,
}

export default UserService
