import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { hasText } from '../../../../common/utils/common-util'
import { HouseConsignmentType } from '../../schemas/houseConsignmentSchema'
import { RuleResult } from '../../../../../../types/DeclarationP5'

const REQUIRED_DOCUMENT_TYPES: Set<string> = new Set(['PREVIOUS_DOCUMENT_TYPE_N355", "PREVIOUS_DOCUMENT_TYPE_N337'])
const RP01Validator = {
  validatePreviousDocumentRequirementForHouseAndItem: (
    houseConsignmentModels: HouseConsignmentType[],
  ): RuleResult => (houseConsignmentModels
    .flatMap((houseConsignmentModel) => houseConsignmentModel.consignmentItem)
    .every((consignmentItemModel) => consignmentItemModel.previousDocument.every(
      (previousDocumentModel) => !REQUIRED_DOCUMENT_TYPES.has(previousDocumentModel.documentType)
              && !hasText(previousDocumentModel.referenceNumber),
    )) ? 'REQUIRED' : 'OPTIONAL'),

  validatePreviousDocumentForRoot: (form :DeclarationForm): boolean => form.previousDocument.every(
    (previousDocumentModel) => !REQUIRED_DOCUMENT_TYPES.has(previousDocumentModel.documentType)
      && !hasText(previousDocumentModel.referenceNumber),
  ),
}

export default RP01Validator
