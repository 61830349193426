import { CustomClassifier } from 'routes/TableFilter'
import { UserPreference } from 'types/UserPreference'
import { UserPreferenceUpdateRequest } from '../../../common/models/user-preference-update-request'
import { UserPreferenceResponse } from '../../../common/models/user-preference-response'

export function toUserPreferencesUpdateRequest(
  preference: CustomClassifier,
): UserPreferenceUpdateRequest {
  return {
    sortOrder: preference.sortOrder,
    value: preference.codeLabel,
    deleted: preference.deleted,
  }
}

export function toUserPreferences(
  preference: UserPreferenceResponse,
): UserPreference {
  return {
    sortOrder: preference.sortOrder,
    value: preference.value!,
    deleted: preference.deleted!,
  }
}
