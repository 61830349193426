import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { IDeclarationInfo } from '../../../types/IDeclaration'
import { apiService, handleResponseData } from '../../../services/api.service'

function postNewDeclarationInfo(declarationId: number, declarationInfo: IDeclarationInfo, isDraft: boolean): Promise<IDeclarationInfo> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl(!isDraft ? '/declaration-info' : '/declaration-info/draft'),
      {
        declarationId,
        place: declarationInfo.place,
        packagesCount: declarationInfo.packagesCount,
        grossMass: declarationInfo.grossMass,
        dispatchCountryCode: declarationInfo.dispatchCountryCode,
        destinationCountryCode: declarationInfo.destinationCountryCode,
        transitCountries: declarationInfo.transitCountries,
        goodsLocation: declarationInfo.goodsLocation,
        goodsLocationQualifier: declarationInfo.goodsLocationQualifier,
        transitDateLimit: declarationInfo.transitDateLimit,
        transitLoadingDate: declarationInfo.transitLoadingDate
          ? new Date(declarationInfo.transitLoadingDate).toISOString() : declarationInfo.transitLoadingDate,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while saving declaration info column')
      reject(error)
    })
  })
}

function putDeclarationInfo(declarationId: number, declarationInfo: IDeclarationInfo, isDraft: boolean): Promise<IDeclarationInfo> {
  return new Promise((resolve, reject) => {
    axios.put(
      apiService.getFullApiUrl(!isDraft ? `/declaration-info/${declarationInfo.id}`
        : `/declaration-info/${declarationInfo.id}/draft`),
      {
        declarationId,
        place: declarationInfo.place,
        packagesCount: declarationInfo.packagesCount,
        grossMass: declarationInfo.grossMass,
        dispatchCountryCode: declarationInfo.dispatchCountryCode,
        destinationCountryCode: declarationInfo.destinationCountryCode,
        transitCountries: declarationInfo.transitCountries,
        goodsLocation: declarationInfo.goodsLocation,
        goodsLocationQualifier: declarationInfo.goodsLocationQualifier,
        transitDateLimit: declarationInfo.transitDateLimit,
        transitLoadingDate: declarationInfo.transitLoadingDate
          ? new Date(declarationInfo.transitLoadingDate).toISOString() : declarationInfo.transitLoadingDate,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while saving declaration info column')
      reject(error)
    })
  })
}

const DeclarationInfoService = {
  postNewDeclarationInfo,
  putDeclarationInfo,
}

export default DeclarationInfoService
