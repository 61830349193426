import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { getBaseApiUrl } from 'services/api.service'
import DeclarationApi from '../apiConfig'
import {
  DocumentRequest, DocumentRequestTypeEnum, DocumentResponse, FileResponse,
} from '../../../common/models'

const {
  baseUrl,
} = DeclarationApi.paths.document

function useNotes(transitOperationId: number | null, documentType: DocumentRequestTypeEnum) {
  const postDocument = useMutation({
    mutationFn: async (data: DocumentRequest[]) => (await axios.post<DocumentResponse[]>(baseUrl, data)).data,
  })

  const putDocument = useMutation({
    mutationFn: async (data : DocumentRequest[]) => (await axios.put<DocumentResponse[]>(`${baseUrl}`, data)).data,
  })

  const postFiles = useMutation({
    mutationFn: async (data: FormData) => (await axios.post<FileResponse>(getBaseApiUrl('/v2/document-file'), data)).data,
  })

  const getDocuments = useMutation({
    mutationFn: async () => (await axios
      .get<DocumentResponse[]>(
      `${baseUrl}/${transitOperationId}`,
      {
        params: { documentType },
      },
    )).data,
  })

  const deleteFile = useMutation({
    mutationFn: async (props: { folder: string, uuid: string }) => (
      await axios.delete<void>(`${getBaseApiUrl(`/v2/document-file/${props.folder}/${props.uuid}`)}`)).data,
  })

  return {
    postDocument,
    putDocument,
    postFiles,
    getDocuments,
    deleteFile,
  }
}

export default useNotes
