import React, { useContext, useMemo, useState } from 'react'
import { FormScope } from 'types/DeclarationP5'
import { TransitOperationContext } from '../hooks/useTransitOperationContext'

interface ColumnProps {
  columnId: string;
  headingLabel: string;
  scope?: FormScope;
  initialIsSectionOpen?: boolean;
}

function CollapsibleColumn({
  columnId, headingLabel, children, scope, initialIsSectionOpen,
} : React.PropsWithChildren<ColumnProps>) {
  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(!!initialIsSectionOpen)
  const { fieldRules } = useContext(TransitOperationContext)
  const rule = useMemo(() => fieldRules.find((fieldRule) => fieldRule.path === scope)?.ruleResult ?? 'OPTIONAL', [fieldRules])

  return (
    <>
      <div
        className={
          `declaration__column-heading d-flex justify-content-between ${isSectionOpen ? 'mb-2' : 'mb-0'}`
        }
        data-bs-toggle="collapse"
        data-bs-target={`#${columnId}`}
        aria-expanded={isSectionOpen}
        aria-controls={columnId}
        role="button"
        tabIndex={isSectionOpen ? -1 : 0}
        onClick={() => setIsSectionOpen(!isSectionOpen)}
        onKeyDown={() => setIsSectionOpen(!isSectionOpen)}
      >
        <h3
          className={
            `${rule === 'REQUIRED' ? 'required' : ''} ${rule === 'NOT_ALLOWED' ? 'text-muted cursor--not-allowed' : ''}`
          }
        >
          {headingLabel}
        </h3>
        <i className={`me-2 fa ${isSectionOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </div>
      <div className={`collapse ${isSectionOpen ? 'show' : ''}`} id={columnId}>
        {children}
      </div>
    </>
  )
}
CollapsibleColumn.defaultProps = {
  scope: undefined,
  initialIsSectionOpen: true,
}

export default CollapsibleColumn
