import { useStore } from 'react-redux'
import { ITransport } from '../../../../types/IDeclaration'
import { IStoreState } from '../../../../redux/store'
import { ISeal } from '../../Seal/types/Seal'
import SealService from '../../Seal/seal.service'
import { addSeal } from '../../../../redux/actions'

function useCreateOrEditSeal() {
  const store = useStore()
  const createOrEditSeal = (result: ITransport, state: IStoreState) => {
    if (state.sealReducer.seal.length === 0) {
      return
    }
    if (result.id === null) {
      return
    }
    const transportId: number = result.id
    const sealArray: Array<ISeal> = []
    const sealPromises: Promise<void | ISeal>[] = []

    state.sealReducer.seal.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.transportId = transportId

      if (item.deleted) {
        if (item.id === null) {
          return
        }
        sealPromises.push(SealService.deleteSeal(transportId, item)
          .then(() => {
            if (!item.deleted) {
              sealArray.push(item)
            }
          }))
      } else if (item.id === null && item.identity !== null && item.identity !== '') {
        sealPromises.push(SealService.postSeal(transportId, item)
          .then(() => {
            sealArray.push({
              ...item,
              id: transportId,
            })
          }))
      } else if (item.id !== null && item.identity !== null && item.identity !== '') {
        sealPromises.push(SealService.updateSeal(transportId, item)
          .then(() => {
            sealArray.push({
              ...item,
              id: transportId,
            })
          }))
      }
    })
    Promise.all(sealPromises)
      .then(() => {
        store.dispatch(addSeal(sealArray))
      })
  }

  return { createOrEditSeal }
}

export default useCreateOrEditSeal
