import React from 'react'
import DeclarationTabLayout from '../../layout/DeclarationTabLayout'
import Consignor from './Consignor'
import Consignee from './Consignee'
import Carrier from './Carrier'
import AdditionalSupplyChainActors from './AdditionalSupplyChainActors'

function DeclarationTransitPartiesTab() {
  return (
    <DeclarationTabLayout
      start={(
        <Consignor scope="consignor" />
      )}
      centerLeft={(
        <Consignee scope="consignee" />
      )}
      centerRight={(
        <Carrier />
      )}
      end={(
        <AdditionalSupplyChainActors scope="additionalSupplyChainActor" />
      )}
    />
  )
}

export default DeclarationTransitPartiesTab
