export interface IClassifier {
  classifierGroup: string;
  code: string;
  codeLabel: string;
  nameEn: string | null;
  nameRu: string | null;
  type: string | null;
  attributes: Array<ClassifierKeyValueAttribute>;
  isArchived?: boolean;
}

export interface ClassifierKeyValueAttribute {
  key: string;
  value: string;
}

export interface ISelectOption {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  value: string | any;
  label: string;
}

export enum OfficeRole {
  OFFICE_ROLE_CAU = 'OFFICE_ROLE_CAU', // Competent Authority Country of Dep
  OFFICE_ROLE_DEP = 'OFFICE_ROLE_DEP', // Office of Departure
  OFFICE_ROLE_DES = 'OFFICE_ROLE_DES', // Office of Destination
  OFFICE_ROLE_ENQ = 'OFFICE_ROLE_ENQ', // Competent Authority of Enquiry
  OFFICE_ROLE_GUA = 'OFFICE_ROLE_GUA', // Office of Guarantee
  OFFICE_ROLE_INC = 'OFFICE_ROLE_INC', // Office of Incident Registration
  OFFICE_ROLE_REC = 'OFFICE_ROLE_REC', // Competent Authority of Recovery
  OFFICE_ROLE_TRA = 'OFFICE_ROLE_TRA', // Office of Transit
  OFFICE_ROLE_TXT = 'OFFICE_ROLE_TXT', // Office of Exit for Transit
  OFFICE_ROLE_AUT = 'OFFICE_ROLE_AUT',
  OFFICE_ROLE_CCA = 'OFFICE_ROLE_CCA',
  OFFICE_ROLE_MCA = 'OFFICE_ROLE_MCA',
  OFFICE_ROLE_RCA = 'OFFICE_ROLE_RCA',
  OFFICE_ROLE_REG = 'OFFICE_ROLE_REG',
  OFFICE_ROLE_ACE = 'OFFICE_ROLE_ACE',
  OFFICE_ROLE_ACP = 'OFFICE_ROLE_ACP',
  OFFICE_ROLE_ACR = 'OFFICE_ROLE_ACR',
  OFFICE_ROLE_ACT = 'OFFICE_ROLE_ACT',
  OFFICE_ROLE_AWB = 'OFFICE_ROLE_AWB',
  OFFICE_ROLE_BTI = 'OFFICE_ROLE_BTI',
  OFFICE_ROLE_CCL = 'OFFICE_ROLE_CCL',
  OFFICE_ROLE_CGU = 'OFFICE_ROLE_CGU',
  OFFICE_ROLE_CVA = 'OFFICE_ROLE_CVA',
  OFFICE_ROLE_CWP = 'OFFICE_ROLE_CWP',
  OFFICE_ROLE_DPO = 'OFFICE_ROLE_DPO',
  OFFICE_ROLE_EIR = 'OFFICE_ROLE_EIR',
  OFFICE_ROLE_ETD = 'OFFICE_ROLE_ETD',
  OFFICE_ROLE_EUS = 'OFFICE_ROLE_EUS',
  OFFICE_ROLE_IPO = 'OFFICE_ROLE_IPO',
  OFFICE_ROLE_OPO = 'OFFICE_ROLE_OPO',
  OFFICE_ROLE_RSS = 'OFFICE_ROLE_RSS',
  OFFICE_ROLE_SAS = 'OFFICE_ROLE_SAS',
  OFFICE_ROLE_SDE = 'OFFICE_ROLE_SDE',
  OFFICE_ROLE_SSE = 'OFFICE_ROLE_SSE',
  OFFICE_ROLE_TEA = 'OFFICE_ROLE_TEA',
  OFFICE_ROLE_TRD = 'OFFICE_ROLE_TRD',
  OFFICE_ROLE_TST = 'OFFICE_ROLE_TST',
  OFFICE_ROLE_PRT = 'OFFICE_ROLE_PRT',
  OFFICE_ROLE_RAC = 'OFFICE_ROLE_RAC',
  OFFICE_ROLE_RRG = 'OFFICE_ROLE_RRG',
  OFFICE_ROLE_CCD = 'OFFICE_ROLE_CCD',
  OFFICE_ROLE_CND = 'OFFICE_ROLE_CND',
  OFFICE_ROLE_IPR = 'OFFICE_ROLE_IPR',
  OFFICE_ROLE_EXC = 'OFFICE_ROLE_EXC',
  OFFICE_ROLE_ENL = 'OFFICE_ROLE_ENL',
  OFFICE_ROLE_ENT = 'OFFICE_ROLE_ENT',
  OFFICE_ROLE_PCI = 'OFFICE_ROLE_PCI',
  OFFICE_ROLE_SCI = 'OFFICE_ROLE_SCI',
  OFFICE_ROLE_CAE = 'OFFICE_ROLE_CAE',
  OFFICE_ROLE_EIN = 'OFFICE_ROLE_EIN',
  OFFICE_ROLE_EXL = 'OFFICE_ROLE_EXL',
  OFFICE_ROLE_EXP = 'OFFICE_ROLE_EXP',
  OFFICE_ROLE_EXT = 'OFFICE_ROLE_EXT',
}
