import React, { Dispatch, SetStateAction } from 'react'
import FormRow from 'components/FormRow'
import { useTranslation } from 'react-i18next'
import { Restriction, RestrictionType } from '../../../../types/restriction'
import RestrictionInputs from './RestrictionInputs'

interface FormProps {
  restriction: Restriction
  setRestriction: Dispatch<SetStateAction<Restriction>>
}
function Form(props: FormProps) {
  const { t } = useTranslation()
  const { restriction, setRestriction } = props

  return (
    <div className="new-customer__container">
      <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
        <div className="heading">
          <h3>
            {t('pages.page', { context: 'restrictions' })}
          </h3>
        </div>
        <FormRow title={t('restriction.type')}>
          <div className="form-row-special-input">
            <div className="form-check d-flex align-items-center me-3">
              <input
                className="form-check-input"
                type="radio"
                name="restrictionType"
                id="commodityCodeType"
                value="false"
                checked={restriction.type === RestrictionType.COMMODITY_CODE}
                onChange={() => {
                  setRestriction({
                    ...restriction,
                    key: '',
                    type: RestrictionType.COMMODITY_CODE,
                  })
                }}
              />
              <label className="form-check-label text-nowrap ps-1" htmlFor="commodityCodeType">
                {`${t('restriction.commodityCodeType')}`}
              </label>
            </div>
            <div className="form-check d-flex align-items-center me-3">
              <input
                className="form-check-input"
                type="radio"
                name="restrictionType"
                id="descriptionType"
                value="true"
                checked={restriction.type === RestrictionType.COMMODITY_DESCRIPTION}
                onChange={() => {
                  setRestriction({
                    ...restriction,
                    type: RestrictionType.COMMODITY_DESCRIPTION,
                  })
                }}
              />
              <label className="form-check-label text-nowrap ps-1" htmlFor="descriptionType">
                {`${t('restriction.commodityDescriptionType')}`}
              </label>
            </div>
            <div className="form-check d-flex align-items-center me-3">
              <input
                className="form-check-input"
                type="radio"
                name="restrictionType"
                id="sanctionType"
                value="true"
                checked={restriction.type === RestrictionType.COMMODITY_SANCTION}
                onChange={() => {
                  setRestriction({
                    ...restriction,
                    type: RestrictionType.COMMODITY_SANCTION,
                  })
                }}
              />
              <label className="form-check-label text-nowrap ps-1" htmlFor="sanctionType">
                {`${t('restriction.commoditySanctionType')}`}
              </label>
            </div>
            <div className="form-check d-flex align-items-center me-3">
              <input
                className="form-check-input"
                type="radio"
                name="carrierType"
                id="carrierType"
                value="true"
                checked={restriction.type === RestrictionType.TRADER}
                onChange={() => {
                  setRestriction({
                    ...restriction,
                    type: RestrictionType.TRADER,
                  })
                }}
              />
              <label className="form-check-label text-nowrap ps-1" htmlFor="carrierType">
                {`${t('restriction.commodityTraderType')}`}
              </label>
            </div>
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="truckType"
                id="truckType"
                value="true"
                checked={restriction.type === RestrictionType.TRUCK}
                onChange={() => {
                  setRestriction({
                    ...restriction,
                    type: RestrictionType.TRUCK,
                  })
                }}
              />
              <label className="form-check-label text-nowrap ps-1" htmlFor="truckType">
                {`${t('restriction.commodityTruckType')}`}
              </label>
            </div>
          </div>
        </FormRow>
        <RestrictionInputs restriction={restriction} setRestriction={setRestriction} />
      </div>
    </div>
  )
}

export default Form
