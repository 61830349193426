import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

interface HeaderNotificationMessageEditorProps {
  headerNotificationMessage: string;
  setHeaderNotificationMessage: Dispatch<SetStateAction<string>>;

}

function HeaderNotificationMessageEditor({
  headerNotificationMessage,
  setHeaderNotificationMessage,
}: HeaderNotificationMessageEditorProps) {
  const { t } = useTranslation()

  return (
    <div className="px-4 pt-5 ">
      <textarea
        className="w-100 p-1"
        value={headerNotificationMessage}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setHeaderNotificationMessage(event.target.value)}
        placeholder={t('notification.inputPlaceholder')}
      />
    </div>
  )
}
export default HeaderNotificationMessageEditor
