import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { apiService, handleResponseData } from '../../../services/api.service'
import { DeclarationPageResponse } from '../../../types/DeclarationPageResponse'
import { PageResponse, TableFilter } from '../../../types/PageResponse'

function getDeclarationPage(
  page: number,
  size: number,
  filters: Array<TableFilter>,
  statusFilter: string,
  isAdmin: boolean,
  sortBy: string,
  orderBy: string,
): Promise<PageResponse<DeclarationPageResponse>> {
  return new Promise((resolve, reject) => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filterParam: any = {}
    filterParam.page = page
    filterParam.size = size

    if (statusFilter !== null && statusFilter !== '') {
      filterParam.status = statusFilter
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of filters) {
      if (filter.key === 'modified' || filter.key === 'dateReviewed') {
        if (filter.value.match('(\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d)$') !== null) {
          filterParam[filter.key] = filter.value
        }
      } else if (filter.value !== null && filter.value !== '') {
        filterParam[filter.key] = filter.value
      }
    }

    // mapping to entity specification
    if (sortBy === 'transitDateLimit') {
      // eslint-disable-next-line no-param-reassign
      sortBy = 'declarationInfo.transitDateLimit'
    }

    axios.get(
      apiService.getFullApiUrl(`/declaration${isAdmin ? '/admin' : ''}`),
      {
        params: {
          ...filterParam,
          sortBy,
          orderBy,
        },
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.error('Getting declarations failed')
      reject(error)
    })
  })
}
const DeclarationTableService = {
  getDeclarationPage,
}

export default DeclarationTableService
