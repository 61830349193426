import messages from '../../../messages/error-messages'

export interface IconData {
  isInvalid: boolean,
  reason: string,
  reasonText: string,
}

interface InvalidField {
  pointer: string,
  reason: string
}

export function getRuleReason(reason: string) {
  const matchingError = messages.errors.find((rule) => rule.code === reason)

  return `${matchingError ? matchingError.en : reason}`
}

export function getInvalidIconData(fieldLabels: Array<string> | undefined, invalidFields: Array<InvalidField> | undefined) {
  const data: IconData = {
    isInvalid: false,
    reason: '',
    reasonText: '',
  }
  if (!invalidFields || !fieldLabels) {
    return data
  }
  let invalidField
  // eslint-disable-next-line no-restricted-syntax
  for (const field of fieldLabels) {
    if (invalidField === undefined) {
      invalidField = invalidFields.find((item) => (item.pointer === field))
    } else {
      break
    }
  }
  if (invalidField !== undefined) {
    data.isInvalid = true
    data.reason = invalidField.reason
    const reasonText = getRuleReason(invalidField.reason)
    data.reasonText = reasonText.length > 0 ? reasonText : invalidField.reason
  }
  return data
}
