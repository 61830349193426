import { TFunction } from 'i18next'
import { ISelectOption } from 'types/IClassifier'

export interface Header {
  title: string,
  accessor: string,
  columns?: [],
  width?: number,
  maxWidth?: number,
  minWidth?: number,
  sticky?: 'left' | 'right',
  className? : string,
  disableSortBy: boolean,
  disableGlobalFilter: boolean,
}

const addDefaultFieldsToTable = (columns: Array<Header>, t: TFunction) => {
  columns.push(
    {
      title: t('transit.id'),
      accessor: 'id',
      sticky: 'left',
      maxWidth: 86,
      className: 'fixed-width-sm',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: t('declaration.p5.transitOperation'),
      accessor: 'transitOperationId',
      sticky: 'left',
      width: 215,
      className: 'fixed-width-lg',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: t('transit.status'),
      accessor: 'status',
      className: 'fixed-width-md',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: t('transit.customer'),
      accessor: 'customerName',
      className: 'fixed-width-md',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: '',
      accessor: 'isReviewed',
      className: 'fixed-width-md-sm',
      disableSortBy: true,
      disableGlobalFilter: true,
    },
    {
      title: t('audit.reason'),
      accessor: 'reason',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: 'Audit Reason',
      accessor: 'auditReason',
      disableSortBy: true,
      disableGlobalFilter: true,
    },
    {
      title: t('audit.created'),
      accessor: 'created',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: t('audit.modified'),
      accessor: 'modified',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: t('transit.lastReview'),
      accessor: 'dateReviewed',
      className: 'fixed-width-md-sm',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
    {
      title: t('transit.reviewer'),
      accessor: 'reviewedBy',
      className: 'fixed-width-md-sm',
      disableSortBy: true,
      disableGlobalFilter: false,
    },
  )
}

export default function getTableHeaders(
  t: TFunction,
): Array<Header> {
  const columns: Array<Header> = []

  addDefaultFieldsToTable(columns, t)

  return columns
}

export function getTransitOperationAuditStatusOptions() {
  return [
    { value: 'IN_REVIEW', label: 'In Review' },
    { value: 'ACCEPTED', label: 'Accepted' },
    { value: 'DECLINED', label: 'Declined' },
    { value: 'PROHIBITED_DESCRIPTION', label: 'Prohibited Description' },
  ] as ISelectOption[]
}
