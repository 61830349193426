import React from 'react'
import GoodsTable from './GoodsTable'

function DeclarationGoodsTab() {
  return (
    <GoodsTable />
  )
}

export default DeclarationGoodsTab
