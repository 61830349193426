import { Modal } from 'react-bootstrap'
import React, {
  Dispatch, SetStateAction, useContext,
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import FormRow from 'components/FormRow'
import TextInput from 'components/Form/Input'
import useUserInviteForm from '../../hooks/useUserInviteForm'
import { isNullOrBlank } from '../../../../helpers'
import { UserContext } from '../../../../context/UserContext'

interface UserModalParams {
  isUserModalVisible: boolean
  toggleUserModal: Dispatch<SetStateAction<boolean>>
  customerId: number
}

function UserModal({ isUserModalVisible, toggleUserModal, customerId }: UserModalParams) {
  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    phone,
    setPhone,
    remoteStaffCode,
    setRemoteStaffCode,
    submit,
  } = useUserInviteForm()

  const { user } = useContext(UserContext)

  const { t } = useTranslation()

  const submitInvite = () => {
    if (isNullOrBlank(email) || isNullOrBlank(firstname) || isNullOrBlank(lastname)) {
      toast.error(t('messages.missingRequiredFields'))
      return
    }
    if (customerId === undefined) {
      return
    }

    submit(customerId).then(() => {
      toast.success(t('messages.inviteSent'))
      toggleUserModal(false)
    }).catch((reason) => {
      // eslint-disable-next-line no-console
      console.error(reason)
      toast.error(t('messages.savingFailed'))
    })
  }

  return (
    <Modal
      show={isUserModalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleUserModal(false)}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-capitalize">{t('buttons.addUser')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => toggleUserModal(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormRow title={t('user.firstName')}>
          <TextInput
            name="firstname"
            placeholder={t('common.fieldPlaceholder')}
            value={firstname}
            onChange={(event) => setFirstname(event.target.value)}
          />
        </FormRow>
        <FormRow title={t('user.lastName')}>
          <TextInput
            name="lastname"
            placeholder={t('common.fieldPlaceholder')}
            value={lastname}
            onChange={(event) => setLastname(event.target.value)}
          />
        </FormRow>
        <FormRow title={t('common.email')}>
          <TextInput
            type="email"
            name="email"
            placeholder={t('common.fieldPlaceholder')}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormRow>
        <FormRow title={t('common.phone')}>
          <TextInput
            name="tel"
            placeholder={t('common.fieldPlaceholder')}
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </FormRow>
        {user?.role === 'ADMIN' && (
          <FormRow title={t('directo.remoteStaffCode')}>
            <TextInput
              name="remoteStaffCode"
              placeholder={t('common.fieldPlaceholder')}
              value={remoteStaffCode ?? ''}
              onChange={(event) => setRemoteStaffCode(event.target.value)}
            />
          </FormRow>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          onClick={() => submitInvite()}
        >
          <i className="fal fa-envelope me-2" />
          <span>{t('buttons.sendInvite')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserModal
