import React from 'react'
import { useTranslation } from 'react-i18next'
import TransitSidebarSection from './TransitSidebarSection'

interface TransitSidebarAuditSectionProps {
  auditReason: string
}

function TransitSidebarAuditSection(props: Readonly<TransitSidebarAuditSectionProps>) {
  const { t } = useTranslation()
  const { auditReason } = props

  return (
    <TransitSidebarSection
      columnId="audit"
      headingLabel={t('audit.declinationReason')}
    >
      <div className="col-12">
        <div className="px-2 text-danger">{auditReason}</div>
      </div>
    </TransitSidebarSection>
  )
}

export default TransitSidebarAuditSection
