import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import PrivacyService from 'routes/Settings/service/privacy.service'

interface AcceptanceModalParams {
  isModalOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  privacyTerms: string;
  // eslint-disable-next-line react/require-default-props
  page?: string | null;
}

function AcceptanceModal({
  isModalOpen, toggleModal, privacyTerms, page,
}: AcceptanceModalParams) {
  const { t } = useTranslation()

  const acceptTerms = () => {
    PrivacyService.acceptTerms().then(() => {
      window.location.reload()
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }

  return (
    <Modal
      show={isModalOpen}
      size="xl"
      className="overflow-hidden"
      dialogClassName="preview-modal modal align-modal-top align-modal-left"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleModal}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2
            className="text-capitalize"
          >
            {t('user.privacy')}
          </h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => toggleModal(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="privacy-terms">{parse(privacyTerms)}</div>
        {page !== null && page === 'login' && (
          <button
            type="button"
            onClick={acceptTerms}
            className="btn btn-primary btn-lg mb-3 p-3 w-100 mt-5"
          >
            {t('buttons.accept')}
          </button>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AcceptanceModal
