import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { CL009_COUNTRY_CODES_COMMON_TRANSIT } from '../../../../common/utils/ncts-constants'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { nonNullConsignee, someAdditionalInformationItemHasExsCode } from '../../../../common/utils/common-util'
import { ConsignmentItem } from '../../schemas/consignmentItemSchema'

const B1820Validator = {
  validateConsigneeOnConsignmentItemRequirement: (formData: DeclarationForm, consignmentItem: ConsignmentItem): RuleResult => {
    const countryOfDestination = removePrefix(formData.countryOfDestination, 'COUNTRY_') ?? ''
    if (CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(countryOfDestination)) {
      if (nonNullConsignee(formData.consignee)) {
        return 'NOT_ALLOWED'
      }
      return 'REQUIRED'
    }
    const itemCountryOfDestination = removePrefix(consignmentItem.countryOfDestination, 'COUNTRY_') ?? ''
    if (CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(itemCountryOfDestination)) {
      return 'REQUIRED'
    }
    if (formData.security === '0' || formData.security === '1') {
      if (nonNullConsignee(formData.consignee)) {
        return 'NOT_ALLOWED'
      }
      return 'OPTIONAL'
    }

    if (someAdditionalInformationItemHasExsCode(formData.additionalInformation)) return 'NOT_ALLOWED'
    if (someAdditionalInformationItemHasExsCode(consignmentItem.additionalInformation)) return 'NOT_ALLOWED'

    if (nonNullConsignee(formData.consignee)) {
      return 'NOT_ALLOWED'
    }

    return 'REQUIRED'
  },
} as const

export default B1820Validator
