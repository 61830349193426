import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import NotificationService from '../../../../service/notification.service'

function useEmailNotification(loading: boolean, setLoading: Dispatch<SetStateAction<boolean>>) {
  const { t } = useTranslation()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [editorContent, setEditorContent] = useState<string>('')

  const submitEmailNotification = () => {
    if (loading || editorContent === '') {
      return
    }
    setLoading(true)

    NotificationService.sendEmailNotification(editorContent)
      .then(() => {
        toast.success(t('notification.success'))
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
      .catch(() => setLoading(false))
  }

  return {
    editorContent,
    setEditorContent,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    submitEmailNotification,
  }
}

export default useEmailNotification
