import { z } from 'zod'
import FileSchema from './fileSchemas'

const BaseDocumentSchema = z.object(
  {
    id: z.number().nullable(),
    sequenceNumber: z.number().nonnegative(),
    documentType: z.string(),
    referenceNumber: z.string(),
    complementOfInformation: z.string(),
    deleted: z.boolean().optional(),
  },
)
export type BaseDocument = z.infer<typeof BaseDocumentSchema>
export const PreviousDocumentSchema = BaseDocumentSchema.extend(
  {
    transportDocumentReferenceNumber: z.string(),
    procedureType: z.string().nullable(),
    files: z.array(FileSchema),
  },
) // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .superRefine((arg, ctx) => {})
export type PreviousDocument = z.infer<typeof PreviousDocumentSchema>

export const SupportingDocumentSchema = BaseDocumentSchema.extend(
  {
    documentLineNumber: z.number().nullable(),
    files: z.array(FileSchema),
  },
// eslint-disable-next-line @typescript-eslint/no-unused-vars
).superRefine((arg, ctx) => {})
export type SupportingDocument = z.infer<typeof SupportingDocumentSchema>

export const TransportDocumentSchema = BaseDocumentSchema.omit(
  {
    complementOfInformation: true,
  },
).extend({
  files: z.array(FileSchema),
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  .superRefine((arg, ctx) => {})
export type TransportDocument = z.infer<typeof TransportDocumentSchema>

export const NotesDocumentSchema = BaseDocumentSchema.extend({
  files: z.array(FileSchema),
  transitOperationId: z.number().nullable(),
})
export type NotesDocument = z.infer<typeof NotesDocumentSchema>
