import axios from 'axios'
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query'
import { ConsignmentItemResponse, CreateOrUpdateConsignmentItemRequest } from '../../../common/models'
import { MutateRequest } from '../request-type'
import TransitApiConfig from '../apiConfig'

const {
  baseUrl,
  queryKeys: { getConsignmentItemByHouseConsignmentId },
} = TransitApiConfig.paths.consignmentItem

function useConsignmentItemApi(houseConsignmentIds: Array<number | null>, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const doPostConsignmentItem = useMutation({
    mutationFn: async ({ data }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (
      await axios.post<ConsignmentItemResponse[]>(baseUrl, data)).data,
    onSuccess: (_, variables) => {
      variables.data.map((item) => queryClient
        .invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(item.houseConsignmentId) }))
    },
  })

  const doPostConsignmentItemDraft = useMutation({
    mutationFn: async ({ data }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (
      await axios.post<ConsignmentItemResponse[]>(`${baseUrl}/draft`, data)).data,
    onSuccess: (_, variables) => {
      variables.data.map((item) => queryClient
        .invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(item.houseConsignmentId) }))
    },
  })

  const doPutConsignmentItem = useMutation({
    mutationFn: async ({
      data,
    }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (await axios
      .put<ConsignmentItemResponse[]>(`${baseUrl}`, data)).data,
    onSuccess: (_, variables) => {
      variables.data.map((item) => queryClient
        .invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(item.houseConsignmentId) }))
    },
  })

  const doPutConsignmentItemDraft = useMutation({
    mutationFn: async ({
      data,
    }: MutateRequest<CreateOrUpdateConsignmentItemRequest[]>) => (await axios
      .put<ConsignmentItemResponse[]>(`${baseUrl}/draft`, data)).data,
    onSuccess: (_, variables) => {
      variables.data.map((item) => queryClient
        .invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(item.houseConsignmentId) }))
    },
  })

  const postConsignmentItem = (isDraft: boolean) => {
    if (isDraft) {
      return doPostConsignmentItemDraft
    }
    return doPostConsignmentItem
  }

  const putConsignmentItem = (isDraft: boolean) => {
    if (isDraft) {
      return doPutConsignmentItemDraft
    }
    return doPutConsignmentItem
  }

  const deleteConsignmentItem = useMutation({
    mutationFn: async ({
      data: consignmentItemId,
    }: MutateRequest<number>) => (await axios.delete<void>(`${baseUrl}/${consignmentItemId}`)).data,
    onSuccess: (_, variables) => queryClient.invalidateQueries({ queryKey: getConsignmentItemByHouseConsignmentId(variables.id) }),
  })

  // useQueries does not accept an abort signal
  //  https://github.com/TanStack/query/issues/5342
  const fetchConsignmentItems = useQueries(
    {
      queries: houseConsignmentIds.map((houseConsignmentId) => ({
        queryKey: ['/v2/consignment-item', houseConsignmentId],
        queryFn: async () => (await axios.get<ConsignmentItemResponse[]>(`${baseUrl}/${houseConsignmentId}`)).data,
        staleTime: Infinity, // never stale
        enabled: !!houseConsignmentId && !doPostConsignmentItem.isLoading && !doPostConsignmentItemDraft.isLoading
          && !doPutConsignmentItem.isLoading
          && !doPutConsignmentItemDraft.isLoading && !deleteConsignmentItem.isLoading && !isSubmitting,
      })),
    },
  )

  return {
    fetchConsignmentItems,
    postConsignmentItem,
    putConsignmentItem,
    deleteConsignmentItem,
  }
}

export default useConsignmentItemApi
