import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { UseDeclarationFormReturn } from '../../form'
import useConsignmentApi from './api'
import { parseConsignmentResponse, toCreateOrUpdateConsignmentRequest } from './mapper'
import useTransportEquipment from '../useTransportEquipment'
import useCarrier from '../useCarrier'
import useConsignmentDetail from '../useConsignmentDetail'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'

function useConsignment(form: UseDeclarationFormReturn) {
  const {
    trigger,
    getValues, setValue,
    formState: { isValid, isSubmitting }, reset,
    watch,
  } = form

  const consignmentId: number | null = watch('consignmentId')
  const transitOperationId: number | null = watch('id')

  const {
    fetchConsignment,
    postConsignment,
    putConsignment,
  } = useConsignmentApi(transitOperationId, isSubmitting)

  const { deleteTransportEquipments } = useTransportEquipment()
  const { createCarrier } = useCarrier(form)
  const { createConsignmentTrader } = useConsignmentDetail(form)

  const populateFormConsignment = () => {
    if (fetchConsignment.isLoading || isSubmitting) {
      return
    }

    const response = parseConsignmentResponse(fetchConsignment.data)

    response?.departureTransportMeans.sort(sortBySequenceNumber)
    response?.activeBorderTransportMeans.sort(sortBySequenceNumber)
    response?.countryOfRoutingOfConsignment.sort(sortBySequenceNumber)

    reset({
      ...structuredClone(getValues()),
      ...response,
    })
  }

  useEffect(() => {
    populateFormConsignment()
  }, [fetchConsignment.data])

  const getConsignment = () => fetchConsignment.data

  const createConsignment = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return
    await createConsignmentTrader(isDraft, 'consignor')
    await createConsignmentTrader(isDraft, 'consignee')
    await createCarrier(isDraft)
    const values = getValues()
    const consignment = toCreateOrUpdateConsignmentRequest(values)

    const result = await postConsignment.mutateAsync(consignment)
    setValue('consignmentId', result.id)
  }
  const updateConsignment = async (isDraft: boolean) => {
    await trigger()

    if (!isDraft && !isValid) return
    if (consignmentId === null) return

    await createConsignmentTrader(isDraft, 'consignor')
    await createConsignmentTrader(isDraft, 'consignee')
    await createCarrier(isDraft)
    const transitForm = getValues()

    await deleteTransportEquipments(transitForm.transportEquipment)

    const consignment = toCreateOrUpdateConsignmentRequest(transitForm)

    try {
      await putConsignment(isDraft).mutateAsync({
        id: consignmentId,
        data: consignment,
      })
    } catch (error) {
      toast.error('Error updating consignment')
      // eslint-disable-next-line no-console
      console.error('An error has occurred: ', error) // T1-1272
    }
  }

  /**
   * @deprecated The method not implemented
   */
  const archiveConsignment = async () => {
    toast.error('Deletion not supported')
  }

  return {
    getConsignment,
    createConsignment,
    updateConsignment,
    archiveConsignment,
  }
}

export default useConsignment
