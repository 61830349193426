import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { DeclarationAudit } from '../../../types/DeclarationAudit'
import TableRowStatus from '../../Transits/Table/TableRowStatus'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface TableRowProps {
  row: any;
  index: number;
  refreshPage: () => void;
  getDeclaration: (id: number, row: any) => void;
  openDecisionModal: (declaration: DeclarationAudit) => void;
}

function TableRow(props: TableRowProps) {
  const {
    row,
    getDeclaration,
    openDecisionModal,
  } = props
  const { t } = useTranslation()
  const [isOptionsOpen, setOptionsOpen] = useState<boolean>(false)

  return (
    <React.Fragment key={`${row.id}_row`}>
      <tr
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...row.getRowProps()}
      >
        {/* eslint-disable  @typescript-eslint/no-explicit-any */}
        {row.cells.map((cell: any) => (
          <td
            role="presentation"
            className={
              `${cell.column.id === 'id' ? 'text-left' : ''} ${
                (!isOptionsOpen && !(cell.column.id === 'mrn' || cell.column.id === 'id')) ? 'pointer' : ''} ${
                cell.column.className ? cell.column.className : ''
              }`
            }
            onClick={() => {
              if (isOptionsOpen) {
                return setOptionsOpen(!isOptionsOpen)
              }

              return getDeclaration(row.original.declarationId, row)
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...cell.getCellProps()}
          >
            {cell.column.id === 'status' && (
              <TableRowStatus value={cell.value} />
            )}
            {
              cell.column.id === 'id'
              && (
                <span className="text-decoration-underline">
                  {row.cells[0].render('Cell')}
                </span>
              )
            }
            {
              (cell.column.id === 'isReviewed')
              && (
                <div
                  className={`d-flex justify-content-center align-items-center rounded-circle wh-24 ms-1 ${
                    row.original.isReviewed ? 'text-success' : 'text-danger'
                  }`}
                  style={{ border: '1px solid' }}
                >
                  <i className={`${row.original.isReviewed ? 'fa-check' : 'fa-times'} far`} />
                </div>
              )
            }
            {
              cell.column.id !== 'id' && cell.column.id !== 'type' && cell.column.id !== 'status' && cell.column.id !== 'isReviewed'
              && (
                cell.render('Cell')
              )
            }
          </td>
        ))}
        <td className="dropdown-cell w-1">
          <Dropdown
            onToggle={(isOpen) => setOptionsOpen(isOpen)}
          >
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="link"
              className="p-0 hide-caret"
            >
              <i className="fas fa-ellipsis-h fa-md" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow">
              <Dropdown.Item onClick={() => openDecisionModal(row.original)}>
                <i className="fal fa-edit me-2 fa-md" />
                <span>{t('buttons.decideAudit')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </td>
      </tr>
    </React.Fragment>
  )
}

export default TableRow
