import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { toast } from 'react-toastify'
import { Document, Page } from 'react-pdf'
import { AxiosResponse } from 'axios'
import { Modal } from 'react-bootstrap'
import PrintService from '../services/print.service'
import LoadingBackdrop from './LoadingBackdrop'

interface PdfPreviewModalParams {
  isPdfPreviewModalVisible: boolean
  setPdfPreviewModalVisible: Dispatch<SetStateAction<boolean>>
  declarationId: number
  isPhase5: boolean
}

function PdfPreviewModal(props: PdfPreviewModalParams) {
  const {
    isPdfPreviewModalVisible,
    setPdfPreviewModalVisible,
    declarationId,
    isPhase5,
  } = props
  const [pdfBlobUrl, setPdfBlobUrl] = useState('')
  const [pdfFilename, setPdfFileName] = useState('')
  const [file, setFile] = useState<File>()
  const [pdfPageCount, setPdfPageCount] = useState<number>(0)
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false)

  const closePdfModal = () => {
    URL.revokeObjectURL(pdfBlobUrl)
    setPdfPreviewModalVisible(false)
    setFile(undefined)
    setPdfBlobUrl('')
    setPdfFileName('')
    setPdfPageCount(0)
  }

  const loadPdf = () => {
    if (declarationId == null) {
      toast.error('Unable to load pdf')
    }

    PrintService.getDeclarationPDF(declarationId).then((response: AxiosResponse) => {
      const objectURL = URL.createObjectURL(response.data)
      const [, filename] = response.headers['content-disposition'].split('filename=')
      setPdfFileName(filename)
      setFile(new File([response.data], filename))
      setPdfBlobUrl(objectURL)
    })
  }
  const loadTransitPdf = () => {
    if (declarationId == null) {
      toast.error('Unable to load pdf')
    }

    setIsPdfLoading(true)

    PrintService.getTransitionPDF(declarationId)
      .then((response: AxiosResponse) => {
        const objectURL = URL.createObjectURL(response.data)
        const [, filename] = response.headers['content-disposition'].split('filename=')
        setPdfFileName(filename)
        setFile(new File([response.data], filename))
        setPdfBlobUrl(objectURL)
        setIsPdfLoading(false)
      })
      .catch(() => {
        setIsPdfLoading(false)
      })
  }
  const savePdf = () => {
    const a: HTMLAnchorElement = document.createElement('a')
    a.href = pdfBlobUrl
    a.download = pdfFilename
    a.click()
  }

  useEffect(() => {
    if (isPdfPreviewModalVisible) {
      if (!isPhase5) {
        loadPdf()
      } else {
        loadTransitPdf()
      }
    }
  }, [isPdfPreviewModalVisible])

  const loadDocument = () => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isPdfLoading && (
        <div className="overlay--backdrop" id="loading">
          <LoadingBackdrop loading={isPdfLoading} isInTable={false} />
        </div>
      )}
      {
        file && !isPdfLoading
        && (
          <Document
            file={file}
            onLoadSuccess={({ numPages }) => {
              setPdfPageCount(numPages)
            }}
            noData="PDF loading failed"
            loading={<p>Loading pdf…</p>}
          >
            {Array.from(
              new Array(pdfPageCount),
              (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={1140}
                />
              ),
            )}
          </Document>
        )
      }
    </>
  )

  return (
    <Modal
      show={isPdfPreviewModalVisible}
      dialogClassName="pdf-preview-modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        closePdfModal()
      }}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>Transit preview</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => {
            closePdfModal()
          }}
        >
          <span>Close</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="pdf-preview">
          {loadDocument()}
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary btn-lg m-1 mt-2"
            onClick={() => {
              closePdfModal()
            }}
          >
            <span>Close</span>
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-lg m-0 text-primary m-1 mt-2"
            disabled={isPdfLoading}
            onClick={() => savePdf()}
          >
            <span>Download</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PdfPreviewModal
