import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../apiConfig'
import { CreateOrUpdateGuaranteeRequest, GuaranteeResponse } from '../../../common/models'

const {
  baseUrl,
  queryKeys: { getGuaranteeByTransitOperationId },
} = DeclarationApi.paths.guarantee

function useGuaranteeApi(transitOperationId: number | null, isSubmitting: boolean) {
  const queryClient = useQueryClient()

  const postGuarantee = useMutation({
    mutationFn: async (data: CreateOrUpdateGuaranteeRequest[]) => (await axios.post<GuaranteeResponse[]>(baseUrl, data)).data,
    onSuccess: (_, request) => {
      request.forEach((guarantee) => {
        queryClient.invalidateQueries({ queryKey: getGuaranteeByTransitOperationId(guarantee.transitOperationId) })
      })
    },
  })

  const putGuarantee = useMutation({
    mutationFn: async (data: CreateOrUpdateGuaranteeRequest[]) => (await axios.put<GuaranteeResponse[]>(`${baseUrl}`, data)).data,
    onSuccess: (_, request) => {
      request.forEach((guarantee) => {
        queryClient.invalidateQueries({ queryKey: getGuaranteeByTransitOperationId(guarantee.transitOperationId) })
      })
    },
  })

  const fetchGuarantee = useQuery({
    queryFn: async ({ signal }) => (await axios
      .get<GuaranteeResponse[]>(`${baseUrl}/${transitOperationId}`, { signal })).data,
    queryKey: getGuaranteeByTransitOperationId(transitOperationId),
    staleTime: Infinity, // never stale
    enabled: !!transitOperationId && !postGuarantee.isLoading && !putGuarantee.isLoading && !isSubmitting,
  })

  return {
    fetchGuarantee,
    postGuarantee,
    putGuarantee,
  }
}

export default useGuaranteeApi
