import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { extractCountryFromDepartureOffice } from '../../../common/utils/classifier-util'
import { CL147_SECURITY_AGREEMENT_AREA_COUNTRIES, IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'

const C0710Validator = {
  validateLocationOfGoodsRequirement: (formData: DeclarationForm): RuleResult => {
    if (IS_TRANSITIONAL_PERIOD) {
      return 'OPTIONAL' // B1804
    }

    if (formData.additionalDeclarationType === 'D') {
      return 'OPTIONAL'
    }

    const departureCountry = extractCountryFromDepartureOffice(formData.departureCustomsOffice)
    if (departureCountry && CL147_SECURITY_AGREEMENT_AREA_COUNTRIES.includes(departureCountry)) {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default C0710Validator
