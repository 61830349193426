import React from 'react'
import { useTranslation } from 'react-i18next'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import useCodelist from '../../../common/hooks/useCodelist'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'

type DeclarationInput = keyof DeclarationForm

function TransportSection() {
  const { t } = useTranslation()
  const [, transportModeOptions] = useCodelist('TRANSPORT_MODES')
  const departureTransportMeans = transportModeOptions.filter((item) => !item.value.endsWith(9)) // B1091

  return (
    <section className="py-3">
      <CollapsibleColumn
        columnId="declaration-transport"
        headingLabel={t('declaration.p5.transport')}
      >
        <FormField labelKey="declaration.p5.inlandTransportMode">
          <FormSelect
            <DeclarationInput>
            field="inlandModeOfTransport"
            labelKey="declaration.p5.inlandTransportMode"
            options={departureTransportMeans}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.borderTransportMode">
          <FormSelect
            <DeclarationInput>
            field="borderModeOfTransport"
            labelKey="declaration.p5.borderTransportMode"
            options={transportModeOptions}
            type="sync"
          />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default TransportSection
