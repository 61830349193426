import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import { ISelectOption } from 'types/IClassifier'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { PreviousDocument } from '../../form/schemas/documentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'

import { blankPreviousDocument } from '../../form'
import { PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX, removePrefix } from '../../../common/utils/classifier-util'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import TransitDocumentFileNames from '../../components/TransitDocumentFileNames'
import DocumentDropdownArea from '../../components/DocumentDropdownArea'

const expectedScope = /houseConsignment\.\d+\.consignmentItem\.\d+\.previousDocument\.\d+/g
type PreviousDocumentItemScope = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.previousDocument.${number}`>
const isPreviousDocumentItemScope = (scope: ObjectScope):
  scope is `houseConsignment.${number}.consignmentItem.${number}.previousDocument.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

export function isTransportDocumentNumberUsable(documentType: string, country: string) {
  return (removePrefix(documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N355'
    || removePrefix(documentType, PREVIOUS_DOCUMENT_TYPE_CODE_PREFIX) === 'N337') && (country === 'EE' || country === 'FI')
}

function PreviousDocuments({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()
  const {
    addAction, removeAction, getFields, arrayRule,
  } = useFieldArrayActionHelper<PreviousDocument>({
    blankItem: blankPreviousDocument,
    name: scope,
  })
  const [, previousDocumentTypesOptions] = useCodelist('PREVIOUS_DOCUMENTS')

  const [procedureTypeOptions] = useState<ISelectOption[]>([
    {
      label: 'PIIR - Piiriületus',
      value: 'PIIR',
    },
    {
      label: 'ASENDUS - Asendustoiming',
      value: 'ASENDUS',
    },
    {
      label: 'VABA - Vabatsoon',
      value: 'VABA',
    },
  ])

  // const document: PreviousDocument = getValues(`houseConsignment.${number}.consignmentItem.${number}.previousDocument.${number}`)
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-previous-documents"
        headingLabel={t('declaration.p5.previousDocuments')}
      >
        {getFields().map((previousDocument, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isPreviousDocumentItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          const currentValue = getValues(innerItemScope)

          const isTransportDocumentNumberVisible = isTransportDocumentNumberUsable(currentValue.documentType, country)

          // @ts-ignore
          return (
            <FormFieldArray
              key={`previousDocument_${previousDocument.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, previousDocument)}
              sequenceNumber={previousDocument.sequenceNumber + 1}
              hidden={previousDocument.deleted}
            >
              <FormField labelKey="declaration.p5.previousDocumentType">
                <FormSelect
                  <PreviousDocumentItemScope>
                  field={`${innerItemScope}.documentType`}
                  labelKey="declaration.p5.previousDocumentType"
                  options={previousDocumentTypesOptions}
                  type="sync"
                />
              </FormField>
              {country === 'EE' && (
                <FormField labelKey="declaration.p5.procedureType">
                  <FormSelect
                    <PreviousDocumentItemScope>
                    field={`${innerItemScope}.procedureType`}
                    labelKey="declaration.p5.procedureType"
                    options={procedureTypeOptions}
                    type="sync"
                  />
                </FormField>
              )}
              <FormField labelKey="declaration.p5.previousDocumentReferenceNumber">
                <Input
                  <PreviousDocumentItemScope>
                  type="text"
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.previousDocumentReferenceNumber"
                />
              </FormField>
              <FormField labelKey="declaration.p5.complementOfInformation">
                <Input
                  <PreviousDocumentItemScope>
                  type="text"
                  field={`${innerItemScope}.complementOfInformation`}
                  labelKey="declaration.p5.complementOfInformation"
                />
              </FormField>
              {
                isTransportDocumentNumberVisible && (
                  <FormField
                    labelKey="declaration.p5.transportDocumentReferenceNumber"
                  >
                    <Input
                      <PreviousDocumentItemScope>
                      type="text"
                      field={`${innerItemScope}.transportDocumentReferenceNumber`}
                      labelKey="declaration.p5.transportDocumentReferenceNumber"
                    />
                  </FormField>
                )
              }
              {country === 'LV' && (
                <>
                  <TransitDocumentFileNames
                    key={`documentFileNames${previousDocument.id + previousDocument.sequenceNumber}`}
                    documentFilesScope={`${innerItemScope}.files`}
                  />
                  <DocumentDropdownArea
                    key={`documents${previousDocument.sequenceNumber + previousDocument.id}`}
                    documentScope={`${innerItemScope}`}
                    documentFilesScope={`${innerItemScope}.files`}
                  />
                </>
              )}
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewDocument')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default PreviousDocuments
