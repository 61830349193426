import React from 'react'

interface ImageMagnifierProps {
  imageSrc: string,
  imageAlt: string,
  // eslint-disable-next-line react/require-default-props
  elementClass?: string,
  // eslint-disable-next-line react/require-default-props
  imageRef?: React.LegacyRef<HTMLImageElement>,
  // eslint-disable-next-line react/no-unused-prop-types
  rotationDegree: number,
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  modalWidth?: number,
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  imageWidth?: number,
}
function ImageMagnifier(props:ImageMagnifierProps) {
  const {
    imageSrc, imageAlt, elementClass, imageRef,
  } = props

  return (
    <img
      ref={imageRef}
      src={imageSrc}
      loading="lazy"
      decoding="async"
      alt={imageAlt}
      className={elementClass || ''}
    />
  )
}

export default ImageMagnifier

