import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccessLevel, DeclarationContext } from '../../../context/DeclarationContext'
import LoadingBackdrop from '../../../components/LoadingBackdrop'

interface IGoodsItemRow {
  hsCode: string | null,
  quantity: number | null,
  grossWeight: number | null,
  onDelete: () => void,
  onRowClick: () => void,
  onDuplicate: () => void,
  isActive: boolean,
  isLoading: boolean,
  hasRestrictedCode: boolean,
  description: string | null,
}

function GoodsItemRow(props: IGoodsItemRow) {
  const { metaData: { accessLevel } } = useContext(DeclarationContext)

  const [hasRowHover, setRowHover] = useState(false)
  const { t } = useTranslation()

  const changeRowHover = (isHovering: boolean) => {
    if (accessLevel === AccessLevel.VIEW) {
      return
    }

    setRowHover(isHovering)
  }

  const {
    hsCode,
    quantity,
    grossWeight,
    onDelete,
    onRowClick,
    onDuplicate,
    isActive,
    isLoading,
    hasRestrictedCode,
    description,
  } = props

  return (
    <tr
      className={`item-hover items-list__row ${isActive ? 'item-hover--active' : ''}`}
      onMouseEnter={() => changeRowHover(true)}
      onMouseLeave={() => changeRowHover(false)}
    >
      <td role="presentation" className="w-66 ps-4 cursor-pointer" onClick={() => onRowClick()}>
        {isLoading && <LoadingBackdrop loading={isLoading} />}
        <div className="d-flex flex-column">
          <span
            className="items-list__row-title text-left"
            style={{ color: `${hasRestrictedCode ? 'red' : ''}` }}
          >
            {t('goodsItem.hsCode')}
          </span>
          <span
            className="items-list__row-value text-left text-wrap"
            style={{ color: `${hasRestrictedCode ? 'red' : ''}` }}
          >
            {hsCode}
          </span>
          <span
            className="items-list__row-title text-left"
          >
            {t('goodsItem.description')}
          </span>
          <span
            className="items-list__row-value text-left text-wrap"
          >
            {description !== null ? description : '-'}
          </span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column">
          <span className="text-center items-list__row-title">
            {t('goodsItem.quantity')}
          </span>
          <span className="items-list__row-value text-center">
            {quantity}
          </span>
        </div>
      </td>
      <td className="pe-4 position-relative">
        <div className="d-flex flex-column">
          <span className="text-right items-list__row-title">
            {t('goodsItem.grossWeight')}
          </span>
          <span className="items-list__row-value text-right">
            {grossWeight}
          </span>
        </div>
        <div
          className={hasRowHover ? 'hover-container d-flex justify-content-center align-items-center shadow' : 'd-none'}
        >
          <button
            type="button"
            className="btn btn-light d-flex justify-content-center align-items-center mx-2 wh-34 rounded-circle shadow-sm"
            onClick={onDelete}
          >
            <i className="fal fa-trash fa-md" />
          </button>
          <button
            type="button"
            className="btn btn-light d-flex justify-content-center align-items-center mx-2 wh-34 rounded-circle shadow-sm"
            onClick={onDuplicate}
          >
            <i className="fal fa-clone fa-flip-horizontal fa-md" />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default GoodsItemRow
