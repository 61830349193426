import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface FormFieldArrayProps {
  titleKey: string;
  appendItem?: () => void;
  removeItem?: () => void;
  hidden?: boolean;
  sequenceNumber?: number;
}

function FormFieldArray({
  titleKey,
  appendItem,
  removeItem,
  hidden,
  sequenceNumber,
  children,
}: React.PropsWithChildren<FormFieldArrayProps>) {
  const { t } = useTranslation()

  return (
    <React.Fragment key={titleKey + hidden}>
      {
        !hidden
        && (
          <fieldset className="p-2 mb-2" key={titleKey + hidden}>
            <legend className="heading align-items-end">
              <h3>
                {t(titleKey)}
                {sequenceNumber !== undefined && sequenceNumber}
              </h3>

              <div className="btn-group">
                {
                  removeItem !== undefined
                  && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-remove">{t('buttons.remove')}</Tooltip>}
                    >
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={removeItem}
                      >
                        <i className="far fa-trash" />
                      </button>
                    </OverlayTrigger>
                  )
                }
                {
                  appendItem !== undefined
                  && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-add">{t('buttons.addNew')}</Tooltip>}
                    >
                      <button
                        className="btn btn-sm btn-success text-white"
                        type="button"
                        onClick={appendItem}
                      >
                        <i className="far fa-plus" />
                      </button>
                    </OverlayTrigger>
                  )
                }
              </div>

            </legend>
            {
              children !== undefined && ((Array.isArray(children) && children.length > 0) || React.isValidElement(children))
              && (
                <div className="p-2 card">
                  {children}
                </div>
              )
            }
          </fieldset>
        )
      }
    </React.Fragment>
  )
}

FormFieldArray.defaultProps = {
  appendItem: undefined,
  removeItem: undefined,
  hidden: false,
  sequenceNumber: undefined,
}

export default FormFieldArray
