import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import LoadingSpinner from 'components/LoadingSpinner'
import { UserContext } from 'context/UserContext'
import { UserPreference } from '../../../../types/UserPreference'
import useCodelist from '../../common/hooks/useCodelist'
import { sortBySortOrder } from '../../../../helpers'
import { CustomClassifier } from '../../../TableFilter'
import useUserPreference from '../hooks/useUserPreference'

interface TableColumnPreferencesProps {
  userPreferences: Array<UserPreference>;
  isTableFilterModalOpen: boolean;
  setIsTableFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  setUserPreferences: Dispatch<SetStateAction<Array<UserPreference>>>;
  isPreferencesLoading: boolean;
}

function TableColumnPreferencesModal({
  userPreferences, isTableFilterModalOpen, setIsTableFilterModalOpen, setUserPreferences, isPreferencesLoading,
}: Readonly<TableColumnPreferencesProps>) {
  const [savingPreferences, setSavingPreferences] = useState<boolean>(false)
  const [userPreferencesClassifiers, setUserPreferencesClassifiers] = useState<Array<CustomClassifier>>([])
  const [preferences] = useCodelist('TRANSIT_TABLE')

  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  const blankClassifier: CustomClassifier = {
    codeLabel: '',
    sortOrder: 0,
    nameEn: '',
    nameRu: '',
    deleted: false,
  }

  const { createOrUpdateUserPreferences } = useUserPreference()

  useEffect(() => {
    if (userPreferences.length === 0 || isPreferencesLoading) {
      return
    }

    const classifierArray: Array<CustomClassifier> = []
    const arrayOfNotMigratedPreferences: Array<CustomClassifier> = []
    let userPreferencesAdmin: Array<UserPreference> = []
    if (user?.role === 'ADMIN') {
      userPreferencesAdmin = userPreferences.filter((item) => (item.value !== 'TRANSIT_COMPANY_NOTE')).map((items) => items)
    }

    preferences.forEach((classifier) => {
      const index = user?.role !== 'ADMIN'
        ? userPreferences.find((preference) => preference.value === classifier.codeLabel)
        : userPreferencesAdmin.find((preference) => preference.value === classifier.codeLabel)
      const preference = JSON.parse(JSON.stringify(blankClassifier))
      if (index) {
        if (classifier.nameEn != null) {
          preference.nameEn = classifier.nameEn
        }
        if (classifier.nameRu != null) {
          preference.nameRu = classifier.nameRu
        }
        preference.deleted = index.deleted
        preference.sortOrder = index.sortOrder
        preference.codeLabel = index.value
        classifierArray.push(preference)
      } else {
        if (user?.role === 'ADMIN' && classifier.codeLabel === 'TRANSIT_COMPANY_NOTE') return
        if (user?.role !== 'ADMIN' && classifier.codeLabel === 'TRANSIT_NOTES') return
        if (classifier.nameEn != null) {
          preference.nameEn = classifier.nameEn
        }
        if (classifier.nameRu != null) {
          preference.nameRu = classifier.nameRu
        }
        preference.deleted = true
        preference.sortOrder = userPreferences.length + arrayOfNotMigratedPreferences.length
        preference.codeLabel = classifier.codeLabel
        arrayOfNotMigratedPreferences.push(preference)
      }
    })
    classifierArray.push(...arrayOfNotMigratedPreferences)
    classifierArray.sort(sortBySortOrder)
    setUserPreferencesClassifiers(classifierArray)
    if (arrayOfNotMigratedPreferences.length > 0) {
      arrayOfNotMigratedPreferences.sort(sortBySortOrder)
      const sortedPreferences = arrayOfNotMigratedPreferences
        .map((newPreference) => ({
          value: newPreference.codeLabel,
          sortOrder: newPreference.sortOrder,
          deleted: newPreference.deleted,
        }))
      setUserPreferences((prevValues) => [...prevValues, ...sortedPreferences])
    }
  }, [isPreferencesLoading, userPreferences.length !== 0])

  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return
    userPreferences.sort(sortBySortOrder)
    const updatedList = [...userPreferences]
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    updatedList.forEach((item, index) => {
      if (item) {
        // eslint-disable-next-line no-param-reassign
        item.sortOrder = index
      }
    })
    updatedList.sort(sortBySortOrder)
    setUserPreferences(updatedList)

    const updatedClassifierList = [...userPreferencesClassifiers]
    const [reorderedClassifierItem] = updatedClassifierList.splice(droppedItem.source.index, 1)
    updatedClassifierList.splice(droppedItem.destination.index, 0, reorderedClassifierItem)
    updatedClassifierList
      .forEach((item, index) => {
        // eslint-disable-next-line no-param-reassign
        item.sortOrder = index
      })
    updatedClassifierList
      .sort(sortBySortOrder)
    setUserPreferencesClassifiers(updatedClassifierList)
  }

  const changePreference = (type: string, checked: boolean) => {
    const classifierExists = userPreferencesClassifiers.find((preference) => preference.codeLabel === type)
    const preferenceExists = userPreferences.find((preference) => preference.value === type)

    if (preferenceExists && checked && classifierExists) {
      preferenceExists.value = type
      preferenceExists.deleted = false
      classifierExists.deleted = false
      setUserPreferences((prevValues) => [...prevValues])
    } else if (preferenceExists && !checked && classifierExists) {
      classifierExists.deleted = true
      preferenceExists.deleted = true
      setUserPreferences((prevValues) => [...prevValues])
    }
  }

  const submitPreferences = async () => {
    setSavingPreferences(true)
    await createOrUpdateUserPreferences(userPreferencesClassifiers)
      .then(() => {
        toast.success(t('preferences.updated'))
        setTimeout(() => {
          setSavingPreferences(false)
          setIsTableFilterModalOpen(false)
        }, 500)
      }).catch((error) => {
        toast.error(t('preferences.failed'))
        // eslint-disable-next-line no-console
        console.error(error.message)
        setSavingPreferences(false)
      })
  }

  return (
    <Modal
      show={isTableFilterModalOpen}
      size="lg"
      className="overflow-hidden"
      dialogClassName="d-flex modal preferences-modal align-modal-top align-modal-left"
      backdropClassName="backdrop-with-modal"
      animation={false}
      onHide={() => setIsTableFilterModalOpen(!isTableFilterModalOpen)}
      centered
    >
      <Modal.Header>
        <Modal.Title className="justify-content-between">
          <h2>
            {t('user.preferences')}
          </h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              if (!savingPreferences) {
                setIsTableFilterModalOpen(!isTableFilterModalOpen)
              }
            }}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '80vh' }}>
        <p>{t('preferences.order')}</p>
        <div className="pt-4 px-4 col-12 col-xl-12 mb-5 mb-md-0">
          <div className="container">
            <div className="row align-items-start">
              <DragDropContext onDragEnd={handleDrop}>
                {userPreferences.length !== 0 && userPreferencesClassifiers.map((classifier, index) => (
                  <Droppable droppableId={classifier.codeLabel} key={classifier.codeLabel}>
                    {(provided) => (
                      <div
                        className="list-container"
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Draggable key={classifier.codeLabel} draggableId={classifier.codeLabel} index={index}>
                          {(secondProvider) => (
                            <div
                              className="item-container"
                              ref={secondProvider.innerRef}
                              /* eslint-disable-next-line react/jsx-props-no-spreading */
                              {...secondProvider.draggableProps}
                              /* eslint-disable-next-line react/jsx-props-no-spreading */
                              {...secondProvider.dragHandleProps}
                              key={classifier.codeLabel}
                              id={classifier.codeLabel}
                            >
                              <div className="d-flex mb-2 col" key={classifier.codeLabel} id={classifier.codeLabel}>
                                <div className="form-check form-switch ms-3 me-2">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={!classifier.deleted}
                                    onChange={(e) => changePreference(classifier.codeLabel, e.target.checked)}
                                  />
                                </div>
                                <p>
                                  {i18n.language === 'ru' ? classifier.nameRu : classifier.nameEn}
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-dark btn-sm d-flex align-items-center"
          type="button"
          disabled={savingPreferences}
          onClick={() => {
            submitPreferences()
          }}
        >
          {
            savingPreferences && <LoadingSpinner />
          }
          <span>{t('buttons.save')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TableColumnPreferencesModal
