import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { UseFormSetValue } from 'react-hook-form'
import { FilePreviewModal } from 'routes/Transits/TransitsAside/FilePreviewModal'
import { GuaranteeCalculationItemRow } from '../../hooks/useGuaranteeCalculator'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import CalculationRow from './CalculationRow'
import { parseNumber } from '../../../common/utils/common-util'
import useTransitOperationLog from '../../hooks/useTransitOperationLog'
import { DocumentFile } from '../../../../../types/Document'

interface GuaranteeCalculatorProps {
  transitOperationId: number | null
  isCalculationModalVisible: boolean
  setCalculationModalVisible: Dispatch<SetStateAction<boolean>>
  rows: GuaranteeCalculationItemRow[]
  exchangeRate: number
  exchangeRateLoading: boolean
  setValue: UseFormSetValue<DeclarationForm>
  foreignCurrency: 'EUR' | 'RON' | 'BGN'
}

function GuaranteeCalculatorModal({
  transitOperationId,
  rows,
  isCalculationModalVisible,
  setCalculationModalVisible,
  exchangeRate,
  exchangeRateLoading,
  setValue,
  foreignCurrency,
}: GuaranteeCalculatorProps) {
  const { t } = useTranslation()
  const { fetchTransitOperationLogs } = useTransitOperationLog(transitOperationId ?? undefined)

  const totalPriceSum = useMemo(() => rows
    .reduce((previousValue, currentValue) => previousValue + (parseNumber(currentValue.taxableItemPrice) ?? 0), 0), [rows])
  const totalPriceInForeignCurrency = (totalPriceSum * exchangeRate).toFixed(3)

  const setCalculationResultAsGuaranteeAmount = () => {
    const taxesSum: number = rows
      .reduce((
        accumulatedSum,
        item,
      ) => accumulatedSum
        + (parseNumber(item.taxableDutyAmount) ?? 0)
        + (parseNumber(item.taxableVatAmount) ?? 0)
        + (parseNumber(item.excise) ?? 0), 0)

    setValue('guarantees.0.amount', Number(taxesSum))
  }
  const submissionCalculationPdfLog = useMemo(
    () => fetchTransitOperationLogs.data && fetchTransitOperationLogs.data
      .find((logItem) => logItem.messageContext?.endsWith('IE928') && logItem.functionalErrors && logItem.functionalErrors.length > 0),
    [fetchTransitOperationLogs],
  )

  if (submissionCalculationPdfLog !== undefined && submissionCalculationPdfLog.functionalErrors !== undefined) {
    const calculationFile = submissionCalculationPdfLog.functionalErrors.find((subRow) => subRow.type === 'FILE')

    return (
      <FilePreviewModal
        type={calculationFile?.originalAttributeValue?.endsWith('pdf') ? 'PDF' : 'EXCEL'}
        file={{
          filename: calculationFile?.originalAttributeValue ?? 'calculation.xlsx',
          url: calculationFile?.reason,
          mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        } as DocumentFile}
        isVisible={isCalculationModalVisible}
        toggleVisibility={() => setCalculationModalVisible(() => !isCalculationModalVisible)}
      />
    )
  }

  return (
    <Modal
      show={isCalculationModalVisible}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setCalculationModalVisible(false)}
      animation={false}
      backdropClassName="backdrop-with-modal"
      centered
      dialogClassName="modal-to-size"
      fullscreen="lg-down"
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-capitalize">
            {t('guaranteeCalculator.preview')}
          </h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setCalculationModalVisible(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="guarantee-calculation-container">
          <div />
          <div>{t('guaranteeCalculator.price')}</div>
          <div>{t('guaranteeCalculator.excise')}</div>
          <div>{t('guaranteeCalculator.dutyRate')}</div>
          <div>{t('guaranteeCalculator.taxableDutyAmount')}</div>
          <div>{t('guaranteeCalculator.vatRate')}</div>
          <div>{t('guaranteeCalculator.taxableVatAmount')}</div>
          <div>{t('guaranteeCalculator.taxableItemPrice')}</div>
          {
            rows.map((rowItem) => <CalculationRow key={`calc_row_${rowItem.sequenceNumber}`} rowItem={rowItem} />)
          }
        </div>
        <div className="d-flex justify-content-end mt-4 px-3">
          <div>
            <div className="mr-2">{t('guaranteeCalculator.totalPrice')}</div>
            {
              exchangeRate > 0 && (
                <div className="d-flex justify-content-end">
                  {`1 EUR = ${exchangeRate}`}
                </div>
              )
            }
            <div className="d-flex justify-content-end">
              {`${totalPriceSum.toFixed(3)} EUR`}
            </div>
            {
              exchangeRate > 0 && (
                <div className="d-flex justify-content-end">
                  {`${totalPriceInForeignCurrency} ${foreignCurrency}`}
                </div>
              )
            }

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={exchangeRateLoading}
          type="button"
          variant="primary"
          size="lg"
          onClick={() => {
            setCalculationResultAsGuaranteeAmount()
            setCalculationModalVisible(false)
          }}
        >
          {exchangeRateLoading ? <LoadingSpinner /> : <span>{t('guaranteeCalculator.useResultValue')}</span>}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GuaranteeCalculatorModal
