import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { PageResponse, TableFilter } from 'types/PageResponse'
import DeclarationApi from '../apiConfig'
import { TransitOperationAuditPageResponse } from '../../../common/models'

const {
  baseUrl,
  queryKeys: { rootPath },
} = DeclarationApi.paths.transitOperationAudit

interface TransitOperationAuditPageParameters {
  filters: TableFilter[],
  status: string,
  pageIndex: number,
  pageSize: number;
}

export default function useTransitOperationAuditPage(parameters: TransitOperationAuditPageParameters) {
  const currentUrlParams = new URLSearchParams()

  const {
    filters,
    status, pageSize,
    pageIndex,
  } = parameters

  if (pageIndex) {
    currentUrlParams.append('page', pageIndex.toString())
  }

  if (pageSize) {
    currentUrlParams.append('size', pageSize.toString())
  }

  filters.forEach((filter) => {
    if (filter.value !== '' && filter.value !== null
      && filter.value !== ' ') {
      currentUrlParams.append(filter.key, filter.value)
    }
  })
  if (status !== null && status !== undefined && status !== '') {
    currentUrlParams.append('status', status)
  }

  const getAllTransitOperationAudits = useQuery({
    queryFn: async ({ signal }) => axios
      .get<PageResponse<TransitOperationAuditPageResponse>>(baseUrl, { signal, params: currentUrlParams }),
    queryKey: [rootPath, filters, status, currentUrlParams, pageIndex, pageSize],
    staleTime: Infinity,
  })

  return {
    getAllTransitOperationAudits,
  }
}
