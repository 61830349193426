import React from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'rc-tooltip'
import { IDeclaration } from 'types/IDeclaration'

function NavigationBar(props: { currentView: string, navigate: React.Dispatch<React.SetStateAction<string>>, declaration: IDeclaration }) {
  const { currentView, navigate, declaration } = props
  const { t } = useTranslation()
  const isNavigationBlocked = () => (declaration.profile === null || declaration.profile === undefined || declaration.profile === '')

  const getNavigationButtons = () => (
    <span>
      <button
        type="button"
        className={`btn btn-link border-start rounded-0 px-3 py-0 text-decoration-none ${
          currentView === 'goods-items' ? 'text-primary' : ''}`}
        disabled={isNavigationBlocked()}
        onClick={() => navigate('goods-items')}
      >
        <h2>
          {t('declaration.headers.goodsItems')}
        </h2>
      </button>
      <button
        type="button"
        className={`btn btn-link border-start rounded-0 px-3 py-0 text-decoration-none ${
          currentView === 'logs' ? 'text-primary ' : ''} `}
        disabled={isNavigationBlocked()}
        onClick={() => navigate('logs')}
      >
        <h2>
          {t('declaration.headers.logs')}
        </h2>
      </button>
    </span>
  )

  return (
    <div className="navigation-bar">
      <button
        type="button"
        className={`${currentView === 'declaration-info' ? 'text-primary ' : ''}
        btn btn-link px-3 py-0 text-decoration-none`}
        onClick={() => navigate('declaration-info')}
      >
        <h2>
          {t('declaration.headers.declarationInfo')}
        </h2>
      </button>
      {
        !isNavigationBlocked()
        && getNavigationButtons()
      }
      {
        isNavigationBlocked()
        && (
          <Tooltip
            overlayClassName="tooltip-lg"
            overlay={<small>{t('tooltips.guaranteeProfileMissing')}</small>}
          >
            {getNavigationButtons()}
          </Tooltip>
        )
      }
    </div>
  )
}
export default NavigationBar

