import { useEffect, useState } from 'react'
import { CustomClassifier } from 'routes/TableFilter'
import { sortBySortOrder } from 'helpers'
import { UserPreference } from 'types/UserPreference'
import { toUserPreferences, toUserPreferencesUpdateRequest } from './mapper'
import useUserPreferenceApi from './api'

function useUserPreference() {
  const {
    fetchUserPreferences,
    updateUserPreferences,
  } = useUserPreferenceApi()

  const [userPreferences, setUserPreferences] = useState<Array<UserPreference>>([])
  const [isPreferencesLoading, setIsPreferencesLoading] = useState<boolean>(true)

  const getUserPreferences = () => fetchUserPreferences.data

  useEffect(() => {
    const { data } = fetchUserPreferences
    if (data) {
      data.sort(sortBySortOrder)
      const preferences = data.map((item) => toUserPreferences(item))

      setUserPreferences(preferences.length !== 0 ? preferences : [])
      setTimeout(() => {
        setIsPreferencesLoading(false)
      }, 500)
    } else {
      setTimeout(() => {
        setIsPreferencesLoading(false)
      }, 500)
    }
  }, [fetchUserPreferences.data])

  const createOrUpdateUserPreferences = async (data: CustomClassifier[]) => {
    const preferences = data.map((preference) => toUserPreferencesUpdateRequest(preference))

    return await updateUserPreferences.mutateAsync(preferences)
  }

  return {
    getUserPreferences,
    userPreferences,
    setUserPreferences,
    createOrUpdateUserPreferences,
    isPreferencesLoading,
  }
}

export default useUserPreference
