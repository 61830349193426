import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { NotificationContext } from 'context/NotificationContext'
import NotificationService from '../../../../service/notification.service'

function useHeaderNotification(loading: boolean, setLoading: Dispatch<SetStateAction<boolean>>) {
  const { t } = useTranslation()
  const [headerNotificationMessage, setHeaderNotificationMessage] = useState<string>('')

  const {
    contextHeaderNotificationMessage,
    setContextHeaderNotificationMessage,
  } = useContext(NotificationContext)

  useEffect(() => {
    setHeaderNotificationMessage(contextHeaderNotificationMessage)
  }, [])

  const updateHeaderNotificationMessage = () => {
    if (headerNotificationMessage.length > 100) {
      toast.error('Message cannot be longer than 100 characters')
      return
    }
    if (loading) {
      return
    }

    setLoading(true)
    NotificationService.putHeaderNotificationMessage(headerNotificationMessage)
      .then((response) => {
        toast.success(t('notification.updateSuccess'))
        setContextHeaderNotificationMessage(response.message)
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
      .catch(() => {
      })
  }

  return {
    headerNotificationMessage,
    setHeaderNotificationMessage,
    updateHeaderNotificationMessage,
  }
}

export default useHeaderNotification
