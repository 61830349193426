import React from 'react'
import CustomerTitleBar from './components/CustomerTitleBar'
// eslint-disable-next-line import/no-cycle
import Table from './components/Table'

export interface CustomerCountsData {
  customerId: number;
  loading: boolean;
  usersCount?: number;
  declarationsCount?: number;
}
export function CustomerTable() {
  return (
    <>
      <CustomerTitleBar />
      <Table />
    </>
  )
}
