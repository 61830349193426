import React from 'react'
import { Tab } from '../navigation/NavigationBarTabs'

export interface DeclarationTabProps {
  tab: Tab
  currentTab: Tab
}

function DeclarationTab({
  children,
  currentTab,
  tab,
}: React.PropsWithChildren<DeclarationTabProps>) {
  return (
    <React.Fragment key={tab}>
      {tab === currentTab ? children : null}
    </React.Fragment>
  )
}
export default DeclarationTab
