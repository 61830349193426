import React, {
  ChangeEvent,
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import Select, { SingleValue } from 'react-select'
import ConsignmentSelect from '../../../components/ConsignmentSelect'
import EoriSearchInput from '../../../components/EoriSearchInput'
import FormRow, { IAddressForm } from '../../../components/FormRow'
import HsCodeSelect from '../../../components/HsCodeSelect'
import { getSelectCountryFilter } from '../../../components/react-select/CountryFunctions'
import { AccessLevel, DeclarationContext } from '../../../context/DeclarationContext'
import { getSelectValue } from '../../../helpers'
import { FieldUsage, getFieldUsageAccordingToC60 } from '../../../helpers/condition.helper'
import { purgeForbiddenCharacters } from '../../../helpers/string.helper'
import { ISelectOption } from '../../../types/IClassifier'
import { IConsignmentDetail } from '../../../types/IConsignmentDetail'
import { IDeclaration } from '../../../types/IDeclaration'
import { IGoodsItem } from '../../../types/IGoodsItem'
import { InvalidField } from '../../Transits/TransitsAside/detailView'
import { getInvalidIconData } from '../../Transits/TransitsAside/invalid-icon.helper'
import TransitField from '../../Transits/TransitsAside/transitFields'
import { NctsProfileNameOption } from '../../../types/NctsProfileNameResponse'

interface GoodsInfoColumnProps {
  quantityUnitsOptions: ISelectOption[]
  countriesOptions: ISelectOption[]
  currentGoodsItem: IGoodsItem
  setCurrentGoodsItem: React.Dispatch<React.SetStateAction<IGoodsItem>>
  declaration: IDeclaration
  invalidFields: Array<InvalidField>
  restrictedKeys: Array<string>
  nctsProfileNames: Array<NctsProfileNameOption>
  setInvalidFields: Dispatch<SetStateAction<Array<InvalidField>>>
  traderRestrictions: Array<string>
  countryStandardVatRate: number
}

function GoodsInfoColumn(props: GoodsInfoColumnProps) {
  const { metaData: { accessLevel, country } } = useContext(DeclarationContext)
  const {
    currentGoodsItem, setCurrentGoodsItem, declaration, countriesOptions,
    traderRestrictions, countryStandardVatRate, invalidFields,
    setInvalidFields, restrictedKeys, nctsProfileNames, quantityUnitsOptions,
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (getFieldUsageAccordingToC60(currentGoodsItem.quantityUnit, 'QUANTITY') !== FieldUsage.REQUIRED) {
      setCurrentGoodsItem((prevState) => ({
        ...prevState,
        quantity: null,
      }))
    }
    if (getFieldUsageAccordingToC60(currentGoodsItem.quantityUnit, 'MARKS') !== FieldUsage.REQUIRED) {
      setCurrentGoodsItem((prevState) => ({
        ...prevState,
        marks: '',
      }))
    }
    if (getFieldUsageAccordingToC60(currentGoodsItem.quantityUnit, 'PIECES') !== FieldUsage.REQUIRED) {
      setCurrentGoodsItem((prevState) => ({
        ...prevState,
        pieces: null,
      }))
    }
  }, [currentGoodsItem.quantityUnit])

  const handleVatChange = (event: ChangeEvent<HTMLInputElement>) => {
    const asyncValue = event.target.value

    setCurrentGoodsItem((prevState: IGoodsItem) => ({
      ...prevState,
      vatRate: asyncValue ? Number(asyncValue.replace(',', '.')) : undefined,
    }))
  }
  return (
    <div className="pb-5 pb-md-3 pt-4 px-4 col-12 col-xl-6 border-end no-mobile-border">
      <div className="heading">
        <h3>
          {t('declaration.headers.goodsInfo')}
        </h3>
      </div>

      <FormRow title={t('goodsItem.kindOfPackage')}>
        <Select
          isDisabled={accessLevel === AccessLevel.VIEW}
          options={quantityUnitsOptions}
          className={`select ${
            getInvalidIconData(
              [
                TransitField.GOODS_ITEM_PACKAGES_NUMBER_OF_PACKAGES, TransitField.GOODS_ITEM_PACKAGES_KIND_OF_PACKAGES,
              ],
              invalidFields,
            ).isInvalid ? 'form-control p-0 pe-3 is-invalid' : ''
          }`}
          classNamePrefix="select"
          isClearable={false}
          menuPlacement="auto"
          placeholder={t('goodsItem.packageUnitPlaceholder')}
          value={getSelectValue(currentGoodsItem.quantityUnit, quantityUnitsOptions)}
          onChange={(option: ISelectOption | null) => {
            setCurrentGoodsItem((prevState) => ({
              ...prevState,
              quantityUnit: option?.value,
            }))
          }}
        />
      </FormRow>

      {
        getFieldUsageAccordingToC60(currentGoodsItem.quantityUnit, 'QUANTITY') === FieldUsage.REQUIRED
        && (
          <FormRow title={t('goodsItem.numberOfPackages')}>
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className={`form-control ${
                getInvalidIconData(
                  [TransitField.GOODS_ITEM_PACKAGES_NUMBER_OF_PACKAGES, TransitField.GOODS_ITEM_PACKAGES_KIND_OF_PACKAGES],
                  invalidFields,
                ).isInvalid ? 'is-invalid' : ''
              }`}
              type="number"
              pattern={'[\\d]+'}
              max={9999999}
              placeholder={t('common.fieldPlaceholder')}
              value={currentGoodsItem.quantity != null ? currentGoodsItem.quantity : ''}
              onKeyPress={(event) => {
                if (event.key.match('[\\d]+') === null) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if ((`${event.target.value}`).length > 7) {
                  return
                }
                const val = event.target.value
                setCurrentGoodsItem((prevState) => ({
                  ...prevState,
                  quantity: Number(val) ?? null,
                }))
              }}
            />
          </FormRow>
        )
      }
      {
        getFieldUsageAccordingToC60(currentGoodsItem.quantityUnit, 'MARKS') === FieldUsage.REQUIRED
        && (
          <FormRow title={t('goodsItem.marksAndNumbers')}>
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className={`form-control ${
                getInvalidIconData(
                  [TransitField.GOODS_ITEM_PACKAGES_MARKS_NUMBERS_OF_PACKAGES],
                  invalidFields,
                ).isInvalid ? 'is-invalid' : ''
              }`}
              type="text"
              maxLength={42}
              placeholder={t('goodsItem.marksPlaceholder')}
              value={currentGoodsItem.marks !== null ? currentGoodsItem.marks : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const val = event.target.value
                setCurrentGoodsItem((prevState) => ({
                  ...prevState,
                  marks: val ?? null,
                }))
              }}
            />
          </FormRow>
        )
      }

      {
        getFieldUsageAccordingToC60(currentGoodsItem.quantityUnit, 'PIECES') === FieldUsage.REQUIRED
        && (
          <FormRow title={t('goodsItem.numberOfPieces')}>
            <input
              disabled={accessLevel === AccessLevel.VIEW}
              className={`form-control ${
                getInvalidIconData(
                  [TransitField.GOODS_ITEM_PACKAGES_NUMBER_OF_PIECES],
                  invalidFields,
                ).isInvalid ? 'is-invalid' : ''
              }`}
              type="number"
              placeholder={t('common.value')}
              max={99999}
              pattern={'[\\d]+'}
              onKeyPress={(event) => {
                if (event.key.match('[\\d]+') === null) {
                  event.preventDefault()
                  event.stopPropagation()
                }
              }}
              value={currentGoodsItem.pieces !== null ? currentGoodsItem.pieces : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const val = event.target.value
                setCurrentGoodsItem((prevState) => ({
                  ...prevState,
                  pieces: Number(val) ?? null,
                }))
              }}
            />
          </FormRow>
        )
      }
      <FormRow title={t('goodsItem.hsCode')}>
        <HsCodeSelect
          isDisabled={accessLevel === AccessLevel.VIEW}
          code={currentGoodsItem.hsCode ?? null}
          isUsingSharedNctsProfile={nctsProfileNames.find((item) => item.value === declaration.profile)?.isShared ?? false}
          setHsCode={
            (code) => setCurrentGoodsItem((prevState) => ({
              ...prevState,
              hsCode: code,
            }))
          }
          setVisualHsCode={
            (code) => setCurrentGoodsItem((prevState) => ({
              ...prevState,
              visualCode: code,
            }))
          }
          isInvalid={getInvalidIconData(
            [TransitField.GOODS_ITEM_COMMODITY_CODE],
            invalidFields,
          ).isInvalid || (currentGoodsItem.hsCode === null || !currentGoodsItem.hsCode)}
          restrictionKeys={restrictedKeys}
        />
      </FormRow>
      <FormRow
        title={t('goodsItem.description')}
        inputType="textArea"
      >
        <textarea
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData(
              [TransitField.GOODS_ITEM_GOODS_DESCRIPTION],
              invalidFields,
            ).isInvalid || (currentGoodsItem.description === '' || currentGoodsItem.description === null) ? 'is-invalid' : ''
          }`}
          id="goodsItemTextarea"
          placeholder={t('goodsItem.descriptionPlaceholder')}
          maxLength={280}
          rows={3}
          value={currentGoodsItem.description !== null ? currentGoodsItem.description : ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            const val = event.target.value
            setCurrentGoodsItem((prevState) => ({
              ...prevState,
              description: purgeForbiddenCharacters(val),
            }))
          }}
        />
      </FormRow>

      <FormRow title={t('goodsItem.grossWeight')} inputType="short">
        <input
          disabled={accessLevel === AccessLevel.VIEW}
          className={`form-control ${
            getInvalidIconData(
              [TransitField.GOODS_ITEM_GROSS_MASS],
              invalidFields,
            ).isInvalid || (currentGoodsItem.grossWeight === null) ? 'is-invalid' : ''
          }`}
          type="number"
          max={99999999999}
          placeholder={t('goodsItem.grossWeight')}
          value={currentGoodsItem.grossWeight !== null ? currentGoodsItem.grossWeight : ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if ((`${event.target.value}`).length > 11) {
              return
            }
            const val = event.target.value
            setCurrentGoodsItem((prevState) => ({
              ...prevState,
              grossWeight: val ? Number(val.replace(',', '.')) : null,
            }))
          }}
        />
      </FormRow>

      {country === 'PL' ? (
        <FormRow title={t('goodsItem.netWeight')} inputType="short">
          <input
            disabled={accessLevel === AccessLevel.VIEW}
            className={`form-control ${
              getInvalidIconData(
                [TransitField.GOODS_ITEM_NET_MASS],
                invalidFields,
              ).isInvalid || ((currentGoodsItem.netWeight !== null && currentGoodsItem.grossWeight !== null && currentGoodsItem.netWeight > currentGoodsItem.grossWeight)) ? 'is-invalid' : ''
            }`}
            type="number"
            max={99999999999}
            placeholder={t('goodsItem.netWeight')}
            value={currentGoodsItem.netWeight !== null ? currentGoodsItem.netWeight : ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if ((`${event.target.value}`).length > 11) {
                return
              }
              const val = event.target.value
              setCurrentGoodsItem((prevState) => ({
                ...prevState,
                netWeight: val ? Number(val.replace(',', '.')) : null,
              }))
            }}
          />
        </FormRow>
      ) : null }

      {
        country === 'RO' && (
          <>
            <FormRow title={t('goodsItem.goodsItemPrice')} inputType="short">
              <div className="input-group">

                <input
                  disabled={accessLevel === AccessLevel.VIEW}
                  className="form-control"
                  type="number"
                  placeholder={t('goodsItem.goodsItemPrice')}
                  value={currentGoodsItem.price ? currentGoodsItem.price : ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const asyncValue = event.target.value
                    setCurrentGoodsItem((prevState) => ({
                      ...prevState,
                      price: asyncValue ? Number(asyncValue.replace(',', '.')) : undefined,
                    }))
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {t('goodsItem.goodsItemPrice', { context: 'unit' })}
                  </span>
                </div>
              </div>
            </FormRow>
            <FormRow title={t('goodsItem.goodsItemDutyRate')} inputType="short">

              <div className="input-group">
                <input
                  disabled={accessLevel === AccessLevel.VIEW}
                  className="form-control"
                  type="number"
                  placeholder={t('goodsItem.goodsItemDutyRate')}
                  value={currentGoodsItem.dutyRate ? currentGoodsItem.dutyRate : ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const asyncValue = event.target.value
                    setCurrentGoodsItem((prevState: IGoodsItem) => ({
                      ...prevState,
                      dutyRate: asyncValue ? Number(asyncValue.replace(',', '.')) : undefined,
                    }))
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    {t('goodsItem.goodsItemDutyRate', { context: 'unit' })}
                  </span>
                </div>
              </div>
            </FormRow>

            <FormRow title={t('goodsItem.goodsItemVatRate')}>
              <div className="row m-0">
                <div className="col-6 px-0">
                  <div className="input-group">
                    <input
                      disabled={accessLevel === AccessLevel.VIEW}
                      type="number"
                      className="form-control"
                      placeholder={t('goodsItem.goodsItemVatRate')}
                      value={currentGoodsItem.vatRate ? currentGoodsItem.vatRate : ''}
                      onChange={(event) => handleVatChange(event)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        {t('goodsItem.goodsItemVatRate', { context: 'unit' })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-6 ">
                  <button
                    disabled={accessLevel === AccessLevel.VIEW}
                    type="button"
                    className="btn btn-outline-dark d-flex align-items-center"
                    onClick={() => {
                      setCurrentGoodsItem((prevState) => ({
                        ...prevState,
                        vatRate: countryStandardVatRate,
                      }))
                    }}
                  >
                    <span>{t('buttons.useDefault')}</span>
                  </button>
                </div>
              </div>
            </FormRow>
          </>
        )
      }

      {declaration.isGoodsTradersEnabled && (
        <>
          <FormRow title={t('declaration.dispatchCountry')} inputType="short">
            <Select
              isDisabled={accessLevel === AccessLevel.VIEW}
              options={countriesOptions}
              filterOption={getSelectCountryFilter}
              className={`select ${
                getInvalidIconData(
                  [
                    TransitField.GOODS_ITEM_COUNTRY_OF_DISPATCH_EXPORT_CODE,
                  ],
                  invalidFields,
                ).isInvalid || (currentGoodsItem.dispatchCountryCode === ''
                || currentGoodsItem.dispatchCountryCode === null) ? 'form-control p-0 pe-3 is-invalid' : ''
              }`}
              classNamePrefix="select"
              isClearable={false}
              menuPlacement="auto"
              placeholder={t('goodsItem.dispatchCountryPlaceholder')}
              value={
                currentGoodsItem.dispatchCountryCode !== null
                  ? countriesOptions.find((item) => item.value === currentGoodsItem.dispatchCountryCode) : null
              }
              onChange={(option: SingleValue<ISelectOption>) => setCurrentGoodsItem((prevState) => ({
                ...prevState, dispatchCountryCode: option?.value,
              }))}
            />
          </FormRow>
          <FormRow title={t('declaration.destinationCountry')} inputType="short">
            <Select
              isDisabled={accessLevel === AccessLevel.VIEW}
              options={countriesOptions}
              filterOption={getSelectCountryFilter}
              className={`select ${
                getInvalidIconData(
                  [
                    TransitField.GOODS_ITEM_COUNTRY_OF_DESTINATION_CODE,
                  ],
                  invalidFields,
                ).isInvalid || (currentGoodsItem.dispatchCountryCode === ''
                || currentGoodsItem.dispatchCountryCode === null) ? 'form-control p-0 pe-3 is-invalid' : ''
              }`}
              classNamePrefix="select"
              isClearable={false}
              menuPlacement="auto"
              placeholder={t('goodsItem.destinationCountryPlaceholder')}
              value={
                currentGoodsItem.destinationCountryCode !== null
                  ? countriesOptions.find((item) => item.value === currentGoodsItem.destinationCountryCode) : null
              }
              onChange={(option: SingleValue<ISelectOption>) => setCurrentGoodsItem((prevState) => ({
                ...prevState, destinationCountryCode: option?.value,
              }))}
            />
          </FormRow>
        </>
      )}
      {
        declaration.isGoodsTradersEnabled
        && (
          <>
            <div className="heading mt-3">
              <h3>
                {t('declaration.headers.traders')}
              </h3>
            </div>
            <FormRow title={t('declaration.eoriId')}>
              <EoriSearchInput
                form={currentGoodsItem.consignor}
                setForm={(form: IConsignmentDetail) => {
                  setCurrentGoodsItem((prevState) => ({
                    ...prevState,
                    consignor: form,
                  }))
                }}
              />
            </FormRow>
            <FormRow title={t('declaration.consignor')} withSecurity={declaration.isSecurityEnabled === true}>
              <ConsignmentSelect
                type="CONSIGNOR"
                traderRestrictions={traderRestrictions}
                selectForm={currentGoodsItem.consignor}
                setSelectForm={(form: IConsignmentDetail) => {
                  setCurrentGoodsItem((prevState) => ({
                    ...prevState,
                    consignor: form,
                  }))
                }}
                invalidFields={invalidFields}
                setInvalidFields={setInvalidFields}
              />
            </FormRow>
            <FormRow
              title={`${t('declaration.consignor')} ${t('declaration.address').toLowerCase()}`}
              inputType="address"
              addressForm={{
                street: currentGoodsItem.consignor.street,
                city: currentGoodsItem.consignor.city,
                country: currentGoodsItem.consignor.country,
                zip: currentGoodsItem.consignor.zip,
              }}
              setAddressForm={(form: IAddressForm) => {
                setCurrentGoodsItem((prevState) => ({
                  ...prevState,
                  consignor: {
                    ...prevState.consignor,
                    street: form.street,
                    city: form.city,
                    country: form.country,
                    zip: form.zip,
                  },
                }))
              }}
              countriesOptions={countriesOptions}
              invalidFields={invalidFields}
              type="CONSIGNOR"
              isLast
            />

            <FormRow title={t('declaration.eoriId')}>
              <EoriSearchInput
                form={currentGoodsItem.consignee}
                setForm={(form: IConsignmentDetail) => {
                  setCurrentGoodsItem((prevState) => ({
                    ...prevState,
                    consignee: form,
                  }))
                }}
              />

            </FormRow>
            <FormRow title={t('declaration.consignee')} withSecurity={declaration.isSecurityEnabled === true}>
              <ConsignmentSelect
                type="CONSIGNEE"
                selectForm={currentGoodsItem.consignee}
                traderRestrictions={[]}
                setSelectForm={(form: IConsignmentDetail) => {
                  setCurrentGoodsItem((prevState) => ({
                    ...prevState,
                    consignee: form,
                  }))
                }}
                invalidFields={invalidFields}
                setInvalidFields={setInvalidFields}
              />
            </FormRow>
            <FormRow
              title={`${t('declaration.consignee')} ${t('declaration.address').toLowerCase()}`}
              inputType="address"
              addressForm={{
                street: currentGoodsItem.consignee.street,
                city: currentGoodsItem.consignee.city,
                country: currentGoodsItem.consignee.country,
                zip: currentGoodsItem.consignee.zip,
              }}
              setAddressForm={(form: IAddressForm) => {
                setCurrentGoodsItem((prevState) => ({
                  ...prevState,
                  consignee: {
                    ...prevState.consignee,
                    street: form.street,
                    city: form.city,
                    country: form.country,
                    zip: form.zip,
                  },
                }))
              }}
              countriesOptions={countriesOptions}
              invalidFields={invalidFields}
              type="CONSIGNEE"
            />
          </>
        )
      }
    </div>
  )
}

export default GoodsInfoColumn

