import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../common/utils/classifier-util'
import { CL234_PREVIOUS_DOCUMENT_EXCISE } from '../../../common/utils/ncts-constants'
import { AdditionalReference } from '../schemas/commonConsignmentSchemas'

const C0015Validator = {
  validateConsignmentItemAdditionalReferenceRefNumberRequirement: (additionalReference: AdditionalReference): RuleResult => {
    if (CL234_PREVIOUS_DOCUMENT_EXCISE.includes(removePrefix(additionalReference.type, 'ADDITIONAL_REFERENCE_TYPE_') ?? '')) {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0015Validator
