import FormRow from 'components/FormRow'
import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { DeclarationContext } from '../../../context/DeclarationContext'
import { ISelectOption } from '../../../types/IClassifier'
import { useTransportCustomsOffices } from './hooks/useTransportCustomsOffices'
import TransportCustomsOfficeSelect from './TransportCustomsOfficeSelect'

export interface TransportCustomsOfficeProps {
  customsOfficesOptions: Array<ISelectOption>;
  setShouldCleanInvalidTransitCustomsOffices: Dispatch<SetStateAction<boolean>>;
  shouldCleanInvalidTransitCustomsOffices: boolean
}

function TransportCustomsOffice(props: TransportCustomsOfficeProps) {
  const { t } = useTranslation()
  const {
    customsOfficesOptions,
    shouldCleanInvalidTransitCustomsOffices,
    setShouldCleanInvalidTransitCustomsOffices,
  } = props
  const {
    metaData: {
      accessLevel,
    },
    declaration,
  } = useContext(DeclarationContext)

  const {
    rows,
    addItem,
    cleanInvalidTransitCustomsOffices,
  } = useTransportCustomsOffices({ declaration })

  useEffect(() => {
    if (shouldCleanInvalidTransitCustomsOffices) {
      setShouldCleanInvalidTransitCustomsOffices(false)
      cleanInvalidTransitCustomsOffices(customsOfficesOptions)
    }
  }, [shouldCleanInvalidTransitCustomsOffices])

  return (
    <FormRow
      key={`transitCustomsOffices_${declaration.transportCustomsOffices.length}_${rows.length}`}
      title={t('declaration.transitCustomsOffice')}
    >
      {
        rows.map((row) => (
          <TransportCustomsOfficeSelect
            key={`TransportCustomsOffice_row_${row.sortOrder}_${row.transitCustomsOffice}`}
            transportCustomsOffice={row}
            accessLevel={accessLevel}
            customsOfficesOptions={customsOfficesOptions}
          />
        ))
      }
      <button
        type="button"
        className="btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm mt-2"
        onClick={() => {
          addItem()
        }}
      >
        <i className="far fa-plus" />
      </button>
    </FormRow>
  )
}

export default TransportCustomsOffice
