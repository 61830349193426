import React from 'react'
import { useTranslation } from 'react-i18next'
import { TitleBar } from '../../../components/TitleBar'

function AuditTitleBar() {
  const { t } = useTranslation()

  return (
    <TitleBar title={t('pages.page', { context: 'audit' })} />
  )
}

export default AuditTitleBar

