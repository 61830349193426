import { RuleResult } from 'types/DeclarationP5'
import { COUNTRY_CODE_PREFIX, removePrefix } from '../../../common/utils/classifier-util'
import { CL009_COUNTRY_CODES_COMMON_TRANSIT, IS_TRANSITIONAL_PERIOD } from '../../../common/utils/ncts-constants'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { nonNullConsignee } from '../../../common/utils/common-util'

const C0001Validator = {
  validateHouseConsignmentConsigneeRequirement: (formData: DeclarationForm): RuleResult => {
    if (IS_TRANSITIONAL_PERIOD) {
      return 'NOT_ALLOWED' // B1030
    }

    const {
      countryOfDestination,
      security,
      consignee,
    } = formData

    const country = removePrefix(countryOfDestination, COUNTRY_CODE_PREFIX)
    if (country !== null && CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(country)) {
      if (nonNullConsignee(consignee)) {
        return 'NOT_ALLOWED'
      }
      return 'REQUIRED'
    }

    if (['0', '1'].includes(security)) {
      if (nonNullConsignee(consignee)) {
        return 'NOT_ALLOWED'
      }
      return 'OPTIONAL'
    }

    if (nonNullConsignee(consignee)) {
      return 'NOT_ALLOWED'
    }

    return 'OPTIONAL'
  },

  validateConsignmentConsigneeRequirement: (formData: DeclarationForm): RuleResult | null => {
    if (formData.additionalInformation.some((item) => item.code === '30600')) return 'NOT_ALLOWED'
    if (formData.houseConsignment
      .flatMap((houseConsignment) => houseConsignment.additionalInformation)
      .some((item) => item.code === '30600')) {
      return 'NOT_ALLOWED'
    }

    return null
  },
} as const

export default C0001Validator
