import axios from 'axios'
import { apiService, getBaseApiUrl } from './api.service'

async function getDeclarationPDF(declarationId: number) {
  return axios.get(
    apiService.getFullApiUrl(`/pdf/${declarationId}`),
    {
      responseType: 'blob',
    },
  )
}
// TODO convert to useMutation
async function getTransitionPDF(transitionOperationId: number) {
  return axios.get(
    getBaseApiUrl(`/v2/pdf/${transitionOperationId}`),
    {
      responseType: 'blob',
    },
  )
}

const PrintService = {
  getDeclarationPDF,
  getTransitionPDF,
}

export default PrintService
