import React from 'react'
import AuditTitleBar from './components/AuditTitleBar'
import Table from './components/Table'

function DeclarationAudit() {
  return (
    <>
      <AuditTitleBar />
      <Table />
    </>
  )
}

export default DeclarationAudit
