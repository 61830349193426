import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { TitleBar } from '../../../../components/TitleBar'
import ROUTES from '../../../../config/routes'
import { RestrictionType } from '../../../../types/restriction'
import FormRow from '../../../../components/FormRow'
import RestrictionService from '../../services/restriction.service'

function RestrictionsTableTitleBar() {
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [restriction, setRestriction] = useState<RestrictionType>(RestrictionType.COMMODITY_CODE)
  const newRestrictionLink = ROUTES.newRestriction ?? '/404'
  const [loading, setLoading] = useState<boolean>(false)
  const [excelFile, setExcelFile] = useState<File | null>(null)
  const noteFileRef = useRef<HTMLInputElement>(null)

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)
  const submitRestrictionImport = () => {
    if (excelFile === null) return

    setLoading(true)
    RestrictionService.importRestriction(excelFile, restriction)
      .then(() => {
        setExcelFile(null)
        setLoading(false)
        setModalOpen((prevState) => !prevState)
        setRestriction(RestrictionType.COMMODITY_CODE)
        toast.success(t('restriction.importSuccess'))
      }).catch(() => {
        setLoading(false)
      })
  }

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcelFile(null)
    event.preventDefault()

    const file: FileList | null = event.target.files

    if (file && file[0] !== null) {
      if (!file[0].name.endsWith('.xlsx')) {
        toast.error(t('excel.mustBeExcel'))

        return
      }

      setExcelFile(file[0])
    }
  }

  return (
    <>
      <TitleBar title={t('pages.page', { context: 'restrictions' })} isMobileThin>
        <div className="d-flex justify-content-end align-items-center ps-3 w-100">
          <button
            type="button"
            className="btn btn-primary btn-lg d-flex align-items-center text-secondary shadow-sm me-3"
            onClick={() => setModalOpen((prevState) => !prevState)}
          >
            {t('buttons.importRestriction')}
          </button>
          <Link
            to={newRestrictionLink}
            className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
          >
            <i className="far fa-plus me-2" />
            <span>{t('buttons.addNew')}</span>
          </Link>
        </div>
      </TitleBar>
      <Modal
        show={isModalOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setModalOpen((prevState) => !prevState)
          setExcelFile(null)
        }}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-self-center">
            <h2>{t('buttons.importRestriction')}</h2>
          </Modal.Title>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              setModalOpen((prevState) => !prevState)
              setExcelFile(null)
            }}
          >
            <span>Close</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
            <FormRow title={t('restriction.type')}>
              <div className="form-row-special-input">
                <div className="form-check d-flex align-items-center me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="restrictionType"
                    id="commodityCodeType"
                    value="false"
                    checked={restriction === RestrictionType.COMMODITY_CODE}
                    onChange={() => {
                      setRestriction(RestrictionType.COMMODITY_CODE)
                    }}
                  />
                  <label className="form-check-label text-nowrap ps-1" htmlFor="commodityCodeType">
                    {`${t('restriction.commodityCodeType')}`}
                  </label>
                </div>
                <div className="form-check d-flex align-items-center me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="restrictionType"
                    id="descriptionType"
                    value="true"
                    checked={restriction === RestrictionType.COMMODITY_DESCRIPTION}
                    onChange={() => {
                      setRestriction(RestrictionType.COMMODITY_DESCRIPTION)
                    }}
                  />
                  <label className="form-check-label text-nowrap ps-1" htmlFor="descriptionType">
                    {`${t('restriction.commodityDescriptionType')}`}
                  </label>
                </div>
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="restrictionType"
                    id="sanctionType"
                    value="true"
                    checked={restriction === RestrictionType.COMMODITY_SANCTION}
                    onChange={() => {
                      setRestriction(RestrictionType.COMMODITY_SANCTION)
                    }}
                  />
                  <label className="form-check-label text-nowrap ps-1" htmlFor="sanctionType">
                    {`${t('restriction.commoditySanctionType')}`}
                  </label>
                </div>
              </div>
            </FormRow>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <div
                role="presentation"
                className="dropdown-area"
                onClick={() => {
                  if (noteFileRef.current) {
                    noteFileRef.current.click()
                  }
                }}
                onDragOver={(event) => {
                  event.currentTarget.classList.add('file-hover')
                  event.preventDefault()
                }}
                onDragLeave={(event) => {
                  event.currentTarget.classList.remove('file-hover')
                }}
                onDropCapture={(event) => {
                  event.currentTarget.classList.remove('file-hover')

                  event.preventDefault()
                  event.stopPropagation()
                }}
              >
                <input
                  className="d-none"
                  type="file"
                  ref={noteFileRef}
                  onChange={(event) => {
                    if (event.target.files) {
                      uploadFile(event)
                    }
                    event.preventDefault()
                    event.stopPropagation()
                    if (noteFileRef.current) {
                      noteFileRef.current.value = ''
                    }
                  }}
                />
                {excelFile?.type === undefined ? (
                  <>
                    <i className="fal fa-cloud-upload me-2" />
                    <span>
                      {t('buttons.addFiles')}
                    </span>
                  </>
                ) : excelFile.name}

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark btn-lg m-0 text-primary"
            disabled={loading || excelFile === null || restriction === null}
            onClick={submitRestrictionImport}
          >
            {
              loading && loadingSpinner()
            }
            {
              !loading && (
                <span>{t('buttons.save')}</span>
              )
            }
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RestrictionsTableTitleBar
