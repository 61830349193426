import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { AdditionalReference } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'

import { blankAdditionalReference } from '../../form'

const expectedScope = /houseConsignment\.\d+\.consignmentItem\.\d+\.additionalReference\.\d+/g
type AdditionalReferenceItemScope = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.additionalReference.${number}`>
const isAdditionalReferenceItemScope = (scope: ObjectScope):
  scope is `houseConsignment.${number}.consignmentItem.${number}.additionalReference.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function AdditionalReferences({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<AdditionalReference>({
    blankItem: blankAdditionalReference,
    name: scope,
  })

  const [, additionalReferencesOptions] = useCodelist('ADDITIONAL_REFERENCES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-additional-references"
        headingLabel={t('declaration.p5.additionalReferences')}
      >
        {getFields().map((additionalReference, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isAdditionalReferenceItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`additionalReference_${additionalReference.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, additionalReference)}
              sequenceNumber={additionalReference.sequenceNumber + 1}
              hidden={additionalReference.deleted}
            >
              <FormField labelKey="declaration.p5.additionalReferenceType">
                <FormSelect
                  <AdditionalReferenceItemScope>
                  field={`${innerItemScope}.type`}
                  labelKey="declaration.p5.additionalReferenceType"
                  options={additionalReferencesOptions}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.additionalReferenceNumber">
                <Input
                  <AdditionalReferenceItemScope>
                  type="text"
                  field={`${innerItemScope}.referenceNumber`}
                  labelKey="declaration.p5.additionalReferenceNumber"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewReference')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default AdditionalReferences
