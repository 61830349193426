import React, { useContext, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import useTransitOperationDraftDeletion from '../hooks/useTransitOperationDraftDeletion'
import { DeclarationPageResponse } from '../../../../types/DeclarationPageResponse'
import { ISelectOption } from '../../../../types/IClassifier'
import TableRowStatus from '../../../Transits/Table/TableRowStatus'
import TableRowDirectoStatus from '../../../Transits/Table/TableDirectoRowStatus'
import { dateFormatWithTimeOptions } from '../../../../config/dateFormatterConfig'
import { UserContext } from '../../../../context/UserContext'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import CustomerTableService from '../../../CustomerTable/services/customer-table.service'
import FormRow from '../../../../components/FormRow'
import useReassignTransitOperation from '../hooks/useReassignTransitOperation'
import useTransitOperationDuplication from '../hooks/useTransitOperationDuplication'
import useTransitOperationForceStatus from '../hooks/useTransitOperationForceStatus'
import { CodelistResponse } from '../../common/models'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface TableBodyProps {
  isLoading: boolean;
  rows: any;
  prepareRow: any;
  asideAction: (input: boolean, targetId: number, response: DeclarationPageResponse) => void;
  modalAction: (input: boolean, targetId: number, response: DeclarationPageResponse) => void;
  getTableBodyProps: any;
  customsOffices: CodelistResponse[];
  departureCustomOffices: CodelistResponse[];
  refreshFilters: () => void;
}

function TableBody(props: TableBodyProps) {
  const {
    getTableBodyProps, rows, prepareRow, modalAction, asideAction, customsOffices, departureCustomOffices,
  } = props
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  const [lastSeen, setLastSeen] = useState<number>(0)
  const [isOptionsOpen, toggleOptions] = useState(false)
  const [duplicationTransitOperationId, setDuplicationTransitOperationId] = useState<number>()
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [isDeleteLoading, setDeleteLoading] = useState(false)
  const [deleteTransitOperationId, setDeleteTransitOperationId] = useState<number>()
  const [isReassigningModalVisible, setReassigningModalVisible] = useState(false)
  const [currentOwnerOfDeclaration, setCurrentOwnerOfDeclaration] = useState('')
  const [customers, setCustomers] = useState<Array<ISelectOption>>([])
  const [form, setForm] = useState<{ transitOperationId: number | undefined, newCustomer: ISelectOption | undefined }>({
    transitOperationId: undefined,
    newCustomer: undefined,
  })

  const {
    duplicateDeclaration, setDuplicationModalVisible,
    isDuplicationModalVisible, isDuplicationLoading,
  } = useTransitOperationDuplication()
  const { deleteTransitOperationDraft } = useTransitOperationDraftDeletion()
  const { forceStatusForTransitOperation } = useTransitOperationForceStatus()

  const toggleDuplicationModalVisibility = () => {
    if (!isDuplicationLoading) {
      setDuplicationModalVisible(!isDuplicationModalVisible)
    }
  }

  const openReassignmentModal = async (transitOperationId: number, customerName: string) => {
    if (customerName === undefined || customerName === null) {
      toast.error('Customer is null!')

      return
    }
    const loadedCustomers = await CustomerTableService.fetchAllCustomers()
    setReassigningModalVisible(true)
    setCurrentOwnerOfDeclaration(customerName)
    setForm({ newCustomer: undefined, transitOperationId })
    setCustomers(loadedCustomers?.map((item) => ({
      value: item.id.toString(),
      label: `${item.name}`,
    })))
  }

  const openDuplicationModal = (transitOperationId: number) => {
    toggleDuplicationModalVisibility()
    setDuplicationTransitOperationId(transitOperationId)
  }

  const closeReassigningModal = () => {
    setReassigningModalVisible(false)
    setCurrentOwnerOfDeclaration('')
    setForm({ transitOperationId: undefined, newCustomer: undefined })
  }

  const { reassignTransitOperationToNewOwner } = useReassignTransitOperation()

  const confirmReassignment = () => {
    if (form && form.transitOperationId && form.newCustomer) {
      reassignTransitOperationToNewOwner(form.transitOperationId, Number(form.newCustomer.value))
        .then(() => {
          toast.success(t('messages.savingSuccess'))
          closeReassigningModal()
        })
    }
  }

  return (
    <tbody {...getTableBodyProps()} className="position-relative">
      {
        rows.map((row: any, i: number) => {
          prepareRow(row)

          return (
            <React.Fragment key={`${i + row.cells.length}n`}>
              <tr
                style={{
                  color: lastSeen !== 0 && lastSeen === row.original.id && '#1a1b1e',
                  backgroundColor: lastSeen !== 0 && lastSeen === row.original.id && 'rgba(0, 0, 0, 0.075)',
                }}
                {...row.getRowProps()}
              >
                {/* eslint-disable  @typescript-eslint/no-explicit-any */}
                {row.cells.map((cell: any) => (
                  <td
                    key={cell.id}
                    role="presentation"
                    className={
                      `${cell.column.id === 'id' ? 'text-left' : ''} ${
                        (!isOptionsOpen && !(cell.column.id === 'mrn' || cell.column.id === 'id')) ? 'pointer' : ''} ${
                        cell.column.className ? cell.column.className : ''
                      }`
                    }
                    onClick={() => {
                      if (isOptionsOpen) {
                        return toggleOptions(!isOptionsOpen)
                      }

                      if (cell.column.id === 'mrn' || cell.column.id === 'id' || cell.column.id === 'notes' || cell.column.id === 'companyNotes') {
                        if (cell.column.id === 'notes' || cell.column.id === 'companyNotes') {
                          return modalAction(true, row.original.id, row.original)
                        }
                        return null
                      }
                      setLastSeen(row.original.id)
                      return asideAction(true, row.original.id, row.original)
                    }}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...cell.getCellProps()}
                  >
                    {
                      cell.column.id === 'id'
                      && (
                        <div
                          className="d-flex align-items-center justify-content-between border-0 w-100 h-100 p-0 lh-1"
                        >
                          {row.cells[1].row.original.hasProblem === true
                            ? <i className="fal fa-exclamation-triangle me-2 text-danger fa-md" />
                            : ''}
                          <span className="text-decoration-underline">
                            {row.cells[0].render('Cell')}
                          </span>
                        </div>
                      )
                    }
                    {
                      cell.column.id === 'status'
                      && (
                        <TableRowStatus value={row.original.status} />
                      )
                    }
                    {
                      cell.column.id === 'directoStatus'
                      && (
                        <TableRowDirectoStatus value={cell.value} />
                      )
                    }
                    {cell.column.id === 'usesT1Grn' && (
                      <div>{cell.value === false ? t('common.no') : t('common.yes')}</div>
                    )}
                    {
                      (cell.column.id === 'destinationCustomsOffice')
                      && (
                        <span>{cell.value === null ? '-' : customsOffices.find((office) => office.code === cell.value)?.codeLabel }</span>
                      )
                    }
                    {
                      (cell.column.id === 'departureCustomsOffice')
                      && (
                        <span>{cell.value === null ? '-' : departureCustomOffices.find((office) => office.code === cell.value)?.codeLabel }</span>
                      )
                    }
                    {
                      (cell.column.id === 'mrn')
                      && (
                        <div>
                          <div>{row.original.mrn}</div>
                          <div>{row.original.lrn}</div>
                        </div>
                      )
                    }
                    {cell.column.id === 'notes' && user?.role === 'ADMIN' && (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {row.original.notes}
                      </>
                    )}
                    {cell.column.id === 'companyNotes' && user?.role !== 'ADMIN' && (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {row.original.companyNotes}
                      </>
                    )}
                    {
                      (cell.column.id === 'isReviewed')
                      && (
                        <div
                          className={`d-flex justify-content-center align-items-center rounded-circle wh-24 ms-1 ${
                            row.original.isReviewed ? 'text-success' : 'text-danger'
                          }`}
                          style={{ border: '1px solid' }}
                        >
                          <i className={`${row.original.isReviewed ? 'fa-check' : 'fa-times'} far`} />
                        </div>
                      )
                    }
                    {
                      (cell.column.id === 'latestCustomsUpdate')
                      && (
                        <span>
                          {
                            row.original.latestCustomsUpdate !== null
                            // eslint-disable-next-line max-len
                              ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(row.original.latestCustomsUpdate)) : ''
                          }
                        </span>
                      )
                    }
                    {
                      cell.column.id !== 'id'
                      && cell.column.id !== 'mrn'
                      && cell.column.id !== 'status'
                      && cell.column.id !== 'directoStatus'
                      && cell.column.id !== 'departureCustomsOffice'
                      && cell.column.id !== 'destinationCustomsOffice'
                      && cell.column.id !== 'latestCustomsUpdate'
                      && cell.column.id !== 'notes'
                      && cell.column.id !== 'companyNotes'
                      && (
                        cell.render('Cell')
                      )
                    }
                  </td>
                ))}

                <td className="dropdown-cell w-1">
                  <Dropdown
                    onToggle={(isOpen) => toggleOptions(isOpen)}
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="link"
                      className="p-0 hide-caret"
                    >
                      <i className="fas fa-ellipsis-h fa-md" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="shadow">
                      <Dropdown.Item onClick={() => openDuplicationModal(row.original.id)}>
                        <i className="fal fa-clone fa-flip-horizontal me-2 fa-md" />
                        <span>
                          {t('buttons.duplicate')}
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => asideAction(true, row.original.id, row.original)}
                      >
                        <i className="fal fa-align-right me-2 fa-md" />
                        <span>
                          {t('buttons.viewLogs')}
                        </span>
                      </Dropdown.Item>
                      {
                        row.original.status === 'DRAFT'
                        && user?.role === 'ADMIN'
                        && (
                          <Dropdown.Item
                            onClick={() => {
                              openReassignmentModal(row.original.id, row.original.customerName)
                            }}
                          >
                            <i className="fal fa-people-arrows me-2 fa-md" />
                            <span>{t('buttons.reassignOwner')}</span>
                          </Dropdown.Item>
                        )
                      }
                      {
                        row.original.status === 'DRAFT'
                        && (
                          <Dropdown.Item onClick={() => {
                            setDeleteTransitOperationId(row.original.id)
                            setDeleteModalVisible(!isDeleteModalVisible)
                          }}
                          >
                            <i className="fal fa-trash me-2 fa-md" />
                            <span>{t('buttons.delete')}</span>
                          </Dropdown.Item>
                        )
                      }
                      {
                        user?.role === 'ADMIN' && (
                          <Dropdown.Item
                            onClick={() => {
                              forceStatusForTransitOperation(row.original.id)
                            }}
                          >
                            <i className="fal fa-times me-2 fa-md" />
                            <span>{t('buttons.t1Closed')}</span>
                          </Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            </React.Fragment>
          )
        })
      }
      <ConfirmationModal
        title={t('buttons.duplicate')}
        messageBody={t('messages.duplicateDocumentsMessage')}
        isVisible={isDuplicationModalVisible}
        toggleVisibility={toggleDuplicationModalVisibility}
        isLoading={isDuplicationLoading}
        onConfirmation={() => duplicateDeclaration(duplicationTransitOperationId, true)}
        onRejection={() => duplicateDeclaration(duplicationTransitOperationId, false)}
      />
      <ConfirmationModal
        title={t('buttons.delete')}
        messageBody={t('messages.deleteConfirmMessage', { 0: deleteTransitOperationId })}
        isVisible={isDeleteModalVisible}
        toggleVisibility={() => setDeleteModalVisible(!isDeleteModalVisible)}
        isLoading={isDeleteLoading}
        onConfirmation={async () => {
          setDeleteLoading(true)
          await deleteTransitOperationDraft(deleteTransitOperationId)
          setDeleteModalVisible(!isDeleteModalVisible)
          setDeleteLoading(false)
        }}
        onRejection={() => {
          setDeleteModalVisible(!isDeleteModalVisible)
          setDeleteTransitOperationId(undefined)
        }}
      />

      <Modal
        show={isReassigningModalVisible}
        dialogClassName="modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          closeReassigningModal()
        }}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="d-flex align-self-center">
            <h2>{t('declaration.reassignOwner')}</h2>
          </Modal.Title>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => {
              closeReassigningModal()
            }}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <FormRow title={t('declaration.currentOwner')}>
              <div className="input-label justify-content-start ">{currentOwnerOfDeclaration}</div>
            </FormRow>
            <FormRow title={t('declaration.selectNewOwner')}>
              <Select
                options={customers}
                className="select"
                classNamePrefix="select"
                isClearable={false}
                menuPlacement="auto"
                isSearchable={false}
                placeholder={t('declaration.selectNewOwner')}
                onChange={(option: ISelectOption | null) => {
                  if (option !== null) {
                    setForm({
                      newCustomer: customers.find((customer) => customer.value === option.value),
                      transitOperationId: form.transitOperationId,
                    })
                  }
                }}
              />
            </FormRow>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary text-primary btn-lg m-1 mt-2"
              onClick={() => {
                confirmReassignment()
              }}
            >
              <span>{t('buttons.change')}</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </tbody>
  )
}

export default TableBody
