import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Navbar() {
  const { t, i18n } = useTranslation()
  const t1Locale = localStorage.getItem('t1_locale')
  const [currentLanguage, setCurrentLanguage] = useState<string>(t1Locale !== null ? t1Locale : 'EN')
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState<boolean>(false)
  const [isMobileMenuOpen, toggleMobileMenu] = useState<boolean>(false)
  const languageDropdown = () => (
    <Dropdown onToggle={() => setLanguageDropdownOpen(!isLanguageDropdownOpen)}>
      <Dropdown.Toggle
        className="hide-caret pt-2 px-3"
        id="dropdown-basic"
      >
        {currentLanguage}
        <i className={`ps-2 fal ${isLanguageDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="shadow mw-20">
        <Dropdown.Item onClick={() => {
          setCurrentLanguage('EN')
          i18n.changeLanguage('en')
        }}
        >
          EN
        </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          setCurrentLanguage('RU')
          i18n.changeLanguage('ru')
        }}
        >
          RU
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )

  return (
    <nav className="navbar navbar-expand-lg fixed-top bg-primary">
      <div className="container">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="navbar-brand"
          href="#"
        >
          Tardek
        </a>
        <button
          type="button"
          className={`d-block d-md-none btn btn-link ${isMobileMenuOpen ? 'text-dark' : 'text-dark'}`}
          onClick={() => toggleMobileMenu(!isMobileMenuOpen)}
        >
          <i className={`fal fa-2x ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`} />
        </button>
        <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} id="landing-nav">
          <ul className="navbar-nav d-flex align-items-center ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="#functionalities">{t('landing.functionalities')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#integrations">{t('landing.integrations')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#aboutUs">{t('landing.aboutUs')}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">{t('landing.contact')}</a>
            </li>
            <li className="nav-item dropdown">
              {languageDropdown()}
            </li>
            <li className="nav-item ms-lg-4">
              <Link className="nav-link w-100" to="/login">
                {t('buttons.logIn')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
