import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { IGuarantee } from '../../../types/IDeclaration'
import { apiService, handleResponseData } from '../../../services/api.service'

function postNewGuarantee(declarationId: number, guarantee: IGuarantee, isDraft: boolean): Promise<IGuarantee> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl(!isDraft ? '/guarantee' : '/guarantee/draft'),
      {
        declarationId,
        amount: guarantee.amount,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while saving declaration guarantee')
      reject(error)
    })
  })
}

function putGuarantee(declarationId: number, guarantee: IGuarantee, isDraft: boolean): Promise<IGuarantee> {
  return new Promise((resolve, reject) => {
    axios.put(
      apiService.getFullApiUrl(!isDraft ? `/guarantee/${guarantee.id}` : `/guarantee/${guarantee.id}/draft`),
      {
        declarationId,
        amount: guarantee.amount,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.warn('Error while saving declaration guarantee')
      reject(error)
    })
  })
}

const GuaranteeService = {
  postNewGuarantee,
  putGuarantee,
}

export default GuaranteeService
