import React from 'react'
import { useTranslation } from 'react-i18next'
import { ArrayScope, NestedFieldScope, ObjectScope } from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import { CountryOfRoutingOfConsignment } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'

import { blankCountryOfRoutingOfConsignment } from '../../form'
import FormFieldArray from '../../components/FormFieldArray'

const scope: ArrayScope = 'countryOfRoutingOfConsignment' as const
const expectedScope = /countryOfRoutingOfConsignment\.\d+/g
type CountriesOfRoutingItemScope = NestedFieldScope<`countryOfRoutingOfConsignment.${number}`>
const isCountriesOfRoutingItemScope = (unknownScope: ObjectScope): unknownScope is `countryOfRoutingOfConsignment.${number}` => (
  Array.from(unknownScope.matchAll(expectedScope))?.length ?? 0) > 0

function CountriesOfRouting() {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<CountryOfRoutingOfConsignment>({
    blankItem: blankCountryOfRoutingOfConsignment,
    name: scope,
  })
  const [, countriesOptions] = useCodelist('COUNTRIES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-countries-of-routing"
        headingLabel={t('declaration.p5.countriesOfRouting')}
      >
        {getFields().map((countryOfRoutingOfConsignment, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isCountriesOfRoutingItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`countriesOfRouting_${countryOfRoutingOfConsignment.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, countryOfRoutingOfConsignment)}
              sequenceNumber={countryOfRoutingOfConsignment.sequenceNumber + 1}
              hidden={countryOfRoutingOfConsignment.deleted}
            >
              <FormField labelKey="declaration.p5.countryOfRouting">
                <FormSelect
                  <CountriesOfRoutingItemScope>
                  field={`${innerItemScope}.country`}
                  labelKey="declaration.p5.countryOfRouting"
                  options={countriesOptions}
                  type="sync"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewRoutingCountry')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default CountriesOfRouting
