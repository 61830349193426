export interface Customer {
  id: number
  name: string
  registryCode: string
  contactEmail: string
  street: string
  city: string
  zipCode: string
  country: string
  eoriNumber: string
  accessCode: string
  maxGuaranteeAmount: number // BigDecimal
  auditRequiredAmount: number
  notes: string
  status: CustomerStatus | undefined
  documentFilesRequired: boolean
  requiresAudit: boolean
  profileStatus: string
  isApiAccessEnabled?: boolean
  remoteClientCode?: string
  external?: boolean
  guaranteeFormula?: string
}

export interface CurrentCustomer {
  id?: number | null
  name: string
  registryCode: string | undefined
  contactEmail: string | undefined
  street: string | undefined
  city: string | undefined
  zipCode: string | undefined
  country: string | undefined
  status: CustomerStatus | undefined
  profileStatus: string | undefined
  clientApiAccess: boolean | undefined
}

export enum CustomerStatus {
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  ARCHIVED = 'ARCHIVED',
}

export interface CustomerRequirements {
  maxGuaranteeAmount: number
  documentFilesRequired: boolean
}
