import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormFieldArray from '../../components/FormFieldArray'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { PackagingType } from '../../form/schemas/consignmentItemSchema'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'
import useCodelist from '../../../common/hooks/useCodelist'
import { getSelectCountryFilter } from '../../../../../components/react-select/CountryFunctions'

import { blankPackaging } from '../../form'

const expectedScope = /houseConsignment\.\d+\.consignmentItem\.\d+\.packaging\.\d+/g
type PackagingItemScope = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}.packaging.${number}`>
const isPackagingItemScope = (scope: ObjectScope): scope is `houseConsignment.${number}.consignmentItem.${number}.packaging.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function Packaging({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction, removeAction, getFields, arrayRule,
  } = useFieldArrayActionHelper<PackagingType>({
    blankItem: blankPackaging,
    name: scope,
  })
  const [, packagesOptions] = useCodelist('PACKAGES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-packages"
        headingLabel={t('declaration.p5.packages')}
      >
        {getFields().map((packages, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isPackagingItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')
          return (
            <FormFieldArray
              key={`packaging${packages.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, packages)}
              sequenceNumber={packages.sequenceNumber + 1}
              hidden={packages.deleted}
            >
              <FormField labelKey="declaration.p5.typeOfPackages">
                <FormSelect
                  <PackagingItemScope>
                  field={`${innerItemScope}.typeOfPackages`}
                  labelKey="declaration.p5.typeOfPackages"
                  options={packagesOptions}
                  customFilterOption={getSelectCountryFilter}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.numberOfPackages">
                <Input
                  <PackagingItemScope>
                  type="number"
                  field={`${innerItemScope}.numberOfPackages`}
                  labelKey="declaration.p5.numberOfPackages"
                  min={0}
                  placeholder={t('declaration.p5.numberOfPackages')}
                />
              </FormField>
              <FormField labelKey="declaration.p5.shippingMarks">
                <Input
                  <PackagingItemScope>
                  type="textarea"
                  field={`${innerItemScope}.shippingMarks`}
                  labelKey="declaration.p5.shippingMarks"
                  placeholder={t('declaration.p5.shippingMarks')}
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewPackage')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}
export default Packaging
