import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import Functionalities from './components/Functionalities'
import Hero from './components/Hero'
import IntegrationMap from './components/IntegrationMap'
import Navbar from './components/Navbar'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import AboutUs from './components/AboutUs'

function Landing() {
  const location = useLocation()

  return (
    <div className="landing_index">
      <Navbar />
      {
        location.hash !== '#terms-and-conditions' && location.hash !== '#privacy-policy'
        && (
          <>
            <section><Hero /></section>
            <section><Functionalities /></section>
            <section><IntegrationMap /></section>
          </>
        )
      }
      {
        location.hash === '#terms-and-conditions'
        && (
          <section><Terms /></section>
        )
      }
      {
        location.hash === '#privacy-policy'
        && (
          <section><Privacy /></section>
        )
      }
      <section><AboutUs /></section>
      <section><Footer /></section>
    </div>
  )
}

export default Landing
