/* eslint-disable no-unused-vars */
export interface Document {
  id: number | null
  type: DocumentType
  number: string
  documentType: string | null
  procedureType: string | null
  sortOrder: number | null
  declarationId: number | null
  goodsItemId: number | null
  deleted: boolean
  orderNumber: string | null
  transportDocumentId: string | null
  files: Array<DocumentFile>
}
export interface DocumentFile {
  id: number | null
  file: File | null
  uuid: string | null
  filename: string
  mimeType: string
  sortOrder: number
  deleted?: boolean
  url?: string
}

export enum DocumentType {
  GOODS = 'GOODS',
  PREVIOUS = 'PREVIOUS',
  NOTE = 'NOTE',
}
