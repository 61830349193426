import { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { t } from 'i18next'
import { mapEntityToTranslatedString, mapReasonToTranslationString } from '../helpers/errors.helper'
import { BadRequestPayload } from '../types/Errors'
import i18n from '../i18n'

const baseUrl = process.env.REACT_APP_API_URL
const apiVersion = process.env.REACT_APP_API_VERSION

function getApiUrl() {
  return `${baseUrl}/v${apiVersion}`
}

export function getBaseApiUrl(path?: string) {
  return `${baseUrl}${path ?? ''}`
}

export function getAuthHeader(token: string) {
  return {
    Authorization: process.env.REACT_APP_API_AUTHORIZATION_PREFIX + token,
  }
}

function getFullApiUrl(additionalPath: string): string {
  return getApiUrl() + additionalPath
}

export function handleResponseData(response: AxiosResponse) {
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(response.data.data)
  }
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response.data)
  }

  if ([401, 403].indexOf(response.status) !== -1) {
    // automatic logout when Unauthorized or Forbidden response is returned from api
    // eslint-disable-next-line no-console
    console.error(response.status, response.statusText)
  }

  // eslint-disable-next-line no-console
  console.error(`Response returned error ${response.status}`, response.statusText)
  return Promise.reject(Error(`Response returned error ${response.status} >> ${response.statusText}`))
}

export function handleApiException(response: AxiosError) {
  if ([401, 403].indexOf(response.request.status) !== -1) {
    // automatic logout when Unauthorized or Forbidden response is returned from api
  }

  if (response.request.status === 404) {
    const translatedString = mapEntityToTranslatedString(response.response?.data)

    if (translatedString !== '') {
      // @ts-ignore
      toast.error(t('messages.notFoundError', {
        entity: translatedString,
        field: response.response?.data.criteria[0].field.toUpperCase(),
        value: response.response?.data.criteria[0].value,
      }))
    } else if (response.response?.request.data.entity !== 'DirectoEvent') {
      toast.error(
        `Not Found! Please send this ID: ${response.response?.data.data.id} to support!`,
        { autoClose: false, closeOnClick: false, draggable: false },
      )
    }
  }

  if (response.request.status === 400 && response.response?.data.errors && response.response?.data.errors.length !== 0) {
    response.response?.data.errors.forEach((errorItem: BadRequestPayload) => {
      const translatedString = mapReasonToTranslationString(errorItem)
      if (translatedString !== '' && translatedString !== null && translatedString !== undefined) {
        toast.error(`${t(`translations${i18n.language.toUpperCase()}:${errorItem.field}`)} ${translatedString}`)
      } else if (response.request.field === null) {
        toast.error(
          `Bad Request! Please send this ID: ${response.response?.data.id} to support!`,
          { autoClose: false, closeOnClick: false, draggable: false },
        )
      }
    })
  }

  const message = (response.request.response ? JSON.parse(response.request.response).message : null)
  if (response.request.status === 409 && message) {
    toast.error(
      `Bad Request! ${message}`,
      { autoClose: false, closeOnClick: false, draggable: false },
    )
  }

  if (response.request.status === 400 && message) {
    toast.error(
      message,
      { autoClose: false, closeOnClick: false, draggable: false },
    )
  }

  if (response.request.status === 500) {
    toast.error(
      `Please contact support and send this ID: ${response.request.data.id}`,
      { autoClose: false, closeOnClick: false, draggable: false },
    )
  }
  // eslint-disable-next-line no-console
  console.error(`Response error code ${response.request.status}`, response.request.statusText)
  return Promise.reject(response.request.data || `Response returned error ${response.request.status} >> ${response.request.statusText}`)
}

export const apiService = {
  getApiUrl,
  getFullApiUrl,
}
