import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { ConsignmentItem } from '../../schemas/consignmentItemSchema'
import { CL234_PREVIOUS_DOCUMENT_EXCISE } from '../../../../common/utils/ncts-constants'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { excludeDeleted, hasText } from '../../../../common/utils/common-util'

const B1922Validator = {
  isDeclarationTypeCorrectDuringTransitionPeriod: (formData: DeclarationForm, consinmentItemModel: ConsignmentItem): boolean => {
    const additionalReferences = consinmentItemModel.additionalReference.filter(excludeDeleted)

    for (const additionalReference of additionalReferences) {
      const additionalReferenceType = removePrefix(additionalReference.type, 'ADDITIONAL_REFERENCE_TYPE_') ?? ''
      if (CL234_PREVIOUS_DOCUMENT_EXCISE.includes(additionalReferenceType)
        && consinmentItemModel.previousDocument.filter(excludeDeleted).some((previousDocument) => removePrefix(previousDocument.documentType, 'PREVIOUS_DOCUMENT_TYPE_') === 'N830' ?? '')) {
        if (hasText(consinmentItemModel.declarationType)) {
          return consinmentItemModel.declarationType === 'T1'
        }

        return formData.declarationType === 'T1'
      }
    }

    const supportingDocument = consinmentItemModel.supportingDocument.filter(excludeDeleted)
    for (const supportingDocumentElement of supportingDocument) {
      if (CL234_PREVIOUS_DOCUMENT_EXCISE.includes(removePrefix(supportingDocumentElement.documentType, 'SUPPORTING_DOCUMENT_TYPE_') ?? '')) {
        if (hasText(consinmentItemModel.declarationType)) {
          return consinmentItemModel.declarationType === 'T2'
        }

        return formData.declarationType === 'T2' || formData.declarationType === 'T2F'
      }
    }

    return true
  },
}

export default B1922Validator
