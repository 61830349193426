import { z } from 'zod'

const SealSchema = z.object({
  id: z.number().nullable(),
  sequenceNumber: z.number().nonnegative(),
  identifier: z.string(),
  deleted: z.boolean().optional(),
})
const GoodsReferencesSchema = z.object({
  id: z.number().nullable(),
  sequenceNumber: z.number().nonnegative(),
  declarationGoodsItemNumber: z.number(),
  deleted: z.boolean().optional(),
})

const TransportEquipmentSchema = z.object({
  id: z.number().nullable(),
  sequenceNumber: z.number().nonnegative(),
  containerIdentificationNumber: z.string(),
  seals: z.array(SealSchema),
  goodsReferences: z.array(GoodsReferencesSchema),
  deleted: z.boolean().optional(),
})

export type SealType = z.infer<typeof SealSchema>
export type GoodsReferenceType = z.infer<typeof GoodsReferencesSchema>
export type TransportEquipmentType = z.infer<typeof TransportEquipmentSchema>

export default TransportEquipmentSchema
