import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import productImg from '../../../assets/gfx/product-img.png'

function Hero() {
  const { t } = useTranslation()

  return (
    <div className="hero">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center justify-content-center">
            <h1 className="hero__title text-center pt-5 mt-5">{t('landing.heroText')}</h1>
            <h2 className="hero__title--sub text-center">{t('landing.heroSubText')}</h2>
            <Link className="btn btn-secondary btn-large mt-4 px-5 py-3" to="/register">
              {t('landing.heroBtnText')}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center justify-content-center">
            <img className="hero__product-img img-fluid" alt="Hero" src={productImg} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
