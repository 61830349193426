// eslint-disable-next-line import/no-extraneous-dependencies
import memoizeOne from 'memoize-one'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { IClassifier, ISelectOption } from '../../types/IClassifier'
import { getNameForLocale } from '../../helpers'

const stripDiacritics = (input: string) => input.normalize('NFD').replace(/\p{Diacritic}/gu, '')
const memoizedStripDiacriticsForInput = memoizeOne(stripDiacritics)

const trimString = function trimString(str: string) {
  return str.replace(/^\s+|\s+$/g, '')
}

// Initially filters using first two letters then from remaining name
export function getSelectCountryFilter(option: FilterOptionOption<ISelectOption>, rawInput: string): boolean {
  const {
    ignoreAccents, ignoreCase, stringify, trim,
  } = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    stringify: ({ label, value }: ISelectOption) => `${label} ${value}`,
  }

  let input = trim ? trimString(rawInput) : rawInput
  let candidate = trim ? trimString(stringify(option)) : stringify(option)

  if (ignoreCase) {
    input = input.toLowerCase()
    candidate = candidate.toLowerCase()
  }

  if (ignoreAccents) {
    input = memoizedStripDiacriticsForInput(input)
    candidate = stripDiacritics(candidate)
  }

  return input.length < 3
    ? candidate.substring(0, input.length) === input
    : candidate.indexOf(input) > -1
}

export function mapCountryToOption(country: IClassifier, language: string) {
  return ({ value: country.code, label: `${country.codeLabel} - ${getNameForLocale(country, language)}` })
}
