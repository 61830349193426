import { z } from 'zod'

// https://zod.dev/ERROR_HANDLING
// https://zod.dev/?id=refine

// TODO translations - https://github.com/aiji42/zod-i18n perhaps ??
const customErrorMapExample: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === 'string') {
      return { message: 'Type mismatch error!' }
    }
  }

  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMapExample)

export * from './common'
