export interface Restriction {
  id: number | null
  key: string
  description: string
  type: RestrictionType
  modified: string | null
  created: string
  requiresAudit: boolean
  subRows?: Array<Restriction>
}
export enum RestrictionType {
  COMMODITY_CODE = 'COMMODITY_CODE',
  COMMODITY_DESCRIPTION = 'COMMODITY_DESCRIPTION',
  COMMODITY_SANCTION = 'COMMODITY_SANCTION',
  TRADER = 'TRADER',
  TRUCK = 'TRUCK',
}
