import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Badge, Button } from 'react-bootstrap'
import FormRow, { IAddressForm } from '../../../components/FormRow'
import { TitleBar } from '../../../components/TitleBar'
import { UserContext } from '../../../context/UserContext'
import { getNameForLocale, sortByCodeLabel } from '../../../helpers'
import useCountries from '../../../hooks/useCountries'
import ClassifierService from '../../../services/classifier.service'
import { IClassifier, ISelectOption, OfficeRole } from '../../../types/IClassifier'
import { NctsProfileModal } from '../components/NctsProfileModal'
import { CustomerContext } from '../CustomerContext'
import useCurrentCustomer from '../hooks/useCurrentCustomer'
import { blankCustomsProfile, CustomsProfileType } from '../index'
import CustomerService from '../services/customer.service'
import UsersTable from '../UsersTable'
import mapOfficesToSelectOptions from '../../../helpers/classifier.helper'
import { NotificationContext } from '../../../context/NotificationContext'
import ClientApiTokenModal from './ClientApiTokenModal'

function CustomerAdmin() {
  const {
    setCustomer,
    customer,
  } = useCurrentCustomer()
  const [isProfileModalVisible, setIsProfileModalVisible] = useState<boolean>(false)
  const [isApiTokenModalVisible, setIsApiTokenModalVisible] = useState<boolean>(false)
  const [guaranteeTypes, setGuaranteeTypes] = useState<Array<ISelectOption>>([])
  const [guaranteeOffices, setGuaranteeOffices] = useState<Array<ISelectOption>>([])
  const [nctsProfile, setNctsProfile] = useState<CustomsProfileType>(blankCustomsProfile)
  const { contextHeaderNotificationMessage } = useContext(NotificationContext)

  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState<boolean>(false)

  const { t, i18n } = useTranslation()
  const { countries } = useCountries(i18n.language)

  const saveCustomer = () => {
    setLoading(true)
    CustomerService.putCurrentCustomer(customer)
      .then((savedCustomer) => {
        setCustomer(savedCustomer)
        setTimeout(() => {
          toast.success(t('messages.savingSuccess'))
          setLoading(false)
        }, 100)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    ClassifierService.getClassifier('GUARANTEE_TYPES')
      .then((guaranteeClassifiers: IClassifier[]) => {
        const guaranteeTypesResponse = guaranteeClassifiers.sort(sortByCodeLabel)
          .map((item) => ({
            value: item.code,
            label: `${item.codeLabel} - ${getNameForLocale(item, i18n.language)}`,
          }))

        setGuaranteeTypes(guaranteeTypesResponse)
      })

    ClassifierService.getCustomsOfficesByRoles([OfficeRole.OFFICE_ROLE_GUA]).then((classifiers: IClassifier[]) => {
      setGuaranteeOffices(mapOfficesToSelectOptions(classifiers, i18n.language))
    })
  }, [])

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  return (
    <>
      <TitleBar title={t('customers.customer', { context: ('edit') })}>
        <div className="d-flex justify-content-end align-items-center ps-3 w-100">
          <button
            type="button"
            className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
            onClick={saveCustomer}
          >
            {
              loading && loadingSpinner()
            }
            {
              !loading && (
                <span>{t('buttons.save')}</span>
              )
            }
          </button>
        </div>
      </TitleBar>
      <div className={`new-customer__container overflow-auto ${contextHeaderNotificationMessage !== '' ? ' customer__container--notification-open' : ''}`}>
        <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
          <div className="heading">
            <h3>
              {t('customers.companyInfo')}
            </h3>
          </div>
          <FormRow title={t('common.name', { context: 'capitalized' })}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={customer?.name}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  name: event.target.value,
                })
              }}
            />
          </FormRow>
          <FormRow title={t('customers.registryCode')}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={customer?.registryCode}
              disabled={user?.role !== 'ADMIN'}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  registryCode: event.target.value,
                })
              }}
            />
          </FormRow>
          <FormRow title={t('common.email')}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={customer?.contactEmail}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  contactEmail: event.target.value,
                })
              }}
            />
          </FormRow>
          <FormRow
            title={t('customers.address')}
            inputType="address"
            countriesOptions={countries}
            addressForm={{
              street: customer?.street!,
              city: customer?.city!,
              country: customer?.country!,
              zip: customer?.zipCode!,
            }}
            setAddressForm={(form: IAddressForm) => {
              setCustomer({
                ...customer,
                street: form.street,
                city: form.city,
                country: form.country,
                zipCode: form.zip,
              })
            }}
            isLast
          />
          <FormRow title={t('customers.apiTokenAccess')}>
            {
              customer.clientApiAccess !== true && (
                <Badge bg="danger" className="mt-2">{t('customers.apiTokenAccessForbidden')}</Badge>
              )
            }
            {
              customer.clientApiAccess === true && (
                <Button
                  type="button"
                  onClick={() => {
                    setIsApiTokenModalVisible(true)
                  }}
                >
                  <span>
                    {t('customers.apiTokenActions')}
                  </span>
                </Button>
              )
            }

          </FormRow>

        </div>
        {
          customer?.id !== null
          && (
            <CustomerContext.Provider value={
              // eslint-disable-next-line react/jsx-no-constructed-context-values
              {
                guaranteeTypes,
                isNctsProfileModalVisible: isProfileModalVisible,
                setNctsProfileModalVisibility: setIsProfileModalVisible,
                nctsProfile,
                setNctsProfile,
                customerId: customer.id,
              }
            }
            >
              <UsersTable customerId={customer?.id?.toString()} reFetch={undefined} />
              <NctsProfileModal
                isProfileModalVisible={isProfileModalVisible}
                toggleProfileModal={setIsProfileModalVisible}
                customsProfile={nctsProfile}
                setCustomsProfile={setNctsProfile}
                customerId={customer.id}
                guaranteeTypes={guaranteeTypes}
                authorityOffice={guaranteeOffices}
                authorityCountry={countries}
                isSharing={undefined}
              />
              {customer.clientApiAccess === true && (
                <ClientApiTokenModal
                  isVisible={isApiTokenModalVisible}
                  toggleVisibility={() => setIsApiTokenModalVisible(!isApiTokenModalVisible)}
                />
              )}

            </CustomerContext.Provider>
          )
        }
      </div>
    </>
  )
}

export default CustomerAdmin
