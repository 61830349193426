import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import { ISelectOption } from 'types/IClassifier'
import CollapsibleColumn from '../../../components/CollapsibleColumn'
import useFieldArrayActionHelper from '../../../services/useFieldArrayActionHelper'
import { GoodsReferenceType } from '../../../form/schemas/transportEquipmentSchema'
import FormFieldArray from '../../../components/FormFieldArray'
import FormField from '../../../components/FormField'
import FormSelect from '../../../components/FormSelect'

import { blankGoodsReference } from '../../../form'
import { excludeDeleted } from '../../../../common/utils/common-util'
import { DeclarationForm } from '../../../form/schemas/declarationFormSchema'

const expectedScope = /transportEquipment\.\d+\.goodsReferences\.\d+/g
type GoodsReferenceItemScope = NestedFieldScope<`transportEquipment.${number}.goodsReferences.${number}`>
const isGoodsReferenceItemScope = (scope: ObjectScope):
  scope is `transportEquipment.${number}.goodsReferences.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function GoodsReferences({
  scope,
}: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const { getValues } = useFormContext<DeclarationForm>()

  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<GoodsReferenceType>({
    blankItem: blankGoodsReference,
    name: scope,
  })

  const declarationGoodsItemNumberOptions: ISelectOption[] = useMemo(
    () => getValues('houseConsignment')
      .filter(excludeDeleted)
      .flatMap((houseConsignment) => houseConsignment.consignmentItem.filter(excludeDeleted))
      .map((value) => ({
        label:
        `${value.declarationGoodsItemNumber + 1}. ${value.sequenceNumber + 1} ${
          (value.commodityDescriptionOfGoods && value.commodityDescriptionOfGoods?.length > 20)
            ? `${value.commodityDescriptionOfGoods.substring(0, 20)}…` : value.commodityDescriptionOfGoods ?? ''
        }`,
        value: value.declarationGoodsItemNumber,
      })),
    [getValues('houseConsignment')],
  )

  return (
    <>
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-transport-equipment-goods-references"
        headingLabel={t('declaration.p5.goodsReferences')}
      >
        {
          getFields().map((goodsReferenceItem, index) => {
            const innerItemScope: ObjectScope = `${scope}.${index}` as const
            if (!isGoodsReferenceItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

            return (
              <FormFieldArray
                key={`goodsReferenceItem_${goodsReferenceItem.id}`}
                titleKey="declaration.p5.sequenceNumber"
                removeItem={() => removeAction(index, goodsReferenceItem)}
                hidden={goodsReferenceItem.deleted}
                sequenceNumber={goodsReferenceItem.sequenceNumber + 1}
              >
                <FormField labelKey="declaration.p5.declarationGoodsItemNumber">
                  <FormSelect
                    <GoodsReferenceItemScope>
                    field={`${innerItemScope}.declarationGoodsItemNumber`}
                    labelKey="declaration.p5.declarationGoodsItemNumber"
                    options={declarationGoodsItemNumberOptions}
                    type="sync"
                  />

                </FormField>
              </FormFieldArray>
            )
          })
        }
      </CollapsibleColumn>

      <div className="row">
        <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
          <button
            type="button"
            className="btn btn-sm btn-primary mt-2"
            onClick={() => addAction()}
            disabled={arrayRule === 'NOT_ALLOWED'}
          >
            <i className="fa fa-plus me-2" />
            {t('declaration.p5.addNewGoodsReferences')}
          </button>
        </div>
      </div>
    </>
  )
}

export default GoodsReferences
