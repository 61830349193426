import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { sortBySortOrder } from '../../../../../helpers'
import { ISelectOption } from '../../../../../types/IClassifier'
import { DeclarationData } from '../../../hooks/useDeclaration'
import { TransportCustomsOffice } from '../../types/TransportCustomsOffice'

interface UseTransportCustomsOfficesProps {
  declaration: DeclarationData;
}

export interface TransportCustomsOfficeRow {
  readonly id: number | null;
  readonly transitCustomsOffice: string;
  readonly expectedArrival: string | null;
  readonly sortOrder: number;
  readonly transportId: number | null;
  readonly setId: (id: (number | null)) => void;
  readonly setTransitCustomsOffice: (transitCustomsOffice: string) => void;
  readonly setExpectedArrival: (expectedArrival: (string | null)) => void;
  readonly setSortOrder: (sortOrder: number) => void;
  readonly deleteItem: () => void;
}

export function useTransportCustomsOffices(props: UseTransportCustomsOfficesProps) {
  const {
    transportCustomsOffices,
    setTransportCustomsOffices,
  } = props.declaration
  const { t } = useTranslation()

  function replaceObjectByKeyInArray(office: TransportCustomsOffice, updatedOffice: TransportCustomsOffice) {
    if (office.deleted !== true && office.sortOrder === updatedOffice.sortOrder) {
      return updatedOffice
    }
    return office
  }

  function deleteItem(deletedOffice: TransportCustomsOffice, offices: Array<TransportCustomsOffice>) {
    let deletedItemsOffset = 0
    const updatedList = offices
      .reduce((accumulator, currentOffice, currentIndex) => {
        if (currentOffice.deleted) {
          // eslint-disable-next-line no-plusplus
          deletedItemsOffset++
          accumulator.push(currentOffice)
          return accumulator
        }

        if (currentOffice.sortOrder === deletedOffice.sortOrder) {
          // eslint-disable-next-line no-plusplus
          deletedItemsOffset++
          if (deletedOffice.id !== null) {
            // eslint-disable-next-line no-param-reassign
            currentOffice.deleted = true
            accumulator.push(currentOffice)
          }
        } else {
          if (currentOffice.sortOrder !== (currentIndex - deletedItemsOffset)) {
            // eslint-disable-next-line no-param-reassign
            currentOffice.sortOrder = currentIndex - deletedItemsOffset
          }
          accumulator.push(currentOffice)
        }

        return accumulator
      }, [] as Array<TransportCustomsOffice>)

    setTransportCustomsOffices([...updatedList.sort(sortBySortOrder)])
  }

  function updateTransportCustomsOffice(updatedOffice: TransportCustomsOffice) {
    setTransportCustomsOffices([
      ...transportCustomsOffices.map((office) => replaceObjectByKeyInArray(office, updatedOffice)),
    ])
  }

  const rows: Array<TransportCustomsOfficeRow> = useMemo(() => transportCustomsOffices
    .filter((office) => !office.deleted)
    .sort(sortBySortOrder)
    .map((office) => ({
      ...office,
      setId: (id: number | null) => updateTransportCustomsOffice({
        ...office,
        id,
      }),
      setTransitCustomsOffice: (transitCustomsOffice: string) => updateTransportCustomsOffice({
        ...office,
        transitCustomsOffice,
      }),
      setExpectedArrival: (expectedArrival: string | null) => updateTransportCustomsOffice({
        ...office,
        expectedArrival,
      }),
      setSortOrder: (sortOrder: number) => updateTransportCustomsOffice({
        ...office,
        sortOrder,
      }),
      deleteItem: () => deleteItem(office, transportCustomsOffices),
    })), [transportCustomsOffices, transportCustomsOffices.length])

  function addItem() {
    const newItemIndex = transportCustomsOffices.filter((office) => !office.deleted).length ?? 0
    if (newItemIndex > 8) {
      toast.warning(t('transportCustomsOffice.limit'), {})
      return
    }
    setTransportCustomsOffices([...transportCustomsOffices.concat({
      id: null,
      transitCustomsOffice: '',
      expectedArrival: null,
      sortOrder: newItemIndex,
      transportId: null,
      deleted: false,
    })])
  }

  function cleanInvalidTransitCustomsOffices(newCustomsOffices: Array<ISelectOption>) {
    setTransportCustomsOffices([...transportCustomsOffices
      .map((originalTransitOffice) => {
        const transitOffice = { ...originalTransitOffice }
        if (newCustomsOffices.findIndex((office) => office.value === transitOffice.transitCustomsOffice) === -1) {
          transitOffice.transitCustomsOffice = ''
        }

        return transitOffice
      })])
  }

  return {
    rows,
    addItem,
    cleanInvalidTransitCustomsOffices,
  }
}
