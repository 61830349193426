import { useContext, useState } from 'react'
import CustomsProfileService from 'routes/Customer/CustomsProfile/services/customs_profile.service'
import { NctsProfileNameOption, NctsProfileNameResponse } from 'types/NctsProfileNameResponse'
import { UserRole } from 'hooks/useAuth'
import { UserContext } from 'context/UserContext'
import { useLocation } from 'react-router-dom'
import ROUTES from 'config/routes'

function mapResponseToNameOption(response: Array<NctsProfileNameResponse>): Array<NctsProfileNameOption> {
  return response.map(({
    isDefault,
    isShared,
    name,
  }) => ({
    isDefault,
    isShared,
    label: name,
    value: name,
  }))
}

function fetchNctsProfilesByCurrentCustomer() {
  return CustomsProfileService.getAllCustomsProfileForCustomer()
    .then(mapResponseToNameOption)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      return []
    })
}

function fetchNctsProfilesByCustomerId(customerId: number) {
  return CustomsProfileService.getAllCustomsProfileByCustomerId(customerId)
    .then(mapResponseToNameOption)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      return []
    })
}

function useNctsProfileNames() {
  const [nctsProfileNames, setNctsProfileNames] = useState<Array<NctsProfileNameOption>>([])
  const { user } = useContext(UserContext)
  const location = useLocation()

  const loadNctsProfileNames = (customerId?: number | null): Promise<Array<NctsProfileNameOption>> => {
    const isAdmin = user?.role === UserRole.ADMIN
    setNctsProfileNames([])

    if (!isAdmin || location.pathname === ROUTES.newTransit) {
      return fetchNctsProfilesByCurrentCustomer()
        .then((options) => {
          setNctsProfileNames(options)

          return options
        })
    }

    if (customerId === undefined || customerId === null) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Cannot load profiles for customer, missing customer id')
    }

    return fetchNctsProfilesByCustomerId(customerId)
      .then((options) => {
        setNctsProfileNames(options)

        return options
      })
  }

  return {
    nctsProfileNames,
    loadNctsProfileNames,
  }
}

export default useNctsProfileNames
