import axios, { AxiosError } from 'axios'
import { IGoodsItem } from '../../../types/IGoodsItem'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'

function getAllGoods(declarationId: number): Promise<Array<IGoodsItem>> {
  return new Promise((resolve) => {
    axios.get(apiService.getFullApiUrl(`/goods-item/${declarationId}`))
      .then(handleResponseData)
      .then((value) => resolve(value))
      .catch(handleApiException)
  })
}

function postGoodsItem(goodsItem: IGoodsItem, declarationId: number, isDraft: boolean): Promise<IGoodsItem> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl(isDraft ? '/goods-item/draft' : '/goods-item'),
      {
        ...goodsItem,
        declarationId,
      },
    )
      .then(handleResponseData).then((value) => {
        resolve(value)
      }).catch((error: AxiosError) => {
        reject(error)
      })
  })
}

function deleteGoodsItem(goodsItemId: number): Promise<IGoodsItem> {
  return new Promise((resolve, reject) => {
    axios.delete(apiService.getFullApiUrl(`/goods-item/${goodsItemId}`))
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      }).catch((error: AxiosError) => {
      // eslint-disable-next-line no-console
        console.error(`Error while deleting goods item ${goodsItemId}`)
        reject(error)
      })
  })
}

function putGoodsItem(goodsItem: IGoodsItem, declarationId: number, isDraft: boolean): Promise<IGoodsItem> {
  return new Promise((resolve, reject) => {
    axios.put(
      apiService.getFullApiUrl(isDraft ? `/goods-item/${goodsItem.id}/draft` : `/goods-item/${goodsItem.id}`),
      {
        ...goodsItem,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })
}

const GoodsItemService = {
  getAllGoods,
  postGoodsItem,
  deleteGoodsItem,
  putGoodsItem,
}

export default GoodsItemService
