import React, { useEffect, useState } from 'react'
import {
  Button, Dropdown, OverlayTrigger, Popover,
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateRangePicker, RangeKeyDict } from 'react-date-range'
import { enGB } from 'date-fns/locale'
import { DebounceInput } from 'react-debounce-input'
import subMonths from 'date-fns/subMonths'
import { TableFilter } from 'types/PageResponse'
import TransitsTable from './table/Table'
import ROUTES from '../../../config/routes'
import { TitleBar } from '../../../components/TitleBar'
import useCodelist from '../common/hooks/useCodelist'
import Export from '../../Export'
import useUserPreference from './hooks/useUserPreference'
import usePrefetchCodelist from './hooks/usePrefetchCodelist/usePrefetchCodelist'
import NotesModal from '../common/components/NotesModal/NotesModal'
import TableColumnPreferencesModal from './components/TableColumnPreferencesModal'
import TransitSidebar from '../common/components/TransitSidebar'

/* eslint-disable  @typescript-eslint/no-explicit-any */
function TransitOperations() {
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [startDate, setStartDate] = useState<Date>(() => subMonths(endDate, 6))
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [filters, setFilters] = useState<Array<TableFilter>>([])
  const [commodityCodeFilter, setCommodityCodeFilter] = useState<string>('')

  const [originalRow, setOriginalRow] = useState<any>()
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false)
  const [transitOperationId, setTransitOperationId] = useState<number | null>(null)
  const [isExportModalVisible, toggleExportModalVisibility] = useState<boolean>(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)
  const [isTransitSidebarOpen, setIsTransitSidebarOpen] = useState(false)
  const [isDateSelectOpen, setIsDateSelectOpen] = useState<boolean>(false)

  const [customsOffices] = useCodelist('DESTINATION_CUSTOMS_OFFICES')
  const [departureCustomOffices] = useCodelist('DEPARTURE_CUSTOMS_OFFICES')

  const { t } = useTranslation()
  const history = useNavigate()
  const { prefetchCodelists } = usePrefetchCodelist()
  const { userPreferences, setUserPreferences, isPreferencesLoading } = useUserPreference()
  const selectedDateString = `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()} - ${
    endDate.getDate()}.${endDate.getMonth() + 1}.${endDate.getFullYear()}`

  const toggleTransitSidebarVisibility = () => {
    setIsTransitSidebarOpen(!isTransitSidebarOpen)
  }
  const toggleDateSelectVisibility = () => {
    setIsDateSelectOpen(!isDateSelectOpen)
  }

  useEffect(() => {
    prefetchCodelists()
  }, [])

  const changeAside = (input: boolean, transitOperation?: number, tableRow?: unknown) => {
    setTransitOperationId(transitOperation || null)
    if (tableRow) {
      setOriginalRow(tableRow)
    }
    toggleTransitSidebarVisibility()
  }

  const toggleNotesModal = (input: boolean, transitOperation: number, tableRow?: unknown) => {
    setTransitOperationId(transitOperation || null)

    if (tableRow) {
      setOriginalRow(tableRow)
    }

    return setIsNotesOpen(input)
  }

  const handleSelect = ({ selection }: RangeKeyDict) => {
    if (selection?.startDate) {
      setStartDate(selection.startDate)
    }

    if (selection?.endDate) {
      setEndDate(selection?.endDate)
    }
  }

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  }

  const clearFilters = () => {
    setStatusFilter('')
    setFilters([])
    localStorage.setItem('search', JSON.stringify([]))
  }
  const popover = (
    <Popover
      id="DateRangePickerPopover"
      placement="top-end"
    >
      <DateRangePicker
        locale={enGB}
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
    </Popover>
  )

  return (
    <>
      <TitleBar title={t('buttons.transits')}>
        <div
          className="d-flex justify-content-between align-items-center ps-3 w-100"
        >
          <div className="d-flex">
            <OverlayTrigger
              trigger="click"
              placement="auto"
              overlay={popover}
              show={isDateSelectOpen}
            >
              <button
                type="button"
                className="btn btn-light btn-lg d-flex align-items-center rounded-lg datePicker"
                onClick={toggleDateSelectVisibility}
              >
                <i className="fal fa-calendar-alt fa-md me-sm-2" />
                <span className="d-none d-sm-block">
                  {selectedDateString}
                </span>
              </button>
            </OverlayTrigger>

            <Button
              className="mx-2"
              variant="outline-dark"
              size="sm"
              onClick={clearFilters}
            >
              <span>
                {t('buttons.filter', { context: 'clear' })}
              </span>
            </Button>
            <DebounceInput
              className="form-control"
              type="search"
              debounceTimeout={900}
              maxLength={10}
              placeholder={t('goodsItem.hsCode')}
              value={commodityCodeFilter}
              onChange={(event) => setCommodityCodeFilter(event.target.value)}
            />
          </div>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-lg me-4"
              disabled={isPreferencesLoading || userPreferences.length === 0}
              onClick={(() => {
                setIsFilterModalOpen(true)
              })}
            >
              <i className="far fa-filter me-2" />
              <span>
                {t('buttons.filter')}
              </span>
            </button>
            <div className="d-md-block d-xl-none d-lg-none mt-2">
              <Dropdown>
                <Dropdown.Toggle variant="link" className="p-0 hide-caret" id="dropdown-basic">
                  <i className="fas fa-ellipsis-h fa-md" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm">
                  <Dropdown.Item onClick={() => toggleExportModalVisibility(true)}>
                    <i className="far fa-file-excel me-2" />
                    <span>{t('buttons.export')}</span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => history('/new-transit')}>
                    <i className="far fa-plus me-2" />
                    <span>
                      {t('buttons.addNew')}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <button
              type="button"
              className="btn btn-outline-dark btn-lg me-4 d-none d-lg-block"
              onClick={(() => {
                toggleExportModalVisibility(true)
              })}
            >
              <i className="far fa-file-excel me-2" />
              <span>
                {t('buttons.export')}
              </span>
            </button>
            <Link
              to={ROUTES.newTransitP5}
              className="btn btn-secondary btn-lg align-items-center text-primary shadow-sm d-none d-lg-block"
            >
              <i className="far fa-plus me-2" />
              <span>
                {t('buttons.addNew')}
              </span>
            </Link>
          </div>
        </div>
      </TitleBar>
      <TransitsTable
        asideAction={changeAside}
        modalAction={toggleNotesModal}
        customsOffices={customsOffices}
        departureCustomOffices={departureCustomOffices}
        endDate={endDate}
        startDate={startDate}
        tablePreferences={userPreferences}
        commodityCodeFilter={commodityCodeFilter}
        setCommodityCodeFilter={setCommodityCodeFilter}
        filterState={{
          filters,
          setFilters,
        }}
        statusFilterState={{
          statusFilter,
          setStatusFilter,
        }}
      />
      {
        isDateSelectOpen
        && (
          <div
            className="active overlay"
            role="presentation"
            onClick={toggleDateSelectVisibility}
          />
        )
      }

      {(transitOperationId && isNotesOpen) && (
        <NotesModal
          originalRow={originalRow}
          setIsNotesModalOpen={setIsNotesOpen}
          isNotesModalOpen={isNotesOpen}
          transitOperationId={transitOperationId}
        />
      )}
      <Export
        modalVisibilityState={isExportModalVisible}
        setModalVisibility={toggleExportModalVisibility}
        isPhaseFive
      />
      <TableColumnPreferencesModal
        isTableFilterModalOpen={isFilterModalOpen}
        setIsTableFilterModalOpen={setIsFilterModalOpen}
        setUserPreferences={setUserPreferences}
        userPreferences={userPreferences}
        isPreferencesLoading={isPreferencesLoading}
      />
      {
        transitOperationId !== null && (
          <TransitSidebar
            isVisible={isTransitSidebarOpen}
            toggleVisibility={toggleTransitSidebarVisibility}
            transitOperationId={transitOperationId}
            originalRow={originalRow}
          />
        )
      }
    </>
  )
}

export default TransitOperations
