import React from 'react'
import { toast } from 'react-toastify'
import { useTable } from 'react-table'

interface IDocument {
  file: string,
  type: string,
  addedBy: string,
  modifiedOn: string,
}

function Documents() {
  const notify = () => toast.info('Sorry! Not yet implemented.')

  const documentTableHeaders: { Header: string, accessor: string }[] = [
    {
      Header: 'File',
      accessor: 'file',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Added by',
      accessor: 'addedBy',
    },
    {
      Header: 'Modified on',
      accessor: 'modifiedOn',
    },
  ]

  const sampleData: IDocument[] = [
    {
      file: 'Fail1.pdf', type: 'global', addedBy: 'Mari Maasikas', modifiedOn: '20.05.2020 15:00',
    },
    {
      file: 'Fail2.pdf', type: 'goods', addedBy: 'By system', modifiedOn: '21.05.2020 15:00',
    },
    {
      file: 'Fail3.pdf', type: 'global', addedBy: 'Toomas Toomingas', modifiedOn: '22.05.2020 15:00',
    },
    {
      file: 'Fail4.pdf', type: 'global', addedBy: 'Villu Vaarikas', modifiedOn: '23.05.2020 15:00',
    },
    {
      file: 'Fail5.pdf', type: 'goods', addedBy: 'Jaanus Jalakas', modifiedOn: '24.05.2020 15:00',
    },
    {
      file: 'Fail6.pdf', type: 'goods', addedBy: 'Kaarel Kaalikas', modifiedOn: '25.05.2020 15:00',
    },
  ]

  const columns = React.useMemo(() => documentTableHeaders, [])
  const data = React.useMemo(() => sampleData, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } = useTable<any>({ columns, data })

  return (
    <>
      <div className="pt-4 px-4 col-12 col-xxl-8">
        <div className="search input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Enter keyword"
            aria-describedby="searchButton"
          />
          <button
            type="button"
            className="btn"
            id="searchButton"
          >
            <i className="fal fa-search" />
          </button>
        </div>
        <div className="table-responsive">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <table {...getTableProps()} className="table table-sm table-hover table-borderless data-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...cell.getCellProps()}
                        className={cell.column.Header === 'File' ? 'w-33 align-middle p-0' : 'text-left align-middle'}
                      >
                        {cell.column.Header === 'File'
                          ? (
                            <button
                              type="button"
                              className="btn btn-link p-1"
                              onClick={notify}
                            >
                              <i className="fal fa-file fa-md me-2" />
                              {cell.render('Cell')}
                            </button>
                          )
                          : cell.render('Cell')}
                      </td>
                    ))}
                    <td className="text-right align-middle p-0">
                      <button
                        type="button"
                        className="btn btn-link p-1"
                        onClick={notify}
                      >
                        <i className="fal fa-trash fa-md" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-link p-1"
                        onClick={notify}
                      >
                        <i className="fal fa-download fa-md" />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pt-4 px-4 col-12 col-xxl-4">
        <div className="dropdown-area mb-0 h-95">
          <i className="fal fa-cloud-upload fa-2x me-2" />
          <span>Add files</span>
        </div>
      </div>
    </>
  )
}

export default Documents
