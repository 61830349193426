import { IDeclarationInfo } from '../../types/IDeclaration'
import { IAction } from '../actionTypes'

export interface IDeclarationInfoReducerState {
  declarationInfo: IDeclarationInfo | null
}

const initialState: IDeclarationInfoReducerState = {
  declarationInfo: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function declarationInfoReducer(state = initialState, action: IAction<IDeclarationInfo>) {
  switch (action.type) {
    case 'ADD_DECLARATION_INFO':
      return {
        ...state,
        declarationInfo: action.payload,
      }
    default:
      return state
  }
}
