import React from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { DocumentRequestTypeEnum, DocumentResponse } from '../../../models'
import { sortBySequenceNumber } from '../../../../TransitOperationEditor/services/useFieldArrayActionHelper'
import useDocumentApi from '../../../../TransitOperationEditor/hooks/useDocument/api'
import TransitSidebarConsignmentItemDocumentSection from './TransitSidebarConsignmentItemDocumentSection'
import { removePrefix, TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX } from '../../../utils/classifier-util'
import TransitSidebarField from '../TransitSidebarField'

export interface TransitSidebarConsignmentItemTransportDocumentsProps {
  transitOperationId: number
  consignmentItemId: number | null
  isItemOpen: boolean
}

function TransitSidebarConsignmentItemTransportDocuments(props: TransitSidebarConsignmentItemTransportDocumentsProps) {
  const {
    transitOperationId,
    isItemOpen,
    consignmentItemId,
  } = props
  const { t } = useTranslation()

  const {
    getDocuments: getTransportDocuments,
  } = useDocumentApi(transitOperationId, DocumentRequestTypeEnum.TRANSPORT, !isItemOpen)

  const transportDocuments = ((getTransportDocuments.data ?? []) as DocumentResponse[])
    .filter((transportDocument) => transportDocument.consignmentItemId === consignmentItemId)
    .sort(sortBySequenceNumber)

  if (transportDocuments.length === 0 && !getTransportDocuments.isLoading) {
    return null
  }

  return (
    <div className="col-6 p-2">
      <div className="card p-2">
        <h5 className="text-secondary mb-2">
          {
            t('declaration.p5.transportDocuments')
          }
        </h5>
        {
          getTransportDocuments.isLoading && (
            <div className="mx-auto mb-2">
              <LoadingSpinner />
            </div>
          )
        }
        {
          transportDocuments.map((document) => (
            <TransitSidebarConsignmentItemDocumentSection
              key={document.id}
              document={document}
              documentTypePrefix={TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX}
            >
              <div className="col-12">
                <TransitSidebarField
                  label={t('declaration.p5.transportDocumentType')}
                  value={removePrefix(document.documentType, TRANSPORT_DOCUMENT_TYPE_CODE_PREFIX) ?? ''}
                />
                <TransitSidebarField
                  label={t('declaration.p5.transportDocumentReferenceNumber')}
                  value={document.referenceNumber ?? ''}
                />
              </div>
            </TransitSidebarConsignmentItemDocumentSection>
          ))

        }
      </div>
    </div>
  )
}

export default TransitSidebarConsignmentItemTransportDocuments
