import React, { ChangeEvent, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { ISelectOption } from 'types/IClassifier'
import LoadingSpinner from 'components/LoadingSpinner'
import { getBaseApiUrl, handleResponseData } from 'services/api.service'

interface FinlandContactFormProps {
  isVisible: boolean,
  toggleVisibility: () => void,
  transitOperationId: number | null
}

interface ContactFormProps {
  reason: string,
  description: string
}

function FinlandContactFormModal(props: Readonly<FinlandContactFormProps>) {
  const reasonSelectOptions: ISelectOption[] = [
    {
      value: '002',
      label: '002 - Invalidation request',
    },
    {
      value: '003',
      label: '003 - Request for time-limit extension',
    },
    {
      value: '004',
      label: '004 - Error notification response; I do not see the same need to amend the declaration details',
    },
    {
      value: '007',
      label: '007 - Bill of discharge / notification of discharge',
    },
    {
      value: '008',
      label: '008 - Notification on re-export of temporary importation or placing under other customs procedure (OSI)',
    },
    {
      value: '010',
      label: '010 - Response for hearing request, approval of the proposal',
    },
    {
      value: '011',
      label: '011 - Cancellation request for the final settlement / notification of the termination of the procedure',
    },
  ]

  const [form, setForm] = useState<ContactFormProps>({
    reason: '',
    description: '',
  })

  const [loading, setLoading] = useState(false)

  const { isVisible, toggleVisibility, transitOperationId } = props

  const { t } = useTranslation()

  const submitForm = () => {
    if (!transitOperationId) return null
    if (form.description === '' || form.reason === '') {
      toast.error('Description and reason are mandatory!')

      return null
    }

    setLoading((prevState) => !prevState)
    return new Promise((resolve, reject) => {
      axios.post(
        getBaseApiUrl('/v2/transit-operation/customs/contact'),
        {
          id: transitOperationId,
          notificationDescription: form.description,
          notificationReason: form.reason,
        },
      )
        .then(handleResponseData)
        .then((value) => {
          resolve(value)
          setLoading((prevState) => !prevState)
        })
        .catch((error: AxiosError) => {
          reject(error)
          setLoading((prevState) => !prevState)
        })
    })
  }

  return (
    <Modal
      show={isVisible}
      dialogClassName="finland-contact-modal modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="backdrop-with-modal"
      onHide={() => toggleVisibility()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{t('declaration.p5.contactForm')}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-self-center w-100">
          <Select
            placeholder={t('declaration.p5.notificationReason')}
            options={reasonSelectOptions}
            className="select w-100 mb-4"
            classNamePrefix="select"
            menuPlacement="auto"
            value={reasonSelectOptions.find((option) => option.value === form.reason) ?? null}
            onChange={(option: ISelectOption | null) => {
              setForm({ ...form, reason: option?.value })
            }}
            isClearable
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-column align-self-center w-100 mb-1">
          <textarea
            placeholder={t('declaration.p5.notificationDescription')}
            className="w-100 form-control mb-2"
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setForm({ ...form, description: e.target.value })}
          />
        </div>
        <div className="d-flex align-content-end justify-content-end mt-5">
          <button
            type="button"
            className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
            onClick={submitForm}
          >
            {
              loading && <LoadingSpinner />
            }
            {
              !loading && (<span>{t('notes.submit')}</span>)
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FinlandContactFormModal
