import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrayScope, NestedFieldScope, ObjectScope, ScopeProps,
} from 'types/DeclarationP5'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { AdditionalSupplyChainActor } from '../../form/schemas/commonConsignmentSchemas'
import useFieldArrayActionHelper from '../../services/useFieldArrayActionHelper'

import { blankAdditionalSupplyChainActor } from '../../form'
import FormFieldArray from '../../components/FormFieldArray'

// eslint-disable-next-line max-len
const expectedScope = /(^additionalSupplyChainActor\.\d+$)|(houseConsignment\.\d+\.additionalSupplyChainActor\.\d+)|(houseConsignment\.\d+\.consignmentItem\.\d+\.additionalSupplyChainActor\.\d+)/g
const isAdditionalSupplyChainActorItemScope = (scope: ObjectScope): scope is `additionalSupplyChainActor.${number}`
  | `houseConsignment.${number}.additionalSupplyChainActor.${number}` | `houseConsignment.${number
}.consignmentItem.${number}.additionalSupplyChainActor.${number}` => (Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

type ConsignmentSupplyChainActor = NestedFieldScope<`additionalSupplyChainActor.${number}`>
type HouseSupplyChainActor = NestedFieldScope<`houseConsignment.${number}.additionalSupplyChainActor.${number}`>
type ConsignmentItemSupplyChainActor = NestedFieldScope<
  `houseConsignment.${number}.consignmentItem.${number}.additionalSupplyChainActor.${number}`>
type AdditionalSupplyChainActorItemScope = ConsignmentSupplyChainActor | HouseSupplyChainActor | ConsignmentItemSupplyChainActor

function AdditionalSupplyChainActors({ scope }: ScopeProps<ArrayScope>) {
  const { t } = useTranslation()
  const {
    addAction,
    removeAction,
    getFields,
    arrayRule,
  } = useFieldArrayActionHelper<AdditionalSupplyChainActor>({
    blankItem: blankAdditionalSupplyChainActor,
    name: scope,
  })

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-supply-chain-actor"
        headingLabel={t('declaration.p5.additionalSupplyChainActor')}
      >
        {getFields().map((additionalSupplyChainActor, index) => {
          const innerItemScope: ObjectScope = `${scope}.${index}` as const
          if (!isAdditionalSupplyChainActorItemScope(innerItemScope)) throw Error('Unable to narrow, invalid scope')

          return (
            <FormFieldArray
              key={`additionalSupplyChainActor_${additionalSupplyChainActor.id}`}
              titleKey="declaration.p5.sequenceNumber"
              removeItem={() => removeAction(index, additionalSupplyChainActor)}
              sequenceNumber={additionalSupplyChainActor.sequenceNumber + 1}
              hidden={additionalSupplyChainActor.deleted}
            >
              <FormField labelKey="declaration.p5.role">
                <FormSelect
                  <AdditionalSupplyChainActorItemScope>
                  field={`${innerItemScope}.role`}
                  labelKey="declaration.p5.role"
                  options={[]}
                  type="sync"
                />
              </FormField>
              <FormField labelKey="declaration.p5.actorIdentificationNr">
                <Input
                  <AdditionalSupplyChainActorItemScope>
                  type="text"
                  field={`${innerItemScope}.identificationNumber`}
                  labelKey="declaration.p5.actorIdentificationNr"
                />
              </FormField>
            </FormFieldArray>
          )
        })}
        <div className="row">
          <div className={`col-12 text-end ${arrayRule === 'NOT_ALLOWED' ? 'cursor--not-allowed' : ''}`}>
            <button
              type="button"
              className="btn btn-sm btn-primary mt-2"
              onClick={() => addAction()}
              disabled={arrayRule === 'NOT_ALLOWED'}
            >
              <i className="fa fa-plus me-2" />
              {t('declaration.p5.addNewActor')}
            </button>
          </div>
        </div>
      </CollapsibleColumn>
    </section>
  )
}

export default AdditionalSupplyChainActors
