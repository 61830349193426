import { ITransport } from '../../types/IDeclaration'
import { IAction } from '../actionTypes'

export interface ITransportReducerState {
  transport: ITransport | null
}

const initialState: ITransportReducerState = {
  transport: null,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function transportReducer(state = initialState, action: IAction<ITransport>) {
  switch (action.type) {
    case 'ADD_TRANSPORT':
      return {
        ...state,
        transport: action.payload,
      }
    default:
      return state
  }
}
