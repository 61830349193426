import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { excludeDeleted, hasText } from '../../../common/utils/common-util'

const C0670Validator = {
  validateGoodsReferenceRequirement: (formData: DeclarationForm): RuleResult => {
    const equipment = formData.transportEquipment.filter(excludeDeleted)
    if (equipment.length === 1 && hasText(equipment.at(0)?.containerIdentificationNumber)) {
      return 'OPTIONAL'
    }
    return 'REQUIRED'
  },
} as const

export default C0670Validator
