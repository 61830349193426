import React from 'react'

export interface TitleBarProps {
  title: string;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  isMobileThin?: boolean;
}

export function TitleBar({ title, children, isMobileThin }: TitleBarProps) {
  return (
    <div className={`title-bar ${isMobileThin ? 'thin-mobile-title-bar' : ''}`}>
      <div className="d-flex align-items-center">
        <h1 className="border-end pe-3">
          {title}
        </h1>
      </div>
      {children}
    </div>
  )
}
