import { z } from 'zod'

const FileSchema = z.object({
  id: z.number().nullable(),
  fileName: z.string().nullable(),
  documentId: z.number().nullable(),
  mimeType: z.string(),
  sequenceNumber: z.number().default(0),
  deleted: z.boolean(),
  uuid: z.string().nullable(),
  file: z.custom<File>().nullable(),
  fileBlob: z.custom<Blob>().nullable(),
  url: z.string().nullable(),
})

export type FileType = z.infer<typeof FileSchema>

export default FileSchema
