import { UseDeclarationFormReturn } from '../../../form'
import useRequestReleaseApi from './api'
import { extractCountryFromDepartureOffice } from '../../../../common/utils/classifier-util'

function useRequestReleaseAction(form: UseDeclarationFormReturn) {
  const {
    getValues,
  } = form
  const { decide } = useRequestReleaseApi()

  const releaseTransitOperation = (transitOperationId: number, isAcceptedRelease: boolean) => {
    const formData = getValues()
    const departureCountry = extractCountryFromDepartureOffice(formData.departureCustomsOffice)
    if (departureCountry !== 'RO') {
      return Promise.reject(Error('Country is not supported'))
    }
    if (formData.status !== 'CONTROL_DECISION') {
      return Promise.reject(Error('Status is not supported'))
    }

    return decide.mutateAsync({ id: transitOperationId, isAcceptedRelease })
  }

  return {
    releaseTransitOperation,
  }
}

export default useRequestReleaseAction
