import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Document, DocumentFile } from '../../../types/Document'
import { AccessLevel } from '../../../context/DeclarationContext'
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface DocumentDropdownAreaProps {
  document: Document,
  accessLevel: AccessLevel,
  setFieldValue: (field: 'number' | 'documentType' | 'procedureType' | 'sortOrder' | 'files' | 'orderNumber',
    newValue: any,
    row: Document,
    selectOption?: boolean) => void,
  generateNameForUploadedFile: (name: string) => string
}

function DocumentDropdownArea(props: DocumentDropdownAreaProps) {
  const {
    document, accessLevel, setFieldValue, generateNameForUploadedFile,
  } = props
  const UPLOAD_FILE_MAX_BYTES = 5242880 // 5*1024*1024
  function addNewFiles(droppedFiles: FileList, parentDocument: Document) {
    const changedFiles = parentDocument.files === undefined ? [] : [...parentDocument.files]
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles.item(i)
      if (!file) {
        toast.error('Error accessing file')
      } else if (file.size > UPLOAD_FILE_MAX_BYTES) {
        toast.error(`File '${file.name}' size is too large. Maximum allowed size - ${UPLOAD_FILE_MAX_BYTES / 1024 / 1024} MiB`)
      } else {
        const newFile: DocumentFile = {
          id: null,
          sortOrder: changedFiles.length,
          deleted: false,
          filename: generateNameForUploadedFile(file.name),
          uuid: null,
          mimeType: file.type,
          file,
        }
        changedFiles.push(newFile)
        setFieldValue('files', changedFiles, parentDocument)
      }
    }
  }

  const { t } = useTranslation()

  const fileRef = useRef<HTMLInputElement>(null)
  return (
    <div className="row mb-5" key={`documentBodyDropArea${document.sortOrder}`}>
      <div className="col-12">
        <div
          role="presentation"
          hidden={accessLevel === AccessLevel.VIEW}
          className="dropdown-area"
          onClick={() => {
            if (fileRef.current && accessLevel !== AccessLevel.VIEW) {
              fileRef.current.click()
            }
          }}
          onDragOver={(event) => {
            event.currentTarget.classList.add('file-hover')
            event.preventDefault()
          }}
          onDragLeave={(event) => {
            event.currentTarget.classList.remove('file-hover')
          }}
          onDropCapture={(event) => {
            event.currentTarget.classList.remove('file-hover')

            addNewFiles(event.dataTransfer.files, document)

            event.preventDefault()
            event.stopPropagation()
          }}
        >
          <input
            disabled={accessLevel === AccessLevel.VIEW}
            className="d-none"
            type="file"
            ref={fileRef}
            onChange={(event) => {
              if (event.target.files) {
                addNewFiles(event.target.files, document)
              }
              event.preventDefault()
              event.stopPropagation()
            }}
          />
          <i className="fal fa-cloud-upload me-2" />
          <span>
            {t('buttons.addFiles')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default DocumentDropdownArea
