import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import TransitApiConfig from '../../apiConfig'

const {
  baseUrl,
} = TransitApiConfig.paths.transitOperation

function useRequestReleaseApi() {
  const decide = useMutation({
    mutationFn: async (request: { id: number; isAcceptedRelease: boolean }) => (
      await axios.post<void>(`${baseUrl}/customs/release`, request)).data,
  })

  return {
    decide,
  }
}

export default useRequestReleaseApi
