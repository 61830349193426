import React, { useEffect, useState } from 'react'
import PrivacyService, { PrivacyPolicy } from 'routes/Settings/service/privacy.service'
import parse from 'html-react-parser'

function Privacy() {
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>({
    id: 0,
    content: '',
  })

  useEffect(() => {
    PrivacyService.getPrivacyTerms(localStorage.getItem('t1_locale') || 'en')
      .then((response) => {
        setPrivacyPolicy(response)
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }, [localStorage.getItem('t1_locale')])

  return (
    <div className="privacy-policy" id="privacy-policy">
      <div className="container">
        {
          privacyPolicy.content === '' && (
            <div className="row pt-5 my-5 text-center">
              <div className="col-12 privacy-policy__content ">
                <i className="fa fa-spinner fa-spin fa-2x" />
              </div>
            </div>
          )

        }
        {
          privacyPolicy.content !== '' && (
            <div className="row my-5">
              <div className="col-12 privacy-policy__content my-5">
                {
                  parse(privacyPolicy.content)
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Privacy
