import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'components/LoadingSpinner'

interface NotificationConfirmationModalProps {
  loading: boolean;
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  submitNotification: () => void;
}

function NotificationConfirmationModal({
  isConfirmationModalOpen,
  setIsConfirmationModalOpen,
  loading,
  submitNotification,
}: NotificationConfirmationModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      show={isConfirmationModalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setIsConfirmationModalOpen(false)}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-capitalize">{t('notification.send')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => setIsConfirmationModalOpen(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="font-weight-bold">{t('notification.placeholder')}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={loading}
          className="btn btn-outline-dark btn-lg m-0 me-5"
          onClick={() => {
            setIsConfirmationModalOpen(false)
          }}
        >
          {loading ? <LoadingSpinner /> : <span>{t('buttons.cancel')}</span>}
        </button>
        <button
          type="button"
          disabled={loading}
          className="btn btn-dark btn-lg m-0 text-primary"
          onClick={() => submitNotification()}
        >
          {loading ? <LoadingSpinner /> : <span>{t('notification.send')}</span>}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NotificationConfirmationModal
