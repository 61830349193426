import axios, { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useObservableState } from 'observable-hooks'
import { Observable } from 'rxjs'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { apiService, handleResponseData } from '../../../../../../services/api.service'
import { ISelectOption } from '../../../../../../types/IClassifier'
import CustomerService from '../../../../../Customer/services/customer.service'
import { Customer } from '../../../../../../types/Customer'

export interface CustomerSearchResponse {
  id: number
  name: string;
  registryCode: string;
  remoteClientCode: string;
  note: string;
}
export interface UseDirectoCustomerSearchProps {
  initialCustomerId: number | null
}

function useDirectoCustomerSearch(props: UseDirectoCustomerSearchProps) {
  const { initialCustomerId } = props
  const [isLoading, setLoading] = useState(false)
  const [isSearching, setSearching] = useState(false)
  const [directoClients, setDirectoClients] = useState<CustomerSearchResponse[]>([])
  const [searchQueryBuffer, setSearchQueryBuffer] = useState<string | undefined>()
  const [valueBuffer, setValueBuffer] = useState<ISelectOption | null>(null)
  const [searchingBuffer, setSearchingBuffer] = useState<ISelectOption[]>([])
  const [initialCustomer, setInitialCustomer] = useState<ISelectOption | null>(null)
  const searchingBufferRef = useRef(searchingBuffer)
  const isSearchingRef = useRef(isSearching)

  const mapToOption = (customer: Customer) => ({
    value: customer.id,
    label: `${customer.name} - ${customer.remoteClientCode ?? ''}`,
  })

  useEffect(() => {
    if (initialCustomerId === null
      || initialCustomerId === undefined
      || initialCustomer !== null) {
      return
    }

    CustomerService.getCustomer(initialCustomerId)
      .then((response) => {
        const initialCustomerOption = mapToOption(response)
        setValueBuffer(initialCustomerOption)
        setInitialCustomer(initialCustomerOption)
        setDirectoClients([{
          id: response.id,
          name: response.name,
          note: response.notes,
          registryCode: response.registryCode,
          remoteClientCode: response.remoteClientCode ?? '',
        }])
      })
      .catch(() => {
      })
  }, [initialCustomerId])

  useEffect(() => {
    searchingBufferRef.current = searchingBuffer
    isSearchingRef.current = isSearching
  })

  const searchForOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolve, reject) => {
    if (isLoading) {
      return
    }
    setLoading(true)

    axios.get(
      apiService.getFullApiUrl('/customer/search'),
      {
        params: {
          searchQuery: inputValue,
          size: 300,
        },
      },
    )
      .then(handleResponseData)
      .then((response) => {
        const content = [...response.content]

        setDirectoClients([...response.content])
        resolve(content.map(mapToOption))
      })
      .catch((error: AxiosError) => {
        reject(error)
      })
      .finally(() => {
        setLoading(false)
      })
  })

  const [searchResults, invokeSearch] = useObservableState(
    (event$: Observable<string>) => event$.pipe(
      debounceTime(200),
      tap(() => setLoading(true)),
      switchMap((requestEvent) => searchForOptions(requestEvent)),
    ),
    () => ([]),
  )

  useEffect(() => {
    if (isSearching) {
      setSearching(false)
      setSearchingBuffer([...searchResults])
    }
  }, [searchResults])

  const waitForResults = () => {
    let interval: NodeJS.Timeout
    return new Promise<ISelectOption[]>((resolve) => {
      interval = setInterval(() => {
        if (!isSearchingRef.current) {
          clearInterval(interval)
          resolve(searchingBufferRef.current)
        }
      }, 10)
    })
  }

  const loadOptions = (inputValue: string) => new Promise<ISelectOption[]>((resolveQuery) => {
    if (inputValue === '' || inputValue === null) {
      resolveQuery([])
      return
    }
    setSearching(true)
    invokeSearch(inputValue)
    waitForResults().then((results) => {
      resolveQuery(results)
    })
  })

  return {
    loadOptions,
    searchQueryBuffer,
    setSearchQueryBuffer,
    valueBuffer,
    setValueBuffer,
    directoClients,
  }
}

export default useDirectoCustomerSearch
