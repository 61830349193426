import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Customer, CustomerStatus } from 'types/Customer'
import ROUTES from '../../../config/routes'
import TableCustomerStatusRow from './TableCustomerStatusRow'
// eslint-disable-next-line import/no-cycle
import { CustomerCountsData } from '../index'
import { dateFormatWithTimeOptions } from '../../../config/dateFormatterConfig'
import CustomerTableService, { CustomerPageResponse } from '../services/customer-table.service'
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface TableRowProps {
  row: any;
  index: number;
  refreshPage: () => void;
  customerCounts: CustomerCountsData | undefined;
}

function TableRow(props: TableRowProps) {
  const {
    row,
    refreshPage,
    customerCounts,
  } = props
  const history = useNavigate()
  const { t } = useTranslation()
  const [isOptionsOpen, setOptionsOpen] = useState<boolean>(false)
  const [savingStatus, setSavingStatus] = useState<boolean>(false)

  const editRow = (customer: Customer) => {
    if (customer.external === true) {
      history(`${ROUTES.externalCustomer}/${customer.id}`)
    } else {
      history(`${ROUTES.customer}/${customer.id}`)
    }
  }

  const changeCustomerStatus = (customerRow: CustomerPageResponse) => {
    if (savingStatus) {
      return
    }
    setSavingStatus(true)
    let newCustomerStatus: CustomerStatus = CustomerStatus.BLOCKED
    if (CustomerStatus.BLOCKED === customerRow.status) {
      newCustomerStatus = CustomerStatus.ACCEPTED
    }

    CustomerTableService.patchCustomerStatus({ customerId: customerRow.id, status: newCustomerStatus })
      .then(() => {
        toast.success(t('messages.customerEdit', { context: 'successful' }))
        setSavingStatus(false)
        refreshPage()
      }).catch(() => {
        toast.error(t('messages.customerEdit', { context: 'failed' }))
        setSavingStatus(false)
      })
  }

  const dateFormatted = (date: string) => (
    date != null && date !== ''
      ? new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(date)) : '')

  return (
    <React.Fragment key={`${row.id}_row`}>
      <tr
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...row.getRowProps()}
      >
        {row.cells.map((cell: any) => (
          <td
            role="presentation"
            className={
              `${cell.column.id === 'id' ? 'text-left' : ''} ${
                (!isOptionsOpen && cell.column.id !== 'id') ? 'pointer' : ''} ${
                cell.column.className ? cell.column.className : ''
              }`
            }
            onClick={() => {
              if (isOptionsOpen
              ) {
                return setOptionsOpen(!isOptionsOpen)
              }

              if (cell.column.id === 'id') {
                return null
              }
              return editRow(row.original)
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...cell.getCellProps()}
          >
            {cell.column.id === 'status' && (
              <TableCustomerStatusRow value={cell.value} />
            )}
            {
              cell.column.id === 'id'
              && (
                <button
                  type="button"
                  className="btn btn-link no-underline d-flex align-items-center justify-content-between
                           border-0 w-100 h-100 p-0 lh-1"
                  onClick={() => editRow(row.original)}
                >
                  <span className="text-decoration-underline">
                    {row.cells[0].render('Cell')}
                  </span>
                </button>
              )
            }
            {
              cell.column.id === 'declarationsCount'
              && (
                <span
                  role="presentation"
                  onClick={() => editRow(row.original)}
                >
                  {
                    customerCounts?.loading
                    && (
                      <i className="fal fa-spinner fa-spin me-2" />
                    )
                  }
                  {
                    !customerCounts?.loading && customerCounts?.declarationsCount !== undefined
                    && (
                      customerCounts?.declarationsCount
                    )
                  }
                </span>
              )
            }
            {
              cell.column.id === 'usersCount'
              && (
                <span
                  role="presentation"
                  onClick={() => editRow(row.original)}
                >
                  {
                    customerCounts?.loading
                    && (
                      <i className="fal fa-spinner fa-spin me-2" />
                    )
                  }
                  {
                    !customerCounts?.loading && customerCounts?.usersCount !== undefined
                    && (
                      customerCounts?.usersCount
                    )
                  }
                </span>
              )
            }
            {
              (cell.column.id === 'external')
              && (
                <div
                  className={`d-flex justify-content-center align-items-center rounded-circle wh-24 ms-1 ${
                    row.original.external ? 'text-success' : 'text-danger'
                  }`}
                  style={{ border: '1px solid' }}
                >
                  <i className={`${row.original.external ? 'fa-check' : 'fa-times'} far`} />
                </div>
              )
            }

            {
              cell.column.id === 'modified'
              && (
                <span
                  role="presentation"
                  onClick={() => editRow(row.original)}
                >
                  {
                    dateFormatted(row.original.modified)
                  }
                </span>
              )
            }
            {
              cell.column.id !== 'id'
              && cell.column.id !== 'status'
              && cell.column.id !== 'usersCount'
              && cell.column.id !== 'declarationsCount'
              && cell.column.id !== 'modified'

              && (
                cell.render('Cell')
              )
            }
          </td>
        ))}

        <td className="dropdown-cell w-1">
          {
            row.original.registryCode !== '10238375'
              && (
                <Dropdown
                  onToggle={(isOpen) => setOptionsOpen(isOpen)}
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    variant="link"
                    className="p-0 hide-caret"
                  >
                    <i className="fas fa-ellipsis-h fa-md" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow">

                    {
                      row.original.status !== CustomerStatus.BLOCKED
                    && (
                      <Dropdown.Item onClick={() => changeCustomerStatus(row.original)}>
                        <i className="fal fa-ban me-2 fa-md" />
                        <span>{t('buttons.block')}</span>
                      </Dropdown.Item>
                    )
                    }
                    {
                      row.original.status === CustomerStatus.BLOCKED
                    && (
                      <Dropdown.Item onClick={() => changeCustomerStatus(row.original)}>
                        <i className="fal fa-check me-2 fa-md" />
                        <span>{t('buttons.activate')}</span>
                      </Dropdown.Item>
                    )
                    }

                  </Dropdown.Menu>
                </Dropdown>
              )
          }
        </td>
      </tr>
    </React.Fragment>
  )
}

export default TableRow

