import Tooltip from 'rc-tooltip'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UserContext } from '../../../context/UserContext'
import { CustomsProfileType } from '../components/NctsProfileModal'
import { CustomerContext } from '../CustomerContext'
import CustomsProfileService, { CustomsProfileListResponse } from './services/customs_profile.service'

interface CustomsProfileProps {
  customerId: string;
  reFetch: boolean | undefined;
}

function CustomsProfile(props: CustomsProfileProps) {
  const { customerId, reFetch } = props
  const { user, customer } = useContext(UserContext)
  const { isNctsProfileModalVisible, setNctsProfileModalVisibility, setNctsProfile } = useContext(CustomerContext)

  const { t } = useTranslation()
  const [customsProfileData, setCustomsProfileData] = useState<Array<CustomsProfileListResponse>>([])
  const [hasT1, setHasT1] = useState<boolean>(false)

  const loadCustomsProfiles = () => {
    if (customer && Number(customerId) === customer.id) {
      CustomsProfileService.getCustomsProfileForCustomerAdmin()
        .then((customsProfiles) => {
          // eslint-disable-next-line no-nested-ternary
          customsProfiles.sort((a, b) => ((a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0)))
          setCustomsProfileData(customsProfiles)
        })
        .catch((reason) => {
          // eslint-disable-next-line no-console
          console.error(reason)
          toast.error(t('messages.savingFailed'))
        })
    } else if (customer) {
      CustomsProfileService.getTardekProfilesForCustomer(Number(customerId))
        .then((customsProfiles) => {
          if (customsProfiles.length >= 1) setHasT1(true)
          // eslint-disable-next-line no-nested-ternary
          customsProfiles.sort((a, b) => ((a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0)))
          setCustomsProfileData(customsProfiles)
        })
        .catch(() => {
          toast.error(t('messages.savingFailed'))
        })
    }
  }

  const revokeAccessToProfile = () => {
    if (customerId !== undefined && customerId !== null && customerId !== '0'
        && Number(customerId) !== customer.id) {
      CustomsProfileService.revokeAccess(Number(customerId)).then(() => {
        toast.success(t('messages.savingSuccess'))
        loadCustomsProfiles()
      }).catch((error) => {
        toast.error(t('messages.savingFailed'))
        // eslint-disable-next-line no-console
        console.error(error)
      })
    }
  }

  const makeProfileDefault = (id: number) => {
    CustomsProfileService.makeProfileDefault(id).then(() => {
      toast.success(t('messages.savingSuccess'))
      loadCustomsProfiles()
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }

  useEffect(() => {
    loadCustomsProfiles()
  }, [isNctsProfileModalVisible, customer, reFetch])

  const mapProfileStatusToButton = () => (
    <>
      {(customer?.profileStatus === 'REQUEST_ACCESS' && user?.role !== 'ADMIN') && (
        <tr>
          <td>
            Tardek T1
          </td>
          <td style={{ lineHeight: 2 }}>
            ********
          </td>
          <td style={{ lineHeight: 2 }}>
            ****
          </td>
          <td style={{ lineHeight: 2 }}>
            ************
          </td>
          <td>
            <button
              className="btn btn-secondary btn-sm text-primary shadow-sm"
              type="button"
              onClick={() => {
                CustomsProfileService.requestTardekProfileAccess()
                  .then(() => {
                    toast.success(t('customsProfile.requestSuccess'))
                  })
                  .catch((error) => {
                    toast.error('Server Error')
                    // eslint-disable-next-line no-console
                    console.error(error)
                  })
              }}
            >
              {t('buttons.requestAccess')}
            </button>
          </td>
        </tr>
      )}
      {(customer?.profileStatus === 'REQUESTED_ACCESS' && user?.role !== 'ADMIN') && (
        <tr>
          <td>
            Tardek T1
          </td>
          <td style={{ lineHeight: 2 }}>
            ********
          </td>
          <td style={{ lineHeight: 2 }}>
            ****
          </td>
          <td style={{ lineHeight: 2 }}>
            ************
          </td>
          {user?.role !== 'ADMIN' && (
            <td>
              <button
                className="btn btn-secondary btn-sm text-primary shadow-sm"
                type="button"
                disabled
              >
                {t('buttons.requestAccess')}
              </button>
            </td>
          )}
        </tr>
      )}
    </>
  )

  return (
    <table className="table table-sm align-items-center table-hover table-borderless data-table">
      <thead>
        <tr>
          <th key="Name">
            <div>
              {t('common.name', { context: 'capitalized' })}
            </div>
          </th>
          <th key="Grn">
            <div>
              {t('common.customsProfileGrn')}
            </div>
          </th>
          <th key="AccessCode">
            <div>
              {t('customers.accessCode')}
            </div>
          </th>
          <th key="Eori">
            <div>
              {t('declaration.eoriId')}
            </div>
          </th>
          {(customer?.profileStatus === 'REQUEST_ACCESS' || customer?.profileStatus === 'REQUESTED_ACCESS') && user?.role !== 'ADMIN' && (
            <th key="Button">
              <div>
                {t('buttons.requestAccess')}
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {mapProfileStatusToButton()}
        {customsProfileData.map((customProfile: CustomsProfileType) => (
          <React.Fragment key={customProfile.id}>
            {customProfile?.accessCode === '****' ? (
              <tr
                className="pe-auto"
                key={`id_${customProfile.id}_${customProfile.name}_'***'`}
              >
                <td key={customProfile.id}>
                  {`${customProfile.name}`}
                </td>
                <td key={`grn_${customProfile.grn}_${customProfile.id}`}>
                  {`${customProfile.grn}`}
                </td>
                <td key={`accessCode_${customProfile.accessCode}_${customProfile.id}`}>
                  {`${customProfile.accessCode}`}
                </td>
                <td key={`eori_${customProfile.eori}_${customProfile.id}`}>
                  {`${customProfile.eori}`}
                </td>
                {!customProfile.isDefault && Number(customerId) === customer.id && (
                  <td
                    role="presentation"
                    key={`default_shared_${customProfile.id}_${customProfile.id}`}
                    onClick={() => {
                      makeProfileDefault(customProfile.id)
                    }}
                  >
                    <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('customsProfile.default')}</small>}>
                      <button className="btn btn-link btn-gray-700 p-0" type="button">
                        <i className="fal fa-check fa-md" />
                      </button>
                    </Tooltip>
                  </td>
                )}
              </tr>
            ) : (
              <tr
                role="button"
                className="pe-auto"
                key={`id_${customProfile.id}_${customProfile.name}`}
              >
                <td
                  role="presentation"
                  key={customProfile.id}
                  onClick={() => {
                    setNctsProfile(customProfile)
                    setNctsProfileModalVisibility(true)
                  }}
                >
                  {`${customProfile.name}`}
                </td>
                <td
                  role="presentation"
                  key={`grn_${customProfile.grn}_${customProfile.id}`}
                  onClick={() => {
                    setNctsProfile(customProfile)
                    setNctsProfileModalVisibility(true)
                  }}
                >
                  {`${customProfile.grn}`}
                </td>
                <td
                  role="presentation"
                  key={`accessCode_${customProfile.accessCode}_${customProfile.id}`}
                  onClick={() => {
                    setNctsProfile(customProfile)
                    setNctsProfileModalVisibility(true)
                  }}
                >
                  {`${customProfile.accessCode}`}
                </td>
                <td
                  role="presentation"
                  key={`eori_${customProfile.eori}_${customProfile.id}`}
                  onClick={() => {
                    setNctsProfile(customProfile)
                    setNctsProfileModalVisibility(true)
                  }}
                >
                  {`${customProfile.eori}`}
                </td>
                {!customProfile.isDefault && Number(customerId) === customer.id && (
                  <td
                    role="presentation"
                    key={`default_${customProfile.id}_${customProfile.id}`}
                    onClick={() => {
                      makeProfileDefault(customProfile.id)
                    }}
                  >
                    <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('customsProfile.default')}</small>}>
                      <button className="btn btn-link btn-gray-700 p-0" type="button">
                        <i className="fal fa-check fa-md" />
                      </button>
                    </Tooltip>
                  </td>
                )}
                {(Number(customerId) !== customer.id && hasT1) && (
                  <td
                    role="presentation"
                    key={`delete_${customProfile.id}_${customProfile.id}`}
                    onClick={() => {
                      revokeAccessToProfile()
                    }}
                  >
                    <Tooltip overlayClassName="tooltip-lg" overlay={<small>{t('customsProfile.revoke')}</small>}>
                      <button className="btn btn-link btn-gray-700 p-0" type="button">
                        <i className="fal fa-times fa-md" />
                      </button>
                    </Tooltip>
                  </td>
                )}
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default CustomsProfile

