import React from 'react'
import { useTranslation } from 'react-i18next'
import transdekOverviewImg from '../../../assets/gfx/transdek-ulevaade2.svg'
import sendingDeclarationsImg from '../../../assets/gfx/profiilid2.svg'
import creatingDecsImg from '../../../assets/gfx/dek-loomine.svg'
import pdfImg from '../../../assets/gfx/PDF.svg'
import crmImg from '../../../assets/gfx/CRM.svg'

function Functionalities() {
  const { t } = useTranslation()

  return (
    <div className="functionalities pt-3 mt-3 pt-md-5 mt-md-5" id="functionalities">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="functionalities__title text-center mt-5">{t('landing.functionalities')}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="Declaration overview" src={transdekOverviewImg} />
            <p className="text-center">{t('landing.transitDeclarationOverview')}</p>
          </div>
          <div className="col-lg-4 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="user profile" src={sendingDeclarationsImg} />
            <p className="text-center">{t('landing.differentCustomsProfileCreation')}</p>
          </div>
          <div className="col-lg-4 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="declaration" src={sendingDeclarationsImg} />
            <p className="text-center">{t('landing.sendingDeclarationsForDifferentCountriesCustoms')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="declaration" src={creatingDecsImg} />
            <p className="text-center">{t('landing.creatingDeclarations')}</p>
          </div>
          <div className="col-lg-4 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="pdf" src={pdfImg} />
            <p className="text-center">{t('landing.declarationPDFs')}</p>
          </div>
          <div className="col-lg-4 col-sm-12 pb-4 mb-4">
            <img className="d-flex mx-auto" alt="crm" src={crmImg} />
            <p className="text-center">{t('landing.companyUserManagement')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Functionalities
