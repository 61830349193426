import {
  createContext, useContext, useEffect, useState,
} from 'react'
import { NctsCountry } from 'config'
import { UserContext } from 'context/UserContext'
import {
  AccessType, FieldPathRule, RestrictionTypeKey, TransitOperationContextType,
} from 'types/DeclarationP5'
import { DeclarationStatusEnum } from 'types/IDeclaration'
import { extractCountryFromDepartureOffice } from '../../../common/utils/classifier-util'

export const TransitOperationContext = createContext<TransitOperationContextType>({
  accessType: 'EDIT',
  isTransitionPeriodActive: true,
  isCurrentUserAdmin: false,
  country: null,
  restrictions: {
    TRADER: [],
    TRUCK: [],
    COMMODITY_CODE: [],
    COMMODITY_DESCRIPTION: [],
    COMMODITY_SANCTION: [],
  },
  fieldRules: [],
  setFieldRules: (value) => value,
  nctsErrors: [],
  setNctsErrors: (value) => value,
})
export type NctsError = {
  description: string
  field: string // FIXME type when backend sends arrays with indexes notation (.0.)
}

export function useTransitOperationContext(status: string | null, departureCustomsOffice: string): TransitOperationContextType {
  const {
    user,
  } = useContext(UserContext)

  const [accessType, setAccessType] = useState<AccessType>('EDIT')
  const [isTransitionPeriodActive] = useState<boolean>(true) // true until 2024
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState<boolean>(false)
  const [country, setCountry] = useState<NctsCountry | null>(null)
  const [fieldRules, setFieldRules] = useState<Array<FieldPathRule>>([])
  const [nctsErrors, setNctsErrors] = useState<Array<NctsError>>([])

  // TODO useRestrictions hook with react-query useQueries
  // https://medium.com/@trisianto/react-query-how-to-memoize-results-from-usequeries-hook-eaed9a0ec700
  const [restrictions] = useState<Record<RestrictionTypeKey, Array<string>>>({
    TRADER: [],
    TRUCK: [],
    COMMODITY_CODE: [],
    COMMODITY_DESCRIPTION: [],
    COMMODITY_SANCTION: [],
  })

  useEffect(() => {
    setIsCurrentUserAdmin(user?.role === 'ADMIN')
  }, [user?.role])

  useEffect(() => {
    setCountry(extractCountryFromDepartureOffice(departureCustomsOffice))
  }, [departureCustomsOffice])

  useEffect(() => {
    if (country === null) return

    if (status === DeclarationStatusEnum.T1_TRANSIT_CLOSED || status === DeclarationStatusEnum.TRANSIT_PROCEDURE_COMPLETED) {
      setAccessType('VIEW')
      return
    }

    if (isCurrentUserAdmin) {
      setAccessType('EDIT')
      // TODO reminder to create a hook for loading directo - currently event inputs are missing
      // if (declaration.id) {
      //   directoEventState.getDirectoEvent(declaration.id)
      // }
    } else if (country === 'EE' || status === DeclarationStatusEnum.DRAFT.toString() || status === DeclarationStatusEnum.REJECTED.toString()) {
      setAccessType('EDIT')
    } else {
      setAccessType('VIEW')
    }
  }, [country, status, isCurrentUserAdmin])

  return {
    accessType,
    country,
    isCurrentUserAdmin,
    isTransitionPeriodActive,
    restrictions,
    fieldRules,
    setFieldRules,
    nctsErrors,
    setNctsErrors,
  } as const
}
