const ROUTES = {
  external: {
    europaTransitLookup: 'https://ec.europa.eu/taxation_customs/dds2/tra/transit_home.jsp?Lang=en&Expand=true&',
    lvGuarantee: 'https://itvs.vid.gov.lv/#itms:11;;',
    ltGuarantee: 'https://litarweb.lrmuitine.lt/taric/web/securitycalc_EN',
    plGuarantee: 'https://ext-isztar4.mf.gov.pl/taryfa_celna/CalculateComplex?lang=EN',
    eeGuarantee: 'https://apps.emta.ee/arctictariff-public-web/#!/taric/duty/calculate',
  },
  login: '/login',
  transit: '/transit',
  newTransit: '/new-transit',
  transitP5: '/transit-operation',
  transitP5Url: '/transit-operation/:transitId',
  newTransitP5: '/new-transit-p5',
  transit_operation: 'transit-operations',
  transit_audit: 'transit-audit',

  restrictions: '/restrictions',
  restriction: '/restriction',
  newRestriction: '/new-restriction',
  registration: '/register',

  customers: '/customers',
  customer: '/customer',
  newCustomer: '/new-customer',
  externalCustomer: '/external-customer',
  newExternalCustomer: '/external-customer',
}

export default ROUTES
