import React, { useContext, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import NotificationTab from './tabs/Notification'
import SeoTab from './tabs/Seo'
import PrivacyPolicyTab from './tabs/PrivacyPolicy'
import CustomsTab from './tabs/Customs'
import CodelistTab from './tabs/Codelist'
import { SettingsTabsPanel } from './tabs'
import { NotificationContext } from '../../context/NotificationContext'
import CertificatesTab from './tabs/Certificates'

export default function Settings() {
  const { contextHeaderNotificationMessage } = useContext(NotificationContext)
  const notificationInUseClass = useMemo(() => (contextHeaderNotificationMessage !== '' ? 'notification--open' : ''), [contextHeaderNotificationMessage])
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className={`table-responsive settings__container ${isMobile ? 'not-in-mobile' : ''} ${notificationInUseClass}`}>
      <SettingsTabsPanel
        loading={loading}
      >
        <NotificationTab key="notification" tabType="notification" loading={loading} setLoading={setLoading} />
        <PrivacyPolicyTab key="privacy" tabType="privacy" loading={loading} setLoading={setLoading} />
        <SeoTab key="seo" tabType="seo" loading={loading} setLoading={setLoading} />
        <CustomsTab key="customs" tabType="customs" loading={loading} setLoading={setLoading} />
        <CodelistTab key="codelist" tabType="codelist" loading={loading} setLoading={setLoading} />
        <CertificatesTab key="certificates" tabType="certificates" loading={loading} setLoading={setLoading} />
      </SettingsTabsPanel>
    </div>
  )
}
