import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Restriction, RestrictionType } from '../../../../types/restriction'
import FormRow from '../../../../components/FormRow'
import HsCodeSelect from '../../../../components/HsCodeSelect'

interface RestrictionInputProps {
  restriction: Restriction
  setRestriction: Dispatch<SetStateAction<Restriction>>
}

function RestrictionInputs(props: RestrictionInputProps) {
  const { t } = useTranslation()
  const {
    restriction,
    setRestriction,
  } = props

  const handleHsCodeSelect = React.useCallback((code: string | null) => {
    setRestriction({
      ...restriction,
      key: code ?? '',
    })
  }, [restriction])

  return (
    <>
      {
        (RestrictionType.COMMODITY_CODE === restriction.type
          || RestrictionType.COMMODITY_SANCTION === restriction.type) && (
          <>
            <FormRow title={`${t('goodsItem.hsCode')} ${t('restriction.key').toLowerCase()}`}>
              <HsCodeSelect
                code={restriction.key}
                isSanction={restriction.type === RestrictionType.COMMODITY_SANCTION}
                setHsCode={handleHsCodeSelect}
                isInvalid={false}
              />
            </FormRow>

            {RestrictionType.COMMODITY_CODE === restriction.type && (
              <FormRow title={t('goodsItem.hsCodeAudit')}>
                <div className="form-row-special-input">
                  <div className="form-check form-switch ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={restriction.requiresAudit}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setRestriction({
                          ...restriction,
                          requiresAudit: e.target.checked,
                        })
                      }}
                    />
                  </div>

                </div>
              </FormRow>
            )}
          </>
        )
      }
      {
        RestrictionType.COMMODITY_DESCRIPTION === restriction.type && (
          <FormRow title={t('goodsItem.description')}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={restriction.key}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setRestriction({
                  ...restriction,
                  key: event.target.value,
                })
              }}
            />
          </FormRow>
        )
      }
      <FormRow title={t('restriction.description')}>
        <input
          className="form-control"
          type="text"
          placeholder={t('common.fieldPlaceholder')}
          value={restriction.description}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setRestriction({
              ...restriction,
              description: event.target.value,
            })
          }}
        />
      </FormRow>
      {
        RestrictionType.TRADER === restriction.type && (
          <FormRow title={t('restriction.commodityTraderType')}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={restriction.key}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setRestriction({
                  ...restriction,
                  key: event.target.value,
                })
              }}
            />
          </FormRow>
        )
      }
      {
        RestrictionType.TRUCK === restriction.type && (
          <FormRow title={t('restriction.commodityTruckType')}>
            <input
              className="form-control"
              type="text"
              placeholder={t('common.fieldPlaceholder')}
              value={restriction.key}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setRestriction({
                  ...restriction,
                  key: event.target.value,
                })
              }}
            />
          </FormRow>
        )
      }
    </>
  )
}

export default RestrictionInputs
