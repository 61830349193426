export const dateFormatWithTimeOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: 'Europe/Tallinn',
}
export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour12: false,
  timeZone: 'Europe/Tallinn',
}

export const dateFormat: string = 'DD.MM.YYYY'
export const isoDateFormat: string = 'YYYY-MM-DD'
export const isoUtcDateFormat: string = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX' // format(Date.parse(form.limitDate ?? ''), isoUtcDateFormat
