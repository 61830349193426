import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MutateRequest } from '../request-type'
import DeclarationApi from '../apiConfig'
import { EditTransitOperationAuditRequest } from '../../../common/models/edit-transit-operation-audit-request'
import { EditTransitOperationAuditResponse } from '../../../common/models/edit-transit-operation-audit-response'

const {
  baseUrl,
  queryKeys: { rootPath },
} = DeclarationApi.paths.transitOperationAudit

function useTransitOperationAuditApi() {
  const queryClient = useQueryClient()

  const putTransitOperationAudit = useMutation({
    mutationFn: async ({
      id,
      data,
    }: MutateRequest<EditTransitOperationAuditRequest>) => (await axios
      .put<EditTransitOperationAuditResponse>(`${baseUrl}/${id}`, data)).data,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [rootPath] }),
  })

  return {
    putTransitOperationAudit,
  }
}
export default useTransitOperationAuditApi

