import { useStore } from 'react-redux'
import { addSeal } from '../../../../../redux/actions'
import { ISeal } from '../../types/Seal'

export interface UseSealsProps {
  seals: Array<ISeal>;
  transportId: number | null,
  setSeals: (newSeal: Array<ISeal>) => void;
}

export function useSeals(props: UseSealsProps) {
  const { transportId, setSeals, seals } = props
  const store = useStore()

  function addItem() {
    const newItemIndex = seals.length ?? 0
    const newSealsArray = [...seals.concat({
      id: null,
      identity: '',
      transportId,
      sortOrder: newItemIndex,
      deleted: false,
    })]
    setSeals(newSealsArray)

    store.dispatch(addSeal([...newSealsArray]))
  }

  return { addItem }
}
