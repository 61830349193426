import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { NestedFieldScope } from 'types/DeclarationP5'
import useDirectoCustomerSearch from '../../../../Declaration/DeclarationInfo/DirectoEvent/hooks/useDirectoCustomerSearch'
import FormRow from '../../../../../components/FormRow'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import { DirectoEventResponseStatusEnum } from '../../../common/models/directo-event-response'

type DirectoItemScope = NestedFieldScope<'directo'>

function DirectoForm() {
  const { t } = useTranslation()

  const { getValues } = useFormContext<DeclarationForm>()
  const directoForm = getValues('directo')

  const {
    loadOptions,
    valueBuffer,
    setValueBuffer,
    directoClients,
  } = useDirectoCustomerSearch({ initialCustomerId: directoForm.customerId })

  return (
    <React.Fragment key="directoEvent">
      <FormRow title={t('directo.customer')}>
        <FormSelect
          <DirectoItemScope>
          field="directo.customerId"
          labelKey="directo.customer"
          type="async"
          loadOptions={loadOptions}
          valueBuffer={valueBuffer}
          setValueBuffer={setValueBuffer}
          isDisabled={directoForm.status !== DirectoEventResponseStatusEnum.IDLE}
        />
      </FormRow>
      <FormRow title={t('directo.descriptionAddendum')}>
        <Input
          <DirectoItemScope>
          field="directo.descriptionAddendum"
          labelKey="directo.descriptionAddendum"
          type="textarea"
          placeholder={t('common.fieldPlaceholder')}
          disabled={directoForm.status !== DirectoEventResponseStatusEnum.IDLE}
        />
      </FormRow>
      <FormRow title={t('customers.notes')}>
        <textarea
          className="form-control"
          disabled
          rows={5}
          placeholder={t('common.fieldPlaceholder')}
          value={directoClients.find((client) => client.id === getValues('directo.customerId'))?.note ?? ''}
        />
      </FormRow>
    </React.Fragment>
  )
}

export default DirectoForm
