import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface ConfirmationModalProps {
  title: string;
  messageBody: string;
  isVisible: boolean;
  isLoading: boolean;
  toggleVisibility: () => void;
  onConfirmation: () => void;
  onRejection: () => void;
  warning?: string;
}

function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    title,
    messageBody,
    isVisible,
    toggleVisibility,
    isLoading,
    onConfirmation,
    onRejection,
    warning,
  } = props
  const { t } = useTranslation()

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin" />)

  return (
    <Modal
      show={isVisible}
      dialogClassName="modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleVisibility()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{title}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>{messageBody}</p>
        {
          warning !== undefined && (
            <strong className="text-danger">{warning}</strong>
          )
        }

        <div className="d-flex justify-content-around mt-4">
          <button
            type="button"
            disabled={isLoading}
            className="btn btn-lg btn-secondary mx-1 text-primary shadow-sm"
            onClick={() => onRejection()}
          >
            {
              isLoading && loadingSpinner()
            }
            {
              !isLoading && (<span>{t('common.no')}</span>)
            }
          </button>
          <button
            type="button"
            disabled={isLoading}
            className="btn btn-lg btn-primary mx-1 text-secondary shadow-sm"
            onClick={() => onConfirmation()}
          >
            {
              isLoading && loadingSpinner()
            }
            {
              !isLoading && (<span>{t('common.yes')}</span>)
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ConfirmationModal.defaultProps = {
  warning: undefined,
}

export default ConfirmationModal
