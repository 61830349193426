import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { isNullOrBlank } from '../../../../helpers'
import UserService from '../../services/user.service'
import { UserContext } from '../../../../context/UserContext'

function useUserInviteForm() {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [remoteStaffCode, setRemoteStaffCode] = useState<string>('')

  const submit = (customerId?: number) => new Promise((resolve, reject) => {
    if (isNullOrBlank(email) || isNullOrBlank(firstname) || isNullOrBlank(lastname)) {
      toast.error(t('messages.missingRequiredFields'))
      reject((Error('Missing email/firstname/lastname')))
      return
    }
    if (customerId === undefined) {
      reject((Error('Missing customerId')))
      return
    }

    if (user?.role === 'ADMIN') {
      UserService.postUser({
        firstname,
        lastname,
        email,
        phone,
        remoteStaffCode,
        customerId,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(Error(`Posting failed: ${reason}`))
        })
    } else {
      UserService.postNewUserForCustomer({
        firstname,
        lastname,
        email,
        phone,
      })
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => reject(Error(`Posting failed: ${reason}`)))
    }
  })

  return {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    phone,
    setPhone,
    remoteStaffCode,
    setRemoteStaffCode,
    submit,
  }
}

export default useUserInviteForm
