import { RuleResult } from 'types/DeclarationP5'
import { COUNTRY_CODE_PREFIX, removePrefix } from '../../../../common/utils/classifier-util'
import { CL009_COUNTRY_CODES_COMMON_TRANSIT } from '../../../../common/utils/ncts-constants'
import { nonNullConsignee, someAdditionalInformationItemHasExsCode } from '../../../../common/utils/common-util'
import { DeclarationForm } from '../../schemas/declarationFormSchema'

const B1823Validator = {
  validateConsignmentConsigneeRequirement: (formData: DeclarationForm): RuleResult => {
    const {
      countryOfDestination,
      security,
    } = formData

    const allGoodsItems = formData
      .houseConsignment.flatMap((house) => house.consignmentItem)

    const country = removePrefix(countryOfDestination, COUNTRY_CODE_PREFIX) ?? ''
    if (CL009_COUNTRY_CODES_COMMON_TRANSIT.includes(country)) {
      if (allGoodsItems.flatMap((item) => item.consignee)
        .some((
          someConsignee,
        ) => someConsignee && nonNullConsignee(someConsignee))) {
        return 'NOT_ALLOWED'
      }
      return 'REQUIRED'
    }

    if (['0', '1'].includes(security)) {
      return 'OPTIONAL'
    }

    if (someAdditionalInformationItemHasExsCode(allGoodsItems.flatMap((
      item,
    ) => item.additionalInformation))) {
      return 'NOT_ALLOWED'
    }
    return 'OPTIONAL'
  },
} as const

export default B1823Validator
