import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../schemas/declarationFormSchema'
import { removePrefix } from '../../../common/utils/classifier-util'

// Unknown qualifiers or unselected values are by default as NOT_ALLOWED
const C0394Validator = {
  validateUnLocodeRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'U': return 'REQUIRED'
      case 'Z':
      case 'X':
      case 'Y':
      case 'W':
      case 'V':
      case 'T':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validateCustomsOfficeRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'V': return 'REQUIRED'
      case 'Z':
      case 'X':
      case 'Y':
      case 'W':
      case 'U':
      case 'T':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validateEconomicOperatorRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'X': return 'REQUIRED'
      case 'Z':
      case 'Y':
      case 'W':
      case 'V':
      case 'U':
      case 'T':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validateAuthorisationNumberRequirement: (formData: DeclarationForm): RuleResult => {
    const country = formData.departureCustomsOffice?.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2)
    if (country === 'FI') return 'NOT_ALLOWED'
    if (formData.locationOfGoods === null) return 'NOT_ALLOWED'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'Y': return 'REQUIRED'
      case 'Z':
      case 'X':
      case 'W':
      case 'V':
      case 'U':
      case 'T':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validatePostCodeAddressRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'T': return 'REQUIRED'
      case 'Z':
      case 'X':
      case 'Y':
      case 'W':
      case 'V':
      case 'U':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validateGnssRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'W': return 'REQUIRED'
      case 'Z':
      case 'X':
      case 'Y':
      case 'V':
      case 'U':
      case 'T':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validateContactPersonRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'V': return 'NOT_ALLOWED'
      case 'Z':
      case 'X':
      case 'Y':
      case 'W':
      case 'U':
      case 'T':
        return 'OPTIONAL'
      default:
        return 'NOT_ALLOWED'
    }
  },
  validateAddressRequirement: (formData: DeclarationForm): RuleResult => {
    if (formData.locationOfGoods === null) return 'OPTIONAL'
    const qualifier = removePrefix(formData.locationOfGoods.qualifierOfIdentification, 'QUALIFIER_OF_IDENTIFICATION_')

    switch (qualifier) {
      case 'Z': return 'REQUIRED'
      case 'X':
      case 'Y':
      case 'W':
      case 'V':
      case 'U':
      case 'T':
        return 'NOT_ALLOWED'
      default:
        return 'NOT_ALLOWED'
    }
  },
} as const

export default C0394Validator
