import React, { useContext, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { UserContext } from 'context/UserContext'
import ROUTES from 'config/routes'
import useDeclarationForm from './form'
import TransitOperationForm from './TransitOperationForm'

import { TransitOperationContext, useTransitOperationContext } from './hooks/useTransitOperationContext'
import invokeValidators from './form/validators'
import { DeclarationForm } from './form/schemas/declarationFormSchema'
import { EachTransitClearedKeys } from './hooks/apiConfig'
import updateFormErrors from './form/errors/updateFormErrors'

function TransitOperationEditor() {
  const { transitId }: { transitId?: string } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { user, customer } = useContext(UserContext)
  useEffect(() => {
    if (user === undefined && customer === undefined) {
      navigate(`${ROUTES.login}`)
    }
  }, [])

  const { form } = useDeclarationForm()
  const contextState = useTransitOperationContext(form.getValues('status'), form.getValues('departureCustomsOffice'))

  useEffect(() => {
    const { unsubscribe } = form.watch((value) => {
      if (value === undefined) {
        return
      }
      const formData = value as DeclarationForm
      const results = invokeValidators(formData)
      updateFormErrors(results, form)
      contextState.setFieldRules(results)
    })

    return () => unsubscribe()
  }, [form.watch])

  useEffect(() => {
    if (transitId === undefined) {
      form.reset()
    }
    // if (process.env.NODE_ENV === 'development') form.reset()
    const id = Number(transitId)
    if (!Number.isNaN(id)) {
      form.setValue('id', id)
    }
    EachTransitClearedKeys.forEach((key) => queryClient.removeQueries({ queryKey: [key] }))
  }, [transitId])

  return (
    <TransitOperationContext.Provider value={contextState}>
      <FormProvider {...form}>
        <TransitOperationForm form={form} />
      </FormProvider>
    </TransitOperationContext.Provider>
  )
}

export default TransitOperationEditor
