import { RuleResult } from 'types/DeclarationP5'
import { PreviousDocument } from '../../schemas/documentSchemas'
import { hasText } from '../../../../common/utils/common-util'

const NA61Validator = {
  validateConsignmentItemPreviousDocumentRefNumberRequirement: (previousDocument: PreviousDocument): RuleResult => {
    if (hasText(previousDocument.documentType)) {
      return 'REQUIRED'
    }
    if (hasText(previousDocument.procedureType) && previousDocument.procedureType !== 'PIIR') {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default NA61Validator
