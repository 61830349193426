import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { COLUMN_KEY_OPTIONS, CustomColumnMap } from '../../hooks/useConsignmentItemImport/mapper'

interface GoodsImportModalProps {
  isVisible: boolean
  toggleVisibility: () => void
  customColumnMap: CustomColumnMap
  setCustomColumnMap: Dispatch<SetStateAction<CustomColumnMap>>
  triggerImport: () => void
}

function GoodsImportModal(props: GoodsImportModalProps) {
  const { t } = useTranslation()
  const {
    isVisible,
    customColumnMap,
    setCustomColumnMap,
    toggleVisibility,
    triggerImport,
  } = props
  const areColumnsMapped = false

  const columnsInCsv: string[] = []

  customColumnMap.forEach(
    (value, key) => {
      columnsInCsv.push(key)
    },
  )

  return (
    <Modal
      show={isVisible}
      dialogClassName="import-modal modal"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleVisibility()}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-self-center">
          <h2>{t('consignmentItemImport.header')}</h2>
        </Modal.Title>
        <button
          type="button"
          className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
          onClick={() => toggleVisibility()}
        >
          <span>{t('buttons.close')}</span>
          <i className="fal fa-times fa-2x ms-2" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div
          className="ms-2 mb-5 mt-2"
        >
          <p>
            {t('consignmentItemImport.explanation')}
          </p>
        </div>
        <div
          className="d-flex flex-row w-100 pb-5"
          style={{
            overflowY: 'hidden',
            overflowX: 'auto',
          }}
        >
          {
            columnsInCsv.map((column, index) => (
              <div
                key={column}
                className={`${index !== 0 ? 'ms-2' : ''} d-flex flex-column w-100 align-items-center`}
              >
                <Select
                  className="select w-100"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  classNamePrefix="select"
                  options={COLUMN_KEY_OPTIONS}
                  formatGroupLabel={(group) => {
                    const translation = t(`declaration.p5.${group.label}`)
                    if (group.label === 'consignmentItem') return t('declaration.p5.goodsData')
                    if (translation === `declaration.p5.${group.label}`) return t(`declaration.p5.${group.label}s`)

                    return translation
                  }}
                  defaultValue={customColumnMap.get(column) ? {
                    value: customColumnMap.get(column) ?? null,
                    label: column.split('_').length > 1 ? column.split('_')[1] : column,
                  } : null}
                  getOptionLabel={(option) => {
                    const translation = t(`declaration.p5.${option.label}`)
                    if (option.value && option.value.includes('consignee')
                      && option.label === 'identificationNumber') {
                      return t('declaration.p5.consigneeIdentificationNr')
                    }
                    if (option.label === 'documentLineNumber') return t('declaration.p5.itemNumber')
                    if (option.label === 'streetAndNumber') return t('common.street')
                    if (translation === `declaration.p5.${option.label}`) return t(`declaration.p5.${option.label}s`)
                    return translation
                  }}
                  onChange={(option) => {
                    setCustomColumnMap(new Map(customColumnMap.set(column, option?.value ?? null)))
                  }}

                />
                <small className="text-muted">{column}</small>
              </div>
            ))
          }
        </div>

      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          disabled={areColumnsMapped}
          onClick={() => {
            triggerImport()
          }}
        >
          <i className="fal fa-check me-2" />
          <span>{t('consignmentItemImport.import', { context: 'start' })}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default GoodsImportModal
