import React, {
  Dispatch, SetStateAction, useEffect, useMemo, useState,
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import DetailViewGoodsRow from './detailViewGoodsRow'
import DataRow from './DataRow'
import DeclarationService from '../../Declaration/services/declaration.service'
import { DeclarationDetailed, ITransitCountry } from '../../../types/IDeclaration'
import { IClassifier } from '../../../types/IClassifier'
import {
  getClassifier, getClassifierName, getNameForLocale, getValue, sortBySortOrder,
} from '../../../helpers'
import { IGoodsItem } from '../../../types/IGoodsItem'
import TransitService from '../../../services/transit.service'
import { EventLog } from '../../../types/EventLog'
import TransitField from './transitFields'
import { getInvalidIconData } from './invalid-icon.helper'
import InvalidIcon from './InvalidIcon'
import DocumentService from '../../Declaration/services/document.service'
import { extractDeclarationCountryFromCustomsOffice } from '../../../hooks/useDeclarationMetaData'
// eslint-disable-next-line import/no-cycle
import { DeclarationMetaInfoProps } from './index'
import FeedbackModal from '../../Declaration/FeedbackModal'
import GoodsItemService from '../../Declaration/services/goods-item.service'
import NotesModal from '../../Declaration/NotesModal'
import { DeclarationPageResponse } from '../../../types/DeclarationPageResponse'
import { DeclarationAudit } from '../../../types/DeclarationAudit'
import { DeclarationCountry } from '../../../context/DeclarationContext'

interface DetailViewProps {
  declarationId: number,
  updateDeclarationMetaInfo: Dispatch<SetStateAction<DeclarationMetaInfoProps>>
  countries: Array<IClassifier>,
  customsOffices: Array<IClassifier>,
  packageTypes: Array<IClassifier>,
  documentTypes: Array<IClassifier>,
  previousDocumentTypes: Array<IClassifier>,
  procedureTypes: Array<IClassifier>,
  invalidFieldsEvent: EventLog | undefined,
  openLogView: () => void,
  declarationReason: string | null,
  isFeedbackModalOpen: boolean,
  setIsFeedbackModalOpen: Dispatch<SetStateAction<boolean>>,
  isNotesModalOpen: boolean,
  setIsNotesModalOpen: Dispatch<SetStateAction<boolean>>,
  originalRow: DeclarationPageResponse | DeclarationAudit | undefined,
}
export interface InvalidField {
  pointer: string,
  reason: string
}

export function DetailView(props: DetailViewProps) {
  const {
    declarationId,
    countries, customsOffices, packageTypes, documentTypes, previousDocumentTypes, procedureTypes,
    updateDeclarationMetaInfo, invalidFieldsEvent, openLogView,
    declarationReason, isFeedbackModalOpen, setIsFeedbackModalOpen,
    isNotesModalOpen, setIsNotesModalOpen, originalRow,
  } = props
  const { t, i18n } = useTranslation()
  const [isTransitsInfoOpen, toggleTransitsInfo] = useState(true)
  const [isGoodsOpen, toggleGoodsOpen] = useState(true)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [transit, setTransit] = useState<DeclarationDetailed | null>(null)
  const [goodsItems, setGoodsItems] = useState<Array<IGoodsItem>>([])

  const getGoodsItemsWithFileUrls: (goods: IGoodsItem[]) =>
  Promise<IGoodsItem[]> = (initialGoods: IGoodsItem[]) => new Promise((resolve, reject) => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const fileUrlResolvingPromises: Array<Promise<any>> = []
    initialGoods
      .flatMap((goodsItem) => goodsItem.documents)
      .flatMap((goodsItemDocument) => goodsItemDocument.files)
      .forEach((file) => {
        fileUrlResolvingPromises.push(
          DocumentService.getDocumentFileSignedUrl(file)
            .then((signedUrl) => {
              // eslint-disable-next-line no-param-reassign
              file.url = signedUrl
            }),
        )
      })

    Promise.all(fileUrlResolvingPromises).then(() => {
      resolve(initialGoods)
    }).catch((error) => {
      reject(error)
      // eslint-disable-next-line no-console
      console.error('An error occurred while resolving document file urls')
    })
  })

  function isNewPhase(detailedDeclarationResponse: DeclarationDetailed, country: DeclarationCountry | null) {
    // if (country === DeclarationCountry.POLAND && parseISO(detailedDeclarationResponse.created) > parseISO('2023-06-08T00:00:00Z')) {
    //   return true
    // }

    return false
  }

  const sortedGoodsItems = (goodsItem: IGoodsItem[]) => goodsItem.sort(sortBySortOrder)

  useEffect(() => {
    setIsLoading(true)
    DeclarationService.getDeclaration(declarationId)
      .then((detailedDeclarationResponse) => {
        setTransit(detailedDeclarationResponse)
        const country = extractDeclarationCountryFromCustomsOffice(detailedDeclarationResponse.transport.departureCustomsOffice)
        const isPhase5 = isNewPhase(detailedDeclarationResponse, country)
        updateDeclarationMetaInfo({
          name: `${detailedDeclarationResponse.id}`,
          status: detailedDeclarationResponse.status,
          mrn: detailedDeclarationResponse.mrn ?? '',
          lrn: detailedDeclarationResponse.localReferenceNumber ?? '',
          declarationCountry: country,
          reason: detailedDeclarationResponse.reason ? detailedDeclarationResponse.reason : '',
          isPhase5,
        })
        GoodsItemService.getAllGoods(declarationId).then((fetchedGoodsItems) => {
          setGoodsItems(sortedGoodsItems(fetchedGoodsItems))
          TransitService.getTransitGoodsItemsDetailed(declarationId, sortedGoodsItems(fetchedGoodsItems))
            .then((goods) => {
              getGoodsItemsWithFileUrls(sortedGoodsItems(goods)).then((goodsItemsWithFileUrls) => {
                setGoodsItems(sortedGoodsItems(goodsItemsWithFileUrls))
                setTimeout(() => {
                  setIsLoading(false)
                }, 200)
              }).catch(() => toast.warn('Loading document file previews failed!'))
            })
        })
      }).catch(() => toast.warn('Declaration loading failed'))
  }, [declarationId])

  const invalidFields = useMemo(() => {
    const pointers: Array<InvalidField> = []
    if (!invalidFieldsEvent || invalidFieldsEvent.functionalErrors.length === 0) {
      return pointers
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const error of invalidFieldsEvent.functionalErrors) {
      const strings = error.pointer ? error.pointer.split('.') : []
      const reason = error.reason !== null ? error.reason : error.pointer
      if (strings.length > 1) {
        strings.reverse().pop()
        pointers.push({ pointer: strings.reverse().join('.'), reason })
      } else {
        pointers.push({ pointer: error.pointer, reason })
      }
    }

    return pointers
  }, [invalidFieldsEvent])

  const guaranteeAmountIconData = useMemo(() => getInvalidIconData([
    TransitField.GUARANTEE_GUARANTEE_REFERENCE_GUARANTEE_REFERENCE_NUMBER_GRN,
    TransitField.GUARANTEE_GUARANTEE_REFERENCE_ACCESS_CODE,
    TransitField.GUARANTEE_GUARANTEE_REFERENCE_AMOUNT_CONCERNED], invalidFields), [invalidFields])

  const packagesCountIconData = useMemo(() => getInvalidIconData([
    TransitField.HEADER_TOTAL_NUMBER_OF_PACKAGES, TransitField.HEADER_TOTAL_NUMBER_OF_ITEMS], invalidFields), [invalidFields])

  if (transit == null) {
    return (
      <div className="aside-main d-flex flex-column">
        <div className="mx-auto mt-3">
          <i className="fa fa-spinner-third fa-spin fa-2x" />
        </div>
      </div>
    )
  }

  let transitCountriesCommaSeparated: string | null = ''
  if (transit.declarationInfo !== null && transit.declarationInfo.transitCountries.length > 0) {
    const transitCountries = (transit.declarationInfo.transitCountries as Array<ITransitCountry>)
      .sort(sortBySortOrder)
    if (transitCountries[0] && transitCountries[0].code !== null) {
      transitCountriesCommaSeparated = transitCountries
        .map((country) => {
          const classifier = getClassifier(country.code, countries)
          return classifier
            ? `${getNameForLocale(classifier, i18n.language)} (${classifier.codeLabel})` : getNameForLocale(country, i18n.language)
        })
        .reduce((countryNames, countryName, index) => countryNames + (countryName + (index === transitCountries.length - 1
          ? '' : ', ')), '')
    }
  }

  const onClickOpenLogView = () => {
    openLogView()
  }

  return (
    <>
      <div className="aside-main d-flex flex-column">
        {declarationReason !== null && declarationReason !== '' && (
          <>
            <div
              className="section__header"
            >
              <div className="d-flex justify-content-center align-items-center text-black-50">
                <i className="fal fa-exclamation-triangle me-2 fa-md" />
                <span>{t('audit.reason')}</span>
              </div>
            </div>
            <div className="active section__body">
              <div className="p-4 col-12 col-lg-6 justify-content-around text-danger">{declarationReason}</div>
            </div>
          </>
        )}
        {transit.feedback !== null && transit.feedback !== '' && (
          <>
            <div
              className="section__header"
            >
              <div className="d-flex justify-content-center align-items-center text-black-50">
                <i className="fal fa-info-circle me-2 fa-md" />
                <span>{t('feedback.info')}</span>
              </div>
            </div>
            <div className="active section__body">
              <div className="p-4 col-12 col-lg-6 justify-content-around text-muted">{transit.feedback}</div>
            </div>
          </>
        )}

        <div
          className="section__header"
          role="presentation"
          onClick={() => toggleTransitsInfo(!isTransitsInfoOpen)}
        >
          <div className="d-flex justify-content-center align-items-center text-black-50">
            <i className="fal fa-route me-2 fa-md" />
            <span>{t('declaration.headers.info')}</span>
          </div>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center"
          >
            <i className={isTransitsInfoOpen ? 'fal fa-chevron-up' : 'fal fa-chevron-down'} />
          </button>
        </div>
        <div className={`${isTransitsInfoOpen ? 'active' : ''} section__body`}>
          <div className="p-4 col-12 col-lg-6 justify-content-around border-end">
            {
              !transit.isGoodsTradersEnabled
                ? [
                  <DataRow
                    key={`${transit}consignorName`}
                    columnWidth={4}
                    title={t('declaration.consignor')}
                    value={getValue(transit.consignor?.name)}
                    fieldLabels={[TransitField.CONSIGNOR_TRADER_NAME, TransitField.CONSIGNOR_SECURITY_TRADER_NAME]}
                    invalidFields={invalidFields}
                    onInvalidIconClick={onClickOpenLogView}
                  />,
                  <DataRow
                    key={`${transit}consignorCity`}
                    columnWidth={4}
                    value={`${getValue(transit.consignor?.city) !== '' ? (`${getValue(transit.consignor?.street)},
                    ${getValue(transit.consignor?.city)}`)
                      : getValue(transit.consignor?.street)}`}
                    secondValue={`${getValue(transit.consignor?.zip) !== ''
                      ? (`${getClassifierName(transit.consignor?.country, countries, i18n.language)}, ${
                        getValue(transit.consignor?.zip)}`)
                      : getClassifierName(transit.consignor?.country, countries, i18n.language)}`}
                    fieldLabels={[
                      TransitField.CONSIGNOR_TRADER_STREET_AND_NUMBER,
                      TransitField.CONSIGNOR_TRADER_CITY,
                      TransitField.CONSIGNOR_TRADER_POSTAL_CODE,
                      TransitField.CONSIGNOR_TRADER_COUNTRY_CODE,
                      TransitField.CONSIGNOR_SECURITY_TRADER_STREET_AND_NUMBER,
                      TransitField.CONSIGNOR_SECURITY_TRADER_CITY,
                      TransitField.CONSIGNOR_SECURITY_TRADER_POSTAL_CODE,
                      TransitField.CONSIGNOR_SECURITY_TRADER_COUNTRY_CODE,
                    ]}
                    invalidFields={invalidFields}
                    onInvalidIconClick={onClickOpenLogView}
                    isLast
                  />,
                  <DataRow
                    key={`${transit}consigneeName`}
                    columnWidth={4}
                    title={t('declaration.consignee')}
                    value={getValue(transit.consignee?.name)}
                    fieldLabels={
                      [TransitField.CONSIGNEE_TRADER_NAME, TransitField.CONSIGNEE_SECURITY_TRADER_NAME]
                    }
                    invalidFields={invalidFields}
                    onInvalidIconClick={onClickOpenLogView}
                  />,
                  <DataRow
                    key={`${transit}consigneeCity`}
                    columnWidth={4}
                    value={`${getValue(transit.consignee?.city) !== '' ? (`${getValue(transit.consignee?.street)},
                    ${getValue(transit.consignee?.city)}`)
                      : getValue(transit.consignee?.street)}`}
                    secondValue={`${getValue(transit.consignee?.zip) !== ''
                      ? (`${getClassifierName(transit.consignee?.country, countries, i18n.language)}, ${
                        getValue(transit.consignee?.zip)}`)
                      : getClassifierName(transit.consignee?.country, countries, i18n.language)}`}
                    fieldLabels={[
                      TransitField.CONSIGNEE_TRADER_STREET_AND_NUMBER,
                      TransitField.CONSIGNEE_TRADER_CITY,
                      TransitField.CONSIGNEE_TRADER_POSTAL_CODE,
                      TransitField.CONSIGNEE_TRADER_COUNTRY_CODE,
                      TransitField.CONSIGNEE_SECURITY_TRADER_STREET_AND_NUMBER,
                      TransitField.CONSIGNEE_SECURITY_TRADER_CITY,
                      TransitField.CONSIGNEE_SECURITY_TRADER_POSTAL_CODE,
                      TransitField.CONSIGNEE_SECURITY_TRADER_COUNTRY_CODE,
                    ]}
                    invalidFields={invalidFields}
                    onInvalidIconClick={onClickOpenLogView}
                    isLast
                  />]
                : [
                  <small
                    key={`goodsTradersEnabledBoolean${transit.id}`}
                    className="text-muted"
                  >
                    {t('declaration.goodsTradersEnabled')}
                  </small>,
                ]
            }
            {
              transit.isSecurityEnabled && transit.carrier && (
                <>
                  <DataRow
                    columnWidth={4}
                    title={t('declaration.carrier')}
                    value={getValue(transit.carrier?.name)}
                    fieldLabels={[TransitField.CARRIER_TRADER_NAME]}
                    invalidFields={invalidFields}
                    onInvalidIconClick={onClickOpenLogView}
                  />
                  <DataRow
                    columnWidth={4}
                    value={`${getValue(transit.carrier?.street)}, ${getValue(transit.carrier?.city)}`}
                    secondValue={`${getClassifierName(transit.carrier?.country, countries, i18n.language)},
                    ${getValue(transit.carrier?.zip)}`}
                    fieldLabels={
                      [
                        TransitField.CARRIER_TRADER_STREET_AND_NUMBER,
                        TransitField.CARRIER_TRADER_CITY,
                        TransitField.CARRIER_TRADER_POSTAL_CODE,
                        TransitField.CARRIER_TRADER_COUNTRY_CODE,
                      ]
                    }
                    invalidFields={invalidFields}
                    onInvalidIconClick={onClickOpenLogView}
                  />
                </>
              )
            }
          </div>
          <div className="p-4 col-12 col-lg-6 border-end">
            <DataRow
              title={t('declaration.departureTruckNo')}
              value={getValue(transit.transport?.departureTruckNo)}
              fieldLabels={[TransitField.HEADER_IDENTITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.departureNationality')}
              value={getClassifierName(transit.transport?.departureNationality, countries, i18n.language)}
              extraValue={getValue(transit.transport?.departureNationality, 'COUNTRY_')}
              fieldLabels={[TransitField.HEADER_NATIONALITY_OF_MEANS_OF_TRANSPORT_AT_DEPARTURE]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.borderCrosserTruckNo')}
              value={getValue(transit.transport?.borderCrosserTruckNo)}
              fieldLabels={[TransitField.HEADER_IDENTITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.borderCrosserNationality')}
              value={getClassifierName(transit.transport?.borderCrosserNationality, countries, i18n.language)}
              extraValue={getValue(transit.transport?.borderCrosserNationality, 'COUNTRY_')}
              fieldLabels={[TransitField.HEADER_NATIONALITY_OF_MEANS_OF_TRANSPORT_CROSSING_BORDER]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.departureCustomsOffice')}
              value={customsOffices.find((office) => office.code === transit.transport?.departureCustomsOffice)?.codeLabel || ''}
              extraValue={getClassifierName(transit.transport?.departureCustomsOffice, customsOffices, i18n.language)}
              fieldLabels={[TransitField.DEPARTURE_CUSTOMS_OFFICE_REFERENCE_NUMBER]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.destinationCustomsOffice')}
              value={customsOffices.find((office) => office.code === transit.transport?.destinationCustomsOffice)?.codeLabel || ''}
              extraValue={getClassifierName(transit.transport?.destinationCustomsOffice, customsOffices, i18n.language)}
              fieldLabels={[TransitField.DESTINATION_CUSTOMS_OFFICE_REFERENCE_NUMBER]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.dispatchCountry')}
              value={getClassifierName(transit.declarationInfo?.dispatchCountryCode, countries, i18n.language)}
              extraValue={getValue(transit.declarationInfo?.dispatchCountryCode, 'COUNTRY_')}
              fieldLabels={[TransitField.HEADER_COUNTRY_OF_DISPATCH_EXPORT_CODE]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.destinationCountry')}
              value={getClassifierName(transit.declarationInfo?.destinationCountryCode, countries, i18n.language)}
              extraValue={getValue(transit.declarationInfo?.destinationCountryCode, 'COUNTRY_')}
              fieldLabels={[TransitField.HEADER_COUNTRY_OF_DESTINATION_CODE]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
            <DataRow
              title={t('declaration.transitCountries')}
              value={getValue(transitCountriesCommaSeparated)}
              isLast
              fieldLabels={[TransitField.ITINERARY_COUNTRY_OF_ROUTING_CODE]}
              invalidFields={invalidFields}
              onInvalidIconClick={onClickOpenLogView}
            />
          </div>
        </div>
        <div
          className="section__header"
          role="presentation"
          onClick={() => toggleGoodsOpen(!isGoodsOpen)}
        >
          <div className="d-flex justify-content-center align-items-center text-black-50 flex-wrap">
            <i className="fal fa-container-storage fa-md me-2" />
            <span className="pe-2 border-end">Goods</span>
            <span className={`${packagesCountIconData.isInvalid && 'd-flex text-danger align-items-baseline'} ms-2 pe-2 border-end`}>
              <span>{`${t('declaration.totalGrossMass')}:`}</span>
              <span className="ms-1">
                {
                  goodsItems.length > 0
                    ? goodsItems
                      .map((item) => item.grossWeight)
                      .reduce((total, current) => (current != null && total != null ? total + current : total), 0)
                    : '-'
                }
              </span>
              <InvalidIcon iconData={packagesCountIconData} onInvalidIconClick={onClickOpenLogView} />
            </span>
            <span className={`${packagesCountIconData.isInvalid && 'd-flex text-danger align-items-baseline'} ms-2 pe-2 border-end`}>
              <span>{`${t('declaration.packagesCount')}:`}</span>
              <span className="ms-1">{getValue(transit.declarationInfo?.packagesCount?.toString())}</span>
              <InvalidIcon iconData={packagesCountIconData} onInvalidIconClick={onClickOpenLogView} />
            </span>
            <span className={`${guaranteeAmountIconData.isInvalid && 'd-flex text-danger align-items-baseline'} ms-2 pe-2 border-end`}>
              <span>{`${t('declaration.guaranteeAmount')}:`}</span>
              <span className="ms-1">{getValue(transit.guarantee?.amount?.toString())}</span>
              <span className="ps-1">EUR</span>
              <InvalidIcon iconData={guaranteeAmountIconData} onInvalidIconClick={onClickOpenLogView} />
            </span>
          </div>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center"
          >
            <i className={isGoodsOpen ? 'fal fa-chevron-up' : 'fal fa-chevron-down'} />
          </button>

        </div>
        <div className={`${isGoodsOpen ? 'active' : ''} section__body flex-column`}>
          {
            goodsItems.map((goodsItem) => (
              <DetailViewGoodsRow
                key={goodsItem.id ?? 'GoodsItemWithoutId'}
                transit={transit}
                countries={countries}
                goodsItem={goodsItem}
                packageTypes={packageTypes}
                documentTypes={documentTypes}
                previousDocumentTypes={previousDocumentTypes}
                procedureTypes={procedureTypes}
                invalidFields={invalidFields}
                onInvalidIconClick={onClickOpenLogView}
                isLoading={isLoading}
              />
            ))
          }
        </div>
      </div>
      <FeedbackModal
        declarationId={declarationId}
        isFeedbackModalOpen={isFeedbackModalOpen}
        setIsFeedbackModalOpen={setIsFeedbackModalOpen}
        declarationFeedback={transit.feedback}
        transit={transit}
      />
      <NotesModal
        declarationId={declarationId}
        isNotesModalOpen={isNotesModalOpen}
        setIsNotesModalOpen={setIsNotesModalOpen}
        originalRow={originalRow}
      />
    </>
  )
}
