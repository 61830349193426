import React from 'react'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import CustomsOfficesNoOptionsMessage from 'components/react-select/CustomsOfficesNoOptionsMessage'
import { AccessLevel } from '../../../../context/DeclarationContext'
import { filterCustomsOfficeOptions, getSelectValue } from '../../../../helpers'
import { ISelectOption } from '../../../../types/IClassifier'
import { TransportCustomsOfficeRow } from '../hooks/useTransportCustomsOffices'

interface TransportCustomsOfficeSelectProps {
  transportCustomsOffice: TransportCustomsOfficeRow;
  accessLevel: AccessLevel;
  customsOfficesOptions: Array<ISelectOption>;

}
function TransportCustomsOfficeSelect(props: TransportCustomsOfficeSelectProps) {
  const { t } = useTranslation()
  const { transportCustomsOffice, accessLevel, customsOfficesOptions } = props

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const loadOptions = (inputValue: string, loadingCallback: (func: any) => void) => {
    loadingCallback(filterCustomsOfficeOptions(inputValue, customsOfficesOptions))
  }

  return (
    <div className="row align-items-baseline">
      <div
        className="col-10"
        key={`TransportCustomsOfficeSelect_select_${transportCustomsOffice.sortOrder}`}
      >
        <AsyncSelect
          components={{
            NoOptionsMessage: (messageProps) => CustomsOfficesNoOptionsMessage(messageProps),
          }}
          key={`TransportCustomsOfficeSelect_value_${transportCustomsOffice.transitCustomsOffice}`}
          isDisabled={accessLevel === AccessLevel.VIEW}
          className="select mt-2"
          classNamePrefix="select"
          menuPlacement="auto"
          defaultOptions={[...customsOfficesOptions.slice(0, 200)]}
          loadOptions={loadOptions}
          placeholder={t('common.customsPlaceholder')}
          value={getSelectValue(transportCustomsOffice.transitCustomsOffice, customsOfficesOptions)}
          onChange={(option: ISelectOption | null) => {
            if (option !== null) {
              transportCustomsOffice.setTransitCustomsOffice(option.value)
            }
          }}
        />
      </div>
      <button
        key={`TransportCustomsOfficeSelect_delete_${transportCustomsOffice.sortOrder}`}
        type="button"
        className="btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm px-0"
        onClick={() => {
          transportCustomsOffice.deleteItem()
        }}
      >
        <i className="far fa-trash" />
      </button>
    </div>
  )
}

export default TransportCustomsOfficeSelect

