import React, { useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import { AxiosError } from 'axios'
import FormRow from '../../components/FormRow'
import { TitleBar } from '../../components/TitleBar'
import UserService, { ChangeUserDetails, ChangeUserPasswordRequest, TopBarUser } from '../Customer/services/user.service'
import AuthenticationService from '../../services/auth.service'
import { UserContext } from '../../context/UserContext'
import { usePasswordValid } from '../../hooks/usePasswordValid'

interface PasswordChangeForm {
  currentPassword: string
  newPassword: string
  passwordRepeated: string
}

function UserProfile() {
  const store = useStore()
  const history = useNavigate()
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [isChangingPassword, setChangingPassword] = useState<boolean>(false)
  const [isChangingUserDetails, setIsChangingUserDetails] = useState<boolean>(false)
  const [errors] = useState<Array<string>>([])

  const { user, customer } = useContext(UserContext)
  const [userProfile, setUserProfile] = useState<TopBarUser>(
    {
      id: user?.id ?? -1,
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
      phone: user?.phone ?? '',
      email: user?.email ?? '',
      customerName: user?.customerName ?? '',
      customerId: customer?.id || 0,
      customerEmail: customer?.contactEmail ?? '',
    },
  )
  const [passwordForm, setPasswordForm] = useState<PasswordChangeForm>(
    {
      currentPassword: '',
      newPassword: '',
      passwordRepeated: '',
    },
  )

  const [
    validLength,
    match,
    isValid,
  ] = usePasswordValid({
    password: passwordForm.newPassword,
    confirmPassword: passwordForm.passwordRepeated,
    requiredLength: 7,
  })

  const loadingSpinner = () => (<i className="fal fa-spinner fa-spin me-1" />)

  const submitPasswordChange = () => {
    if (isValid) {
      toast.error(t('messages.passwordsLetterError'))
      return
    }
    if (!validLength) {
      toast.error(t('messages.passwordsLengthError'))
      return
    }
    if (match) {
      toast.error(t('messages.passwordsMatchError'))
      return
    }
    setSaving(true)

    const request: ChangeUserPasswordRequest = {
      currentPassword: passwordForm.currentPassword,
      newPassword: passwordForm.newPassword,
    }

    UserService.changeUserPassword(request)
      .then(() => {
        toast.success(t('messages.passwordsChanged', { context: 'successful' }))
        setTimeout(() => {
          history('/login')
          AuthenticationService.logout()
          store.dispatch({ type: 'RESET_DECLARATION' })
        }, 3000)
        setSaving(false)
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          if (error.response.status >= 500) {
            toast.error(t('messages.passwordsChanged', { context: 'failed' }))
          }
        } else {
          toast.error(t('common.error'))
        }
        setSaving(false)
      })
  }

  const submitUserDetailsChange = () => {
    const request: ChangeUserDetails = {
      firstname: userProfile.firstname,
      lastname: userProfile.lastname,
      phone: userProfile.phone,
    }
    setLoading(true)

    UserService.changeUserDetails(request)
      .then(() => {
        toast.success(t('messages.profileUpdated'))
        setSaving(false)
        setIsChangingUserDetails(false)
      })
    setLoading(false)
  }

  const passwordChangeForm = useMemo(() => (
    <>
      <div className="heading position-relative mt-4">
        <h3>
          {`${t('buttons.change')} ${t('common.password')}`}
        </h3>
        <div className="heading-button d-flex align-items-center">
          {
            !isChangingPassword
            && (
              <button
                className="btn btn-outline-dark btn-sm d-flex align-items-center"
                type="button"
                onClick={() => {
                  setChangingPassword(true)
                }}
              >
                <i className="far fa-edit me-1" />
                <span>{t('buttons.change')}</span>
              </button>
            )
          }
          {
            isChangingPassword
            && (
              <>
                <button
                  className="btn btn-outline-dark btn-sm d-flex align-items-center me-2"
                  type="button"
                  onClick={() => {
                    setChangingPassword(false)
                  }}
                >
                  <span>{t('buttons.cancel')}</span>
                </button>
                <button
                  className="btn btn-secondary text-primary btn-sm d-flex align-items-center"
                  type="submit"
                  disabled={saving}
                  onClick={() => submitPasswordChange()}
                >
                  {!saving ? (
                    <i className="far fa-save me-1" />
                  ) : loadingSpinner()}
                  <span>{t('buttons.submit')}</span>
                </button>
              </>
            )
          }
        </div>
      </div>

      {
        isChangingPassword
        && (
          <>
            <FormRow title={t('user.password', { context: 'current' })}>
              <input
                required
                id="current-password"
                type="password"
                className="form-control"
                placeholder={t('common.password')}
                autoComplete="password"
                value={passwordForm.currentPassword}
                onChange={(event) => {
                  setPasswordForm({
                    ...passwordForm,
                    currentPassword: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow title={t('user.password', { context: 'new' })}>
              <input
                required
                id="new-password"
                type="password"
                className="form-control"
                autoComplete="new-password"
                placeholder={t('user.password', { context: 'new' })}
                value={passwordForm.newPassword}
                onChange={(event) => {
                  setPasswordForm({
                    ...passwordForm,
                    newPassword: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow title={t('user.password', { context: 'repeat' })}>
              <input
                required
                id="repeat-password"
                type="password"
                className="form-control"
                autoComplete="new-password"
                placeholder={t('user.password', { context: 'repeat' })}
                value={passwordForm.passwordRepeated}
                onChange={(event) => {
                  setPasswordForm({
                    ...passwordForm,
                    passwordRepeated: event.target.value,
                  })
                }}
              />
            </FormRow>
            <FormRow title="">
              {
                errors.length > 0
                && (
                  <div className="mb-2">
                    {
                      errors.map((message) => (
                        <small key={message} className="text-danger">
                          {message}
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </FormRow>
          </>
        )
      }
      {
        !isChangingPassword
        && (
          <small>{t('messages.passwordChangeLogoutWarning')}</small>
        )
      }
    </>
  ), [passwordForm, isChangingPassword, errors, saving])

  return (
    <>
      <TitleBar title={t('buttons.profile')} />
      <div className="user-profile__container">
        <div className="pt-4 px-4 col-12 col-xl-4 mb-5 mb-md-0">
          <div className="heading position-relative">
            <h3>
              {t('user.userProfileDetails')}
            </h3>
            <div className="heading-button d-flex align-items-center">
              {
                !isChangingUserDetails
                && (
                  <button
                    className="btn btn-outline-dark btn-sm d-flex align-items-center"
                    type="button"
                    onClick={() => {
                      setIsChangingUserDetails(true)
                    }}
                  >
                    <i className="far fa-edit me-1" />
                    <span>{t('buttons.change')}</span>
                  </button>
                )
              }
              {
                isChangingUserDetails
                && (
                  <>
                    <button
                      className="btn btn-outline-dark btn-sm d-flex align-items-center me-2"
                      type="button"
                      onClick={() => {
                        setIsChangingUserDetails(false)
                      }}
                    >
                      <span>{t('buttons.cancel')}</span>
                    </button>
                    <button
                      className="btn btn-secondary text-primary btn-sm d-flex align-items-center"
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        submitUserDetailsChange()
                      }}
                    >
                      {!loading ? (<i className="far fa-save me-1" />) : loadingSpinner()}
                      <span>{t('buttons.submit')}</span>
                    </button>
                  </>
                )
              }
            </div>
          </div>
          {
            !isChangingUserDetails && (
              <>
                <FormRow title={t('user.firstName')}>
                  <span className="value-label text-nowrap ">
                    {userProfile.firstname}
                  </span>
                </FormRow>
                <FormRow title={t('user.lastName')}>
                  <span className="value-label text-nowrap ">
                    {userProfile.lastname}
                  </span>
                </FormRow>
                <FormRow title={t('common.email')}>
                  <span className="value-label text-nowrap ">
                    {userProfile.email}
                  </span>
                </FormRow>
                <FormRow title={t('common.phone')}>
                  <span className="value-label text-nowrap">
                    {userProfile.phone}
                  </span>
                </FormRow>
              </>
            )
          }
          {
            isChangingUserDetails && (
              <>
                <FormRow title={t('user.firstName')}>
                  <input
                    required
                    id="firstname"
                    type="text"
                    className="form-control"
                    autoComplete="firstname"
                    placeholder={t('user.firstName')}
                    value={userProfile.firstname}
                    onChange={(event) => {
                      setUserProfile({
                        ...userProfile,
                        firstname: event.target.value,
                      })
                    }}
                  />
                </FormRow>

                <FormRow title={t('user.lastName')}>
                  <input
                    required
                    id="lastname"
                    type="text"
                    className="form-control"
                    autoComplete="lastname"
                    placeholder={t('user.lastName')}
                    value={userProfile.lastname}
                    onChange={(event) => {
                      setUserProfile({
                        ...userProfile,
                        lastname: event.target.value,
                      })
                    }}
                  />
                </FormRow>
                <FormRow title={t('user.phone')}>
                  <input
                    required
                    id="phone"
                    type="text"
                    className="form-control"
                    autoComplete="phone"
                    placeholder={t('user.phone')}
                    value={userProfile.phone !== null ? userProfile.phone : ''}
                    onChange={(event) => {
                      setUserProfile({
                        ...userProfile,
                        phone: event.target.value,
                      })
                    }}
                  />
                </FormRow>
              </>
            )
          }
          {passwordChangeForm}
        </div>
      </div>
    </>
  )
}

export default UserProfile
