import { RuleResult } from 'types/DeclarationP5'
import { removePrefix } from '../../../common/utils/classifier-util'
import { DeclarationForm } from '../schemas/declarationFormSchema'

const C0531Validator = {
  validateBorderTransportMeansConveyanceReferenceNumberRequirement: (formData: DeclarationForm): RuleResult => {
    if ((formData.security === '1' || formData.security === '2' || formData.security === '3')
      && removePrefix(formData.borderModeOfTransport, 'TRANSPORT_MODE_', 1) === '4') {
      return 'REQUIRED'
    }

    return 'OPTIONAL'
  },
} as const

export default C0531Validator
