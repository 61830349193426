const BULK_PACKAGE_TYPES = [
  'PACKAGE_TYPE_VQ', 'PACKAGE_TYPE_VG', 'PACKAGE_TYPE_VL', 'PACKAGE_TYPE_VY', 'PACKAGE_TYPE_VR', 'PACKAGE_TYPE_VS', 'PACKAGE_TYPE_VO',
]
const UNPACKED_PACKAGE_TYPES = ['PACKAGE_TYPE_NE', 'PACKAGE_TYPE_NF', 'PACKAGE_TYPE_NG']

export enum FieldUsage {
  REQUIRED,
  OPTIONAL,
  FORBIDDEN,
}

export function isBulkPackage(quantityUnit: string) {
  return BULK_PACKAGE_TYPES.indexOf(quantityUnit) !== -1
}

function isUnpackedPackage(quantityUnit: string) {
  return UNPACKED_PACKAGE_TYPES.indexOf(quantityUnit) !== -1
}

export function getFieldUsageAccordingToC60(quantityUnit: string | null, targetField: 'MARKS' | 'PIECES' | 'QUANTITY'): FieldUsage {
  if (quantityUnit === null) {
    return FieldUsage.REQUIRED
  }
  if (isBulkPackage(quantityUnit)) {
    if (targetField === 'MARKS') {
      return FieldUsage.OPTIONAL
    }
    if (targetField === 'QUANTITY' || targetField === 'PIECES') {
      return FieldUsage.FORBIDDEN
    }
  }

  if (isUnpackedPackage(quantityUnit)) {
    if (targetField === 'MARKS') {
      return FieldUsage.OPTIONAL
    }
    if (targetField === 'QUANTITY') {
      return FieldUsage.FORBIDDEN
    }
    if (targetField === 'PIECES') {
      return FieldUsage.REQUIRED
    }
  }

  if (targetField === 'MARKS') {
    return FieldUsage.REQUIRED
  }
  if (targetField === 'QUANTITY') {
    return FieldUsage.REQUIRED
  }
  if (targetField === 'PIECES') {
    return FieldUsage.FORBIDDEN
  }

  return FieldUsage.REQUIRED
}
