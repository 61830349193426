import React from 'react'

/* eslint-disable  @typescript-eslint/no-explicit-any */
function TableRowDirectoStatus({ value }: any) {
  switch (value) {
    case 'IDLE':
      return <span className="text-muted">IDLE</span>
    case 'ERROR':
      return <span className="text-danger">ERROR</span>
    case 'SUBMITTED':
      return <span className="text-success">SUBMITTED</span>
    case 'QUEUED':
      return <span className="text-warning">QUEUED</span>
    default:
      return <span className="text-muted">{value ? value.replaceAll('_', ' ') : value}</span>
  }
}
export default TableRowDirectoStatus
