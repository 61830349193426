import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ROUTES from '../../../../config/routes'
import { Restriction, RestrictionType } from '../../../../types/restriction'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import RestrictionService from '../../services/restriction.service'
import TableRowStatus from '../../../Transits/Table/TableRowStatus'

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface TableRowProps {
  row: any
  index: number
  refreshPage: () => void
}

function TableRow(props: TableRowProps) {
  const {
    row,
    refreshPage,
  } = props
  const history = useNavigate()
  const { t } = useTranslation()
  const [isOptionsOpen, setOptionsOpen] = useState<boolean>(false)
  const [isDeletionModalVisible, setDeletionModalVisible] = useState<boolean>(false)
  const [isDeletionLoading, setDeletionLoading] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<Restriction | null>(null)

  const editRow = (id: number) => {
    history(`${ROUTES.restriction}/${id}`)
  }

  const toggleDeletionModalVisibility = () => {
    if (!isDeletionLoading) { setDeletionModalVisible(!isDeletionModalVisible) }
  }

  const openDeleteModal = (restriction: Restriction) => {
    toggleDeletionModalVisibility()
    setDeleteItem(restriction)
  }
  const deleteRestriction = () => {
    setDeletionLoading(true)
    if (deleteItem == null || isDeletionLoading || deleteItem.id === null) {
      return
    }

    RestrictionService.deleteRestriction(deleteItem.id)
      .then(() => {
        toast.success(t('messages.delete', { context: 'successful' }))
        refreshPage()
      })
      .catch(() => toast.error(t('messages.delete', { context: 'failed' })))
      .finally(() => {
        toggleDeletionModalVisibility()
        setDeletionLoading(false)
      })
  }

  return (
    <React.Fragment key={`${row.id}_row`}>
      <tr
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...row.getRowProps()}
      >
        {/* eslint-disable  @typescript-eslint/no-explicit-any */}
        {row.cells.map((cell: any) => (
          <td
            role="presentation"
            className={
              `${cell.column.id === 'id' ? 'text-left' : ''} ${
                (!isOptionsOpen && !(cell.column.id === 'mrn' || cell.column.id === 'id')) ? 'pointer' : ''} ${
                cell.column.className ? cell.column.className : ''
              }`
            }
            onClick={() => {
              if (isOptionsOpen) {
                return setOptionsOpen(!isOptionsOpen)
              }

              if (cell.column.id === 'mrn' || cell.column.id === 'id') {
                return null
              }
              return editRow(row.original.id)
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...cell.getCellProps()}
          >
            {cell.column.id === 'status' && (
              <TableRowStatus value={cell.value} />
            )}
            {
              cell.column.id === 'id'
              && (
                <button
                  type="button"
                  className="btn btn-link no-underline d-flex align-items-center justify-content-between
                           border-0 w-100 h-100 p-0 lh-1"
                  onClick={() => editRow(row.original.id)}
                >
                  <span className="text-decoration-underline">
                    {row.cells[0].render('Cell')}
                  </span>
                </button>
              )
            }
            {
              cell.column.id === 'type' && row.original.type === RestrictionType.COMMODITY_CODE
              && (
                <span>
                  {`${t('restriction.commodityCodeType')}`}
                </span>
              )
            }
            {
              cell.column.id === 'type' && row.original.type === RestrictionType.COMMODITY_DESCRIPTION
              && (
                <span>
                  {`${t('restriction.commodityDescriptionType')}`}
                </span>
              )
            }
            {
              cell.column.id === 'type' && row.original.type === RestrictionType.COMMODITY_SANCTION
                && (
                  <span>
                    {`${t('restriction.commoditySanctionType')}`}
                  </span>
                )
            }
            {
              cell.column.id === 'type' && row.original.type === RestrictionType.TRUCK
                && (
                  <span>
                    {`${t('restriction.commodityTruckType')}`}
                  </span>
                )
            }
            {
              cell.column.id === 'type' && row.original.type === RestrictionType.TRADER
                && (
                  <span>
                    {`${t('restriction.commodityTraderType')}`}
                  </span>
                )
            }
            {
              cell.column.id !== 'id' && cell.column.id !== 'type' && cell.column.id !== 'status'
              && (
                cell.render('Cell')
              )
            }
          </td>
        ))}

        <td className="dropdown-cell w-1">
          <Dropdown
            onToggle={(isOpen) => setOptionsOpen(isOpen)}
          >
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="link"
              className="p-0 hide-caret"
            >
              <i className="fas fa-ellipsis-h fa-md" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow">
              <Dropdown.Item onClick={() => openDeleteModal(row.original)}>
                <i className="fal fa-trash me-2 fa-md" />
                <span>{t('buttons.delete')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </td>
      </tr>

      <ConfirmationModal
        title={t('buttons.delete')}
        messageBody={t('messages.deleteConfirmMessage', { 0: deleteItem?.id })}
        isVisible={isDeletionModalVisible}
        toggleVisibility={toggleDeletionModalVisibility}
        isLoading={isDeletionLoading}
        onConfirmation={() => deleteRestriction()}
        onRejection={() => {
          toggleDeletionModalVisibility()
          setDeleteItem(null)
        }}
      />
    </React.Fragment>
  )
}

export default TableRow
