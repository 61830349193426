import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { AdditionalInformation } from '../../../form/schemas/commonConsignmentSchemas'
import { blankAdditionalInformation } from '../../../form'
import { ADDITIONAL_INFORMATION_CODE_PREFIX } from '../../../../common/utils/classifier-util'
import { hasText } from '../../../../common/utils/common-util'

type NotImportableFields = 'sequenceNumber' | 'id' | 'deleted'

function isAdditionalInformationField(field: string): field is Exclude<ObjectKeys<AdditionalInformation>, NotImportableFields> {
  return field in blankAdditionalInformation
}

export default function mapAdditionalInformation(
  newImportedItemObject: AdditionalInformation[],
  consignmentItemObjectSubfield: string,
  cellValue: string,
  newSequenceNumber: number,
) {
  if (!isAdditionalInformationField(consignmentItemObjectSubfield)) {
    return
  }

  let additionalInformation = newImportedItemObject
    .find((doc) => doc.sequenceNumber === newSequenceNumber)

  if (additionalInformation === undefined) {
    additionalInformation = {
      ...blankAdditionalInformation,
      sequenceNumber: newSequenceNumber,
    }
    newImportedItemObject.push(additionalInformation)
  }
  switch (consignmentItemObjectSubfield) {
    case 'code':
      additionalInformation[consignmentItemObjectSubfield] = hasText(cellValue) ? ADDITIONAL_INFORMATION_CODE_PREFIX + cellValue : ''
      break
    case 'text':
      additionalInformation[consignmentItemObjectSubfield] = cellValue
      break
    default:
      throw Error(`Unknown additional information field ${consignmentItemObjectSubfield}`)
  }
}
