import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { PageFilterRequest, PageResponse } from '../../../types/PageResponse'
import { DeclarationAudit } from '../../../types/DeclarationAudit'

function getAuditPage(
  {
    pageIndex,
    pageSize,
    filters,
    statusFilter,
  }: PageFilterRequest,
): Promise<PageResponse<DeclarationAudit>> {
  return new Promise((resolve, reject) => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filterParam: any = {}
    filterParam.page = pageIndex
    filterParam.size = pageSize

    if (statusFilter !== null && statusFilter !== '') {
      filterParam.status = statusFilter
    }
    // eslint-disable-next-line no-shadow,no-restricted-syntax
    for (const filter of filters) {
      if (filter.key === 'modified' || filter.key === 'created') {
        if (filter.value.match('(\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d)$') !== null) {
          filterParam[filter.key] = filter.value
        }
      } else if (filter.value !== null && filter.value !== '') {
        filterParam[filter.key] = filter.value
      }
    }
    axios.get(
      apiService.getFullApiUrl('/declaration/audit'),
      {
        params: {
          ...filterParam,
        },
      },
    )
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      })
      .catch((error: AxiosError) => {
        toast.error('Getting declarations failed')
        reject(error)
      })
  })
}

function putAudit(declarationAudit: DeclarationAudit) {
  return axios
    .put(
      apiService.getFullApiUrl(`/declaration/audit/${declarationAudit.id}`),
      { reason: declarationAudit.reason, status: declarationAudit.status },
    )
    .then(handleResponseData)
    .catch(handleApiException)
}

const DeclarationAuditService = {
  getAuditPage,
  putAudit,
}

export default DeclarationAuditService
