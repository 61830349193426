import { AuthorisationResponse, CreateOrUpdateAuthorisationRequest } from '../../../common/models'
import { Authorisation } from '../../form/schemas/commonConsignmentSchemas'

export function toCreateOrUpdateAuthorisationRequest(authorisation: Authorisation, transitOperationId: number, index: number)
  : CreateOrUpdateAuthorisationRequest {
  return {
    id: authorisation.id,
    referenceNumber: authorisation.referenceNumber,
    sequenceNumber: index,
    transitOperationId,
    type: authorisation.type,
  }
}

export function parseAuthorisationsResponse(response?: AuthorisationResponse | null): Authorisation {
  return {
    id: response?.id ?? null,
    referenceNumber: response?.referenceNumber ?? '',
    sequenceNumber: response?.sequenceNumber ?? 0,
    type: response?.type ?? '',
  }
}
