import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'
import { ISeal } from './types/Seal'

function getSeals(transportId: number): Promise<Array<ISeal>> {
  return new Promise((resolve) => {
    axios.get(apiService.getFullApiUrl(`/seal/${transportId}`))
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      })
  })
}

function postSeal(transportId: number, seal: ISeal): Promise<ISeal> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl('/seal'),
      {
        identity: seal.identity,
        transportId,
      },
    )
      .then(handleResponseData)
      .then((value) => {
        resolve(value)
      })
      .catch((error: AxiosError) => {
        toast.warn('Error while saving Seal')
        reject(error)
      })
  })
}

function updateSeal(transportId: number, seal: ISeal) {
  return axios.put(
    apiService.getFullApiUrl(`/seal/${seal.id}`),
    {
      identity: seal.identity,
      transportId,
    },
  )
    .then<ISeal>(handleResponseData)
    .catch(handleApiException)
}

function deleteSeal(transportId: number, seal: ISeal): Promise<ISeal> {
  return axios.delete(apiService.getFullApiUrl(`/seal/${seal.id}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

const SealService = {
  getSeals,
  postSeal,
  updateSeal,
  deleteSeal,
}

export default SealService
