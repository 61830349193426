/* tslint:disable */
/* eslint-disable */
/**
 * Tardek API Endpoints
 * This Is a documentation for T1 Tardek API Endpoints. <br />
 *
 * OpenAPI spec version: 1.0.0
 * Contact: info@bitweb.ee
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @interface DocumentRequest
 */
export interface DocumentRequest {
    /**
     * Document ID
     * @type {string}
     * @memberof DocumentRequest
     */
    id: number | null;
    /**
     * Internal type of the document
     * @type {string}
     * @memberof DocumentRequest
     */
    type?: DocumentRequestTypeEnum;
    /**
     * Document Reference Number
     * @type {string}
     * @memberof DocumentRequest
     */
    referenceNumber?: string;
    /**
     * Document Transport Document Reference Number
     * @type {string}
     * @memberof DocumentRequest
     */
    transportDocumentReferenceNumber?: string;
    /**
     * Document Complement of information
     * @type {string}
     * @memberof DocumentRequest
     */
    complementOfInformation?: string;
    procedureType?: string;
    /**
     * Document line number
     * @type {string}
     * @memberof DocumentRequest
     */
    documentLineItemNumber?: string;
    /**
     * Document Type
     * @type {number}
     * @memberof DocumentRequest
     */
    documentType: string;
    /**
     * Consignment Item ID
     * @type {number}
     * @memberof DocumentRequest
     */
    consignmentItemId: number | null;
    /**
     * Transit Operation ID
     * @type {number}
     * @memberof DocumentRequest
     */
    transitOperationId: number;
    /**
     * Document Sequence Number
     * @type {number}
     * @memberof DocumentRequest
     */
    sequenceNumber: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentRequestTypeEnum {
    PREVIOUS = 'PREVIOUS',
    SUPPORTING = 'SUPPORTING',
    TRANSPORT = 'TRANSPORT',
    NOTE = 'NOTE'
}

