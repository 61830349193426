import React, { Dispatch, SetStateAction, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserService, { UserListResponse } from '../services/user.service'
import FormRow from '../../../components/FormRow'
import TextInput from '../../../components/Form/Input'

interface ChangeUserModalProps {
  isUserModalVisible: boolean;
  toggleUserModal: Dispatch<SetStateAction<boolean>>;
  existingUser: UserListResponse;
  customerId: number;
}

function ChangeUserModal(props: ChangeUserModalProps) {
  const {
    isUserModalVisible, toggleUserModal, existingUser, customerId,
  } = props
  const { t } = useTranslation()

  const [user, setUser] = useState<UserListResponse>(existingUser)

  const submitUserChanges = () => {
    UserService.updateUser({
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone: user.phone,
      customerId,
      remoteStaffCode: user.remoteStaffCode,
    }).then(() => {
      toast.success('User updated')
      existingUser.remoteStaffCode = user.remoteStaffCode
      existingUser.phone = user.phone
      existingUser.lastname = user.lastname
      existingUser.firstname = user.firstname
      toggleUserModal(false)
    })
      .catch(() => {
        toast.error('Could not update user')
      })
  }

  return (
    <Modal
      show={isUserModalVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => toggleUserModal(false)}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <h2 className="text-capitalize">{t('common.edit')}</h2>
          <button
            type="button"
            className="btn btn-link no-underline btn-lg d-flex justify-content-center align-items-center px-0"
            onClick={() => toggleUserModal(false)}
          >
            <span>{t('buttons.close')}</span>
            <i className="fal fa-times fa-2x ms-2" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormRow title={t('user.firstName')}>
          <TextInput
            name="firstname"
            placeholder={t('common.fieldPlaceholder')}
            value={user.firstname}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, firstname: event.target.value })}
          />
        </FormRow>
        <FormRow title={t('user.lastName')}>
          <TextInput
            name="lastname"
            placeholder={t('common.fieldPlaceholder')}
            value={user.lastname}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, lastname: event.target.value })}
          />
        </FormRow>
        <FormRow title={t('common.email')}>
          <TextInput
            type="email"
            name="email"
            placeholder={t('common.fieldPlaceholder')}
            value={user.email}
            disabled
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: event.target.value })}
          />
        </FormRow>
        <FormRow title={t('common.phone')}>
          <TextInput
            name="tel"
            placeholder={t('common.fieldPlaceholder')}
            value={user.phone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, phone: event.target.value })}
          />
        </FormRow>
        <FormRow title={t('directo.remoteStaffCode')}>
          <TextInput
            name="remoteStaffCode"
            placeholder={t('common.fieldPlaceholder')}
            value={user.remoteStaffCode ?? ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, remoteStaffCode: event.target.value })}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-dark btn-lg m-0 text-primary"
          onClick={submitUserChanges}
        >
          <span>{t('common.edit')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeUserModal
