import {
  Dispatch, SetStateAction, useEffect, useMemo, useState,
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '../../../../../../types/IClassifier'
import PrivacyService from '../../../../service/privacy.service'

function usePrivacyPolicy(
  setIsConfirmationModalOpen: Dispatch<SetStateAction<boolean>>,
  selectOptions: ISelectOption[],
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,
) {
  const { t } = useTranslation()

  const [language, setLanguage] = useState<ISelectOption>(selectOptions[0])
  const [privacyTermsRus, setPrivacyTermsRus] = useState('')
  const [privacyTermsEng, setPrivacyTermsEng] = useState('')

  const currentTerms = useMemo(() => (language.value === 'ru' ? privacyTermsRus : privacyTermsEng), [language.value, privacyTermsRus, privacyTermsEng])

  function loadPrivacyPolicy(lang: 'en' | 'ru') {
    setLoading(true)
    PrivacyService.getPrivacyTerms(lang)
      .then((response) => {
        if (lang === 'ru') {
          setPrivacyTermsRus(response.content)
        } else {
          setPrivacyTermsEng(response.content)
        }
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  useEffect(() => {
    loadPrivacyPolicy('en')
    loadPrivacyPolicy('ru')
  }, [])

  const setCurrentTerms = (content: string) => (language.value === 'ru' ? setPrivacyTermsRus(content) : setPrivacyTermsEng(content))

  const submitUpdatedPrivacyPolicy = () => {
    if (loading) {
      return
    }
    if (!(privacyTermsRus && privacyTermsEng)) {
      toast.error(t('messages.bothLanguages'))
      return
    }

    setLoading(true)
    PrivacyService.changeTerms(privacyTermsEng, privacyTermsRus)
      .then(() => {
        setIsConfirmationModalOpen(false)
        setLoading(false)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        setLoading(false)
      })
  }

  return {
    currentTerms,
    setCurrentTerms,
    language,
    setLanguage,
    submitUpdatedPrivacyPolicy,
  }
}

export default usePrivacyPolicy
