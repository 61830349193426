import { ISelectOption } from 'types/IClassifier'
import { getNameForLocale } from 'helpers'
import { CodelistResponse } from '../../models'

export type CodelistGroup =
  'COUNTRIES'
  | 'TRANSPORT_MODES'
  | 'TRANSPORT_IDENTIFICATIONS'
  | 'PREVIOUS_DOCUMENTS'
  | 'SUPPORTING_DOCUMENTS'
  | 'TRANSPORT_DOCUMENTS'
  | 'PREVIOUS_DOCUMENT_EXPORTS'
  | 'PACKAGES'
  | 'UNITS'
  | 'AUTHORISATIONS'
  | 'ADDITIONAL_INFORMATION'
  | 'ADDITIONAL_REFERENCES'
  | 'PAYMENT_METHODS'
  | 'CUSTOMS_OFFICES'
  | 'TRANSIT_CUSTOMS_OFFICES'
  | 'TRANSIT_EXIT_CUSTOMS_OFFICES'
  | 'DEPARTURE_CUSTOMS_OFFICES'
  | 'DESTINATION_CUSTOMS_OFFICES'
  | 'QUALIFIER_OF_IDENTIFICATION'
  | 'TRANSIT_TABLE'

export function mapSelectOptions(codelist: CodelistResponse[] | undefined, language: string): ISelectOption[] {
  if (codelist === undefined || codelist === null) {
    return [] as ISelectOption[]
  }

  return codelist.map<ISelectOption>((codelistItem) => ({
    value: codelistItem.code,
    label: `${codelistItem.codeLabel} - ${getNameForLocale(codelistItem, language)}`,
  }))
}
export const getCountryForOffice: (item: CodelistResponse) => string = (item: CodelistResponse) => item.codeLabel?.substring(0, 2) ?? ''
