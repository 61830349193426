import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { removePrefix } from '../../../../common/utils/classifier-util'
import { IS_TRANSITIONAL_PERIOD } from '../../../../common/utils/ncts-constants'

const B1897Validator = {
  validateDepartureTransportMeansNationalityRequirement: (formData: DeclarationForm): RuleResult => {
    if (!IS_TRANSITIONAL_PERIOD) {
      return 'REQUIRED'
    }

    if (removePrefix(formData.inlandModeOfTransport, 'TRANSPORT_MODE_') === '2') {
      return 'NOT_ALLOWED'
    }

    if (formData.containerIndicator === 'true') {
      return 'OPTIONAL'
    }

    return 'REQUIRED'
  },
} as const

export default B1897Validator
