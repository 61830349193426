import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CodelistPrefix, removePrefix } from '../../../utils/classifier-util'
import { DocumentResponse } from '../../../models'
import TransitSidebarConsignmentItemDocumentFilesSection from './TransitSidebarConsignmentItemDocumentFilesSection'

export interface TransitSidebarConsignmentItemDocumentSectionProps {
  document: DocumentResponse
  documentTypePrefix: CodelistPrefix
}

function TransitSidebarConsignmentItemDocumentSection({
  document,
  documentTypePrefix,
  children,
}: PropsWithChildren<TransitSidebarConsignmentItemDocumentSectionProps>) {
  const {
    id,
    sequenceNumber,
    documentType,
    referenceNumber,
    files,
  } = document
  const { t } = useTranslation()
  const [isDocumentRowOpen, toggleDocumentRowOpen] = useState<boolean>(false)

  return (
    <div className="">
      <div
        className="heading mb-2 d-flex align-items-center"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded={isDocumentRowOpen}
        aria-controls={`${id}`}
        role="button"
        tabIndex={isDocumentRowOpen ? -1 : 0}
        onClick={() => toggleDocumentRowOpen(!isDocumentRowOpen)}
        onKeyDown={() => toggleDocumentRowOpen(!isDocumentRowOpen)}
      >
        <small className="col-11 d-flex">
          <span className="col-2">
            <span>{t('declaration.p5.sequenceNumber')}</span>
            <span className="px-1">
              {(sequenceNumber ?? 0) + 1}
            </span>
          </span>
          <span className="col-8 pe-1">
            {referenceNumber}
          </span>
          <span className="col-1 pe-2">
            <i className="me-1 fal fa-file" />
            {files.length}
          </span>
          <span className="col-2 px-1">
            {removePrefix(documentType, documentTypePrefix)}
          </span>
        </small>
        <i className={`me-2 fa ${isDocumentRowOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </div>
      <div className={`collapse ${isDocumentRowOpen ? 'show mb-3' : ''}`} id={`${id}`}>
        <div className="d-flex flex-wrap">
          {
            isDocumentRowOpen && (
              children
            )
          }
          {
            isDocumentRowOpen && (
              <TransitSidebarConsignmentItemDocumentFilesSection files={files} />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default TransitSidebarConsignmentItemDocumentSection
