import React from 'react'
import HouseConsignmentsTable from './HouseConsignmentsTable'

function DeclarationConsignmentTab() {
  return (
    <HouseConsignmentsTable />
  )
}

export default DeclarationConsignmentTab

