import { RuleResult } from 'types/DeclarationP5'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import C0394Validator from '../C0394'
import { extractCountryFromDepartureOffice } from '../../../../common/utils/classifier-util'

const NC9012Validator = {
  validateAddressRequirement: (formData: DeclarationForm): RuleResult => {
    const country = extractCountryFromDepartureOffice(formData.departureCustomsOffice)
    if (country !== 'FI') return C0394Validator.validateAddressRequirement(formData)

    if (formData.locationOfGoods?.typeOfLocation === 'D') {
      return 'REQUIRED'
    }

    return 'NOT_ALLOWED'
  },

}

export default NC9012Validator
