import { UseDeclarationFormReturn } from '../../form'
import useConsignmentDetailApi from './api'
import { toConsignmentDetailRequest } from './mapper'
import { ConsignmentDetailRequest } from '../../../common/models'
import { nonNullConsignee } from '../../../common/utils/common-util'

function useConsignmentDetail(form: UseDeclarationFormReturn) {
  const {
    getValues,
    watch,
    setValue,
  } = form

  const transitOperationId: number | null = watch('id')
  const { postConsignmentDetail } = useConsignmentDetailApi(transitOperationId)

  const createConsignmentTrader = async (isDraft: boolean, traderTarget: 'consignor' | 'consignee') => {
    const consignmentDetail = getValues(traderTarget)
    // const traderState = getFieldState(traderTarget)

    if (consignmentDetail === null) {
      return
    }

    // todo validate if allowed
    const newTransitOperationId = getValues('id')
    if (newTransitOperationId === null) throw Error('Missing transitOperationId')

    const request: ConsignmentDetailRequest = toConsignmentDetailRequest(
      consignmentDetail,
      newTransitOperationId,
      getValues(`${traderTarget}.id`),
    )

    const response = await postConsignmentDetail(isDraft).mutateAsync(request)

    setValue(`${traderTarget}.id`, response.id)
  }

  const createHouseConsignmentTrader = async (isDraft: boolean, traderTarget: 'consignor' | 'consignee', houseConsignmentIndex: number) => {
    const formPath = `houseConsignment.${houseConsignmentIndex}.${traderTarget}` as const
    const consignmentDetail = getValues(formPath)
    if (consignmentDetail === null) return

    // todo validate if allowed
    const newTransitOperationId = getValues('id')
    if (newTransitOperationId === null) throw Error('Missing transitOperationId')

    const request: ConsignmentDetailRequest = toConsignmentDetailRequest(
      consignmentDetail,
      newTransitOperationId,
      getValues(`${formPath}.id`),
    )
    const response = await postConsignmentDetail(isDraft).mutateAsync(request)
    setValue(`${formPath}.id`, response.id)
  }

  const createConsignmentItemConsignee = async (isDraft: boolean, houseConsignmentIndex: number, consignmentItemIndex: number) => {
    const formPath = `houseConsignment.${houseConsignmentIndex}.consignmentItem.${consignmentItemIndex}.consignee` as const
    const consignmentDetail = getValues(formPath)

    // todo validate if allowed
    if (consignmentDetail === null || consignmentDetail === undefined || !nonNullConsignee(consignmentDetail)) return
    const newTransitOperationId = getValues('id')
    if (newTransitOperationId === null) throw Error('Missing transitOperationId')

    const request: ConsignmentDetailRequest = toConsignmentDetailRequest(
      consignmentDetail,
      newTransitOperationId,
      getValues(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${consignmentItemIndex}.consignee.id`),
    )

    const response = await postConsignmentDetail(isDraft).mutateAsync(request)

    setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${consignmentItemIndex}.consignee.id`, response.id)
  }

  return { createConsignmentTrader, createHouseConsignmentTrader, createConsignmentItemConsignee }
}

export default useConsignmentDetail
