import React, { Dispatch, SetStateAction } from 'react'
import { isMobile } from 'react-device-detect'
import Select from 'react-select'
import { HeaderGroup } from 'react-table'
import CustomerSearchHeader from '../../../components/Table/CustomerSearchHeader'
import { getSelectValue } from '../../../helpers'
import { ISelectOption } from '../../../types/IClassifier'
import { TableFilter } from '../../../types/PageResponse'
import { getExternalCustomerOptions } from '../../CustomerTable/config'
import { getDirectoStatusOptions, getPublicOptions } from './config'

interface TableHeaderProps {
  headerGroups: Array<HeaderGroup>,
  filterState: {
    filters: Array<TableFilter>
    setFilters: Dispatch<SetStateAction<Array<TableFilter>>>
  }
  // eslint-disable-next-line react/require-default-props
  selectFilterOptions?: ISelectOption[],
  // eslint-disable-next-line react/require-default-props
  selectFilterState?: {
    selectFilter: string,
    setSelectFilter: Dispatch<SetStateAction<string>>
  },
  // eslint-disable-next-line react/require-default-props
  sortState?: {
    sortBy: string
    setSortBy: Dispatch<SetStateAction<string>>
  },
  // eslint-disable-next-line react/require-default-props
  orderState?: {
    orderBy: string
    setOrderBy: Dispatch<SetStateAction<string>>
  }
}

const directoStatusOptions: ISelectOption[] = getDirectoStatusOptions()
const directoOptions: ISelectOption[] = getExternalCustomerOptions()
const publicOptions: ISelectOption[] = getPublicOptions()

function TableHeader(props: TableHeaderProps) {
  const {
    headerGroups, selectFilterOptions, selectFilterState, filterState, sortState, orderState,
  } = props
  const { filters, setFilters } = filterState

  const getFilter = (header: string) => {
    const match: TableFilter | undefined = filters.find((filter) => filter.key === header)
    return match || null
  }
  const getFilterValue = (header: string) => {
    const match: TableFilter | null = getFilter(header)
    return match ? match.value : ''
  }

  function isDateColumn(column: HeaderGroup<{}>) {
    return column.id === 'modified' || column.id === 'created' || column.id === 'dateReviewed'
  }

  function getSimpleColumnInputHeader(column: HeaderGroup<{}>) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {
          // eslint-disable-next-line max-len
          (column.id !== 'status' && column.id !== 'type' && column.id !== 'isReviewed' && column.id !== 'directoStatus'
            && column.id !== 'usesT1Grn' && column.id !== 'customerName' && column.id !== 'external') && (
            <input
              disabled={
                column.id === 'usersCount'
                || column.id === 'declarationsCount' || column.id === 'latestCustomsUpdate' || column.id === 'auditReason'
              }
              className="form-control"
              type="search"
              name={`${column.id}Search`}
              id={`${column.id}Search`}
              value={getFilterValue(column.id)}
              placeholder={isDateColumn(column) ? 'dd.mm.yyyy' : undefined}
              pattern={isDateColumn(column) ? '\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d' : undefined}
              minLength={column.id === 'notes' ? 3 : undefined}
              onChange={(event) => {
                if (isDateColumn(column) || column.id === 'notes') {
                  const isValid = event.target.checkValidity()
                  if (!isValid) {
                    event.target.classList.add('invalid')
                  } else {
                    event.target.classList.remove('invalid')
                  }
                }
                if (column.id === 'id' && event.target.value.match('^[0-9]*$') == null) {
                  return
                }
                if (getFilter(column.id) === null) {
                  setFilters([...filters, {
                    key: column.id,
                    value: event.target.value,
                  }])
                } else {
                  setFilters(filters.map((filter) => {
                    if (filter.key === column.id) {
                      // eslint-disable-next-line no-param-reassign
                      filter.value = event.target.value
                      return filter
                    }
                    return filter
                  }))
                }
              }}
            />
          )
        }
      </>
    )
  }

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <tr {...headerGroup.getHeaderGroupProps()}>
          {isMobile ? <th className="w-1" aria-label="empty" /> : null}
          {headerGroup.headers.map((column) => (
            <th
              key={column.getHeaderProps().key}
              className={
                `${(isMobile && column.id === 'mrn') ? 'd-none d-sm-table-cell' : ''} ${
                  /* eslint-disable  @typescript-eslint/no-explicit-any */
                  (column as any).className ? ((column as any).className) : ''
                }`
              }
              role={column.getHeaderProps().role}
              style={{
                ...column.getHeaderProps().style,
                cursor: `${(column.id === 'id' || column.id === 'dateReviewed' || column.id === 'modified'
                  || column.id === 'created' || column.id === 'reviewedBy' || column.id === 'status' || column.id === 'latestCustomsUpdate'
                  || column.id === 'transitDateLimit')
                && sortState ? 'pointer'
                  : 'auto'}`,
              }}
            >
              <span
                role="presentation"
                onClick={() => {
                  if (sortState) {
                    if (column.id !== 'id' && column.id !== 'dateReviewed'
                          && column.id !== 'reviewedBy' && column.id !== 'modified' && column.id !== 'created'
                          && column.id !== 'status' && column.id !== 'latestCustomsUpdate' && column.id !== 'transitDateLimit') {
                      return
                    }
                    if ((sortState.sortBy === 'dateCreated' && column.id && column.id === 'created')
                        || (sortState.sortBy === 'dateModified' && column.id === 'modified')
                    ) {
                      sortState.setSortBy('id')
                    }
                    if (column.id === 'modified' && sortState.sortBy !== 'dateModified') {
                      sortState.setSortBy('dateModified')
                    } else if (column.id === 'created' && sortState.sortBy !== 'dateCreated') {
                      sortState.setSortBy('dateCreated')
                    } else if (sortState.sortBy === column.id) {
                      sortState.setSortBy('id')
                    } else if (column.id !== 'modified' && column.id !== 'created') {
                      sortState.setSortBy(column.id)
                    }
                  }
                }}
              >
                {column.render('title')}
              </span>
              {sortState && ((column.id === 'id' || column.id === 'dateReviewed' || column.id === 'transitDateLimit'
                    || column.id === 'latestCustomsUpdate' || (column.id === 'modified' && sortState.sortBy !== 'dateModified')
                    || (column.id === 'created' && sortState.sortBy !== 'dateCreated')
                          || column.id === 'reviewedBy' || column.id === 'status')
                      && sortState.sortBy !== column.id)
                  && <span className="fas fa-arrows-alt-v ms-2" />}
              {sortState && orderState
                && orderState.orderBy === 'DESC'
                && (column.id === sortState.sortBy
                || (column.id === 'modified' && sortState.sortBy === 'dateModified')
                || (column.id === 'created' && sortState.sortBy === 'dateCreated')) && (
                <i
                  className="fas fa-sort-up ms-2 mt-2"
                  role="presentation"
                  onClick={() => {
                    if (orderState) {
                      orderState.setOrderBy('ASC')
                    }
                  }}
                />
              )}
              {sortState && orderState
                && orderState.orderBy === 'ASC'
                && (column.id === sortState.sortBy
                || (column.id === 'modified' && sortState.sortBy === 'dateModified')
                || (column.id === 'created' && sortState.sortBy === 'dateCreated')) && (
                <i
                  className="fas fa-sort-down ms-2 mt-2"
                  role="presentation"
                  onClick={() => {
                    if (orderState) {
                      orderState.setOrderBy('DESC')
                    }
                  }}
                />
              )}
            </th>
          ))}
        </tr>
      ))}
      {headerGroups.map((headerGroup) => (
        <tr className="table-search" {...headerGroup.getHeaderGroupProps()}>
          {isMobile ? <td className="w-1" /> : null}
          {headerGroup.headers.map((column) => (
            <td
              className={
                `${column.id === 'id' ? 'cell-id' : ''
                }${isMobile && column.id === 'mrn' ? 'd-none d-sm-table-cell' : ''
                }${column.id === 'status' ? 'filter-select' : ''
                }${isDateColumn(column) ? 'cell-modified' : ''} hover`
              }
              {...column.getHeaderProps()}
            >
              {
                (column.id === 'status' || column.id === 'type') && (
                  <Select
                    key={selectFilterState?.selectFilter}
                    options={selectFilterOptions}
                    className="select border-0 w-100"
                    classNamePrefix="select"
                    menuPlacement="auto"
                    value={selectFilterOptions ? getSelectValue(selectFilterState?.selectFilter!, selectFilterOptions) : null}
                    onChange={(option: ISelectOption | null) => selectFilterState?.setSelectFilter(option ? option.value : null)}
                    isClearable
                    isSearchable={false}
                  />
                )
              }
              {
                (column.id === 'directoStatus') && (
                  <Select
                    options={directoStatusOptions}
                    className="select border-0 w-100"
                    classNamePrefix="select"
                    menuPlacement="auto"
                    value={
                      filterState?.filters.find((filter) => filter.key === 'directoStatus')?.value
                      // eslint-disable-next-line max-len
                        ? getSelectValue(filterState?.filters.find((filter) => filter.key === 'directoStatus')?.value ?? null, directoStatusOptions)
                        : null
                    }
                    onChange={(option: ISelectOption | null) => {
                      if (getFilter(column.id) === null) {
                        setFilters([...filters, {
                          key: column.id,
                          value: option ? option.value : null,
                        }])
                      } else {
                        setFilters(filters.map((filter) => {
                          if (filter.key === column.id) {
                            // eslint-disable-next-line no-param-reassign
                            filter.value = option ? option.value : null
                            return filter
                          }
                          return filter
                        }))
                      }
                    }}
                    isClearable
                    isSearchable={false}
                  />
                )
              }
              {column.id === 'external' && (
                <Select
                  options={directoOptions}
                  className="select border-0 w-100"
                  classNamePrefix="select"
                  menuPlacement="auto"
                  value={
                    filterState?.filters.find((filter) => filter.key === 'external')?.value
                    // eslint-disable-next-line max-len
                      ? getSelectValue(filterState?.filters.find((filter) => filter.key === 'external')?.value ?? null, directoStatusOptions)
                      : null
                  }
                  onChange={(option: ISelectOption | null) => {
                    if (getFilter(column.id) === null) {
                      setFilters([...filters, {
                        key: column.id,
                        value: option ? option.value : null,
                      }])
                    } else {
                      setFilters(filters.map((filter) => {
                        if (filter.key === column.id) {
                          // eslint-disable-next-line no-param-reassign
                          filter.value = option ? option.value : null
                          return filter
                        }
                        return filter
                      }))
                    }
                  }}
                  isClearable
                  isSearchable={false}
                />
              )}
              {(column.id === 'usesT1Grn') && (
                <Select
                  options={publicOptions}
                  className="select border-0 w-100"
                  classNamePrefix="select"
                  menuPlacement="auto"
                  value={
                    filterState?.filters.find((filter) => filter.key === 'usesT1Grn')?.value
                      ? getSelectValue(filterState?.filters.find((filter) => filter.key === 'usesT1Grn')?.value!, publicOptions)
                      : null
                  }
                  onChange={(option: ISelectOption | null) => {
                    if (getFilter(column.id) === null) {
                      setFilters([...filters, {
                        key: column.id,
                        value: option ? option.value : null,
                      }])
                    } else {
                      setFilters(filters.map((filter) => {
                        if (filter.key === column.id) {
                          // eslint-disable-next-line no-param-reassign
                          filter.value = option ? option.value : null
                          return filter
                        }
                        return filter
                      }))
                    }
                  }}
                  isClearable
                  isSearchable={false}
                />
              )}
              {
                column.id === 'customerName' && (
                  <CustomerSearchHeader filterState={filterState} />
                )
              }
              {getSimpleColumnInputHeader(column)}
            </td>
          ))}
          <td className="d-none d-sm-table-cell" />
        </tr>
      ))}
    </thead>
  )
}

export default TableHeader
