/* eslint-disable react/require-default-props */
import React from 'react'
import Tooltip from 'rc-tooltip'
import { IconData } from './invalid-icon.helper'

interface InvalidIconProps {
  iconData: IconData;
  onInvalidIconClick?: () => void;
}

function InvalidIcon(props: InvalidIconProps) {
  const {
    iconData, onInvalidIconClick,
  } = props

  const tooltipElement = (
    <small>
      {
        `${iconData.reasonText.substring(0, 60)}…`
      }
    </small>
  )

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        iconData.isInvalid
        && (
          <Tooltip overlayClassName="log-view-tooltip" overlay={tooltipElement}>
            <i
              className="fal fa-exclamation-triangle text-danger ms-2 d-flex align-items-center pointer"
              role="presentation"
              onClick={() => {
                if (onInvalidIconClick) {
                  onInvalidIconClick()
                }
              }}
            />
          </Tooltip>
        )
      }
    </>
  )
}

export default InvalidIcon
