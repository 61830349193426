export interface DeclarationAudit {
  id: number | null;
  status: DeclarationAuditStatus | null;
  customerName: string | null;
  modified: string | null;
  created: string | null;
  reason: string | null;
  isReviewed: boolean | null;
  dateReviewed: string | null;
  reviewedBy: string | null;
  notes?: string;
}
export enum DeclarationAuditStatus {
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  PROHIBITED_DESCRIPTION = 'PROHIBITED_DESCRIPTION',
}
