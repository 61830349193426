import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { Document, DocumentFile, DocumentType } from '../../../types/Document'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'

function getAllDocuments(declarationId: number, type: DocumentType): Promise<Array<Document>> {
  return new Promise((resolve, reject) => {
    axios.get(apiService.getFullApiUrl(`/document/${declarationId}`), {
      params: {
        documentType: type,
      },
    })
      .then(handleResponseData)
      .then((value) => resolve(value))
      .catch((error: AxiosError) => {
        toast.warn('An error occurred while retrieving goods documents')
        reject(error)
      })
  })
}

function postDocument(document: Document, declarationId: number): Promise<Document> {
  return new Promise((resolve) => {
    axios.post(
      apiService.getFullApiUrl('/document'),
      {
        ...document,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch(handleApiException)
  })
}

function putDocument(document: Document, declarationId: number): Promise<Document> {
  return new Promise((resolve) => {
    axios.put(
      apiService.getFullApiUrl(`/document/${document.id}`),
      {
        ...document,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch(handleApiException)
  })
}

function deleteDocument(deleteDocumentId: number): Promise<Document> {
  return new Promise((resolve, reject) => {
    axios.delete(apiService.getFullApiUrl(`/document/${deleteDocumentId}`)).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      // eslint-disable-next-line no-console
      console.error(`Error while deleting document id ${deleteDocumentId}`)
      reject(error)
    })
  })
}

function postDocumentFile(documentFile: DocumentFile, documentId: number, declarationId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    if (documentFile.id !== null || documentFile.file === null) {
      resolve('')
      return
    }

    const formData = new FormData()
    formData.append('filename', documentFile.filename)
    formData.append('mimeType', documentFile.mimeType)
    formData.append('sortOrder', `${documentFile.sortOrder}`)
    formData.append('declarationId', `${declarationId}`)
    formData.append('documentId', `${documentId}`)

    documentFile.file.arrayBuffer().then((fileBytes) => {
      const fileBlob = new Blob([fileBytes], { type: documentFile.mimeType })
      formData.append('multipartFile', fileBlob, documentFile.filename)
      axios.post(
        apiService.getFullApiUrl('/document-file/upload'),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ).then(handleResponseData).then((savedFileUuid) => {
        resolve(savedFileUuid)
      }).catch((error: AxiosError) => {
        reject(error)
      })
    }).catch((reason) => {
      reject(Error(`Error occurred while getting file array buffer: ${reason}`))
    })
  })
}

function getDocumentFileSignedUrl(documentFile: DocumentFile): Promise<string> {
  return new Promise((resolve, reject) => {
    if (documentFile.uuid === null || documentFile.uuid.length === 0) {
      reject(Error('Invalid document uuid'))
    }

    let folder = documentFile.uuid!.split('/')[0]

    if (folder === undefined || folder === null) {
      folder = 'LATVIA'
    }

    let uuid = documentFile.uuid?.split('/')[1]

    if (uuid === undefined || uuid === null) {
      uuid = documentFile.uuid!
    }

    axios.get(apiService.getFullApiUrl(`/document-file/${folder}/${uuid}`))
      .then(handleResponseData)
      .then((fileSignedUrl) => {
        resolve(fileSignedUrl)
      }).catch((error: AxiosError) => reject(error))
  })
}

function downloadFileFromCdn(signedUrl: string): Promise<object> {
  return new Promise((resolve, reject) => {
    fetch(signedUrl, {
      cache: 'no-cache',
      method: 'GET',
    }).then((responseObject) => {
      responseObject.arrayBuffer().then((bufferedBytes) => {
        resolve(new Blob([bufferedBytes]))
      })
    }).catch(() => {
      reject(Error('Failed to download file'))
    })
  })
}

function deleteDocumentFile(documentFile: DocumentFile) {
  return new Promise((resolve, reject) => {
    if (documentFile.uuid === null || documentFile.uuid.length === 0) {
      reject(Error('Invalid document uuid'))
    }

    axios.delete(apiService.getFullApiUrl(`/document-file/${documentFile.uuid}`))
      .then(handleResponseData)
      .then((fileSignedUrl) => {
        resolve(fileSignedUrl)
      }).catch((error: AxiosError) => reject(error))
  })
}

function saveBlob(file: DocumentFile | null, source: object | string, type: 'blob' | 'url', pdfName?: string) {
  if (type === 'url' && typeof source === 'string') {
    downloadFileFromCdn(source).then((fileBytes) => saveBlob(file, fileBytes, 'blob', pdfName))
    return
  }
  if (type === 'blob') {
    const link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)
    // @ts-ignore
    link.href = URL.createObjectURL(source)
    link.download = file?.filename ?? pdfName ?? 'pdf.pdf'
    link.click()
    document.body.removeChild(link)
  }
}

function downloadFile(file: DocumentFile) {
  if (file.file && file.uuid === null) {
    file.file.arrayBuffer().then((fileBytes) => {
      const blob = new Blob([fileBytes], { type: file.mimeType })
      saveBlob(file, blob, 'blob')
    }).catch(() => {
      toast.error('Failed to download just uploaded file')
    })
  } else if (file.uuid !== null) {
    getDocumentFileSignedUrl(file)
      .then((signedUrl) => saveBlob(file, signedUrl, 'url'))
  } else {
    toast.error('Failed to download file')
  }
}

const DocumentService = {
  getAllDocuments,
  postDocument,
  putDocument,
  deleteDocument,
  postDocumentFile,
  getDocumentFileSignedUrl,
  downloadFileFromCdn,
  deleteDocumentFile,
  saveBlob,
  downloadFile,
}

export default DocumentService
