import { TFunction } from 'i18next'
import { UserPreference } from '../../../../types/UserPreference'
import { ISelectOption } from '../../../../types/IClassifier'

export interface Header {
  title: string,
  accessor: string,
  columns?: [],
  width?: number,
  maxWidth?: number,
  minWidth?: number,
  sticky?: 'left' | 'right',
  className? : string,
  disableSortBy: boolean,
  disableGlobalFilter: boolean,
}

const addDefaultFieldsToTable = (columns: Array<Header>, t: TFunction) => {
  columns.push({
    title: t('transit.id'),
    accessor: 'id',
    sticky: 'left',
    maxWidth: 86,
    className: 'fixed-width-sm',
    disableSortBy: false,
    disableGlobalFilter: false,
  }, {
    title: t('transit.mrnAndLrn'),
    accessor: 'mrn',
    sticky: 'left',
    width: 215,
    className: 'fixed-width-lg',
    disableSortBy: true,
    disableGlobalFilter: false,
  })
}

const mapPreferencesToFields = (columns: Array<Header>, t: TFunction, isAdmin: boolean, userPreferences: Array<UserPreference>) => {
  userPreferences.forEach((item) => {
    if (!item.deleted) {
      if (item.value === 'TRANSIT_CONSIGNOR') {
        return columns.push({
          title: t('transit.consignor'),
          accessor: 'consignorName',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_CONSIGNEE') {
        return columns.push({
          title: t('transit.consignee'),
          accessor: 'consigneeName',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_CARRIER') {
        return columns.push({
          title: t('transit.carrier'),
          accessor: 'carrierName',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_DEPARTURE_CUSTOMS') {
        return columns.push({
          title: t('transit.departureCustomsOffice'),
          accessor: 'departureCustomsOffice',
          className: 'fixed-width-sm-l',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_DESTINATION_CUSTOMS') {
        return columns.push({
          title: t('transit.destinationCustomsOffice'),
          accessor: 'destinationCustomsOffice',
          className: 'fixed-width-sm-l',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_GUARANTEE') {
        return columns.push({
          title: t('transit.guaranteeAmount'),
          accessor: 'guaranteeAmount',
          className: 'fixed-width-md-sm',
          disableSortBy: true,
          disableGlobalFilter: true,
        })
      }
      if (item.value === 'TRANSIT_PROFILE') {
        return columns.push({
          title: t('declaration.nctsProfile'),
          accessor: 'guaranteeProfile',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_T1_GRN') {
        return columns.push({
          title: t('customers.publicDeclaration'),
          accessor: 'usesT1Grn',
          className: 'fixed-width-md-sm',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_TRANSPORT') {
        return columns.push({
          title: t('transit.transport'),
          accessor: 'departureTransportMeans',
          className: 'fixed-width-md-sm',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_STATUS') {
        return columns.push({
          title: t('transit.status'),
          accessor: 'status',
          className: 'fixed-width-md',
          disableSortBy: false,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_DIRECTO_STATUS') {
        return columns.push({
          title: t('transit.directoStatus'),
          accessor: 'directoStatus',
          className: 'fixed-width-sm',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_DIRECTO') {
        return columns.push({
          title: t('transit.directoClient'),
          accessor: 'directoClientCode',
          className: 'fixed-width-md-s',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_NOTES') {
        return columns.push({
          title: t('buttons.notes'),
          accessor: 'notes',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_COMPANY_NOTE') {
        return columns.push({
          title: t('buttons.notes'),
          accessor: 'companyNotes',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_CUSTOMER') {
        return columns.push({
          title: t('transit.customer'),
          accessor: 'customerName',
          className: 'fixed-width-md',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_REVIEWER') {
        return columns.push(
          {
            title: t('transit.reviewer'),
            accessor: 'reviewedBy',
            className: 'fixed-width-md-sm',
            disableSortBy: true,
            disableGlobalFilter: false,
          },
        )
      }
      if (item.value === 'TRANSIT_LAST_REVIEW') {
        return columns.push(
          {
            title: t('transit.lastReview'),
            accessor: 'dateReviewed',
            className: 'fixed-width-md-sm',
            disableSortBy: false,
            disableGlobalFilter: false,
          },
        )
      }
      if (item.value === 'TRANSIT_CREATED_BY') {
        return columns.push({
          title: t('transit.createdByName'),
          accessor: 'createdByName',
          className: 'fixed-width-md-s',
          disableSortBy: true,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_MODIFIED') {
        return columns.push({
          title: t('transit.modified'),
          accessor: 'dateModified',
          disableSortBy: false,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_CREATED') {
        return columns.push({
          title: t('transit.created'),
          accessor: 'dateCreated',
          disableSortBy: false,
          disableGlobalFilter: false,
        })
      }
      if (item.value === 'TRANSIT_LAST_CUSTOMS_UPDATE') {
        return columns.push({
          title: t('declaration.latestCustomsUpdate'),
          accessor: 'latestCustomsUpdate',
          disableSortBy: false,
          disableGlobalFilter: true,
        })
      }
      if (item.value === 'TRANSIT_DATE_LIMIT') {
        return columns.push({
          title: t('declaration.transitDateLimit'),
          accessor: 'transitDateLimit',
          disableSortBy: false,
          disableGlobalFilter: false,
        })
      }
    }
  })

  return columns
}

export default function getTableHeaders(
  t: TFunction,
  isAdmin: boolean,
  tablePreferences?: Array<UserPreference>,
): Array<Header> {
  const columns: Array<Header> = []
  addDefaultFieldsToTable(columns, t)
  if (tablePreferences !== undefined && tablePreferences?.length !== 0) {
    mapPreferencesToFields(columns, t, isAdmin, tablePreferences)
  }
  return columns
}

export function getPublicOptions() {
  return [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ] as ISelectOption[]
}
