import axios from 'axios'
import React from 'react'
import { IGoodsItem } from '../../../../types/IGoodsItem'
import { apiService, handleResponseData } from '../../../../services/api.service'
import { sortBySortOrder } from '../../../../helpers'

function useUpdateGoodsItemsState(
  setShouldUpdateGoodsForm: React.Dispatch<React.SetStateAction<boolean>>,
  setIsGoodsItemsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setGoodsItems: React.Dispatch<React.SetStateAction<Array<IGoodsItem>>>,
) {
  const fetchHsCodes = (hsCodes: Set<string>, goodsItemsResponse: IGoodsItem[]) => {
    if (hsCodes.size !== 0) {
      hsCodes.forEach((code) => {
        axios.get(
          apiService.getFullApiUrl('/classifier/page'),
          {
            params: {
              code: code !== '990500' ? code.padEnd(8, '0') : code,
              classifierGroup: 'COMBINED_NOMENCLATURE',
            },
          },
        ).then(handleResponseData).then((response) => {
          if (response.content.length > 0) {
            const goodItem = goodsItemsResponse.filter((goods) => goods.hsCode === code)

            if (goodItem.length !== 0) {
              goodItem.forEach((resp) => {
                // eslint-disable-next-line no-param-reassign
                resp.visualCode = response.content[0].codeLabel
              })
            }
          }
        })
      })
    }
  }

  const updateGoodsItemsState = (goodsItemsResponse: IGoodsItem[]) => {
    if (goodsItemsResponse && goodsItemsResponse.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < goodsItemsResponse.length; i++) {
        // eslint-disable-next-line no-param-reassign
        goodsItemsResponse[i].sortOrder = i
        if (goodsItemsResponse[i].documents && goodsItemsResponse[i].documents.length) {
          goodsItemsResponse[i].documents.sort(sortBySortOrder)
        }
      }
      const hsCodes = new Set<string>()
      goodsItemsResponse.forEach((goodsItem) => {
        if (goodsItem.hsCode === undefined || goodsItem.hsCode === null) {
          // eslint-disable-next-line no-param-reassign
          goodsItem.visualCode = '-'

          return
        }

        hsCodes.add(goodsItem.hsCode)
      })
      fetchHsCodes(hsCodes, goodsItemsResponse)

      goodsItemsResponse.sort(sortBySortOrder)
      setGoodsItems(goodsItemsResponse)
      setShouldUpdateGoodsForm(true)
    }
    setIsGoodsItemsLoading(false)
  }

  return { updateGoodsItemsState }
}

export default useUpdateGoodsItemsState
