import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { CustomerStatus } from 'types/Customer'
import { PageFilterRequest, PageResponse } from 'types/PageResponse'
import { apiService, handleApiException, handleResponseData } from '../../../services/api.service'

function getCustomerUsersCount(customerId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/customer-user/count/${customerId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function getCustomerDeclarationsCount(customerId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/declaration/count/${customerId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function getCustomerPage(
  {
    pageIndex,
    pageSize,
    filters,
    typeFilter,
  }: PageFilterRequest,
): Promise<PageResponse<CustomerPageResponse>> {
  return new Promise((resolve, reject) => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const filterParam: any = {}
    filterParam.page = pageIndex
    filterParam.size = pageSize

    if (typeFilter !== null && typeFilter !== '') {
      filterParam.status = typeFilter
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const filter of filters) {
      if (filter.key === 'modified' || filter.key === 'created') {
        if (filter.value.match('(\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d)$') !== null) {
          filterParam[filter.key] = filter.value
        }
      } else if (filter.value !== null && filter.value !== '') {
        filterParam[filter.key] = filter.value
      }
    }
    axios.get(
      apiService.getFullApiUrl('/customer'),
      {
        params: {
          ...filterParam,
        },
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      toast.error('Getting customers failed')
      reject(error)
    })
  })
}

async function fetchAllCustomers() {
  let isLastPage = false
  let currentPage = 0
  const loadedCustomers: Array<CustomerPageResponse> = []
  while (!isLastPage) {
    // eslint-disable-next-line no-await-in-loop
    const response = await getCustomerPage({
      pageIndex: currentPage,
      pageSize: 40,
      filters: [{
        key: 'external',
        value: 'false',
      }],
    })
    if (response) {
      loadedCustomers.push(...response.content)
      isLastPage = response.last
      currentPage += 1
    } else {
      isLastPage = true
    }
  }
  return loadedCustomers
}

function patchCustomerStatus(customerStatusRequest: { customerId: number, status: CustomerStatus }) {
  return axios
    .patch(apiService.getFullApiUrl('/customer'), {
      ...customerStatusRequest,
    })
    .catch(handleApiException)
}

export interface CustomerPageResponse {
  id: number;
  name: string;
  registryCode: string;
  address: string;
  usersCount: number;
  transitsCount: number;
  status: CustomerStatus;
  created: string;
  external?: boolean
}

const CustomerTableService = {
  getCustomerUsersCount,
  getCustomerDeclarationsCount,
  getCustomerPage,
  patchCustomerStatus,
  fetchAllCustomers,
}

export default CustomerTableService
