import React from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope } from 'types/DeclarationP5'
import { Link } from 'react-router-dom'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import useCodelist from '../../../common/hooks/useCodelist'
import E1114Validator from '../../form/validators/transitional/E1114'

const scope = 'placeOfLoading'
type PlaceOfLoadingScope = NestedFieldScope<typeof scope>

function PlaceOfLoading() {
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-loading-place"
        headingLabel={t('declaration.p5.placeOfLoading')}
      >
        <FormField labelKey="declaration.p5.nationality">
          <FormSelect
            <PlaceOfLoadingScope>
            field="placeOfLoading.country"
            labelKey="declaration.p5.nationality"
            options={countriesOptions}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.placeOfLoadingLocation">
          <Input
            <PlaceOfLoadingScope>
            field="placeOfLoading.location"
            labelKey="declaration.p5.placeOfLoadingLocation"
            type="text"
            maxLength={E1114Validator.getMaximumLengthOfLoadingLocation()}
            placeholder={t('declaration.p5.placeOfLoadingLocation')}
          />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default PlaceOfLoading
