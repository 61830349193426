import React from 'react'
import { GuaranteeCalculationGoodsItemRow } from 'routes/Declaration/hooks/useGuaranteeCalculator'

interface CalculationRowProps {
  rowItem: GuaranteeCalculationGoodsItemRow
}

function CalculationRow({
  rowItem,
}: CalculationRowProps) {
  return (
    <>
      <div>
        <small className="mb-1">
          {rowItem.hsCode}
        </small>
        <small className="overflow-ellipsis">
          {rowItem.description}
        </small>
      </div>
      <div>
        {
          rowItem.price ?? '-'
        }
      </div>
      <div>
        {
          rowItem.dutyRate ?? '-'
        }
      </div>
      <div>
        {
          rowItem.vatRate ?? '-'
        }
      </div>
      <div>
        {
          rowItem.taxableDutyAmount ?? '-'
        }
      </div>
      <div>
        {
          rowItem.taxableVatAmount ?? '-'
        }
      </div>
      <div>
        {
          rowItem.taxableItemPrice ?? '-'
        }
      </div>
    </>
  )
}

export default CalculationRow
