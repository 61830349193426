import React from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { hasText } from '../../utils/common-util'

export interface TransitSidebarFieldProps {
  label: string
  value: string | undefined
  parenthesesValue?: string | undefined
  isLoading?: boolean

}
function TransitSidebarField(props: TransitSidebarFieldProps) {
  const {
    label, value, parenthesesValue, isLoading,
  } = props

  return (
    <div className="row mb-2">
      <div className="col-5 d-flex text-muted input-label pt-0">
        {label}
      </div>
      {
        isLoading && (
          <div className="col-7 ">
            <LoadingSpinner />
          </div>
        )
      }
      {
        !isLoading && (
          <div className="col-7 d-flex align-items-center flex-wrap">
            <small>
              {value}
            </small>

            {hasText(parenthesesValue) ? (
              <span className="text-muted ms-2">
                (
                {parenthesesValue}
                )
              </span>
            ) : null}

          </div>
        )
      }
    </div>
  )
}

TransitSidebarField.defaultProps = {
  parenthesesValue: undefined,
  isLoading: false,
}

export default TransitSidebarField
