import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { apiService, handleResponseData } from '../../../services/api.service'
import { ICarrier, IConsignmentDetail, IConsignmentDetailResponse } from '../../../types/IConsignmentDetail'
import { IDeclaration } from '../../../types/IDeclaration'
import { isNullOrBlank } from '../../../helpers'

function postConsignmentDetail(consignmentDetail: IConsignmentDetail, declarationId: number): Promise<IConsignmentDetail> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl('/consignment-detail'),
      {
        ...consignmentDetail,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })
}

function postCarrier(consignmentDetail: ICarrier, declarationId: number): Promise<ICarrier> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl('/consignment-detail/carrier'),
      {
        ...consignmentDetail,
        declarationId,
      },
    ).then(handleResponseData).then((value) => {
      resolve(value)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })
}

function getConsignmentDetailsByIds(ids: Array<number>): Promise<Array<IConsignmentDetail>> {
  return new Promise((resolve, reject) => {
    axios.post(
      apiService.getFullApiUrl('/consignment-detail/ids'),
      { consignmentDetailIds: ids },
    )
      .then(handleResponseData)
      .then((data) => resolve(data))
      .catch((error) => reject(error))
  })
}

function postDeclarationConsignmentDetails(
  declarationId: number,
  declaration: IDeclaration,
  consignmentDetails: {
    consignor: IConsignmentDetail | null,
    consignee: IConsignmentDetail | null,
    carrier: IConsignmentDetail | null
  },
): Promise<IConsignmentDetailResponse> {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const response = {
          consignor: null,
          consignee: null,
          carrier: null,
        } as IConsignmentDetailResponse
        if (declaration.isGoodsTradersEnabled !== true) {
          if (consignmentDetails.consignor != null
              && !(isNullOrBlank(consignmentDetails.consignor.name) && isNullOrBlank(consignmentDetails.consignor.zip)
              && isNullOrBlank(consignmentDetails.consignor.street) && isNullOrBlank(consignmentDetails.consignor.country)
                  && isNullOrBlank(consignmentDetails.consignor.city))) {
            try {
              response.consignor = await postConsignmentDetail(consignmentDetails.consignor, declarationId)
            } catch (consignorError) {
              toast.warn('Error while saving consignor data')
              reject(consignorError)
            }
          }
          if (consignmentDetails.consignee != null
              && !(isNullOrBlank(consignmentDetails.consignee.name) && isNullOrBlank(consignmentDetails.consignee.zip)
              && isNullOrBlank(consignmentDetails.consignee.street) && isNullOrBlank(consignmentDetails.consignee.country)
                  && isNullOrBlank(consignmentDetails.consignee.city))) {
            try {
              response.consignee = await postConsignmentDetail(consignmentDetails.consignee, declarationId)
            } catch (consigneeError) {
              toast.warn('Error while saving consignee data')
              reject(consigneeError)
            }
          }
        }
        if (declaration.isSecurityEnabled === true && consignmentDetails.carrier != null
            && !(isNullOrBlank(consignmentDetails.carrier.name) && isNullOrBlank(consignmentDetails.carrier.zip)
            && isNullOrBlank(consignmentDetails.carrier.street) && isNullOrBlank(consignmentDetails.carrier.country)
            && isNullOrBlank(consignmentDetails.carrier.city))) {
          try {
            response.carrier = await postConsignmentDetail(consignmentDetails.carrier, declarationId)
          } catch (carrierError) {
            toast.warn('Error while saving carrier data')
            reject(carrierError)
          }
        }
        resolve(response)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const ConsignmentDetailsService = {
  postDeclarationConsignmentDetails,
  postConsignmentDetail,
  getConsignmentDetailsByIds,
  postCarrier,
}

export default ConsignmentDetailsService
