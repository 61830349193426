import { createStore, PreloadedState } from 'redux'
import { IConsignmentDetail } from '../types/IConsignmentDetail'
import {
  DeclarationStatusEnum, DeclarationTypeEnum, IDeclaration, IDeclarationInfo, IGuarantee, ITransport,
} from '../types/IDeclaration'
// eslint-disable-next-line import/no-cycle
import rootReducer from './reducers'
import { ISeal } from '../routes/Declaration/Seal/types/Seal'

export const initialState: PreloadedState<IStoreState> = {
  consignmentDetailReducer: {
    consignor: {
      id: null,
      target: 'CONSIGNOR',
      name: '',
      street: '',
      city: '',
      country: '',
      zip: '',
      eori: '',
    },
    consignee: {
      id: null,
      target: 'CONSIGNEE',
      name: '',
      street: '',
      city: '',
      country: '',
      zip: '',
      eori: '',
    },
    carrier: {
      id: null,
      target: 'CARRIER',
      name: '',
      street: '',
      city: '',
      country: '',
      zip: '',
      eori: '',
    },
  },
  declarationInfoReducer: {
    declarationInfo: {
      id: null,
      place: 'Tallinn',
      packagesCount: null,
      grossMass: null,
      dispatchCountryCode: null,
      destinationCountryCode: null,
      transitCountries: [],
      goodsLocation: null,
      goodsLocationQualifier: null,
      transitDateLimit: null,
      transitLoadingDate: null,
    },
  },
  guaranteeReducer: {
    guarantee: {
      id: null,
      amount: null,
      declarationId: null,
    },
  },
  transportReducer: {
    transport: {
      id: null,
      departureTruckNo: null,
      departureNationality: null,
      departureCustomsOffice: null,
      borderCrosserTruckNo: null,
      borderCrosserNationality: null,
      borderTransportMode: null,
      isIndicatorContainerised: false,
      destinationCustomsOffice: null,
      inlandTransportMode: null,
      unloadingPlace: null,
      loadingPlace: null,
      transportContainers: [],
      transportCustomsOffices: [],
      declarationId: null,
    },
  },
  sealReducer: {
    seal: [{
      id: null,
      identity: '',
      transportId: null,
      sortOrder: 0,
      deleted: false,
    }],
  },
  declarationReducer: {
    declaration: {
      id: null,
      mrn: null,
      status: DeclarationStatusEnum.DRAFT,
      declarationType: DeclarationTypeEnum.T1,
      isGoodsTradersEnabled: false,
      isSecurityEnabled: true,
      consignorId: null,
      consigneeId: null,
      carrierId: null,
      localReferenceNumber: null,
      requiresAudit: null,
      profile: null,
      customerId: null,
      specialCondition: null,
      shipmentNumber: null,
      securityReferenceNumber: null,
    },
  },
}

// @ts-ignore
export default createStore(rootReducer, initialState)

export interface IStoreState {
  declarationInfoReducer: {
    declarationInfo: IDeclarationInfo
  }
  guaranteeReducer: {
    guarantee: IGuarantee
  }
  declarationReducer: {
    declaration: IDeclaration
  }
  transportReducer: {
    transport: ITransport
  },
  consignmentDetailReducer: {
    consignor: IConsignmentDetail,
    consignee: IConsignmentDetail,
    carrier: IConsignmentDetail,
  },
  sealReducer: {
    seal: Array<ISeal>
  }
}
