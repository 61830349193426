import axios, { AxiosError } from 'axios'
import React, {
  Dispatch,
  useContext, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../components/ConfirmationModal'
import LoadingBackdrop from '../../components/LoadingBackdrop'
import PdfPreviewModal from '../../components/PdfPreviewModal'
import { mapCountryToOption } from '../../components/react-select/CountryFunctions'
import { TitleBar } from '../../components/TitleBar'
import { AccessLevel, DeclarationContext } from '../../context/DeclarationContext'
import { NotificationContext } from '../../context/NotificationContext'
import { UserContext } from '../../context/UserContext'
import {
  getSelectValue, isNullOrBlank, rejectError, safeTrim,
} from '../../helpers'
import { UserRole } from '../../hooks/useAuth'
import useDeclarationMetaData, { CUSTOMS_OFFICE_PREFIX } from '../../hooks/useDeclarationMetaData'
import { addConsignmentDetails, addDeclaration, addTransport } from '../../redux/actions'
import { IStoreState } from '../../redux/store'
import { apiService, handleResponseData } from '../../services/api.service'
import { Document, DocumentFile, DocumentType } from '../../types/Document'
import { EventLog, EventLogRow } from '../../types/EventLog'
import { IClassifier, ISelectOption } from '../../types/IClassifier'
import {
  DeclarationStatusEnum, DeclarationTypeEnum, IDeclaration, ITransport,
} from '../../types/IDeclaration'
import { IGoodsItem } from '../../types/IGoodsItem'
import { PageResponse } from '../../types/PageResponse'
import TableRowStatus from '../Transits/Table/TableRowStatus'
import { InvalidField } from '../Transits/TransitsAside/detailView'
import { mapToRows } from '../Transits/TransitsAside/logView'
import DeclarationInfo from './DeclarationInfo'
import Documents from './Documents'
import { Goods } from './Goods'
import NavigationBar from './NavigationBar'
import ConsignmentDetailsService from './services/consignment-detail.service'
import DeclarationService from './services/declaration.service'
import DocumentService from './services/document.service'
import EventLogService from './services/event-log.service'
import GoodsItemService from './services/goods-item.service'
import TransportService from './services/transport.service'
import TempLogView from './TempLogView'
import useDeclaration from './hooks/useDeclaration'
import useDirectoEvent from './hooks/useDirectoEvent'
import useRestrictionKeys from './hooks/useRestrictionKeys'
import useRestrictedCodes from './Goods/hooks/useRestrictedCodes'
import useValidator from '../../hooks/useValidator'
import useCustomerRequirement from '../../hooks/useCustomerRequirement'
import useCreateOrEditDeclaration from './hooks/useCreateOrEditDeclaration'
import useDeclarationInfo from './hooks/useCreateOrEditDeclarationInfo'
import useGuarantee from './hooks/useCreateOrEditGuarantee'
import useCreateOrEditSeal from './hooks/useCreateOrEditSeal'
import usePrefillDeclaration from './hooks/usePrefillDeclaration'
import useGuaranteeCalculator from './hooks/useGuaranteeCalculator'
import useStandardVatRate from './hooks/useStandardVatRate'
import { NctsProfileNameOption } from '../../types/NctsProfileNameResponse'
import ROUTES from '../../config/routes'
import NotificationService from '../Settings/service/notification.service'
import SanctionModal from './Modals/Sanction'
import CustomsConfirmationModal from './Modals/CustomsConfirmation'
import BlacklistModal from './Modals/Blacklist'
import ProfileModal from './Modals/Profile'
import GuaranteeCalculatorModal from './Modals/GuaranteeCalculator'
import useCurrencyExchangeRate from './hooks/useCurrencyExchangeRate'
import { FilePreviewModal } from '../Transits/TransitsAside/FilePreviewModal'
import { TardekError } from '../../types/TardekError'
import { removePrefix } from '../phase5/common/utils/classifier-util'
import { NctsCountry, tardekConfig } from '../../config'

function Declaration() {
  const store = useStore()
  const history = useNavigate()
  const location = useLocation()
  const {
    t,
    i18n,
  } = useTranslation()
  const params: { declarationId?: string } = useParams()
  const {
    sanctionedKeys,
    loadSanctions,
    loadTruckRestrictions,
    loadCarrierRestrictions,
    truckRestrictions,
    traderRestrictions,
  } = useRestrictionKeys()

  const {
    country,
    setDepartureCustomsOffice,
    accessLevel,
    setAccessLevel,
    mockDraftCountry,
  } = useDeclarationMetaData()
  const {
    declarationData,
    saveTransportCustomsOffices,
  } = useDeclaration(params.declarationId)

  const { setContextHeaderNotificationMessage, contextHeaderNotificationMessage } = useContext(NotificationContext)

  const blankDeclaration: IDeclaration = {
    id: null,
    mrn: null,
    localReferenceNumber: null,
    status: DeclarationStatusEnum.DRAFT,
    declarationType: DeclarationTypeEnum.T1,
    isGoodsTradersEnabled: false,
    isSecurityEnabled: true,
    consignorId: null,
    consigneeId: null,
    carrierId: null,
    requiresAudit: null,
    profile: null,
    customerId: null,
    specialCondition: null,
    shipmentNumber: null,
    securityReferenceNumber: null,
  }

  const [saving, setSaving] = useState(false)
  const [submittingToCustoms, setSubmittingToCustoms] = useState(false)
  const [currentView, setCurrentView] = useState('declaration-info')
  const [customsAction, setCustomsAction] = useState<'SUBMIT' | 'CANCEL' | 'EDIT'>('SUBMIT')
  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)
  const [profileModalVisible, setProfileModalVisible] = useState<boolean>(false)
  const [isPdfPreviewModalVisible, setPdfPreviewModalVisible] = useState(false)
  const [isCalculationModalVisible, setCalculationModalVisible] = useState<boolean>(false)

  const [declaration, setDeclaration] = useState<IDeclaration>(blankDeclaration)
  const [goodsItems, setGoodsItems] = useState<Array<IGoodsItem>>([])
  const [deletedGoodsItems, setDeletedGoodsItems] = useState<Array<IGoodsItem>>([])
  const [eventLogs, setEventLogs] = useState<Array<EventLogRow>>([])
  const [isDuplicationLoading, setDuplicationLoading] = useState(false)
  const [isDuplicationModalVisible, setDuplicationModalVisible] = useState(false)

  const [countries, setCountries] = useState<Array<ISelectOption>>([])
  const [countryClassifiers, setCountryClassifiers] = useState<Array<IClassifier>>([])
  const [packageTypes, setPackageTypes] = useState<Array<ISelectOption>>([])
  const [documentTypes, setDocumentTypes] = useState<Array<ISelectOption>>([])
  const [previousDocumentTypes, setPreviousDocumentTypes] = useState<Array<ISelectOption>>([])
  const [procedureTypes, setProcedureTypes] = useState<Array<ISelectOption>>([])
  const [isGoodsItemsLoading, setIsGoodsItemsLoading] = useState<boolean>(false)

  const [shouldUpdateGoodsForm, setShouldUpdateGoodsForm] = useState(false)
  const [invalidFields, setInvalidFields] = useState<Array<InvalidField>>([])

  const [sanctionedCodes, setSanctionedCodes] = useState<Array<string>>([])
  const [isSanctionModalVisible, setSanctionModalVisible] = useState(false)
  const [isBlacklistWarningModalVisible, setIsBlacklistWarningModalVisible] = useState(false)
  const { user } = useContext(UserContext)
  const directoEventState = useDirectoEvent(declaration.id, user?.role === UserRole.ADMIN)

  const [isSendToCustomsDisabled, setIsSendToCustomsDisabled] = useState(true)

  const getDeclaration = () => (store.getState() as IStoreState).declarationReducer.declaration
  const getTransport = () => (store.getState() as IStoreState).transportReducer.transport
  const [transport, setTransport]: [ITransport, Dispatch<ITransport>] = useState(getTransport())

  const { validate, invalidFormFields } = useValidator()

  const { loadRequirements, customerRequirement } = useCustomerRequirement()

  const { createOrEditDeclaration } = useCreateOrEditDeclaration()

  const { createOrEditDeclarationInfo } = useDeclarationInfo(country)

  const isAdmin = user?.role === 'ADMIN'

  const {
    prefillDeclaration,
    nctsProfileNames,
    loadNctsProfileNames,
  } = usePrefillDeclaration(setIsGoodsItemsLoading, setShouldUpdateGoodsForm, setGoodsItems)

  const { createOrEditGuarantee } = useGuarantee()
  const { createOrEditSeal } = useCreateOrEditSeal()
  const currentNctsProfile = useMemo(
    () => getSelectValue(declaration?.profile, nctsProfileNames) as NctsProfileNameOption,
    [declaration.profile, nctsProfileNames],
  )
  const { countryStandardVatRate } = useStandardVatRate(country, countryClassifiers)
  const {
    exchangeRate,
    exchangeRateLoading,
  } = useCurrencyExchangeRate(country)

  const {
    openCalculation,
    goodsItemsRows,
  } = useGuaranteeCalculator(countryStandardVatRate, goodsItems, isCalculationModalVisible, setCalculationModalVisible)

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setDeclaration(getDeclaration())
      setTransport(getTransport())
    })
    return () => unsubscribe()
  }, [])

  const { restrictedGoods, restrictionKeys } = useRestrictedCodes(goodsItems, currentNctsProfile?.isShared)

  const createOrEditDirectoEvent = (savedDeclaration: IDeclaration) => {
    if (savedDeclaration.id === null) {
      return Promise.reject(Error('Missing declaration id'))
    }

    return directoEventState.saveDirectoEvent(savedDeclaration.id)
  }

  const isCustomsDepartureCountryAllowed = (departureCustomsOfficeCode: string | null): boolean => {
    const allowedCountries = process.env.REACT_APP_ENV === 'live' ? tardekConfig.phase4CountriesLive : tardekConfig.phase4CountriesTest
    const customsDepartureCode = removePrefix(departureCustomsOfficeCode, CUSTOMS_OFFICE_PREFIX)
    if (!customsDepartureCode) {
      return true
    }
    const countryCode = customsDepartureCode.substring(0, 2)
    return allowedCountries.includes(countryCode as NctsCountry)
  }

  const updateDocumentFiles = (initialDocument: Document, declarationId: number | null, savedDocument: Document) => new Promise<Document>(
    (resolve, reject) => {
      const promiseReject = () => (error: AxiosError) => {
        reject(error)
      }

      if (savedDocument.id === null || declarationId === null) {
        reject()
        return
      }

      const fileUploads = []
      // eslint-disable-next-line no-restricted-syntax
      for (const documentFile of initialDocument.files) {
        if (documentFile.uuid !== null && documentFile.deleted === true) {
          fileUploads.push(DocumentService.deleteDocumentFile(documentFile))
        } else if (documentFile.uuid === null && documentFile.deleted !== true) {
          fileUploads.push(DocumentService.postDocumentFile(documentFile, savedDocument.id, declarationId)
            .then((savedFileUuid) => {
              documentFile.uuid = savedFileUuid
              documentFile.file = null
              documentFile.deleted = false

              return savedFileUuid
            }))
        }
      }
      Promise.all(fileUploads)
        .then(() => resolve(savedDocument))
        .catch(promiseReject())
    },
  )

  function handleDocumentAfterSave(
    document: Document,
    savedDeclaration: IDeclaration,
    savedDocument: Document,
    resolve: (value: (Document | PromiseLike<Document>)) => void,
    reject: (reason?: string) => void,
  ) {
    updateDocumentFiles(document, savedDeclaration.id, savedDocument)
      .then(() => {
        // eslint-disable-next-line no-param-reassign
        savedDocument.files = document.files.filter((fileInSavedDoc) => !(
          document.files.find((docFile) => docFile.sortOrder === fileInSavedDoc.sortOrder)?.deleted))

        return resolve(savedDocument)
      })
      .catch((error) => reject(error))
  }

  const postDocument = (document: Document, savedDeclaration: IDeclaration): Promise<Document> => new Promise((resolve, reject) => {
    if (country !== 'FI') {
      // eslint-disable-next-line no-param-reassign
      document.orderNumber = null
    }

    if (savedDeclaration.id) {
      DocumentService.postDocument(document, savedDeclaration.id)
        .then((savedDocument) => {
          handleDocumentAfterSave(document, savedDeclaration, savedDocument, resolve, reject)
        }).catch(() => {
          setSubmittingToCustoms(false)
          setSaving(false)
        })
    } else {
      reject(Error('Invalid declaration id'))
    }
  })
  const putDocument = (document: Document, savedDeclaration: IDeclaration): Promise<Document> => new Promise((resolve, reject) => {
    if (country !== 'FI') {
      // eslint-disable-next-line no-param-reassign
      document.orderNumber = null
    }
    if (savedDeclaration.id) {
      DocumentService.putDocument(document, savedDeclaration.id)
        .then((savedDocument) => {
          handleDocumentAfterSave(document, savedDeclaration, savedDocument, resolve, reject)
        }).catch(() => {
          setSubmittingToCustoms(false)
          setSaving(false)
        })
    } else {
      reject(Error('Invalid declaration id'))
    }
  })

  // eslint-disable-next-line no-nested-ternary, @typescript-eslint/no-explicit-any
  const orderBySortOrder = () => (a: any, b: any) => ((a.sortOrder < b.sortOrder) ? -1 : ((a.sortOrder > b.sortOrder) ? 1 : 0))

  // eslint-disable-next-line no-async-promise-executor
  const createOrEditGoodsItems = (savedDeclaration: IDeclaration, isDraft: boolean) => new Promise(async (resolve, reject) => {
    if (!savedDeclaration.id) {
      reject(Error('Missing declaration id'))
      return
    }

    const savedItems: IGoodsItem[] = []
    const promises = []

    goodsItems.sort(orderBySortOrder())
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < goodsItems.length; i++) {
      const goodsItem = goodsItems[i]
      goodsItem.sortOrder = i
      goodsItem.visualCode = goodsItems[i].visualCode

      if (goodsItem.destinationCountryCode !== null && goodsItem.destinationCountryCode.includes(',')) {
        toast.error(
          t(
            'messages.destinationCountryWrong',
            { value: goodsItem.destinationCountryCode.split('COUNTRY_')[1], number: goodsItem.sortOrder + 1 },
          ),
          { autoClose: false },
        )

        return
      }

      if (goodsItem.dispatchCountryCode !== null && goodsItem.dispatchCountryCode.includes(',')) {
        toast.error(
          t(
            'messages.dispatchCountryWrong',
            { value: goodsItem.dispatchCountryCode.split('COUNTRY_')[1], number: goodsItem.sortOrder + 1 },
          ),
          { autoClose: false },
        )

        return
      }
      if (goodsItem.quantityUnit !== null && goodsItem.quantityUnit !== ''
          && goodsItem.quantityUnit.split('PACKAGE_TYPE_')[1].length === 1) {
        toast.error(
          t(
            'messages.invalidQuantityUnit',
            { type: goodsItem.quantityUnit.split('PACKAGE_TYPE_')[1], number: goodsItem.sortOrder + 1 },
          ),
          { autoClose: false },
        )

        reject(Error(`${goodsItem.quantityUnit} is invalid quantity unit type!`))
      }

      const traderPromises = []
      if (savedDeclaration.isGoodsTradersEnabled) {
        if (!isNullOrBlank(goodsItem.consignee.zip)) {
          traderPromises.push(ConsignmentDetailsService.postConsignmentDetail(goodsItem.consignee, savedDeclaration.id)
            .then((result) => {
              goodsItem.consigneeId = result.id
              goodsItem.consignee = result
            })
            .catch((error) => {
              if (error.response.status === 409) {
                savedItems.push(goodsItem)
                toast.error(t('messages.goodsItemError'))
              }
              reject(error)
            }))
        }
        if (!isNullOrBlank(goodsItem.consignor.zip)) {
          traderPromises.push(ConsignmentDetailsService.postConsignmentDetail(goodsItem.consignor, savedDeclaration.id)
            .then((result) => {
              goodsItem.consignorId = result.id
              goodsItem.consignor = result
            })
            .catch((error) => {
              if (error.response.status === 409) {
                savedItems.push(goodsItem)
                toast.error(t('messages.goodsItemError'))
              }
              reject(error)
            }))
        }

        if (savedDeclaration.isGoodsTradersEnabled) {
          const state: IStoreState = store.getState() as IStoreState

          const {
            carrier,
            consignee,
            consignor,
          } = state.consignmentDetailReducer

          traderPromises.push(
            ConsignmentDetailsService.postDeclarationConsignmentDetails(
              savedDeclaration.id,
              state.declarationReducer.declaration,
              {
                carrier: (!isNullOrBlank(carrier.name) && !isNullOrBlank(carrier.zip)
                  && !isNullOrBlank(carrier.street)) ? carrier : null,
                consignee: (!isNullOrBlank(consignee.name) && !isNullOrBlank(consignee.zip)
                  && !isNullOrBlank(consignee.street)) ? consignee : null,
                consignor: (!isNullOrBlank(consignor.name) && !isNullOrBlank(consignor.zip)
                  && !isNullOrBlank(consignor.street)) ? consignor : null,
              },
            )
              .then((result) => {
                store.dispatch(addConsignmentDetails({
                  consignor: goodsItem.consignor,
                  consignee: goodsItem.consignee,
                  carrier: result.carrier !== null ? result.carrier : carrier,
                }))
              }),
          )
        }
      }

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(traderPromises)

      if (!savedDeclaration.isGoodsTradersEnabled) {
        const state: IStoreState = store.getState() as IStoreState
        const { declarationInfo } = state.declarationInfoReducer
        goodsItem.dispatchCountryCode = declarationInfo.dispatchCountryCode
        goodsItem.destinationCountryCode = declarationInfo.destinationCountryCode
      }

      if (goodsItem.id === undefined || goodsItem.id === null) {
        promises.push(GoodsItemService.postGoodsItem(goodsItem, savedDeclaration.id, isDraft)
          .then((response) => {
            goodsItem.id = response.id
            response.visualCode = goodsItems[i].visualCode
            response.consignee = goodsItem.consignee
            response.consignor = goodsItem.consignor
            if (goodsItem.documents && goodsItem.documents.length > 0) {
              response.documents = goodsItem.documents.map((doc) => {
                // eslint-disable-next-line no-param-reassign
                doc.goodsItemId = response.id
                return doc
              })
            }
            savedItems.push(response)
          })
          .catch((error) => {
            if (!(error.response.fields.includes('ClassifierExists')
                || error.response.fields.includes('Uppercase'))) {
              toast.error(t('messages.goodsItemError'))
            }
            reject(error)
          }))
      } else {
        promises.push(GoodsItemService.putGoodsItem(goodsItem, savedDeclaration.id, isDraft)
          .then((putResponse) => {
            goodsItem.id = putResponse.id
            // eslint-disable-next-line no-param-reassign
            putResponse.visualCode = goodsItems[i].visualCode
            // eslint-disable-next-line no-param-reassign
            putResponse.consignee = goodsItem.consignee
            // eslint-disable-next-line no-param-reassign
            putResponse.consignor = goodsItem.consignor
            if (goodsItem.documents && goodsItem.documents.length > 0) {
              // eslint-disable-next-line no-param-reassign
              putResponse.documents = goodsItem.documents.map((doc) => {
                // eslint-disable-next-line no-param-reassign
                doc.goodsItemId = putResponse.id

                return doc
              })
            }
            savedItems.push(putResponse)
          })
          .catch((error) => {
            if (error) {
              if (error.response.fields.length > 0 && !(error.response.fields.includes('ClassifierExists')
                || error.response.fields.includes('Uppercase'))) {
                toast.error(t('messages.goodsItemError'))
              }
              reject(error)
            }
          }))
      }
    }
    Promise.all(promises)
      .then(() => {
        const itemPromises = []
        // eslint-disable-next-line no-restricted-syntax
        for (const savedGoods of savedItems) {
          const documentPromises: Array<Promise<Document>> = []
          // eslint-disable-next-line no-continue
          if (savedGoods.id === null || savedDeclaration.id === null) continue
          if (savedGoods.documents && savedGoods.documents.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const document of savedGoods.documents) {
              document.sortOrder = savedGoods.documents.filter((doc) => doc.type === document.type).indexOf(document)
              if (document.id === null && !document.deleted) {
                document.goodsItemId = savedGoods.id
                documentPromises.push(postDocument(document, savedDeclaration))
              } else if (document.id !== null && !document.deleted) {
                documentPromises.push(putDocument(document, savedDeclaration))
              } else if (document.id !== null && document.deleted) {
                documentPromises.push(DocumentService.deleteDocument(document.id)
                  .then((deletedItem) => {
                    // eslint-disable-next-line no-param-reassign
                    deletedItem.deleted = true
                    return deletedItem
                  }))
              }
            }
          }

          itemPromises.push(
            new Promise((resolveItem, rejectItem) => {
              Promise.all(documentPromises)
                .then((resolvedDocuments: Array<Document>) => {
                  savedGoods.documents = resolvedDocuments.filter((doc) => !doc.deleted)
                  resolveItem(resolvedDocuments)
                })
                .catch((error) => {
                  rejectItem(error)
                })
            }),
          )
        }
        Promise.all(itemPromises)
          .then(() => {
            const sorted = savedItems.sort(orderBySortOrder())
            setGoodsItems(sorted)
            setShouldUpdateGoodsForm(true)
            resolve(sorted)
          })
          .catch((error) => reject(error))
      })
      .catch((error: AxiosError) => {
        reject(error)
      })
  })

  const createOrEditTransport = (savedDeclaration: IDeclaration, isDraft: boolean) => {
    if (!savedDeclaration.id) {
      return Promise.reject(Error('Missing declaration id'))
    }
    // @ts-ignore
    const state: IStoreState = store.getState()
    const transportForm = {
      ...transport,
      loadingPlace: safeTrim(transport.loadingPlace),
      unloadingPlace: safeTrim(transport.unloadingPlace),
    }
    if (!transportForm.isIndicatorContainerised) {
      transportForm.transportContainers = []
    }

    function saveTransportOfficesAndUpdateStore(result: ITransport) {
      const savedTransport: ITransport = {
        ...result,
        transportCustomsOffices: [...transportForm.transportCustomsOffices],
      }

      if (savedTransport.transportCustomsOffices.length === 0) {
        store.dispatch(addTransport(savedTransport))
        return
      }
      saveTransportCustomsOffices(result.id)
        .then((savedOffices) => {
          savedTransport.transportCustomsOffices = savedOffices
          store.dispatch(addTransport(savedTransport))
        })
        .catch(() => {
          store.dispatch(addTransport(savedTransport))
        })
    }

    if (transportForm.id === null) {
      return TransportService.postNewTransport(savedDeclaration.id, transportForm, isDraft)
        .then((result) => {
          if (user?.role === 'ADMIN') {
            createOrEditSeal(result, state)
          }
          saveTransportOfficesAndUpdateStore(result)
        })
    }
    return TransportService.putTransport(savedDeclaration.id, transportForm, isDraft)
      .then((result) => {
        if (user?.role === 'ADMIN') {
          createOrEditSeal(result, state)
        }
        saveTransportOfficesAndUpdateStore(result)
      })
  }

  const deleteGoodsItems = () => new Promise((resolve, reject) => {
    const promises = []
    // eslint-disable-next-line no-restricted-syntax
    for (const goodsItem of deletedGoodsItems) {
      if (goodsItem.id !== null) {
        promises.push(GoodsItemService.deleteGoodsItem(goodsItem.id)
          .catch(() => {
            // eslint-disable-next-line no-console
            console.error('Error deleting goods item', goodsItem.id)
          }))
      }
    }

    Promise.all(promises)
      .then(() => {
        setDeletedGoodsItems([])
        resolve(null)
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error('Error deleting goods items', error)
        reject(error)
      })
  })

  useEffect(() => {
    if (country === null) return
    if (user?.role === 'ADMIN' && declaration.status !== DeclarationStatusEnum.T1_TRANSIT_CLOSED) {
      setAccessLevel(AccessLevel.EDIT)
      if (declaration.id) {
        directoEventState.getDirectoEvent(declaration.id)
      }
    } else if ((declaration.status === DeclarationStatusEnum.DRAFT
        || declaration.status === DeclarationStatusEnum.REJECTED
       || declaration.status !== DeclarationStatusEnum.T1_TRANSIT_CLOSED)) {
      setAccessLevel(AccessLevel.EDIT)
    } else {
      setAccessLevel(AccessLevel.VIEW)
    }
  }, [country])

  const delay = (milliseconds: number) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, milliseconds)
  })

  const iterateOverSanctionedCodes = () => {
    const keys: Array<string> = []
    goodsItems.forEach((item) => {
      sanctionedKeys.forEach((key) => {
        if (key.length === 4 || key.length === 6) {
          if (item.hsCode?.startsWith(key) && !keys.includes(item.hsCode!)) {
            keys.push(item.hsCode!)
          }
        }
        if (item.hsCode === key && !keys.includes(item.hsCode!)) {
          keys.push(item.hsCode!)
        }
      })
    })
    setSanctionedCodes(keys)
  }

  const searchByCode = (code: string) => new Promise<Array<IClassifier>>((resolve, reject) => {
    axios.get(
      apiService.getFullApiUrl('/classifier/page'),
      {
        params: {
          code: code.padEnd(8, '0'),
          classifierGroup: 'COMBINED_NOMENCLATURE',
        },
      },
    ).then(handleResponseData).then((response: PageResponse<IClassifier>) => {
      resolve(response.content)
    }).catch((error: AxiosError) => {
      reject(error)
    })
  })

  const checkForArchivedCommodityCodes = (commodityCodes: Set<string>) => new Promise<boolean>((resolve, reject) => {
    const promises: Array<Promise<IClassifier[]>> = []
    commodityCodes.forEach((commodityCode) => promises.push(searchByCode(commodityCode)))

    Promise.all(promises).then((classifiers) => {
      const anyArchived = classifiers.flatMap((commodityCodeResponse) => commodityCodeResponse)
        .findIndex((commodityCodeClassifier) => commodityCodeClassifier.isArchived === true)
      resolve(anyArchived !== -1)
    }).catch((error) => {
      reject(error)
    })
  })

  const saveDeclaration = async (isDraft: boolean) => {
    await delay(75)
    if (accessLevel === AccessLevel.VIEW) {
      return rejectError('accessDenied', 'Saving in view mode is prohibited')
    }
    if (saving) {
      return rejectError('saving', 'Saving is already in process')
    }

    setSaving(true)

    // @ts-ignore
    const state: IStoreState = store.getState()

    if (directoEventState.customerId === null && !isNullOrBlank(directoEventState.descriptionAddendum)) {
      toast.warn(t('messages.directoAddendumWithoutCustomer'))
    }

    const commodityCodes = new Set<string>()

    goodsItems.forEach((item) => {
      if (item.hsCode !== null && item.hsCode !== undefined) {
        commodityCodes.add(item.hsCode)
      }
    })

    const hasArchived: boolean = await checkForArchivedCommodityCodes(commodityCodes)

    if (hasArchived) {
      toast.error(t('messages.archivedCommodityCodes'))
      setSaving(false)
      return rejectError('goodsItem', 'One or more goods items use archived commodity code')
    }

    const declarationResponse = await createOrEditDeclaration()

    const promises = []

    if (declarationResponse.id === null) {
      return rejectError('declarationId', 'Declaration id after saving is null')
    }

    if (user?.role === 'ADMIN') {
      promises.push(
        createOrEditDirectoEvent(declarationResponse),
      )
    }

    promises.push(
      createOrEditDeclarationInfo(declarationResponse, isDraft),
    )

    promises.push(
      createOrEditGuarantee(declarationResponse, isDraft),
    )

    promises.push(
      createOrEditTransport(declarationResponse, isDraft),
    )

    const {
      carrier,
      consignee,
      consignor,
    } = state.consignmentDetailReducer

    promises.push(
      ConsignmentDetailsService.postDeclarationConsignmentDetails(
        declarationResponse.id,
        state.declarationReducer.declaration,
        {
          carrier: (!isNullOrBlank(carrier.name) && !isNullOrBlank(carrier.zip)
                && !isNullOrBlank(carrier.street)) ? carrier : null,
          consignee: (!isNullOrBlank(consignee.name) && !isNullOrBlank(consignee.zip)
                && !isNullOrBlank(consignee.street)) ? consignee : null,
          consignor: (!isNullOrBlank(consignor.name) && !isNullOrBlank(consignor.zip)
                && !isNullOrBlank(consignor.street)) ? consignor : null,
        },
      )
        .then((result) => {
          store.dispatch(addConsignmentDetails({
            consignor: result.consignor !== null ? result.consignor : consignor,
            consignee: result.consignee !== null ? result.consignee : consignee,
            carrier: result.carrier !== null ? result.carrier : carrier,
          }))
        }),
    )
    promises.push(deleteGoodsItems())

    promises.push(createOrEditGoodsItems(declarationResponse, isDraft))

    return Promise.all(promises)
      .then(() => {
        toast.success(t('messages.savingSuccess'))
        if (params.declarationId === undefined) {
          setTimeout(() => {
            history(`/transit/${declarationResponse.id}`)
            prefillDeclaration(declarationResponse.id)
            setTimeout(() => {
              setSaving(false)
            }, 400)
          }, 400)
        } else {
          setTimeout(() => {
            setSaving(false)
          }, 200)
        }
        return Promise.resolve(declarationResponse as IDeclaration)
      })
      .catch((error: AxiosError) => {
        toast.error('An error occurred while saving declaration')
        setSaving(false)
        return rejectError('declaration', `Error: ${error}`)
      })
  }

  const fetchHeaderMessage = () => {
    NotificationService.getHeaderNotificationMessage().then((response) => {
      setContextHeaderNotificationMessage(response.message)
    })
  }

  const askConfirmationForSendingToCustoms = () => {
    if (restrictedGoods.length > 0) {
      toast.error(t('messages.restrictedGoods'), { autoClose: false })
      return
    }

    const state = (store.getState() as IStoreState)

    iterateOverSanctionedCodes()
    fetchHeaderMessage()
    validate(goodsItems, false, country, countryClassifiers, state, customerRequirement, nctsProfileNames)
    setCustomsAction('SUBMIT')
    setConfirmationModalVisible(true)
  }
  const askConfirmationForEditToCustoms = () => {
    if (restrictedGoods.length > 0) {
      toast.error(t('messages.restrictedGoods'), { autoClose: false })
      return
    }
    fetchHeaderMessage()
    const state = (store.getState() as IStoreState)
    validate(goodsItems, false, country, countryClassifiers, state, customerRequirement, nctsProfileNames)
    setCustomsAction('EDIT')
    setConfirmationModalVisible(true)
  }

  const askConfirmationForCancellationToCustoms = () => {
    fetchHeaderMessage()
    setCustomsAction('CANCEL')
    setConfirmationModalVisible(true)
  }

  const submitNewDeclarationToCustoms = () => {
    setSubmittingToCustoms(true)

    if (invalidFormFields.length > 0) {
      setSubmittingToCustoms(false)
      return
    }

    saveDeclaration(false)
      .then((result: TardekError | IDeclaration) => {
        const { id } = result as IDeclaration
        if (id === null) return
        DeclarationService.submitDeclarationToCustoms(id, getDeclaration().profile, exchangeRate)
          .then((noContentResponse: string) => {
            setSubmittingToCustoms(false)
            if (noContentResponse === '') {
              // eslint-disable-next-line no-use-before-define
              if (declaration.requiresAudit && !isAdmin) {
                toast.success('Declaration submitted for review')
              } else {
                toast.success('Declaration submitted')
              }
              prefillDeclaration(Number(id))
              EventLogService.getAllEventLogs(Number(id))
                .then((logs) => setEventLogs(mapToRows(logs)))
            }
          })
          .catch((errorResponse) => {
            setSubmittingToCustoms(false)
            if (errorResponse.errors === undefined) {
              toast.error('Error while submitting declaration.', {
                delay: 20,
              })
            } else if (errorResponse.errors && errorResponse.errors.length === 0) {
              toast.error(`Error while submitting declaration to customs. ${errorResponse.message}`, {
                delay: 20,
                autoClose: false,
              })
            } else {
              const mainMessage = `${errorResponse.errors.length} validation
          ${errorResponse.length === 1 ? 'error' : 'errors'} prevented sending request to customs.`
              toast.warn(
                mainMessage,
                {
                  delay: 20,
                  autoClose: false,
                },
              )
              /* eslint-disable  @typescript-eslint/no-explicit-any */
              errorResponse.errors.forEach((error: any) => {
                const errorMessage = `Invalid field: ${error.field} \n - ${error.description}`
                toast.warn(
                  errorMessage,
                  {
                    delay: 30,
                    autoClose: 15000,
                  },
                )
              })
            }
            // eslint-disable-next-line no-console
            console.error('Validation errors occurred while submitting declaration to customs office', errorResponse)
          })
      })
      .catch(() => {
        setSubmittingToCustoms(false)
      })
  }

  const submitAmendmentToCustoms = () => {
    setSubmittingToCustoms(true)
    if (invalidFormFields.length > 0) {
      setSubmittingToCustoms(false)
      return
    }

    saveDeclaration(false)
      .then((result: TardekError | IDeclaration) => {
        const { id } = result as IDeclaration
        if (id === null) return
        DeclarationService.submitAmendmentToCustoms(id, getDeclaration().profile)
          .then((noContentResponse: string) => {
            setSubmittingToCustoms(false)
            if (noContentResponse === '') {
              toast.success('Declaration modified')
              prefillDeclaration(Number(id))
              EventLogService.getAllEventLogs(Number(id))
                .then((logs) => setEventLogs(mapToRows(logs)))
            }
          })
          .catch((errorResponse) => {
            setSubmittingToCustoms(false)
            if (errorResponse.errors && errorResponse.errors.length === 0) {
              toast.error(`Error while submitting declaration to customs. ${errorResponse.message}`, {
                delay: 20,
                autoClose: false,
              })
            } else {
              if (!errorResponse.errors) {
                toast.error(`Error: ${errorResponse.id}`)
                return
              }
              const mainMessage = `${errorResponse.errors.length} validation
          ${errorResponse.length === 1 ? 'error' : 'errors'} prevented sending request to customs.`
              toast.warn(
                mainMessage,
                {
                  delay: 20,
                  autoClose: false,
                },
              )
              // eslint-disable-next-line no-restricted-syntax
              for (const error of errorResponse.errors) {
                const errorMessage = `Invalid field: ${error.field} \n - ${error.description}`
                toast.warn(
                  errorMessage,
                  {
                    delay: 30,
                    autoClose: 15000,
                  },
                )
              }
            }
          })
      })
      .catch(() => {
        setSubmittingToCustoms(false)
      })
  }

  const submitCancellationToCustoms = () => {
    setSubmittingToCustoms(true)
    saveDeclaration(false)
      .then((result: TardekError | IDeclaration) => {
        const { id } = result as IDeclaration
        if (id === null) return
        DeclarationService.submitCancellationToCustoms(id)
          .then((noContentResponse: string) => {
            setSubmittingToCustoms(false)
            if (noContentResponse === '') {
              toast.success(t('messages.successfulCancellation'))
              setConfirmationModalVisible(false)
              prefillDeclaration(Number(id))
              EventLogService.getAllEventLogs(Number(id))
                .then((logs) => setEventLogs(mapToRows(logs)))
            }
          })
          .catch((errorResponse) => {
            setSubmittingToCustoms(false)
            if (errorResponse.errors && errorResponse.errors.length === 0) {
              toast.error(` ${t('messages.nctsSubmitError')} ${errorResponse.message}`, {
                delay: 20,
                autoClose: false,
              })
            } else if (errorResponse.errors) {
              const mainMessage = t('messages.nctsValidationError', { count: errorResponse.errors.length })
              toast.warn(
                mainMessage,
                {
                  delay: 20,
                  autoClose: false,
                },
              )
              // eslint-disable-next-line no-restricted-syntax
              for (const error of errorResponse.errors) {
                const errorMessage = `${t('messages.invalidField')}: ${error.field} \n - ${error.description}`
                toast.warn(
                  errorMessage,
                  {
                    delay: 30,
                    autoClose: 15000,
                  },
                )
              }
            } else {
              toast.error(t('messages.nctsSubmitError'))
            }
          })
      })
      .catch(() => {
        setSubmittingToCustoms(false)
      })
  }

  const isBlacklistNotEmpty = (transport: ITransport) => invalidFields.filter((fields) => fields.reason === '-111').length > 0
        || truckRestrictions.filter((restrictions) => transport.borderCrosserTruckNo?.toLowerCase()
          .includes(restrictions.toLowerCase())).length > 0
        || truckRestrictions.filter((restrictions) => transport.departureTruckNo?.toLowerCase()
          .includes(restrictions.toLowerCase())).length > 0

  const customsActionConfirmed = () => {
    if (customsAction === 'CANCEL') {
      submitCancellationToCustoms()
      return
    }
    setConfirmationModalVisible(false)
    if (sanctionedCodes.length !== 0) {
      setSanctionModalVisible(true)

      return
    }

    if (isBlacklistNotEmpty(transport)) {
      setIsBlacklistWarningModalVisible(true)
      return
    }

    setProfileModalVisible(true)
  }

  const confirmCustoms = () => {
    if (declaration.profile === null || declaration.profile === '' || declaration.profile === '-') return

    if (customsAction === 'SUBMIT') {
      if (restrictedGoods.length > 0) {
        toast.error(t('messages.restrictedGoods'), { autoClose: false })
        return
      }
      submitNewDeclarationToCustoms()
      setProfileModalVisible(false)
    } else if (customsAction === 'EDIT') {
      if (restrictedGoods.length > 0) {
        toast.error(t('messages.restrictedGoods'), { autoClose: false })
        return
      }

      submitAmendmentToCustoms()
      setProfileModalVisible(false)
    } else {
      toast.warn(`Unknown action requested: ${customsAction}`)
    }
  }

  useEffect(() => {
    axios.get(apiService.getFullApiUrl('/classifier'), {
      params: {
        classifierGroup: 'COUNTRIES',
      },
    })
      .then(handleResponseData)
      .then((countriesResponse: IClassifier[]) => {
        setCountries(countriesResponse.map((classifier) => mapCountryToOption(classifier, i18n.language)))
        setCountryClassifiers(countriesResponse)
      })
    axios.get(apiService.getFullApiUrl('/classifier'), {
      params: {
        classifierGroup: 'PACKAGE_TYPES',
      },
    })
      .then(handleResponseData)
      .then((packageTypesResponse: IClassifier[]) => {
        setPackageTypes(
          packageTypesResponse.map((packageType) => ({
            value: packageType.code,
            label: `${packageType.codeLabel}: ${i18n.language === 'ru' ? packageType.nameRu : packageType.nameEn}`,
          })),
        )
      })
    axios.get(apiService.getFullApiUrl('/classifier'), {
      params: {
        classifierGroup: 'DOCUMENT_TYPES',
      },
    })
      .then(handleResponseData)
      .then((documentTypesResponse: IClassifier[]) => {
        documentTypesResponse.forEach((item) => {
          if (item.code !== 'DOCUMENT_TYPE_NOTE') {
            setDocumentTypes((prevState) => [...prevState, {
              value: item.code,
              label: `${item.codeLabel}: ${i18n.language === 'ru' ? item.nameRu : item.nameEn}`,
            }])
          }
        })
      })
    axios.get(apiService.getFullApiUrl('/classifier'), {
      params: {
        classifierGroup: 'PREVIOUS_DOCUMENT_TYPES',
      },
    })
      .then(handleResponseData)
      .then((previousDocumentTypesResponse: IClassifier[]) => {
        setPreviousDocumentTypes(previousDocumentTypesResponse
          .map((item) => ({
            value: item.code,
            label: `${item.codeLabel}: ${i18n.language === 'ru' ? item.nameRu : item.nameEn}`,
          })))
      })
    axios.get(apiService.getFullApiUrl('/classifier'), {
      params: {
        classifierGroup: 'PROCEDURE_TYPES',
      },
    })
      .then(handleResponseData)
      .then((procedureTypesResponse: IClassifier[]) => {
        setProcedureTypes(procedureTypesResponse.map((item) => ({
          value: item.code,
          label: `${item.codeLabel}: ${i18n.language === 'ru' ? item.nameRu : item.nameEn}`,
        })))
      })
  }, [location, i18n.language])

  useEffect(() => {
    loadSanctions()
    loadRequirements()
    if (location.pathname === ROUTES.newTransit) {
      loadNctsProfileNames().then((profileOptions) => {
        const defaultProfile = profileOptions.find((profileOption) => profileOption.isDefault)?.value ?? null
        store.dispatch(addDeclaration({
          ...getDeclaration(),
          profile: defaultProfile,
        }))
      })
    }
    loadCarrierRestrictions()
    loadTruckRestrictions()
    const { declarationId } = params
    if (declarationId !== undefined) {
      prefillDeclaration(Number(declarationId))
      EventLogService.getAllEventLogs(Number(declarationId))
        .then((logs) => setEventLogs(mapToRows(logs)))
    } else {
      mockDraftCountry()
      store.dispatch({ type: 'RESET_DECLARATION' })
    }
  }, [params.declarationId])

  useEffect(() => {
    setIsSendToCustomsDisabled(!isCustomsDepartureCountryAllowed(transport.departureCustomsOffice))
  }, [transport])

  const sortNewestToFront = (a: EventLog, b: EventLog) => {
    const createdTimeA = new Date(a.created).getTime()
    const createdTimeB = new Date(b.created).getTime()

    // eslint-disable-next-line no-nested-ternary
    return createdTimeA < createdTimeB ? 1 : (createdTimeA > createdTimeB ? -1 : 0)
  }

  const isEventWithFunctionalErrors = (event: EventLog) => {
    const invalidContexts = ['IE016', 'IE005']
    return invalidContexts.indexOf(event.messageContext) !== -1
  }

  const getInvalidFields = (eventLog: EventLog) => {
    const pointers: Array<InvalidField> = []

    if (!eventLog || eventLog.functionalErrors.length === 0) {
      return pointers
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const error of eventLog.functionalErrors) {
      const strings = error.pointer ? error.pointer.split('.') : [error.pointer]
      const reason = error.reason !== null ? error.reason : error.pointer
      if (strings.length > 1) {
        strings.reverse()
          .pop()
        pointers.push({
          pointer: strings.reverse()
            .join('.'),
          reason,
        })
      } else {
        pointers.push({
          pointer: error.pointer,
          reason,
        })
      }
    }

    return pointers
  }

  function getLatestLogs() {
    if (!declaration || declaration.id === null) {
      return
    }

    EventLogService.getAllEventLogs(declaration.id)
      .then((logs) => {
        logs.sort(sortNewestToFront)
        const eventLog = logs[0]
        if (eventLog && isEventWithFunctionalErrors(eventLog)) {
          const fields = getInvalidFields(eventLog)
          setInvalidFields((prevValues) => [...prevValues, ...fields])
        } else {
          setInvalidFields((prevValues: InvalidField[]) => [...prevValues])
        }

        return setEventLogs(mapToRows(logs))
      })
  }

  useEffect(() => {
    getLatestLogs()
  }, [declaration.id])
  useEffect(() => {
    if (currentView === 'logs') {
      getLatestLogs()
    }
  }, [currentView])

  const openPdfModal = () => {
    if (accessLevel !== AccessLevel.VIEW) {
      saveDeclaration(true)
        .then(() => {
          setPdfPreviewModalVisible(true)
        })
    } else {
      setPdfPreviewModalVisible(true)
    }
  }

  const alertUser = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    if (isGoodsItemsLoading) {
      window.addEventListener('beforeunload', alertUser)
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [isGoodsItemsLoading])

  const toggleContent = () => {
    if (isGoodsItemsLoading) {
      return <LoadingBackdrop isInTable={false} loading={isGoodsItemsLoading} />
    }
    if (currentView === 'documents') {
      return <Documents />
    }
    if (currentView === 'goods-items') {
      return (
        <Goods
          countriesOptions={countries}
          quantityUnitsOptions={packageTypes}
          documentTypeOptions={documentTypes}
          previousDocumentTypeOptions={previousDocumentTypes}
          procedureTypeOptions={procedureTypes}
          declaration={declaration}
          deletedGoodsItemsState={{
            deletedGoodsItems,
            setDeletedGoodsItems,
          }}
          goodsItemsState={{
            goodsItems,
            setGoodsItems,
          }}
          shouldUpdateFormState={{
            shouldUpdateGoodsForm,
            setShouldUpdateGoodsForm,
          }}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          isLoading={isGoodsItemsLoading}
          restrictedGoods={restrictedGoods}
          restrictionKeys={restrictionKeys}
          nctsProfileNames={nctsProfileNames}
          traderRestrictions={traderRestrictions}
          countryStandardVatRate={countryStandardVatRate}
        />
      )
    }

    if (currentView === 'logs') {
      return (<TempLogView rows={eventLogs} />)
    }

    return (
      <DeclarationInfo
        countriesOptions={countries}
        countryClassifiers={countryClassifiers}
        navigate={setCurrentView}
        invalidFields={invalidFields}
        nctsProfileNames={nctsProfileNames}
        setInvalidFields={setInvalidFields}
        traderRestrictions={traderRestrictions}
        truckRestrictions={truckRestrictions}
        openCalculation={openCalculation}
        goodsItems={goodsItems}
      />
    )
  }

  const isCancelButtonVisible = () => {
    // @ts-ignore
    const state: IStoreState = store.getState()
    let visible = false
    const currentDeclaration = state.declarationReducer.declaration
    if (state.declarationReducer && currentDeclaration && country !== 'FI' && (currentDeclaration.localReferenceNumber !== null || currentDeclaration.mrn !== null)) {
      switch (currentDeclaration.status) {
        case DeclarationStatusEnum.DRAFT:
        case DeclarationStatusEnum.SUBMITTED:
        case DeclarationStatusEnum.DELETED:
        case DeclarationStatusEnum.TRANSIT_PROCEDURE_COMPLETED:
        case DeclarationStatusEnum.PROCEDURE_ENDED:
        case DeclarationStatusEnum.T1_TRANSIT_CLOSED:
        case DeclarationStatusEnum.CANCEL_REQUESTED:
          break
        case DeclarationStatusEnum.DISCREPANCIES:
        case DeclarationStatusEnum.GOODS_RELEASED:
        case DeclarationStatusEnum.OPEN:
          if (country === 'RO') {
            visible = true
          }
          break
        default:
          if (country === 'LT' && (currentDeclaration.localReferenceNumber !== null && currentDeclaration.localReferenceNumber?.length > 0)) {
            visible = true
          }

          if ((currentDeclaration.mrn != null && currentDeclaration.mrn.length > 0)
            || (currentDeclaration.localReferenceNumber !== null && currentDeclaration.localReferenceNumber?.length > 0)) {
            visible = true
          }
          break
      }
    }

    return visible
  }

  const customsButtons = () => {
    if (country === null) {
      return []
    }

    if (declaration.status === DeclarationStatusEnum.SUBMITTED || declaration.status === DeclarationStatusEnum.CANCEL_REQUESTED) {
      return []
    }

    if (user?.role !== 'ADMIN' && country !== 'LT' && (declaration.status !== DeclarationStatusEnum.DRAFT
        && declaration.status !== DeclarationStatusEnum.REJECTED && declaration.status !== DeclarationStatusEnum.DECLINED)) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return (<></>)
    }

    if (isSendToCustomsDisabled) {
      return []
    }

    const isAmendmentButtonVisible = () => {
      if (country === 'RO') {
        return false
      }

      if (declaration.mrn === null) return false

      return isCancelButtonVisible()
    }

    const buttons = []
    if (isCancelButtonVisible()) {
      buttons.push(
        <button
          key="cancelInCustoms"
          type="button"
          disabled={saving || !isCancelButtonVisible()}
          className="btn btn-outline-danger btn-lg d-flex align-items-center shadow-sm me-3"
          onClick={askConfirmationForCancellationToCustoms}
        >
          {
            submittingToCustoms
              ? <i className="fal fa-spinner fa-spin me-2" />
              : <i className="fal fa-reply fa-flip-horizontal me-2" />
          }
          <span className="text-uppercase">{t('buttons.cancelDeclaration')}</span>
        </button>,
      )
    }

    if (isAmendmentButtonVisible()) {
      buttons.push(
        [
          <button
            key="changeInCustoms"
            type="button"
            disabled={saving}
            className="btn btn-primary btn-lg d-flex align-items-center shadow-sm me-3"
            onClick={askConfirmationForEditToCustoms}
          >
            {
              submittingToCustoms
                ? <i className="fal fa-spinner fa-spin me-2" />
                : <i className="fal fa-reply fa-flip-horizontal me-2" />
            }
            <span className="text-uppercase">{t('buttons.editDeclaration')}</span>
          </button>,
        ],
      )
    }

    if (buttons.length > 0) {
      return buttons
    }

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {(declaration.status !== DeclarationStatusEnum.IN_REVIEW
          && declaration.status !== DeclarationStatusEnum.T1_TRANSIT_CLOSED && declaration.profile !== '' && declaration.profile !== '-') && (
          <button
            type="button"
            disabled={saving || isGoodsItemsLoading}
            className="btn btn-primary btn-lg d-flex align-items-center shadow-sm me-3"
            onClick={askConfirmationForSendingToCustoms}
          >
            {
              submittingToCustoms
                ? <i className="fal fa-spinner fa-spin me-2" />
                : <i className="fal fa-reply fa-flip-horizontal me-2" />
            }
            {!declaration.requiresAudit || user?.role === 'ADMIN' ? (
              <span className="text-uppercase">{`${t('buttons.send')} ${t('buttons.submitCustoms')}`}</span>
            ) : (
              <span className="text-uppercase">{`${t('buttons.send')} ${t('buttons.submitReview')}`}</span>
            )}
          </button>
        )}
      </>
    )
  }

  const saveButton = () => {
    if (!isAdmin && declaration.status !== DeclarationStatusEnum.DRAFT && declaration.status !== DeclarationStatusEnum.T1_TRANSIT_CLOSED) {
      return (
        <button
          type="button"
          className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
          disabled={declaration.status === DeclarationStatusEnum.IN_REVIEW || isGoodsItemsLoading}
          onClick={() => history('/transits')}
        >
          <span className="text-uppercase">{t('buttons.close')}</span>
        </button>
      )
    }

    return (
      <button
        type="button"
        disabled={saving
            || (declaration.status === DeclarationStatusEnum.IN_REVIEW || declaration.status === DeclarationStatusEnum.T1_TRANSIT_CLOSED)
            || isGoodsItemsLoading}
        className="btn btn-secondary btn-lg d-flex align-items-center text-primary shadow-sm"
        onClick={() => saveDeclaration(true)}
      >
        {
          !saving
          && <span className="text-uppercase">{t('buttons.saveDraft')}</span>
        }
        {
          saving
          && (
            <>
              <span className="text-uppercase">{t('common.saving')}</span>
              <i className="fa fa-spinner fa-spin ms-2" />
            </>
          )
        }
      </button>
    )
  }

  const confirmationModalText = () => {
    if (customsAction === 'SUBMIT') {
      return ` ${t('buttons.submitCustoms')}`
    }

    return customsAction === 'CANCEL' ? ` ${t('buttons.cancelCustoms')}` : ` ${t('buttons.amendmentCustoms')}`
  }

  const clearAllGoodsItemsPreviousDocumentProcedures = () => {
    const clearedGoods = []
    if (goodsItems && goodsItems.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const goodsItem of goodsItems) {
        if (goodsItem.documents && goodsItem.documents.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const document of goodsItem.documents) {
            if (document.type === DocumentType.PREVIOUS) {
              document.procedureType = null
            }
          }
        }
        clearedGoods.push(goodsItem)
      }
      setGoodsItems(clearedGoods)
    }
  }

  const toggleDuplicationModalVisibility = () => {
    if (!isDuplicationLoading) { setDuplicationModalVisible(!isDuplicationModalVisible) }
  }

  const duplicateDeclaration = (withFiles: boolean) => {
    if (isDuplicationLoading || declaration.id === undefined || declaration.id === null) { return }

    setDuplicationLoading(true)
    DeclarationService.duplicateDeclaration(declaration.id, withFiles)
      .then((response) => {
        setDuplicationLoading(false)
        toast.success(t('messages.duplicate', {
          context: 'successful',
          replace: { id: response.id },
        }))
        if (response.id !== null && response.id > 0) {
          setTimeout(() => {
            history(`/transit/${response.id}`)
          }, 500)
        }
        toggleDuplicationModalVisibility()
      })
      .catch(() => {
        setDuplicationLoading(false)
        toast.error(t('messages.duplicate', { context: 'failed' }))
        toggleDuplicationModalVisibility()
      })
  }

  return (
    <DeclarationContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        metaData: {
          country,
          setDepartureCustomsOffice,
          accessLevel,
        },
        directoEvent: directoEventState,
        clearPreviousDocumentProcedures: clearAllGoodsItemsPreviousDocumentProcedures,
        declaration: declarationData,
      }}
    >
      <TitleBar title={`${declaration.id !== null ? t('common.edit') : t('common.new')} ${t('transit.title')}`}>
        <div className="d-flex justify-content-between align-items-center ps-3 w-100">
          <div className="d-flex align-items-center">
            <div className="pt-2 text-uppercase text-muted border-end pe-3">
              <TableRowStatus value={declaration.status ? declaration.status.toString() : 'DRAFT'} />
            </div>
            {
              declaration.id && (
                <div className="d-flex border-end px-3 pt-2">
                  <div className="d-flex">{`${t('transit.id')}: ${declaration.id}`}</div>
                </div>
              )
            }
            {
              (declaration.mrn || declaration.localReferenceNumber) && (
                <div
                  className={
                    `border-end px-3 d-flex flex-column ${declaration.mrn && declaration.localReferenceNumber ? 'py-0' : 'pt-2'}`
                  }
                >
                  {
                    declaration.mrn && (
                      <div className="d-flex text-muted">
                        <small className="pe-1">{`${t('common.mrn')}: `}</small>
                        <small>{declaration.mrn}</small>
                      </div>
                    )
                  }
                  {
                    declaration.localReferenceNumber && (
                      <div className="d-flex text-muted">
                        <small className="pe-1">{`${t('common.lrn')}: `}</small>
                        <small>{declaration.localReferenceNumber}</small>
                      </div>
                    )
                  }
                </div>
              )
            }
            {(declaration.id !== null) && (
              <div className="d-flex px-3">
                <button
                  type="button"
                  disabled={isGoodsItemsLoading || saving || isSendToCustomsDisabled}
                  onClick={toggleDuplicationModalVisibility}
                  className="btn btn-primary btn-lg d-flex align-items-center shadow-sm me-3"
                >
                  {t('buttons.duplicate')}
                </button>
              </div>
            )}

          </div>
          <div className="d-flex flex-wrap">
            {
              declaration.id !== null
              && (
                <button
                  type="button"
                  className="btn btn-light btn-lg d-flex justify-content-center align-items-center rounded-circle wh-34 shadow-sm mx-3"
                  onClick={openPdfModal}
                >
                  <i className="fal fa-print" />
                </button>
              )
            }
            {(declaration.status !== DeclarationStatusEnum.IN_REVIEW || isAdmin) && (
              <>
                {customsButtons()}
                {saveButton()}
              </>
            )}
          </div>
        </div>
      </TitleBar>
      <ConfirmationModal
        title={t('buttons.duplicate')}
        messageBody={t('messages.duplicateDocumentsMessage')}
        isVisible={isDuplicationModalVisible}
        toggleVisibility={toggleDuplicationModalVisibility}
        isLoading={isDuplicationLoading}
        onConfirmation={() => duplicateDeclaration(true)}
        onRejection={() => duplicateDeclaration(false)}
      />
      <NavigationBar currentView={currentView} navigate={setCurrentView} declaration={declaration} />
      <div
        className={`${currentView === 'goods-items' ? 'flex-nowrap ' : ''}${
          contextHeaderNotificationMessage !== '' ? 'new-declaration__container--notification-open ' : ''
        } new-declaration__container`}
      >
        {toggleContent()}
        {
          declaration.id !== null
          && (
            <PdfPreviewModal
              isPdfPreviewModalVisible={isPdfPreviewModalVisible}
              setPdfPreviewModalVisible={setPdfPreviewModalVisible}
              declarationId={declaration.id}
              isPhase5={false}
            />
          )
        }
      </div>
      <SanctionModal
        isSanctionModalVisible={isSanctionModalVisible}
        setSanctionModalVisible={setSanctionModalVisible}
        setProfileModalVisible={setProfileModalVisible}
        setIsBlacklistWarningModalVisible={setIsBlacklistWarningModalVisible}
        sanctionedCodes={sanctionedCodes}
        isBlacklistNotEmpty={isBlacklistNotEmpty}
      />
      <BlacklistModal
        isBlacklistWarningModalVisible={isBlacklistWarningModalVisible}
        setIsBlacklistWarningModalVisible={setIsBlacklistWarningModalVisible}
        setProfileModalVisible={setProfileModalVisible}
        invalidFields={invalidFields}
        truckRestrictions={truckRestrictions}
      />
      <CustomsConfirmationModal
        confirmationModalVisible={confirmationModalVisible}
        setConfirmationModalVisible={setConfirmationModalVisible}
        confirmationModalText={confirmationModalText}
        invalidFormFields={invalidFormFields}
        customsActionConfirmed={customsActionConfirmed}
      />
      <ProfileModal
        profileModalVisible={profileModalVisible}
        setProfileModalVisible={setProfileModalVisible}
        nctsProfileNames={nctsProfileNames}
        currentNctsProfile={currentNctsProfile}
        declaration={declaration}
        invalidFormFields={invalidFormFields}
        confirmCustoms={confirmCustoms}
        accessLevel={accessLevel}
      />

      <>
        {
          eventLogs.findIndex((logItem) => logItem.message === 'IE928' && logItem.subRows.length > 0) === -1 && (
            <GuaranteeCalculatorModal
              rows={goodsItemsRows}
              isCalculationModalVisible={isCalculationModalVisible}
              setCalculationModalVisible={setCalculationModalVisible}
              exchangeRate={exchangeRate}
              exchangeRateLoading={exchangeRateLoading}
            />
          )
        }

        {
          eventLogs.findIndex((logItem) => logItem.message === 'IE928' && logItem.subRows.length > 0) !== -1 && (
            <FilePreviewModal
              type="PDF"
              file={{
                filename: eventLogs.find((logItem) => logItem.message === 'IE928')?.subRows
                  .find((subRow) => subRow.subType === 'FILE')?.value ?? '',
                url: eventLogs.find((logItem) => logItem.message === 'IE928')?.subRows
                  .find((subRow) => subRow.subType === 'FILE')?.reason,
              } as DocumentFile}
              isVisible={isCalculationModalVisible}
              toggleVisibility={() => setCalculationModalVisible(() => !isCalculationModalVisible)}
            />
          )
        }

      </>
    </DeclarationContext.Provider>
  )
}

export default Declaration
