import axios from 'axios'
import { CustomsProfileType } from 'routes/Customer/components/NctsProfileModal'
import { NctsProfileNameResponse } from 'types/NctsProfileNameResponse'
import { apiService, handleApiException, handleResponseData } from '../../../../services/api.service'

export interface CustomsProfileListResponse {
  id: number,
  name: string,
  grn: string,
  accessCode: string,
  eori: string,
  isDefault: boolean,
  companyName: '',
  companyPostalCode: '',
  companyStreet: '',
  companyCity: '',
  companyCountry: '',
  guaranteeType: '',
  authorityOffice: '',
  authorityCountry: ''
}

export interface NctsExportResponse {
  name: string,
}

function postCustomsProfile(customsProfile: CustomsProfileType) {
  return axios
    .post(apiService.getFullApiUrl('/ncts-profile'), {
      ...customsProfile,
    })
    .then<CustomsProfileType>(handleResponseData)
    .catch(handleApiException)
}

function getCustomsProfileForCustomerAdmin() {
  return axios
    .get(apiService.getFullApiUrl('/ncts-profile'))
    .then<Array<CustomsProfileListResponse>>(handleResponseData)
    .catch(handleApiException)
}

function updateCustomsProfile(customsProfile: CustomsProfileType, id: number) {
  return axios
    .put(apiService.getFullApiUrl(`/ncts-profile/${id}`), {
      ...customsProfile,
    })
    .then<CustomsProfileType>(handleResponseData)
    .catch(handleApiException)
}

function getAllCustomsProfileForCustomer() {
  return axios
    .get(apiService.getFullApiUrl('/ncts-profile/declaration-profile'))
    .then<NctsProfileNameResponse[]>(handleResponseData)
    .catch(handleApiException)
}

function getAllCustomsProfileByCustomerId(customerId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/ncts-profile/declaration-profile/${customerId}`))
    .then<NctsProfileNameResponse[]>(handleResponseData)
    .catch(handleApiException)
}

function requestTardekProfileAccess() {
  return axios
    .post(apiService.getFullApiUrl('/ncts-profile/request-access'))
    .then(handleResponseData)
    .catch(handleApiException)
}

function approveAccess(customerId: number, accessCode: string) {
  return axios
    .post(apiService.getFullApiUrl('/ncts-profile/approve'), {
      customerId, accessCode,
    })
    .then(handleResponseData)
    .catch(handleApiException)
}

function revokeAccess(customerId: number) {
  return axios
    .delete(apiService.getFullApiUrl(`/ncts-profile/disapprove/${customerId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function getTardekProfilesForCustomer(customerId: number) {
  return axios
    .get(apiService.getFullApiUrl(`/ncts-profile/${customerId}`))
    .then<Array<CustomsProfileListResponse>>(handleResponseData)
    .catch(handleApiException)
}

function makeProfileDefault(profileId: number) {
  return axios
    .patch(apiService.getFullApiUrl(`/ncts-profile/default/${profileId}`))
    .then(handleResponseData)
    .catch(handleApiException)
}

function getAllProfiles() {
  return axios
    .get(apiService.getFullApiUrl('/ncts-profile/all'))
    .then<NctsExportResponse[]>(handleResponseData)
    .catch(handleApiException)
}

const CustomsProfileService = {
  postCustomsProfile,
  getCustomsProfileForCustomerAdmin,
  updateCustomsProfile,
  getAllCustomsProfileForCustomer,
  getAllCustomsProfileByCustomerId,
  requestTardekProfileAccess,
  approveAccess,
  getAllProfiles,
  getTardekProfilesForCustomer,
  makeProfileDefault,
  revokeAccess,
}

export default CustomsProfileService
